import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { ToastContainer } from 'react-toastify'
import { ThemeProvider } from 'styled-components'

import Theme from './Theme'
import { appearance } from './Configs/App'

import './index.css'
import App from 'Components/App'
import { store, persistor } from './Redux'
import { createBrowserHistory } from 'history'

import 'react-toastify/dist/ReactToastify.css'
import 'react-image-gallery/styles/css/image-gallery-no-icon.css'

const rootElement = document.getElementById('root')

require('dotenv').config()

const history = createBrowserHistory()
const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1]
if (path) {
  history.replace(path)
}

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={Theme[appearance.theme]}>
        <App />
      </ThemeProvider>
      <ToastContainer />
    </PersistGate>
  </Provider>,
  rootElement
)
