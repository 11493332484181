import styled from 'styled-components'
import { fontFamily, sizes, fontSize } from 'Constants'

/**********************************************************************
 *******************DownloadCarousel***********************************
 **********************************************************************/

export const CarouselImg = styled.img`
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
  display: block !important;
  @media (max-width: ${sizes.phone}px) {
    max-width: 50%;
  }
`

export const Dots = styled.ul`
  & > li > button:before {
    opacity: 0.75 !important;
    color: silver !important;
  }
  & > li.slick-active > button:before {
    color: black !important;
  }
`

export const BackGroundWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
  background: ${props => props.theme.colors.downloadGrey};
  @media (max-width: ${sizes.phone}px) {
    text-align: center;
    padding: 30px 0;
  }
`

export const DefaultBoldText = styled.p`
  margin: 0;
  padding-top: 1.5em;
  font-size: ${fontSize.h3};
  font-family: ${fontFamily};
  font-weight: 900;
  color: ${props => props.theme.colors.defaultFontGrey};
  @media (max-width: ${sizes.tablet}px) {
    font-size: ${fontSize.h4};
  }
`

export const DefaultBold = styled.p`
  margin: 0;
  padding-top: 1.5em;
  font-size: ${fontSize.h4};
  font-family: ${fontFamily};
  font-weight: 900;
  color: ${props => props.theme.colors.defaultFontGrey};
  @media (max-width: ${sizes.tablet}px) {
    font-size: ${fontSize.h4};
  }
`

export const DefaultText = styled.p`
  font-size: ${fontSize.h4};
  font-family: ${fontFamily};
  margin: 0.5em 0;
  color: #565656;
`

export const GridDownloadApp = styled.div`
  display: flex;
  justify-content: space-around;
  width: 90%;
  max-width: 1168px;
  margin: 0 auto;
  @media (max-width: ${sizes.phone}px) {
    flex-wrap: wrap;
  }
`

export const DownloadLeft = styled.div`
  width: 30%;
  @media (max-width: ${sizes.phone}px) {
    width: 80%;
  }
`

export const DownloadRight = styled.div`
  width: 60%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (max-width: ${sizes.phone}px) {
    width: 80%;
  }
`

export const DownloadMarket = styled.div`
  padding-top: 2em;
`
export const DowloadMarketLink = styled.a`
`

export const DownloadMarketImage = styled.img`
  width: 25%;
  height: auto;
  border-radius: 10px;
  margin-right: 20px;
  @media (max-width: ${sizes.tablet}px) {
    width: 100px;
    margin: 0 5px;
  }
`
