import { put } from 'redux-saga/effects'

import { updateToken } from 'Redux/actions/auth'

function* onUnauthorizedError(action) {
  yield put(
    updateToken(null, {
      action: action.failedAction
    })
  )
}

export default onUnauthorizedError
