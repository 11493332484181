import styled from 'styled-components'
import { fontFamily } from 'Constants'

export const TabWrap = styled.div`
  background: #fff;
`

export const TabList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${p => p.theme.sizes.phone}px) {
    justify-content: space-around;
    font-size: 12px;
  }
`

export const TabLi = styled.li`
  padding: 0 15px;
  text-align: center;
  list-style: none;
  @media (max-width: ${p => p.theme.sizes.phone}px) {
    padding: 0;
  }
`

export const Span = styled.span`
  list-style: none;
  padding: 10px 0;
  color: #488eed;
  font-weight: bold;
  font-family: ${fontFamily};
  display: inline-block;
  cursor: pointer;
  position: relative;
  opacity: ${props => (props.active ? '1' : '0.5')};
  &:after {
    content: '';
    position: absolute;
    border-bottom: ${props => (props.active ? '2px solid #488eed' : 'none')};
    width: 50%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`
