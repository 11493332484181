import React from 'react'
// import countryList from 'react-select-country-list'

import { InputLabel, Row, Col6,Col12 } from '../style'
import Input from '../Atom/Input-field'
import Select from '../Atom/Select-field'
import {
  PhoneWrapper,
  DivRelative,
} from './../../../../ProfilePage/styles'

import {
  PhoneListMobile
} from 'Components/blocks'

const PersonalDetails = ({ t, validatorConfig, onHandleChange }) => {
  const {
    firstName,
    lastName,
    business,
    adforbussiness,
    email,
    mobile,
    // select_country,
    campaignTitle    
  } = validatorConfig

  console.log(firstName);
  

  // const [value, setValue] = useState('')
  // const options = useMemo(
  //   () =>
  //     countryList()
  //       .getData()
  //       .map(({ value: id, label: name }) => ({ id, name })),
  //   []
  // )

  const onCountrySelect = value => {
    onHandleChange({ target: { name: 'select_country', value } }, true)
  }

 
  return (
    <>
    
    <div>

      <Row>
        <Col12>
            <InputLabel mandatory>{t('campaign-title')}</InputLabel>
            <Input {...campaignTitle } t={t}  onHandleChange={onHandleChange} />
        </Col12>
      </Row>

      <Row>
        <Col6>
          <InputLabel mandatory>{t('fname')}</InputLabel>
          <Input {...firstName } t={t} onHandleChange={onHandleChange} />
        </Col6>
        <Col6>
          <InputLabel mandatory>{t('lname')}</InputLabel>
          <Input {...lastName} t={t} onHandleChange={onHandleChange} />
        </Col6>
      </Row>
      <Row>
        <Col6>
          <InputLabel>{t('business-Name')}</InputLabel>
          <Input {...business} t={t} onHandleChange={onHandleChange} />
        </Col6>
        <Col6>
          <InputLabel mandatory>{t('is-ad-for-business')}</InputLabel>
          <Select {...adforbussiness} onHandleChange={onHandleChange} />
        </Col6>
      </Row>
      <Row>
        <Col6>
          <InputLabel mandatory>{t('email-add')}</InputLabel>
          <Input {...email} onHandleChange={onHandleChange} />
        </Col6>
      </Row>
      <Row>
        <Col6>
          <InputLabel mandatory>{t('country')}</InputLabel>
          <div style={{ marginTop: '13px' }}>
            <PhoneWrapper>
              <DivRelative>              
                <PhoneListMobile
                  code='+1'
                  isProfile
                  value="United States"
                  setFieldValue={onCountrySelect}
                />
              </DivRelative>
            </PhoneWrapper>
          </div>
        </Col6>
        <Col6>
          <InputLabel mandatory>{t('mobile')}</InputLabel>
          <Input {...mobile} onHandleChange={onHandleChange} />
        </Col6>
      </Row>
    </div>

    
    </>
  )
}

export default PersonalDetails
