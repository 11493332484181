import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Modal, DatePicker, InputWrapper, SelectCurrency } from 'Components/ui'

import {
  Container,
  Header,
  Title,
  Buttons,
  SubmitButton,
  CancelButton,
  Content,
  Block,
  Row,
  Description,
  Label,
  Col6,
  DatePickerWrapper,
  BudgetWrapper,
  Input,
  CurrencyContainer,
  Text,
  styles,
  CloseIcon
} from './style'
import Switch from 'react-switch'
import { getBudget } from 'Constants/payment'
import { toast } from 'react-toastify'

const RecurringModal = ({
  isLoading,
  isVisible,
  onCancel,
  onSubmit,
  t,
  budget: budgetMyCountry,
  currency: currencyMyCountry,
  isShowDescription,
  onGetRatio
}) => {
  let dateDoneBefore = new Date()
  dateDoneBefore.setDate(dateDoneBefore.getDate() + 1)

  const [expiryDate, setExpiryDate] = useState(new Date())
  const [doneBefore, setDoneBefore] = useState(dateDoneBefore)
  const [appointmentDate, setAppointmentDate] = useState(new Date())
  const [isRecurring, setIsRecurring] = useState(false)
  const [currency, setCurrency] = useState(currencyMyCountry || 'USD')
  const [budget, setBudget] = useState(budgetMyCountry || 0)
  const [rate, setRate] = useState(1)

  useEffect(() => {
    setBudget(budgetMyCountry)
  }, [budgetMyCountry])

  useEffect(() => {
    setCurrency(currencyMyCountry)
  }, [currencyMyCountry])

  useEffect(() => {
    onGetRatio(currency, rate => setRate(rate))
  }, [onGetRatio, currency])

  const handleSetExpDate = date => {
    setExpiryDate(date)
  }

  const handleSetBeforeDate = date => {
    setDoneBefore(date)
  }

  const handleChangeAppointmentDate = date => setAppointmentDate(date)

  const handleChangeBudget = e => setBudget(e.target.value)

  const handleChangeCurrency = currency => setCurrency(currency)

  const handleSubmit = isHireNow => () => {
    if (getBudget(budget, rate, currency, 'number') < 10) {
      return toast.warn(t('jobPost:budgetDescription'))
    }

    onSubmit({
      expiryDate,
      doneBefore,
      appointmentDate,
      isRecurring,
      budget: getBudget(budget, rate, currency, 'number'),
      currency,
      isHireNow,
      budgetMyCountry: Number(budget),
    })
  }

  const handleChangeRecurring = () => setIsRecurring(!isRecurring)

  const handleClose = () => {
    const isAccept = window.confirm(t('jobDetail:recurringQuestion'))

    if (isAccept) {
      onCancel()
    }
    return
  }

  return (
    <Modal
      isShow={isVisible}
      zIndex={1010}
      onRequestClose={handleClose}
      width="600px"
    >
      <Container>
        <Header>
          <Title>{t('jobDetail:bookedJob')}</Title>
        </Header>
        {isShowDescription && (
          <Description>{t('jobDetail:EmployerHiringRequestTip')}</Description>
        )}
        <Content>
          <Block>
            <Row isTop>
              <Col6>
                <Label>{t('jobPost:expDate')}</Label>
                <DatePicker
                  data-lpignore="true"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  handleChange={handleSetExpDate}
                  minDate={new Date()}
                  selected={expiryDate}
                  showTimeSelect
                  timeCaption={t('time')}
                  timeFormat="HH:mm"
                  timeIntervals={15}
                />
              </Col6>
              <Col6>
                <Label>{t('jobPost:doneBefore')}</Label>
                <DatePicker
                  data-lpignore="true"
                  dateFormat="MMMM d, yyyy"
                  endDate={doneBefore}
                  handleChange={handleSetBeforeDate}
                  minDate={dateDoneBefore}
                  selected={doneBefore}
                  selectsEnd
                  startDate={new Date()}
                />
              </Col6>
            </Row>
            <DatePickerWrapper>
              <Label>{t('myJobsDetail:appointmentScheduledFor')}:</Label>
              <DatePicker
                data-lpignore="true"
                dateFormat="MMMM d, yyyy h:mm aa"
                handleChange={handleChangeAppointmentDate}
                minDate={new Date()}
                showTimeSelect
                timeCaption={t('time')}
                timeFormat="HH:mm"
                timeIntervals={30}
                value={appointmentDate}
              />
            </DatePickerWrapper>
            <Row>
              <Text flex={1}>{t('profilePage:recurring')}</Text>
              <Switch
                checked={isRecurring}
                checkedIcon={null}
                uncheckedIcon={null}
                onChange={handleChangeRecurring}
              />
            </Row>
            <BudgetWrapper>
              <InputWrapper>
                <Label htmlFor="budget">{t('jobDescription:budget')}</Label>
                <BudgetWrapper>
                  <Input
                    data-lpignore="true"
                    id="budget"
                    min="0"
                    type="number"
                    value={budget}
                    onChange={handleChangeBudget}
                  />
                  <CurrencyContainer>
                    <SelectCurrency
                      value={currency}
                      onSelect={handleChangeCurrency}
                    />
                  </CurrencyContainer>
                </BudgetWrapper>
              </InputWrapper>
            </BudgetWrapper>
            <Row top={35}>
              <Input value={getBudget(budget, rate, currency)} style={styles.budgetConvert} />
              <Input value="USD" style={styles.currency} />
            </Row>
            <Row>
              <Text>{t('jobPost:budgetDescription')}</Text>
            </Row>
          </Block>
        </Content>
        <Buttons>
          <CancelButton onClick={handleSubmit(false)} disabled={isLoading}>
            {t('jobDetail:HireSomeoneElse')}
          </CancelButton>
          <SubmitButton disabled={isLoading} onClick={handleSubmit(true)}>
            {t('serviceProvider:hireNow')}
          </SubmitButton>
        </Buttons>
      </Container>
      <CloseIcon onClick={handleClose}>×</CloseIcon>
    </Modal>
  )
}

RecurringModal.propTypes = {
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  t: PropTypes.func,
  isShowDescription: PropTypes.bool,
  isVisible: PropTypes.bool,
  onGetRatio: PropTypes.func,
}

export default RecurringModal

