import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { signIn, oauthAuthenticate } from 'Redux/actions/auth'

import Component from './SignIn'

const selector = createStructuredSelector({})

const actions = {
  onSignIn: signIn,
  onOauthAuthorize: oauthAuthenticate
}

export default connect(
  selector,
  actions
)(Component)
