import React, { PureComponent } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { PopularCategories, ServiceProviders } from './innerBlocks'

class RightSidebar extends PureComponent {
  state = {
    loadingPopularServiceProviders: false,
    loadingPopularCategories: false
  }

  componentDidMount() {
    const { popularCategories, serviceProviders } = this.props
    const { loadingPopularServiceProviders } = this.state

    if (_.isEmpty(serviceProviders) && !loadingPopularServiceProviders) {
      this.handleLoadSP()
    }

    if (_.isEmpty(popularCategories)) {
      this.handleLoadServices()
    }
  }

  handleLoadSP = () => {
    const { onLoadServiceProviders } = this.props

    this.setState({ loadingPopularServiceProviders: true }, () => {
      onLoadServiceProviders(() =>
        this.setState(prevState =>
          prevState.loadingPopularServiceProviders
            ? { loadingPopularServiceProviders: false }
            : null
        )
      )
    })
  }

  handleLoadServices = () => {
    const { onLoadPopularCategories } = this.props

    this.setState({ loadingPopularCategories: true }, () => {
      onLoadPopularCategories(() =>
        this.setState({ loadingPopularCategories: false })
      )
    })
  }

  render() {
    const {
      activeCategoriesIds,
      handleFilter,
      popularCategories,
      serviceProviders
    } = this.props
    const {
      loadingPopularCategories,
      loadingPopularServiceProviders
    } = this.state

    return (
      <>
        <PopularCategories
          activeIds={activeCategoriesIds}
          handleFilter={handleFilter}
          items={popularCategories}
          loading={loadingPopularCategories}
          onUpdate={this.handleLoadServices}
        />
        <ServiceProviders
          data={serviceProviders}
          loading={loadingPopularServiceProviders}
          onUpdate={this.handleLoadSP}
        />
      </>
    )
  }
}

RightSidebar.propTypes = {
  activeCategoriesIds: PropTypes.array,
  handleFilter: PropTypes.func,
  popularCategories: PropTypes.array,
  serviceProviders: PropTypes.array,
  onLoadPopularCategories: PropTypes.func,
  onLoadServiceProviders: PropTypes.func
}

export default RightSidebar
