import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'
import memoize from 'memoize-one'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import {
  faStar,
  faCommentDots,
  faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons'

import { findHelp } from 'Routes/routesConstants'
import {
  RoundIcon,
  Premium,
  Pro,
  Profession,
  Divider,
  Avatar,
  Star
} from 'Components/ui'

import {
  HelpCardWrapper,
  TitleWrapper,
  BodyWrapper,
  DescriptionWrapper,
  LeftGroup,
  IconGroup,
  GeneralInfo,
  NameGroup,
  StarGroup,
  SkillGroup,
  Comments,
  Distance,
  Delete,
  Span,
  TextVerified
} from './styles'
import JobImages from './JobImages'
import HireButton from './HireButton'
import { idVerificationStatus } from 'Constants/idVerification'

const HelpCard = ({
  t,
  data,
  onCommentClick,
  onHandleFavouriteClick,
  onHandleDeleteClick,
  isLoadingFavourite,
  onShareClick,
  jobsDone: propsJobsDone,
  onSendRequest,
  onClick,
  user,
  comments
}) => {
  const {
    commentsLink,
    about,
    distance,
    username,
    isPremium,
    isPro,
    id_verification,
    picsOfWork,
    jobsDone,
    rate,
    avatarURL,
    services,
    favouriteId,
    short_name
  } = data

  const filteredServices =
    services && services.filter(service => service.status === 'active')

  const _renderHireButton = () => {
    if (!_.isFunction(onSendRequest) || user.username === username) return null

    return <HireButton onClick={onSendRequest} />
  }

  return (
    <HelpCardWrapper>
      <TitleWrapper>
        {_.isFunction(onHandleDeleteClick) && (
          <Delete onClick={memoize(() => onHandleDeleteClick(username))} />
        )}
        <LeftGroup>
          <Avatar avatar={avatarURL} id={username} isVerified={id_verification === idVerificationStatus.success} />
          <GeneralInfo>
            <NameGroup>
              {_.isFunction(onClick) ? (
                <Span onClick={onClick}>{short_name}</Span>
              ) : (
                <Link to={`${findHelp}/${username}`}>
                  <Span>{short_name}</Span>
                </Link>
              )}
              {isPremium && <Premium />}
              {isPro && <Pro />}
            </NameGroup>
            {id_verification === idVerificationStatus.success && (
              <TextVerified>{t('profilePage:identificationVerified')}</TextVerified>
            )}
            <StarGroup>
              <Star>
                <FontAwesomeIcon icon={faStar} />
                <span>{rate ? rate : 0}</span>
                <Divider />
              </Star>

              <Comments>
                <Link
                  to={commentsLink ? commentsLink : '#'}
                  onClick={onCommentClick}
                >
                  <FontAwesomeIcon icon={faCommentDots} />
                  <span>{comments || 0}</span>
                </Link>
                <Divider />
              </Comments>

              {distance && (
                <Distance>
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                  <span>
                    {distance.length} {t(`jobDetail:${distance.units}`)}
                  </span>
                </Distance>
              )}
            </StarGroup>
            <SkillGroup>
              <span>
                {jobsDone || propsJobsDone} {t('jobsDone', { count: jobsDone })}
              </span>
              {filteredServices && filteredServices.length !== 0 && (
                <Profession professions={filteredServices} />
              )}
            </SkillGroup>
          </GeneralInfo>
        </LeftGroup>
        <IconGroup>
          <RoundIcon
            favourite={favouriteId}
            icon={[favouriteId ? 'fas' : 'far', 'heart']}
            id={username}
            loading={isLoadingFavourite}
            onClick={onHandleFavouriteClick}
          />
          <RoundIcon icon="paper-plane" onClick={onShareClick} />
        </IconGroup>
      </TitleWrapper>
      <BodyWrapper>
        {picsOfWork && <JobImages data={picsOfWork} id={username} />}
        {about && (
          <DescriptionWrapper>
            <p>{about}</p>
          </DescriptionWrapper>
        )}
      </BodyWrapper>
      {_renderHireButton()}
    </HelpCardWrapper>
  )
}

HelpCard.defaultProps = {
  isPremium: false,
  isPro: false,
  isVerified: false,
  jobsDone: 0
}

HelpCard.propTypes = {
  data: PropTypes.object,
  isLoadingFavourite: PropTypes.bool,
  isPremium: PropTypes.bool,
  isPro: PropTypes.bool,
  isVerified: PropTypes.bool,
  jobsDone: PropTypes.number,
  t: PropTypes.func,
  user: PropTypes.object,
  onClick: PropTypes.func,
  onHandleDeleteClick: PropTypes.func,
  onHandleFavouriteClick: PropTypes.func,
  onSendRequest: PropTypes.func,
  onShareClick: PropTypes.func
}

export default HelpCard
