import { createAsyncAction } from 'Helpers/redux'
import { gooogleApiKey, googleGeocoding } from 'Constants'

export const GET_GEO = createAsyncAction('job/GET_GEO')
export const getGeo = (lat, lng, callback) => {
  const url = `${googleGeocoding}/json?latlng=${lat},${lng}&key=${gooogleApiKey}`
  fetch(url)
    .then(response => response.json())
    .then(json => {
      if (json.status === 'OK') {
        callback(null, json)
      }
    })
    .catch(errorMessage => {
    })

  return {
    type: GET_GEO
  }
}
