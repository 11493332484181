import memoize from 'memoize-one'
import { path } from './config'
import * as routes from 'Routes/routesConstants'

export const getJobsListBC = memoize(t => [
  {
    displayName: t('findJobs')
  }
])

export const getJobsMapBC = memoize(t => [
  {
    displayName: t('findJobs')
  }
])

export const getJobDescrBC = memoize((t, isListView, jobTitle) => [
  {
    displayName: t('findJobs'),
    url: isListView ? path.jobsList : path.jobsMap
  },
  {
    displayName: jobTitle
  }
])

export const getPeopleWhoAppliedBC = memoize(
  (t, isListView, jobTitle, jobId) => [
    {
      displayName: t('findJobs'),
      url: isListView ? path.jobsList : path.jobsMap
    },
    {
      displayName: jobTitle,
      url: `${isListView ? path.jobsList : path.jobsMap}/${jobId}`
    },
    {
      displayName: t('appServiceProvider:totalServiceProviders')
    }
  ]
)

export const getAppliedProfileBC = memoize(
  (t, isListView, jobTitle, jobId, userName) => [
    {
      displayName: t('findJobs'),
      url: isListView ? path.jobsList : path.jobsMap
    },
    {
      displayName: jobTitle,
      url: `${isListView ? path.jobsList : path.jobsMap}/${jobId}`
    },
    {
      displayName: t('appServiceProvider:totalServiceProviders'),
      url: `${isListView ? path.jobsList : path.jobsMap}/${jobId}${
        routes.appliedServiceProviders
      }`
    },
    {
      displayName: userName
    }
  ]
)
