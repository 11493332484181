import axios from 'axios'
import _ from 'lodash'
import { urlRegex } from 'Constants'

const api = 'https://api.microlink.io'

export const getMicrolinkObg = async ({ url, video = true, audio = true }) => {
  if (!_.isString(url) || !urlRegex.test(url)) {
    return {
      error: 'invalidUrl'
    }
  }

  try {
    const response = await axios.get(
      `${api}?url=${encodeURIComponent(url)}${audio ? '&audio=true' : ''}${
        video ? '&video=true&palette=true&iframe=true' : ''
      }`
    )
    if (response.data.status === 'success') {
      return {
        data: _.get(response, 'data.data', {}),
        error: false
      }
    }

    return {
      data: _.get(response, 'data.data', {}),
      error: true
    }
  } catch (err) {
    return {
      data: {},
      error: true
    }
  }
}
