import React from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'

import { SmallLoader } from 'Components/ui/SmallLoader'

import SideBarItem from './ServiceProvidersItem'
import { SideBarWrapper, SideBarTitle, Header } from '../styles'

const ServiceProviders = ({ data = [], t, loading, onUpdate }) => (
  <SideBarWrapper>
    <Header>
      <SideBarTitle>{t('rightSideBar.serviceProviders')}</SideBarTitle>
      <SmallLoader active={loading} onClick={onUpdate} />
    </Header>
    {data.slice(0, 4).map((item, key) => (
      <SideBarItem item={item} key={key} />
    ))}
  </SideBarWrapper>
)

ServiceProviders.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  t: PropTypes.func,
  onUpdate: PropTypes.func
}

export default withNamespaces('common')(ServiceProviders)

export { ServiceProviders }