import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'
import _ from 'lodash'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { RoundIcon, Premium, JobPopup } from 'Components/ui'
import { jobStatuses } from 'Constants/jobStatuses.js'

import {
  DescriptionWrapper,
  TitleWrapper,
  TitleLeft,
  TitleText,
  TitleTextSpan,
  TitleRight,
  Budget,
  MilesGroup,
  MilesLeft,
  MilesRight,
  Text,
  ScrollWrap,
  JobPopupContainer,
  BookedInfo,
  ApplyBy,
  JobTitle
} from './styles'

const JobDescription = ({
  t,
  isUserJob,
  onFavouriteClick,
  data,
  onEdit,
  isFavouriteLoading,
  onDelete,
  onClick,
  onShareClick,
  isEditableVacancy,
  status
}) => {
  const {
    title,
    appliedBy,
    budget,
    distance,
    doneBefore,
    description,
    isPremium,
    favouriteId,
    _id,
    startDate,
    currency,
    budgetMyCountry,
    currencyMyCountry
  } = data

  const renderJobStatus = () => {
    if (status === jobStatuses.done) {
      return <BookedInfo>{t('myJobsDetail:done')}</BookedInfo>
    }
    if (status === jobStatuses.completed) {
      return <BookedInfo>{t('myJobsDetail:completed')}</BookedInfo>
    }
    if (status === jobStatuses.booked) {
      return <BookedInfo>{t('myJobsDetail:booked')}</BookedInfo>
    }

    return (
      <ApplyBy onClick={onClick}>
        {t('appliedBy')} {appliedBy}
      </ApplyBy>
    )
  }

  return (
    <DescriptionWrapper>
      <TitleWrapper>
        <TitleLeft isPostedJobs={isEditableVacancy}>
          {title && (
            <TitleText>
              <TitleTextSpan>{title}</TitleTextSpan>
              {isPremium && <Premium />}
            </TitleText>
          )}
          {renderJobStatus()}
        </TitleLeft>
        <TitleRight>
          {!isUserJob && (
            <RoundIcon
              disabled={isUserJob}
              favourite={favouriteId}
              icon={favouriteId ? ['fas', 'heart'] : ['far', 'heart']}
              id={_id}
              loading={isFavouriteLoading}
              size={35}
              onClick={onFavouriteClick}
            />
          )}

          <RoundIcon icon="paper-plane" size={35} onClick={onShareClick} />
          {isEditableVacancy &&
          _.isFunction(onDelete) &&
          _.isFunction(onEdit) ? (
            <JobPopupContainer>
              <JobPopup onClickDelete={onDelete} onClickEdit={onEdit} />
            </JobPopupContainer>
          ) : null}
        </TitleRight>
      </TitleWrapper>
      {budget && (
        <Budget>
          {budgetMyCountry &&
          currencyMyCountry &&
          currency !== currencyMyCountry
            ? `${t(
                'budget'
              )}: ${budgetMyCountry} ${currencyMyCountry}/ ${budget} ${currency}`
            : `${t('budget')}: ${budget} ${currency}`}
        </Budget>
      )}
      <MilesGroup>
        <MilesLeft>
          <h2>
            {t(
              _.isString(startDate)
                ? 'myJobsDetail:appointmentScheduledFor'
                : 'before'
            )}
          </h2>
          <h3>{moment(startDate || doneBefore).format('lll')}</h3>
          <JobTitle>{title}</JobTitle>
        </MilesLeft>

        {!_.isEmpty(distance) && (
          <MilesRight>
            <FontAwesomeIcon icon="map-marker-alt" />
            <span>
              {distance.length} {t(`jobDetail:${distance.units}`)}
            </span>
          </MilesRight>
        )}
      </MilesGroup>
      {description && (
        <ScrollWrap>
          <Text>{description}</Text>
        </ScrollWrap>
      )}
    </DescriptionWrapper>
  )
}

export default compose(
  withNamespaces([
    'jobDescription',
    'myJobsDetail',
    'landingPage',
    'jobDetail'
  ]),
  withRouter
)(JobDescription)

JobDescription.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    appliedBy: PropTypes.number,
    budget: PropTypes.number,
    distance: PropTypes.object,
    doneBefore: PropTypes.string,
    expDate: PropTypes.string,
    description: PropTypes.string,
    isPremium: PropTypes.bool,
    favouriteId: PropTypes.string,
    _id: PropTypes.string
  }),
  isFavouriteLoading: PropTypes.bool,
  isEditableVacancy: PropTypes.bool,
  isUserJob: PropTypes.bool,
  location: PropTypes.object,
  t: PropTypes.func,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onFavouriteClick: PropTypes.func,
  onShareClick: PropTypes.func
}
