import Title from './Title'
import SubTitle from './SubTitle'
import Wrapper from './Wrapper'
import Switch from './Switch'

export const SettingsSection = {
  Title,
  SubTitle,
  Wrapper,
  Switch
}
