import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { changePassword } from 'Redux/actions/settings'

import { getProfile } from 'Redux/selectors/user'

import Component from './ChangePassword'

const actions = {
  onChangePassword: changePassword
}

const selectors = createStructuredSelector({
  user: getProfile
})

export default connect(
  selectors,
  actions
)(Component)
