import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withNamespaces } from 'react-i18next'

import { ServicesModal } from 'Components/blocks'

import { FilterWrapper } from './styles'

class Component extends React.Component {
  state = {
    isOpenModal: false
  }

  handleOpenModal = () => {
    this.setState({ isOpenModal: true })
  }

  handleModalDismiss = () => {
    this.setState({ isOpenModal: false })
  }

  handleSubmit = props => {
    const { handleFilter } = this.props

    if (_.isEmpty(props) || !_.isObject(props)) handleFilter({ clear: true })
    else handleFilter(props)
  }

  render() {
    const { t, isFindJob, initialValues } = this.props
    const { isOpenModal } = this.state

    return (
      <>
        <FilterWrapper onClick={this.handleOpenModal}>
          {t('viewFilter')}
        </FilterWrapper>
        <ServicesModal
          initialValues={initialValues}
          isFindJob={isFindJob}
          isOpen={isOpenModal}
          onRequestClose={this.handleModalDismiss}
          onRequestDone={this.handleSubmit}
        />
      </>
    )
  }
}

Component.propTypes = {
  handleFilter: PropTypes.func,
  initialValues: PropTypes.object,
  isFindJob: PropTypes.bool,
  t: PropTypes.func.isRequired
}

export const Filter = withNamespaces('findJobPage')(Component)
