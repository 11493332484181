import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'
import { withNamespaces } from 'react-i18next'
import memoize from 'memoize-one'
import _ from 'lodash'
import { toast } from 'react-toastify'

import { ProgressBar } from 'Components/blocks'
import { Premium, Avatar } from 'Components/ui'

import 'rc-slider/assets/index.css'

import { Ad } from '../index'

import {
  Content,
  ContentAvatar,
  RaitingTitle,
  SubTitleAvatar,
  RewiewInput,
  TitleAvatarName,
  SliderWrapper,
  Star,
  SliderSection,
  FormikTitle,
  SubmitButton,
  Slider,
  StarIcon,
  RateSliderText
} from './style'
import { rateSections } from './config'

class RatingReview extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      rate: _.get(props, 'review.rate', 3),
      feedback: _.get(props, 'review.comment', ''),
      isRequest: false,
      isUpdateData: false,
      review: this.props.review
    }
  }

  componentDidMount() {
    const { onLoadSP, job, user, location } = this.props
    const { review } = this.state

    if (!review) {
      const query = qs.parse(location.search.slice(1)) || {}

      const nextState = { isUpdateData: true }

      if (query.reviewId) {
        const review = job.review.find(el => el._id === query.reviewId) || {}

        nextState.rate = review.rate
        nextState.feedback = review.comment
        nextState.review = review
      }

      this.setState(nextState, () => {
        const request =
          user.username === job.doer ? _.get(job, 'author.username') : job.doer

        onLoadSP(request, () => {
          this.setState({ isUpdateData: false })
        })
      })
    }
  }

  rateList = memoize(rate =>
    rateSections.map(el => (
      <SliderSection color={el.color} key={el.value}>
        <Star
          isActive={rate < el.value}
          onClick={() => this.handleChangeStar(el.value)}
        >
          <StarIcon>★</StarIcon>
          <RateSliderText>{el.text}</RateSliderText>
        </Star>
      </SliderSection>
    ))
  )

  handleChangeStar = rate => this.setState({ rate })

  handleChangeFeedback = ({ target: { value } }) =>
    this.setState({ feedback: value })

  handleSubmit = () => {
    const {
      onCreateReview,
      onUpdateReview,
      history,
      profile,
      t,
      job,
    } = this.props
    const { rate, feedback, review } = this.state

    this.setState({ isRequest: true })

    if (review) {
      const request = {
        reviewId: review._id,
        jobId: review.jobId
      }

      if (review.rate !== rate) request.rate = rate
      if (review.comment !== feedback) request.comment = feedback

      onUpdateReview(request, error => {
        if (!error) toast.success(t('common:success').toUpperCase())

        this.setState({ isRequest: false })
      })
    } else {
      const request = {
        reviewedUserId: profile.username,
        jobId: _.get(job, '_id'),
        comment: feedback,
        rate: rate
      }

      onCreateReview(request, error => {
        if (!error) toast.success(t('common:success').toUpperCase())

        this.setState({ isRequest: false }, history.goBack)
      })
    }
  }

  render() {
    const { profile, t } = this.props
    const { feedback, rate, isUpdateData, isRequest } = this.state

    const rateList = this.rateList(rate)

    return (
      <>
        <ProgressBar percent={isUpdateData ? 1 : 100} />
        <Content>
          <ContentAvatar>
            <RaitingTitle>{t('reviewRating')}</RaitingTitle>
            <Avatar
              avatar={profile.avatarURL}
              id={profile.username}
              isVerified={false}
            />
            <SubTitleAvatar>
              <TitleAvatarName>
                {profile.short_name}
              </TitleAvatarName>
              <Premium visible={_.get(profile, 'isPremium', false)} />
            </SubTitleAvatar>
          </ContentAvatar>
          <SliderWrapper>
            <Slider value={rate} onChange={this.handleChangeStar}>
              {rateList}
            </Slider>
          </SliderWrapper>

          <FormikTitle>{t('giveYourFeedback')}</FormikTitle>
          <RewiewInput
            placeholder={t('typeHere')}
            value={feedback}
            onChange={this.handleChangeFeedback}
          />
          <SubmitButton
            disabled={isRequest}
            type="button"
            onClick={this.handleSubmit}
          >
            {t('submit')}
          </SubmitButton>
          <Ad />
        </Content>
      </>
    )
  }
}

RatingReview.propTypes = {
  history: PropTypes.object,
  job: PropTypes.object,
  profile: PropTypes.object,
  t: PropTypes.func,
  user: PropTypes.object,
  onCreateReview: PropTypes.func,
  onLoadSP: PropTypes.func,
  onUpdateReview: PropTypes.func
}

export default withNamespaces(['rating', 'common'])(RatingReview)
