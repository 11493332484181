import React from 'react'

import { Container, Wrapper, Article, InnerWrap } from './styles'

export default () => (
  <>
    <Container>
      <Wrapper>
        <Article>
          <InnerWrap>
            <h1>SPOTJOBS Privacy Policy</h1>
            <p>
              SPOTJOBS DBASpotGigs. SPOTJOBS and SpotGigs are one and the same
              application. Therefore, Privacy Policy mentioning SPOTJOBS applies
              automatically to SpotGigsevenif SpotGigs is not mentioned.
            </p>
            <p>
              We are a social network and online platform offering paid services
              between individuals. People use our Services to find jobs, post
              jobs, hire help for various projects, and be found for jobs &
              business opportunities. Our Privacy Policy applies to any Member
              or Visitor to our Services. Our registered users (“<b>Members</b>
              ”) share their identities, location, engage with the network,
              exchange information, post jobs, hire Members, view relevant
              content, promote themselves, and find jobs opportunities. Content
              and data on some of our Services isviewable to non-members (“
              <b>Visitors</b>”). We use the term “<b>DesignatedCountries</b>” to
              refer to countries in the European Union (EU), European Economic
              Area (EEA), and Switzerland.
            </p>
            <p>
              This Privacy Policy discloses our privacy practices and the
              possible uses of the information that we gather via
              http://SPOTJOBS app.com and http://spotgigs.io, our mobile
              application(s), and via other submissions by you to SPOTJOBS
              (together, the “Site and Services”, “us”, “our” and/or “we”). By
              using the Site and Services you consent to the terms of this
              Privacy Policy. If you do not agree to the terms and conditions of
              this Privacy Policy, including having your personally identifiable
              information ("Personal Information" as defined below) used in any
              of the ways described in this Privacy Policy, you may not be able
              to use certain parts or features of our Site and Services, and in
              some instances, this may necessitate the revocation of your
              membership. Wemay update this Privacy Policy from time-to-time in
              our sole discretion. It is your responsibility to review the
              Privacy Policy for any changes each time that you use the Site and
              Services and you are bound by such changes. Your use of the Site
              and Services following us posting a new privacy policy on our Site
              signifies that you agree to and accept the Privacy Policy as
              modified. This Privacy Policy applies to the entire SPOTJOBS , and
              ATOMX LLC family of products and services, such as our online
              services, our print publications, our call centerservices, and
              other SPOTJOBS , and ATOMX LLC products or services. This Privacy
              Policy is part of our Membership Agreement, Terms of Use, and all
              other terms of using our Site and Services.
            </p>
            <p>
              Changes to the Privacy Policy apply to your use of our Services
              after the “effective date.”We can modify this Privacy Policy, and
              if we make material changes to it, we may provide notice through
              our Services, or by other means, to provide you the opportunity to
              review the changes before they become effective. If you object to
              any changes, you may{' '}
              <a
                href="/settings"
                style={{ textDecoration: 'underline', color: '#000' }}
              >
                <b>deleteyouraccount</b>
              </a>
              .You acknowledge that your continued use of our Services after we
              publish or send a notice about our changes to this Privacy Policy
              means that the collection, use and sharing of your personal data
              is subject to the updated Privacy Policy, as of its effective
              date.
            </p>
            <p>
              <b>Information Collected</b>, we collect information on our users
              in various ways, such as, by your voluntary submissions,
              participation in services provided via the Site and Services, from
              third parties with your consent, and through cookie and other
              tracking technology. Wecollect the following information:
            </p>
            <ul>
              <li>
                <i>Personal Information</i>
              </li>
            </ul>
            <p>
              "Personal Information" refers to information that tells us
              specifically who you are, such as your name, phone number, email,
              postal address, pictures, identification, website links, and
              possibly information relating to certain support or customer
              service issues. In many cases, we need this information to provide
              to you many of the services that you may have requested.
            </p>
            <ul>
              <li>
                <i>Cellular Phone Numbers</i>
              </li>
            </ul>
            <p>
              By providing a cellular telephone number to us, you expressly
              consent to, and authorize us or a third party on our behalf to
              deliver autodialed or prerecorded telemarketing calls and/or text
              messages to the number you provide. You are not required to
              consent to these calls as a condition to purchasing any goods or
              services, but withholding your consent may prevent us from
              providing certain services to you. This consent encompasses all
              future autodialed or prerecorded telemarketing calls and text
              messages from us, or a third party placing these calls on our
              behalf. You may revoke consent to receive text messages at any
              time by contacting us.
            </p>
            <ul>
              <li>
                <i>Submission of Reviews, Ratings, and Comments</i>
              </li>
            </ul>
            <p>
              We collect information that you voluntarily provide as part of our
              Site and Services, including messages, posts, comments, responses,
              reviews, and ratings you submit through the Site and Services,
              ("User Generated Content"). Wemay in our sole discretion share
              your User Generated Content with others such as your business
              reviews. If you do not want certain information shared with
              others, you should not submit User Generated Content, or should
              not submit User Generated Content that contains information or
              Personal Information you do not want shared in this manner. Once
              you have submitted User Generated Content, we reserve the right to
              publish it in any medium to others.
            </p>
            <ul>
              <li>
                <i>Aggregate Information</i>
              </li>
            </ul>
            <p>
              We may collect general, non-personal, statistical information
              about the use of the Site and Services, such as how many visitors
              visit a specific page on the Site, how long they stay on that
              page, and which hyperlinks, if any, they click on. This
              information represents a generic overview of our users, including
              their collective viewing habits, and allows us and other third
              parties to modify information, promotions, offers and/or discounts
              on products and services based on user traffic and behavior. We
              collect this information through the use of technologies such as
              'cookies'and web beacons, which are discussed in greater detail
              below. We collect this information in order to help enhance the
              Site and Services to make for a better visitor experience.
            </p>
            <ul>
              <li>
                <i>Third Party Information</i>
              </li>
            </ul>
            <p>
              We may supplement information you provide to us with information
              from other sources, such as information to validate or update your
              address, payment card or other demographic and lifestyle
              information. We use this information to help us maintain the
              accuracy of the information we collect, to enable us to fulfill
              our products and services, to target our communications so that we
              can inform you of products, services and offers that may be of
              interest, and for internal business analysis or other business
              purposes consistent with our mission.
            </p>
            <ul>
              <li>
                <i>Cookies</i>
              </li>
            </ul>
            <p>
              We may automatically collect non-personally identifiable
              information and data through the use of cookies. Cookies are small
              text files a website uses to recognize repeat users, facilitate
              the user's ongoing access to the website and facilitate the use of
              the website. Cookies also allow a website to track usage behavior
              and compile site usage information that will allow for the
              improvement of content and target advertising. Most browsers allow
              you to control cookies, including whether or not to accept them
              and how to remove them. You may set your browsers to notify you if
              you receive a cookie, or you may choose to block cookies with your
              browser. However, please be aware that some features of our Site
              and Services may not function or may be slower if you refuse
              cookies. You may also get cookies from our advertisers or other
              third parties with links on the Site as described below. We do not
              directly control these cookies. The use of advertising cookies
              sent by third party ad servers is standard in the Internet
              industry. We may use the services of third parties to collect and
              use anonymous information about your visits to and interactions
              with our App and website through the use of technologies such as
              cookies to personalize advertisements for goods and services.
            </p>
            <ul>
              <li>
                <i>Other Tracking Technologies</i>
              </li>
            </ul>
            <p>
              Tracking technologies is used for some features of our Site and
              Services, be aware that some features of our Site and Services may
              not function if the tracking technologies is not activated.
              Tracking technologies may also be used to record information such
              as Internet domain and host names; protocol (IP) addresses;
              browser software and operating system types; clickstream patterns;
              the MAC address or other technical information from your mobile
              device; location information through GPS, WiFi, Bluetooth, or
              similar technologies; and dates and times that our Site and
              Services are accessed. An IP address is a number that is
              automatically assigned to your computer whenever you are surfing
              the web. Web servers, the computers that 'serve up' webpages,
              automatically identify your computer by its IP address. The Site
              and Services may also use technology called "tracer tags". These
              may also be referred to as "Pixels", "Clear GIFs" or "Web
              Beacons". This technology allows us to understand which pages you
              visit on our Site and other ways you interact with our Site and
              Services, such as purchases made through the Site and Services.
              These tracer tags are used to help us to optimize and tailor our
              Site and Services for you and other users of our Site and
              Services. We may link the information we record using tracking
              technologies to Personal Information we collect.
            </p>
            <ul>
              <li>
                <i>Do Not Track</i>
              </li>
            </ul>
            <p>
              Do Not Track (DNT) is a privacy preference that users can set in
              some web browsers, allowing users to opt out of tracking by
              websites and online services. At the present time, the World Wide
              Web Consortium (W3C) has not yet established universal standards
              for recognizable DNT signals and therefore, the Site and Services
              do not recognize DNT. <b>Use of Information</b>
              <br />
              The information we collect is used in a variety of ways,
              including:
            </p>
            <ul>
              <li>for internal review</li>
              <li>to improve the Site and Services;</li>
              <li>
                to optimize third-party offers of products and/or services;
              </li>
              <li>to verify the legitimacy of reviews and ratings;</li>
              <li>to notify you about updates to the Site and Services;</li>
              <li>
                to let you know about products, services, and promotions that
                you may be interested in;
              </li>
              <li>to get reviews and ratings;</li>
              <li>
                for our marketing purposes and the marketing of discounts
                offered through our Site and Services by Service providers or
                health care related providers (collectively "Service
                Providers"); and
              </li>
              <li>
                to fulfill and provide products and services, including
                personalized or enhanced services, requested by you; andinternal
                business analysis or other business purposes consistent with our
                mission; and to carry out other purposes that are disclosed to
                you and to which you consent.
              </li>
              <li>
                Identifications and pictures for the ID verification process
              </li>
            </ul>
            <b>Disclosures and Transfers of Information</b>
            <p>
              We do not disclose Personal Information to third parties, except
              when one or more of the following conditions is true:
            </p>
            <ul>
              <li>
                We have your permission to make the disclosure; including but is
                not limited to Service provider and Job Provider’s name and
                address when a job is being booked via SPOTJOBS.
              </li>
              <li>
                The disclosure is necessary for the purpose for which the
                personal information was obtained;
              </li>
              <li>
                The disclosure is to the service provider from whom you
                purchased services through our platform, including without
                limitation Big Deals, Storefronts, and project submissions;
              </li>
              <li>
                The disclosure is to financial service providers in order to
                fulfill and carry out the purchase and provision of goods and
                services requested by you;
              </li>
              <li>The disclosure is permitted by relevant law;</li>
              <li>
                The Personal Information to be disclosed is otherwise publicly
                available in accordance with the applicable law;
              </li>
              <li>
                The disclosure is reasonably related to the sale or other
                disposition of all or part of our business or assets;
              </li>
              <li>
                The disclosure is for our own marketing purposes (including,
                without limitation, for SPOTJOBS to market services to you on
                third-party social media platforms such as Facebook, Amazon,
                SPOTJOBS ), or, with your authorization, for the marketing
                purposes of third parties;
              </li>
              <li>
                The disclosure is combined with information collected from other
                companies and used to improve and personalize services, content,
                and advertising from us or third parties;
              </li>
              <li>
                The party to whom the disclosure is made controls, is controlled
                by, or is under common control with SPOTJOBS , ATOMX LLC and its
                affiliates;
              </li>
              <li>
                The disclosure is in our sole discretion necessary for the
                establishment or maintenance of legal claims or legal
                compliance, to satisfy any law, regulation, subpoena or
                government request, or in connection with litigation;
              </li>
              <li>
                The disclosure is in our sole discretion about users who we
                believe are engaged in illegal activities or are otherwise in
                violation of our Membership Agreement, even without a subpoena,
                warrant or court order; or
              </li>
              <li>
                The disclosure is to outside businesses to perform certain
                services for us, such as maintaining our Site and Services,
                mailing lists, processing orders and delivering products and
                services, sending postal mail, processing claims for lost or
                stolen certificates, providing marketing assistance, confirming
                your identity for review integrity, and data analysis
                ("Administrative Service Providers"), including Administrative
                Service Providers outside the country or jurisdiction in which
                you reside.
              </li>
            </ul>
            <p>
              Prior to SPOTJOBS , ATOMX LLC and its affiliates, and such outside
              parties to whom we are transferring data to provide for the
              allowable use and security of the transferred information in
              accordance with this Privacy Policy and applicable law. If we
              become aware that any such outside party is improperly using or
              disclosing Personal Information, it will notify that outside party
              and request that it take reasonable steps to cease such use or
              disclosure. We may provide certain Personal Information as a
              service to others for their marketing purposes. We may also
              disclose your name and address to Service Providers, freelancers
              and Job Providers when you submit User Generated Content relating
              to those Service Providers, freelancers and Job Providers. If you
              properly notify us that you no longer want us to share your
              Personal Information, we will cease sharing your Personal
              Information. However, except as otherwise stated in this Privacy
              Policy, we do not directly control the third parties to which we
              share such Personal Information and you will need to further
              notify such third parties directly if you would like them to cease
              use of your Personal Information. We may also disclose aggregate
              visitor data in order to describe the use of the Site and Services
              to our existing or potential business partners or other third
              parties, or in response to a government request. We may also share
              aggregated demographic and preference data with third parties to
              enable the provision of targeted information, promotions, offers
              and/or discounts on products and services.
            </p>
            <b>
              Choice: Accessing Your Information and Opt-in/Opt-out Requests
            </b>
            <p>
              To help you review, edit, and control how we use much of the
              information we collect about you, you can visit your "My Account”
              and “Settings" sections of the Site and Services, in which you can
              update certain information and unsubscribe from receiving specific
              types of email messages. You cannot, however, unsubscribe from
              receiving certain emails, such as account status and billing
              related emails, unless you close your account and fulfill any
              existing contractual obligations. You can also ask us whether we
              are keeping information about you; ask how your information is
              being used; ask us to update or correct your information; or
              opt-out of our collection and use of certain information by
              sending an email to support@SPOTJOBS app.com, specifying the
              nature and scope of your request. If you choose to opt-out of the
              collection and use of certain of your information, you understand
              that some services, may no longer be available to you, potentially
              requiring the cancelation of your Membership. Please state in your
              correspondence the details of your request. We may require proof
              of your identity before we provide you with the requested
              information. We allow you to challenge the data that we hold about
              you and, where appropriate, you may have certain data erased,
              rectified, amended, or completed. Approved requests will be
              processed generally within ten (10) business days of the date on
              which we receive the request.
            </p>
            <b>Data Security, Integrity, and Access</b>
            <p>
              The Personal Information you provide and we collect is stored
              within databases that we control directly or through our
              Administrative Service Providers. As we deem appropriate and as
              required by the applicable law, we use security measures
              appropriate for the sensitivity of the Personal Information and
              consistent with industry standards, including physical,
              organizational and technological protections, such as firewalls,
              to protect your Personal Information. We also use industry
              standard encryption technology to protect credit card and password
              information. However, since no security system is impenetrable, we
              cannot guarantee the security of our databases, nor can we
              guarantee that information you supply will not be intercepted
              while being transmitted to us over the Internet. Personal
              Information may only be accessed by persons within our
              organization, our third-party service providers who require such
              access to provide you with the services indicated above, or any
              other party that you have provided us with authorization to share
              such information with. We retain Personal Information that we
              collect only as long as reasonably necessary to fulfill the
              purposes for which it was collected or to otherwise meet any legal
              requirements. Your information may be transferred to, and
              maintained on, computers located outside of your state,
              province/territory, or country where the privacy laws may not be
              as protective as those where you live. If you are located outside
              the United States and choose to provide information to us, please
              be aware that we transfer most Personal Information to the United
              States and process and store it there. As a result, this
              information may be subject to access requests from governments,
              courts, law enforcement officials and national security
              authorities in the United States according to its laws. Subject to
              the applicable laws, we will use reasonable efforts to ensure that
              appropriate protections are in place to maintain protections on
              the Personal Information. By submitting your Personal Information,
              you consent to having your personal data transferred to,
              processed, and stored in the United States. We may assign you a
              user ID and a password when you as part of your participation and
              access to the Site and Services. Your user ID and password may
              only be used by you and the members of your household. You may not
              share your user ID and password with anyone else, and you are
              solely responsible for maintaining and protecting the
              confidentiality of your user ID and password. You are fully
              responsible for all activities that occur under your user ID and
              password. You can access and update your login information on your
              "My Account" section in the Site and Services.
            </p>
            <b>Credit Card Information</b>
            <p>
              We may share credit card information with trusted third parties in
              order to bill you for our service. We use third parties for secure
              credit card transaction processing, and we send billing
              information to those third parties to process your orders and
              payments or to validate your credit card information on file.
              Credit card information is encrypted during transmission and
              whenever stored within our systems or with our vendors. We store
              only the amount of information required to bill you for services.
              Our credit card processing vendors use security measures to
              protect your information both during the transaction and after
              it’s complete. Credit card processing vendors are compliant with
              multiple industry-standard compliance frameworks.
            </p>
            <b>Third Party Websites and Links</b>
            <p>
              Please note that you may have cookies placed on your computer by
              third party websites that refer you to the Site. Although we do
              not share your Personal Information with these third-party
              websites unless you have authorized us to do so, they may be able
              to link certain nonpersonally identifiable information we transfer
              to them with Personal Information they previously collected from
              you. Please review the privacy policies of each website you visit
              to better understand their privacy practices. In addition, we
              would like to inform you that anytime you click on links
              (including advertising banners), which take you to third party
              websites, you will be subject to the third parties' privacy
              policies. While we support the protection of our customer's
              privacy on the Internet, SPOTJOBS , ATOMX LLC and its affiliates
              expressly disclaims any and all liability for the actions of third
              parties, including but without limitation to actions relating to
              the use and/or disclosure of Personal Information by third
              parties.
            </p>
            <p>
              Our Site and Services may contain links to other sites operated by
              third parties. SPOTJOBS , ATOMX LLC and its affiliates do not
              control such other sites and is not responsible for their content,
              their privacy policies, or their use of Personal Information.
              SPOTJOBS Inclusion of such links does not, by itself, imply any
              endorsement of the content on such sites or of their owners or
              operators except as disclosed on the Site and Services. Any
              information submitted by you directly to these third parties is
              subject to that third party's privacy policy.
            </p>
            <p>
              Our Site and Services use third parties Hosted Services to provide
              user identity verifications and allow for payment transactions.
              SPOTJOBS , ATOMX LLC and its affiliates are not responsible or
              liable for their accuracy, content, their privacy policies, or
              their use of Personal Information. Any information submitted by
              you directly to these third parties Hosted Services is subject to
              that third party's privacy policy. You acknowledge that the:
            </p>
            <p>
              a) use of the Hosted Services is at your sole risk, that we cannot
              and do not warrant that the service will meet all requirements, or
              that the operation of the Hosted Services will be uninterrupted or
              error- free.
              <br />
              b) the Hosted Services and anything related thereto are provided
              "as is" and "as available", with all faults and without warranty
              of any kind, and we hereby expressly disclaim all warranties and
              conditions with respect to the Hosted Services and anything
              related thereto, either express, implied or statutory, including,
              but not limited to, the implied warranties and/or conditions of
              merchantability, of satisfactory quality, fitness for a particular
              purpose, accuracy, quiet enjoyment, and of non-infringement of
              third party rights. No oral or written information or advice given
              by SPOTJOBS , ATOMX LLC or its affiliates shall mean or intend to
              create a warranty, express or implied.
              <br />
              c) complex software is never wholly free from defects, errors,
              bugs etc. nor entirely free from security vulnerabilities; and
              subject to the other provisions of the Agreement, we give no
              warranty or representation that the Hosted Services will be
              entirely secure or that the Hosted Services will be wholly free
              from defects, errors and/or bugs or that such defects shall be
              corrected promptly.
              <br />
              d) the third parties Hosted Services may not be able to ensure
              exactly 100% accuracy in results or go by the sharp 30-second
              verification time; these figures may vary slightly as the
              verification process can be delayed owing to heavy website traffic
              or the clarity of the verification document
            </p>
            <b>Children's Privacy</b>
            <p>
              SPOTJOBS , ATOMX LLC and its affiliates do not intend the Site and
              Services to be used by individuals under the age of majority
              within the jurisdiction they reside without the supervision of a
              parent or guardian of majority age. SPOTJOBS , ATOMX LLC and its
              affiliates relies upon such parents or guardians to determine if
              the Site and Services is appropriate for the viewing, access, or
              participation by such individuals under the age of majority. If an
              individual under the age of majority uses the Site and Services,
              such individual may only use the Site and Services under the
              supervision of a parent or guardian of majority age.
            </p>
            <p>
              We do not seek or knowingly collect any Personal Information about
              individuals under the age of majority. If we become aware that we
              have unknowingly collected Personal Information from a minor, we
              will make commercially reasonable efforts to delete such
              information from our database.
            </p>
            <p>
              If you are the parent or guardian of a minor child who has
              provided us with Personal Information, you must delete their
              account immediately.
            </p>
            <b>Governing Law; Visitors Residing Outside the United States</b>
            <p>
              This Privacy Policy and the privacy practices of SPOTJOBS will be
              subject exclusively to the laws in which SPOTJOBS provide its
              services.
            </p>
            <b>Your California Privacy Rights</b>
            <p>
              This privacy policy describes how we may share your information
              for marketing purposes. It applies to all SPOTJOBS customers,
              including California residents:
            </p>
            <ul>
              <li>
                We share information with others as more fully set forth above
                in Disclosures and
                <b> Transfers of Information.</b>
              </li>
              <li>
                We share your information with other parts of our business and
                with our service providers. See above: Disclosures and{' '}
                <b>Transfers of Information.</b>
              </li>
            </ul>
            <p>
              <b>Data Controllers and Contracting Parties</b>
              <br />
              If you are in the “Designated Countries”, ATOMX LLC and its
              affiliates will be the controller of your personal data provided
              to, or collected by or for, or processed in connection with our
              Services. If you are outside of the Designated Countries, ATOMX
              LLC and its affiliates will be the controller of your personal
              data provided to, or collected by or for, or processed in
              connection with, our Services. As a Visitor or Member of our
              Services, the collection, use and sharing of your personal data is
              subject to this Privacy Policy and other documents referenced in
              this Privacy Policy, as well as updates.
              <br />
              Advertising
              <br />
              <p>
                We serve you tailored ads both on and off our Services. We offer
                you choices regarding personalized ads, but you cannot opt-out
                of seeing other ads.
                <br />
                We may target (and measure the performance of) ads to Members,
                Visitors and others both on and off our Services directly or
                through a variety of partners, using the following data, whether
                separately or combined:
                <br />
                Data from advertising technologies on and off our Services,
                pixels, ad tags, cookies, and device identifiers;
                <br />
                Member-provided information (e.g., profile, contact information,
                location and services);
                <br />
                Data from your use of our Services (e.g., search history, feed,
                content you read, who you hire or jobs you apply for, money
                spent on the site, participation, page visits, clicking on an
                ad, etc.),
                <ul>
                  <li>
                    Information from advertising partners, vendors and
                    publishers ; and
                  </li>
                  <li>
                    Information inferred from data described above (e.g., using
                    job titles from a profile to infer industry, seniority, and
                    compensation bracket; using first names or pronoun usage to
                    infer gender; using your feed activity to infer your
                    interests; or using device data to recognize you as a
                    Member).
                  </li>
                </ul>
              </p>
            </p>
            <p>
              We will show you ads called sponsored content which look similar
              to non-sponsored content, except that they may be labeled as
              advertising (e.g., as “ad” or “sponsored”). We do not share your
              personal data with any third-party advertisers or ad networks
              except for: (i) hashed IDs or device identifiers (to the extent
              they are personal data in some countries); (ii) with your separate
              permission (e.g., in a lead generation form) or (iii) data already
              visible to any users of the Services (e.g., profile). However, if
              you view or click on an ad on or off our Services, the ad provider
              will get a signal that someone visited the page that displayed the
              ad, and they may, through the use of mechanisms such as cookies,
              determine it is you. Advertising partners can associate personal
              data collected by the advertiser directly from you with hashed IDs
              or device identifiers received from us. In such instances, we seek
              to contractually require such advertising partners to obtain your
              explicit, opt-in consent before doing so.
            </p>
            <b>Marketing</b>
            <p>
              We promote our Services to you and others. In addition to
              advertising our Services, we use Members’ data and content for
              invitations and communications promoting membership and network
              growth, engagement and our Services.
            </p>
            <b>Developing Services and Research</b>
            <p>
              We develop our Services and conduct research. We use data,
              including public feedback, to conduct research and development for
              our Services in order to provide you and others with a better,
              more intuitive and personalized experience, drive membership
              growth and engagement on our Services, and help connect Members to
              each other and economic opportunity. We use the personal data
              available to us to research social, economic and workplace trends,
              such as jobs availability and skills needed for these jobs and
              policies that help bridge the gap in various industries and
              geographic areas. In some cases, we work with trusted third
              parties to perform this research, under controls that are designed
              to protect your privacy. We publish or allow others to publish
              economic insights, presented as aggregated data rather than
              personal data. Polls and surveys are conducted by us and others
              through our Services. You are not obligated to respond to polls or
              surveys, and you have choices about the information you provide.
            </p>
            <b>Customer Support</b>
            <p>
              We use data to help you and fix problems. We use data (which can
              include your communications) to investigate, respond to and
              resolve complaints and for Service issues (e.g., bugs).
            </p>
            <b>Insights That Do Not Identify You</b>
            <p>
              We use your data to produce and share insights that do not
              identify you. For example, we may use your data to generate
              statistics about our members, their profession or industry, to
              calculate ad impressions served or clicked on, or to publish
              visitor demographics for a Service or create demographic workforce
              insights.
            </p>
            <b>Security and Investigations</b>
            <p>
              We use data for security, fraud prevention and investigations. We
              use your data (including your communications) for security
              purposes or to prevent or investigate possible fraud or other
              violations of our Terms & Conditions and/or attempts to harm our
              Members, Visitors or others.
            </p>
            <b>You may contact us with any questions. See below:</b>
            <b>How To Contact us</b>
            <p>
              All requests, questions, concerns, or complaints about your
              Personal Information or this Privacy Policy, please contact our
              Privacy Officer by email at the following:
              support@spotjobsapp.com,
              <br />
              Attn: Privacy Officer.
            </p>
          </InnerWrap>
        </Article>
      </Wrapper>
    </Container>
  </>
)
