import React from 'react'

import { findHelp } from 'Routes/routesConstants'
import { ImagesAmountInFindHelpCard } from 'Constants/counts'

import { MainImagesWrapper, ImageWrapper, CountImage } from './styles'
import { ProfileImage } from 'Components/blocks'

const JobImages = ({ data, id }) => {
  let preparedImages = []
  let count = null

  if (data && data.length <= ImagesAmountInFindHelpCard) {
    preparedImages = data
  } else if (data && data.length > ImagesAmountInFindHelpCard) {
    preparedImages = data.slice(0, ImagesAmountInFindHelpCard)
    count = data.length - ImagesAmountInFindHelpCard
  }

  return (
    <MainImagesWrapper>
      {preparedImages.map((img, key) => (
        <ImageWrapper key={key} to={`${findHelp}/${id}`}>
          <ProfileImage src={img} type="picsOfWork" userId={id} />
          {key === ImagesAmountInFindHelpCard - 1 && count && (
            <CountImage>+ {count}</CountImage>
          )}
        </ImageWrapper>
      ))}
    </MainImagesWrapper>
  )
}

export default JobImages
