import styled from 'styled-components'

export const Wrapper = styled.div`
  background: ${props => props.theme.colors.componentsBackground};
  margin-bottom: 20px;
  width: 100%;
  box-shadow: ${props => props.theme.colors.boxShadow};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  color: ${props => props.theme.colors.lightGrayText};
  font-weight: 600;
`

export const Block = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  & > span:first-child {
    color: ${props => props.theme.colors.defaultFontBlack};
  }
`

export const GroupBlocks = styled.div`
  display: flex;
`

export const Title = styled.h4`
  margin: 0;
  color: #000;
  font-size: ${p => p.theme.fontSize.h4};
`

export const SubTitle = styled.p`
  margin: 0;
  color: rgb(85, 85, 85, 1);
  padding-top: 5px;
  font-size: ${p => p.theme.fontSize.small};
`

export const AddressList = styled.ul`
  list-style-type: none;
  padding: 0;
  font-family: ${p => p.theme.fontFamily.default};
`

export const AddressElement = styled.li`
  &:first-child {
    color: ${props => props.theme.colors.defaultFontBlack};
    font-size: ${p => p.theme.fontSize.h4};
    font-weight: bold;
  }
  color: ${props => props.theme.colors.lightGrayText};
  font-size: ${p => p.theme.fontSize.small};
  list-style-type: none;
  margin-bottom: 3px;
`

export const AddressLink = styled.span`
  color: ${p => p.theme.colors.joinUs};
  font-size: ${p => p.theme.fontSize.small};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`
