import { createSelector, createStructuredSelector } from 'reselect'

const getState = state => state.providers

export const getServiceProvider = createSelector(
  getState,
  serviceProvider => serviceProvider
)

export const getSpecificSP = createSelector(
  getState,
  data => data.serviceProvider
)

export const getError = createSelector(
  getState,
  data => data.error
)

export const getFullLibraryData = createStructuredSelector({
  getServiceProvider,
  getSpecificSP,
  getError
})
