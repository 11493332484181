import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { withNamespaces } from 'react-i18next'
import { root } from 'Routes/routesConstants'
import { Wrapper, Breadcrumbs, Divider, Span } from './styles'

const BreadCrumbs = ({ location: { pathname }, t, title, routeName, data }) => {
  const getRouteName = () => {
    const matches = pathname.split('/')
    matches.shift()
    return matches[0]
  }

  const _renderPath = () =>
    data.map((el, index) => {
      if (
        _.isString(el.url) &&
        el.url.startsWith('/') &&
        !_.isFunction(el.onClick)
      ) {
        return (
          <Fragment key={index}>
            <Divider> > </Divider>
            <Link to={el.url}>{el.displayName}</Link>
          </Fragment>
        )
      }

      return (
        <Fragment key={index}>
          <Divider> > </Divider>
          <Span isClicable={_.isFunction(el.onClick)} onClick={el.handleClick}>
            {el.displayName}
          </Span>
        </Fragment>
      )
    })

  return (
    <Wrapper>
      <Breadcrumbs>
        <Link to={root}>{t('breadCrumbs.home')}</Link>
        {_.isArray(data) && !_.isEmpty(data) ? (
          _renderPath()
        ) : (
          <>
            {_.isString(title) ? (
              <>
                <Divider> > </Divider>
                <Link to={`/${getRouteName()}`}>
                  {t(`breadCrumbs.${getRouteName()}`)}
                </Link>
                <Divider> > </Divider>
                <Span>{title}</Span>
              </>
            ) : (
              <>
                <Divider> > </Divider>
                <Span>
                  {_.isString(routeName)
                    ? routeName
                    : t(`breadCrumbs.${getRouteName()}`)}
                </Span>
              </>
            )}
          </>
        )}
      </Breadcrumbs>
    </Wrapper>
  )
}

BreadCrumbs.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      url: PropTypes.string,
      onClick: PropTypes.func
    })
  ),
  location: PropTypes.object,
  match: PropTypes.object,
  routeName: PropTypes.string,
  t: PropTypes.func
}

BreadCrumbs.defaultProps = {
  location: {
    pathname: ''
  }
}

export default withNamespaces('common')(BreadCrumbs)
