import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import memoize from 'memoize-one'
import { withNamespaces } from 'react-i18next'
import { compose } from 'redux'
import { gooogleApiKey } from 'Constants'
import { icons } from 'Assets/icons'

import { Map as GoogleMap, GoogleApiWrapper } from 'google-maps-react'
import { PreloadImg, MapMarker } from 'Components/ui'

import { Ad } from 'Components/blocks'

import {
  Container,
  MapWrapper,
  CardContainer,
  CardWrapper,
  LeftIcon,
  RightIcon,
  RightIconWrapper,
  LeftIconWrapper,
  CloseCardButton
} from './style'

class MapView extends Component {
  constructor(props) {
    super(props)

    const state = {
      lat: 0,
      lng: 0,
      activeCardIndex: null,
      mapCenter: null,
      isShowCards: true
    }

    const card = _.get(props, 'data[0]', null)
    if (card && card.geolocation) {
      state.activeCardIndex = 0
      const geocode = card.geolocation.split('/')
      state.lat = geocode[0]
      state.lng = geocode[1]
    }

    this.state = state
  }

  handleMarkerClick = index =>
    this.setState({ activeCardIndex: index, isShowCards: true })

  handleNext = () => {
    const { data } = this.props
    const { activeCardIndex } = this.state

    const nextCard = data[activeCardIndex + 1]
    const geocode = nextCard.geolocation.split('/')
    const mapCenter = {
      lat: Number.parseFloat(geocode[0]),
      lng: Number.parseFloat(geocode[1])
    }

    if (data[activeCardIndex + 1])
      this.setState({ activeCardIndex: activeCardIndex + 1, mapCenter })
  }

  handleCloseCard = () =>
    this.setState({ isShowCards: false, activeCardIndex: null })

  handlePrev = () => {
    const { data } = this.props
    const { activeCardIndex } = this.state

    const nextCard = data[activeCardIndex - 1]
    const geocode = nextCard.geolocation.split('/')
    const mapCenter = {
      lat: Number.parseFloat(geocode[0]),
      lng: Number.parseFloat(geocode[1])
    }

    if (data[activeCardIndex - 1])
      this.setState({ activeCardIndex: activeCardIndex - 1, mapCenter })
  }

  _renderMarkers = () => {
    const { data } = this.props
    const { activeCardIndex } = this.state

    if (_.isEmpty(data)) return null

    return data.map((el, index) => {
      const geolocation = el.geolocation?.split('/') || [0, 0]
      return (
        <MapMarker
          center={{
            lat: Number.parseFloat(geolocation[0]),
            lng: Number.parseFloat(geolocation[1])
          }}
          isActive={activeCardIndex === index}
          key={el._id}
          onClick={memoize(() => this.handleMarkerClick(index))}
        />
      )
    })
  }

  _renderCards = () => {
    const { data, renderCard, loading, cardWidth } = this.props
    const { activeCardIndex, isShowCards } = this.state

    if (!_.isFunction(renderCard)) return null

    const card = data[activeCardIndex]

    if (!card || !isShowCards) return null

    return (
      <CardContainer width={cardWidth}>
        <CardWrapper>
          {loading ? (
            <PreloadImg alt="loading" m={0} mb={15} src={icons.preload} />
          ) : (
            <>
              {data[activeCardIndex + 1] && (
                <RightIconWrapper onClick={this.handleNext}>
                  <RightIcon />
                </RightIconWrapper>
              )}
              {renderCard(card)}
              {data[activeCardIndex - 1] && (
                <LeftIconWrapper onClick={this.handlePrev}>
                  <LeftIcon />
                </LeftIconWrapper>
              )}
              <CloseCardButton onClick={this.handleCloseCard} />
            </>
          )}
        </CardWrapper>
      </CardContainer>
    )
  }

  render() {
    const { google } = this.props
    const { lat, lng, mapCenter } = this.state

    return (
      <Container>
        <Ad />
        <MapWrapper>
          <GoogleMap
            center={mapCenter}
            google={google}
            initialCenter={{ lat: lat || 0, lng: lng || 0 }}
            zoom={12}
            onClick={this.handleCloseCard}
          >
            {this._renderMarkers()}
            {this._renderCards()}
          </GoogleMap>
        </MapWrapper>
        <Ad />
      </Container>
    )
  }
}

MapView.propTypes = {
  cardWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  data: PropTypes.array,
  google: PropTypes.object,
  loading: PropTypes.bool,
  renderCard: PropTypes.func
}

export default compose(
  withNamespaces('findHelp'),
  GoogleApiWrapper({
    apiKey: gooogleApiKey,
    libraries: ['places']
  })
)(MapView)
