import React, { PureComponent } from 'react'
import { withNamespaces } from 'react-i18next'
import { Formik } from 'formik'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'

import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import { icons } from 'Assets/icons'
import { root, signIn, termsCond } from 'Routes/routesConstants'
import { api } from 'Configs/App'
import { arrows } from 'Assets/arrows'
import PHONES from 'Constants/phones'

import { PhoneListMobile } from 'Components/blocks'
import { Link } from 'react-router-dom'
import {
  SocialButtons,
  Background,
  Registered,
  Logo,
  SubTitle,
  Form,
  Label,
  AuthInput,
  FormTitle,
  CheckBoxSpan,
  CheckBoxText,
  InputWrapper,
  ChekboxInput,
  ArrowBack,
  ForgotWrap,
  BtnSubmit,
  Col4,
  Col8
} from 'Components/ui'

import { getValidationSchema } from './config'
import {
  StyledAuthFormWrapper,
  Row,
  TermsError,
  CountryCodeWrap,
  TermsLabel,
  TermsLink,
  BtnSubmitWrapper,
  TextError,
  LabelPhone,
} from './styles'
import { handleSocialClick } from 'Helpers/socialLogin'
import { isPhoneNumber, formatPhoneNumber } from 'Constants/regex'

class SignUp extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      check: false,
      isSelectedCode: true,
      validationSchema: getValidationSchema(props.t),
      isRequest: false,
      showPassword: false,
      showPasswordConfirm: false
    }
  }

  componentDidMount() {
    const { location, onOauthAuthorize, history, t } = this.props

    const queryString = location.search.substr(1)

    if (queryString.startsWith('user=')) {
      const bodyStr = decodeURIComponent(queryString.substr('user='.length))
      const tokens = JSON.parse(bodyStr)
      const auth = { tokens }
      const headers = {
        Authorization: [tokens.TokenType, tokens.AccessToken].join(' ')
      }

      axios
        .get(api.defaultUrl + '/user', { headers })
        .then(({ status, data }) => {
          if (status === 200) {
            auth.user = data
            onOauthAuthorize(auth)
            history.replace(root)
          }
        })
        .catch(err => {
          toast.error(t('NotAuthorizedException'))
        })
        .finally(() => {
          this.setState({ isRequest: false })
        })
    } else if (queryString.startsWith('error_message=')) {
      const bodyStr = decodeURIComponent(
        queryString.substr('error_message='.length)
      )

      const errorBody = JSON.parse(bodyStr)

      if (errorBody) {
        const errorMessage = errorBody.message

        if (errorMessage) toast.error(errorMessage)
      }
    }

    history.replace(window.location.pathname)

    window.addEventListener('storage', this.handleVerify)
  }

  componentWillUnmount() {
    window.removeEventListener('storage', this.handleVerify)
  }

  handleVerify = e => {
    if (e.storageArea.verify === 'true') {
      this.setState({
        check: true
      })
      localStorage.setItem('verify', null)
    }
  }

  handleOnCheckBoxClick = () => {
    this.setState({
      check: !this.state.check
    })
  }

  handleSubmit = data => {
    const { onSignUp, t } = this.props
    const { check } = this.state
    const { email, id, password, phone, code, local } = data
    const phoneNumber = `${code}${formatPhoneNumber(phone)}`

    if (!check) {
      this.form.setStatus('notChecked')
    } else {
      const isValid = isPhoneNumber.test(phone)

      if(isValid) {
        this.setState({
          isRequest: true
        })
        onSignUp(id, email.toLowerCase(), password, phoneNumber, code, local.toLowerCase(), error => {
        this.setState({
          isRequest: false
        })
        if (!error) {
          toast.success(this.props.t('send'))
          this.props.history.push('/verify', { username: id })
        } else {
          if (error?.message.includes('phone number')) {
            this.form.setFieldError(
              'phone',
              t('validation.invalidPhoneNumberFormat')
            )

            toast.error(t('validation.invalidPhoneNumberFormat'))
          }

          if (error?.message.includes('Username cannot be of email format')) {
            this.form.setFieldError(
              'username',
              t('validation.invalidUsername')
            )
          }

          if (error?.message.includes('Invalid email address format.')) {
            this.form.setFieldError(
              'email',
              t('validation.invalidEmailAddressFormat')
            )
          }
      }})
    }
    }
  }

  handleToggle = () => {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  handleToggleConfirm = () => {
    this.setState({
      showPasswordConfirm: !this.state.showPasswordConfirm
    })
  }

  render() {
    const { t } = this.props
    const {
      check,
      isRequest,
      validationSchema,
      showPassword,
      showPasswordConfirm
    } = this.state
    const code = Object.values(PHONES)

    return (
      <Background>
        <Link to={root}>
          <Logo>
            SpotJobs<Registered>&reg;</Registered>
          </Logo>
          <SubTitle>{t('subTitle')}</SubTitle>
        </Link>
        <Formik
          initialValues={{ code: code[0].phone, local: 'CA' }}
          ref={ref => (this.form = ref)}
          validationSchema={validationSchema}
          onSubmit={this.handleSubmit}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              submitCount,
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue
            } = props

            const isFormTouched = submitCount > 0
            const isValid = isPhoneNumber.test(values.phone)
            return (
              <Form onSubmit={handleSubmit}>
                <FormTitle>
                  <ArrowBack to={signIn}>
                    <img alt="arrow" src={arrows.arrowBack} />
                  </ArrowBack>
                  {t('SignUp')}
                </FormTitle>
                <StyledAuthFormWrapper>
                  <InputWrapper>
                    <Label htmlFor="email">{t('email')}</Label>
                    <AuthInput
                      id="email"
                      placeholder={t('EnterYourEmail')}
                      type="text"
                      value={values.email || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {errors.email && (touched.email || isFormTouched) && (
                      <TextError>{errors.email}</TextError>
                    )}
                  </InputWrapper>
                  <InputWrapper>
                    <Label htmlFor="id">{t('UserID')}</Label>
                    <AuthInput
                      id="id"
                      placeholder={t('EnterUserID')}
                      type="text"
                      value={values.id || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {errors.id && (touched.id || isFormTouched) && (
                      <TextError>{errors.id}</TextError>
                    )}
                  </InputWrapper>
                  <InputWrapper>
                    <Label htmlFor="password">{t('Password')}</Label>
                    <AuthInput
                      id="password"
                      placeholder={t('Password')}
                      type={showPassword ? 'text' : 'password'}
                      value={values.password || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <FontAwesomeIcon
                      icon={showPassword ? faEye : faEyeSlash}
                      onClick={this.handleToggle}
                    />
                    {errors.password && (touched.password || isFormTouched) && (
                      <TextError>{errors.password}</TextError>
                    )}
                  </InputWrapper>
                  <InputWrapper>
                    <Label htmlFor="confirm">{t('ConfirmPass')}</Label>
                    <AuthInput
                      id="confirm"
                      placeholder={t('ConfirmPass')}
                      type={showPasswordConfirm ? 'text' : 'password'}
                      value={values.confirm || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <FontAwesomeIcon
                      icon={showPasswordConfirm ? faEye : faEyeSlash}
                      onClick={this.handleToggleConfirm}
                    />
                    {errors.confirm && (touched.confirm || isFormTouched) && (
                      <TextError>{errors.confirm}</TextError>
                    )}
                  </InputWrapper>

                  <Row>
                    <Col4>
                      <CountryCodeWrap>
                        <LabelPhone htmlFor="confirm">{t('CountryCode')}</LabelPhone>
                        <PhoneListMobile
                          code={values.code}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          value={values.code}
                        />
                      </CountryCodeWrap>
                    </Col4>
                    <Col8>
                      <InputWrapper>
                        <Label htmlFor="phone">{t('MobileNumber')}</Label>
                        <AuthInput
                          id="phone"
                          placeholder={t('EnterYourMobileNumber')}
                          type="tel"
                          value={values.phone || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {((errors.phone && (touched.phone || isFormTouched)) || (values.phone && !isValid)) && <TextError>{errors.phone || t('sign:WrongPhoneNumberFormat')}</TextError>}
                      </InputWrapper>
                    </Col8>
                  </Row>

                  <ForgotWrap>
                    <InputWrapper>
                      <TermsLabel htmlFor="terms">
                        <ChekboxInput
                          id="terms"
                          type="checkbox"
                          value={check}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          onClick={this.handleOnCheckBoxClick}
                        />
                        <CheckBoxSpan toggle={check} />
                        <CheckBoxText>
                          {t('IAgree')}{' '}
                          <TermsLink target="_blank" to={termsCond}>
                            {t('TermsAndConditions')}
                          </TermsLink>
                        </CheckBoxText>
                      </TermsLabel>
                      {!check && (touched.terms || isFormTouched) && (
                        <TermsError>{t('validation.terms')}</TermsError>
                      )}
                    </InputWrapper>
                  </ForgotWrap>
                  <BtnSubmitWrapper>
                    {isRequest ? (
                      <BtnSubmit disabled>
                        <img alt="" src={icons.threeDotsLoader} />
                      </BtnSubmit>
                    ) : (
                      <BtnSubmit type="submit">{t('SignUp')}</BtnSubmit>
                    )}
                  </BtnSubmitWrapper>
                </StyledAuthFormWrapper>
              </Form>
            )
          }}
        </Formik>

        <SocialButtons
          disabled={this.state.isRequest}
          onHandleClick={handleSocialClick}
        />
      </Background>
    )
  }
}

export default withNamespaces('sign')(SignUp)
