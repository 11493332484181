import styled from 'styled-components'
import { sizes } from 'Constants'

export const Breadcrumbs = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
  & > a {
    text-decoration: none;
    color: ${props => props.theme.colors.blueIcon};
  }
  @media (max-width: ${sizes.tablet}px) {
    display: none;
  }
`

export const Divider = styled.span`
  margin: 0 5px;
  color: #ccc;
`

export const Wrapper = styled.div`
  width: 70rem;
  max-width: 90%;
  margin: 0 auto;
`

export const Span = styled.span`
  margin: 0 5px;
  color: ${p => (p.isClicable ? 'blue' : '#555')};
  white-space: nowrap;
  overflow-x: hidden;
  max-width: 200px;
  text-overflow: ellipsis;
`
