import { compose } from 'redux'
import { withNamespaces } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getProfile } from 'Redux/selectors/user'

import Component from './WhoApplied'

const selectors = createStructuredSelector({
  user: getProfile
})

export default compose(
  withRouter,
  withNamespaces(['jobDescription', 'jobDetail']),
  connect(selectors)
)(Component)
