const { NODE_ENV } = process.env

export const clientId = '3gk1fsij8l2u0uoe813uof9th4'
export const hostedAuthUIurl =
  'https://spotjobapps.auth.us-east-1.amazoncognito.com'

export const authRedirectURL =
  NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : 'https://dzbbrssnsxdez.cloudfront.net'

export const verifyUrl = `${hostedAuthUIurl}/oauth2/token`
// eslint-disable-next-line max-len
export const hostedUIAuth = `${hostedAuthUIurl}/login?response_type=code&client_id=${clientId}&redirect_uri=${authRedirectURL}&scope=openid+profile+aws.cognito.signin.user.admin`
