import { memo } from 'react'
import { compose } from 'redux'

import { withNamespaces } from 'react-i18next'

import Component from './PrivilageList'

export default compose(
  withNamespaces(['subscriptionScreen']),
  memo
)(Component)
