import { put, select } from 'redux-saga/effects'

import { getAttemptSocketConnect } from 'Redux/selectors/socket'
import { socketAuthentificate } from 'Redux/actions/socket'
import { getAccessToken } from 'Redux/selectors/auth'
import { LOG_OUT } from 'Redux/actions/auth'

export default function*() {
  const attemp = yield select(getAttemptSocketConnect)

  if (attemp <= 5) {
    const token = yield select(getAccessToken)
    yield put(socketAuthentificate(token))
  } else {
    yield put({ type: LOG_OUT.REQUEST })
  }
}
