import * as routes from 'Routes/routesConstants'

export const sortData = [
  {
    text: 'new',
    value: 'new',
    turnOffText: 'common:off',
    turnOnText: 'common:on'
  },
  {
    text: 'distance',
    value: 'distance',
    turnOnText: ['closest', 'on'],
    turnOffText: ['closest', 'off'],
    activeValue: 'asc',
    disabledValue: 'desc'
  },
  {
    text: 'budget',
    value: 'budget',
    turnOffText: 'lowToHigh',
    turnOnText: 'highToLow'
  },
  {
    text: 'expiryDate',
    value: 'expiryDate',
    turnOnText: ['closest', 'on'],
    turnOffText: ['closest', 'off']
  },
  {
    text: 'premiumMembers',
    value: 'isPremium',
    turnOffText: 'common:off',
    turnOnText: 'common:on'
  }
]

export const path = {
  jobsList: `${routes.findJobs}${routes.listView}`,
  jobsMap: `${routes.findJobs}${routes.mapView}`,
  listJobDescription: `${routes.findJobs}${routes.listView}${routes.jobDescription}`,
  mapJobDescription: `${routes.findJobs}${routes.mapView}${routes.jobDescription}`,
  listJobPeopleWhoApplied: `${routes.findJobs}${routes.listView}${routes.jobDescription}${routes.appliedServiceProviders}`,
  mapJobPeopleWhoApplied: `${routes.findJobs}${routes.mapView}${routes.jobDescription}${routes.appliedServiceProviders}`,
  listAppliedProfile: `${routes.findJobs}${routes.listView}${routes.jobDescription}${routes.appliedServiceProviders}${routes.requestId}${routes.freelancerProfile}`,
  mapAppliedProfile: `${routes.findJobs}${routes.mapView}${routes.jobDescription}${routes.appliedServiceProviders}${routes.freelancerProfile}`
}
