import memoize from 'memoize-one'
import _ from 'lodash'
import { icons } from 'Assets/icons'

export const getCustomerInfo = memoize(author => ({
  avatar: _.get(author, 'avatarURL', icons.avatar),
  name: author.short_name,
  username: author.username,
  reviews: author.reviewsCount,
  stars: author.rate,
  isPro: author.isPro,
  isPremium: author.isPremium,
  id_verification: author?.id_verification,
}))
