import styled from 'styled-components'

export const ServicesSwitch = styled.div`
  display: flex;
  justify-align: space-between;
  align-items: center;
  margin-right: -15px;
  transform: scale(0.6);
`

export const PickText = styled.span`
  color: ${p => p.theme.colors.joinUs};
  ${p => (!p.isActive ? 'opacity: 0.6' : 'font-weight: bold')};
  transition: color 0.3s;
`

export const Option = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 25px;
  padding-left: 10px;
  & > span:hover {
    cursor: pointer;
  }
`

export const LowHighText = styled.span`
  padding-right: 25px;
  transform: scale(1.5);
  margin-right: 5px;
`
