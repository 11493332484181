import styled from 'styled-components'

export const Wrapper = styled.div`
  background: ${props => props.theme.colors.componentsBackground};
  margin-bottom: 20px;
  box-shadow: ${props => props.theme.colors.boxShadow};
  border-radius: 4px;
  padding: 15px;
  & > h3 {
    margin-top: 0;
  }
`

export const VideosWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: wrap;
`

export const Video = styled.div`
  width: 80px;
  margin-right: 20px;
  & > img {
    width: 100%;
    object-fit: cover;
  }
`
VideosWrapper.displayName = 'VideosWrapper'
