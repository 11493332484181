import styled from 'styled-components'

export const Container = styled.div`
  padding: 20px;
`

export const Header = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const Title = styled.h1`
  font-size: ${p => p.theme.fontSize.h3};
  color: #000;
  font-weight: bold;
`

export const Content = styled.div`
  margin: 20px auto 0;
  max-width: 300px;
  position: relative;
`

export const Input = styled.input`
  display: block;
  width: 100%;
  padding: 15px 25px;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
  text-align: right;
  border: 1px solid ${p => p.theme.colors.headerText};
`

export const Label = styled.label`
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  &:after {
    content: 'USD';
    display: block;
    text-align: center;
    font-size: 16px;
  }
`

export const Button = styled.button`
  font-size: 18px;
  font-weight: 600;
  display: block;
  border: none;
  border-radius: 15px;
  background: #3ad6ff;
  color: #fff;
  padding: 15px 30px;
  margin-left: auto;
  min-width: 140px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.8;
  }
`

export const CloseIcon = styled.div`
  position: absolute;
  top: -20px;
  right: -10px;
  font-size: 45px;
  background: #fff;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50%;
  box-shadow: 0 1px 4px 0 #c9cfde;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.5;
  }
`

export const LoadingIcon = styled.img`
  width: 50px;
`