import styled from 'styled-components'
import { fontSize, fontFamily } from 'Constants'

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

export const Text = styled.span`
  font-family: ${fontFamily};
  font-size: ${fontSize.h4};
`

export const Popup = styled.div`
  position: absolute;
  left: 50%;
  top: -150px;
  transform: translateX(-50%);
  min-width: 200px;
  max-width: 300px;
  background-color: #fff;
  border: solid 1px silver;
  border-radius: 10px;
  max-height: ${p => (p.isVisible ? '300px' : 0)};
  overflow-y: scroll;
  transition: max-height 0.3s, opacity 0.3s;
  opacity: ${p => (p.isVisible ? 1 : 0)};
  @media (max-width: ${p => p.theme.sizes.phone}px) {
    left: -50%;
  }
`

export const List = styled.div``

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 15px;
  border-bottom: solid 1px silver;
  transition: color 0.3s;
  cursor: pointer;
  &:hover {
    color: ${p => p.theme.colors.joinUs};
  }
  &:last-child {
    border-bottom: none;
  }
`

export const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Currency = styled(Text)`
  margin-right: 10px;
  font-size: ${fontSize.default};
`

export const Code = styled(Text)`
  font-weight: bold;
  font-size: ${fontSize.default};
`
