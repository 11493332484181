import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withNamespaces } from 'react-i18next'

import { Container, Popup, PopupButton, MoreIcon } from './style'

class Component extends PureComponent {
  state = {
    isOpen: false
  }

  handleOpen = callback =>
    this.setState(
      { isOpen: true },
      _.isFunction(callback) ? callback : undefined
    )

  handleClose = callback =>
    this.setState(
      { isOpen: false },
      _.isFunction(callback) ? callback : undefined
    )

  handleClickEdit = () => {
    const { onClickEdit } = this.props

    this.handleClose(onClickEdit)
  }

  handleClickDelete = () => {
    const { onClickDelete } = this.props

    this.handleClose(onClickDelete)
  }

  render() {
    const { t } = this.props
    const { isOpen } = this.state

    return (
      <Container>
        <MoreIcon onClick={isOpen ? this.handleClose : this.handleOpen} />
        <Popup isActive={isOpen}>
          <PopupButton testID="handleClickEdit" onClick={this.handleClickEdit}>
            <span>{t('edit')}</span>
          </PopupButton>
          <PopupButton onClick={this.handleClickDelete}>
            <span>{t('delete')}</span>
          </PopupButton>
        </Popup>
      </Container>
    )
  }
}

export const JobPopup = withNamespaces('common')(Component)

JobPopup.propTypes = {
  t: PropTypes.func,
  onClickDelete: PropTypes.func,
  onClickEdit: PropTypes.func
}
