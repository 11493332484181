import React from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'

import {
  DefaultBoldText,
  DefaultText,
  ItemDefaultWrapper,
  ItemImage,
  ItemPadder,
  HowItWorksPadder
} from './styles'

const Item = ({ noImage, t, isCards, isBigger, ...props }) => {
  const _renderContent = () => (
    <>
      {noImage ? null : (
        <ItemImage alt="" isBigger={isBigger} src={props.image} />
      )}
      <DefaultBoldText>{t(props.title)}</DefaultBoldText>
      <DefaultText more={props.more || false}>{t(props.text)}</DefaultText>
    </>
  )

  return (
    <ItemDefaultWrapper {...props}>
      {isCards ? (
        <ItemPadder>{_renderContent()}</ItemPadder>
      ) : (
        <HowItWorksPadder>{_renderContent()}</HowItWorksPadder>
      )}
    </ItemDefaultWrapper>
  )
}

Item.propTypes = {
  isBigger: PropTypes.bool,
  isCards: PropTypes.bool,
  t: PropTypes.func
}

export default withNamespaces('landingPage')(Item)
