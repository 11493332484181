import React, { PureComponent } from 'react'
import { withNamespaces } from 'react-i18next'
import { Formik } from 'formik'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

import { signIn, root } from 'Routes/routesConstants'
import { icons } from 'Assets/icons'
import {
  Background,
  Registered,
  Logo,
  SubTitle,
  Form,
  Label,
  AuthInput,
  Error,
  InputWrapper,
  BtnSubmit
} from 'Components/ui'

import { getValidationSchema } from './config'
import { StyledAuthFormWrapper, FormTitle, BtnSubmitWrapper } from './styles'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Verify extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isRequest: false,
      isResend: false,
      showPassword: false,
      showConfirmPassword: false
    }
  }

  handleSubmit = (data, username) => {
    const { onPasswordChange } = this.props
    const { confirmationCode, password } = data

    this.setState({
      isRequest: true
    })

    onPasswordChange(username, password, confirmationCode, error => {
      this.setState({
        isRequest: false
      })

      if (!error) {
        this.props.history.push({
          pathname: signIn
        })
      }
    })
  }

  handleToggleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  handleToggleShowConfirmPassword = () => {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword
    })
  }

  handleResendCode = userName => {
    const { onResend } = this.props
    this.setState({
      isResend: !this.state.isResend
    })
    onResend(userName, error => {
      this.setState({
        isResend: !this.state.isResend
      })
      !error && toast.success(this.props.t('send'))
    })
  }

  render() {
    const { t } = this.props
    const { showPassword, showConfirmPassword } = this.state
    const userName = this.props.location.params

    return (
      <Background>
        <Link to={root}>
          <Logo>
            SpotJobs<Registered>&reg;</Registered>
          </Logo>
          <SubTitle>{t('subTitle')}</SubTitle>
        </Link>
        <Formik
          validationSchema={getValidationSchema(t)}
          onSubmit={data => this.handleSubmit(data, userName)}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              submitCount
            } = props

            const isFormTouched = submitCount > 0
            return (
              <Form onSubmit={handleSubmit}>
                <FormTitle>{t('enterVerifyCode')}</FormTitle>
                <StyledAuthFormWrapper>
                  <InputWrapper>
                    <Label htmlFor="Code">{t('code')}</Label>
                    <AuthInput
                      id="confirmationCode"
                      placeholder={t('enterVerifyCode')}
                      type="text"
                      value={values.confirmationCode || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {errors.confirmationCode &&
                      (touched.confirmationCode || isFormTouched) && (
                        <Error>{errors.confirmationCode}</Error>
                      )}
                  </InputWrapper>
                  <InputWrapper>
                    <Label htmlFor="password">{t('Password')}</Label>
                    <AuthInput
                      id="password"
                      placeholder={t('Password')}
                      type={showPassword ? 'text' : 'password'}
                      value={values.password || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <FontAwesomeIcon
                      icon={this.state.showPassword ? faEye : faEyeSlash}
                      onClick={this.handleToggleShowPassword}
                    />
                    {errors.password && (touched.password || isFormTouched) && (
                      <Error>{errors.password}</Error>
                    )}
                  </InputWrapper>
                  <InputWrapper>
                    <Label htmlFor="confirm">{t('ConfirmPass')}</Label>
                    <AuthInput
                      id="confirm"
                      placeholder={t('ConfirmPass')}
                      type={showConfirmPassword ? 'text' : 'password'}
                      value={values.confirm || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <FontAwesomeIcon
                      icon={this.state.showConfirmPassword ? faEye : faEyeSlash}
                      onClick={this.handleToggleShowConfirmPassword}
                    />
                    {errors.confirm && (touched.confirm || isFormTouched) && (
                      <Error>{errors.confirm}</Error>
                    )}
                  </InputWrapper>
                  <BtnSubmitWrapper>
                    {this.state.isResend ? (
                      <BtnSubmit disabled>
                        <img alt="" src={icons.threeDotsLoader} />
                      </BtnSubmit>
                    ) : (
                      <BtnSubmit
                        type="button"
                        onClick={() => this.handleResendCode(userName)}
                      >
                        {t('resendCode')}
                      </BtnSubmit>
                    )}
                    {this.state.isRequest ? (
                      <BtnSubmit disabled>
                        <img alt="" src={icons.threeDotsLoader} />
                      </BtnSubmit>
                    ) : (
                      <BtnSubmit
                        isDisabled={Object.keys(errors).length !== 0}
                        type="submit"
                      >
                        {t('confirm')}
                      </BtnSubmit>
                    )}
                  </BtnSubmitWrapper>
                </StyledAuthFormWrapper>
              </Form>
            )
          }}
        </Formik>
      </Background>
    )
  }
}

export default withNamespaces('sign')(Verify)
