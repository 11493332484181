import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

export const Container = styled.div``

export const Header = styled.header`
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  position: relative;
  border-bottom: solid 1px silver;
`

export const Title = styled.h1`
  font-size: ${p => p.theme.fontSize.h3};
  color: #000;
  font-weight: bold;
  text-align: center;
`

export const Content = styled.div`
  overflow-y: auto;
  max-height: 80vh;
  padding: 15px 10px 0;
`

export const CloseButton = styled(FontAwesomeIcon).attrs(() => ({
  icon: faTimes
}))`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: ${p => p.theme.colors.joinUs};
  cursor: pointer;
`

export const ListEmptyText = styled.span`
  font-size: ${p => p.theme.fontSize.h4};
  /* color: gray; */
  color: black;
  text-align: center;
  margin: 20px 0 40px;
  display: block;
`
