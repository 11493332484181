import memoize from 'memoize-one'
import * as routes from 'Routes/routesConstants'

export const getBreadCrumbs = memoize(t => [
  {
    displayName: t('homePage:myJobs')
  },
  {
    displayName: t('homePage:bookedJobs')
  }
])

export const getDescriptionBreadCrumbs = memoize((t, jobTitle) => [
  {
    displayName: t('homePage:myJobs'),
    url: `${routes.myJobs}${routes.booked}`
  },
  {
    displayName: t('homePage:bookedJobs'),
    url: `${routes.myJobs}${routes.booked}`
  },
  {
    displayName: jobTitle
  }
])

export const getReviewBreadCrumbs = memoize((t, jobTitle, jobId) => [
  {
    displayName: t('homePage:myJobs'),
    url: `${routes.myJobs}${routes.booked}`
  },
  {
    displayName: t('homePage:bookedJobs'),
    url: `${routes.myJobs}${routes.booked}`
  },
  {
    displayName: jobTitle,
    url: `${routes.myJobs}${routes.booked}/${jobId}`
  },
  {
    displayName: t('rating:reviewRating')
  }
])
