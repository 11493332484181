import React, { Component } from 'react'
import { registerLocale } from 'react-datepicker'
import _ from 'lodash'
import moment from 'moment'
import './index.css'

import { locales } from 'Localization/datePickerLocales'

import { Picker } from './styles'

export class DatePicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: new Date()
    }

    registerLocale(
      localStorage.getItem('lang'),
      locales[localStorage.getItem('lang')]
    )
  }

  handleChange = date => {
    const { onChange, minDate, maxDate } = this.props

    const selectedDate = moment.utc(date)
    const currentSelectedDate = moment.utc(this.state.value)

    let nextDate = selectedDate

    if (minDate) {
      const minDateMoment = moment.utc(minDate)

      if (selectedDate.isBefore(minDateMoment)) {
        nextDate = currentSelectedDate
      }
    }

    if (maxDate) {
      const maxDateMoment = moment.utc(maxDate)

      if (selectedDate.isAfter(maxDateMoment)) {
        nextDate = currentSelectedDate
      }
    }

    const stripedDate = nextDate.toDate()

    this.setState({
      value: stripedDate
    })

    if (_.isFunction(onChange)) {
      onChange(stripedDate)
    }
  }

  render() {
    const {
      value,
      endDate,
      selected,
      startDate,
      dateFormat,
      showTimeSelect,
      timeCaption,
      timeFormat,
      timeIntervals,
      handleChange,
      minDate,
      maxDate,
      showMonthDropdown,
      showYearDropdown
    } = this.props

    return (
      <Picker
        style={{ width: '100%' }}
        data-lpignore="true"
        dateFormat={dateFormat}
        dropdownMode="select"
        endDate={endDate || null}
        locale={localStorage.getItem('lang')}
        maxDate={maxDate || null}
        minDate={minDate || null}
        selected={selected || value}
        showMonthDropdown={showMonthDropdown || null}
        showTimeSelect={showTimeSelect || null}
        showYearDropdown={showYearDropdown || null}
        startDate={startDate || null}
        timeCaption={timeCaption || null}
        timeFormat={timeFormat || null}
        timeIntervals={timeIntervals || 10}
        onChange={handleChange || this.handleChange}
      />
    )
  }
}
