import styled from 'styled-components'
import { fontFamily, sizes, fontSize } from 'Constants'

export const Social = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2em;
  width: 600px;
  max-width: 90%;
  @media (max-width: ${sizes.phone}px) {
    flex-direction: column;
  }
`

export const SocialBtn = styled.button`
  outline: none;
  width: 200px;
  ${props => (props.checkbox ? 'width: auto' : 'width: 100%;')};
  border: none;
  background: #459aee;
  padding: 10px;
  border-radius: 100px;
  color: #fff;
  font-size: ${fontSize.h4};
  font-family: ${fontFamily};
  font-weight: bold;
  cursor: pointer;
  margin: 0 10px;
  transition: all 0.3s ease;
  box-shadow: 8px 10px 20px 0 rgba(46, 61, 73, 0.15);
  &:hover {
    box-shadow: 2px 4px 8px 0 rgba(46, 61, 73, 0.2);
  }
  @media (max-width: ${sizes.phone}px) {
    margin: 10px 0;
  }
`
SocialBtn.displayName = 'SocialBtn'