import React from 'react'
import PropTypes from 'prop-types'
import withSizes from 'react-sizes'
import { sizes } from 'Constants'
import { withNamespaces } from 'react-i18next'

import { MainTitle } from 'Components/ui'

import {
  HowItWorksWrapper,
  HowItWorksItemsWrapper,
  HowItWorksItem,
  Title,
  Row,
  Body,
  Items
} from '../styles'

const Component = ({ t, items, isMobile }) => {
  const _renderItems = () => {
    if (isMobile) {
      return items.map((el, i) => (
        <HowItWorksItem key={i}>
          <span>{t(el.title)}</span>
          {t(el.text)}
        </HowItWorksItem>
      ))
    }

    return (
      <Items>
        <Row>
          {items.map((el, i) => (
            <Title key={i}>{t(el.title)}</Title>
          ))}
        </Row>
        <Row>
          {items.map((el, i) => (
            <Body key={i + 0.1}>{t(el.text)}</Body>
          ))}
        </Row>
      </Items>
    )
  }

  return (
    <HowItWorksWrapper>
      <MainTitle>{t('howItWorks')}</MainTitle>
      <HowItWorksItemsWrapper>{_renderItems()}</HowItWorksItemsWrapper>
    </HowItWorksWrapper>
  )
}

Component.propTypes = {
  isMobile: PropTypes.bool,
  items: PropTypes.array,
  t: PropTypes.func
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width <= sizes.phone
})

export const ExplanationBlock = withNamespaces('landingPage')(
  withSizes(mapSizesToProps)(Component)
)
