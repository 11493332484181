import styled from 'styled-components'
import { fontSize, fontFamily, sizes } from 'Constants'

export const SearchWrapper = styled.div`
  & > span {
    font-size: ${fontSize.h5};
    font-family: ${fontFamily};
    font-weight: 600;
  }
  @media (max-width: ${sizes.phone}px) {
    width: 48%;
    margin-right: 3%;
  }
`

export const InputWrapper = styled.div`
  position: relative;
`

export const Input = styled.input`
  padding: 10px 40px 10px 10px;
  width: 250px;
  font-size: ${fontSize.default};
  font-family: ${fontFamily};
  border: 2px solid ${props => props.theme.colors.lightGrayBorder};
  border-radius: 5px;
  &::placeholder {
    color: ${props => props.theme.colors.lightGrayText};
  }
  @media (max-width: ${sizes.desktop}px) {
    width: 200px;
  }
  /* @media (max-width: ${sizes.tablet}px) {
    width: 80%;
  } */
  @media (max-width: ${sizes.phone}px) {
    width: 100%;
    /* margin-right: 5%; */
  }
`

export const FormWrapper = styled.div`
  margin: 7px 10px 0 0;
  position: relative;
  & > svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }

  @media (max-width: ${sizes.phone}px) {
    margin: 7px 0 0 0;
  }
`

export const SearchBtn = styled.button`
  width: 22px;
  height: 22px;
  border: none;
  background: transparent;
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -8px;
  outline: none;
  cursor: pointer;
  &:before {
    content: ' ';
    border: 2px solid #f2f1f4;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
  }
  &:after {
    content: ' ';
    border-top: 2px solid #f2f1f4;
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 8px;
    height: 1px;
    transition: width 0.1s linear;
    transform-origin: 100% 50%;
    transform: rotate(-135deg);
  }
`
