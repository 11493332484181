import styled, { keyframes } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  
  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
`

export const Overlay = styled.div`
  background: ${p => (p.isVisible ? 'rgba(0, 0, 0, 0.6)' : 'transparent')};
  z-index: ${p => (p.isVisible ? 99999999999 : -1)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  transition: background 0.3s, z-index 0.3s;
  top: 0;
  left: 0;
`

export const Icon = styled(FontAwesomeIcon).attrs(() => ({
  icon: faSpinner,
  size: '3x'
}))`
  margin: 0 auto;
  animation: ${rotate} 1.5s linear infinite;
`

export const Box = styled.div`
  min-width: 150px;
  border-radius: 10px;
  background-color: silver;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
`

export const Text = styled.span`
  font-size: ${p => p.theme.fontSize.h3};
  color: #000;
  text-align: center;
  margin-top: 10px;
`
