import React from 'react'
import { profile, settings, subscriptions } from 'Routes/routesConstants'
import { Arrow, Popap, ListLink, ProfileLink } from './styles'

const ProfileDropdown = ({ t, isOpen, onLogOut }) => (
  <Arrow className="arrow" select={isOpen}>
    <Popap className="popap" select={isOpen}>
      <ProfileLink to={profile}>{t('YourProfile')}</ProfileLink>
      <ProfileLink to={subscriptions}>{t('subscription')}</ProfileLink>
      <ProfileLink to={settings}>{t('setting')}</ProfileLink>
      <ListLink onClick={onLogOut}>{t('LogOut')}</ListLink>
    </Popap>
  </Arrow>
)

export default ProfileDropdown
