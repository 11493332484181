import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import shortId from 'shortid'

import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withNamespaces } from 'react-i18next'

import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { getProfile } from 'Redux/selectors/user'
import { toast } from 'react-toastify'

import { buyPlan, buyPoints, viewedAds } from 'Redux/actions/subscription'
import { fetchUser } from 'Redux/actions/user'
import { rehydrateToken } from 'Redux/actions/auth'
import { getPrice, getPoints } from 'Redux/selectors/subscriptions'

function withPayments(Component) {
  return class extends React.Component {
    static propTypes = {
      price: PropTypes.number,
      user: PropTypes.object,
      onBuyPlan: PropTypes.func,
      onBuyPoints: PropTypes.func,
      onGetUser: PropTypes.func
    }

    state = {
      isRequest: false
    }

    componentDidMount() {
      const { user, onGetUser } = this.props
      this._isMounted = true

      if (!user) onGetUser()
    }

    componentWillUnmount() {
      window.removeEventListener('storage', this.updateData)
    }

    _isMounted = false

    activePlan = null

    paymentId = null

    setRequest = (value, callback) => {
      if (this._isMounted) this.setState({ isRequest: value }, callback)
      else if (_.isFunction(callback)) callback()
    }

    getProps = () =>
      _.omit(this.props, ['user', 'onBuyPoints', 'onBuyPlan', 'onGetUser'])

    updateData = () => {
      const paymentId = localStorage.getItem('paymentId')

      localStorage.removeItem('paymentId')

      if (paymentId === this.paymentId) {
        if (this.activePlan) {
          this.handleBuyPlan(this.activePlan, {
            forPoints: true,
            afterBuy: true
          })
          this.activePlan = null
        }

        this.paymentId = null
        window.removeEventListener('storage', this.updateData)
      }
    }

    handleBuyPlan = (plan, options = {}) => {
      const { user, t } = this.props
      const { isRequest } = this.state
      const { forPoints, afterBuy } = options
      console.log('____OPTIONS___', options)

      if (isRequest) return

      if (forPoints) {
        if (user.ptsAmount >= plan.val || afterBuy) {
          this.setRequest(true)
          const planType =
            plan.type === 'monthPremiumSubscriptionCost' ? 'monthly' : 'yearly'

          this.props.onBuyPlan(planType, () => {
            this.setRequest(false)
          })
        } else toast.error(t('NotEnoughPts'))
      } else {
        this.activePlan = plan
        this.handleBuyPoints(plan.val)
      }
    }

    handleBuyPoints = points => {
      const {
        onBuyPoints,
        // onViewedAds,
        // onTokenRehydrate
      } = this.props
      const { isRequest } = this.state

      if (isRequest) return

      const paymentId = shortId.generate()

      this.setRequest(true)

      onBuyPoints({ amount: points, paymentId }, error => {
        this.setRequest(false)

        console.log('points', points)

        // if (!error) {
        //   onViewedAds(Number(points), () => {
        //     onTokenRehydrate()
        //   })
        // }

        if (!error && this.activePlan) {
          this.paymentId = paymentId

          window.addEventListener('storage', this.updateData)
        }
      })
    }

    render() {
      const { isRequest } = this.state

      return (
        <Component
          {...this.getProps()}
          isPaymentRequest={isRequest}
          onBuyPlan={this.handleBuyPlan}
          onBuyPoints={this.handleBuyPoints}
        />
      )
    }
  }
}

const actions = {
  onBuyPoints: buyPoints,
  onBuyPlan: buyPlan,
  onGetUser: fetchUser,
  onViewedAds: viewedAds,
  onTokenRehydrate: rehydrateToken
}

const selectors = createStructuredSelector({
  user: getProfile,
  price: getPrice,
  pointsFromSubscription: getPoints
})

export default compose(
  withRouter,
  withNamespaces(['apiErrors']),
  connect(selectors, actions),
  withPayments
)
