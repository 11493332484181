import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Wrapper, Icon } from './styles'

export const RoundIcon = ({
  icon,
  size = null,
  onClick,
  id,
  favourite,
  disabled,
  loading
}) => {
  const iconSize = size / 2
  return (
    <Wrapper
      active={favourite}
      disabled={disabled}
      iconSize={iconSize}
      isLoading={loading}
      size={size}
      onClick={() =>
        disabled ? undefined : _.isFunction(onClick) && onClick(id, favourite)
      }
    >
      <Icon icon={icon} />
    </Wrapper>
  )
}

RoundIcon.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onHandleClick: PropTypes.func
}
