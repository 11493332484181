import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Formik } from 'formik'
import { isEmpty, find, get } from 'lodash'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import PHONES from 'Constants/phones'
import { arrows } from 'Assets/arrows'
import { icons } from 'Assets/icons'

import { PARSE_LOCATION_INFO } from 'Constants/parseLocationInfo'

import {
  LocationAutocomplete,
  PhoneListMobile,
  ConfirmModal
} from 'Components/blocks'
import {
  DatePicker,
  ProfileBackground,
  FormContainer,
  Logo,
  Registered,
  SubTitle
} from 'Components/ui'

import { paymentsTransactions, root } from 'Routes/routesConstants'
import {
  Loading,
  ContentHeaderContainer,
  ContentHeader,
  ContnentHeaderBackButton,
  Form,
  Row,
  Input,
  InputShort,
  Frame,
  SaveButton,
  TitleWrap,
  DateLabel,
  DatePickerWrapper,
  PhoneWrapper,
  DivRelative,
  CountryCodeLabel,
  CardTypeWrapper,
  CardTypeImg,
  BgCreditCard,
  TitleCard,
  SimCard,
  UserCard,
  SimCardImg,
  IdCardNumber,
  ExpiryDateType,
  ExpiryDateCard
} from './styles'
import { getValidationSchema, cards } from './config'

class PaymentSetup extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isFirstFetch: true,
      isRequest: false,
      errors: null,
      submitCount: null,
      location: this.props.user.geolocation || '',
      attributeToVerify: null,
      minDate: new Date('1900-01-01'),
      maxDate: new Date(),
      isFocusPhonenumber: false,
      paymentStatus: 'none',
      isLinkingCard: false,
      isDeletingCard: false,
      isGettingCard: false,
      isShowConfirmDeleteModal: false
    }
  }

  componentDidMount() {
    const { onFetchUser } = this.props
    onFetchUser(() => {
      this.setState({ isFirstFetch: false })
    })

    window.addEventListener('storage', this.handleVerify)
  }

  componentWillUnmount() {
    window.removeEventListener('storage', this.handleVerify)
  }

  componentWillReceiveProps(nextProps) {
    const { user: nextUser } = nextProps
    const { user, onGetCard, cards } = this.props

    if (
      nextUser?.credit_card_id &&
      nextUser.credit_card !== user?.credit_card_id &&
      !cards[nextUser.credit_card_id] &&
      !this.state.isGettingCard
    ) {
      this.setState({ isGettingCard: true })
      onGetCard(nextUser.credit_card_id, error => {
        this.setState({ isGettingCard: false })
        if (error) {
          toast.error(error.message)
        }
      })
    }
  }

  handleVerify = e => {
    if (e.storageArea.verify === 'true') {
      this.setState({
        check: true
      })
      localStorage.setItem('verify', null)
    }
  }

  avatarInput = React.createRef()

  handleSubmit = data => {
    const { onLinkCard, onGetCard, onFetchUser, t } = this.props
    const { expiryDate } = data
    const card_expire_month = ('0' + (expiryDate.getMonth() + 1)).slice(-2)

    this.setState({ isLinkingCard: true })

    const body = {
      card_number: data.cardNumber,
      card_type: data.card_type,
      card_expire_month,
      card_expire_year: data.expiryDate.getFullYear().toString(),
      first_name: data.firstName,
      last_name: data.lastName,
      address: data.address,
      city: data.city,
      country_code: data.country,
      postal_code: data.zip,
      state: data.state,
      phone: `${data.code}${data.phone}`,
      credit_card_phone_code: data.code,
      csc: data.csc
    }

    onLinkCard(body, (error, response) => {
      this.setState({ isLinkingCard: false })
      if (error) {
        return toast.error(error.message)
      }

      if (response) {
        toast.success(t('common:success').toUpperCase())
        if (!this.state.isGettingCard) {
          this.setState({ isGettingCard: true })
          onGetCard(response.data.credit_card_id, error => {
            this.setState({ isGettingCard: false })
            if (error) {
              toast.error(error.message)
            }
          })
        }

        onFetchUser()
        this.setState({ paymentStatus: 'none' })
      }
    })
  }

  handleUpdate = data => {
    const { user, cards, onUpdateCard, t } = this.props
    const card = cards[user?.credit_card_id]
    const updateData = {}
    const {
      firstName,
      lastName,
      expiryDate,
      address,
      city,
      state,
      zip,
      country,
      phone,
      code
    } = data
    const card_expire_month = ('0' + (expiryDate.getMonth() + 1)).slice(-2)
    const card_expire_year = expiryDate.getFullYear().toString()

    if (firstName && firstName !== card.first_name) {
      updateData.first_name = firstName
    }

    if (lastName && lastName !== card.last_name) {
      updateData.last_name = lastName
    }

    if (card_expire_month && card_expire_month !== card.card_expire_month) {
      updateData.card_expire_month = card_expire_month
    }

    if (card_expire_year && card_expire_year !== card.card_expire_year) {
      updateData.card_expire_year = card_expire_year
    }

    if (address && address !== card.address) {
      updateData.address = address
    }

    if (city && city !== card.city) {
      updateData.city = city
    }

    if (state && state !== card.state) {
      updateData.state = state
    }

    if (zip && zip !== card.postal_code) {
      updateData.postal_code = zip
    }

    if (country && country !== card.country) {
      updateData.country_code = country
    }

    if (phone && phone !== card.phone) {
      updateData.phone = phone
    }

    if (code && code !== card.credit_card_phone_code) {
      updateData.credit_card_phone_code = code
    }

    if (isEmpty(updateData)) {
      return toast.warn('Not Change')
    }

    this.setState({ isLinkingCard: true })
    onUpdateCard(user?.credit_card_id, updateData, error => {
      this.setState({ isLinkingCard: false })
      if (error) {
        return toast.error(error.message)
      }
      toast.success(t('common:success').toUpperCase())
      return this.setState({ paymentStatus: 'none' })
    })
  }

  handleOpenAttributeVerifyModal = attributeToVerify => {
    const { onSendAttributeVerificationCode, t } = this.props
    onSendAttributeVerificationCode(attributeToVerify || null, error => {
      !error && toast.success(t('verifySended'))
    })
    this.setState({ attributeToVerify })
  }

  handleAddressChanged = (
    locationInfo = {},
    setFieldValue,
    fromMapClick = false
  ) => {
    const { address_components, geometry } = locationInfo
    PARSE_LOCATION_INFO.forEach(infoType => {
      const info = find(
        address_components,
        item => item.types.indexOf(infoType.searchForType) > -1
      )

      if (info) {
        if (infoType.putToField === 'country') {
          setFieldValue(
            infoType.putToField,
            info?.short_name || infoType.pickField(info)
          )
        } else {
          setFieldValue(infoType.putToField, infoType.pickField(info))
        }
      } else {
        setFieldValue(infoType.putToField, '')
      }
    })

    const location = get(geometry, 'location', {})

    if (!fromMapClick && location.lat && location.lng) {
      this.setState({
        location: `${location.lat()}/${location.lng()}`
      })
    } else if (!fromMapClick) {
      this.setState({
        location: ''
      })
    }

    let address = locationInfo.formatted_address

    const route = find(
      address_components,
      item => item.types.indexOf('route') > -1
    )

    if (route && route.long_name) {
      address = route.long_name

      const streetNumber = find(
        address_components,
        item => item.types.indexOf('street_number') > -1
      )

      if (streetNumber && streetNumber.long_name) {
        address += `, ${streetNumber.long_name}`
      }
    }
    setFieldValue('address', address)
  }

  handleCheckGeocode = () => {
    const { t } = this.props

    this.formik.setFieldTouched('address')

    setTimeout(() => {
      if (!this.state.location) toast.warn(t('errorAddressLocated'))
    }, 100)
  }

  renderForm = props => {
    const {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
      submitCount,
      isSubmitting,
      isValidating
    } = props

    const {
      paymentStatus,
      isLinkingCard
    } = this.state

    const { t, user = {} } = this.props

    const isFormTouched = submitCount > 0
    if (isSubmitting && !isValidating && Object.keys(errors).length !== 0) {
      window.scrollTo({ top: 30, behavior: 'smooth' })
    }

    const phoneNumberRegex = /^[0-9]*$/
    const phoneNumber = parsePhoneNumberFromString(
      `${values.code}${values.phone}` || ''
    )
    const isValid =
      phoneNumber &&
      phoneNumberRegex.test(values.phone) &&
      phoneNumber.isValid()

    const isEdit = paymentStatus === 'edit'

    const handleFocusPhonenumber = () => {
      if (!this.state.isFocusPhonenumber) {
        this.setState({ isFocusPhonenumber: true })
      }
    }

    const _setFieldValue = (field, value) => () => setFieldValue(field, value)

    const renderCard = () =>
      cards.map(({ id, type, icon }) => (
        <CardTypeWrapper
          onClick={_setFieldValue('card_type', type)}
          disabled={isEdit}
          key={id}
        >
          <CardTypeImg
            className={values.card_type === type ? 'active' : null}
            src={icon}
          />
        </CardTypeWrapper>
      ))

    return (
      <Form onSubmit={handleSubmit}>
        {/* INFO */}
        <Frame>
          <Row>
            <Input
              data-lpignore="true"
              errors={errors.firstName}
              id="firstName"
              label={t('fields.firstName.label')}
              mr="15px"
              touched={touched.firstName || isFormTouched}
              value={values.firstName}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <Input
              data-lpignore="true"
              errors={errors.lastName}
              label={t('fields.lastName.label')}
              name="lastName"
              touched={touched.lastName || isFormTouched}
              value={values.lastName || ''}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Row>
          <Row>
            <PhoneWrapper>
              <DivRelative>
                <CountryCodeLabel>
                  {t('fields.countryCode.label')}
                </CountryCodeLabel>
                <PhoneListMobile
                  code={values.code}
                  handleBlur={handleBlur}
                  isProfile
                  setFieldValue={setFieldValue}
                  value={values.code}
                  handleChange={handleFocusPhonenumber}
                />
              </DivRelative>
              <Input
                data-lpignore="true"
                errors={
                  errors.phone ||
                  (!isValid &&
                    this.state.isFocusPhonenumber &&
                    t('sign:WrongPhoneNumberFormat')) ||
                  ''
                }
                label={t('fields.phoneNumber.label')}
                marginLeft={100}
                name="phone"
                phone
                touched={
                  !user.phone_number_verified || touched.phone || isFormTouched
                }
                value={values.phone}
                onBlur={handleBlur}
                onChange={handleChange}
                onErrorClick={() =>
                  !errors.phone &&
                  isValid &&
                  this.handleOpenAttributeVerifyModal('phone_number')
                }
                onFocus={handleFocusPhonenumber}
              />
            </PhoneWrapper>
          </Row>
        </Frame>
        {/* ADD A CARD */}
        <Frame>
          <Row>
            <TitleWrap size={20}>{t('addACard')}</TitleWrap>
          </Row>
          <Row>
            <Input
              label={t('fields.debitOrCreditCardNumber.label')}
              name="cardNumber"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.cardNumber}
              errors={errors.cardNumber}
              touched={touched.cardNumber || isFormTouched}
              disabled={isEdit}
            />
            <DatePickerWrapper style={{ width: '23%' }}>
              <DateLabel>{t('fields.expiryDate.label')}</DateLabel>
              <DatePicker
                data-lpignore="true"
                errors={errors.expiryDate}
                dateFormat="MM/yyyy"
                handleChange={date => setFieldValue('expiryDate', date)}
                minDate={this.state.maxDate}
                mr="15px"
                name="expiryDate"
                showMonthDropdown
                showYearDropdown
                touched={touched.expiryDate || isFormTouched}
                value={values.expiryDate || null}
                onBlur={handleBlur}
              />
            </DatePickerWrapper>
            <InputShort
              label={t('fields.csc.label')}
              name="csc"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.csc}
              errors={errors.csc}
              touched={touched.csc || isFormTouched}
              disabled={isEdit}
            />
          </Row>
          <Row>{renderCard()}</Row>
          <Row>
            <LocationAutocomplete
              InputComponent={Input}
              inputProps={{
                errors: errors.address,
                label: t('fields.address.label'),
                mr: '15px',
                name: 'address',
                width: '100%',
                touched: touched.address || isFormTouched,
                onBlur: this.handleCheckGeocode
              }}
              ref={ref => (this.locationAutocomplete = ref)}
              value={values.address}
              onChange={text => setFieldValue('address', text)}
              onSelect={data => this.handleAddressChanged(data, setFieldValue)}
            />
            <Input
              autoComplete="none"
              data-lpignore="true"
              errors={errors.city}
              label={t('fields.city.label')}
              name="city"
              touched={touched.city || isFormTouched}
              value={values.city}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Row>
          <Row mt={10}>
            <Input
              autoComplete="none"
              data-lpignore="true"
              errors={errors.state}
              label={t('fields.state.label')}
              mr="15px"
              name="state"
              touched={touched.state || isFormTouched}
              value={values.state}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <InputShort
              autoComplete="none"
              data-lpignore="true"
              errors={errors.zip}
              label={t('fields.zipCode.label')}
              mr="15px"
              name="zip"
              touched={touched.zip || isFormTouched}
              value={values.zip}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <InputShort
              autoComplete="none"
              data-lpignore="true"
              errors={errors.country}
              label={t('fields.country.label')}
              mr="15px"
              name="country"
              touched={touched.country || isFormTouched}
              value={values.country}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Row>
          <Row style={{ justifyContent: 'center', marginTop: 40 }}>
            {isLinkingCard ? (
              <SaveButton disabled>
                <img alt="" src={icons.threeDotsLoader} />
              </SaveButton>
            ) : (
              <SaveButton type="submit">
                {isEdit ? 'Update Card' : t('linkCard')}
              </SaveButton>
            )}
          </Row>
        </Frame>
      </Form>
    )
  }

  renderPaymentInfo = () => {
    const { cards, user, t } = this.props
    const { isDeletingCard, isShowConfirmDeleteModal } = this.state
    const card =
      user?.credit_card_id && cards[user.credit_card_id]
        ? cards[user.credit_card_id]
        : {}
    const userCard = card?.first_name
      ? `${card.first_name} ${card.last_name}`
      : ''
    const expiryDate = card?.card_expire_month
      ? `${card.card_expire_month}/${card.card_expire_year}`
      : ''

    const handleEdit = () => this.setState({ paymentStatus: 'edit' })

    const handleDelete = () => {
      const { onDeleteCard, user, t } = this.props
      this.setState({ isDeletingCard: true })
      onDeleteCard(user?.credit_card_id, error => {
        this.setState({ isDeletingCard: false })
        if (error) {
          return toast.error(error.message)
        }
        toast.success(t('common:delete').toUpperCase())
        this.setState({ paymentStatus: 'deleted' })
      })
    }

    return (
      <>
        <Frame>
          <Row style={{ justifyContent: 'center' }}>
            <BgCreditCard>
              <TitleCard>Credit Card</TitleCard>
              <SimCard>
                <SimCardImg src={icons.simCard} />
              </SimCard>
              <ExpiryDateType>month/year</ExpiryDateType>
              <ExpiryDateCard>Valid Thru {expiryDate}</ExpiryDateCard>
              <IdCardNumber>{card?.card_number || ''}</IdCardNumber>
              <UserCard>{userCard}</UserCard>
            </BgCreditCard>
          </Row>
          <Row style={{ justifyContent: 'center' }}>
            <SaveButton onClick={handleEdit}>Edit</SaveButton>
            <SaveButton onClick={this.handleOpenConfirmDeleteModal}>
              {t('common:delete')}
            </SaveButton>
          </Row>
        </Frame>
        <ConfirmModal
          isVisible={isShowConfirmDeleteModal}
          isLoading={isDeletingCard}
          onSubmit={handleDelete}
          onCancel={this.handleCloseConfirmDeleteModal}
          title="Are you want to delete the card?"
          submitText="Confirm"
        />
      </>
    )
  }

  renderContent = () => {
    const { user, cards, t } = this.props
    const { paymentStatus } = this.state
    const code = user.country_code || '+1'
    const phones = Object.values(PHONES)

    if (user?.credit_card_id && paymentStatus === 'none') {
      return this.renderPaymentInfo()
    }
    if (paymentStatus === 'edit') {
      const card =
        user?.credit_card_id && cards[user.credit_card_id]
          ? cards[user.credit_card_id]
          : {}
      const expiryDate = card?.card_expire_month
        ? new Date(card.card_expire_year, card.card_expire_month - 1)
        : ''
      const phone =
        card?.billing_address?.phone?.slice(
          user?.credit_card_phone_code?.length || 2
        ) || ''

      const initialValues = {
        firstName: card?.first_name || '',
        lastName: card?.last_name || '',
        cardNumber: card?.card_number || '',
        expiryDate,
        csc: '',
        address: card?.billing_address?.line1 || '',
        city: card?.billing_address?.city || '',
        state: card?.billing_address?.state || '',
        zip: card?.billing_address?.postal_code || '',
        country: card?.billing_address?.country_code || '',
        phone,
        code: user?.credit_card_phone_code || phones[0].phone,
        card_type: card?.card_type || 'visa'
      }

      return (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          ref={ref => (this.formik = ref)}
          render={this.renderForm}
          validationSchema={getValidationSchema(true, t)}
          onSubmit={this.handleUpdate}
        />
      )
    }
    const initialValues = {
      firstName: '',
      lastName: '',
      cardNumber: '',
      expiryDate: '',
      csc: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      phone: '',
      code: code || phones[0].phone,
      card_type: 'visa'
    }

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        ref={ref => (this.formik = ref)}
        render={this.renderForm}
        validationSchema={getValidationSchema(false, t)}
        onSubmit={this.handleSubmit}
      />
    )
  }

  handleOpenConfirmDeleteModal = () =>
    this.setState({ isShowConfirmDeleteModal: true })

  handleCloseConfirmDeleteModal = () =>
    this.setState({ isShowConfirmDeleteModal: false })

  render() {
    const { t } = this.props
    const { isFirstFetch, isGettingCard } = this.state

    return (
      <ProfileBackground>
        <Link to={root}>
          <Logo>
            SpotJobs<Registered>&reg;</Registered>
          </Logo>
          <SubTitle>{t('signInHeadDescription')}</SubTitle>
        </Link>
        <FormContainer>
          <Fragment>
            <ContentHeaderContainer>
              <ContentHeader>{t('paymentSetup')}</ContentHeader>
              <Link to={paymentsTransactions}>
                <ContnentHeaderBackButton src={arrows.arrowBack} />
              </Link>
            </ContentHeaderContainer>
            {isFirstFetch || isGettingCard ? (
              <Loading>
                <img alt="" src={icons.preload} />
              </Loading>
            ) : (
              this.renderContent()
            )}
          </Fragment>
        </FormContainer>
      </ProfileBackground>
    )
  }
}

PaymentSetup.propTypes = {
  user: PropTypes.object,
  onUserVerify: PropTypes.func,
  onLinkCard: PropTypes.func,
  onGetCard: PropTypes.func,
  onUpdateCard: PropTypes.func,
  onDeleteCard: PropTypes.func,
  onFetchUser: PropTypes.func
}

export default PaymentSetup
