import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import * as routes from 'Routes/routesConstants'

import { arrows } from 'Assets/arrows'

import {
  ProfileBackground,
  FormContainer,
  Logo,
  Registered,
  SubTitle,
  TextBlock
} from 'Components/ui'

import { profile, root } from 'Routes/routesConstants'

import {
  ContentHeaderContainer,
  ContentHeader,
  ContnentHeaderBackButton,
  Section,
  Row,
  SectionCenter,
  Text,
  Button,
  Loading,
  Icon,
  Block,
  Description
} from './styles'
import { icons } from 'Assets/icons'
import { requestUrl } from './config'
import { toast } from 'react-toastify'

class PaymentsTransactions extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      isPaymentMaintenance: false
    }
  }

  componentDidMount() {
    const { history, location, onConnectPaypal, onFetchUser, t } = this.props

    this.setState({ isLoading: true })
    onFetchUser(() => this.setState({ isLoading: false }))

    const query = new URLSearchParams(location.search)
    const code = query.get('code')
    const error = query.get('error_uri')

    if (error) {
      history.push(routes.paymentsTransactions)
      toast.error(t('profilePage:connectWithPaypalFail'))
    }

    if (code && !error) {
      this.setState({ isLoading: true })
      onConnectPaypal({ code }, error => {
        onFetchUser(() => {
          history.push(routes.paymentsTransactions)
          this.setState({ isLoading: false })
        })
        if (error) {
          return toast.error(t('profilePage:connectWithPaypalFail'))
        }
        toast.success(t('profilePage:connectWithPaypalSuccess'))
      })
    }

    this.getPaymentMaintenance();
  }

  handleClick = () => {
    const { history } = this.props
    history.push(routes.withdrawalMoney)
  }

  handleConnectWithPaypal = () => window.open(requestUrl, '_self')

  renderButton = () => {
    const { user, t } = this.props

    if (this.state.isPaymentMaintenance) return null;

    if (!user?.paypal_account_withdraw) {
      return (
        <>
          <Text size={16} color="#aaa" width="80%" bottom={10}>
            {t('jobDetail:SPApplyTheJobTip')}
          </Text>
          <Button onClick={this.handleConnectWithPaypal}>
            <Icon src={icons.paypal} />
            {t('profilePage:connectWithPaypal')}
          </Button>
        </>
      )
    }

    return (
      <Block>
        <Text size={20}>{t('profilePage:connectedWithPaypal')}</Text>
        <Description>{t('profilePage:paypalAccount')}: {user?.paypal_account_withdraw}</Description>
      </Block>
    )
  }

  getPaymentMaintenance = async () => {
    const { onGetPaymentMaintenance } = this.props;

    try {
      const { payload } = await onGetPaymentMaintenance();
      this.setState({ isPaymentMaintenance: payload?.paymentMaintenance });
    } catch (error) {
      return;
    }
  }

  render() {
    const { t } = this.props

    return (
      <ProfileBackground>
        <Link to={root}>
          <Logo>
            SpotJobs<Registered>&reg;</Registered>
          </Logo>
          <SubTitle>{t('signInHeadDescription')}</SubTitle>
        </Link>
        <FormContainer>
          <ContentHeaderContainer>
            <ContentHeader>{t('paymentsTransactions')}</ContentHeader>
            <Link to={profile}>
              <ContnentHeaderBackButton src={arrows.arrowBack} />
            </Link>
          </ContentHeaderContainer>
          {this.state.isLoading ? (
            <Loading>
              <img alt="" src={icons.preload} />
            </Loading>
          ) : (
            <>
              <SectionCenter>
                {/* <Text>{t('balance')}</Text>
                <Text size={22}>
                  $ {user?.total_amount?.toFixed(2) || '0.00'}
                </Text>
                <Button onClick={this.handleClick}>{t('withdrawMoney')}</Button> */}
                {this.renderButton()}
              </SectionCenter>
              <Section>
                <Row>
                  <TextBlock text={t('earnings')} isTop to="/earnings" />
                </Row>
                <TextBlock text={t('payments')} to="/payments" />
                {/* <TextBlock text="Payment setup" to="/payment-setup" /> */}
                {/* <TextBlock text={t('withdrawals')} to="/withdrawals" /> */}
              </Section>
            </>
          )}
        </FormContainer>
      </ProfileBackground>
    )
  }
}

PaymentsTransactions.propTypes = {}

export default PaymentsTransactions
