import memoize from 'memoize-one'

export const getAddress = memoize(
  ({ streetAddress, state, city, country, zipCode }) => [
    `${streetAddress?.split(', ')?.slice(0, 2)?.join(', ') || ''} ${zipCode || ''}`,
    state || '',
    city || '',
    country || ''
  ]
)
