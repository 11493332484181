import { createStore, applyMiddleware } from 'redux'

import { composeWithDevTools } from 'redux-devtools-extension'

// persistor
import { persistStore } from 'redux-persist'

// middlewares
import thunk from 'redux-thunk'
import apiMiddlware from './middleware/api'
import socketMiddlware from './middleware/socket'

// sagas
import createSagaMiddleware from 'redux-saga'
import runSagas from './middleware/sagas'

import rootReducer from './reducers'

function createMyStore() {
  const sagaMiddleware = createSagaMiddleware()
  const middlewares = [socketMiddlware, apiMiddlware, thunk, sagaMiddleware]

  const enhancer = composeWithDevTools(applyMiddleware(...middlewares))

  const store = createStore(rootReducer, enhancer)

  const persistor = persistStore(store)

  runSagas(sagaMiddleware)

  return { store, persistor }
}

export const { store, persistor } = createMyStore()
