import React from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'
import { URLPreviewBlock } from 'Components/blocks'

import { Wrapper, VideosWrapper } from './styles'

const Component = props => {
  const { t, data, title } = props

  return (
    <Wrapper>
      <h3>{t(`${title}`, { count: data.length })}</h3>
      <VideosWrapper>
        {data &&
          data.map((item, key) => (
            <URLPreviewBlock
              autoPlay={false}
              key={key}
              target="_blank"
              url={item}
            />
          ))}
      </VideosWrapper>
    </Wrapper>
  )
}

Component.propTypes = {
  data: PropTypes.array,
  t: PropTypes.func,
  title: PropTypes.string
}

export const VideosAndWebsitesLinks = withNamespaces('serviceProvider')(
  Component
)
