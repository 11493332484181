import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  border-bottom: 1px solid #ddd;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
`

export const RowBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
`

export const Text = styled.span`
  ${({flex}) => flex ? {
    paddingLeft: 15,
    flex: 1,
  } : { paddingRight: 15 }};
  font-weight: ${p => (p.isBold ? 'bold' : 'normal')};
  font-size: ${p => (p.isBold ? '18px' : '16px')};
`

export const LinkText = styled(Link)`
  color: ${({ theme }) => theme.colors.blue};;
`
