import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { fontFamily, sizes, fontSize } from 'Constants'

export const Head = styled.header`
  background: rgba(10, 148, 241, 1);
  background: -moz-linear-gradient(
    left,
    rgba(10, 148, 241, 1) 0%,
    rgba(10, 148, 241, 1) 56%,
    rgba(100, 195, 255, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(10, 148, 241, 1)),
    color-stop(56%, rgba(10, 148, 241, 1)),
    color-stop(100%, rgba(100, 195, 255, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(10, 148, 241, 1) 0%,
    rgba(10, 148, 241, 1) 56%,
    rgba(100, 195, 255, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(10, 148, 241, 1) 0%,
    rgba(10, 148, 241, 1) 56%,
    rgba(100, 195, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(10, 148, 241, 1) 0%,
    rgba(10, 148, 241, 1) 56%,
    rgba(100, 195, 255, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(10, 148, 241, 1) 0%,
    rgba(10, 148, 241, 1) 56%,
    rgba(100, 195, 255, 1) 100%
  );
  @media (max-width: ${sizes.tablet}px) {
    position: fixed;
    max-width: 100%;
    top: 0;
    z-index: 999;
  }
`

export const Wrapper = styled.div`
  width: 70rem;
  max-width: 90%;
  margin: 0 auto;
  @media (max-width: ${sizes.phone}px) {
    max-width: 80%;
  }
`

export const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const Div = styled.div`
  width: 100%;
`

export const Wrap = styled.div`
  display: flex;
  align-items: ${props => (props.view ? 'center' : 'flex-end')};
  position: relative;
  height: ${props => (props.view ? '75px' : '120px')};
  @media (max-width: ${sizes.tablet}px) {
    height: 75px;
    align-items: center;
  }
`

export const AbsoluteWrap = styled.div`
  display: flex;
  align-items: ${props => (props.view ? 'center' : 'flex-end')};
  position: relative;
  height: ${props => (props.view ? '75px' : '120px')};
  position: relative;
  @media (max-width: ${sizes.tablet}px) {
    height: 75px;
    align-items: center;
  }
`

export const AdsBar = styled.div`
  position: absolute;
  bottom: -30px;
  right: 0px;
  height: 30px;
  background: #BBBBBB;
  font-weight: bold;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  vertical-align: middle;
  padding: 0 10px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LeftPart = styled.div`
  display: flex;
  align-items: center;
`

export const Logo = styled(Link)`
  color: ${props => props.theme.colors.defaultFont};
  font-size: ${fontSize.h1};
  letter-spacing: 0.01em;
  font-weight: 600;
  text-decoration: none;
`

export const Registered = styled.sup`
  color: ${props => props.theme.colors.defaultFont};
  font-size: ${fontSize.h4};
  font-family: ${fontFamily};
`

export const RightPart = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: calc(100% + 20px);
  left: -20px;
`

export const SignInLink = styled(Link)`
  color: ${props => props.theme.colors.defaultFont};
  font-size: ${fontSize.default};
  font-family: ${fontFamily};
  text-decoration: none;
  font-weight: 900;
`

export const RightPartDoted = styled.button`
  display: flex;
  align-items: center;
  width: 60px;
  height: 60px;
  justify-content: center;
  font-size: ${fontSize.h1};
  font-family: ${fontFamily};
  border-radius: 10%;
  background: ${props => props.theme.colors.joinUs};
  color: ${props => props.theme.colors.joinButtonColor};
  border: 2px solid #ffffff;
  @media (min-width: ${sizes.tablet}px) {
    display: none;
  }
  @media (max-width: ${sizes.tablet}px) {
    width: 40px;
    height: 40px;
    font-size: ${fontSize.h4};
    outline: none;
  }
`

export const JoinUsNav = styled(Link)`
  color: ${props => props.theme.colors.defaultFont};
  font-size: ${fontSize.default};
  font-family: ${fontFamily};
  font-weight: 900;
  text-transform: uppercase;
  height: 50px;
  min-width: 200px;
  border-radius: 50px;
  background: #00d65a;
  border: 2px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-align: center;
  & > span {
    padding: 0 15px;
  }
  &:hover {
    opacity: 0.9;
  }
  @media (max-width: ${sizes.desktop}px) {
    width: 180px;
  }
`

export const SearchLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const NavLine = styled.div`
  flex-basis: 80%;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: ${sizes.desktop}px) {
    padding-top: 15px;
  }
`

export const Pts = styled.p`
  color: ${props => props.theme.colors.defaultFont};
  padding: 8px;
  font-weight: 800;
`

// IconGroup

export const IconGroup = styled.div`
  position: relative;
  left: -4em;
`

export const MediaDesktop = styled.div`
  @media (max-width: ${sizes.desktop}px) {
    display: none;
  }
`

export const MediaTablet = styled.div`
  @media (max-width: ${sizes.tablet}px) {
    display: none;
  }
`

export const MediaPhone = styled.div`
  @media (max-width: ${sizes.phone}px) {
    display: none;
  }
`

export const ProfileLink = styled(Link)`
  text-decoration: none;
`

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`
