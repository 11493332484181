import styled from 'styled-components'
import { fontSize, fontFamily } from 'Constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'

export const Container = styled.div`
  position: relative;
  align-self: center;
  display: flex;
`

export const Popup = styled.div`
  position: absolute;
  bottom: ${p => (p.isActive ? '-75px' : '-100px')};
  transition: all 0.3s;
  right: -16px;
  border-radius: 5px;
  box-shadow: ${p => p.theme.colors.boxShadow};
  opacity: ${p => (p.isActive ? 1 : 0)};
  z-index: ${p => (p.isActive ? 1 : -1)};
  display: flex;
  background-color: #fff;
  flex-direction: column;
  overflow: hidden;
`

export const PopupButton = styled.div`
  padding: 7px 15px;
  text-align: center;
  font-size: ${fontSize.default};
  font-family: ${fontFamily};
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
  &:hover {
    background-color: ${p => p.theme.colors.header};
    color: #fff;
  }
`

export const MoreIcon = styled(FontAwesomeIcon).attrs({
  icon: faEllipsisV,
  size: 'lg'
})`
  cursor: pointer;
`
