import styled from 'styled-components'
import { fontFamily, sizes, fontSize } from 'Constants'

/* Messages
/* ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## */

export const SvgDiv = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.defaultFont};
  position: relative;
  & > svg {
    height: auto;
    width: 25px !important;
  }
  @media (max-width: ${sizes.desktop}px) {
    padding-left: 1em;
  }
  @media (max-width: ${sizes.tablet}px) {
    transform: scale(1.5);
    top: 15px;
    left: -25px;
    border: 1px solid #ccc;
    border-radius: 50%;
    padding: 15px;
  }
`

export const Active = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.notification};
  top: 5px;
  right: 4px;
`

export const NavigationWrapper = styled.div`
  & > ul {
    list-style-type: none;
    display: flex;
    padding: 0;
    margin: 0;
    font-size: ${fontSize.small};
    font-family: ${fontFamily};
    & > li {
      margin: 0 10px;
      & > a {
        text-decoration: none;
        color: ${props => props.theme.colors.defaultFont};
      }
    }
  }
`
