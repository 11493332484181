import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'
import { withNamespaces } from 'react-i18next'

import { getEarnings } from 'Redux/actions/paymentsTransactions'

import { makeSelectEarnings } from 'Redux/selectors/paymentsTransactions'

import Component from './Earnings'

const selector = createStructuredSelector({
  earnings: makeSelectEarnings
})

const actions = {
  onGetEarnings: getEarnings
}

export default compose(
  withNamespaces('profilePage'),
  connect(selector, actions)
)(Component)
