import styled from 'styled-components'
import { fontFamily, fontSize } from 'Constants'

export const FilterWrapper = styled.button`
  margin-top: 25px;
  text-transform: uppercase;
  width: 185px;
  text-align: center;
  font-size: ${fontSize.default};
  font-family: ${fontFamily};
  height: 50px;
  border: 2px solid ${props => props.theme.colors.defaultBlue};
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.defaultBlue};
  font-weight: 700;
  cursor: pointer;
  background-color: transparent;
  outline: none;
`
