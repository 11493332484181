export const rateSections = [
  {
    value: 1,
    text: 1,
    color: 'red'
  },
  {
    value: 2,
    text: 2,
    color: 'orange'
  },
  {
    value: 3,
    text: 3,
    color: 'yellow'
  },
  {
    value: 4,
    text: 4,
    color: '#58d68d'
  },
  {
    value: 5,
    text: 5,
    color: 'green'
  }
]
