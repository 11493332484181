import React, { Fragment } from 'react'
import {
  findJobs,
  jobPosts,
  findHelp,
  profile,
  notifications,
  termsCond,
  subscriptions,
  settings,
  chats,
  myRequests,
  myJobs,
  myFavourites,
  privacy
} from 'Routes/routesConstants'
import { TextWrapper, ContactUsWrapper, styles } from './styles'

export const footerNav = [
  {
    id: 1,
    href: findJobs,
    trans: 'homePage:findJobs',
    tags: ['auth', 'unauth']
  },
  {
    id: 2,
    href: jobPosts,
    trans: 'landingPage:postJobs',
    tags: ['auth']
  },
  {
    id: 3,
    href: findHelp,
    trans: 'homePage:findHelp',
    tags: ['auth']
  },
  {
    id: 4,
    href: myJobs,
    trans: 'landingPage:myJobs',
    tags: ['auth']
  },
  {
    id: 5,
    href: myFavourites,
    trans: 'landingPage:favorites',
    tags: ['auth']
  },
  {
    id: 6,
    href: notifications,
    trans: 'homePage:Notifications',
    tags: ['auth']
  },
  {
    id: 7,
    href: myRequests,
    trans: 'homePage:myRequests',
    tags: ['auth']
  },
  {
    id: 8,
    href: chats,
    trans: 'homePage:chats',
    tags: ['auth']
  },
  {
    id: 9,
    href: '#',
    trans: 'homePage:trackNow',
    tags: ['auth']
  },

  {
    id: 10,
    href: subscriptions,
    trans: 'homePage:subscription',
    tags: ['auth']
  },
  {
    id: 11,
    href: profile,
    trans: 'homePage:myAccount',
    tags: ['auth']
  },
  {
    id: 12,
    href: settings,
    trans: 'homePage:settings',
    tags: ['auth']
  },
  {
    id: 13,
    href: termsCond,
    trans: 'landingPage:termsCond',
    tags: ['auth', 'unauth']
  },
  {
    id: 14,
    href: privacy,
    trans: 'landingPage:termsPrivacy',
    tags: ['auth', 'unauth']
  },
  {
    id: 15,
    href: '#',
    textKey: 'faqs',
    trans: 'landingPage:faqs',
    tags: ['auth', 'unauth']
  },
  {
    id: 16,
    href: '#',
    textKey: 'aboutUs',
    trans: 'aboutUs',
    tags: ['auth', 'unauth']
  },
  {
    id: 17,
    href: '#',
    textKey: 'contactUs',
    trans: 'contactUs',
    tags: ['auth', 'unauth']
  }
]

export const getText = (t, key) => {
  switch (key) {
    case 'aboutUs':
      return (
        <TextWrapper width={400}>
          <p>{t('common:aboutUsText')}</p>
        </TextWrapper>
      )
    case 'faqs':
      const text = [
        t('common:isFree'),
        t('common:becomePremium'),
        t('common:availableArea'),
        t('common:howMutch'),
        t('common:canPostJob'),
        t('common:whoBecomeProvider'),
        t('common:howDoIEarn'),
        t('common:extraPoints'),
        // t('common:takeCommission'),
        // t('common:getPaid'),
        t('common:unsubscribeEmails'),
        t('common:backgroundCheck'),
        t('common:providerWithoutJoining'),
        t('common:howMakeMoney')
      ]

      return (
        <TextWrapper width={900}>
          {text.map(el => (
            <Fragment key={el.split(' ')[8]}>
              <span>
                <b style={styles.bold}>{el.split('?')[0]}?"</b>
                {el
                  .split('?')
                  .slice(1)
                  .join('?')}
              </span>
              <br />
              <br />
            </Fragment>
          ))}
        </TextWrapper>
      )
    case 'contactUs':
      return (
        <ContactUsWrapper>
          <p>Atomx llc / SpotJobs</p>
          <p>P.O Box 250004 Plano</p>
          <p>TX 75025-0004-USA</p>
          <p>E-mail: contact@atomxllc.com</p>
          <p>support@spotjobsapp.com</p>
        </ContactUsWrapper>
      )
    default:
      return ''
  }
}
