import styled from 'styled-components'
import { fontSize } from 'Constants'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const SInput = styled.input`
  border: solid 1px #d0d0d0;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  padding: 6px 5px;
  outline: none;
  transition: box-shadow 0.3s;
  font-size: ${fontSize.h4};
  &:focus {
    box-shadow: 0 0 10px silver;
  }
`

export const Label = styled.span`
  font-size: ${fontSize.default};
  margin-bottom: 3px;
`

export const Error = styled.span`
  font-size: ${fontSize.small};
  color: ${p => p.theme.colors.iconRed};
  opacity: ${p => (p.isVisible ? 1 : 0)};
`
