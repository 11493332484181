import React from 'react'
import { AddLinkButton } from '../AddLinkButton'
import URLPreview from '../URLPreviewBlock'
import { PreviewContainer, PreviewRow } from './styles'

export const LinksUploader = ({
  values,
  maxItemsCount,
  onMaxItemsOverflow,
  onAddBtnClick,
  onDeleteClick,
  type
}) => {
  const renderLinksPreview = values => {
    if (values) {
      return values.map((url, index) => {
        const handleDeleteClick = () => onDeleteClick(index, type)

        return (
          <URLPreview
            autoPlay={false}
            disabled={index >= maxItemsCount}
            handleDelete={handleDeleteClick}
            isShowErrorMessage
            key={index}
            url={url}
          />
        )
      })
    }

    return null
  }

  const handleAddClick = () => {
    if (values && maxItemsCount && values.length >= maxItemsCount) {
      onMaxItemsOverflow()
    } else {
      onAddBtnClick(type)
    }
  }

  return (
    <PreviewContainer>
      <AddLinkButton type="button" onClick={handleAddClick} />
      <PreviewRow>{renderLinksPreview(values)}</PreviewRow>
    </PreviewContainer>
  )
}
