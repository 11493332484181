import React from 'react'
import PropTypes from 'prop-types'
import { icons } from 'Assets/icons'

import { withRouter } from 'react-router-dom'

import {
  Wrapper,
  Avatar,
  Name,
  LastMessage,
  Header,
  Time,
  Content,
  JobTitle,
  IconActive,
} from './style'

const ChatPuck = ({
  avatarUrl,
  username,
  isActive,
  name,
  date,
  text,
  type,
  onClick,
  isChatBlocked,
  jobTitle,
  isRead,
}) => (
    <Wrapper isActive={isActive} onClick={onClick}>
      <Avatar src={avatarUrl || icons.avatar} type="avatar" userId={username} />
      <Content>
        <JobTitle isChatBlocked={isChatBlocked} title={jobTitle}>{jobTitle}</JobTitle>
        <Header>
          <Name isChatBlocked={isChatBlocked}>{name}</Name>
          <Time isChatBlocked={isChatBlocked}>
            {date}
          </Time>
        </Header>
        {type !== 'voice_message' && (
          <LastMessage isChatBlocked={isChatBlocked} label={text}>
            {text}
          </LastMessage>
        )}
      </Content>
      {isRead ? null : <IconActive />}
    </Wrapper>
  )

ChatPuck.propTypes = {
  avatarUrl: PropTypes.string,
  jobTitle: PropTypes.string,
  date: PropTypes.any,
  name: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  username: PropTypes.string,
  onClick: PropTypes.func
}
ChatPuck.defaultProps = {
  name: 'Text T',
  path: '#'
}

export default withRouter(ChatPuck)
