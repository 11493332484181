import React from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'

import { Modal } from 'Components/ui'

import {
  Container,
  Header,
  Title,
  Buttons,
  SubmitButton,
  CancelButton,
  Content,
  Block,
  Row,
  Text,
  Description,
} from './style'

const PaymentIdentityModal = ({
  isLoading,
  isVisible,
  onCancel,
  onSubmit,
  t
}) => {
  return (
    <Modal
      isShow={isVisible}
      zIndex={1010}
      onRequestClose={onCancel}
      width="600px"
    >
      <Container>
        <Header>
          <Title>{t('profilePage:IDPayment')}</Title>
        </Header>
        <Content>
          <Row>
            <Description>{t('profilePage:identificationDescription')}</Description>
          </Row>
          <Block>
            <Row>
              <Text flex={1}>{t('profilePage:idVerification')}</Text>
              <Text>$ 2.99 USD</Text>
            </Row>
            <Row>
              <Text>{t('profilePage:quantity')} 1</Text>
            </Row>
          </Block>
        </Content>
        <Buttons>
          <CancelButton onClick={onCancel}>{t('jobPost:cancel')}</CancelButton>
          <SubmitButton disabled={isLoading} onClick={onSubmit}>
            {t('profilePage:submit')}
          </SubmitButton>
        </Buttons>
      </Container>
    </Modal>
  )
}

PaymentIdentityModal.propTypes = {
  isLoading: PropTypes.bool,
  isVisible: PropTypes.bool,
  t: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default withNamespaces(['jobDetail', 'findJobPage', 'jobPost'])(
  PaymentIdentityModal
)
