import React from 'react'
import PropTypes from 'prop-types'

import { SettingsSection } from 'Components/ui'

import { Container } from './style'

const Notifications = ({ title, value, onClick }) => {
  return (
    <Container>
      <SettingsSection.Title>{title}</SettingsSection.Title>
      <SettingsSection.Switch value={value} onClick={onClick} />
    </Container>
  )
}

Notifications.propTypes = {
  t: PropTypes.func,
  title: PropTypes.string,
  value: PropTypes.bool,
  onClick: PropTypes.func
}

export { Notifications }
