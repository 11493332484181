import React from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'
import { toast } from 'react-toastify'
import Modal from 'react-modal'
import { Formik } from 'formik'

import {
  modalContentStyle,
  modalOverlayStyle,
  Footer,
  Btn,
  Input,
  FormContainer,
  Description,
  ResendButton,
  Text
} from './styles'

import { validationSchema } from './config'

const AttributeVerifyModal = withNamespaces('profilePage')(
  ({ attribute, onRequestClose, onCancel, onSubmit, onResendClick, t }) => {
    const handleCancel = () => onCancel()

    const handleFormSubmit = data => {
      onSubmit(data.code)
    }

    const renderForm = ({
      handleChange,
      handleBlur,
      values: valuesList,
      touched: touchedList,
      errors: errorsList,
      handleSubmit,
      submitCount
    }) => {
      const errors = errorsList || {}
      const values = valuesList || {}
      const touched = touchedList || {}
      const isFormTouched = submitCount > 0
      return (
        <FormContainer>
          <Description>
            {t(`verifyAttributeModal.description.${attribute}`)}
          </Description>
          <Input
            errors={errors.code}
            label={t('verifyAttributeModal.codeFieldLabel')}
            mr="15px"
            name="code"
            touched={touched.code || isFormTouched}
            value={values.code || ''}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <Footer>
            <Btn
              onClick={() => {
                values.code ? handleSubmit() : toast.error(t('codeRequired'))
              }}
            >
              {t('verifyAttributeModal.submitButtonLabel')}
            </Btn>
            <Text>{t('verifyAttributeModal.resendSection.question')}</Text>
            <ResendButton onClick={() => onResendClick()}>
              {t('verifyAttributeModal.resendSection.buttonLabel')}
            </ResendButton>
          </Footer>
        </FormContainer>
      )
    }

    return (
      <Modal
        appElement={document.getElementById('root')}
        className="modal-form"
        isOpen={!!attribute}
        style={{
          overlay: modalOverlayStyle,
          content: modalContentStyle
        }}
        onRequestClose={onRequestClose}
      >
        <Formik
          className="form-link"
          render={renderForm}
          validationSchema={validationSchema}
          onReset={handleCancel}
          onSubmit={handleFormSubmit}
        />
      </Modal>
    )
  }
)

AttributeVerifyModal.propTypes = {
  attribute: PropTypes.oneOfType([PropTypes.string, val => val === null]),
  t: PropTypes.func,
  onResendClick: PropTypes.func,
  onSubmit: PropTypes.func
}

export { AttributeVerifyModal }
