import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { withNamespaces } from 'react-i18next'

import { getProfile } from 'Redux/selectors/user'
import { getNotifications } from 'Redux/selectors/notifications'
import { withTracking } from 'Components/hoc'
import { getGeolocation } from 'Redux/selectors/track'

import {
  markNotificationsAsRead,
  loadNotifications
} from 'Redux/actions/notifications'

import Component from './Notifications'

const selector = createStructuredSelector({
  user: getProfile,
  notifications: getNotifications,
  geoLocation: getGeolocation
})

const actions = {
  onMarkAsRead: markNotificationsAsRead,
  onLoadNotifications: loadNotifications
}

export default compose(
  withTracking,
  withNamespaces('notifications'),
  connect(
    selector,
    actions
  )
)(Component)
