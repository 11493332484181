import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import { debounce } from 'lodash'
import {
  BurgerMenu,
  Header,
  Ad,
  Notification,
  Footer,
  ProgressBar,
  ListAd
} from 'Components/blocks'
import {
  PageWrapper,
  HalfWidthWrapper,
  SmallLoader,
  PreloadImg,
  LoadMoreWrapper
} from 'Components/ui'
import {
  NotificationWrapper,
  MarkAsRead,
  Section,
  SmallLoaderWrapper,
  StyledCardWrapper
} from './styles'
import { icons } from 'Assets/icons'

class Notifications extends PureComponent {
  state = {
    isLoadMarkAs: false,
    isRequest: false,
    page: 0
  }

  componentDidMount() {
    window.onscroll = debounce(() => {
      const scroll = window.innerHeight + document.documentElement.scrollTop
      const position = document.documentElement.offsetHeight - 500

      const { page, isRequest } = this.state
      const {
        notifications: { currentPage, isListEnd }
      } = this.props

      if (isRequest || isListEnd || currentPage !== page) return

      if (scroll > position) {
        this.setState(
          { page: this.state.page + 1 },
          this.handleLoadNotifications
        )
      }
    }, 10)
    this.handleLoadNotifications()
  }

  getItems = memoize((data = []) =>
    data.map((el, key) => (
      <NotificationWrapper key={el._id}>
        <Notification
          date={el.createdAt}
          isRead={el.read}
          job={el.data.job}
          jobId={el.data.jobId}
          reason={el.data.reason}
          requestId={el._id}
          type={el.notificationType}
          user={el.data.user}
          userId={el.data.userId}
          jobDoer={el.data.job?.doer}
          currentUserId={el.userId}
          jobTitleUnavailable={el.data?.jobTitle}
          userCancelId={el.data?.userCancel?.userId}
          userCancelName={el.data?.userCancel?.shortName}
          onMarkAsRead={this.handleMarkAsReadSingleItem}
          onOpenTrack={this.handleOpenTrack}
          isAuthor={this.props.user?.username === el.data?.job?.author || this.props.user?._id === el.data?.job?.author}
          netMoneyWithdraw={el?.data?.netMoneyWithdraw}
        />
        <ListAd data={data} index={key} />
      </NotificationWrapper>
    ))
  )

  handleLoadNotifications = () => {
    const { onLoadNotifications } = this.props

    this.setState({ isRequest: true }, () => {
      onLoadNotifications({ page_number: this.state.page }, () =>
        this.setState({ isRequest: false })
      )
    })
  }

  handleMarkAsRead = () => {
    const { onMarkAsRead, notifications } = this.props

    this.setState({ isLoadMarkAs: true }, () => {
      const unreadedIds = notifications.list
        .filter(el => !el.read)
        .map(el => el._id)

      onMarkAsRead(unreadedIds, () => {
        this.setState({ isLoadMarkAs: false })
      })
    })
  }

  handleMarkAsReadSingleItem = id => {
    const { onMarkAsRead } = this.props

    onMarkAsRead([id])
  }

  handleOpenTrack = job => {
    const { onStartTracking, geoLocation } = this.props

    onStartTracking(job, geoLocation)
  }

  showLoading = () => (
    <LoadMoreWrapper>
      <PreloadImg
        alt="loading"
        className="loader"
        isAds={true}
        src={icons.preload}
      />
    </LoadMoreWrapper>
  )

  render() {
    const {
      t,
      user,
      notifications: { list }
    } = this.props
    const { isLoadMarkAs, isRequest } = this.state

    const items = this.getItems(list)
    const loading = this.state.isRequest ? this.showLoading() : null
    return (
      <PageWrapper>
        <ProgressBar percent={isRequest ? 1 : 100} />
        <Header postJob={true} />
        <BurgerMenu user={user} />
        <Section>
          <StyledCardWrapper>
            <Ad />
          </StyledCardWrapper>
          <HalfWidthWrapper>
            <h2>{t('notifications')}</h2>
            <MarkAsRead onClick={this.handleMarkAsRead}>
              {isLoadMarkAs && (
                <SmallLoaderWrapper>
                  <SmallLoader active />
                </SmallLoaderWrapper>
              )}{' '}
              {t('markAsRead')}
            </MarkAsRead>
            {items}
            {loading}
          </HalfWidthWrapper>
          <StyledCardWrapper>
            <Ad />
          </StyledCardWrapper>
        </Section>
        <Footer black={true} isAuth={user} />
      </PageWrapper>
    )
  }
}

export default Notifications

Notifications.propTypes = {
  notifications: PropTypes.shape({
    list: PropTypes.array,
    isListEnd: PropTypes.bool,
    currentPage: PropTypes.number
  }),
  location: PropTypes.object,
  match: PropTypes.object,
  t: PropTypes.func,
  user: PropTypes.object,
  onLoadNotifications: PropTypes.func,
  onMarkAsRead: PropTypes.func,
  onOpenTrack: PropTypes.func
}
