import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { withRouter } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'

import { getProfile } from 'Redux/selectors/user'

import Component from './JobCard'

const actions = {}

const selectors = createStructuredSelector({
  user: getProfile
})

export default compose(
  withRouter,
  withNamespaces([
    'findJobPage',
    'LeftoverOnes',
    'jobDetail',
    'myJobsDetail',
    'landingPage'
  ]),
  connect(
    selectors,
    actions
  )
)(Component)
