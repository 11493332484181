import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { withNamespaces } from 'react-i18next'
import { withRouter } from 'react-router-dom'

import { updateUser } from 'Redux/actions/user'
import { getProfile } from 'Redux/selectors/user'
import { getNotifications } from 'Redux/selectors/notifications'
import { getPoints } from 'Redux/selectors/subscriptions'
import { getIsUnreadMessage } from 'Redux/selectors/chats'

import Component from './Header'

const selector = createStructuredSelector({
  user: getProfile,
  notifications: getNotifications,
  points: getPoints,
  isUnreadMessage: getIsUnreadMessage
})

const actions = {
  onUpdateProfile: updateUser
}

export default compose(
  withNamespaces(['landingPage', 'subscriptionScreen']),
  withRouter,
  connect(
    selector,
    actions
  )
)(Component)
