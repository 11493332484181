import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'
import { icons } from 'Assets/icons'

import {
  BlockChatButton,
  Header,
  JobInfo,
  JobInfoText,
  JobTitle,
  ProfileAvatar,
  ProfileName,
  RightBlock,
  UserInfo,
  StyledReactLoading
} from './style'

const ChatHeader = ({
  username,
  isChatBlocked,
  showButton,
  islockChatRequest,
  avatarUrl,
  profileName,
  jobTitle,
  pathJob,
  pathProfile,
  t,
  onLockChat,
  className
}) => (
  <Header className={className}>
    <UserInfo>
      <ProfileAvatar
        src={avatarUrl || icons.avatar}
        type="avatar"
        userId={username}
      />
      <Link to={pathProfile || '#'}>
        <ProfileName>{profileName}</ProfileName>
      </Link>
    </UserInfo>
    <JobInfo>
      {jobTitle && (
        <>
          <JobInfoText>{t('jobTitle')}: </JobInfoText>
          <Link to={pathJob || '#'}>
            <JobTitle>{jobTitle}</JobTitle>
          </Link>
        </>
      )}
    </JobInfo>
    <RightBlock>
      {
        showButton &&
        (!islockChatRequest ? (
          <BlockChatButton
            isChatBlocked={isChatBlocked}
            islockChatRequest={islockChatRequest}
            onClick={onLockChat}
          />
        ) : (
          <StyledReactLoading />
        ))
      }
    </RightBlock>
  </Header>
)

ChatHeader.propTypes = {
  avatarUrl: PropTypes.string,
  className: PropTypes.string,
  jobTitle: PropTypes.string,
  path: PropTypes.string,
  profileName: PropTypes.string,
  t: PropTypes.func,
  username: PropTypes.string,
  onLockChat: PropTypes.func
}

ChatHeader.defaultProps = {
  profileName: 'Michael Y.',
  jobTitle: 'Need a Cook'
}

export default withNamespaces('jobPost')(ChatHeader)
