export const firstRow = [
  {
    title: 'needExtraCash',
    image: 'images/need_extra_cash.png',
    text: 'becomeAProvider'
  },
  {
    title: 'beAServiceProvider',
    image: 'images/be_a_service_provider.png',
    text: 'setUpProfile'
  },
  {
    title: 'aplyForJobs',
    image: 'images/apply_for_jobs.png',
    text: 'buildAReputaion'
  },
  {
    title: 'setYourSchedule',
    image: 'images/set_your_schedule.png',
    text: 'bookJobsAt'
  }
]

export const secondRow = [
  {
    title: 'needHelp',
    image: 'images/need_help.png',
    text: 'setYourBudget'
  },
  {
    title: 'WebPaymentGetPaidDirectly',
    image: 'images/set_your_budget.png',
    text: 'WebPaymentGetPaidDirectlyDescription'
  },
  {
    title: 'idVerification',
    image: 'images/id_verification.png',
    text: 'WebIDAIbasedVerification'
  },
  {
    title: 'chatAvailable',
    image: 'images/chatting_available.png',
    text: 'clarifyEverything'
  }
]

export const free = [
  {
    title: 'freeForAll',
    image: 'images/free_for_all.png',
    text: 'appForFree'
  },
  {
    title: 'earnPoints',
    image: 'images/earn_points.png',
    text: 'earnMembership'
  },
  {
    title: 'liveTrackingAvail',
    image: 'images/live_tracking_available.png',
    text: 'fallowTheService'
  },
  {
    title: 'recieveJobAlerts',
    image: 'images/recieve_job_alerts.png',
    text: 'getNotified'
  }
]

export const donwloadText = 'downloadText'
