import React from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'
import JumbotronButtonBlock from './JumbotronButtonBlock'
import {
  JumbotronWrapper,
  MainTitle,
  WidthLimiter,
  VerticalFlip,
  VerticalFlipSpan,
  Wrapper,
  BoldSubTitle
} from './styles'

const Jumbotron = ({ t, onShowVideo }) => (
  <JumbotronWrapper>
    <Wrapper>
      <WidthLimiter>
        <MainTitle>{t('alternativeWay')}</MainTitle>
        <MainTitle>{t('getTheJob')}</MainTitle>
        <BoldSubTitle>{t('paidService')}</BoldSubTitle>
        <JumbotronButtonBlock onShowVideo={onShowVideo} />
        <VerticalFlip>
          <VerticalFlipSpan blue>{t('earnExtraCash')}</VerticalFlipSpan>
          <VerticalFlipSpan pink>{t('findOddJobs')}</VerticalFlipSpan>
          <VerticalFlipSpan purple>{t('postAJob')}</VerticalFlipSpan>
          <VerticalFlipSpan blue>{t('usePaidServices')}</VerticalFlipSpan>
          {/* <VerticalFlipSpan pink>{t('noCommission')}</VerticalFlipSpan> */}
        </VerticalFlip>
      </WidthLimiter>
    </Wrapper>
  </JumbotronWrapper>
)

Jumbotron.propTypes = {
  t: PropTypes.func,
  onShowVideo: PropTypes.func
}

export default withNamespaces('landingPage')(Jumbotron)
