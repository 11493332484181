import memoize from 'memoize-one'
import _ from 'lodash'
import { icons } from 'Assets/icons'

export const getJobDescr = memoize(data => ({
  title: data.title,
  appliedBy: data.peopleWhoApplied && data.peopleWhoApplied.length,
  budget: data.budget,
  distance: data.distance,
  expDate: data.expiryDate,
  doneBefore: data.doneBefore,
  description: data.description,
  isPremium: _.get(data, 'author.isPremium', false),
  favouriteId: data.favouriteId,
  _id: data._id,
  startDate: data.startDate,
  currency: data.currency,
  budgetMyCountry: data.budgetMyCountry,
  currencyMyCountry: data.currencyMyCountry,
}))

export const getCustomerInfo = memoize(author => ({
  avatar: _.get(author, 'avatarURL', icons.avatar),
  name: author.short_name,
  username: author.username,
  reviews: author.reviewCount,
  stars: author.stars,
  isPro: author.isPro,
  isPremium: author.isPremium,
  id_verification: author?.id_verification,
}))

export const getCategoryInfo = memoize(data => ({
  category: data.category?.title,
  subCategory: (data.service && data.service.title) || '',
  created: data.createdAt,
  jobId: data._id,
  streetAddress: data.streetAddress,
  state: data.state,
  city: data.city,
  country: data.country,
  zipCode: data.zipCode
}))
