import React from 'react'
import Slider from 'react-slick'
import { CarouselImg, Dots } from './styles'
import items from 'Assets/mobilePreview'
import { settings } from './conffig'

const DownloadCarousel = () => {
  const _renderDots = dots => <Dots>{dots}</Dots>
  return (
    <Slider {...settings} appendDots={_renderDots} className="slider">
      {items.map((item, i) => (
        <CarouselImg alt="iphone" key={i} src={item} />
      ))}
    </Slider>
  )
}

export default DownloadCarousel
