import styled from 'styled-components'
import { Button } from 'Components/ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { fontFamily, fontSize, sizes } from 'Constants'

export const BodyPart = styled.div`
  display: flex;
  @media (max-width: ${sizes.tablet}px) {
    flex-direction: column;
  }
`

export const ImagesPart = styled.div`
  flex-basis: 50%;
  margin-bottom: 20px;
  @media only screen and (max-width: ${sizes.desktop}px) {
    flex-basis: 100%;
    margin-bottom: 20px;
    padding-right: 20px;
    & > div {
      position: static;
      top: 0;
    }
  }
  @media only screen and (max-width: ${sizes.tablet}px) {
    flex-basis: 100%;
    & > div {
      position: static;
      top: 0;
    }
  }
  @media (max-width: ${sizes.tablet}px) {
    padding-right: 0;
  }
`

const UIButton = styled(Button)`
  display: block;
  width: 100%;
`

export const StyledButton = styled(Button)`
  width: 100%;
  height: 40px;
  text-transform: uppercase;
  margin-top: 0;
  font-family: ${fontFamily};
  margin-bottom: 20px;
  @media (max-width: ${sizes.desktop}px) {
    /* width: auto; */
    width: 75%;
    padding: 0 60px;
    margin: 0 auto;
    margin-bottom: 20px;
    display: block;
  }
  @media (max-width: ${sizes.desktop}px) {
    padding: 0 30px;
    font-size: ${fontSize.h5};
  }
`

export const VideosAndWebsitesLinksBlock = styled.div`
  margin-top: 30px;
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

export const IconSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  color: ${props => (props.disabled ? 'silver' : '#000')};
  font-weight: 600;
  max-width: 120px;
  cursor: pointer;
`

export const Line = styled.div`
  width: 3px;
  height: 100px;
  margin: auto;
  background: rgba(199, 199, 199, 0.75);
  border-radius: 15px;
`

export const MarkButton = styled(UIButton)`
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;
  font-weight: 600;
  text-transform: uppercase;
  ${p =>
    p.disabled &&
    `pointer-events: none; opacity: 0.6; background: ${p.theme.colors.grayButton}`};
  cursor: pointer;
  z-index: 10;
`

export const RateButton = styled(UIButton)`
  margin-bottom: 10px;
  background: #fff !important;
  color: gray;
  border: 1px solid rgba(199, 199, 199, 0.75);
  box-shadow: 0 3px 5px 0 rgba(199, 199, 199, 0.75);
  cursor: pointer;
  ${p => p.disabled && 'pointer-events: none; opacity: 0.6;'};
`

export const IconText = styled.span`
  text-align: center;
  margin-top: 15px;
  font-size: ${p => p.theme.fontSize.default};
`

export const CalendarIcon = styled(FontAwesomeIcon).attrs(props => ({
  icon: faCalendar,
  size: '3x'
}))``

export const MapMarkerIcon = styled(FontAwesomeIcon).attrs(props => ({
  icon: faMapMarkerAlt,
  size: '3x'
}))``

export const CancelButton = styled(UIButton)`
  margin: 0 auto 10px;
  display: block;
  background: ${p => p.theme.colors.grayButton};
  ${p => p.disabled && 'pointer-events: none; opacity: 0.6;'};
  ${p => p.jobClose && 'background: #303030; opacity: 1'};
  transition: box-shadow 0.3s;
  width: 100%;
  font-weight: bold;
  min-height: 35px;
  height: auto;
  padding: 0 8px;
  &:hover {
    box-shadow: 0 0 10px silver;
    background: ${p => p.theme.colors.grayButton};
  }
`

export const RequestButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
`

const RequestButton = styled(UIButton)`
  transition: box-shadow 0.3s;
  font-weight: bold;
  color: #fff;
  &:hover {
    box-shadow: 0 0 10px silver;
  }
`

export const AcceptButton = styled(RequestButton)`
  background: ${props =>
    props.disabled
      ? 'linear-gradient(-75deg, #f0f0f0, #cfcfcf)'
      : 'linear-gradient(to right, #459aee 0%, #3ad6ff 99%)'};
  border-radius: 0 15px 15px 0;
  > img {
    height: 15px;
  }
`

export const RejectButton = styled(RequestButton)`
  background: ${p => p.theme.colors.grayButton};
  border-radius: 15px 0 0 15px;

  &:hover {
    background: ${p => p.theme.colors.grayButton};
  }
`

export const StartButton = styled(UIButton)`
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;
  font-weight: 600;
  text-transform: uppercase;
  ${p =>
    p.disabled &&
    `pointer-events: none; opacity: 0.6; background: ${p.theme.colors.grayButton}`};
  cursor: pointer;
  z-index: 10;
  background: ${p => p.theme.colors.defaultGreen};
  &:hover {
    opacity: 0.8;
    background: ${p => p.theme.colors.defaultGreen};
  }
`

export const DisputeButton = styled(UIButton)`
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;
  font-weight: 600;
  text-transform: uppercase;
  ${p =>
    p.disabled &&
    `pointer-events: none; opacity: 0.6; background: ${p.theme.colors.grayButton}`};
  cursor: pointer;
  z-index: 10;
  background: ${p => p.theme.colors.accentColor};
  &:hover {
    opacity: 0.8;
    background: ${p => p.theme.colors.accentColor};
  }
`

export const ImgLoading = styled.img`
  width: 80px;
`