import { createReducer } from 'Helpers/redux'

import {
  GET_CARD,
  DELETE_CARD,
  GET_EARNINGS,
  GET_PAYMENTS,
  GET_WITHDRAWAL_HISTORIES,
} from 'Redux/actions/paymentsTransactions'

const initialState = {
  cards: {},
  earnings: [],
  payments: [],
  withdrawalHistories: []
}

const handlers = {
  [GET_CARD.SUCCESS]: (state, { payload, meta }) => ({
    ...state,
    cards: {
      ...state.cards,
      [meta.cardId]: payload
    }
  }),
  [DELETE_CARD.SUCCESS]: (state, { meta }) => {
    delete state.cards[meta.cardId]

    return {
      ...state
    }
  },
  [GET_EARNINGS.SUCCESS]: (state, { payload }) => ({
    ...state,
    earnings: payload
  }),
  [GET_PAYMENTS.SUCCESS]: (state, { payload }) => ({
    ...state,
    payments: payload
  }),
  [GET_WITHDRAWAL_HISTORIES.SUCCESS]: (state, { payload }) => ({
    ...state,
    withdrawalHistories: payload
  })
}

export default createReducer(initialState, handlers)
