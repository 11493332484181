import React from 'react'
import { withNamespaces } from 'react-i18next'
import { SubTitle } from './styles'

const ServiceSubtitle = props => {
  const { t, text } = props
  return <SubTitle>{t(text)}</SubTitle>
}

export default withNamespaces('services')(ServiceSubtitle)
