import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
  faTimes
} from '@fortawesome/free-solid-svg-icons'
import { width } from 'styled-system'

export const Container = styled.div`
  padding-top: 25px;
`

export const MapWrapper = styled.div`
  height: 100vh;
  position: relative;
  width: 100%;
  margin: 15px 0 30px;
`

export const CardContainer = styled.div`
  position: absolute;
  bottom: 50px;
  left: 50%;
  width: 500px;
  transform: translateX(-50%);
  ${width};
  @media (max-width: ${p => p.theme.sizes.tablet}px) {
    width: 95%;
  }
`

export const CardWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const RightIconWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  right: -70px;
  background-color: ${p => p.theme.colors.watchButtonColor05};
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  @media screen and (max-width: ${p => p.theme.sizes.tablet}px) {
    top: -45px;
    right: 10%;
    transform: translate(0, 0);
  }
`

export const LeftIconWrapper = styled(RightIconWrapper)`
  right: initial;
  left: -70px;
  margin-left: 0;
  @media screen and (max-width: ${p => p.theme.sizes.tablet}px) {
    left: 10%;
    top: -45px;
  }
`

export const RightIcon = styled(FontAwesomeIcon).attrs(props => ({
  icon: faChevronRight
}))`
  margin-left: 3px;
`

export const LeftIcon = styled(FontAwesomeIcon).attrs(props => ({
  icon: faChevronLeft
}))`
  margin-right: 3px;
`

export const CloseCardButton = styled(FontAwesomeIcon).attrs(() => ({
  icon: faTimes,
  size: '2x'
}))`
  color: #000;
  transition: transform 0.3s;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  &:hover {
    transform: scale(1.2);
  }
`
