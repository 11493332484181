import React from 'react'
import PropTypes from 'prop-types'
import { omit, isString, get, isFunction } from 'lodash'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getSpecificJob } from 'Redux/selectors/jobList'
import { jobDescription } from 'Redux/actions/job'

function withJob(Component) {
  return class extends React.Component {
    static displayName = 'withJob'

    static propTypes = {
      job: PropTypes.object,
      loadJobDescription: PropTypes.func
    }

    state = {
      loadJobId: null
    }

    handleLoadJob = (jobId, callback) => {
      const { loadJobDescription, job } = this.props
      if (!isString(jobId) || !jobId) return

      if (get(job, '_id') === jobId && isFunction(callback)) {
        callback()
      }

      this.setState({ loadJobId: jobId }, () => {
        loadJobDescription(jobId, () => {
          this.setState({ loadJobId: null }, callback)
        })
      })
    }

    render() {
      const { job } = this.props
      const { loadJobId } = this.state

      return (
        <Component
          {...omit(this.props, ['loadJobDescription'])}
          job={loadJobId ? null : job}
          loadJobId={loadJobId}
          onLoadJob={this.handleLoadJob}
        />
      )
    }
  }
}

const actions = {
  loadJobDescription: jobDescription
}

const selectors = createStructuredSelector({
  job: getSpecificJob
})

export default compose(
  connect(
    selectors,
    actions
  ),
  withJob
)
