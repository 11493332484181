import styled from 'styled-components'
import { getThemeField } from 'Theme'
import { fontFamily, sizes, fontSize } from 'Constants'

export const Container = styled.div`
  position: relative;
  max-width: ${props => (props.modal ? '100%' : '450px')};
  width: ${props => (props.modal ? '100%' : '47%')};

  @media only screen and (max-width: ${sizes.desktop}px) {
    width: 100%;
  }
`

/* eslint-disable max-len  */
export const SuggestionsWindow = styled.div`
  position: absolute;
  z-index: 1;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  min-width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background: ${getThemeField('palette.white')};
`

export const SuggestionItem = styled.div`
  background-color: #ffffff;
  font-size: ${fontSize.default};
  font-family: ${fontFamily};
  cursor: pointer;
  &:hover {
    background-color: #ff0000;
  }
`

export const SuggestionItemActive = styled(SuggestionItem)`
  background-color: #a7c0f4;
`
