import styled from 'styled-components'

export const CapitalizeText = styled.span`
  text-transform: capitalize;
`

export const HeaderPremiumWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin: 16px 0;
  padding-bottom: 16px;
  font-size: ${p => p.theme.fontSize.h5}
  font-weight: bold;
  border-bottom: 1px solid ${props => props.theme.colors.lightGrayBorder};
`

export const PremiumBodyComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
  padding-bottom: 16px;
  font-weight: bold;
`

export const PremiumIconWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 0 auto;
`

export const TranslucentText = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: #b4b4b4;
`
