import React, { Fragment } from 'react'
// import { root } from 'Routes/routesConstants'
// import { Button } from 'Components/ui'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Scroll from 'react-scroll'
import _ from 'lodash'

import {
  Container,
  Wrapper,
  Article,
  InnerWrap,
  // FooterModal,
  FloatingButtonBlock,
  FloatingButton,
  // CustomLink
} from './styles'

class TermsCondNew extends React.Component {
  state = {
    query: null
  }

  componentDidMount() {
    const { location, history } = this.props

    const queryString = location.search.substr(1)
    if (queryString.startsWith('redirect_uri=')) {
      const query = decodeURIComponent(
        queryString.substr('redirect_uri='.length)
      )
      this.setState({
        query: query
      })
      this.props.history.replace({
        ...history.location,
        search: ''
      })
    }
  }

  handleClick = () => {
    const { history } = this.props
    const { query } = this.state
    if (_.isString(query)) {
      return (window.location.href = query)
    } else {
      localStorage.setItem('verify', 'true')
      window.close()
    }
    history.goBack()
  }

  handleMoveToBottom = () => {
    Scroll.animateScroll.scrollToBottom()
  }

  _renderFloatingButton = () => {
    return (
      <FloatingButtonBlock>
        <FloatingButton onClick={this.handleMoveToBottom}>
          <FontAwesomeIcon icon={faChevronDown} />
        </FloatingButton>
      </FloatingButtonBlock>
    )
  }

  render() {
    return (
      <Fragment>
        <Container>
          <Wrapper>
            <Article>
              <InnerWrap>
                <h2>Terms & Conditions</h2>
                <b>Ad Serving Policy</b>
                <p>
                  SPOTJOBS, ATOMX LLC, its affiliates and subsidiaries have sole discretion to update
                  this Ad Serving Policy from time to time. We will provide notice of any material changes
                  to this Policy. The changes to this Policy will not apply retroactively. If you do not agree
                  to be bound by the changes, you shall immediately cease the related advertising
                  activities. You further agree that if you continue to use and/or access the services after
                  being notified of such changes to the Policy such use and/or access shall constitute an
                  affirmative towards changes.
                </p>
                 <b>Bidding Rules:</b>
                  <ol list-style-type="upper-roman">
                    <li>
                     1. User/Advertiser/Agency are sole responsible for the accuracy of the information they
                      entered when posting ads.
                    </li>
                    <li>
                      2. No matter what billing currency is applied for payments under the Agreement, bidding
                      on SpotJobs for Ad on the Platform ("Platform") will be ranked by default in US Dollar.
                      The conversion of non-US Dollar billing currency into US Dollar for bidding purpose will
                      automatically take place pursuant to the real time exchange rate announced by the
                      website designated by SPOTJOBS, ATOMX LLC, its affiliates and subsidiaries. The
                      convert rules shall be applied by ATOMX LLC, its affiliates and subsidiaries, its affiliates
                      and subsidiaries.
                    </li>
                  </ol>

                    <b>Reservation Ads Creative:</b>
                    <ol>
                      <li>
                        1. No modification of reservation ad creative assets will be allowed after the asset-
                        delivering deadline.
                      </li>
                      <li>
                        2. No modifications of website page link or video link will be allowed after the assets
                        have been reviewed by the SPOTJOBS Admin team and the campaign has been
                        launched online.
                      </li>
                      <li>
                        3. If User/User/Advertiser/Agency would like to use certain assets (including but not
                        limited to: popular IP/portraits/music/sticker images, etc.), it should provide the copyright
                        certification of those assets or the authorization document and grant a license of use to
                        ATOMX LLC in writing.
                      </li>                     
                    </ol>

                    <b>Reservation Order Changes or Cancellation:</b>
                    <ol>
                      <li>
                        1. Reservation ad shall be based on the media plan confirmed by both Parties in
                        writing, including through emails. Once the media plan is confirmed by both Parties, the
                        order will be regarded as successfolly placed. Withdrawal of or changes to the order will
                        require negotiation between both Parties. If User/Advertiser/Agency suggestswithdrawing or changing the order unilaterally, it shall compensate SPOTJOBS, ATOMX
                        LLC, its affiliates and subsidiaries for the loss according to relevant agreed terms.
                      </li>
                      <li>
                        2. Unilateral Withdrawal by User/Advertiser/Agency
                        <ol type="a">
                          <li>
                            a. Ad posted by User/Advertiser/Agency that have been fully paid cannot be cancelled.
                          </li>
                          <li>
                            b. An Ad Cancelation Insurance fee of US$ 3.50 is available. The Cancelation
                            Insurance allows users to get money back if they cancel the ad campaign up to 3 days
                            before its starting date. The Cancellation insurance fee is nonrefundable.
                          </li>
                          <li>
                            c. Ads posted by User/Advertiser/Agency or Admin for User/Advertiser/Agency can be
                            cancelled at any time, but Ads booked without a Cancelation Insurance are
                            nonrefundable regardless of if the Ad campaign has started or not.
                          </li>
                          <li>
                            d. User/Advertiser/Agency shall pay 100% of the actual ad campaign cost to
                            SPOTJOBS, ATOMX LLC, its affiliates and subsidiaries
                          </li>
                          <li>
                            e. User/Advertiser/Agency shall pay 100% of the actual production fee/ campaign cost
                            of Reservation Effect to SPOTJOBS, ATOMX LLC, its affiliates and subsidiaries, if any
                            reservation effect order is withdrawn after the campaign production is started.
                          </li>
                          <li>
                            f. User/Advertiser/Agency shall pay 50% of the actual ad campaign cost to ATOMX
                            LLC if there is any change to a standard ad order earlier than 3 calendar days before
                            the scheduled ad flight date. If any unilateral change is made less than 3 calendar days
                            (included), User/Advertiser/Agency shall pay 100% of the actual ad campaign amount to
                            SPOTJOBS, ATOMX LLC, its affiliates and subsidiaries.
                          </li>
                        </ol>
                      </li>
                    </ol>
                    <b>Disclaimer:</b>
                    <ol>
                      <li>
                        1. If any ad fails to be served because User/Advertiser/Agency fails to deliver ad
                        assets within the agreed timeline, SPOTJOBS, ATOMX LLC, its affiliates and
                        subsidiaries will not be responsible in such situation and will not make any
                        compensation to User/Advertiser/Agency.
                      </li>
                      <li>
                        2. User/Advertiser/Agency shall ensure SPOTJOBS, ATOMX LLC, its affiliates and
                        subsidiaries that anything related to any third-party rights or any intelligence property
                        has already been duly authorized and/or cleared.
                      </li>
                      <li>
                        3. SPOTJOBS, ATOMX LLC, its affiliates and subsidiaries shall not be responsible
                        for any consequence if the website link page or video link cannot work properly due to
                        reason attributed to User/Advertiser/Agency or any other third party . In such situation,
                        SPOTJOBS may suspend the ad serving without informing User/Advertiser/Agency in
                        advance in order to maintain better users’ experience. In addition, once SPOTJOBS
                        finds out any User/Advertiser/Agency inserting any universal link for the website link
                        page or video link page, SPOTJOBS will immediately cease the ad serving without
                        incurring any compensation to Users/Advertiser/Agency.
                      </li>
                      <li>
                        4. SPOTJOBS, ATOMX LLC, its affiliates and subsidiaries will not be responsible in
                        any of the following events: relevant service is terminated/delayed/interrupted or any
                        data loss due to Force Majeure event; any loss or damage caused by
                        User/Advertiser/Agency using illegal ways to acquire data. Under those circumstances,
                        no compensation will be made by SPOTJOBS, ATOMX LLC, its affiliates and
                        subsidiaries.
                      </li>
                      <li>
                        5. If a third-party, a SPOTJOBS user or institution claims compensation for damage
                        based on advertising content, it should be made clear that User/Advertiser/Agency is
                        responsible, and the compensation to the third party shall be made by
                        User/Advertiser/Agency directly, and it is understood and agreed that SPOTJOBS,ATOMX LLC, its affiliates and subsidiaries is not responsible or liable for any damages
                        in any shape or form.
                      </li>
                    </ol>
                                 
              </InnerWrap>
            </Article>
            {/* <FooterModal>
              <Button onClick={() => this.handleClick()}>I agree</Button>
            </FooterModal> */}
          </Wrapper>
          {this._renderFloatingButton()}
        </Container>
      </Fragment>
    )
  }
}

export default TermsCondNew
