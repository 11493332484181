import React, { useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withNamespaces } from 'react-i18next'
import Webcam from 'react-webcam'

import {
  Container,
  Button,
  Icon,
  Input,
  Image,
  DeleteButton,
  EditIcon,
  VerifyIcon,
  Content,
  CameraIcon,
  UploadIcon,
  Row,
  ArrowIcon,
  IconWrapper,
  Title,
  Header,
  UploadContent,
  cameraStyle,
  CameraMode
} from './style'
import { icons } from 'Assets/icons'
import { paymentIdentity } from 'Redux/actions/user'
import { PaymentIdentityModal } from 'Components/blocks'
import { getProfile } from 'Redux/selectors/user'
import { Modal } from 'Components/ui'
import arrows from 'Assets/arrows'
import { toast } from 'react-toastify'
import { DragAndDrop } from '../index'
import { idVerificationStatus } from 'Constants/idVerification'

const MAX_IMG_SIZE = 5242880
const MAX_IMG_SIZE_STR = '5 MB'

const UploadImage = props => {
  const {
    onChange,
    idVerification,
    t,
    base64,
    setBase64,
    isCenter,
    setSize,
    type: verifyType
  } = props
  const inputRef = useRef()
  const cameraRef = useRef(null)
  const [isShowModal, setIsShowModal] = useState(false)
  const [isRequest, setIsRequest] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [modalType, setModalType] = useState(null)
  const [isShowVerifyModal, setIsShowVerifyModal] = useState(false)
  const [mode, setMode] = useState('user')

  const handleShowModal = () => setIsShowModal(true)
  const handleHideModal = () => setIsShowModal(false)
  const handleShowVerifyModal = () => setIsShowVerifyModal(true)
  const handleHideVerifyModal = () => {
    setIsShowVerifyModal(false)
    setModalType(null)
  }

  const handleRemoveImage = () => {
    setIsEdit(false)
    setBase64(null)
  }

  const triggerFileUpload = () => {
    if (inputRef && inputRef?.current) {
      return inputRef.current.click()
    }
  }

  const handlePayment = () => {
    const { user } = props

    if (user?.isPaidIdentityVerification) {
      return handleShowVerifyModal()
    }
    handleShowModal()
  }

  const handleChangeFile = e => {
    e.preventDefault()
    let file = e.target.files[0]

    if (file?.size && file?.size > MAX_IMG_SIZE) {
      return toast.error(
        t('profilePage:sizeOverflowError', { maxImgSize: MAX_IMG_SIZE_STR })
      )
    }

    if (file) {
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        handleHideVerifyModal()
        setBase64(reader.result)
        setSize(reader?.size || 0)
        onChange(reader.result)
        setIsEdit(true)
      }
    }
  }

  const handleSubmit = () => {
    const { paymentIdentity } = props

    setIsRequest(true)
    paymentIdentity(() => {
      setIsRequest(false)
      setIsShowModal(false)
    })
  }

  const renderImage = () => {
    switch (idVerification) {
      case idVerificationStatus.success:
        return <VerifyIcon src={icons.idVerified} />
      default:
        return base64 ? (
          <>
            <Image onClick={handleShowVerifyModal} src={base64} alt="" />
            <EditIcon
              src={icons.edit}
              alt=""
              onClick={handleShowVerifyModal}
              className="edit"
            />
          </>
        ) : (
          <Button onClick={handlePayment}>
            <Icon />
          </Button>
        )
    }
  }

  const onCapture = useCallback(() => {
    const imageSrc = cameraRef.current.getScreenshot()
    setBase64(imageSrc)
    setIsEdit(true)
    handleHideVerifyModal()
  }, [cameraRef, setBase64])

  const renderModalContent = type => {
    const handleClick = type => () => setModalType(type)

    const videoConstraints = {
      width: 360,
      height: 200,
      facingMode: mode
    }

    const onUserMediaError = error => {
      handleHideVerifyModal()
      if (error?.message) {
        toast.error(error.message)
      }
    }

    const onChangeMode = () => {
      if (mode === 'user') {
        return setMode('environment')
      }
      setMode('user')
    }

    switch (type) {
      case 'camera':
        return (
          <Content isCenter>
            <Header>
              <span>
                {verifyType === 'faceVerify'
                  ? t('profilePage:TakePhotoVideo')
                  : t('profilePage:DocumentPictureFront')}
              </span>
            </Header>
            <Webcam
              audio={false}
              ref={cameraRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              forceScreenshotSourceSize
              style={cameraStyle}
              onUserMediaError={onUserMediaError}
            />
            <CameraIcon
              isCamera
              src={icons.cameraBlack}
              onClick={onCapture}
            />
            <CameraMode src={icons.cameraMode} onClick={onChangeMode} />
          </Content>
        )
      case 'upload':
        return (
          <Content isCenter>
            <Header>
              <span>
                {verifyType === 'faceVerify'
                  ? t('profilePage:UploadYourPhoto')
                  : t('profilePage:UploadPictureFront')}
              </span>
            </Header>
            <DragAndDrop handleDrop={handleDragDrop}>
              <UploadContent onClick={triggerFileUpload}>
                <UploadIcon isUpload src={icons.upload} alt="" />
              </UploadContent>
            </DragAndDrop>
          </Content>
        )
      default:
        return (
          <Content>
            <Header>
              <Title>
                {verifyType === 'faceVerify'
                  ? t('profilePage:AddYourPicture')
                  : t('profilePage:UploadID')}
              </Title>
            </Header>
            <Row onClick={handleClick('camera')}>
              <IconWrapper>
                <CameraIcon src={icons.cameraBlack} alt="" />
              </IconWrapper>
              <ArrowIcon src={arrows.arrowRight} alt="" />
            </Row>
            <Row onClick={handleClick('upload')}>
              <IconWrapper>
                <UploadIcon src={icons.upload} alt="" />
              </IconWrapper>
              <ArrowIcon src={arrows.arrowRight} alt="" />
            </Row>
          </Content>
        )
    }
  }

  const handleDragDrop = file => {
    if (file[0].size > MAX_IMG_SIZE) {
      return toast.error(
        t('profilePage:sizeOverflowError', { maxImgSize: MAX_IMG_SIZE_STR })
      )
    }

    let reader = new FileReader()
    reader.readAsDataURL(file[0])
    reader.onloadend = () => {
      handleHideVerifyModal()
      setBase64(reader.result)
      setSize(reader?.size || 0)
      onChange(reader.result)
      setIsEdit(true)
    }
  }

  return (
    <>
      <Container
        isCenter={isCenter}
        isHideBg={
          idVerification === idVerificationStatus.success ||
          ((idVerification === idVerificationStatus.failed ||
            idVerification === idVerificationStatus.notYet ||
            !idVerification ||
            isEdit) &&
            base64)
        }
      >
        {renderImage()}
        {(idVerification !== idVerificationStatus.success) &&
          isEdit &&
          base64 && (
            <DeleteButton onClick={handleRemoveImage}>
              <img alt="" src={icons.closeWhite} />
            </DeleteButton>
          )}
        <Input onChange={handleChangeFile} ref={inputRef} />
      </Container>
      <PaymentIdentityModal
        isLoading={isRequest}
        isVisible={isShowModal}
        onCancel={handleHideModal}
        onSubmit={handleSubmit}
      />
      <Modal
        isShow={isShowVerifyModal}
        zIndex={1010}
        onRequestClose={handleHideVerifyModal}
        width="400px"
      >
        {renderModalContent(modalType)}
      </Modal>
    </>
  )
}

UploadImage.propTypes = {
  onChange: PropTypes.func,
  type: PropTypes.string,
  userId: PropTypes.string,
  isCenter: PropTypes.bool
}

const actions = {
  paymentIdentity
}

const selectors = createStructuredSelector({
  user: getProfile
})

export default compose(
  connect(selectors, actions),
  withNamespaces(['LeftoverOnes'])
)(UploadImage)
