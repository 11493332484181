import { isArray } from 'lodash'

export const getOrderByName = key => {
  switch (key) {
    case 'proStatus':
      return 'pros'
    case 'verifiedStatus':
      return 'verified'
    case 'rating':
      return 'rate'
    case 'isPremium':
      return 'premiumMembers'
    default:
      return key
  }
}

export const getText = (t, text) =>
  isArray(text) ? text.map(el => t(el)).join(' ') : t(text)
