import { createAsyncAction } from 'Helpers/redux'

import { getRefreshToken } from 'Redux/selectors/auth'

import { apiCall } from './api'

export const SIGN_UP = createAsyncAction('auth/SIGN_UP')
export const signUp = (username, email, password, phoneNumber, country_code, local, callback) => {
  return apiCall({
    method: 'post',
    endpoint: '/auth/signup',
    query: {
      username,
      email,
      password,
      phoneNumber,
      country_code,
      local,
    },
    types: SIGN_UP,
    callback
  })
}

export const SIGN_IN = createAsyncAction('auth/SIGN_IN')
export const signIn = (username, password, meta, callback) => {
  return apiCall({
    method: 'post',
    endpoint: '/auth/signin',
    query: {
      username,
      password
    },
    meta,
    types: SIGN_IN,
    callback
  })
}

export const LOG_OUT = createAsyncAction('auth/LOG_OUT')
export const logOut = () => {
  return apiCall({
    method: 'get',
    endpoint: '/auth/logout',
    types: LOG_OUT
  })
}

export const VERIFY = createAsyncAction('auth/VERIFY')
export const verify = (verificationCode, username, callback) => {
  return apiCall({
    method: 'post',
    endpoint: 'auth/signup/confirm',
    query: {
      username,
      verificationCode
    },
    types: VERIFY,
    callback
  })
}

export const RESEND_CODE = createAsyncAction('auth/RESEND_CODE')
export const resendCode = (username, callback) => {
  return apiCall({
    method: 'post',
    endpoint: '/auth/signup/confirm-code-resend',
    query: {
      username
    },
    types: RESEND_CODE,
    callback
  })
}

export const oauthAuthenticate = payload => {
  return {
    type: SIGN_IN.SUCCESS,
    payload,
    meta: {
      remember: true
    }
  }
}

export const FORGOT_PASSWORD = createAsyncAction('auth/FORGOT_PASSWORD')
export const forgotPassword = (username, callback) => {
  return apiCall({
    method: 'post',
    endpoint: '/auth/forgot-password',
    query: {
      username
    },
    types: FORGOT_PASSWORD,
    callback
  })
}

export const FORGOT_PASSWORD_CONFIRM = createAsyncAction(
  'auth/FORGOT_PASSWORD_CONFIRM'
)
export const forgotPasswordConfirm = (
  username,
  password,
  confirmationCode,
  callback
) => {
  return apiCall({
    method: 'post',
    endpoint: '/auth/forgot-password/confirm',
    query: {
      username,
      password,
      confirmationCode
    },
    types: FORGOT_PASSWORD_CONFIRM,
    callback
  })
}

export const UPDATE_TOKEN = createAsyncAction('auth/UPDATE_TOKEN')
export const updateToken = (token, meta = {}, callback = null) => (
  dispatch,
  getState
) => {
  const refreshToken = token || getRefreshToken(getState())

  dispatch(
    apiCall({
      endpoint: '/auth/update-creds',
      types: UPDATE_TOKEN,
      headers: {
        Authorization: `Bearer ${refreshToken}`
      },
      callback,
      meta
    })
  )
}

export const REHYDRATE_TOKEN = createAsyncAction('auth/REHYDRATE_TOKEN')
export const rehydrateToken = callback => {
  const sessionStorageId = sessionStorage.getItem('user_token')
  const localStorageId = localStorage.getItem('user_token')
  const AccessToken = localStorage.getItem('access')

  const RefreshToken = sessionStorageId ? sessionStorageId : localStorageId

  const isRefreshTokenValid =
    typeof RefreshToken !== 'undefined' && RefreshToken !== null
  const isAccessTokenValid =
    typeof AccessToken !== 'undefined' && AccessToken !== null

  if (isRefreshTokenValid || isAccessTokenValid) {
    if (callback) {
      callback()
    }
    return {
      type: REHYDRATE_TOKEN.SUCCESS,
      payload: {
        data: { RefreshToken, AccessToken }
      }
    }
  } else {
    return {
      type: REHYDRATE_TOKEN.FAILURE,
      payload: {
        data: null
      }
    }
  }
}
