import _ from 'lodash'
import memoize from 'memoize-one'
import { getProfile } from 'Redux/selectors/user'

export const getShortUserName = (
  givenName,
  familyName,
  text = '',
  isShort = true
) => {
  if (!_.isString(givenName) || !_.isString(familyName)) return text

  return `${givenName} ${isShort ? familyName[0].toUpperCase() : familyName}.`
}

export const isUSA = memoize(({ country }) => country === 'United States')

export const getParamsWithUnits = memoize(({ params }, store) => {
  const user = getProfile(store.getState())

  return {
    params: {
      ...params,
      length_units: isUSA(user) ? 'mile' : 'kilometre'
    }
  }
})

export const isMatchService = (job, { services: userServices }) => {
  let serviceId = job.service || job.category
  if (_.isObject(serviceId)) serviceId = serviceId._id

  if (userServices) {
    return userServices.some(el => el._id === serviceId)
  }
}
