import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'

import { Premium } from 'Components/ui/Icons'
import { SubsctiptionWrapper } from 'Components/ui'

import {
  CapitalizeText,
  HeaderPremiumWrapper,
  PremiumBodyComponent,
  PremiumIconWrapper,
  TranslucentText
} from './styles'

const SubscriptionPremiumPuck = ({ t, expiryDate, points, planType }) => (
  <SubsctiptionWrapper isPremium>
    <HeaderPremiumWrapper>
      <PremiumIconWrapper>
        <Premium isPremiumCenter size={28} />
      </PremiumIconWrapper>
      <TranslucentText>{t('YouAreA')}</TranslucentText>
      <div>{t('PremiumUser')}</div>
    </HeaderPremiumWrapper>
    <PremiumBodyComponent>
      <TranslucentText>{t('YouHave')}</TranslucentText>
      <div>
        {points || 0} {t('points')}
      </div>
    </PremiumBodyComponent>
    <PremiumBodyComponent>
      <TranslucentText>{t('landingPage:subscription')}</TranslucentText>
      <div>
        <CapitalizeText>{t(planType)}</CapitalizeText>
      </div>
    </PremiumBodyComponent>
    <PremiumBodyComponent>
      <TranslucentText>{t('ExpiredOn')}</TranslucentText>
      <div>
        {moment(expiryDate).isValid()
          ? moment(expiryDate).format(`DD MMM YYYY`)
          : ''}
      </div>
    </PremiumBodyComponent>
  </SubsctiptionWrapper>
)

SubscriptionPremiumPuck.propTypes = {
  expiryDate: PropTypes.string,
  planType: PropTypes.string.isRequired,
  points: PropTypes.string,
  t: PropTypes.func.isRequired
}

export default SubscriptionPremiumPuck
