import { createSelector } from 'reselect'

const getReducer = store => store.chats

export const getChats = createSelector(
  getReducer,
  ({ chats, isListEnd, currentPage }) => ({ chats, isListEnd, currentPage })
)

export const getActiveChat = createSelector(
  getReducer,
  data => data.activeChat
)

export const getIsUnreadMessage = createSelector(
  getReducer,
  data => data?.isUnreadMessage || []
)
