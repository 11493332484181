import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import _ from 'lodash'
import * as routes from 'Routes/routesConstants'
import { withRouter } from 'react-router-dom'
import i18n from 'i18next'
import { toast } from 'react-toastify'

import { DateModal, PaymentModal, PaymentFailedModal } from 'Components/blocks'
import { acceptJob, rejectJob, hireJob } from 'Redux/actions/postedJobs'
import { getSpecificJob } from 'Redux/selectors/jobList'
import { getProfile } from 'Redux/selectors/user'
import { serviceFee } from 'Constants'
import { clientUrl } from 'Constants/api'
import { jobDescription } from 'Redux/actions/job'
import { getSpecificSP } from 'Redux/selectors/serviceProvider'
import { jobStatuses } from 'Constants/jobStatuses'
import { startTheJob } from 'Redux/actions/bookedJobs'
import withCurrency from './withCurrency'

function withHireSP(Component) {
  return class extends React.PureComponent {
    static displayName = 'withHireSP'

    static propTypes = {
      accept: PropTypes.func,
      hire: PropTypes.func,
      history: PropTypes.object,
      job: PropTypes.object,
      reject: PropTypes.func
    }

    state = {
      loadingApplicationId: null,
      isModalOpen: false,
      isOpenPaymentModal: false,
      date: null,
      budget: _.get(this.props.job, 'budget'),
      currency: _.get(this.props.job, 'currencyMyCountry'),
      isShowPaymentFailed: false,
      isRecurring: false,
      doneBefore: null,
      expiryDate: null,
      budgetMyCountry: _.get(this.props.job, 'budgetMyCountry'),
      isGettingPaymentMaintenance: false
    }

    application = {}
    callback = null

    componentDidMount() {
      const { history, location } = this.props

      const query = new URLSearchParams(location.search)

      const status = query.get('status')
      if (status) {
        history.push(location.pathname)
        if (status === 'failed') {
          this.setState({ isShowPaymentFailed: true }, () => {
            setTimeout(
              () =>
                this.setState(
                  { isShowPaymentFailed: false },
                  this.handleOpenPaymentModal
                ),
              2000
            )
          })
        }
      }
    }

    handleHire = (
      {
        _id,
        budget,
        budgetMyCountry,
        currency,
        date,
        isRecurring,
        jobId,
        userId
      },
      callback
    ) => {
      const { hire, history } = this.props

      this.setState(
        { loadingApplicationId: _id || this.application._id },
        () => {
          const request = {
            jobId: jobId || this.application.jobId,
            userId: userId || this.application.userId,
            startDate: moment(date).format(),
            budget: Number(budget),
            currency: 'USD',
            isRecurring,
            budgetMyCountry,
            currencyMyCountry: currency
          }

          hire(request, error => {
            this.setState({
              loadingApplicationId: null,
              isModalOpen: false
            })
            _.isFunction(callback) && callback()

            if (error) {
              return toast.error(error?.message || error?.payload?.message)
            }

            if (_.isFunction(callback)) {
              return window.location.replace(
                `${clientUrl}${routes.myJobs}${routes.booked}/${jobId ||
                  this.application.jobId}`
              )
            }

            history.push(
              `${routes.myJobs}${routes.booked}/${jobId ||
                this.application.jobId}`
            )
          })
        }
      )
    }

    handleCloseModal = () => this.setState({ isModalOpen: false })

    handleOpenModal = application => {
      this.application = application
      this.setState({ isModalOpen: true })
    }

    handleAccept = application => {
      const { accept } = this.props

      this.setState({ loadingApplicationId: application._id })
      if (_.isString(application._id)) {
        const payload = {
          applicationId: application._id,
          doerId: application.userId,
          jobId: application.jobId
        }

        accept(payload, () => {
          this.setState({ loadingApplicationId: null })
        })
      }
    }

    handleReject = (application, callback) => {
      const { reject } = this.props

      this.setState({ loadingApplicationId: application._id })
      if (_.isString(application._id)) {
        reject(application._id, () => {
          this.setState({ loadingApplicationId: null }, callback)
        })
      }
    }

    handleOpenPaymentModal = callback => {
      if (_.isFunction(callback)) {
        this.callback = callback
      }
      this.setState({ isOpenPaymentModal: true })
    }

    handleClosePaymentModal = () => {
      this.setState({ isOpenPaymentModal: false })
      _.isFunction(this.callback) && this.callback()
    }

    handleStartTheJob = callback => {
      const { onStartingTheJob, job, user } = this.props
      this.setState({ isStartingTheJob: true })
      const paypalRedirectUrl = `${clientUrl}${routes.myJobs}${routes.booked}/${job._id}`
      const body = {
        jobId: job._id,
        paypalRedirectUrl,
        isAuthor: job?.author?.username === user?.username
      }
      if (_.isFunction(callback)) {
        this.callback = callback
      }
      onStartingTheJob(body, error => {
        this.setState({
          isOpenPaymentModal: false,
          isStartingTheJob: false
        })
        _.isFunction(this.callback) && this.callback()
        if (error) {
          return toast.error(error.message)
        }
        if (
          !job?.isPaymentViaOurService ||
          job?.author?.username !== user?.username
        ) {
          toast.success(i18n.t('common:success'))
        }
      })
    }

    handleClosePaymentFailModal = () =>
      this.setState({ isShowPaymentFailed: false })

    render() {
      const { job, user, onGetRatio } = this.props
      const {
        isModalOpen,
        loadingApplicationId,
        isOpenPaymentModal,
        isShowPaymentFailed,
        // budget,
        isStartingTheJob
      } = this.state

      return (
        <>
          <Component
            {..._.omit(this.props, ['accept', 'reject', 'hire', 'onGetRatio'])}
            loadingApplicationId={loadingApplicationId}
            onAccept={this.handleAccept}
            onHire={this.handleOpenModal}
            onReject={this.handleReject}
            onClose={this.handleClosePaymentModal}
            onHireNow={this.handleHire}
            onStartingTheJob={
              job?.author?.username === user?.username
                ? this.handleOpenPaymentModal
                : this.handleStartTheJob
            }
          />
          <DateModal
            budget={
              _.get(this.props.job, 'budgetMyCountry') || _.get(job, 'budget')
            }
            currency={
              _.get(this.props.job, 'currencyMyCountry') ||
              _.get(job, 'currency')
            }
            isRequest={Boolean(loadingApplicationId)}
            isVisible={isModalOpen}
            onClose={this.handleCloseModal}
            onSubmit={this.handleHire}
            isShowDescription={!this.application?.user?.paypal_account_withdraw}
            onGetRatio={onGetRatio}
          />
          <PaymentModal
            isVisible={isOpenPaymentModal}
            budget={_.get(job, 'budget') || 0}
            serviceFees={serviceFee}
            isLoading={isStartingTheJob}
            onSubmit={this.handleStartTheJob}
            onCancel={this.handleClosePaymentModal}
            isJobDone={job?.jobStatus === jobStatuses.done}
            isAuthor={job?.author?.username === user?.username}
            onGetRatio={onGetRatio}
          />
          <PaymentFailedModal
            isVisible={isShowPaymentFailed}
            onClose={this.handleClosePaymentFailModal}
          />
        </>
      )
    }
  }
}

const actions = {
  accept: acceptJob,
  reject: rejectJob,
  hire: hireJob,
  onGetJobDescription: jobDescription,
  onStartingTheJob: startTheJob
}

const selectors = createStructuredSelector({
  job: getSpecificJob,
  user: getProfile,
  profile: getSpecificSP
})

export default compose(connect(selectors, actions), withRouter, withCurrency, withHireSP)
