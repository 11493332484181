import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import * as routes from 'Routes/routesConstants'
import { Switch, Route, Redirect } from 'react-router-dom'
import memoize from 'memoize-one'

import { TAB_ADS } from 'Constants/tabNav'
import { Header, Footer, BurgerMenu, HeaderTabs } from 'Components/blocks'

import { PageWrapper } from 'Components/ui'

import { CurrentAds, CreateAds } from './innerBlocks'
import {
  BookedJobsKeys,
  AppliedJobsKeys,
  getHeaderTabsInitialId
} from './config'

class PostAds extends PureComponent {
  state = {
  }

  getActiveTabIndex = memoize(url => {
    if (url.includes(routes.currentAds)) return 'a'
    if (url.includes(routes.createAds)) return 'b'
  })

  subRoute = memoize(getHeaderTabsInitialId)

  handleTabClick = id => {
    const { history } = this.props

    switch (id) {
      case 'a':
        history.push(`${routes.postAds}${routes.currentAds}`)
        break
      case 'b':
        history.push(`${routes.postAds}${routes.createAds}`)
        break
      default:
        history.push(`${routes.postAds}${routes.createAds}`)
        break
    }
  }

  handleSetActiveCurrentAds = activeJob => {
    const { history } = this.props

    history.push(`${routes.postAds}${routes.currentAds}/${activeJob._id}`)
  }

  handleSetActiveCreateAds = activeJob => {
    const { history } = this.props

    history.push(`${routes.postAds}${routes.createAds}/${activeJob._id}`)
  }

  render() {
    const { user, location } = this.props

    const subRoute = this.subRoute(location)

    const getProps = keys => ({
      ..._.pick(this.props, keys),
      defaultRadius: user.defaultRadius
    })
    //this.props.onClose()
    return (
      <PageWrapper>
        {user && <BurgerMenu user={user} />}
        <Header postJob={false} view={Object.keys(user).length === 0} />
        <HeaderTabs
          activeTabIndex={this.getActiveTabIndex(location.pathname)}
          initialRoute={subRoute}
          options={TAB_ADS}
          onClickLink={this.handleTabClick}
        />

        <Switch>
          <Route
            path={`${routes.postAds}${routes.currentAds}`}
            render={() => (
              <CurrentAds
                {...getProps(AppliedJobsKeys)}
                onClickJob={this.handleSetActiveAppliedJob}
              />
            )}
          />
          <Route
            path={`${routes.postAds}${routes.createAds}`}
            render={() => (
              <CreateAds
                {...getProps(BookedJobsKeys)}
                onClickJob={this.handleSetActiveBookedJob}
              />
            )}
          />
          <Redirect to={`${routes.postAds}${routes.createAds}`} />
        </Switch>

        <Footer black isAuth={user} />
      </PageWrapper>
    )
  }
}

PostAds.propTypes = {
  location: PropTypes.object,
  t: PropTypes.func,
  user: PropTypes.object,
}

export default PostAds
