import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { withNamespaces } from 'react-i18next'

import { getProfile } from 'Redux/selectors/user'

import Component from './HelpCard'

const actions = {}

const selectors = createStructuredSelector({
  user: getProfile
})

export default compose(
  connect(
    selectors,
    actions
  ),
  withNamespaces('findHelp')
)(Component)
