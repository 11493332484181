import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import InfiniteScroll from 'react-infinite-scroller'
import memoize from 'memoize-one'
import { TABS_FAVOURITES } from 'Constants/tabNav'
import { icons } from 'Assets/icons'
import * as routes from 'Routes/routesConstants'

import {
  BreadCrumbs,
  Header,
  JobCard,
  RightSidebar,
  Ad,
  Footer,
  BurgerMenu,
  HeaderTabs,
  HelpCard,
  ListAd,
  ProgressBar
} from 'Components/blocks'

import {
  PageWrapper,
  ContentWrapper,
  CardWrapper,
  RightSideBarWrapper,
  DownPart,
  PreloadImg,
  LoadMoreWrapper
} from 'Components/ui'

class Favourites extends PureComponent {
  state = {
    isJobsRequest: true,
    isSPRequest: true,
    elements_per_page: 10,
    pageNumberJobs: 0,
    pageNumberSP: 0,
    isLoadMoreRequest: false,
    activeTabId: 'a',
    idCardFavouriteLoad: null
  }

  componentDidMount() {
    const { onGetFavouritesJobList, onGetFavouritesSPList } = this.props
    onGetFavouritesJobList(() => this.setState({ isJobsRequest: false }))
    onGetFavouritesSPList(() => this.setState({ isSPRequest: false }))
  }

  handleTabClick = id => this.setState({ activeTabId: id })

  loadMoreItems = () => {
    const { onGetFavouritesJobList } = this.props
    const { isLoadMoreRequest, elements_per_page, pageNumber } = this.state
    if (isLoadMoreRequest) return

    const request = {
      elements_per_page: elements_per_page,
      page_number: pageNumber + 1
    }

    this.setState({ isLoadMoreRequest: true, pageNumber: pageNumber + 1 })
    onGetFavouritesJobList(request, error => {
      if (!error) this.setState({ isLoadMoreRequest: false })
    })
  }

  renderSpPucks = memoize((data, loadFavouriteSPId) => {
    const { onFavouriteSPClick, onShare } = this.props

    return data.map((item, key) => (
      <div key={key}>
        <HelpCard
          data={item}
          isLoadingFavourite={loadFavouriteSPId === item._id}
          onHandleFavouriteClick={() => onFavouriteSPClick(item)}
          onShareClick={() => onShare(item)}
        />
        <ListAd data={data} index={key} />
      </div>
    ))
  })

  renderJobPucks = memoize((data, loadFavouriteJobId) => {
    const { onApply, onFavouriteClick, onShare } = this.props

    return data.map((item, key) => (
      <Fragment key={key}>
        <JobCard
          {...item}
          isLoadingFavourite={loadFavouriteJobId === item._id}
          path={`${routes.findJobs}${routes.listView}/${item._id}`}
          onHandleApply={cb => onApply(item._id, cb)}
          onHandleFavouriteClick={() => onFavouriteClick(item)}
          onShareClick={() => onShare(item)}
        />
        <ListAd data={data} index={key} />
      </Fragment>
    ))
  })

  _renderJobsView = () => {
    const {
      favourites: { favouritesJobs },
      loadFavouriteJobId
    } = this.props
    const { isJobsRequest } = this.state

    return (
      <>
        <ProgressBar isDisabledScreen percent={isJobsRequest ? 1 : 100} />
        <DownPart>
          <CardWrapper>
            <Ad />
            {!isJobsRequest ? (
              <>
                <InfiniteScroll
                  hasMore={false}
                  loadMore={this.loadMoreItems}
                  loader={
                    <LoadMoreWrapper key={-1}>
                      <PreloadImg
                        alt="loading"
                        className="loader"
                        src={icons.preload}
                      />
                    </LoadMoreWrapper>
                  }
                  useWindow
                >
                  {!_.isEmpty(favouritesJobs) && (
                    <>
                      {this.renderJobPucks(favouritesJobs, loadFavouriteJobId)}
                      <Ad />
                    </>
                  )}
                </InfiniteScroll>
              </>
            ) : (
              <PreloadImg alt="loading" src={icons.preload} />
            )}
          </CardWrapper>
          <RightSideBarWrapper>
            <RightSidebar />
          </RightSideBarWrapper>
        </DownPart>
      </>
    )
  }

  _renderSPView = () => {
    const {
      favourites: { favouritesSP },
      loadFavouriteSPId
    } = this.props
    const { isSPRequest, hasMore } = this.state

    return (
      <>
        <ProgressBar isDisabledScreen percent={isSPRequest ? 1 : 100} />
        <DownPart>
          <CardWrapper>
            {isSPRequest && !_.isEmpty(favouritesSP) ? (
              <PreloadImg alt="loading" src={icons.preload} />
            ) : (
              <InfiniteScroll
                hasMore={hasMore}
                loadMore={this.loadMoreItems}
                loader={
                  <LoadMoreWrapper key={-2}>
                    <PreloadImg
                      alt="loading"
                      className="loader"
                      src={icons.preload}
                    />
                  </LoadMoreWrapper>
                }
                useWindow={true}
              >
                <Ad />
                {!_.isEmpty(favouritesSP) && (
                  <>
                    {this.renderSpPucks(favouritesSP, loadFavouriteSPId)}
                    <Ad />
                  </>
                )}
              </InfiniteScroll>
            )}
          </CardWrapper>
          <RightSideBarWrapper>
            <RightSidebar />
          </RightSideBarWrapper>
        </DownPart>
      </>
    )
  }

  render() {
    const { match, location, user, t } = this.props
    const { activeTabId } = this.state

    return (
      <PageWrapper>
        {user && <BurgerMenu user={user} />}
        <Header postJob={false} view={Object.keys(user).length === 0} />
        <HeaderTabs
          options={TABS_FAVOURITES}
          onClickLink={this.handleTabClick}
        />
        <BreadCrumbs
          location={location}
          match={match}
          title={t(
            `common:${activeTabId === 'a' ? 'jobs' : 'serviceProviders'}`
          )}
        />
        <ContentWrapper>
          {activeTabId === 'b' ? this._renderSPView() : this._renderJobsView()}
        </ContentWrapper>
        <Footer black={true} isAuth={user} />
      </PageWrapper>
    )
  }
}

Favourites.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  services: PropTypes.object,
  t: PropTypes.func,
  user: PropTypes.object.isRequired,
  onApply: PropTypes.func,
  onGetFavouritesJobList: PropTypes.func,
  onGetFavouritesSPList: PropTypes.func,
  onGetJobList: PropTypes.func,
  onGetPopularServices: PropTypes.func,
  onShare: PropTypes.func,
  loadFavouriteSPId: PropTypes.string,
  onFavouriteSPClick: PropTypes.func,
  loadFavouriteJobId: PropTypes.string,
  onFavouriteClick: PropTypes.func
}

export default Favourites
