import { connect } from 'react-redux'

import { deleteAccount } from 'Redux/actions/settings'
import { logOut } from 'Redux/actions/auth'

import Component from './DeleteAccountModal'

const actions = {
  onDeleteAccount: deleteAccount,
  onLogOut: logOut
}

export default connect(
  null,
  actions
)(Component)
