import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { createReducer } from 'Helpers/redux'
import { filterDeletedItems } from 'Helpers/filterDeletedItems'
import {
  ADD_PROVIDER_TO_FAVOURITES,
  REMOVE_PROVIDER_FROM_FAVOURITES
} from 'Redux/actions/favourites'

import {
  GET_SERVICE_PROVIDER,
  GET_SERVICE_PROVIDERS_LIST,
  GET_NEAREST_SERVICE_PROVIDERS_LIST,
  REMOVE_SP,
  RESET_ERROR
} from 'Redux/actions/serviceProviders'
import { LOG_OUT } from 'Redux/actions/auth'

const persistConfig = {
  key: 'providers',
  storage,
  blacklist: []
}

const initialState = {
  serviceProvider: {},
  serviceProvidersList: [],
  nearestSP: [],
  removedIds: [],
  error: {},
}

const handlers = {
  [GET_SERVICE_PROVIDERS_LIST.SUCCESS]: (prevState, { payload, meta }) => {
    return {
      ...prevState,
      serviceProvidersList: meta.isFilter
        ? filterDeletedItems(payload.list, prevState.removedIds)
        : filterDeletedItems(
            [...prevState.serviceProvidersList, ...payload.list],
            prevState.removedIds
          ),
      isListEnd: !payload.isListEnd
    }
  },
  [GET_NEAREST_SERVICE_PROVIDERS_LIST.SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      nearestSP: payload.list
    }
  },
  [GET_SERVICE_PROVIDER.REQUEST]: prevState => {
    return {
      ...prevState,
      error: {},
      serviceProvider: {}
    }
  },
  [GET_SERVICE_PROVIDER.SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      serviceProvider: payload,
      error: {}
    }
  },
  [GET_SERVICE_PROVIDER.FAILURE]: (prevState, { payload }) => {
    return {
      ...prevState,
      error: payload?.response?.data || {}
    }
  },
  [RESET_ERROR.REQUEST]: prevState => ({
    ...prevState,
    error: {}
  }),
  [ADD_PROVIDER_TO_FAVOURITES.SUCCESS]: (prevState, { payload }) => {
    let serviceProvider = {}

    if (
      prevState.serviceProvider &&
      prevState.serviceProvider.username === payload.spId
    ) {
      serviceProvider = {
        ...prevState.serviceProvider,
        favouriteId: payload._id
      }
    }

    return {
      ...prevState,
      serviceProvidersList: prevState.serviceProvidersList.map(item => {
        if (item.username === payload.spId) {
          item.favouriteId = payload._id
          return item
        } else {
          return item
        }
      }),
      serviceProvider: serviceProvider
    }
  },
  [REMOVE_PROVIDER_FROM_FAVOURITES.SUCCESS]: (prevState, { meta }) => {
    const serviceProvider = prevState.serviceProvider
    if (serviceProvider && serviceProvider.favouriteId === meta.favouriteSpId) {
      serviceProvider.favouriteId = null
    }
    return {
      ...prevState,
      serviceProvidersList: prevState.serviceProvidersList.map(item => {
        if (item.favouriteId === meta.favouriteSpId) {
          item.favouriteId = null
          return item
        } else {
          return item
        }
      }),
      serviceProvider: serviceProvider
    }
  },
  [REMOVE_SP]: (state, { id }) => ({
    ...state,
    serviceProvidersList: state.serviceProvidersList.filter(
      el => el._id !== id
    ),
    removedIds: [...state.removedIds, id]
  }),
  [LOG_OUT.REQUEST]: () => initialState
}

export default persistReducer(
  persistConfig,
  createReducer(initialState, handlers)
)
