import styled from 'styled-components'
import { Button } from 'Components/ui'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Container = styled.div`
  margin-top: ${p => (p.isMapView ? 0 : '10px')};
  background-color: #fff;
  width: 100%;
  ${p =>
    p.isMapView &&
    `box-shadow: 0 0 5px silver;
  border-radius: 5px;`};
  ${p => p.isBordered && !p.isMapView && 'border-bottom: solid 1px silver'};
  &:first-child {
    margin-top: 0;
  }
`

export const StarIcon = styled(FontAwesomeIcon).attrs(props => ({
  icon: faStar,
  color: props.theme.colors.premiumBg
}))`
  margin-right: 5px;
`

export const Content = styled.div`
  display: flex;
  padding: 10px 20px;
  @media screen and (max-width: ${p => p.theme.sizes.tablet}px) {
    padding-right: 0;
    padding-left: 0;
  }
`

export const AvatarContainer = styled.div`
  margin-right: 20px;
  @media screen and (max-width: ${p => p.theme.sizes.tablet}px) {
    margin-left: 10px;
  }
`

export const Info = styled.div``

export const NameBlock = styled.div`
  display: flex;
  margin-bottom: 5px;
  align-items: center;
`

export const Name = styled.h4`
  margin: 0 5px 0 0;
  padding: 0;
  font-size: ${p => p.theme.fontSize.h5};
`

export const Stars = styled.div`
  display: flex;
  font-weight: bold;
  margin-bottom: 5px;
`

export const Footer = styled.div`
  display: flex;
  margin-top: 10px;
  ${p =>
    p.isLoading &&
    `transform: scale(0.5);
    justify-content: center;`};
`

export const RejectButton = styled(Button)`
  border-radius: 0;
  flex-basis: 40%;
  font-weight: bold;
  color: #000;
  background: linear-gradient(-120deg, #cfcfcf, #f0f0f0);
  &:hover {
    background: linear-gradient(-75deg, #f0f0f0, #cfcfcf);
  }
`

export const AcceptButton = styled(Button)`
  border-radius: 0;
  flex-basis: 60%;
  font-weight: bold;
`
