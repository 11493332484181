import { createReducer } from 'Helpers/redux'

import {
  LOAD_PLANS,
  ADS_VIEWED,
  LOAD_PTS_COST,
  BUY_POINTS
} from 'Redux/actions/subscription'
import { FETCH_USER } from 'Redux/actions/user'

const initialState = {
  data: {},
  points: 0,
  price: {}
}

const handlers = {
  [LOAD_PLANS.SUCCESS]: (state, { payload }) => ({
    ...state,
    data: payload
  }),
  [ADS_VIEWED.REQUEST]: state => ({
    ...state
    // points: state.points + 1
  }),
  [FETCH_USER.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      points: payload.ptsAmount
    }
  },
  [LOAD_PTS_COST.SUCCESS]: (state, { payload }) => ({
    ...state,
    price: payload
  }),
  [BUY_POINTS.SUCCESS]: (state, { payload }) => {
    console.log('BUY_POINTS payload', payload.ptsAmount)
    return {
      ...state,
      // points: payload.ptsAmount
    }
  }
}

export default createReducer(initialState, handlers)
