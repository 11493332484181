import PropTypes from 'prop-types'
import React from 'react'
import { withNamespaces } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlag } from '@fortawesome/free-solid-svg-icons'

import { Wrapper } from './styles'

const Report = ({
  t,
  name,
  isVisible = true,
  children,
  onClick,
  isNotAuthor = false
}) =>
  isVisible ? (
    <Wrapper isNotAuthor={isNotAuthor} onClick={onClick}>
      <FontAwesomeIcon icon={faFlag} />
      <span>{t('report')}</span>
      <span>{children || name}</span>
    </Wrapper>
  ) : null

Report.propTypes = {
  children: PropTypes.string,
  isVisible: PropTypes.bool,
  name: PropTypes.string,
  t: PropTypes.func,
  onClick: PropTypes.func
}

export default withNamespaces('common')(Report)
