const paypal = 'paypal'
const credit = 'credit_card'

export const paymentMethods = {
  paypal,
  credit
}

export const serviceFee = 8

export const formatPrice = price => (Math.round(price * 100) / 100)

export const formatBudget = budget => {
  if (budget) {
    let negative = false;

    if(budget < 0) {
      negative = true;
      budget = budget * -1;
    }
    const multiplicator = Math.pow(10, 2);
    budget = parseFloat((budget * multiplicator).toFixed(11));
    budget = (Math.round(budget) / multiplicator).toFixed(2);
    if(negative) {
      budget = (budget * -1).toFixed(2);
    }
    return budget;
  }
  return '0.00'
}

export const getBudget = (budget, rate = 1, currency = 'USD', type = 'string') => {
  if (currency === 'USD') {
    if (type === 'string') {
      return formatBudget(budget)
    }
    const _budget = formatBudget(budget).split('.')
    return Number(`${_budget[0]}.${_budget[1].slice(0, 2)}`)
  }

  const budgetConvert = budget / rate

  if (type === 'string') {
    return formatBudget(budgetConvert)
  }

  const _budgetConvert = formatBudget(budgetConvert).split('.')
  return Number(`${_budgetConvert[0]}.${_budgetConvert[1].slice(0, 2)}`)
}

export const processingFee = 0.3
export const paypalFee = 0.029