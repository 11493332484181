import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
// import ads from 'Configs/ads'
import { LoadMoreWrapper, PreloadImg } from 'Components/ui'
import { icons } from 'Assets/icons'

import {
  Wrapper,
  Button,
  SubTitle,
  Image,
  Ad,
  Title,
  Block,
  ButtonWrapper,
  TitleGroup
} from './style'

export default class Ads extends PureComponent {
  state = {
    isAdsLoading: false
  }

  componentDidMount() {
    // (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  render() {
    // to props t, type, className,
    const { t } = this.props
    const { isAdsLoading } = this.state
    // if (type === 'ads') {
    //   return <GoogleAdSense format="auto" className={className} />
    // }

    return (
      <Wrapper
        // onClick={() => {
        //   this.setState({ isAdsLoading: true })
        //   onViewedAds(ads.simple, () => {
        //     onTokenRehydrate(() => this.setState({ isAdsLoading: false }))
        //   })
        // }}
      >
        {/* <Block>
          <Image />
          <TitleGroup>
            <Title>{t('placeYourAdHere')}</Title>
            <SubTitle>atomxllc.com</SubTitle>
          </TitleGroup>
        </Block>
        <Block>
          <ButtonWrapper>
            <Button>GRAB IT</Button>
          </ButtonWrapper>
        </Block>
        <Ad>{t('homePage:ad')}</Ad> */}

        {isAdsLoading ? (
          <LoadMoreWrapper>
            <PreloadImg
              alt="loading"
              className="loader"
              isAds={true}
              src={icons.preload}
            />
          </LoadMoreWrapper>
        ) : (
          <>
            <Block>
              <Image />
              <TitleGroup>
                <Title>{t('common:placeYourAdHere')}</Title>
                <SubTitle>atomxllc.com</SubTitle>
              </TitleGroup>
            </Block>
            <Block>
              <ButtonWrapper>
                <Button>GRAB IT</Button>
              </ButtonWrapper>
            </Block>
            <Ad>{t('homePage:ad')}</Ad>
          </>
          // <ins
          //   className="adsbygoogle"
          //   data-ad-client="ca-pub-5502345004497283"
          //   data-ad-format="auto"
          //   // data-ad-format="rectangle"
          //   data-ad-slot="2960184572"
          //   data-full-width-responsive="true"
          //   style={{
          //     display: 'block',
          //     // width: '728px',
          //     // height: '90px',
          //     // maxHeight: '90px',
          //     minWidth: '100%',
          //     minHeight: '100%',
          //     maxHeight: '80px',
          //     cursor: 'pointer'
          //   }}
          // ></ins>
        )}
      </Wrapper>
    )
  }
}

Ads.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func
}

// export default withNamespaces(['common', 'homePage'])(Ads)
