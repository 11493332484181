export const TABS_LIST_MAP = [
  {
    id: 1,
    text: 'listView',
    setId: 'a'
  },
  {
    id: 2,
    text: 'mapView',
    setId: 'b'
  }
]

export const TABS_FAVOURITES = [
  {
    id: 1,
    text: 'jobs',
    setId: 'a'
  },
  {
    id: 2,
    text: 'serviceProviders',
    setId: 'b'
  }
]

export const TAB_JOBS = [
  {
    id: 1,
    text: 'postedJobs',
    setId: 'a'
  },
  {
    id: 2,
    text: 'appliedJobs',
    setId: 'b'
  },
  {
    id: 3,
    text: 'bookedJobs',
    setId: 'c'
  }
]

export const TAB_ADS = [
  {
    id: 1,
    text: 'currentAds',
    setId: 'a'
  },
  {
    id: 2,
    text: 'createAds',
    setId: 'b'
  },
]
