import React from 'react'
import PropTypes from 'prop-types'

import { SubsctioptionButtons, SubsctiptionWrapper } from 'Components/ui'

import { checkProps } from './config'
import { PrivilageList } from '../index'

const SubscriptionPackage = ({
  t,
  title,
  price,
  cost,
  type,
  onBuyClick,
  isVisible,
  onUsePointsClick
}) =>
  isVisible ? (
    <SubsctiptionWrapper isMostPopular={type === 'yearly' && t('mostPopular')}>
      <h3>{title}</h3>
      <h2>
        {price} {t('pts')}
      </h2>
      <h4>(${cost})</h4>
      {type === 'yearly' && <PrivilageList />}
      <SubsctioptionButtons.SelectBtn onClick={onBuyClick}>
        {t('buy')}
      </SubsctioptionButtons.SelectBtn>
      <SubsctioptionButtons.UseButton onClick={onUsePointsClick}>
        {t('usePoints')}
      </SubsctioptionButtons.UseButton>
    </SubsctiptionWrapper>
  ) : null

SubscriptionPackage.propTypes = {
  cost: PropTypes.any,
  isVisible: PropTypes.bool,
  price: PropTypes.any,
  t: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.oneOf(['yearly', 'monthly']),
  onBuyClick: PropTypes.func,
  onUsePointsClick: PropTypes.func
}

SubscriptionPackage.defaultProps = {
  isVisible: true
}

export default React.memo(SubscriptionPackage, checkProps)
