import styled from 'styled-components'
import ReactSlider from 'react-slider'

export const StyledSliderContainer = styled.div`
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    height: 30px;
`

export const StyledRadiusLabel = styled.div`
    padding-right: 10;
    width: 15%;
    text-align: right;
`

export const StyledSlider = styled(ReactSlider)`
    margin-left: 5px;
    width: 80%;
    height: 25px;
`;

export const StyledThumb = styled.div`
    height: 25px;
    line-height: 25px;
    width: 25px;
    text-align: center;
    background-color: #FFFFFF;
    color: #37ABF8;
    border-radius: 50%;
    cursor: grab;
    border: 1px solid #37ABF8;
`;

export const StyledTrack = styled.div`
    top: 0;
    bottom: 0;
    background: ${props => props.index === 1 ? '#FFFFFF' : '#37ABF8'};
    border-radius: 999px;
`;

export const GoogleMapContainer = styled.div`
  height: 300px;
  width: 100%;
  position: relative;
`