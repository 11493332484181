import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-regular-svg-icons'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  min-width: 50px;
  height: 50px;
  min-height: 50px;
  border-radius: 50%;
  background: ${p => p.theme.colors.lightBlue};
`

export const Icon = styled(FontAwesomeIcon).attrs(() => ({
  icon: faBell,
  size: '2x',
  color: '#fff'
}))``
