import React, { Component } from 'react'

import { Wrapper } from './style'

class GoogleAdSense extends Component {
  render() {
    const { className, format } = this.props

    return (
      <Wrapper>
        <ins
          className={`adsbygoogle ${className}`}
          data-ad-client="ca-pub-4921840598010858"
          data-ad-format={format}
          // data-ad-slot={slot}
        />
      </Wrapper>
    )
  }
}

export { GoogleAdSense }
