import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from './api'

export const ADD_JOB_TO_FAVOURITES = createAsyncAction(
  'favourites/ADD_JOB_FAVOURITES'
)
export const addJobToFavourites = (jobId, callback) =>
  apiCall({
    method: 'post',
    endpoint: '/favourites/job',
    query: {
      jobId
    },
    types: ADD_JOB_TO_FAVOURITES,
    callback
  })

export const REMOVE_JOB_FROM_FAVOURITES = createAsyncAction(
  'favourites/REMOVE_JOB_FROM_FAVOURITES'
)
export const removeJobFromFavourites = (favouriteJobId, callback) =>
  apiCall({
    method: 'delete',
    endpoint: '/favourites/job',
    query: {
      favouriteJobId
    },
    meta: {
      favouriteJobId
    },
    types: REMOVE_JOB_FROM_FAVOURITES,
    callback
  })

export const GET_FAVOURITES_JOBS = createAsyncAction(
  'favourites/GET_FAVOURITES_JOBS'
)
export const favouritesJobs = callback =>
  apiCall({
    method: 'GET',
    endpoint: '/favourites/job/list',
    types: GET_FAVOURITES_JOBS,
    qsParams: {
      elements_per_page: 1000,
      no_geolocation_filter: true
    },
    callback
  })

export const ADD_PROVIDER_TO_FAVOURITES = createAsyncAction(
  'favourites/ADD_PROVIDER_TO_FAVOURITES'
)
export const addProviderToFavourites = (username, callback) =>
  apiCall({
    method: 'post',
    endpoint: '/favourites/service-provider',
    query: {
      spId: username
    },
    types: ADD_PROVIDER_TO_FAVOURITES,
    callback
  })

export const REMOVE_PROVIDER_FROM_FAVOURITES = createAsyncAction(
  'favourites/REMOVE_PROVIDER_FROM_FAVOURITES'
)
export const removeProviderFromFavourites = (favouriteSpId, callback) =>
  apiCall({
    method: 'delete',
    endpoint: '/favourites/service-provider',
    query: {
      favouriteSpId
    },
    meta: {
      favouriteSpId
    },
    types: REMOVE_PROVIDER_FROM_FAVOURITES,
    callback
  })

export const GET_FAVOURITES_PROVIDERS = createAsyncAction(
  'favourites/GET_FAVOURITES_PROVIDERS'
)
export const favouritesProviders = callback =>
  apiCall({
    method: 'get',
    endpoint: '/favourites/service-provider/list',
    types: GET_FAVOURITES_PROVIDERS,
    qsParams: {
      elements_per_page: 1000,
      no_geolocation_filter: true
    },
    callback
  })
