import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { Select } from './styles'

class SelectInput extends Component {
  state = {
    language: localStorage.getItem('lang')
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { language } = this.state
    if (language !== localStorage.getItem('lang')) {
      this.setState({ language: localStorage.getItem('lang') })
      return true
    }
    return nextProps.value !== this.props.value
  }

  render() {
    const { services, onChange, name, value, t } = this.props
    return (
      <Select component="select" name={name} onChange={onChange}>
        <option />
        {services &&
          services.map((item, key) =>
            item.subservices.length > 0 ? (
              <optgroup key={key} label={this.props.t(item.title)}>
                {item.subservices.map((childItem, key) => (
                  <option
                    key={key}
                    selected={childItem._id === value ? 'selected' : null}
                    value={[item._id, childItem._id]}
                  >
                    {t(item.title)} - {t(childItem.title)}
                  </option>
                ))}
              </optgroup>
            ) : (
              <option
                key={key}
                selected={item._id === value ? 'selected' : null}
                value={item._id}
              >
                {t(item.title)}
              </option>
            )
          )}
      </Select>
    )
  }
}

export default withNamespaces('services')(SelectInput)
