import styled from 'styled-components'
import { fontFamily, fontSize, sizes } from 'Constants'
import { space } from 'styled-system'

export const PageWrapper = styled.div`
  background: ${props => props.theme.colors.mainBackground};
  min-height: 100vh;
  @media only screen and (max-width: ${sizes.tablet}px) {
    margin-top: 75px;
  }
`
export const ContentWrapper = styled.div`
  display: flex;
  width: 70rem;
  max-width: 90%;
  margin: 0 auto;
  min-height: 60vh;
  flex-direction: column;
  @media screen and (max-width: ${p => p.theme.sizes.tablet}px) {
    max-width: 100%;
  }
`

export const TopPart = styled.div``

export const DownPart = styled.div`
  display: flex;
  margin-top: 25px;
`
export const FiltersGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  @media (max-width: ${sizes.tablet}px) {
    /* flex-direction: column-reverse; */
    flex-direction: column;
  }
`

export const FiltersGroupRight = styled(FiltersGroup)`
  float: right;
`

export const SSGroup = styled.div`
  display: flex;

  @media (max-width: ${sizes.tablet}px) {
    /* display: none; */
    margin-top: 25px;
  }
`

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  & > div > a {
    text-decoration: none;
  }
  @media only screen and (max-width: ${sizes.fhd}px) {
    width: 65%;
  }
  @media only screen and (max-width: ${sizes.hd}px) {
    width: 70%;
  }
  @media only screen and (max-width: ${sizes.desktop}px) {
    width: 100%;
  }
  @media only screen and (max-width: ${sizes.tablet}px) {
    width: 100%;
  }
`

export const RightSideBarWrapper = styled.div`
  display: flex;
  width: 35%;
  margin-left: 20px;
  flex-direction: column;
  @media only screen and (max-width: ${sizes.fhd}px) {
    width: 35%;
  }
  @media only screen and (max-width: ${sizes.hd}px) {
    width: 30%;
  }
  @media only screen and (max-width: ${sizes.desktop}px) {
    display: none;
  }
  @media only screen and (max-width: ${sizes.tablet}px) {
    display: none;
  }
`

export const GreyBgWrapper = styled.div`
  background: ${props => props.theme.colors.downloadGrey};
  padding: 30px 0;
`

export const Star = styled.div`
  display: flex;
  align-items: center;
  margin: 0 5px 0;
  & > svg {
    & > path {
      fill: ${props => props.theme.colors.premiumBg};
    }
  }
`

export const HalfWidthWrapper = styled.div`
  width: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  & > h2 {
    font-size: ${fontSize.h1};
    font-family: ${fontFamily};
    padding-bottom: 30px;
    margin: 0;
  }
  @media only screen and (max-width: ${sizes.desktop}px) {
    width: 60%;
  }
  @media only screen and (max-width: ${sizes.tablet}px) {
    width: 70%;
  }
  @media only screen and (max-width: ${sizes.phone}px) {
    width: 90%;
  }
`

export const PreloadImg = styled.img`
  width: 50%;
  height: ${props => props.isAds && '100%'};
  align-self: center;
  margin-top: 150px;
  margin-bottom: ${props => (props.isAds ? '25px' : '150px')} ${space};
`

export const PreloadWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LoadMoreWrapper = styled.div`
  width: 100%;
  height: ${props => props.isAdsPosts && '100%'};
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    margin-top: 20px;
  }
`
