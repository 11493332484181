import { socket } from 'Redux/middleware/socket'

function* onLogOut() {
  const email = localStorage.getItem('email')
  const password = localStorage.getItem('password')
  localStorage.clear()
  if (email && password) {
    localStorage.setItem('email', email)
    localStorage.setItem('password', password)
  }
  if (window.location.pathname !== '/') window.location.replace('/')
  socket.close()
  yield true
}

export default onLogOut
