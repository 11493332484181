import styled from 'styled-components'

export const Wrapper = styled.div`
  color: ${props => props.theme.colors.defaultFont};
  background-color: ${props => props.theme.colors.premiumBg};
  border-radius: 50%;
  width: ${props => props.size || '20'}px;
  min-width: ${props => props.size || '20'}px;
  height: ${props => props.size || '20'}px;
  min-height: ${props => props.size || '20'}px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    width: ${props => props.iconSize || '14'}px !important;
  }
`
