import * as routes from 'Routes/routesConstants'
import memoize from 'memoize-one'

export const getProfileBC = memoize((t, userName) => [
  {
    displayName: t('homePage:findHelp'),
    url: routes.findHelp
  },
  {
    displayName: userName
  }
])
