import * as routes from 'Routes/routesConstants'

export const getSortData = getText => [
  {
    text: getText('new'),
    value: 'new'
  },
  {
    text: getText('distance'),
    value: 'distance'
  },
  {
    text: getText('budget'),
    value: 'budget'
  },
  {
    text: getText('doneBefore'),
    value: 'doneBefore'
  },
  {
    text: getText('expiryDate'),
    value: 'expiryDate'
  }
]

export const TABS = [
  {
    id: 1,
    text: 'homePage:bookingRequests',
    setId: 'a'
  },
  {
    id: 2,
    text: 'homePage:hiringRequests',
    setId: 'b'
  }
]

export const path = {
  requestSPDetail: `${routes.myRequests}${routes.hiringRequests}${routes.requestId}`,
  requestJobDetail: `${routes.myRequests}${routes.bookingRequests}${routes.requestId}${routes.jobDescription}`,
  bookingRequests: `${routes.myRequests}${routes.bookingRequests}`,
  hiringRequests: `${routes.myRequests}${routes.hiringRequests}`,
  peopleWhoApplied: `${routes.myRequests}${routes.bookingRequests}${routes.requestId}${routes.jobDescription}${routes.appliedServiceProviders}`,
  appliedProfile: `${routes.myRequests}${routes.bookingRequests}${routes.requestId}${routes.jobDescription}${routes.appliedServiceProviders}${routes.freelancerProfile}`
}
