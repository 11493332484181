import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'

import { findJobs } from 'Routes/routesConstants'
import { more } from 'Constants/mockHowItWorks'
import Jumbotron from './innerBlocks/Jumbotron'
import ExplanationBlock from './innerBlocks/ExplanationBlock'
import Advantages from './innerBlocks/Advantages'
import MoreBlock from './innerBlocks/MoreBlock'
import DownloadBlock from './innerBlocks/DownloadBlock'
import QuoteBlock from './innerBlocks/QuoteBlock'
import { firstRow, secondRow, free } from './items'
import {
  HorizontalLine,
  Services,
  BurgerMenu,
  Header,
  Footer
} from 'Components/blocks'
import { Modal } from 'Components/ui'
import { ShowVideo, DonateButton, CookieDisclaimer, CookieText, CookieButton } from './styles'
import { icons } from 'Assets/icons'
import DonateModal from './innerBlocks/DonateModal'
import { PaymentFailedModal } from 'Components/blocks'
import { clientUrl } from 'Constants/api'
import { toast } from 'react-toastify'

class LandingPage extends Component {
  state = {
    isShowVideoModal: false,
    isShowDonateModal: false,
    isDonating: false,
    isDonateFailed: false,
    isCookieShow: true,
  }

  componentDidMount() {
    const { onGetServices, onGetPopularServices, location, history, t } = this.props

    onGetServices()
    onGetPopularServices()

    const query = new URLSearchParams(location.search)
    const status = query.get('status')
    if (status === 'success') {
      history.push('/')
      toast.success(t('landingPage:thanksYou'))
    }

    if (status === 'failed') {
      history.push('/')
      this.setState({ isDonateFailed: true }, () => {
        setTimeout(() => this.setState({ isDonateFailed: false }), 3000)
      })
    }
  }

  handleShowVideo = () => {
    this.setState({ isShowVideoModal: true })
  }

  handleHideVideo = () => this.setState({ isShowVideoModal: false })

  filterJobs = (categories) => {
    this.props.history.push(findJobs, { filter: { categories }})
  }

  handleClickDonateButton = () => this.setState({ isShowDonateModal: true })

  handleCloseDonationModal = () => this.setState({ isShowDonateModal: false })

  handleDonate = budget => {
    const { onDonate } = this.props

    const body = {
      amount: Number(budget),
      client_url_callback: clientUrl
    }
    this.setState({ isDonating: true })
    onDonate(body, () => {
      this.setState({ isDonating: false })
    })
  }

  render() {
    const { services, user, t } = this.props
    const { isShowVideoModal, isShowDonateModal, isDonating, isDonateFailed } = this.state
    return (
      <>
        <BurgerMenu isAuthorized={false} />
        <Header postJob={true} view />
        <Jumbotron onShowVideo={this.handleShowVideo} />
        <Services items={services.popularServices} filterJobs={this.filterJobs} />
        <HorizontalLine />
        <Advantages firstRow={firstRow} isCards secondRow={secondRow} />
        <HorizontalLine />
        <MoreBlock isCards items={free} />
        <HorizontalLine />
        <ExplanationBlock items={more} />
        <DownloadBlock />
        <QuoteBlock />
        <Footer black={true} isAuth={user} />
        {isShowVideoModal && (
          <Modal
            height="31%"
            isShow
            width="auto"
            onRequestClose={this.handleHideVideo}
          >
            <ShowVideo
              title="landingPageFrame"
              src="https://www.youtube.com/embed/gGd_DIZY_nc"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Modal>
        )}
        <DonateButton src={icons.donateButton} alt="" onClick={this.handleClickDonateButton} />
        <DonateModal
          isVisible={isShowDonateModal}
          onCancel={this.handleCloseDonationModal}
          onSubmit={this.handleDonate}
          isLoading={isDonating}
        />
        <PaymentFailedModal
          isVisible={isDonateFailed}
          onClose={() => this.setState({ isDonateFailed: false })}
        />
        <CookieDisclaimer style={{visibility: this.state.isCookieShow?'visible':'hidden'}}>
          <CookieText>
            {t("landingPage:cookieText")}
          </CookieText>
          <CookieButton onClick={() => {this.setState({isCookieShow: false})}}>
            {t("landingPage:cookieButton")}
          </CookieButton>
        </CookieDisclaimer>
      </>
    )
  }
}

export default withNamespaces('sign')(LandingPage)
