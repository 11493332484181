import styled from 'styled-components'
import { space } from 'styled-system'
import { fontFamily, sizes, fontSize } from 'Constants'

export const ContentHeaderContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  position: relative;
`

export const ContentHeader = styled.h1`
  margin: 0;
  font-size: ${fontSize.h1};
  font-family: ${fontFamily};
  @media (max-width: ${sizes.tablet}px) {
    font-size: ${fontSize.h2};
  }
  @media (max-width: ${sizes.phone}px) {
    font-size: ${fontSize.h3};
  }
`

export const ContnentHeaderBackButton = styled.img`
  position: absolute;
  left: 40px;
  top: 3px;
  width: 2em;
  height: 2em;
  @media (max-width: ${sizes.tablet}px) {
    top: -3px;
  }
  @media (max-width: ${sizes.phone}px) {
    left: 10px;
  }
`

export const Row = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  margin-top: ${props => props.mt || '5'}px;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: ${props => (props.noWrap ? 'nowrap' : 'wrap')};
  & .react-datepicker-popper {
    z-index: 2;
  }
  & > label {
    text-align: left;
    font-size: ${fontSize.default};
    font-family: ${fontFamily};
    margin: 10px 0 3px 0;
  }

  @media only screen and (max-width: ${sizes.desktop}px) {
    ${props =>
      !props.noBreak &&
      `
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    `};
  }

  ${space}
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 40px;
  margin-top: 20px;
`

export const Block = styled.div`
  margin-bottom: 20;
`;

export const Loading = styled.div`
  display: flex;
  height: calc(100vh - 225px);
  width: 100%;
  justify-content: center;
  & img {
    margin: auto;
    height: 16px;
  }
`