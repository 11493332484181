import styled from 'styled-components'
import { fontFamily, fontSize } from 'Constants'

export const HeaderTitle = styled.h1`
  margin: 0;
  color: white;
  line-height: 1;
`
export const ContainerText = styled.h3`
  margin: 0;
  color: white;
  font-size: ${fontSize.small};
  font-family: ${fontFamily};
  line-height: 3;
`
