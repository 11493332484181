import styled from 'styled-components'
import { ProfileImage } from 'Components/blocks/ProfileImage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserLock } from '@fortawesome/free-solid-svg-icons'
import { sizes } from 'Constants'
import ReactLoading from 'react-loading'

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  background: #fff;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  @media (max-width: ${sizes.phone}px) {
    position: relative;
    flex-direction: column;
    align-items: start;
  }
`

export const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 20%;
  min-width: 150px;
  align-items: center;
`

export const ProfileAvatar = styled(ProfileImage)`
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
`

export const ProfileName = styled.span`
  font-weight: bold;
  font-size: ${p => p.theme.fontSize.default};
  color: #000;
`

export const JobInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-basis: 80%;
  justify-content: center;
  @media (max-width: ${sizes.phone}px) {
    margin-top: 7px;
    margin-bottom: 10px;
  }
`

export const JobInfoText = styled.div`
  font-size: ${p => p.theme.fontSize.default};
  color: silver;
  font-weight: bold;
  margin-right: 5px;
  min-width: 77px;
`

export const JobTitle = styled(JobInfoText)`
  color: ${p => p.theme.colors.joinUs};
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media (max-width: ${sizes.desktop}px) {
    max-width: 20vw;
  }
  @media (max-width: ${sizes.tablet}px) {
    max-width: 15vw;
  }
  @media (max-width: ${sizes.phone}px) {
    flex-basis: 100%;
    max-width: 150px;
  }
`

export const RightBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-basis: 20%;
  @media (max-width: ${sizes.phone}px) {
    position: absolute;
    top: 18px;
    right: 12px;
  }
`

export const BlockChatButton = styled(FontAwesomeIcon).attrs(() => ({
  icon: faUserLock
}))`
  cursor: pointer;
  color: ${p => (p.isChatBlocked ? 'red' : p.theme.colors.black)};
`

export const StyledReactLoading = styled(ReactLoading).attrs(() => ({
  type: 'spokes',
  color: 'black',
  height: 25,
  width: 25
}))``
