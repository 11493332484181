import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'
import { GoogleApiWrapper } from 'google-maps-react'
import { withNamespaces } from 'react-i18next'

import { getGeo } from 'Redux/actions/googleGeocoding'
import { fetchUser, updateUser, verifyAttribute } from 'Redux/actions/user'
import {
  linkCard,
  getCard,
  updateCard,
  deleteCard
} from 'Redux/actions/paymentsTransactions'

import { getCredentials } from 'Redux/selectors/auth'
import { getServices } from 'Redux/selectors/services'
import { getProfile } from 'Redux/selectors/user'
import { makeSelectCard } from 'Redux/selectors/paymentsTransactions'

import Component from './PaymentSetup'

import { gooogleApiKey } from 'Constants'

const selector = createStructuredSelector({
  user: getProfile,
  services: getServices,
  credentials: getCredentials,
  cards: makeSelectCard
})

const actions = {
  onFetchUser: fetchUser,
  onUpdateProfile: updateUser,
  onVerifyAttribute: verifyAttribute,
  onGetGeo: getGeo,
  onLinkCard: linkCard,
  onGetCard: getCard,
  onUpdateCard: updateCard,
  onDeleteCard: deleteCard
}

export default compose(
  withNamespaces('profilePage'),
  GoogleApiWrapper({
    apiKey: gooogleApiKey,
    libraries: ['places']
  }),
  connect(selector, actions)
)(Component)
