import { createSelector } from 'reselect'

const getData = store => store.requests

export const getJobsRequests = createSelector(
  getData,
  data => data.jobs
)

export const getSPRequests = createSelector(
  getData,
  data => data.sp
)
