import { getAwsCredential } from 'Helpers/awsCredential'

const AWS = require('aws-sdk')
const { AWScreds } = require('./aws')

const s3 = new AWS.S3({
  credentials: AWScreds,
  signatureVersion: 'v4',
  region: 'us-east-1',
  s3DisableBodySigning: false
})

export const updateCredentials = (credentials) => {
  const {
    AccessKeyId: accessKeyId,
    SecretAccessKey: secretAccessKey,
    SessionToken: sessionToken
  } = getAwsCredential(credentials)
  const newCredentials = new AWS.Credentials({accessKeyId, secretAccessKey, sessionToken});
  s3.config.update({ credentials: newCredentials });
}

export const call = (action, params) => {
  if (!params.Bucket) {
    params.Bucket = process.env.REACT_APP_DEFAULT_BUCKET_NAME
  }

  return s3[action](params).promise()
}

export const instance = s3

