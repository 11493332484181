import React from 'react'
import PropTypes from 'prop-types'

import { Container, Icon } from './style'

const SmallLoader = ({ active, onClick }) => (
  <Container active={active} onClick={onClick}>
    <Icon />
  </Container>
)

SmallLoader.propTypes = {
  visible: PropTypes.bool
}

export { SmallLoader }
