import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { fontFamily, fontSize, sizes } from 'Constants'
import {
  Form,
  AuthFormWrapper,
  CheckBoxText,
  ForgetLink,
  ForgotWrap,
  Label
} from 'Components/ui'

export const StyledForm = styled(props => <Form {...props} />)`
  @media (max-width: ${sizes.phone}px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`

export const StyledAuthFormWrapper = styled(props => <AuthFormWrapper {...props} />)`
  @media (max-width: ${sizes.phone}px) {
    width: 100%;
  }
`

export const StyledForgotWrap = styled(props => <ForgotWrap {...props} />)`
  @media (max-width: ${sizes.phone}px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`

export const RememberLabel = styled(props => <Label {...props} />)`
  @media (max-width: ${sizes.phone}px) {
    width: 100%;
    margin-bottom: 5px;
  }
`

export const StyledForgetLink = styled(props => <ForgetLink {...props} />)`
  @media (max-width: ${sizes.phone}px) {
    width: 100%;
    margin-left: 0;
    text-align: left;
  }
`

export const StyledCheckBoxText = styled(props => <CheckBoxText {...props} />)`
  @media (max-width: ${sizes.phone}px) {
    padding-left: 5px;
  }
`

export const SignUp = styled.p`
  margin: 0;
  font-size: ${fontSize.small};
  font-family: ${fontFamily};
  text-align: center;
  padding-top: 10px;
`

export const SignUpLink = styled(props => <Link {...props} />)`
  color: #3a83cb;
  text-decoration: none;
`

export const BtnSubmitWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`
