import _ from 'lodash'
import {SERVICE_URL} from '../Constants/serviceUrl'


export function filteredServices(items = [], value = '', t) {
  return items.filter(el => {
    if (!_.isEmpty(el.subservices)) {
      const isApprove = el.subservices.find(item => {
        const title = t(item.title)
        return (title || '').toLowerCase().indexOf(value.toLowerCase()) !== -1
      })

      if (isApprove) return true
    }
    const title = t(el.title)
    return (title || '').toLowerCase().indexOf(value.toLowerCase()) !== -1
  })
}

export const getServiceImageUrl = fileName =>
  `${SERVICE_URL}${fileName}`

export const getCurrentService = (services, serviceId, categoryId) => {
  let result = null

  services.forEach(el => {
    if (el._id === serviceId || el._id === categoryId) result = el
    else if (!_.isEmpty(el.subservices)) {
      const subService = el.subservices.find(({ _id }) => _id === serviceId)
      if (subService)
        result = {
          ...subService,
          parentCategory: el,
        }
    }
  })

  return result
}
