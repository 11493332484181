import styled from 'styled-components'
import { getThemeField } from 'Theme'

export const PreviewRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  width: 100%;
`

export const PreviewContainer = styled.div`
  width: 100%;
  background: ${getThemeField('palette.white')};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 10px auto;
  transition: all 0.3s ease-in;
`
