import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'
import { withNamespaces } from 'react-i18next'

import { getProfile } from 'Redux/selectors/user'

import { connectPaypal, withdraw } from 'Redux/actions/paymentsTransactions'
import { fetchUser } from 'Redux/actions/user'

import Component from './WithdrawalMoney'

const selector = createStructuredSelector({
  user: getProfile
})

const actions = {
  onConnectPaypal: connectPaypal,
  onFetchUser: fetchUser,
  onWithdraw: withdraw
}

export default compose(
  withNamespaces('profilePage'),
  connect(selector, actions)
)(Component)
