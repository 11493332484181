import React from 'react'
import PropTypes from 'prop-types'

import { Text } from './style'

const SubTitle = ({ children }) => <Text>{children}</Text>

SubTitle.propTypes = {
  children: PropTypes.string
}

export default SubTitle
