import { createReducer } from 'Helpers/redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { jobStatuses } from 'Constants/jobStatuses'

import {
  LOAD_BOOKED_JOB,
  MARK_AS,
  CANCEL_BOOKED_JOB,
  // START_THE_JOB
} from 'Redux/actions/bookedJobs'
import { HIRE_JOB } from 'Redux/actions/postedJobs'
import { CANCEL_APPLIED_JOB } from 'Redux/actions/appliedJobs'

const persistConfig = {
  key: 'bookedJobs',
  storage,
  blacklist: []
}

const initialState = {
  data: [],
  isListEnd: false
}

const handlers = {
  [LOAD_BOOKED_JOB.SUCCESS]: (prevState, { payload }) => ({
    ...prevState,
    data: payload.list.filter(
      el =>
        el.jobStatus === jobStatuses.booked ||
        el.jobStatus === jobStatuses.completed ||
        el.jobStatus === jobStatuses.done
    )
  }),
  [MARK_AS.SUCCESS]: (state, { payload }) => ({
    ...state,
    data: state.data.map(el =>
      el._id === payload._id ? { ...el, jobStatus: payload.jobStatus } : el
    )
  }),
  [CANCEL_BOOKED_JOB.SUCCESS]: (state, { meta, payload }) => {
    if (payload?.jobStatus === jobStatuses.posted) {
      return {
        ...state,
        data: state.data.filter(el => el._id !== meta.jobId)
      }
    }

    const index = state.data.findIndex(el => el._id === meta.jobId)

    if (index > -1) {
      return {
        ...state,
        data: [
          ...state.data.slice(0, index),
          {
            ...state.data[index],
            ...payload,
          },
          ...state.data.slice(index + 1)
        ]
      }
    }

    return { ...state }
  },
  [HIRE_JOB.SUCCESS]: (state, { meta }) => ({
    ...state,
    data: state.data.filter(el => el._id !== meta.jobId)
  }),
  // [START_THE_JOB.SUCCESS]: (state, { meta }) => {
  //   const index = state.data.findIndex(job => job._id === meta.jobId)
  //   if (meta.isAuthor) {
  //     return {
  //       ...state,
  //       data: [
  //         ...state.data.slice(0, index),
  //         {
  //           ...state.data[index],
  //           authorStartJob: true
  //         },
  //         ...state.data.slice(index + 1)
  //       ]
  //     }
  //   }

  //   return {
  //     ...state,
  //     data: [
  //       ...state.data.slice(0, index),
  //       {
  //         ...state.data[index],
  //         doerStartJob: true
  //       },
  //       ...state.data.slice(index + 1)
  //     ]
  //   }
  // }
  [CANCEL_APPLIED_JOB.SUCCESS]: (state, { meta, payload }) => {
    if (payload?.jobStatus === jobStatuses.posted) {
      return {
        ...state,
        data: state.data.filter(el => el._id !== meta.jobId)
      }
    }

    const index = state.data.findIndex(el => el._id === meta.jobId)

    if (index > -1) {
      return {
        ...state,
        data: [
          ...state.data.slice(0, index),
          {
            ...state.data[index],
            ...payload,
          },
          ...state.data.slice(index + 1)
        ]
      }
    }

    return { ...state }
  },
}

export default persistReducer(
  persistConfig,
  createReducer(initialState, handlers)
)
