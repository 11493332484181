import React, { PureComponent } from 'react'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import _ from 'lodash'
import {
  Container,
  SuggestionsWindow,
  SuggestionItem,
  SuggestionItemActive
} from './styles'

class LocationAutocomplete extends PureComponent {
  state = {
    isFocused: false,
    isSubmitting: true
  }

  handleChange = address => {
    const { onChange } = this.props

    this.setState(state => state.isSubmitting && { isSubmitting: false })
    onChange(address)
  }

  handleSetFocus = event => {
    const { inputProps } = this.props
    const { isFocused, isSubmitting } = this.state

    if (!isFocused && _.isFunction(inputProps.onFocus))
      inputProps.onFocus(event)
    if (isFocused && _.isFunction(inputProps.onBlur)) {
      inputProps.onBlur(event)
      if (!isSubmitting) this.handleSelect()
    }

    this.setState({ isFocused: !isFocused })
  }

  handleSelect = address => {
    const { onSelect, value } = this.props

    this.setState({ isSubmitting: true })
    if (!address) onSelect({ formatted_address: value })

    geocodeByAddress(address)
      .then(results => {
        if (results && results.length) {
          onSelect(results[0])
        }
      })
      .catch(error => {
        console.error('Error', error)
        if (error === 'ZERO_RESULTS') {
          onSelect({ formatted_address: value })
        }
      })
  }

  render() {
    const {
      inputProps,
      InputComponent,
      searchOptions,
      value,
      modal = false
    } = this.props
    const { isFocused, isSubmitting } = this.state

    return (
      <PlacesAutocomplete
        searchOptions={searchOptions}
        value={value}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <Container modal={modal}>
            <InputComponent
              {...getInputProps()}
              {...inputProps}
              autoComplete="off"
              data-lpignore="true"
              value={value}
              onBlur={this.handleSetFocus}
              onFocus={this.handleSetFocus}
            />
            {!isSubmitting && isFocused && !_.isEmpty(suggestions) && (
              <SuggestionsWindow>
                {loading && <div>Loading...</div>}
                {suggestions.map(suggestion => {
                  const Item = suggestion.active
                    ? SuggestionItemActive
                    : SuggestionItem
                  return (
                    <Item
                      {...getSuggestionItemProps(suggestion)}
                      key={suggestion.placeId}
                    >
                      {suggestion.description}
                    </Item>
                  )
                })}
              </SuggestionsWindow>
            )}
          </Container>
        )}
      </PlacesAutocomplete>
    )
  }
}

export { LocationAutocomplete }
