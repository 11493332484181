import { MOUNT_CHANNELS } from 'Redux/actions/socket'
import { SOCKET_EVENT } from 'Constants'
import { map, has, isEmpty } from 'lodash'
import io from 'socket.io-client'

import { socketAuthentificate } from 'Redux/actions/socket'
import { inSocketTypes } from 'Constants/socketEventTypes'
import { getAccessToken } from 'Redux/selectors/auth'
import { getChats, getActiveChat } from 'Redux/selectors/chats'
import { getProfile } from 'Redux/selectors/user'
import { getMessageDetailAndActive, getMessageDetail } from '../../actions/chats'

export const socket = io.connect(process.env.REACT_APP_SOCKET_API, {
  path: '/socket',
  autoConnect: false
})

const socketMiddleware = store => next => action => {
  if (action.type === MOUNT_CHANNELS.REQUEST) {
    socket.open()
    const token = getAccessToken(store.getState())

    map(inSocketTypes, type => {
      socket.on(type, payload => {
        if (type === inSocketTypes.messageReceived) {
          const { chatId, jobId, userReceived } = payload
          const state = store.getState()
          const chats = getChats(state)
          const activeChat = getActiveChat(state)
          const profile = getProfile(state)
          const chatDetail = has(chats, `chats.${chatId}`)

          if (profile._id === userReceived) {
            if (isEmpty(activeChat)) {
              store.dispatch(getMessageDetailAndActive({ id: jobId, chat_id: chatId }, null))
            } else if(!chatDetail) {
              store.dispatch(getMessageDetail({ id: jobId, chat_id: chatId }, null))
            }
          }
        }

        next({ type: `socket/${type.toUpperCase()}`, payload })
      })
    })

    setTimeout(() => {
      store.dispatch(socketAuthentificate(token))
    }, 500)

    return next({ type: MOUNT_CHANNELS.SUCCESS })
  }

  if (action.type === SOCKET_EVENT) {
    const { endpoint, query, types, meta } = action.fields
    next({ type: types.event, payload: query, meta })
    if (types.event === 'chats/SEND_MESSAGE') {
      let isSuccess = false;
      socket.emit(endpoint, query, response => {
        if (response === 'ok') {
          isSuccess = true;
          return;
        }
      });
      setTimeout(() => {
        if (!isSuccess) {
          next({type: 'MESSAGE_FAIL', meta});
        }
      }, 3000)
      return;
    }

    socket.emit(endpoint, query)

    return
  }

  return next(action)
}

export default socketMiddleware
