import React, { useState } from 'react'
import {
  InputLabel,
  Label,
  CheckBoxText,
  DatePickerWrapper,
  Row,
  Col4,
  Col12,
  CheckboxInput,

} from '../style'

import { DatePicker } from 'Components/ui'
import Input from '../Atom/Input-field'

const CANCELLATION_FEE = 3.45

const PriceDetails = ({ validatorConfig, onHandleChange, t, radius, onRadiusChange}) => {

  const { noOfWeek, cancelationFee, total,radiusMap } = validatorConfig
  const [startDate, setStartDate] = useState(new Date())
  const [endingDate, setEndingDate] = useState(new Date())
 

   
  // useEffect(() => {
  //   onHandleChange(
  //     {
  //       target: {
  //         name: 'startDate',
  //         value: startDate
  //       }
  //     },
  //     true
  //   )

  //   onHandleChange(
  //     {
  //       target: {
  //         name: 'endDate',
  //         value: endingDate
  //       }
  //     },
  //     true
  //   )

  // }, [startDate, endingDate, onHandleChange])

  const onCheckedItem = event => {
    onHandleChange(
      {
        target: {
          name: cancelationFee.name,
          value: event.target.checked
        }
      },
      true
    )
  }

  

  return (
    <div>
      <Row>
        <Col4>
        <InputLabel>{t('map-radius')} ({t('km-mile')}) </InputLabel>
        <Input {...radiusMap} 
          onHandleChange={(event) => {
            onHandleChange(event);
            onRadiusChange(event.target.value);
          }}
          value={radius}
          minValue={11} >
        </Input> 

          
        </Col4>
       
        <Col4>
          <InputLabel mandatory>{t('str-date')}</InputLabel>
          <DatePickerWrapper>
            <DatePicker
              dateFormat="MMMM d, yyyy "
              selected={startDate}
              value={startDate}
              handleChange={date => {
                setStartDate(date)
                onHandleChange(
                  {
                    target: {
                      name: 'startDate',
                      value: startDate
                    }
                  },
                  true
                )
              }}
            />
          </DatePickerWrapper>
        </Col4>
        <Col4>
          <InputLabel mandatory>{t('end-date')}</InputLabel>
          <DatePickerWrapper>
            <DatePicker
              dateFormat="MMMM d, yyyy "
              selected={endingDate}
              value={startDate}
              handleChange={date => {
                setEndingDate(date)
                onHandleChange(
                  {
                    target: {
                      name: 'endDate',
                      value: endingDate
                    }
                  },
                  true
                )
              }}
            />
          </DatePickerWrapper>
        </Col4>
      </Row>
      <Row>
        <Col4>
          <InputLabel mandatory>{t('num-of-week')}</InputLabel>
          <Input {...noOfWeek} onHandleChange={onHandleChange} />
        </Col4>
        <Col4>
          <InputLabel>{t('cancel-insurance')}</InputLabel>
          <Label htmlFor={cancelationFee.name}>
            <CheckboxInput
              type="checkbox"
              name={cancelationFee.name}
              onClick={onCheckedItem}
            />
            <CheckBoxText>{`USD ${CANCELLATION_FEE}`}</CheckBoxText>
          </Label>
        </Col4>
        <Col4>
          <InputLabel>{t('total-in-dolar')}</InputLabel>
          <Input {...total} onHandleChange={onHandleChange} />
        </Col4>
      </Row>
      <Row>
        <Col12>
          <InputLabel>
              {t('cancel-insurance-allow')}
          </InputLabel>
        </Col12>
      </Row>
    </div>
  )
}

export default PriceDetails
