import React from 'react'
import styled from 'styled-components'
import { Ad } from 'Components/blocks'
import { fontFamily, fontSize, sizes } from 'Constants'
import { arrows } from 'Assets/arrows'
import { Label as UILabel } from 'Components/ui'
//import { Error as Errors, BtnSubmit } from 'Components/ui'

export const Container = styled.div`
  ${props =>
    props.colorDisabled
      ? `background:transparent`
      : `
  background: #fff;
  box-shadow: 0 0 5px rgb(0 0 0 / 15%);

`}
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  border-radius: 20px;
  margin: auto;
  margin-top: 30px;
  @media (min-width: 801px) {
    width: 700px;
  }
`

export const GoogleAdsFirst = styled(props => <Ad {...props} />)`
  margin-bottom: 20px;
`

export const Input = styled.input`
  width: 100%;
  margin: 0 auto;
  margin-top: 10px;
  border: 2px solid #ececec;
  min-height: 46px;
  border-radius: 5px;
  outline: none;
  padding: 10px 15px;
  font-size: ${fontSize.h5};
  font-family: ${fontFamily};
  background-image: none !important;
  &:focus {
    border-color: #a7c0f4;
  }
  &:-webkit-autofill {
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0 1000px #5da6ff inset;
  }
`
export const InputLabel = styled.label`
  color: black;
  font-weight: bold;
  font-size: 15px;
  padding-bottom: 20px;
  ${props =>
    props.mandatory
      ? `::after {
    content: '*';
    color: red;
    padding-left: 2px;
    
  }`
      : ``}
`
export const Select = styled.select`
  width: 100%;
  border: 2px solid #ececec;
  border-radius: 5px;

  background: #fff;
  padding: 10px 15px;

  margin-top: 10px;
  font-size: ${fontSize.h5};
  font-family: ${fontFamily};
  position: relative;
  background: url('${arrows.arrowDown}') no-repeat;
  background-position: 95% 50%;
  appearance: none;
  &::-ms-expand {
    display: none;
  }
  &:-webkit-autofill {
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0 1000px #5da6ff inset;
    border: none;
  }
  @media (max-width: ${sizes.phone}px) {
    text-indent: 0px;
    font-size: ${fontSize.h6};
    padding-left: 0;
    /* & option {
      background: red;
    } */

	/* text-indent:-1.5px;
	padding-left:2.5px;
	padding-left:5px; */

  }
`

export const DatePickerWrapper = styled.div`
  & > .react-datepicker-wrapper {
    width: 100%;
    & > .react-datepicker__input-container {
      width: 100%;
      margin-top: 5px;
      & > input {
        width: 100%;
      }
    }
  }
`
export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: 801px) {
    flex-direction: row;
  }
`
export const Col12 = styled.div`
  width: 100%;
  padding: 10px;
`
export const Col6 = styled.div`
  width: 100%;
  padding: 10px;
  @media (min-width: 801px) {
    width: 50%;
  }
`
export const Col4 = styled.div`
  width: 100%;
  padding: 10px;
  @media (min-width: 801px) {
    width: 33%;
  }
`

export const Col3 = styled.div`
  width: 100%;
  padding: 10px;
  @media (min-width: 801px) {
    width: 25%;
  }
`
export const Col9 = styled.div`
  width: 100%;
  padding: 10px;
  @media (min-width: 801px) {
    width: 75%;
  }
`

export const Title = styled.h3`
  margin: 20px;
  text-align: center;
  font-size: ${fontSize.h1};
  font-family: ${fontFamily};
`

export const CheckboxInput = styled.input`
  /* width: auto;
  opacity: 0;
  position: absolute; */
`

// export const CheckBoxSpan = styled.span`
//   position: relative;
//   cursor: pointer;
//   height: 20px;
//   width: 20px;
//   border: ${props =>
//     props.toggle ? '2px solid transparent' : '2px solid #eee'};
//   background: ${props => (props.toggle ? '#2196F3' : 'transparent')};
//   border-radius: 3px;
//   &:after {
//     content: '';
//     position: absolute;
//     display: block;
//     left: 5px;
//     top: 0;
//     width: 5px;
//     height: 10px;
//     border: ${props => (props.toggle ? 'solid #fff' : 'solid transparent')};
//     border-width: 0 3px 3px 0;
//     transform: rotate(45deg);
//   }
// `
export const CheckBoxText = styled.p`
  margin: 0;
  padding-left: 10px;
  cursor: pointer;
  font-size: ${fontSize.small};
`

export const Label = styled(props => <UILabel {...props} />)`
  margin: 25px 0 15px 0;
`

export const Button = styled.button`
  margin: 5px;
  outline: none;
  width: 45%;
  ${props =>
    props.submit
      ? `    background: linear-gradient(to right,#459aee 0%,#3ad6ff 99%);     color: #fff;`
      : `    background: linear-gradient(
-75deg
,#f0f0f0,#cfcfcf);
    color: #6b6b6c;`}
  border: none;
  background: linear-gradient(to right, #459aee 0%, #3ad6ff 99%);
  padding: 10px;
  border-radius: 100px;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
`
export const InputShort = styled(props => <Input {...props} />)`
  width: 20%;
  @media only screen and (max-width: ${sizes.desktop}px) {
    width: 100%;
  }
`