import React from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'
import { Button } from 'Components/ui'
import { fontSize } from 'Constants'

export const Container = styled.div``

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  ${space};
  @media (max-width: ${p => p.theme.sizes.tablet}px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`

export const InputWrapper = styled.div`
  flex-basis: 45%;
  min-width: 200px;
  @media (max-width: ${p => p.theme.sizes.tablet}px) {
    flex-basis: initial;
    width: 100%;
    margin-bottom: 10px;
  }
`

export const ButtonWrapper = styled(InputWrapper)`
  display: flex;
  justify-content: center;
`

export const ChangeButton = styled(props => <Button {...props} />)`
  letter-spacing: 1px;
  width: 100%;
  text-transform: uppercase;
  font-weight: bold;
  font-size: ${p => p.theme.fontSize.default};
`

export const Content = styled.div`
  position: relative;
`

export const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s, z-index 0.5s;
  opacity: ${p => (p.isVisible ? 1 : 0)};
  z-index: ${p => (p.isVisible ? 1 : -1)};
`

export const Title = styled.h2`
  font-size: ${fontSize.h2};
  font-weight: bold;
  margin: 0;
`
