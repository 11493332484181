import PropTypes from 'prop-types'
import React from 'react'
import { withNamespaces } from 'react-i18next'
import moment from 'moment'
import _ from 'lodash'

import ServiceSubtitle from './subtitle'
import {
  Wrapper,
  Block,
  GroupBlocks,
  Title,
  SubTitle,
  AddressList,
  AddressElement,
  AddressLink
} from './styles'

import { getAddress } from './memoize'

const CategoryInfo = ({ t, isShowAddress, data, onShowMap }) => (
  <Wrapper>
    <GroupBlocks>
      <Block>
        <Title>{t('category')}</Title>
        <ServiceSubtitle text={data.category} />
      </Block>
      {!_.isEmpty(data.subCategory) && (
        <Block>
          <Title>{t('subCategory')}</Title>
          <ServiceSubtitle text={data.subCategory} />
        </Block>
      )}
    </GroupBlocks>
    <GroupBlocks>
      <Block>
        <Title>{t('createdOn')}</Title>
        <SubTitle>{moment(data.created).format('lll')}</SubTitle>
      </Block>
      <Block>
        <Title>{t('jobId')}</Title>
        <SubTitle>{data.jobId}</SubTitle>
      </Block>
    </GroupBlocks>
    {isShowAddress && (
      <>
        <AddressList>
          <AddressElement>{t('landingPage:fields.address')}</AddressElement>
          {getAddress(data).map((el, index) => (
            <AddressElement key={index}>{el}</AddressElement>
          ))}
        </AddressList>
        {_.isFunction(onShowMap) && (
          <AddressLink onClick={onShowMap}>
            {t('myJobsDetail:seeAddressOnMap')}
          </AddressLink>
        )}
      </>
    )}
  </Wrapper>
)

export default withNamespaces([
  'jobDescription',
  'landingPage',
  'myJobsDetail'
])(CategoryInfo)

CategoryInfo.propTypes = {
  data: PropTypes.object,
  t: PropTypes.func,
  onShowMap: PropTypes.func
}
