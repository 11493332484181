import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import _ from 'lodash'

import { createReducer } from 'Helpers/redux'
import { filterDeletedItems } from 'Helpers/filterDeletedItems'

import { CANCEL_APPLIED_JOB } from 'Redux/actions/appliedJobs'
import { MARK_AS, CANCEL_BOOKED_JOB, DISPUTE_JOB } from 'Redux/actions/bookedJobs'
import { ACCEPT_JOB, REJECT_JOB, HIRE_JOB } from 'Redux/actions/postedJobs'
import {
  GET_JOB_DESCRIPTION,
  GET_JOB_LIST,
  REMOVE_JOB,
  APPLY_JOB
} from 'Redux/actions/job'
import {
  ADD_JOB_TO_FAVOURITES,
  REMOVE_JOB_FROM_FAVOURITES
} from 'Redux/actions/favourites'
import { LOG_OUT } from 'Redux/actions/auth'
import { START_THE_JOB } from 'Redux/actions/bookedJobs'

const persistConfig = {
  key: 'job',
  storage,
  blacklist: []
}

const initialState = {
  jobDescription: null,
  jobList: [],
  removedIds: [],
  isListEnd: false,
  filters: {},
  page: 0
}

const handlers = {
  [GET_JOB_LIST.REQUEST]: (state, { meta }) => ({
    ...state,
    filters: meta.filters,
    removedIds: meta.isClearRemoved ? [] : state.removedIds
  }),
  [GET_JOB_LIST.SUCCESS]: (state, { payload, meta: { isPagination } }) => {
    const result = isPagination
      ? [...state.jobList, ...payload.list]
      : payload.list
    return {
      ...state,
      jobList: filterDeletedItems(result, state.removedIds),
      isListEnd: payload.isListEnd,
      page: isPagination ? state.page + 1 : 0
    }
  },
  [GET_JOB_LIST.FAILURE]: state => ({
    ...state,
    jobList: initialState.jobList
  }),
  [GET_JOB_DESCRIPTION.REQUEST]: state => ({
    ...state,
    jobDescription: null
  }),
  [GET_JOB_DESCRIPTION.SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      jobDescription: payload
    }
  },
  [ADD_JOB_TO_FAVOURITES.SUCCESS]: (prevState, { payload }) => {
    let jobDescription = null

    if (
      prevState.jobDescription &&
      prevState.jobDescription._id === payload.jobId
    ) {
      jobDescription = { ...prevState.jobDescription, favouriteId: payload._id }
    }

    return {
      ...prevState,
      jobList: prevState.jobList.map(item => {
        if (item._id === payload.jobId) {
          item.favouriteId = payload._id
          return item
        } else {
          return item
        }
      }),
      jobDescription: jobDescription
    }
  },
  [APPLY_JOB.SUCCESS]: (prevState, { payload }) => {
    let jobDescription = null

    if (_.get(prevState, 'jobDescription._id') === payload.jobId) {
      jobDescription = {
        ...prevState.jobDescription,
        peopleWhoApplied: _.get(
          prevState,
          'jobDescription.peopleWhoApplied',
          []
        ).concat([payload])
      }
    }
    return {
      ...prevState,
      jobList: prevState.jobList.map(item => {
        if (item._id === payload.jobId) {
          item.applicationId = payload._id
          return item
        } else {
          return item
        }
      }),
      jobDescription: jobDescription
    }
  },
  [REMOVE_JOB_FROM_FAVOURITES.SUCCESS]: (prevState, { meta }) => {
    const jobDescription = prevState.jobDescription

    if (jobDescription && jobDescription.favouriteId === meta.favouriteJobId) {
      jobDescription.favouriteId = null
    }
    return {
      ...prevState,
      jobList: prevState.jobList.map(item => {
        if (item.favouriteId === meta.favouriteJobId) {
          item.favouriteId = null
          return item
        } else {
          return item
        }
      }),
      jobDescription: jobDescription
    }
  },
  [REMOVE_JOB]: (state, { id }) => ({
    ...state,
    jobList: state.jobList.filter(el => el._id !== id),
    removedIds: [...state.removedIds, id]
  }),
  [ACCEPT_JOB.SUCCESS]: (state, { payload, meta }) => ({
    ...state,
    jobDescription: {
      ...state.jobDescription,
      peopleWhoApplied: state.jobDescription.peopleWhoApplied.map(el =>
        el._id === meta.applicationId
          ? {
              ...el,
              status: payload.status,
              user: {
                ...el.user,
                chatId: payload.chatId
              }
            }
          : el
      )
    }
  }),
  [REJECT_JOB.SUCCESS]: (state, { payload, meta }) => ({
    ...state,
    jobDescription: {
      ...state.jobDescription,
      peopleWhoApplied: state.jobDescription.peopleWhoApplied.filter(
        el => el._id !== meta.applicationId
      )
    }
  }),
  [MARK_AS.SUCCESS]: (state, { payload }) => ({
    ...state,
    jobDescription:
      _.get(state, 'jobDescription._id') === payload._id
        ? { ...state.jobDescription, jobStatus: payload.jobStatus }
        : state.jobDescription
  }),
  [HIRE_JOB.SUCCESS]: (state, { meta }) => ({
    ...state,
    jobDescription: null,
    jobList: state.jobList.filter(el => el._id !== meta.jobId)
  }),
  [CANCEL_BOOKED_JOB.SUCCESS]: (state, { payload }) => ({
    ...state,
    jobDescription: {
      ...state.jobDescription,
      ..._.omit(payload, ['favouriteId', 'review'])
    }
  }),
  [CANCEL_APPLIED_JOB.SUCCESS]: (state, { payload }) => ({
    ...state,
    jobDescription: {
      ...state.jobDescription,
      ..._.omit(payload, ['favouriteId', 'review'])
    }
  }),
  [START_THE_JOB.SUCCESS]: (state, { payload, meta }) => {
    if (payload?.paypal_approval_url) {
      return {
        ...state
      }
    }

    if (meta.isAuthor) {
      return {
        ...state,
        jobDescription: {
          ...state.jobDescription,
          authorStartJob: true,
          doerStartJob: true
        }
      }
    }

    return {
      ...state,
      jobDescription: {
        ...state.jobDescription,
        doerStartJob: true
      }
    }
  },
  [DISPUTE_JOB.SUCCESS]: (state, { meta }) => ({
    ...state,
    jobDescription: {
      ...state.jobDescription,
      disputeReason: meta.isCancel ? null : meta.disputeReason,
      authorDispute: meta.sender === 'author' ? true : state.jobDescription.authorDispute,
      doerDispute: meta.sender === 'doer' ? true : state.jobDescription.doerDispute,
    }
  }),
  [LOG_OUT.REQUEST]: () => initialState
}

export default persistReducer(
  persistConfig,
  createReducer(initialState, handlers)
)
