import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { withNamespaces } from 'react-i18next'
import { withHireSP } from 'Components/hoc'
import { withRouter } from 'react-router-dom'

import { getSpecificJob } from 'Redux/selectors/jobList'

import { getProfile } from 'Redux/selectors/user'
import Component from './ApplyBySection'

const actions = {}

const selectors = createStructuredSelector({
  job: getSpecificJob,
  user: getProfile
})

export default compose(
  withNamespaces(['appServiceProvider']),
  withHireSP,
  withRouter,
  connect(
    selectors,
    actions
  )
)(Component)
