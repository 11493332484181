import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from './api'

export const SERVICES = createAsyncAction('services/SERVICES')
export const services = () =>
  apiCall({
    method: 'GET',
    endpoint: '/services',
    types: SERVICES
  })

export const POPULAR_SERVICES = createAsyncAction('services/POPULAR_SERVICES')
export const popularServices = callback =>
  apiCall({
    method: 'GET',
    endpoint: '/services/popular',
    types: POPULAR_SERVICES,
    callback
  })
