import { compose } from 'redux'
import { connect } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { logOut } from 'Redux/actions/auth'
import Component from './ProfileDropdown'

const actions = {
  onLogOut: logOut
}

export const ProfileDropdown = compose(
  connect(
    null,
    actions
  ),
  withNamespaces('common')
)(Component)
