import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { withNamespaces } from 'react-i18next'
import { withShare, withFavouriteJob, withFavouriteSP } from 'Components/hoc'

import { popularServices } from 'Redux/actions/services'
import { applyJob } from 'Redux/actions/job'
import { favouritesJobs, favouritesProviders } from 'Redux/actions/favourites'

import { getProfile } from 'Redux/selectors/user'
import { getServices } from 'Redux/selectors/services'
import { getNearestSP } from 'Redux/selectors/nearestSP'
import { getFullLibraryData } from 'Redux/selectors/favourites'

import Component from './Favourites'

const selector = createStructuredSelector({
  user: getProfile,
  services: getServices,
  favourites: getFullLibraryData,
  nearestSP: getNearestSP
})

const actions = {
  onGetFavouritesJobList: favouritesJobs,
  onGetFavouritesSPList: favouritesProviders,
  onGetPopularServices: popularServices,
  onApply: applyJob
}

export default compose(
  connect(
    selector,
    actions
  ),
  withNamespaces(['findJobPage', 'common']),
  withShare,
  withFavouriteJob,
  withFavouriteSP
)(Component)
