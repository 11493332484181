import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createReducer } from 'Helpers/redux'

import {
  SIGN_IN,
  UPDATE_TOKEN,
  REHYDRATE_TOKEN,
  LOG_OUT
} from 'Redux/actions/auth'

const initialState = {
  error: null,
  credentials: {},
  verifiedToken: false,
  isLoading: false,
  isSetChannels: false
}

const persistConfig = {
  key: 'auth',
  storage,
  blacklist: ['error']
}

const handlers = {
  [SIGN_IN.SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      credentials: payload.tokens
    }
  },
  [UPDATE_TOKEN.SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      credentials: {
        ...prevState.credentials,
        ...payload
      }
    }
  },
  [REHYDRATE_TOKEN.SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      credentials: payload.data
    }
  },
  [LOG_OUT.REQUEST]: () => initialState
}

export default persistReducer(
  persistConfig,
  createReducer(initialState, handlers)
)
