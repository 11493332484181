import React from 'react'
import PropTypes from 'prop-types'

import RSwitch from 'react-switch'

const Switch = ({ value, onClick, ...props }) => {
  return (
    <RSwitch
      checkedIcon={null}
      uncheckedIcon={null}
      {...props}
      checked={value}
      onChange={onClick}
    />
  )
}

Switch.propTypes = {
  value: PropTypes.bool,
  onClick: PropTypes.func
}

export default Switch
