import { compose } from 'redux'

import { withNamespaces } from 'react-i18next'
import { withServiceProviderProfile } from 'Components/hoc'

import Component from './DoerInfo'

export default compose(
  withNamespaces(['jobDetail']),
  withServiceProviderProfile
)(Component)
