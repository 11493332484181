import styled from 'styled-components'
import { fontFamily, sizes, fontSize } from 'Constants'

export const ItemDefaultWrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  ${props =>
    props.wrapped && `border: 1px solid ${props.theme.colors.itemBorder}`}
`

export const ContainersWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 0 50px 0;
  @media (max-width: ${sizes.phone}px) {
    padding: 30px 0;
  }
`

export const GridFourElements = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  ${ItemDefaultWrapper} {
    margin: 10px 0;
    width: 22%;
    @media (max-width: ${sizes.desktop}px) {
      width: 22%;
    }
    @media (max-width: ${sizes.tablet}px) {
      width: 45%;
    }
    @media (max-width: ${sizes.phone}px) {
      width: 90%;
    }
  }
`

export const MainTitle = styled.h2`
  text-align: center;
  margin: 0.1em;
  font-size: ${fontSize.large};
  font-family: ${fontFamily};
  font-weight: 300;
  color: ${props => props.theme.colors.defaultGrey};
  @media (max-width: ${sizes.desktop}px) {
    font-size: ${fontSize.h1};
  }
  @media (max-width: ${sizes.tablet}px) {
    font-size: ${fontSize.h2};
  }
  @media (max-width: ${sizes.phone}px) {
    font-size: ${fontSize.h3};
  }
`

export const WidthLimiter = styled.div`
  width: 90%;
  max-width: 1168px;
  margin: 0 auto;
`
