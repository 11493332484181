import React, { Component } from 'react'
import { icons } from 'Assets/icons'
import _ from 'lodash'

import { ProfileImage, ProfileDropdown } from 'Components/blocks'

import { ProfileWrapper, AvatarImg, ProfileDropdownMenu } from './styles'

class Profile extends Component {
  state = {
    isOpen: false
  }

  componentDidMount() {
    document.body.addEventListener('click', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleClickOutside)
  }

  handleClickOutside = ({ target }) => {
    const popap = target.closest('.popap')

    !popap && this.state.isOpen && this.setState({ isOpen: false })
  }

  handleToggPopap = () => this.setState(state => ({ isOpen: !state.isOpen }))

  render() {
    const { user } = this.props
    const { isOpen } = this.state

    return _.isObject(user) && !_.isEmpty(user) ? (
      <ProfileWrapper active={!_.isEmpty(user)} onClick={this.handleToggPopap}>
        <ProfileDropdownMenu>
          {user.given_name && user.family_name
            ? `${user.given_name} ${user.family_name}`
            : user.username}
        </ProfileDropdownMenu>
        <AvatarImg>
          <ProfileImage
            src={user.avatarURL || icons.avatar}
            type="avatar"
            userId={user.username}
          />
        </AvatarImg>
        <ProfileDropdown isOpen={isOpen} />
      </ProfileWrapper>
    ) : null
  }
}

export default Profile
