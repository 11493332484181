export default ({
  title,
  message,
  buttonText,
  buttonPath,
  footerText = 'Copyright © 2020 SpotJobs - Atomx llc All rights reserved. Spotjobs is a registered trademark'
}) =>
`${title}

${message}

${buttonText}
${buttonPath}

${footerText}
`