import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { TruncateText, ArrowIcon } from './styles'

class ReadMore extends Component {
  state = {
    truncated: true
  }

  handleClick = () =>
    this.setState(prevState => ({ truncated: !prevState.truncated }))

  render() {
    const { children } = this.props
    const { truncated } = this.state
    return (
      <>
        <TruncateText isTruncated={truncated}>{children}</TruncateText>
        <ArrowIcon active={truncated.toString()} onClick={this.handleClick} />
      </>
    )
  }
}

ReadMore.propTypes = {
  children: PropTypes.string
}

export default ReadMore
