import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from 'Redux/actions/api'
import { getParamsWithUnits } from 'Helpers/user'

export const LOAD_BOOKED_JOB = createAsyncAction('job/LOAD_BOOKED')
export const loadBookedJobList = (request, callback) =>
  apiCall({
    endpoint: '/jobs/booked',
    types: LOAD_BOOKED_JOB,
    method: 'GET',
    qsParams: {
      ...request,
      elements_per_page: 1000,
      no_geolocation_filter: true
    },
    preFormat: getParamsWithUnits,
    callback
  })

export const MARK_AS = createAsyncAction('jobs/MARK_AS')
export const markAsJob = (jobId, callback) =>
  apiCall({
    endpoint: `/jobs/${jobId}/complete`,
    types: MARK_AS,
    method: 'POST',
    callback
  })

export const CANCEL_BOOKED_JOB = createAsyncAction('bookedJobs/CANCEL')
export const cancelBookedJob = ({ jobId, reason }, callback) =>
  apiCall({
    endpoint: `/jobs/${jobId}/cancele`,
    types: CANCEL_BOOKED_JOB,
    method: 'POST',
    meta: {
      jobId
    },
    query: {
      reason
    },
    callback
  })

export const START_THE_JOB = createAsyncAction('bookedJobs/START_THE_JOB')
export const startTheJob = ({ jobId, isAuthor, paypalRedirectUrl }, callback) =>
  apiCall({
    endpoint: `/job/${jobId}/start`,
    types: START_THE_JOB,
    method: 'POST',
    query: {
      paypalRedirectUrl
    },
    meta: {
      jobId,
      isAuthor
    },
    callback
  })

export const DISPUTE_JOB = createAsyncAction('bookedJobs/DISPUTE_JOB')
export const disputeJob = ({ subject, content, jobId, reason, cancelDispute, sender, isCancel }, callback) =>
  apiCall({
    endpoint: '/dispute',
    types: DISPUTE_JOB,
    method: 'POST',
    query: {
      mail_subject: subject,
      mail_content: content,
      jobId,
      reason,
      cancelDispute
    },
    meta: {
      disputeReason: reason,
      sender,
      isCancel
    },
    callback
  })

export const RECURRING_JOB = createAsyncAction('bookedJobs/RECURRING_JOB')
export const recurringJob = (query, callback) =>
  apiCall({
    endpoint: '/job/recurring',
    types: RECURRING_JOB,
    method: 'POST',
    query,
    callback
  })
