import { createSelector, createStructuredSelector } from 'reselect'

const getState = state => state.job

export const getJobDescription = createSelector(
  getState,
  jobDescription => jobDescription
)

export const getFullLibraryData = createStructuredSelector({
  getJobDescription
})
