import styled from 'styled-components'
import { fontFamily, fontSize, sizes } from 'Constants'
import { Button } from 'Components/ui'
import Truncate from 'react-truncate'

export const JobCardWrapper = styled.div`
  background: ${props => props.theme.colors.componentsBackground};
  margin-bottom: 20px;
  width: 100%;
  align-self: center;
  justify-self: center;
  position: relative;
  box-shadow: ${props => props.theme.colors.boxShadow};
  border-radius: 3px 3px 0 0;
  overflow: auto;
  transition: box-shadow 0.3s;
  &:hover {
    box-shadow: 0 0 10px #b1bcd4;
  }
  & a {
    text-decoration: none;
    color: ${props => props.theme.colors.lightGrayText};
  }
`

export const Delete = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  right: 10px;
  top: 10px;
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    right: 50%;
    width: 2px;
    height: 16px;
    background: black;
    transform: translate(50%, -50%) rotate(45deg);
  }
  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    right: 50%;
    width: 16px;
    height: 2px;
    background: black;
    transform: translate(50%, -50%) rotate(45deg);
    z-index: 1;
  }
`

export const TitleWrapper = styled.div`
  margin: 15px 30px 0 20px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > button {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 125px;
    height: 35px;
    line-height: 35px;
    font-size: ${fontSize.small};
    font-family: ${fontFamily};
    text-transform: uppercase;
    font-weight: 600;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  }
`

export const LeftGroup = styled.div`
  display: flex;
  max-width: 75%;
  & > a {
    width: ${props => props.isPremium ?  '92%' : '100%'};
  }
  @media (max-width: ${sizes.phone}px) {
    max-width: 50%;
  }
  @media (max-width: 305px) {
    max-width: 35%;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
`

export const AcceptButton = styled(Button)`
  width: 150px;
  margin-left: 15px;
  text-transform: uppercase;
  background: ${props =>
    props.disabled
      ? 'linear-gradient(-75deg, #f0f0f0, #cfcfcf)'
      : 'linear-gradient(to right, #459aee 0%, #3ad6ff 99%)'};
  > img {
    height: 15px;
  }
`

export const ApplyButton = styled(Button)`
  background: ${props =>
  props.disabled
    ? 'linear-gradient(-75deg, #f0f0f0, #cfcfcf)'
    : 'linear-gradient(to right, #459aee 0%, #3ad6ff 99%)'};
  > img {
    margin-top: 10px;
    height: 15px;
  }
  &:hover {
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    background: ${props =>
    props.disabled
      ? 'linear-gradient(-75deg, #f0f0f0, #cfcfcf)'
      : 'linear-gradient(to right, #459aee 0%, #3ad6ff 99%)'};
  }
`

export const RejectButton = styled(AcceptButton)`
background: linear-gradient(
  to left,
  gray 0%,
  rgba(146, 146, 146, 0.8) 56%,
  rgba(182, 182, 182, 0.4) 100%
);
&:hover {
  background: linear-gradient(
    to right,
    gray 0%,
    rgba(146, 146, 146, 0.8) 56%,
    rgba(182, 182, 182, 0.4) 100%
  );
`

export const BodyWrapper = styled.div`
  margin: 7px 20px 7px 20px;
  display: flex;
  flex-wrap: nowrap;
  & > a > img,
  & > div > img {
    border-radius: 3px;
    width: 80px;
    height: 75px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    object-fit: cover;
  }
`

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${fontSize.small};
  font-family: ${fontFamily};
  color: ${props => props.theme.colors.lightGrayText};
  flex-basis: 100%;
  margin-left: 15px;
  padding-bottom: 20px;
  width: 80%;
  @media (max-width: ${sizes.phone}px) {
    width: 50%;
  }
`

export const DateGroup = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  font-weight: 700;
  color: ${props => props.theme.colors.defaultFontBlack};
  & > svg {
    font-size: 18px;
    margin-right: 5px;
    color: ${props => props.theme.colors.blueIcon};
  }
`

export const DateText = styled.span`
  padding-left: 3px;
  font-size: ${p => p.theme.fontSize.h5};
`

export const Description = styled(Truncate).attrs(props => ({
  lines: 2,
  width: props.isSelectModal ? 300 : 0
}))`
  margin: 0 0 10px 0;
  font-size: ${props => props.theme.fontSize.h5};
  line-height: 1.5;
  display: block;
  width: 100%;
  overflow: hidden;
  @media (max-width: ${sizes.phone}px) {
    text-overflow: ellipsis;
  }
`

export const IconGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  margin-right: 10px;
  & > div:last-child {
    margin-right: 10px;
  }

  @media (max-width: ${sizes.phone}px) {
    width: 100%;
    margin-right: 0;
    & > div:last-child {
      margin-right: 0;
    }
  }
`

export const FooterWrapper = styled.div`
  padding: 0 20px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  background: linear-gradient(
    to right,
    rgba(10, 148, 241, 1) 0%,
    rgba(10, 148, 241, 1) 56%,
    rgba(100, 195, 255, 1) 100%
  );
  & > span {
    color: ${props => props.theme.colors.defaultFont};
    font-size: ${props => props.theme.fontSize.h3};
    font-family: ${props => props.theme.fontFamily.default};
  }
`

export const DistanceWrapper = styled.div`
  position: absolute;
  left: 20px;
  height: 26px;
  padding: 0 5px;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: ${props => props.theme.colors.greyBackGround};
  & > svg {
    margin-right: 5px;
    & > path {
      fill: ${props => props.theme.colors.iconRed};
    }
  }

  @media (max-width: ${sizes.phone}px) {
    left: 5px;
  }
`

export const Span = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${props => props.theme.colors.defaultFontBlack};
  font-weight: bold;
  font-size: ${props => props.theme.fontSize.h4};
  cursor: pointer;
  display: block;
  width: 98%;
  margin-right: 10px;

  /* @media (max-width: ${sizes.phone}px) {
    width: 65%;
  } */
`

export const EditDeleteContainer = styled.div`
  position: absolute;
  right: 25px;
`

export const RoundIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const CanceledButton = styled.span`
  cursor: pointer;
  font-size: ${p => p.theme.fontSize.default};
  font-weight: bold;
  position: absolute;
  right: 25px;
  top: 15px;
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.7;
  }
  @media (max-width: ${sizes.phone}px) {
    right: 3px;
    top: 20px;
    font-size: 12px;
    text-align: right;
  }
`

export const Budget = styled.span`
  @media (max-width: ${sizes.phone}px) {
    padding-left: 50px;
    display: inline-block;
    width: 200px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
`
