import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getProfile } from 'Redux/selectors/user'

import Component from './DateModal'

const selectors = createStructuredSelector({
  user: getProfile
})

export default connect(
  selectors,
)(Component)
