import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Icon = styled.img`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
`

export const Overlay = styled.div`
  width: 10px;
  height: 15px;
  background-color: ${props => props.theme.colors.joinButtonColor};
`
