import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withNamespaces } from 'react-i18next'

import { Wrapper } from './styles'

const Profession = ({ professions, t }) => {
  if (_.isEmpty(professions)) return null

  const text =
    professions.length > 1
      ? `${t(professions[0].title)} & ${professions.length - 1} ${t('more')}`
      : t(professions[0].title)
  return (
    <Wrapper>
      <p>{text}</p>
    </Wrapper>
  )
}

export default withNamespaces('services')(Profession)

Profession.propTypes = {
  professions: PropTypes.array,
  t: PropTypes.func
}
