import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import memoize from 'memoize-one'
import * as routes from 'Routes/routesConstants'

import { icons } from 'Assets/icons'

import { Switch, Route } from 'react-router-dom'
import {
  JobCard,
  RightSidebar,
  Ad,
  ProgressBar,
  Sort,
  ListAd,
  JobView,
  BreadCrumbs,
  ApplyBySection,
  FreelancerProfileView
} from 'Components/blocks'

import {
  CardWrapper,
  RightSideBarWrapper,
  DownPart,
  PreloadImg,
  TopPart,
  FiltersGroupRight,
  SSGroup,
  Msg,
  ContentWrapper
} from 'Components/ui'

import { sortData } from '../../config'
import { path } from './config'
import {
  getBreadCrumbs,
  getDescriptionBreadCrumbs,
  getPeopleWhoAppliedBreadCrumbs,
  getAppliedProfileBC
} from './memoize'
import { Container, GoogleAdsFirst } from './style'

export class AppliedJobs extends PureComponent {
  state = {
    isRequest: true,
    isLoadMoreRequest: false,
    activeTabIndex: 'a',
    order_by: 'distance',
    order: 'asc',
    canceledJob: null,
    activeCategoriesIds: []
  }

  componentDidMount() {
    const { onLoadAppliedJobList, defaultRadius, userName } = this.props
    const { elements_per_page, pageNumber } = this.state

    const request = {
      elements_per_page,
      page_number: pageNumber,
      radius: defaultRadius,
      author: userName
    }

    onLoadAppliedJobList(request, () => this.setState({ isRequest: false }))
  }

  handleTabClick = id => this.setState({ activeTabIndex: id })

  handleOpenJob = job => {
    const { history } = this.props

    history.push(`${routes.myJobs}${routes.applied}/${job._id}`)
  }

  handleCancelJob = () => {
    const { specificJob, onCancelJob } = this.props

    onCancelJob(specificJob)
  }

  onFilter = (data = {}) => {
    const { onLoadAppliedJobList } = this.props

    const { order_by, order } = this.state

    this.setState({
      isRequest: true,
      activeCategoriesIds: _.isEmpty(data.services) ? [] : data.services
    })

    const request = {
      order_by,
      order,
      categories: _.isEmpty(data.services)
        ? undefined
        : JSON.stringify(data.services)
    }

    onLoadAppliedJobList(request, () => this.setState({ isRequest: false }))
  }

  onClickPopularService = ({ services }) => {
    const { activeCategoriesIds } = this.state
    const id = services[0]
    let result
    if (activeCategoriesIds.indexOf(id) === -1) {
      result = activeCategoriesIds.concat(services)
    } else {
      result = activeCategoriesIds.filter(el => el !== id)
    }

    this.onFilter({ services: result })
  }

  loadMoreItems = () => {
    const { defaultRadius, userName, onLoadAppliedJobList } = this.props
    const { elements_per_page, pageNumber, isLoadMoreRequest } = this.state

    if (isLoadMoreRequest) return

    const request = {
      elements_per_page,
      page_number: pageNumber + 1,
      radius: defaultRadius,
      author: userName
    }
    this.setState({ isLoadMoreRequest: true, pageNumber: pageNumber + 1 })
    onLoadAppliedJobList(request, () => {
      this.setState({ isLoadMoreRequest: false })
    })
  }

  handleOpenAppliedProfile = (profile, application) => {
    const { history, specificJob } = this.props

    const user = profile.user || profile

    history.push(
      `${routes.myJobs}${routes.applied}/${specificJob._id}${routes.appliedServiceProviders}/${user.username}`
    )
  }

  handleOpenPeopleWhoApplied = () => {
    const { history, specificJob } = this.props

    history.push(
      `${routes.myJobs}${routes.applied}/${specificJob._id}${routes.appliedServiceProviders}`
    )
  }

  _renderListView = () => {
    const { appliedJobs, onApply, onShare, onCancelJob } = this.props
    const { isRequest } = this.state

    if (isRequest && _.isEmpty(appliedJobs))
      return <PreloadImg alt="loading" src={icons.preload} />

    return (
      <>
        {_.isEmpty(appliedJobs)
          ? null
          : appliedJobs.map((item, key) => (
              <Fragment key={key}>
                <JobCard
                  {...item}
                  onCancelClick={memoize(() => onCancelJob(item))}
                  onClick={memoize(() => this.handleOpenJob(item))}
                  onHandleApply={onApply}
                  onHandleFavouriteClick={this.handleFavouriteClick}
                  onShareClick={memoize(() => onShare(item))}
                />
                <ListAd data={appliedJobs} index={key} />
              </Fragment>
            ))}

        {_.isEmpty(appliedJobs) ? null : <Ad />}
      </>
    )
  }

  handleSorting = ({ orderBy, order }) => {
    this.setState({ order_by: orderBy, order }, this.onFilter)
  }

  renderContent = () => {
    const { t } = this.props
    const {
      order_by: orderBy,
      activeTabIndex,
      activeCategoriesIds
    } = this.state

    const breadCrumbs = getBreadCrumbs(t)
    return (
      <>
        <BreadCrumbs data={breadCrumbs} />
        <ContentWrapper>
          <TopPart>
            <FiltersGroupRight>
              <SSGroup>
                <Sort
                  data={sortData}
                  value={orderBy}
                  onSelect={this.handleSorting}
                />
              </SSGroup>
            </FiltersGroupRight>
          </TopPart>
          <DownPart>
            <CardWrapper>
              <GoogleAdsFirst />
              {activeTabIndex === 'a' ? this._renderListView() : null}
            </CardWrapper>
            <RightSideBarWrapper>
              <RightSidebar
                activeCategoriesIds={activeCategoriesIds}
                handleFilter={this.onClickPopularService}
              />
            </RightSideBarWrapper>
          </DownPart>
        </ContentWrapper>
      </>
    )
  }

  renderDescription = props => {
    const { t, specificJob, user } = this.props
    const breadCrumbs = getDescriptionBreadCrumbs(
      t,
      _.get(specificJob, 'title')
    )

    const isShowCancelButton = _.get(specificJob, 'peopleWhoApplied', []).some(
      el => el.userId === user.username
    )

    return (
      <>
        <BreadCrumbs data={breadCrumbs} />
        <ContentWrapper>
          <Container>
            <JobView
              {...props}
              onCancelClick={
                isShowCancelButton ? this.handleCancelJob : undefined
              }
              onOpenApplyByProfile={this.handleOpenAppliedProfile}
              onOpenPeopleWhoApplied={this.handleOpenPeopleWhoApplied}
            />
            {this._renderChatButton()}
          </Container>
        </ContentWrapper>
      </>
    )
  }

  renderAppliedBy = props => {
    const { t, specificJob } = this.props
    const breadCrumbs = getPeopleWhoAppliedBreadCrumbs(
      t,
      _.get(specificJob, 'title'),
      _.get(specificJob, '_id')
    )

    return (
      <>
        <BreadCrumbs data={breadCrumbs} />
        <ApplyBySection
          {...props}
          onClickProfile={this.handleOpenAppliedProfile}
        />
      </>
    )
  }

  renderAppliedByProfile = props => {
    const { serviceProfile, t, specificJob, onSendRequest } = this.props
    const breadCrumbs = getAppliedProfileBC(
      t,
      _.get(specificJob, 'title'),
      _.get(specificJob, '_id'),
      _.get(serviceProfile, 'username')
    )

    return (
      <>
        <BreadCrumbs data={breadCrumbs} />
        <ContentWrapper>
          <Container>
            <FreelancerProfileView
              {...props}
              isMyJob={false}
              onHire={memoize(() => onSendRequest(serviceProfile))}
            />
          </Container>
        </ContentWrapper>
      </>
    )
  }

  _renderChatButton = () => {
    const { user, specificJob, history } = this.props

    if (!specificJob) return null

    const jobByUser = specificJob.peopleWhoApplied.find(item => item.userId === user._id) || {}
    const showChatButton = jobByUser.status === 'accepted'

    if (!showChatButton) return null

    return (
      <Msg
        isRequest={false}
        onClick={() => history.push(`/chats/${specificJob._id}#${jobByUser.user.chatId || ''}`)}
      />
    )
  }


  render() {
    const { isRequest } = this.state

    return (
      <>
        <ProgressBar percent={isRequest ? 1 : 100} />

        <Switch>
          <Route
            exact
            path={path.jobDescription}
            render={this.renderDescription}
          />
          <Route exact path={path.appliedBy} render={this.renderAppliedBy} />
          <Route
            exact
            path={path.appliedByProfile}
            render={this.renderAppliedByProfile}
          />
          <Route render={this.renderContent} />
        </Switch>
      </>
    )
  }
}

AppliedJobs.propTypes = {
  appliedJobs: PropTypes.array,
  defaultRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  specificJob: PropTypes.object,
  user: PropTypes.object,
  onApply: PropTypes.func,
  onCancelJob: PropTypes.func,
  onLoadAppliedJobList: PropTypes.func
}
