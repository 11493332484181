import * as routes from 'Routes/routesConstants'
import memoize from 'memoize-one'

export const getJobDescrBreadCrumbs = (t, jobTitle) => [
  {
    displayName: t('homePage:myJobs'),
    url: `${routes.myJobs}${routes.posted}`
  },
  {
    displayName: t('homePage:postedJobs'),
    url: `${routes.myJobs}${routes.posted}`
  },
  {
    displayName: jobTitle
  }
]

export const getJobBreadCrumbs = t => [
  {
    displayName: t('homePage:myJobs')
  },
  {
    displayName: t('homePage:postedJobs')
  }
]

export const getPeopleWhoAppliedBreadCrumbs = memoize((t, jobTitle, jobId) => [
  {
    displayName: t('homePage:myJobs'),
    url: routes.myJobs
  },
  {
    displayName: t('homePage:postedJobs'),
    url: `${routes.myJobs}${routes.posted}`
  },
  {
    displayName: jobTitle,
    url: `${routes.myJobs}${routes.posted}/${jobId}`
  },
  {
    displayName: t('appServiceProvider:totalServiceProviders')
  }
])

export const getAppliedProfileBC = memoize((t, jobTitle, jobId, username) => [
  {
    displayName: t('homePage:myJobs'),
    url: routes.myJobs
  },
  {
    displayName: t('homePage:postedJobs'),
    url: `${routes.myJobs}${routes.posted}`
  },
  {
    displayName: jobTitle,
    url: `${routes.myJobs}${routes.posted}/${jobId}`
  },
  {
    displayName: t('appServiceProvider:totalServiceProviders'),
    url: `${routes.myJobs}${routes.posted}/${jobId}${routes.appliedServiceProviders}`
  },
  {
    displayName: username
  }
])
