export const PARSE_LOCATION_INFO = [
  {
    searchForType: 'country',
    putToField: 'country',
    pickField: item => item.long_name
  },
  {
    searchForType: 'postal_code',
    putToField: 'zip',
    pickField: item => item.long_name
  },
  {
    searchForType: 'administrative_area_level_1',
    putToField: 'state',
    pickField: item => item.long_name
  },
  {
    searchForType: 'locality',
    putToField: 'city',
    pickField: item => item.long_name
  }
]
