import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { toast } from 'react-toastify'
import ImageUploader from 'react-images-upload'
import profileLimits from 'Configs/profileLimits'
import { ProfileImage } from 'Components/blocks'
import {
  ALLOWED_IMG_EXTENSIONS,
  MAX_IMG_SIZE,
  MAX_IMG_SIZE_STR
} from '../../config'

class ImageUpload extends Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return nextProps.value !== this.props.value
  }

  handleImgSizeOverflowException = () => {
    const { t } = this.props

    toast.error(t('sizeOverflowError', { maxImgSize: MAX_IMG_SIZE_STR }))
  }

  handleMaxItemsUploaded = () => {
    const { t } = this.props

    toast.error(t('overflowError'))
  }

  handleMaxIDUploaded = () => {
    const { t } = this.props
    toast.error(t('overflowIdError'))
  }

  render() {
    const {
      username,
      userRole,
      value,
      setFieldValue,
      type,
      onHandleImgListChanged,
      onHandleRemoveImage
    } = this.props

    return (
      <ImageUploader
        ImageComponent={imgProps => (
          <ProfileImage {...imgProps} type={type} userId={username} />
        )}
        buttonText={null}
        imgExtension={ALLOWED_IMG_EXTENSIONS}
        label={null}
        maxFileSize={MAX_IMG_SIZE}
        maxItemsCount={profileLimits[type][userRole]}
        singleImage
        value={value.filter(value => value.status !== 'deleted')}
        withIcon={false}
        withPreview
        onChange={(b64s, files) =>
          onHandleImgListChanged(type, files, b64s, setFieldValue)
        }
        onImgSizeOverflow={this.handleImgSizeOverflowException}
        onMaxItemsOverflow={
          type === 'idPic'
            ? this.handleMaxIDUploaded
            : this.handleMaxItemsUploaded
        }
        onRemoveImage={(img, index) =>
          onHandleRemoveImage(img, index, type, value, setFieldValue)
        }
      />
    )
  }
}

export default withNamespaces('profilePage')(ImageUpload)
