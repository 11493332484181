import React, { PureComponent } from 'react'
import { withNamespaces } from 'react-i18next'
import { Formik } from 'formik'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

import { signIn, root } from 'Routes/routesConstants'
import { icons } from 'Assets/icons'
import {
  Background,
  Registered,
  Logo,
  Form,
  Label,
  AuthInput,
  Error,
  InputWrapper,
  BtnSubmit
} from 'Components/ui'

import { FormTitle, BtnSubmitWrapper, StyledAuthFormWrapper } from './styles'
import { getValidationSchema } from './config'

class Verify extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isRequest: false,
      isResend: false,
    }
    this.initialUserName = this.props.location.state.username || null
  }

  handleSubmit = ({ code, userName }) => {
    const { onVerify } = this.props

    this.setState({
      isRequest: !this.state.isRequest,
    })

    onVerify(code, userName, error => {
      this.setState({
        isRequest: !this.state.isRequest,
      })
      if (!error) {
        toast.success(this.props.t('signUpSuccess'))
        this.props.history.push({
          pathname: signIn
        })
      }
    })
  }

  handleResendCode = userName => {
    const { onResend } = this.props

    this.setState({ isResend: true })

    onResend(userName, error => {
      this.setState({ isResend: false })

      if (!error) toast.success(this.props.t('send'))
    })
  }

  render() {
    const { t } = this.props

    return (
      <Background>
        <Link to={root}>
          <Logo>
            SpotJobs<Registered>&reg;</Registered>
          </Logo>
          {/* <SubTitle>{t('subTitle')}</SubTitle>z */}
        </Link>
        <Formik
          initialValues={{ userName: this.initialUserName || '', code: '' }}
          validationSchema={getValidationSchema(t)}
          onSubmit={this.handleSubmit}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              submitCount
            } = props

            const isFormTouched = submitCount > 0
            return (
              <Form onSubmit={handleSubmit}>
                <FormTitle>{t('enterVerifyCode')}</FormTitle>
                <StyledAuthFormWrapper>
                  <InputWrapper>
                    <Label htmlFor="userName">{t('UserID')}</Label>
                    <AuthInput
                      id="userName"
                      placeholder={t('EnterUserID')}
                      type="text"
                      value={values.userName || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {errors.userName && (touched.userName || isFormTouched) && (
                      <Error>{errors.userName}</Error>
                    )}
                  </InputWrapper>
                  <InputWrapper>
                    <Label htmlFor="Code">{t('code')}</Label>
                    <AuthInput
                      id="code"
                      placeholder={t('enterVerifyCode')}
                      type="text"
                      value={values.code || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {errors.code && (touched.code || isFormTouched) && (
                      <Error>{errors.code}</Error>
                    )}
                  </InputWrapper>
                  <BtnSubmitWrapper>
                    {this.state.isResend ? (
                      <BtnSubmit disabled>
                        <img alt="" src={icons.threeDotsLoader} />
                      </BtnSubmit>
                    ) : (
                      <BtnSubmit
                        type="button"
                        onClick={() => this.handleResendCode(values.userName)}
                        disabled={this.state.isRequest}
                      >
                        {t('resendCode')}
                      </BtnSubmit>
                    )}
                    {this.state.isRequest ? (
                      <BtnSubmit disabled>
                        <img alt="" src={icons.threeDotsLoader} />
                      </BtnSubmit>
                    ) : (
                      <BtnSubmit
                        disabled={Object.keys(errors).length !== 0 || this.state.isResend}
                        type="submit"
                      >
                        {t('confirm')}
                      </BtnSubmit>
                    )}
                  </BtnSubmitWrapper>
                </StyledAuthFormWrapper>
              </Form>
            )
          }}
        </Formik>
      </Background>
    )
  }
}

export default withNamespaces('sign')(Verify)
