import React from 'react'
import get from 'lodash/get'
import merge from 'lodash/merge'
import pick from 'lodash/pick'
import assign from 'lodash/assign'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import eq from 'lodash/eq'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { NamespacesConsumer } from 'react-i18next'

import axios from 'axios'

import { api as apiConfig } from 'Configs/App'
import { notShowErrorCodes } from 'Constants/errors'

import { API_CALL, API_CALL_FAILURE } from 'Constants/ids'
import { UPDATE_TOKEN, LOG_OUT } from 'Redux/actions/auth'

import { getAccessToken, getIsAuth } from 'Redux/selectors/auth'
import { getProfile } from 'Redux/selectors/user'

export const ApiService = {
  apiCall: (
    url,
    endpoint = '',
    method = 'GET',
    query = {},
    headers = {},
    qsParams = {},
    postFormat,
    preFormat
  ) => {
    const HTTPMethod = method.toLowerCase()

    let formattedRequest = {}

    if (isFunction(preFormat)) {
      formattedRequest = preFormat({ query, headers, params: qsParams }) || {}
    }

    const api = axios.create({
      baseURL: url || apiConfig.defaultUrl,
      headers: formattedRequest.headers || headers,
      params: formattedRequest.params || qsParams,
      timeout: 50000,
    })

    const body =
      method === 'delete'
        ? { data: formattedRequest.query || query }
        : formattedRequest.query || query

    return new Promise((resolve, reject) => {
      api[HTTPMethod](endpoint, body)
        .then(data => {
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const nextAction = (action, data) => {
  const next = merge({}, action, data)
  delete next[API_CALL]
  return next
}

export default store => next => action => {
  if (action.type !== API_CALL || !action.fields) return next(action)
  const {
    url,
    endpoint,
    headers,
    method,
    query,
    types,
    qsParams,
    callback,
    postFormat,
    preFormat
  } = action.fields

  const signature = Date.now()

  const format = {
    post: isFunction(postFormat) ? data => postFormat(data, store) : undefined,
    pre: isFunction(preFormat) ? data => preFormat(data, store) : undefined
  }

  const token = getAccessToken(store.getState())

  const completeHeaders = assign(
    isEmpty(query) && { 'Content-Type': 'application/json' },
    token
      ? {
        Authorization: `Bearer ${token}`
      }
      : {},
    headers
  )

  const fsaFields = pick(action.fields, 'payload', 'error', 'meta')
  const isLoadRequest =
    !method ||
    method.toUpperCase() === 'GET' ||
    method.toUpperCase() === 'PATCH' ||
    method.toUpperCase() === 'POST'

  next(
    nextAction(fsaFields, {
      type: types.REQUEST,
      meta: merge({ signature }, isLoadRequest && { endpoint, isRequest: true })
    })
  )

  const onError = error => {
    const data = {
      payload: error,
      type: types.FAILURE,
      meta: {
        signature,
        httpCode: error.status,
        endpoint
      },
      error: true
    }

    const responseCode = get(error, 'response.status', null)

    if (responseCode === 500 || data.type === 'notifications/LOAD.FAILURE') {
      return
    }

    if (
      responseCode === 401 &&
      types.FAILURE !== 'settings/SET_LANGUAGE.FAILURE'
    ) {
      store.dispatch({
        type: LOG_OUT.REQUEST
      })
    }
    if (responseCode === 401 || error.message === 'Network Error') {
      if (types.FAILURE !== 'settings/SET_LANGUAGE.FAILURE') {
        if (!eq(types, UPDATE_TOKEN)) {
          // next({
          //   type: API_CALL_FAILURE,
          //   failedAction: action
          // })
        } else {
          store.dispatch({
            type: LOG_OUT.REQUEST
          })
        }
      }
    } else {
      const { response: responseError = {} } = { ...error } || {}
      const messageError = responseError?.data?.message || ''

      if (isFunction(callback)) callback(get(error, 'response.data', error))
      let errorCode,
        errorMessages = null
      if (!_.isEmpty(responseError)) {
        errorCode =
          responseError?.data?.code ||
          responseError?.data?.message ||
          error.message

        if (_.isString(responseError?.data)) {
          errorCode = responseError?.data
        }

        if (errorCode === 'UserAlreadyApplied' && endpoint === '/job-request') {
          errorCode = 'AlreadyApplied'
        }

        if (errorCode === 'InvalidParameterException') {
          if (responseError?.data?.message === 'Invalid email address format.') {
            errorCode = 'invalidEmailAddressFormat'
          }
          if (responseError?.data?.message === 'Username cannot be of email format, since user pool is configured for email alias.') {
            errorCode = 'invalidUsername'
          }
        }

        errorMessages = [errorCode]

        if (responseError?.data?.code === 'UserExistsException') {
          if (responseError?.data?.details.indexOf('email') !== -1) {
            errorMessages.push('UserExistsExceptionEmail')
          }
          if (responseError?.data?.details.indexOf('phone_number') !== -1) {
            errorMessages.push('UserExistsExceptionPhoneNumber')
          }
          if (responseError?.data?.details.indexOf('username') !== -1) {
            errorMessages.push('UserExistsExceptionUserName')
          }
        }
      } else {
        errorCode = error.code
        errorMessages = [errorCode]
      }
      if (notShowErrorCodes.indexOf(errorCode) === -1) {
        if (types.FAILURE !== 'settings/SET_LANGUAGE.FAILURE') {
          if (
            !(
              endpoint === '/auth/signup' &&
              messageError.includes('phone number format')
            )
          ) {
            if (errorMessages[0] === 'dipute_error') return
            toast.error(
              <NamespacesConsumer ns={['apiErrors', 'notifications']}>
                {t => {
                  return (
                    <div>
                      {errorMessages?.map((errorCodeName, index) => (
                        _.isString(errorCodeName) ? <p key={index}>{t(errorCodeName)}</p> : <p key={index}>{t(errorCodeName?.error?.message)}</p>
                      ))}
                    </div>
                  )
                }}
              </NamespacesConsumer>
            )
          }
        }
      }
      next(nextAction(fsaFields, data))
    }

    return data
  }

  const onSuccess = response => {
    const meta = merge(
      { signature },
      isLoadRequest && { endpoint, isSuccess: true }
    )
    const payload = get(response, 'data')
    const data = { meta, payload, type: types.SUCCESS }
    if (isFunction(callback)) {
      callback(null, payload)
    }

    const result = _.isFunction(postFormat) ? postFormat(data, store) : data

    if (_.isObject(result.error)) {
      if (
        result.meta.endpoint === '/auth/signup' &&
        result.meta.isSuccess &&
        response.data.user
      ) {
      } else {
        onError(result.error)
        return
      }
    }

    if (
      result.meta.endpoint === '/auth/signup' &&
      result.meta.isSuccess &&
      response.data.user
    ) {
    } else {
      if (
        getIsAuth(store.getState()) &&
        _.isEmpty(getProfile(store.getState()))
      ) {
        if (!eq(types, UPDATE_TOKEN)) {
          next({
            type: API_CALL_FAILURE,
            failedAction: action
          })
        } else {
          store.dispatch({
            type: LOG_OUT.REQUEST
          })
        }
      }
    }

    next(nextAction(fsaFields, result))

    return result
  }

  const apiRequest = ApiService.apiCall(
    url,
    endpoint,
    method,
    query,
    completeHeaders,
    qsParams,
    format.post,
    format.pre
  )

  return apiRequest
    .then(onSuccess, onError)
    .catch(error => console.error('Request error', error))
}
