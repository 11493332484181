import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withNamespaces } from 'react-i18next'
import { types } from 'Helpers/notifications'
import * as routes from 'Routes/routesConstants'

import { Container, Message, TextLink } from './style'
import { getUrl } from '../../blocks/Notification/config'

const NotificationText = ({
  t,
  isRead,
  job = {},
  type,
  user = {},
  reason,
  currentUserId,
  userCancelId,
  userCancelName,
  userId,
  onOpenTrack,
  jobTitleUnavailable,
  isAuthor,
  netMoneyWithdraw
}) => {
  // const userName = user.username
  const jobTitle = job.title
  const jobId = job._id

  const shortName = user.short_name

  const startDate = moment(job.startDate)
  const formattedDate = startDate.isValid()
    ? startDate.format('MMMM D, YYYY [@] h:mm A')
    : job.startDate
  const formattedTime = startDate.isValid()
    ? startDate.format('[@] h:mm A')
    : job.startDate
  let address = job.streetAddress
  if (job.city) address = `${address}, ${job.city}`
  if (job.country) address = `${address} ${job.country}`
  if (job.zipCode) address = `${address} ${job.zipCode}`

  const renderJobLink = () => {
    switch (type) {
      case 'jobUnavailable':
        return (
          <TextLink to={`${routes.findJobs}${routes.listView}`}>
            {jobTitleUnavailable}
          </TextLink>
        )
      case types.jobCompleted:
        return (
          <TextLink to={`${routes.myJobs}${routes.booked}/${jobId}`}>
            {jobTitle}
          </TextLink>
        )
      case types.authorCancelJobNoShow:
        return (
          <TextLink to={`${routes.myJobs}${routes.booked}/${jobId}`}>
            {jobTitle}
          </TextLink>
        )
      default:
        return (
          <TextLink to={`${routes.findJobs}${routes.listView}/${jobId}`}>
            {jobTitle}
          </TextLink>
        )
    }
  }

  const renderUserLink = () => (
    <TextLink to={`${routes.findHelp}/${userId}`}>{shortName}</TextLink>
  )

  const renderUserCancelName = () => (
    <TextLink to={`${routes.findHelp}/${userCancelId}`}>
      {userCancelName}
    </TextLink>
  )

  const renderViewDetail = () => {
    switch (type) {
      case types.jobDone:
        return (
          <>
            {' '}
            <TextLink to={isAuthor ? routes.paymentsHistory : routes.earnings}>
              {t('notifications:viewDetails')}
            </TextLink>
          </>
        )
      default:
        return (
          <>
            {' '}
            <TextLink to={`${routes.findJobs}${routes.listView}/${jobId}`}>
              {t('notifications:viewDetails')}
            </TextLink>
          </>
        )
    }
  }

  const renderStartingText = () => {
    if (isAuthor) {
      return (
        <Message isRead={isRead}>
          {t('startJobNotification.start')}
          {renderJobLink()} {t('startJobNotification.end')}
        </Message>
      )
    }
    return (
      <Message isRead={isRead}>
        {t('startJobNotificationStarted.start')}
        {renderJobLink()} {t('startJobNotificationStarted.end')}
      </Message>
    )
  }

  // const renderMessage = key => <Message isRead={isRead}>{t(key)}</Message>

  switch (type) {
    case types.newMessage:
      return (
        <Container>
          {/* {renderMessage('youHaveNewMessageFrom')} {renderUserLink()} */}
          <Message isRead={isRead}>
            {t('hello')} {t('newJobRequest')}. {renderUserLink()}{' '}
          </Message>
        </Container>
      )
    case types.jobMatchesProfile:
      return (
        <Container>
          {/* {renderMessage('youHaveNewMessageFrom')} {renderUserLink()} */}
          <Message isRead={isRead}>
            {t('hello')} {t('followingJob')} {renderJobLink()}{' '}
            {t('mutchesProfile')} {renderViewDetail()}
          </Message>
        </Container>
      )
    case types.newJobRequest:
      return (
        <Container>
          <Message isRead={isRead}>
            {t('hello')} {renderUserLink()} {t('likeToHire')} {renderJobLink()}.{' '}
            <TextLink
              to={`${routes.myRequests}${routes.hiringRequests}/${jobId}`}
            >
              {t('notifications:viewDetails')}
            </TextLink>
          </Message>
          {/* Hello, {renderUserLink()} {renderMessage('likeToHire')} {renderJobLink()} */}
        </Container>
      )
    case types.trackingAvailable:
      return (
        <Container>
          {currentUserId === job.author ? (
            <Message isRead={isRead}>
              {t('hello')} {t('trackingJob')} {renderJobLink()}{' '}
              {t('trackingAvailable')}.{' '}
              <TextLink
                to={`${routes.trackNow}/${jobId}/${job.doer}`}
                onClick={onOpenTrack}
              >
                {t('landingPage:trackNow')}
              </TextLink>
            </Message>
          ) : (
            <Message isRead={isRead}>
              {t('notifications:TrackingTellingServiceProviderToOpenTracking')}{' '}
              <TextLink
                to={`${routes.trackNow}/${jobId}/${job.doer}`}
                onClick={onOpenTrack}
              >
                {t('notifications:viewDetails')}
              </TextLink>
            </Message>
          )}
        </Container>
      )
    case types.appointmentRemider:
      return (
        <Container>
          <Message isRead={isRead}>
            {t('hello')} {t('appointmentReminder')} {renderJobLink()}:{' '}
            <TextLink to={getUrl(type, jobId)}>
              {address} {formattedTime}.
            </TextLink>{' '}
            <TextLink to={getUrl(type, jobId)}>
              {t('notifications:viewDetails')}
            </TextLink>
          </Message>
          {/* {renderUserLink()} {renderMessage('rejectOffer')} {renderJobLink()} */}
        </Container>
      )
    // return <Container>{renderMessage('appointmentReminder')}</Container>
    case types.jobRequestRejected:
      return (
        <Container>
          <Message isRead={isRead}>
            {t('hello')} {renderUserLink()} {t('rejectOffer')} {renderJobLink()}
            . {renderViewDetail()}
          </Message>
          {/* {renderUserLink()} {renderMessage('rejectOffer')} {renderJobLink()} */}
        </Container>
      )
    case types.jobRequestAccepted:
      return (
        <Container>
          <Message isRead={isRead}>
            {t('hello')} {renderUserLink()} {t('acceptedOffer')}{' '}
            {renderJobLink()}. {renderViewDetail()}
          </Message>
          {/* {renderUserLink()} {renderMessage('acceptedOffer')} {renderJobLink()} */}
        </Container>
      )
    case types.appointmentScheduled:
      return (
        <Container>
          <Message isRead={isRead}>
            {t('hello')} {t('NotificationJobBooked')}{' '}
            {t('myJobsDetail:appointmentScheduledFor')} {renderJobLink()}:{' '}
            {formattedDate} - {address}. {renderViewDetail()}
          </Message>
          {/* <Message isRead={isRead}>
            {t('NotificationJobBooked')}{' '}
            {t('NotificationappointmentScheduledFor')}{' '}
          </Message>
          {renderJobLink()}
          {renderMessage(`: ${formattedDate} - ${address}`)} */}
        </Container>
      )
    case types.jobCancelled:
      return (
        <Container>
          {userCancelId !== undefined && userCancelId === currentUserId && (
            <Message isRead={isRead}>
              {t('hello')} {t('LeftoverOnes:thisJobCancelled')}{' '}
              {renderJobLink()}. {t('LeftoverOnes:reasonCancelling')}:{' '}
              <b>{reason}</b> .{renderViewDetail()}
            </Message>
          )}
          {userCancelId !== undefined && userCancelId !== currentUserId && (
            <Message isRead={isRead}>
              {t('hello')} {renderUserCancelName()}{' '}
              {t('notifications:JobApplicationCancelledNotification')}{' '}
              {renderJobLink()}. {t('LeftoverOnes:reasonCancelling')}:{' '}
              <b>{reason}</b> .{renderViewDetail()}
            </Message>
          )}
        </Container>
      )
    case types.jobUnavailable:
      return (
        <Container>
          <Message isRead={isRead} disabled>
            {t('hello')} {t('notifications:jobUnavailable')} {renderJobLink()}.
          </Message>
        </Container>
      )
    case types.appliedForJob:
      return (
        <Container>
          <Message isRead={isRead}>
            {t('hello')} {renderUserLink()} {t('applyJobNotificationMessage')}{' '}
            {renderJobLink()}.{' '}
            <TextLink to={getUrl(type, jobId)}>
              {t('notifications:viewDetails')}
            </TextLink>
          </Message>
        </Container>
      )
    case types.startJob:
      return (
        <Container>
          {renderStartingText()}
        </Container>
      )
    case types.jobCompleted:
      return (
        <Container>
          <Message isRead={isRead}>
            {t('jobCompleted')}
            {': '}
            {renderJobLink()}
          </Message>
        </Container>
      )
    case types.dispute:
      return (
        <Container>
          <Message isRead={isRead}>
            {t('disputeNotification.start')} {renderJobLink()}{' '}
            {t('disputeNotification.end')}
            {reason}
          </Message>
        </Container>
      )
    case types.jobAppointmentHasExpired:
      return (
        <Container>
          <Message isRead={isRead}>
            {t('jobAppointmentHasExpired.start')}{' '}{renderJobLink()}{' '}
            {t('jobAppointmentHasExpired.end')}
          </Message>
        </Container>
      )
    case types.authorCancelJobNoShow:
      return (
        <Container>
          <Message isRead={isRead}>
            {t('authorCancelJobNoShow.start')}{renderJobLink()}
            {t('authorCancelJobNoShow.end')}
          </Message>
        </Container>
      )
    case types.jobDone:
      return (
        <Container>
          {isAuthor ? (
            <Message isRead={isRead}>
              {t('PaymentSent')}{job?.budget?.toFixed(2)}.{renderViewDetail()}
            </Message>
          ) : (
            <Message isRead={isRead}>
              {t('PaymentReceived')}{netMoneyWithdraw} .{renderViewDetail()}
            </Message>
          )}
        </Container>
      )
    default:
      return (
        <Container>
          <Message>{type}</Message>
        </Container>
      )
  }
}

NotificationText.propTypes = {
  disable: PropTypes.bool,
  isRead: PropTypes.bool,
  job: PropTypes.object,
  reason: PropTypes.string,
  t: PropTypes.func,
  type: PropTypes.string,
  user: PropTypes.object,
  onClickJob: PropTypes.func,
  onClickUser: PropTypes.func,
  onOpenTrack: PropTypes.func
}

export default withNamespaces(['notifications', 'LeftoverOnes', 'headerBlock'])(
  NotificationText
)

export { NotificationText }
