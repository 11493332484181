import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import _ from 'lodash'
import { withNamespaces } from 'react-i18next'

import { signIn, signUp } from 'Routes/routesConstants'
import { Lang } from 'Components/blocks'
import { Profile, Messages, Navigation, Notifications } from 'Components/ui'
import { getNotifications } from 'Redux/selectors/notifications'
import { getIsUnreadMessage } from 'Redux/selectors/chats'
import {
  BurgerWrap,
  SignInLink,
  JoinUsNav,
  Flex,
  Tooltips,
  MenuWrap
} from './styles'

const Component = ({ user, t, isUnreadMessage, notifications }) => {
  const styles = {
    bmMenu: {
      background: '#373a47',
      padding: '2em 1em',
      fontSize: '1.15em',
      scrollbar: 'display: none'
    }
  }

  const menu = !_.isEmpty(user) ? (
    <Flex>
      <Profile />
      <Tooltips>
        <Messages isActive={isUnreadMessage[user?._id]} />
        <Notifications isActive={notifications?.list?.some(el => !el.read)} />
      </Tooltips>
      <Navigation />
    </Flex>
  ) : (
    <>
      <SignInLink to={signIn}>{t('signIn')}</SignInLink>
      <JoinUsNav to={signUp}>{t('joinFree')}</JoinUsNav>
    </>
  )

  return (
    <BurgerWrap className="burger-menu">
      <MenuWrap right styles={styles}>
        {menu}
        <Lang isAuth={user} />
      </MenuWrap>
    </BurgerWrap>
  )
}

const selector = createStructuredSelector({
  notifications: getNotifications,
  isUnreadMessage: getIsUnreadMessage
})

const actions = {}

export const BurgerMenu = compose(
  withNamespaces('landingPage'),
  connect(selector, actions)
)(Component)
