import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import _ from 'lodash'
import { withNamespaces } from 'react-i18next'

import { PageWrapper, SettingsSection } from 'Components/ui'
import {
  BurgerMenu,
  Header,
  Footer,
  Ad,
  BreadCrumbs,
  ChangePassword,
  DeleteAccountModal
} from 'Components/blocks'
import { Notifications, Section } from './innerBlocks'

import { Content, Title } from './style'

class Settings extends Component {
  state = {
    isDeleteModalShow: false
  }

  handleNotificationsChange = () => {
    const { onSetNotifications, settings } = this.props
    onSetNotifications(!settings.notifications)
  }

  handleJobAlertsChange = () => {
    const { onSetJobAlerts, settings } = this.props
    onSetJobAlerts(!settings.jobAlerts)
  }

  handleAccountActiveChange = () => {
    const { onSetAccountStatus, settings } = this.props
    onSetAccountStatus(!settings.accountActive)
  }

  handleOpenDeleteModal = () => this.setState({ isDeleteModalShow: true })

  handleCloseDeleteModal = () => this.setState({ isDeleteModalShow: false })

  render() {
    const { user, t, settings } = this.props
    const { isDeleteModalShow } = this.state

    return (
      <>
        <PageWrapper>
          <BurgerMenu user={user} />
          <Header postJob={true} />
          <BreadCrumbs routeName={t('settings')} />
          <Content>
            <Ad />
            <Title>{t('settings')}</Title>
            <SettingsSection.Wrapper>
              <Notifications
                title={t('notifications')}
                value={settings.notifications}
                onClick={this.handleNotificationsChange}
              />
            </SettingsSection.Wrapper>
            <SettingsSection.Wrapper>
              <Section
                subTitle={t('receiveJob')}
                title={t('jobAlerts')}
                value={settings.jobAlerts}
                onClick={this.handleJobAlertsChange}
              />
            </SettingsSection.Wrapper>
            {user.authProvider === 'cognito' && (
              <SettingsSection.Wrapper>
                <ChangePassword />
              </SettingsSection.Wrapper>
            )}
            <SettingsSection.Wrapper>
              <Section
                subTitle={t('accountActiveInfo')}
                title={t('accountActive')}
                value={settings.accountActive}
                onClick={this.handleAccountActiveChange}
              />
            </SettingsSection.Wrapper>
            <SettingsSection.Wrapper>
              <Section
                buttonText={t('deleteAccount')}
                subTitle={t('deleteAccountInfo')}
                title={t('deleteAccount')}
                value={settings.accountActive}
                onClick={this.handleOpenDeleteModal}
              />
              <DeleteAccountModal
                isShow={isDeleteModalShow}
                onCloseModal={this.handleCloseDeleteModal}
              />
            </SettingsSection.Wrapper>
          </Content>
        </PageWrapper>
        <Footer black={true} isAuth={user} />
      </>
    )
  }
}

Settings.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  t: PropTypes.func,
  user: PropTypes.object
}

export default withNamespaces(['settings'])(Settings)
