import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { fontFamily, sizes, fontSize } from 'Constants'

export const DefaultQuotes = styled.h3`
  font-weight: 100;
  font-size: ${fontSize.h2};
  font-family: ${fontFamily};
  margin: 0 0 1em;
  color: ${props => props.theme.colors.defaultFontGrey};
  @media (max-width: ${sizes.phone}px) {
    font-size: ${fontSize.h3};
  }
`

export const GetStartedBtn = styled(Link)`
  color: ${props => props.theme.colors.joinButtonColor};
  background: linear-gradient(
    to right,
    rgba(10, 148, 241, 1) 0%,
    rgba(10, 148, 241, 1) 56%,
    rgba(100, 195, 255, 1) 100%
  );
  text-transform: uppercase;
  outline: none;
  font-size: ${fontSize.h4};
  font-family: ${fontFamily};
  font-weight: 900;
  padding: 10px 40px;
  border-radius: 22px;
  cursor: pointer;
  z-index: 10;
  text-decoration: none;
  &:hover {
    opacity: 0.9;
  }
`

export const QuoteBlock = styled.div`
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 60px 0;
  @media (max-width: ${sizes.desktop}px) {
    width: 80%;
    padding: 25px 0;
  }
`

export const WidthLimiter = styled.div`
  width: 90%;
  max-width: 1168px;
  margin: 0 auto;
`
