import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { createReducer } from 'Helpers/redux'

import {
  GET_FAVOURITES_JOBS,
  GET_FAVOURITES_PROVIDERS,
  REMOVE_JOB_FROM_FAVOURITES,
  REMOVE_PROVIDER_FROM_FAVOURITES
} from 'Redux/actions/favourites'
import { LOG_OUT } from 'Redux/actions/auth'

const persistConfig = {
  key: 'favourites',
  storage
}

const initialState = {
  favouritesJobs: [],
  favouritesProviders: []
}

const handlers = {
  [GET_FAVOURITES_JOBS.SUCCESS]: (prevState, { payload }) => ({
    ...prevState,
    favouritesJobs: payload.list
  }),
  [GET_FAVOURITES_PROVIDERS.SUCCESS]: (prevState, { payload }) => ({
    ...prevState,
    favouritesProviders: payload.list
  }),
  [REMOVE_JOB_FROM_FAVOURITES.SUCCESS]: (prevState, { meta }) => ({
    ...prevState,
    favouritesJobs: prevState.favouritesJobs.filter(
      item => item.favouriteId !== meta.favouriteJobId
    )
  }),
  [REMOVE_PROVIDER_FROM_FAVOURITES.SUCCESS]: (prevState, { meta }) => ({
    ...prevState,
    favouritesProviders: prevState.favouritesProviders.filter(
      item => item.favouriteId !== meta.favouriteSpId
    )
  }),
  [LOG_OUT.REQUEST]: () => initialState
}

export default persistReducer(
  persistConfig,
  createReducer(initialState, handlers)
)
