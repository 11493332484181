import _ from 'lodash'

export default rawProfile => {
  if (!_.isObject(rawProfile)) throw Error('Invalid profile')

  const processedProfile = _.clone(rawProfile)

  processedProfile.email_verified =
    _.get(rawProfile, 'email_verified', null) === 'true'
  processedProfile.phone_number_verified =
    _.get(rawProfile, 'phone_number_verified', null) === 'true'

  return processedProfile
}
