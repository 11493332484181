import styled from 'styled-components'

export const GalleryWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  & * img {
    border-radius: 5px;
  }
  & .image-gallery-thumbnail.active {
    border: 2px solid ${props => props.theme.colors.blueIcon};
    border-radius: 7px;
  }
  & .image-gallery-thumbnail {
    width: 56px;
  }

  & .image-gallery-thumbnails-wrapper.left {
    width: 65px;
  }
`

export const OneImage = styled.img`
  width: 80%;
  display: block;
  margin: 0 auto;
`

export const Image = styled.div`
  width: 200px;
  height: 200px;
  background: url("${p => p.url}") no-repeat center center;
`
