import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import { ItemWrap } from './styles'
import { privilege, checkProps } from './config'

const PrivilageList = ({ t, isVisible }) =>
  isVisible
    ? privilege.map(el => (
        <ItemWrap key={el}>
          <FontAwesomeIcon icon={faCheck} />
          <p>{t(el)}</p>
        </ItemWrap>
      ))
    : null

PrivilageList.propTypes = {
  isVisible: PropTypes.bool,
  t: PropTypes.func
}

PrivilageList.defaultProps = {
  isVisible: true
}

export default React.memo(PrivilageList, checkProps)
