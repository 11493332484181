import styled from 'styled-components'
import { fontFamily, fontSize, sizes } from 'Constants'

export const CarouselImage = styled.img`
  max-height: 50px;
  height: auto;
  @media (max-width: ${sizes.phone}px) {
    max-height: 50px;
  }
`
export const CarouselText = styled.span`
  padding-top: 15px;
  font-size: ${fontSize.default};
  font-family: ${fontFamily};
`
export const CarouselWrapper = styled.div`
  padding: 10px;
  text-align: center;
`
export const CarouselItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 25px;
  text-align: center;
`

export const ItemWrap = styled.div`
  & > a {
    text-decoration: none;
    color: ${props => props.theme.colors.defaultFontBlack};
  }
`

export const ServicesButtonAll = styled.p`
  position: absolute;
  font-weight: 500;
  margin: 0;
  right: 0;
  top: -20px;
  color: #2989d8;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  & :active {
    color: #ffffff;
    background: #2989d8;
  }
  @media (max-width: ${sizes.phone}px) {
    top: 0;
  }
`

export const MainTitle = styled.h2`
  text-align: center;
  margin: 0.1em;
  font-size: ${fontSize.large};
  font-family: ${fontFamily};
  font-weight: 300;
  color: #565656;
  @media (max-width: ${sizes.desktop}px) {
    font-size: ${fontSize.h1};
  }
  @media (max-width: ${sizes.tablet}px) {
    font-size: ${fontSize.h2};
  }
  @media (max-width: ${sizes.phone}px) {
    font-size: ${fontSize.h3};
  }
`

export const SliderWrapper = styled.div`
  width: 90%;
  max-width: 1168px;
  margin: 0 auto;
  position: relative;
  padding-top: 30px;
`

export const SectionCategories = styled.section`
  padding: 3em 0 1em;
  max-width: 100%;
  @media (max-width: ${sizes.phone}px) {
    padding: 2em 0;
  }
`

export const Item = styled.div`
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

ItemWrap.displayName = 'ItemWrap'
Item.displayName = 'Item'