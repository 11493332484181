import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { withServiceProviderProfile } from 'Components/hoc'

import { withRouter } from 'react-router-dom'

import { loadUserReviews, deleteReview } from 'Redux/actions/review'

import { getProfile } from 'Redux/selectors/user'
import { getReviews } from 'Redux/selectors/review'

import Component from './RatingsProfile'

const actions = {
  onLoadReviews: loadUserReviews,
  onDeleteReview: deleteReview
}

const selectors = createStructuredSelector({
  reviews: getReviews,
  user: getProfile
})

export default compose(
  withServiceProviderProfile,
  withRouter,
  connect(
    selectors,
    actions
  )
)(Component)
