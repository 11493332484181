import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { signUp, oauthAuthenticate } from 'Redux/actions/auth'

import Component from './SignUp'

const selector = createStructuredSelector({})

const actions = {
  onSignUp: signUp,
  onOauthAuthorize: oauthAuthenticate
}

export default connect(
  selector,
  actions
)(Component)
