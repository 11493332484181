import Slide1 from 'Assets/slides/dummySlide1.jpg'
import Slide2 from 'Assets/slides/dummySlide2.jpg'
import Slide3 from 'Assets/slides/dummySlide3.jpg'

export const images = [
  {
    original: Slide1,
    thumbnail: Slide1
  },
  {
    original: Slide2,
    thumbnail: Slide2
  },
  {
    original: Slide3,
    thumbnail: Slide3
  }
]

export const ServiceProviders = [
  {
    id: 'ololo',
    name: 'Clayton L',
    isVerified: true,
    stars: 5,
    isPro: true,
    isPremium: true,
    professions: ['Plumber', 'Developer', 'M.D.', 'HR', 'Singer', 'Teacher']
  },
  {
    id: 'ololo',
    name: 'Clayton L',
    isVerified: false,
    stars: 5,
    isPro: true,
    isPremium: true,
    professions: ['Plumber', 'Developer', 'M.D.', 'HR', 'Singer', 'Teacher']
  },
  {
    id: 'ololo',
    name: 'Clayton L',
    isVerified: true,
    stars: 5,
    isPro: false,
    isPremium: false,
    professions: ['Plumber', 'Developer', 'M.D.', 'HR', 'Singer', 'Teacher']
  }
]
