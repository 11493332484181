import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { withNamespaces } from 'react-i18next'
import { withTracking } from 'Components/hoc'

import { loadTrackJobs } from 'Redux/actions/track'

import { getProfile } from 'Redux/selectors/user'
import { getTrackJobList, getGeolocation } from 'Redux/selectors/track'
import { getSpecificSP } from 'Redux/selectors/serviceProvider'

import Component from './TrackNow'

const actions = {
  onLoadTrackJobs: loadTrackJobs
}

const selectors = createStructuredSelector({
  user: getProfile,
  jobs: getTrackJobList,
  profile: getSpecificSP,
  geoLocation: getGeolocation,
})

export default compose(
  withTracking,
  withNamespaces(),
  connect(
    selectors,
    actions
  )
)(Component)
