import * as routes from 'Routes/routesConstants'

export const sortData = [
  {
    text: 'rate',
    value: 'rating',
    turnOnText: 'highToLow',
    turnOffText: 'lowToHigh',
    activeValue: 'desc',
    disabledValue: 'asc'
  },
  {
    text: 'distance',
    value: 'distance',
    turnOnText: ['closest', 'on'],
    turnOffText: ['closest', 'off'],
    activeValue: 'asc',
    disabledValue: 'desc'
  },
  {
    text: 'pros',
    value: 'proStatus',
    turnOnText: 'common:on',
    turnOffText: 'common:off'
  },
  {
    text: 'verified',
    value: 'verifiedStatus',
    turnOnText: 'common:on',
    turnOffText: 'common:off'
  },
  {
    text: 'premiumMembers',
    value: 'isPremium',
    turnOnText: 'common:on',
    turnOffText: 'common:off'
  }
]

export const path = {
  profile: `${routes.findHelp}${routes.freelancerProfile}`,
  review: `${routes.findHelp}/:userId${routes.ratingProfile}`
}
