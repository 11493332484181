import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { TABS_LIST_MAP } from 'Constants/tabNav'
import memoize from 'memoize-one'

import { ContentWrapper, Msg } from 'Components/ui'
import { HeaderTabs, MapView } from 'Components/blocks'

import { ListItem } from './innerBlocks'
import { Container, Title, List } from './style'
import { getPeopleWhoApplied } from './memoize'

class ApplyBySection extends PureComponent {
  state = {
    activeTabId: 'a'
  }

  handleTabClick = id => this.setState({ activeTabId: id })

  renderCard = props => {
    const {
      user,
      job,
      onAccept,
      onReject,
      onHire,
      loadingApplicationId,
      onClickProfile,
      location
    } = this.props
    const { activeTabId } = this.state

    return (
      <ListItem
        {...props.user}
        createdAt={props.createdAt}
        isLoading={props._id === loadingApplicationId}
        isMapView={activeTabId === 'b'}
        isMyJob={_.get(job, 'author.username') === user.username}
        key={props._id}
        path={location.pathname + '/' + props._id + '/' + props.user.username}
        status={props.status}
        onAccept={memoize(() => onAccept(props))}
        onClickProfile={
          _.isFunction(onClickProfile) && memoize(() => onClickProfile(props))
        }
        onHire={memoize(() => onHire(props))}
        onReject={memoize(() => onReject(props))}
      />
    )
  }

  _renderListView = () => {
    const { t, job, user } = this.props

    const data = getPeopleWhoApplied(job, user.username)

    return (
      <Container>
        <Title>
          {t('totalServiceProviders')} ({data.length})
        </Title>
        <List>{data.map(this.renderCard)}</List>
      </Container>
    )
  }

  _renderMapView = () => {
    const { job, user } = this.props

    const data = getPeopleWhoApplied(job, user.username)

    return (
      <MapView
        data={data.map(el => ({
          ...el,
          geolocation: el.user.geolocation
        }))}
        renderCard={this.renderCard}
      />
    )
  }

  _renderChatButton = () => {
    const { user, job, history } = this.props
    if (!job) return null

    const jobByUser = job.peopleWhoApplied.find(item => item.userId === user._id) || {}
    const showChatButton = jobByUser.status === 'accepted'

    if (!showChatButton) return null

    return (
      <Msg
        isRequest={false}
        onClick={() => history.push(`/chats/${job._id}#${jobByUser.user.chatId || ''}`)}
      />
    )
  }

  render() {
    const { job, user } = this.props
    const { activeTabId } = this.state

    const data = getPeopleWhoApplied(job, user.username)

    if (_.isEmpty(data)) return null
    this.props.onClose()
    return (
      <>
        <ContentWrapper>
          <HeaderTabs
            options={TABS_LIST_MAP}
            onClickLink={this.handleTabClick}
          />
          {activeTabId === 'b' ? this._renderMapView() : this._renderListView()}
          {this._renderChatButton()}
        </ContentWrapper>
      </>
    )
  }
}

ApplyBySection.propTypes = {
  job: PropTypes.object,
  loadingApplicationId: PropTypes.string,
  t: PropTypes.func,
  user: PropTypes.object,
  onAccept: PropTypes.func,
  onClickProfile: PropTypes.func,
  onHire: PropTypes.func,
  onReject: PropTypes.func
}

export default ApplyBySection
