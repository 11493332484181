import React from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'

import { ButtonHire } from './styles'

const HireButton = ({ t, onClick }) => (
  <ButtonHire onClick={onClick}>{t('hireNow')}</ButtonHire>
)

HireButton.propTypes = {
  t: PropTypes.func,
  onClick: PropTypes.func
}

export default withNamespaces('serviceProvider')(HireButton)
