import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import * as routes from 'Routes/routesConstants'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'
import moment from 'moment'
import toast from 'react-toastify'

import { arrows } from 'Assets/arrows'
import { icons } from 'Assets/icons'
import { paymentsTransactions, root } from 'Routes/routesConstants'
import { PaymentCard } from 'Components/blocks'
import {
  ProfileBackground,
  FormContainer,
  Logo,
  Registered,
  SubTitle,
  EmptyList
} from 'Components/ui'

import {
  ContentHeaderContainer,
  ContentHeader,
  ContnentHeaderBackButton,
  Section,
  Block,
  Loading
} from './styles'
import { getServiceFees, getTax, getTotal } from 'Helpers/payments'

class PaymentsHistory extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isRequest: false
    }
  }

  componentDidMount() {
    const { onGetPaymentsHistory } = this.props
    this.setState({ isRequest: true })
    onGetPaymentsHistory(error => {
      this.setState({ isRequest: false })
      if (error) {
        toast.error(error.message)
      }
    })
  }

  renderContent = () => {
    const { payments } = this.props

    return (
      <Block>
        {isEmpty(payments) ? (
          <EmptyList />
        ) : (
          payments.map(payment => {
            const date = moment(payment.createdAt).format('DD/MM/YYYY')

            return (
              <PaymentCard
                key={payment._id}
                date={date}
                refValue={payment._id}
                jobTitle={payment.jobTitle}
                budget={this.formatPrice(payment.totalAmount)}
                serviceFees={getServiceFees(payment.totalAmount, payment.serviceFees)}
                totalAmount={getTotal(payment.totalAmount, payment.serviceFees)}
                paymentType={payment.paymentType}
                link={`${routes.findJobs}${routes.listView}/${payment.jobId}`}
                isPayment
                tax={getTax(payment.totalAmount, payment.serviceFees)}
              />
            )
          })
        )}
      </Block>
    )
  }

  formatPrice = price => Math.round(price * 100) / 100

  render() {
    const { t } = this.props
    const { isRequest } = this.state
    return (
      <ProfileBackground>
        <Link to={root}>
          <Logo>
            SpotJobs<Registered>&reg;</Registered>
          </Logo>
          <SubTitle>{t('signInHeadDescription')}</SubTitle>
        </Link>
        <FormContainer>
          <Fragment>
            <ContentHeaderContainer>
              <ContentHeader>{t('payments')}</ContentHeader>
              <Link to={paymentsTransactions}>
                <ContnentHeaderBackButton src={arrows.arrowBack} />
              </Link>
            </ContentHeaderContainer>
            {isRequest ? (
              <Loading>
                <img alt="" src={icons.preload} />
              </Loading>
            ) : (
              <Section>{this.renderContent()}</Section>
            )}
          </Fragment>
        </FormContainer>
      </ProfileBackground>
    )
  }
}

PaymentsHistory.propTypes = {
  payments: PropTypes.array,
  onGetPaymentsHistory: PropTypes.func
}

export default PaymentsHistory
