import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import { Overlay, Box, Icon, Text } from './style'

class FullScreenLoader extends Component {
  state = {}

  shouldComponentUpdate(nextProps, nextState) {
    const { isVisible } = this.props

    return isVisible !== nextProps.isVisible
  }

  renderContent = () => {
    const { t, isVisible } = this.props
    return (
      <Overlay isVisible={isVisible}>
        <Box>
          <Icon isActive={isVisible} />
          <Text>{t('loading')}</Text>
        </Box>
      </Overlay>
    )
  }

  render() {
    return ReactDOM.createPortal(
      this.renderContent(),
      document.getElementById('screen-loader')
    )
  }
}

FullScreenLoader.propTypes = {
  isVisible: PropTypes.bool,
  t: PropTypes.func
}

export default FullScreenLoader
