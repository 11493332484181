import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'Components/ui'

export const Container = styled.div`
  padding: 0 10px 10px;
`

export const Header = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 10px;
`

export const Title = styled.h1`
  font-size: ${p => p.theme.fontSize.h3};
  color: #000;
  text-align: center;
  font-weight: bold;
`

export const CloseIcon = styled(FontAwesomeIcon).attrs(() => ({
  icon: faTimes
}))`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -15px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.joinUs};
  background: #fff;
  width: 25px !important;
  height: 25px;
  border-radius: 50%;
  top: -5px;
  box-shadow: 0 1px 4px 0 #c9cfde;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.5;
  }
`

export const Label = styled.label`
  font-size: ${p => p.theme.fontSize.small};
  display: block;
  margin-bottom: 5px;
`

export const Content = styled.div`
  margin: 20px auto 40px;
  max-width: 300px;
`

export const Textarea = styled.textarea`
  border: solid 1px silver;
  border-radius: 5px;
  width: 100%;
  resize: none;
  height: 100px;
  outline: none;
  font-size: ${p => p.theme.fontSize.default};
`

export const SubmitButton = styled(props => <Button {...props} />)`
  margin: 0 auto 20px;
  display: block;
  ${p => p.disabled && 'filter: grayscale(100%); pointer-events: none'};
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

export const Text = styled.span`
  flex: 1;
  font-size: 18px;
`

export const CheckBox = styled.input`
  cursor: pointer;
`

export const ReasonError = styled.div`
  position: absolute;
  margin-top: 10px;
  font-size: 12px;
  color: ${p => p.theme.colors.iconRed};
`
