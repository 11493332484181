import React from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'

import { Title } from './styles'

const Area = ({ children, t }) => <Title>{t(children)}</Title>

Area.propTypes = {
  children: PropTypes.string,
  t: PropTypes.func
}

export default withNamespaces('services')(Area)
