import memoize from 'memoize-one'

export const getBreadCrumbs = memoize(t => [
  {
    displayName: t('homePage:postAds')
  },
  {
    displayName: t('homePage:createAds')
  }
])
