import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'

import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'

import { api } from 'Configs/App'
import { icons } from 'Assets/icons'
import { signUp, forgotIdPass, root, signIn } from 'Routes/routesConstants'
import {
  SocialButtons,
  Background,
  Registered,
  Logo,
  SubTitle,
  Label,
  AuthInput,
  BtnSubmit,
  FormTitle,
  Error,
  CheckBoxSpan,
  InputWrapper,
  ChekboxInput
} from 'Components/ui'

import { getValidationSchema } from './config'
import { updateCredentials } from 'Services/Libs/s3.lib'
import { apiUrl } from 'Constants/api'

import {
  SignUp,
  SignUpLink,
  BtnSubmitWrapper,
  StyledForm,
  StyledAuthFormWrapper,
  StyledForgetLink,
  StyledCheckBoxText,
  StyledForgotWrap,
  RememberLabel
} from './styles'
import { handleSocialClick } from 'Helpers/socialLogin'

class SignIn extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isRequest: false,
      showPassword: false
    }
  }

  async componentDidMount() {
    const { location, onOauthAuthorize, history, t } = this.props
    const queryString = location.search.substr(1)

    if (queryString.startsWith('user=')) {
      this.setState({ isRequest: true })
      const bodyStr = decodeURIComponent(queryString.substr('user='.length))
      const tokens = JSON.parse(bodyStr)
      const auth = { tokens }
      const headers = {
        Authorization: [tokens.TokenType, tokens.AccessToken].join(' ')
      }

      await axios
        .get(api.defaultUrl + '/user', { headers })
        .then(async ({ status, data }) => {
          if (status === 200) {
            auth.user = data
            onOauthAuthorize(auth)
            if (auth?.tokens?.AccessToken) {
              await this.getCredentials(auth.tokens.AccessToken).then(
                ({ data }) => {
                  localStorage.setItem(
                    'awsCredential',
                    JSON.stringify(data.aws_credential)
                  )
                  updateCredentials(data.aws_credential)
                }
              )
            }

            history.replace(root)
          }
        })
        .catch(err => {
          toast.error(t('NotAuthorizedException'))
        })
        .finally(() => {
          this.setState({ isRequest: false })
        })
    } else if (queryString.startsWith('error_message=')) {
      let bodyStr = decodeURIComponent(
        queryString.substr('error_message='.length)
      )
      if (bodyStr.indexOf('?') !== -1) {
        bodyStr = bodyStr.split('?')[0]
      }
      const errorBody = JSON.parse(bodyStr)
      if (errorBody) {
        const errorMessage = errorBody.message
        if (errorMessage) {
          history.replace(signIn)
          toast.error(errorMessage)
        }
      }
    }
  }

  handleSubmit = data => {
    const { onSignIn } = this.props
    const { email, password, remember = false } = data
    this.setState({
      isRequest: true
    })
    onSignIn(email, password, { remember, email, password }, (error, res) => {
      this.setState({
        isRequest: false
      })

      if (!error) {
        if (res && res.aws_credential) {
          localStorage.setItem(
            'awsCredential',
            JSON.stringify(res.aws_credential)
          )
          updateCredentials(res.aws_credential)
        }
        this.props.history.push({
          pathname: root
        })
      } else {
        if (error.code === 'UserNotConfirmedException') {
          this.props.history.push('/verify', { username: email })
        }
      }
    })
  }

  handleToggle = () => {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  getCredentials = async token => {
    const api = await axios.create({
      baseURL: apiUrl,
      timeout: 20000,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })

    return api.get('/aws/credential')
  }

  getLoginRemember = () => {
    const email = localStorage.getItem('email')
    const password = localStorage.getItem('password')
    if (email && password) {
      return {
        email,
        password,
        remember: true
      }
    }
    return {
      email: '',
      password: '',
      remember: false
    }
  }

  render() {
    const { t } = this.props
    return (
      <Background>
        <Link to={root}>
          <Logo>
            SpotJobs<Registered>&reg;</Registered>
          </Logo>
          <SubTitle>{t('subTitle')}</SubTitle>
        </Link>
        <Formik
          validationSchema={getValidationSchema(t)}
          onSubmit={this.handleSubmit}
          initialValues={this.getLoginRemember()}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              submitCount
            } = props
            const isFormTouched = submitCount > 0
            return (
              <StyledForm onSubmit={handleSubmit}>
                <FormTitle>{t('SignIn')}</FormTitle>
                <StyledAuthFormWrapper>
                  <InputWrapper>
                    <Label htmlFor="email">{t('EmailID')}</Label>
                    <AuthInput
                      id="email"
                      placeholder={t('EnterYourEmail')}
                      type="text"
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {errors.email && (touched.email || isFormTouched) && (
                      <Error>{errors.email}</Error>
                    )}
                  </InputWrapper>
                  <InputWrapper>
                    <Label htmlFor="password">{t('Password')}</Label>
                    <AuthInput
                      id="password"
                      placeholder={t('Password')}
                      type={this.state.showPassword ? 'text' : 'password'}
                      value={values.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <FontAwesomeIcon
                      icon={this.state.showPassword ? faEye : faEyeSlash}
                      onClick={this.handleToggle}
                    />
                    {errors.password && (touched.password || isFormTouched) && (
                      <Error>{errors.password}</Error>
                    )}
                  </InputWrapper>
                  <StyledForgotWrap>
                    <RememberLabel htmlFor="remember">
                      <ChekboxInput
                        checkbox
                        id="remember"
                        type="checkbox"
                        value={values.remember}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <CheckBoxSpan toggle={values.remember} />
                      <StyledCheckBoxText>{t('RememberMe')}</StyledCheckBoxText>
                    </RememberLabel>
                    <StyledForgetLink to={forgotIdPass}>
                      {t('ForgotPass')}
                    </StyledForgetLink>
                  </StyledForgotWrap>

                  {this.state.isRequest ? (
                    <BtnSubmitWrapper>
                      <BtnSubmit disabled>
                        <img alt="" src={icons.threeDotsLoader} />
                      </BtnSubmit>
                    </BtnSubmitWrapper>
                  ) : (
                    <BtnSubmitWrapper>
                      <BtnSubmit
                        isDisabled={Object.keys(errors).length !== 0}
                        type="submit"
                      >
                        {t('SignIn')}
                      </BtnSubmit>
                    </BtnSubmitWrapper>
                  )}
                  <SignUp>
                    {t('dontHaveAccount?')}{' '}
                    <SignUpLink to={signUp}>{t('SignUp')}</SignUpLink>
                  </SignUp>
                </StyledAuthFormWrapper>
              </StyledForm>
            )
          }}
        </Formik>
        <SocialButtons
          disabled={this.state.isRequest}
          onHandleClick={handleSocialClick}
        />
      </Background>
    )
  }
}

SignIn.propTypes = {
  onSignIn: PropTypes.func
}

export default withNamespaces('sign')(SignIn)
