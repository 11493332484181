import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import InfiniteScroll from 'react-infinite-scroller'
import { Route, Switch } from 'react-router-dom'
import * as routes from 'Routes/routesConstants'
import { toast } from 'react-toastify'
import { icons } from 'Assets/icons'

import {
  JobCard,
  RightSidebar,
  Filter,
  BodySearch,
  Sort,
  MapView,
  ProgressBar,
  JobView,
  ApplyBySection,
  ListAd,
  BreadCrumbs,
  FreelancerProfileView
} from 'Components/blocks'

import {
  CardWrapper,
  RightSideBarWrapper,
  TopPart,
  DownPart,
  FiltersGroup,
  SSGroup,
  PreloadImg,
  LoadMoreWrapper,
  ContentWrapper
} from 'Components/ui'

import { sortData } from '../../config'
import { path } from './config'
import {
  getJobBreadCrumbs,
  getJobDescrBreadCrumbs,
  getPeopleWhoAppliedBreadCrumbs,
  getAppliedProfileBC
} from './memoize'

import { Container, GoogleAdsFirst } from './style'

export class PostedJobs extends Component {
  state = {
    isRequest: true,
    elements_per_page: 10,
    pageNumber: 0,
    order_by: 'distance',
    order: 'asc',
    keywords: '',
    isLoadMoreRequest: false,
    activeCategoriesIds: [],
    activeTabId: 'a',
    location: {
      location: undefined
    }
  }

  componentDidMount() {
    const { onGetPostedJobList, defaultRadius, userName } = this.props
    const { elements_per_page, pageNumber } = this.state

    const request = {
      elements_per_page,
      page_number: pageNumber,
      radius: defaultRadius,
      author: userName
    }

    onGetPostedJobList(request, () => this.setState({ isRequest: false }), true)
  }

  handleTabClick = id => this.setState({ activeTabIndex: id })

  onFilter = (data = {}) => {
    const { onGetPostedJobList, userName, user } = this.props
    const {
      elements_per_page,
      order_by,
      order,
      keywords,
      pageNumber
    } = this.state

    let location = this.state.location
    if (!_.isEmpty(data.location)) {
      location = {
        ...data.locationFields,
        location: data.location
      }
    }

    this.setState({
      isRequest: true,
      activeCategoriesIds: _.isEmpty(data.services) ? [] : data.services,
      location
    })

    const request = {
      elements_per_page,
      page_number: pageNumber,
      order_by: order_by,
      order: order,
      keywords: keywords ? _.trim(keywords) : undefined,
      radius: user.defaultRadius || 10,
      geolocation: user.geolocation,
      author: userName,
      categories: _.isEmpty(data.services)
        ? undefined
        : JSON.stringify(data.services)
    }

    onGetPostedJobList(request, () => this.setState({ isRequest: false }), true)
  }

  onClickPopularService = ({ services }) => {
    const { activeCategoriesIds } = this.state
    const id = services[0]
    let result
    if (activeCategoriesIds.indexOf(id) === -1) {
      result = activeCategoriesIds.concat(services)
    } else {
      result = activeCategoriesIds.filter(el => el !== id)
    }

    this.onFilter({ services: result })
  }

  handleEditClick = job => {
    const { history } = this.props

    history.push({
      pathname: routes.jobPosts,
      params: {
        data: {
          ...job,
          category: _.get(job, 'category._id', job.category),
          service: _.get(job, 'service._id', job.service)
        }
      }
    })
  }

  handleDeleteClick = id => {
    const { onDeleteJob, t } = this.props

    onDeleteJob(id, error => !error && toast.success(t('common:JobDeleted')))
  }

  handleClearKeywords = () => this.setState({ keywords: '' })

  loadMoreItems = () => {
    const { defaultRadius, onGetPostedJobList, userName } = this.props
    const { elements_per_page, pageNumber, isLoadMoreRequest } = this.state

    if (isLoadMoreRequest) return

    const request = {
      elements_per_page,
      page_number: pageNumber + 1,
      radius: defaultRadius,
      author: userName
    }

    this.setState({ isLoadMoreRequest: true, pageNumber: pageNumber + 1 })
    onGetPostedJobList(request, () => {
      this.setState({ isLoadMoreRequest: false })
    })
  }

  _renderListView = () => {
    const { postedJobs, onClickJob, onShare, onApply } = this.props
    const { isRequest, activeCategoriesIds } = this.state

    if (isRequest && _.isEmpty(postedJobs.data)) {
      return <PreloadImg alt="loading" src={icons.preload} />
    }

    return (
      <DownPart>
        <CardWrapper>
          <GoogleAdsFirst />
          <InfiniteScroll
            hasMore={postedJobs.isListEnd}
            loadMore={this.loadMoreItems}
            loader={
              <LoadMoreWrapper key={-1}>
                <PreloadImg
                  alt="loading"
                  className="loader"
                  src={icons.preload}
                />
              </LoadMoreWrapper>
            }
            useWindow
          >
            {(postedJobs.data || []).map((item, key) => (
              <Fragment key={key}>
                <JobCard
                  {...item}
                  isMyPostedJobs
                  onClick={() => onClickJob(item)}
                  onEditClick={() => this.handleEditClick(item)}
                  onHandleApply={onApply}
                  onHandleDeleteClick={this.handleDeleteClick}
                  onHandleFavouriteClick={this.handleFavouriteClick}
                  onShareClick={() => onShare(item)}
                />
                <ListAd data={postedJobs.data} index={key} />
              </Fragment>
            ))}
          </InfiniteScroll>
          {_.isEmpty(postedJobs.data) ? null : <GoogleAdsFirst />}
        </CardWrapper>
        <RightSideBarWrapper>
          <RightSidebar
            activeCategoriesIds={activeCategoriesIds}
            handleFilter={this.onClickPopularService}
          />
        </RightSideBarWrapper>
      </DownPart>
    )
  }

  handleSorting = ({ order, orderBy }) => {
    this.setState({ order_by: orderBy, order }, this.onFilter)
  }

  handleSearch = keywords => this.setState({ keywords }, this.onFilter)

  handleApplyByClick = profile => {
    const { history, specificJob } = this.props

    const user = _.get(profile, 'user', profile)
    history.push(
      `${routes.myJobs}${routes.posted}/${specificJob._id}${routes.appliedServiceProviders}/${user.username}`
    )
  }

  handleOpenPeopleWhoApplied = () => {
    const { history, specificJob } = this.props

    history.push(
      `${routes.myJobs}${routes.posted}/${specificJob._id}${routes.appliedServiceProviders}`
    )
  }

  handleReject = application => {
    const { history, onReject } = this.props

    onReject(application, () => {
      history.replace(`${routes.myJobs}${routes.posted}`)
    })
  }

  _renderMapView = () => {
    const { postedJobs, onClickJob } = this.props
    const { isRequest } = this.state

    return (
      <MapView
        data={postedJobs.data}
        loading={isRequest}
        renderCard={props => (
          <JobCard
            {...props}
            isMyPostedJobs
            onClick={() => onClickJob(props)}
          />
        )}
      />
    )
  }

  _renderContent = () => {
    const { services, t } = this.props

    const {
      order_by: orderBy,
      location: savedLocation,
      activeCategoriesIds,
      activeTabIndex
    } = this.state

    const breadCrumbs = getJobBreadCrumbs(t)

    return (
      <>
        <BreadCrumbs data={breadCrumbs} />
        <ContentWrapper>
          <TopPart>
            <FiltersGroup>
              <Filter
                handleFilter={this.onFilter}
                initialValues={{
                  location: savedLocation,
                  services: activeCategoriesIds
                }}
                isfindJob
              />
              <SSGroup>
                <BodySearch
                  services={services.services}
                  onFocus={this.handleClearKeywords}
                  onSetService={service =>
                    this.onFilter({ services: [service] })
                  }
                  onSubmit={this.handleSearch}
                />
                <Sort
                  data={sortData}
                  value={orderBy}
                  onSelect={this.handleSorting}
                />
              </SSGroup>
            </FiltersGroup>
          </TopPart>
          {activeTabIndex === 'b'
            ? this._renderMapView()
            : this._renderListView()}
        </ContentWrapper>
      </>
    )
  }

  _renderJobDescription = () => {
    const { t, specificJob } = this.props
    const breadCrumbs = getJobDescrBreadCrumbs(t, _.get(specificJob, 'title'))

    return (
      <>
        <BreadCrumbs data={breadCrumbs} />
        <ContentWrapper>
          <Container>
            <JobView
              onDeleteClick={() =>
                this.handleDeleteClick(_.get(specificJob, '_id'))
              }
              onEditClick={() => this.handleEditClick(specificJob)}
              onOpenApplyByProfile={this.handleApplyByClick}
              onOpenPeopleWhoApplied={this.handleOpenPeopleWhoApplied}
            />
          </Container>
        </ContentWrapper>
      </>
    )
  }

  renderPeopleWhoAppliedProfile = props => {
    const {
      serviceProfile,
      t,
      specificJob,
      onAccept,
      onHire,
      loadingApplicationId,
      onSendRequest
    } = this.props
    const breadCrumbs = getAppliedProfileBC(
      t,
      _.get(specificJob, 'title'),
      _.get(specificJob, '_id'),
      _.get(serviceProfile, 'username')
    )

    const userId = _.get(props, 'match.params.freelancerId')

    const application = (specificJob?.peopleWhoApplied || []).find(
      el => el.userId === userId
    )

    return (
      <>
        <BreadCrumbs data={breadCrumbs} />
        <ContentWrapper>
          <Container>
            <FreelancerProfileView
              {...props}
              application={application}
              isLoadingApplication={Boolean(loadingApplicationId)}
              isMyJob
              onAcceptApplication={() => onAccept(application)}
              onHire={() => onSendRequest(serviceProfile)}
              onHireApplication={() => onHire(application)}
              onRejectApplication={() => this.handleReject(application)}
            />
          </Container>
        </ContentWrapper>
      </>
    )
  }

  renderPeopleWhoApplied = props => {
    const { t, specificJob } = this.props
    const breadCrumbs = getPeopleWhoAppliedBreadCrumbs(
      t,
      _.get(specificJob, 'title'),
      _.get(specificJob, '_id')
    )

    return (
      <>
        <BreadCrumbs data={breadCrumbs} />
        <ApplyBySection {...props} onClickProfile={this.handleApplyByClick} />
      </>
    )
  }

  render() {
    const { isRequest } = this.state
    return (
      <>
        <ProgressBar percent={isRequest ? 1 : 100} />

        <Switch>
          <Route
            exact
            path={path.jobDescription}
            render={this._renderJobDescription}
          />
          <Route
            exact
            path={path.appliedBy}
            render={this.renderPeopleWhoApplied}
          />
          <Route
            exact
            path={path.appliedByProfile}
            render={this.renderPeopleWhoAppliedProfile}
          />
          <Route render={this._renderContent} />
        </Switch>
      </>
    )
  }
}

PostedJobs.propTypes = {
  defaultRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  history: PropTypes.object,
  location: PropTypes.object,
  postedJobs: PropTypes.shape({
    data: PropTypes.array,
    isListEnd: PropTypes.bool
  }),
  serviceProfile: PropTypes.object,
  services: PropTypes.object,
  specificJob: PropTypes.object,
  t: PropTypes.func,
  user: PropTypes.object.isRequired,
  userName: PropTypes.string,
  onApply: PropTypes.func,
  onApplyByClick: PropTypes.func,
  onClickJob: PropTypes.func,
  onDeleteJob: PropTypes.func,
  onGetJobList: PropTypes.func,
  onGetPostedJobList: PropTypes.func,
  onShare: PropTypes.func,
  onAccept: PropTypes.func,
  onHire: PropTypes.func,
  onReject: PropTypes.func,
  loadingApplicationId: PropTypes.string,
  onSendRequest: PropTypes.func
}
