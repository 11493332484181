import styled from 'styled-components'
import { sizes, fontSize, fontFamily } from 'Constants'

export const Content = styled.div`
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
  @media only screen and (max-width: ${sizes.tablet}px) {
    padding-top: 0;
  }
  padding: 20px 0;
  font-family: ${fontFamily};
`

export const Title = styled.h1`
  font-size: ${fontSize.large};
  font-weight: bold;
  text-align: center;
  margin: 20px 0 15px;
`
