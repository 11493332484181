import ProfileImagesManager from 'Services/Images/ProfileImagesManager'
import { profileImageTypes } from 'Services/Constants/profileImages.constants'
import _ from 'lodash'

const backet = process.env.REACT_APP_BACKET
const serviceFolder = 'service-icons'
const jobsFolder = 'job-pics'

export const getServiceImage = fileName => {
  const iconName = fileName.replace(/\s/g, '%20')
  return `https://${backet}.s3.amazonaws.com/${serviceFolder}/${iconName}`
}

export const getJobImage = (id, fileName) =>
  `https://${backet}.s3.amazonaws.com/${jobsFolder}/${id}/${fileName}`

export const getProfileImage = ({ type, src, userId }) => {
  let source = src

  if (_.isString(src)) {
    if (/(data:image\/.*?;base64),/g.exec(src) || src.startsWith('http')) {
      source = src
    } else {
      source = ProfileImagesManager.getSignedURLForProfileImage(
        type,
        src,
        userId
      )
    }
  } else if (_.isObject(src)) {
    source = null
  }

  return source
}

export const isUrl = str => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  )
  return !!pattern.test(str)
}

export const getProfileAvatar = (avatarURL, userId) => {
  if (isUrl(avatarURL)) return avatarURL

  if (_.isString(avatarURL)) {
    return getProfileImage({
      type: profileImageTypes.avatar,
      src: avatarURL,
      userId
    })
  }

  return null
}
