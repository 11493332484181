import styled from 'styled-components'
import { sizes } from 'Constants'

export const ItemDefaultWrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  ${props =>
    props.wrapped && `border: 1px solid ${props.theme.colors.itemBorder}`}
`

export const ContainersWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
  @media (max-width: ${sizes.phone}px) {
    padding: 30px 0;
  }
`

export const GridThreeElements = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  ${ItemDefaultWrapper} {
    width: 27%;
    @media (max-width: ${sizes.desktop}px) {
      width: 27%;
    }
    @media (max-width: ${sizes.tablet}px) {
      width: 45%;
    }
    @media (max-width: ${sizes.phone}px) {
      width: 90%;
    }
  }
`

export const WidthLimiter = styled.div`
  width: 90%;
  max-width: 1168px;
  margin: 0 auto;
`
