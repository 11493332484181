import React from 'react'
import PropTypes from 'prop-types'
import { getGeocodeObg } from 'Helpers/maps'

import { Map, Marker } from 'google-maps-react'
import { Modal } from 'Components/ui'
import { Container } from './style'

const MapModal = ({ isShow, job, google, onClose }) => {
  const { lat, lng } = getGeocodeObg(job.geolocation)

  return (
    <Modal isShow={isShow} onRequestClose={onClose}>
      <Container>
        <Map google={google} initialCenter={{ lat, lng }} zoom={16}>
          <Marker position={{ lat, lng }} />
        </Map>
      </Container>
    </Modal>
  )
}

MapModal.propTypes = {
  google: PropTypes.any,
  isShow: PropTypes.bool,
  job: PropTypes.object.isRequired,
  onClose: PropTypes.func
}

export default React.memo(MapModal)
