const _ = require('lodash')
const uuid = require('uuid')

const S3 = require('../Libs/s3.lib')

const {
  DEFAULT_BUCKET_NAME,
  bucketFolderNames
} = require('../Constants/images.constants')

class Voices {
  /**
   * Put object to S3 bucket\
   * Returns S3 response
   *
   * @param Body image parametrs { image, imageType }
   * @param {object} paramsOverride override default put options
   */
  async putObject(Body, paramsOverride = {}) {
    const imageName = paramsOverride.imageName || uuid.v4()
    const filename = `${imageName}.mp3`
    const filePath = `chats/${paramsOverride.chatId}/${filename}`;

    const params = {
      Body,
      Bucket: paramsOverride.bucket || null,
      Key: filePath
    }

    if (paramsOverride.kmsKey) {
      params.ServerSideEncryption = 'aws:kms'
      params.SSEKMSKeyId = paramsOverride.kmsKey
    }

    const response = await S3.call('putObject', params)

    return _.assign(response, { filename })
  }

  /**
   * Returns signed url to get image from S3
   *
   * @param {string} filename Filename of image
   * @param {object} paramsOverride Override default get options
   */
  getSignedURLForObject(filename, paramsOverride = {}) {
    const folderName = paramsOverride.folderName
    const filePath =
      paramsOverride.filePath ||
      `${folderName}/${
        paramsOverride.subfolder ? paramsOverride.subfolder + '/' : ''
        }${filename}`

    const operationParametrs = {
      Bucket: paramsOverride.bucket || DEFAULT_BUCKET_NAME,
      Key: filePath
    }

    return S3.instance.getSignedUrl('getObject', operationParametrs)

  }

  /**
   * Get image from S3 \
   * Returns S3 response
   * @param {string} imageType Type of image to remove
   * @param {string} filename Filename of image to remove
   * @param {object} paramsOverride Override default delete options
   */
  async getObject(imageType, filename, paramsOverride = {}) {
    const folderName = paramsOverride.folderName || bucketFolderNames[imageType]
    const filePath =
      paramsOverride.filePath ||
      `${folderName}/${
        paramsOverride.subfolder ? paramsOverride.subfolder + '/' : ''
        }${filename}`

    const params = {
      Bucket: paramsOverride.bucket || null,
      Key: filePath
    }

    return await S3.call('getObject', params)
  }
}

export default new Voices()
