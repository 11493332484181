import { takeLatest } from 'redux-saga/effects'

import { API_CALL_FAILURE } from 'Constants/ids'
import { inSocketTypes } from 'Constants/socketEventTypes'
import {
  SIGN_IN,
  REHYDRATE_TOKEN,
  UPDATE_TOKEN,
  LOG_OUT
} from 'Redux/actions/auth'

import onSignInSuccess from './OnSignInSuccess'
import onUnauthorizedError from './OnUnathorizedError'
import onTokenRehydrateSuccess from './onTokenRehydrateSuccess'
import onTokenUpdate from './onTokenUpdate'
import onLogOut from './onLogoutSuccess'
import onTrySocketAuth from './onTrySocketAuth'

export default function*() {
  yield takeLatest(LOG_OUT.REQUEST, onLogOut)
  yield takeLatest(SIGN_IN.SUCCESS, onSignInSuccess)
  yield takeLatest(API_CALL_FAILURE, onUnauthorizedError)
  yield takeLatest(REHYDRATE_TOKEN.SUCCESS, onTokenRehydrateSuccess)
  yield takeLatest(UPDATE_TOKEN.SUCCESS, onTokenUpdate)
  yield takeLatest(
    `socket/${inSocketTypes.Unauthorized.toUpperCase()}`,
    onTrySocketAuth
  )
}
