import _ from 'lodash'

import { createSelector } from 'reselect'

const getState = state => state.auth

export const getIsAuth = createSelector(
  getState,
  auth => _.has(auth, 'credentials.RefreshToken')
)

export const getCredentials = createSelector(
  getState,
  auth => auth.credentials
)

export const getIdToken = createSelector(
  getState,
  auth => auth.credentials.IdToken
)

export const getAccessToken = createSelector(
  getState,
  auth => _.get(auth, 'credentials.AccessToken', null)
)

export const getRefreshToken = createSelector(
  getState,
  auth => _.get(auth, 'credentials.RefreshToken', null)
)

export const getAuthError = createSelector(
  getState,
  auth => auth.errors
)

export const getCode = createSelector(
  getState,
  auth => auth.code
)

export const getFindHelpData = createSelector(
  getState,
  findHelp => findHelp
)
