import styled from 'styled-components'
import { Button } from 'Components/ui'
import { sizes } from 'Constants'

export const SelectBtn = styled(Button)`
  font-size: 14px;
  margin: 5px 0;
  padding: 0 5px;
  font-weight: bold;
  width: auto;
  min-width: 100px;
  &:first-child {
    margin-top: 25px;
  }
`

export const ArrowBlock = styled.div`
  display: flex;
  flex-direction: column;
`

export const TopArrow = styled.div`
  width: 0;
  height: 0;
  margin-bottom: 5px;
  border-left: 6.25px solid transparent;
  border-right: 6.25px solid transparent;
  border-bottom: 8.5px solid #555;
  border-radius: 2.5px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: ${sizes.phone}px) {
    margin-bottom: 15px;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: 17px solid #555;
  }
`

export const BottomArrow = styled(TopArrow)`
  margin-bottom: 0;
  border-bottom: 0;
  border-top: 8.5px solid #555;
  @media (max-width: ${sizes.phone}px) {
    margin-bottom: 0;
    border-top: 17px solid #555;
    border-bottom: 0;
  }
`

export const BuyExtraPoints = styled.div`
  padding: 15px;
  & > div {
    font-size: 12px;
  }
`

export const BuyExtraPointsBtn = styled(SelectBtn)`
  width: 100%;
  min-height: 35px;
  padding-right: 10px;
  padding-left: 10px;
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 40px 0;
`

export const Input = styled.input`
  font-size: 16px;
  text-align: center;
  border: none;
  outline: none;
  width: 100%;
  background-color: #fff;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`

export const TotalPoints = styled.div`
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.colors.lightGrayText};
  & > * {
    float: left;
  }
`

export const TotalPointsText = styled.div`
  margin-left: 5px;
`
