import styled from 'styled-components'
import { ProfileImage } from 'Components/blocks/ProfileImage'
import { sizes } from 'Constants'

export const Wrapper = styled.div`
  box-shadow: 0 0 5px silver;
  border-radius: 10px;
  position: relative;
  height: 80px;
  width: 100%;
  background-color: ${p => (p.isActive ? 'whitesmoke' : '#fff')};
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  transition: background-color 0.3s;
  &:hover {
    background-color: whitesmoke;
  }
  @media (max-width: ${sizes.tablet}px) {
    height: 60px;
  }
`

export const Avatar = styled(ProfileImage)`
  height: 50px;
  width: 50px;
  min-height: 50px;
  min-width: 50px;
  align-self: center;
  box-shadow: 0 0 5px silver;
  border-radius: 50%;
  @media (max-width: ${sizes.tablet}px) {
    height: 25px;
    width: 25px;
    min-height: 25px;
    min-width: 25px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  justify-content: center;
  padding: 0 10px;
`

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const JobTitle = styled.span`
  font-size: ${p => p.theme.fontSize.default}
  font-weight: bold;
  color: ${props =>
    props.isChatBlocked
      ? props.theme.colors.black
      : props.theme.colors.defaultBlue};
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  margin-bottom: 5px;
  @media (max-width: ${sizes.tablet}px) {
    font-size: 10px;
  }
`

export const Name = styled.span`
  font-size: ${p => p.theme.fontSize.default};
  font-weight: bold;
  color: ${props =>
    props.isChatBlocked
      ? props.theme.colors.black
      : props.theme.colors.defaultBlue};
  max-width: 100%;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  @media (max-width: ${sizes.tablet}px) {
    font-size: 10px;
  }
`

export const Time = styled.span`
  font-size: ${p => p.theme.fontSize.small};
  color: ${props =>
    props.isChatBlocked ? props.theme.colors.black : 'silver'};
  @media (max-width: ${sizes.tablet}px) {
    font-size: 10px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  } 
`

export const LastMessage = styled.span`
  margin-top: 5px;
  font-size: ${p => p.theme.fontSize.default};
  color: ${props =>
    props.isChatBlocked ? props.theme.colors.black : 'silver'};
  overflow-x: hidden;
  max-width: 180px;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (max-width: ${sizes.tablet}px) {
    font-size: 10px;
  }
`
export const IconActive = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.notification};
  top: 5px;
  right: 4px;
  @media (max-width: ${sizes.tablet}px) {
    width: 6px;
  height: 6px;
  }
`