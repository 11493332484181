import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import _ from 'lodash'
import InfiniteScroll from 'react-infinite-scroller'
import memoize from 'memoize-one'
import * as routes from 'Routes/routesConstants'

import { icons } from 'Assets/icons'

import {
  BreadCrumbs,
  Header,
  JobCard,
  RightSidebar,
  Ad,
  Footer,
  BurgerMenu,
  HeaderTabs,
  ProgressBar,
  ListAd,
  HelpCard,
  RequestSPDescription,
  JobView,
  ApplyBySection,
  FreelancerProfileView
} from 'Components/blocks'

import {
  PageWrapper,
  ContentWrapper,
  CardWrapper,
  RightSideBarWrapper,
  DownPart,
  PreloadImg,
  LoadMoreWrapper
} from 'Components/ui'

import { FooterAdWrapper, Wrapper } from './style'
import { TABS, path } from './config'
import {
  getSPListBC,
  getJobsBC,
  getJobDetailBC,
  breadSPProfileBC,
  getPeopleWhoAppliedBC,
  getAppliedProfileBC
} from './memoize'

class MyRequests extends PureComponent {
  state = {
    isRequest: true,
    isSubmitting: false,
    isRejecting: false,
    submittingId: undefined,
    isLoadMoreRequest: false,
    idCardFavouriteLoad: null
  }

  componentDidMount() {
    this.handleLoadJobsRequests()
  }

  handleLoadJobsRequests = () => {
    const { onLoadRequestsJobs } = this.props

    this.setState({ isRequest: true }, () => {
      onLoadRequestsJobs(() => {
        this.setState({ isRequest: false })
      })
    })
  }

  handleLoadSPRequests = () => {
    const { onLoadRequestsSP } = this.props

    this.setState({ isRequest: true }, () => {
      onLoadRequestsSP(() => {
        this.setState({ isRequest: false })
      })
    })
  }

  handleTabClick = id => {
    const { history } = this.props

    if (id === 'a') {
      this.handleLoadJobsRequests()
    } else {
      this.handleLoadSPRequests()
    }

    history.push(
      `${routes.myRequests}${
      id === 'a' ? routes.bookingRequests : routes.hiringRequests
      }`
    )
  }

  handleOpenFreelancerProfile = job => {
    const { history } = this.props
    history.push(
      `${routes.myRequests}${routes.hiringRequests}/${job.jobRequest._id}`
    )
  }

  handleOpenJobDescription = job => {
    const { history } = this.props

    history.push(
      `${routes.myRequests}${routes.bookingRequests}/${job.jobRequest._id}/${job._id}`
    )
  }

  handleAcceptRequest = job => {
    const { history, onAcceptRequest } = this.props
    this.setState({ isSubmitting: true })

    onAcceptRequest(job, () => {
      history.replace(routes.myRequests)
      this.setState({ isSubmitting: false })
    })
  }

  handleRejectRequest = job => {
    const { history, onRejectRequest } = this.props
    this.setState({ isRejecting: true })

    onRejectRequest(job, () => {
      history.replace(routes.myRequests)
      this.setState({ isRejecting: false })
    })
  }

  handleOpenPeopleWhoApplied = () => {
    const { history, location } = this.props

    history.push(`${location.pathname}${routes.appliedServiceProviders}`)
  }

  handleOpenAppliedProfile = profile => {
    const { history, location } = this.props

    history.push(
      `${location.pathname}${
      location.pathname.includes(routes.appliedServiceProviders)
        ? ''
        : routes.appliedServiceProviders
      }/${profile.username || profile.userId}`
    )
  }

  handleSendHiringRequest = () => {
    const { spProfile, onSendRequest } = this.props

    onSendRequest(spProfile)
  }

  loadMoreJobs = () => { }

  loadMoreSP = () => { }

  _renderJobs = props => {
    const {
      t,
      jobs,
      onShare,
      loadRequestId,
      onAcceptRequest,
      onRejectRequest,
      loadFavouriteJobId,
      onFavouriteClick
    } = this.props
    const { isRequest, isSubmitting, isRejecting, submittingId } = this.state

    const breadCrumbs = getJobsBC(t)
    return (
      <>
        <BreadCrumbs data={breadCrumbs} />
        <DownPart>
          <CardWrapper>
            <Ad />
            {isRequest && _.isEmpty(jobs.list) ? (
              <PreloadImg alt="loading" src={icons.preload} />
            ) : (
              <InfiniteScroll
                hasMore={false}
                loadMore={this.loadMoreJobs}
                loader={
                  <LoadMoreWrapper key={-1}>
                    <PreloadImg
                      alt="loading"
                      className="loader"
                      src={icons.preload}
                    />
                  </LoadMoreWrapper>
                }
                useWindow
              >
                {(jobs.list || []).map((item, key) => (
                  <Fragment key={key}>
                    <JobCard
                      {...item}
                      isSubmitting={isSubmitting && submittingId === item._id}
                      isRejecting={isRejecting && submittingId === item._id}
                      isLoadRequest={loadRequestId === item._id}
                      isLoadingFavourite={loadFavouriteJobId === item._id}
                      onAccept={memoize(() => {
                        this.setState({ isSubmitting: true, submittingId: item._id })
                        onAcceptRequest(item, () =>
                          this.setState({ isSubmitting: false, submittingId: item._id })
                        )
                      })}
                      onClick={memoize(() =>
                        this.handleOpenJobDescription(item)
                      )}
                      onHandleFavouriteClick={memoize(() =>
                        onFavouriteClick(item)
                      )}
                      onReject={memoize(() => {
                        this.setState({ isRejecting: true, submittingId: item._id })
                        onRejectRequest(item, () =>
                          this.setState({ isRejecting: false, submittingId: undefined })
                        )
                      })}
                      onShareClick={memoize(() => onShare(item))}
                    />
                    <ListAd data={jobs.list} index={key} />
                  </Fragment>
                ))}
              </InfiniteScroll>
            )}
            <FooterAdWrapper>
              <Ad />
            </FooterAdWrapper>
          </CardWrapper>
          <RightSideBarWrapper>
            <RightSidebar />
          </RightSideBarWrapper>
        </DownPart>
      </>
    )
  }

  _renderSP = props => {
    const { t, sp, onShare, onFavouriteSPClick, loadFavouriteSPId } = this.props
    const { isRequest } = this.state

    const breadCrumbs = getSPListBC(t)

    return (
      <>
        <BreadCrumbs data={breadCrumbs} />
        <DownPart>
          <CardWrapper>
            <Ad />
            {isRequest && _.isEmpty(sp.list) ? (
              <PreloadImg alt="loading" src={icons.preload} />
            ) : (
                <InfiniteScroll
                  hasMore={false}
                  loadMore={this.loadMoreSP}
                  loader={
                    <LoadMoreWrapper key={-1}>
                      <PreloadImg
                        alt="loading"
                        className="loader"
                        src={icons.preload}
                      />
                    </LoadMoreWrapper>
                  }
                  useWindow
                >
                  {sp.list.map((item, key) => (
                    <Fragment key={key}>
                      <HelpCard
                        data={item.doer}
                        isLoadingFavourite={loadFavouriteSPId === item.doer._id}
                        onClick={memoize(() =>
                          this.handleOpenFreelancerProfile(item)
                        )}
                        onHandleFavouriteClick={memoize(() =>
                          onFavouriteSPClick(item.doer)
                        )}
                        onShareClick={memoize(() => onShare(item.doer))}
                      />
                      <ListAd data={sp.list} index={key} />
                    </Fragment>
                  ))}
                </InfiniteScroll>
              )}
            <FooterAdWrapper>
              <Ad />
            </FooterAdWrapper>
          </CardWrapper>
          <RightSideBarWrapper>
            <RightSidebar />
          </RightSideBarWrapper>
        </DownPart>
      </>
    )
  }

  _renderContent = () => {
    return (
      <ContentWrapper>
        <Switch>
          <Route exact path={path.bookingRequests} render={this._renderJobs} />
          <Route exact path={path.hiringRequests} render={this._renderSP} />
          <Redirect to={path.bookingRequests} />
        </Switch>
      </ContentWrapper>
    )
  }

  renderRequestSPDetail = props => {
    const { t, spProfile = {} } = this.props

    const breadCrumbs = breadSPProfileBC(
      t,
      `${spProfile.given_name || ''} ${spProfile.family_name || ''}`
    )

    return (
      <ContentWrapper>
        <BreadCrumbs data={breadCrumbs} />
        <RequestSPDescription {...props} />
      </ContentWrapper>
    )
  }

  renderRequestJobDetail = props => {
    const { t, job, jobs } = this.props
    const { isSubmitting, isRejecting } = this.state

    const breadCrumbs = getJobDetailBC(t, _.get(job, 'title'))

    const jobId = _.get(props, 'match.params.jobId')

    const data = jobs.list.find(el => el._id === jobId)

    return (
      <Switch>
        <Route
          exact
          path={path.peopleWhoApplied}
          render={this.renderPeopleWhoApplied}
        />
        <Route
          exact
          path={path.appliedProfile}
          render={this.renderAppliedProfile}
        />
        <Route
          render={() => (
            <ContentWrapper>
              <BreadCrumbs data={breadCrumbs} />
              <Wrapper>
                <JobView
                  isRejecting={isRejecting}
                  isSubmitting={isSubmitting}
                  request={data.jobRequest}
                  onAcceptRequest={memoize(() =>
                    this.handleAcceptRequest(data)
                  )}
                  onOpenApplyByProfile={this.handleOpenAppliedProfile}
                  onOpenPeopleWhoApplied={this.handleOpenPeopleWhoApplied}
                  onRejectRequest={memoize(() =>
                    this.handleRejectRequest(data)
                  )}
                />
              </Wrapper>
            </ContentWrapper>
          )}
        />
      </Switch>
    )
  }

  renderPeopleWhoApplied = props => {
    const { t, job } = this.props

    const { params } = props.match

    const breadCrumbs = getPeopleWhoAppliedBC(
      t,
      params.jobId,
      _.get(job, 'title'),
      params.requestId
    )

    return (
      <ContentWrapper>
        <BreadCrumbs data={breadCrumbs} />
        <ApplyBySection
          {...props}
          onClickProfile={this.handleOpenAppliedProfile}
        />
      </ContentWrapper>
    )
  }

  renderAppliedProfile = props => {
    const { t, job, spProfile } = this.props

    const { params } = props.match

    const breadCrumbs = getAppliedProfileBC(
      t,
      params.jobId,
      _.get(job, 'title'),
      spProfile,
      params.requestId
    )

    return (
      <ContentWrapper>
        <BreadCrumbs data={breadCrumbs} />
        <Wrapper>
          <FreelancerProfileView
            {...props}
            onHire={this.handleSendHiringRequest}
          />
        </Wrapper>
      </ContentWrapper>
    )
  }

  render() {
    const { user, location } = this.props
    const { isRequest } = this.state

    return (
      <PageWrapper>
        <ProgressBar percent={isRequest ? 1 : 100} />
        {user && <BurgerMenu user={user} />}
        <Header postJob={false} view={Object.keys(user).length === 0} />
        <HeaderTabs
          activeTabIndex={
            location.pathname.includes(routes.bookingRequests) ? 'a' : 'b'
          }
          options={TABS}
          onClickLink={this.handleTabClick}
        />

        <Switch>
          <Route
            exact
            path={path.requestSPDetail}
            render={this.renderRequestSPDetail}
          />
          <Route
            path={path.requestJobDetail}
            render={this.renderRequestJobDetail}
          />
          <Route render={this._renderContent} />
        </Switch>
        <Footer black={true} isAuth={user} />
      </PageWrapper>
    )
  }
}

MyRequests.propTypes = {
  jobs: PropTypes.shape({
    list: PropTypes.array,
    isListEnd: PropTypes.bool
  }),
  loadFavouriteJobId: PropTypes.string,
  loadFavouriteSPId: PropTypes.string,
  loadRequestId: PropTypes.string,
  location: PropTypes.object,
  sp: PropTypes.shape({
    list: PropTypes.array,
    isListEnd: PropTypes.bool
  }),
  spProfile: PropTypes.object,
  t: PropTypes.func,
  user: PropTypes.object.isRequired,
  onAcceptRequest: PropTypes.func,
  onAddToFavourites: PropTypes.func,
  onFavouriteClick: PropTypes.func,
  onFavouriteSPClick: PropTypes.func,
  onLoadRequestsJobs: PropTypes.func,
  onLoadRequestsSP: PropTypes.func,
  onRejectRequest: PropTypes.func,
  onRemoveFromFavourites: PropTypes.func,
  onSendRequest: PropTypes.func,
  onShare: PropTypes.func
}

export default MyRequests
