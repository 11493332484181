import { createSelector, createStructuredSelector } from 'reselect'

const getState = state => state.categories

export const getCategories = createSelector(
  getState,
  categories => categories
)

export const getFullLibraryData = createStructuredSelector({
  getCategories
})
