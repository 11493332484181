import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments } from '@fortawesome/free-solid-svg-icons'

import { chats } from 'Routes/routesConstants'
import { SvgDiv, Active } from './styles'

export const Messages = ({ isActive }) => (
  <Link to={chats}>
    <SvgDiv>
      <FontAwesomeIcon icon={faComments} />
      {isActive && <Active />}
    </SvgDiv>
  </Link>
)
