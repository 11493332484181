import React from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'

import { OutsideClick } from 'Components/blocks'
import { SortOption } from 'Components/ui'
import {
  SortWrapper,
  Title,
  Container,
  Text,
  TextWrapper,
  Popup,
  List,
  SubmitBtn
} from './styles'
import { getOrderByName, getText } from './config'

class Sort extends React.Component {
  state = {
    isVisible: false,
    order: true,
    orderBy: 'distance',
    value: 'desc'
  }

  handleChange = (order, value) => this.setState({ order, value })

  handleSetActive = (orderBy, initialValue) =>
    this.setState({
      orderBy: orderBy.value,
      value: initialValue || 'desc',
      order: true
    })

  handleShow = () => this.setState({ isVisible: true })

  handleHide = () => this.setState({ isVisible: false })

  handleSubmit = () => {
    const { onSelect } = this.props
    const { orderBy, value } = this.state

    onSelect({ order: orderBy === 'expiryDate' ? (value === 'desc' ? 'asc' : 'desc') : value, orderBy })
    this.handleHide()
  }

  renderOptions = memoize((data, order, orderBy, t) =>
    data.map((el, index) => (
      <SortOption
        isActive={el.value === orderBy}
        isChecked={order}
        key={index}
        orderText={getText(t, order ? el.turnOnText : el.turnOffText)}
        title={t(el.text)}
        value={el.value}
        onChangeSwitch={bool =>
          this.handleChange(
            bool,
            bool ? el.activeValue || 'desc' : el.disabledValue || 'asc'
          )
        }
        onClickTitle={() => this.handleSetActive(el, el.activeValue)}
      />
    ))
  )

  render() {
    const { t, data } = this.props
    const { isVisible, orderBy, order } = this.state

    return (
      <SortWrapper>
        <Title>{t('sorting')}</Title>
        <Container>
          <TextWrapper onClick={this.handleShow}>
            <Text>{t(getOrderByName(orderBy))}</Text>
          </TextWrapper>
          <Popup
            isVisible={isVisible}
            isWidthSmall={this.props.lng === 'en' || this.props.lng === 'de'}
          >
            <OutsideClick onOutsideClick={this.handleHide}>
              <List>{this.renderOptions(data, order, orderBy, t)}</List>
              <SubmitBtn onClick={this.handleSubmit}>
                {t('rating:submit')}
              </SubmitBtn>
            </OutsideClick>
          </Popup>
        </Container>
      </SortWrapper>
    )
  }
}

Sort.propTypes = {
  data: PropTypes.array
}

Sort.defaultProps = {
  data: [],
  onSelect: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default Sort
