import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'
import _ from 'lodash'
import { icons } from 'Assets/icons'
import { getMicrolinkObg } from 'Helpers/microlink'
import { toast } from 'react-toastify'

import { VideoCover, PreloadImg } from 'Components/ui'

import {
  CardWrapper,
  DeleteButton,
  CardContainer,
  CardTitle,
  CardDescription,
  TextBlock,
  CardLink,
  VideoContainer,
  PlayButton,
  ErrorIcon,
  PreloadImgContainer,
  ErrorLink
} from './styles'

class URLPreview extends PureComponent {
  state = {
    isRequest: true,
    video: null,
    audio: null,
    poster: null,
    title: '',
    description: '',
    link: '',
    isPlay: false,
    error: false,
    isWebsite: false
  }
  mounted = false

  async componentDidMount() {
    this.mounted = true
    await this._getVideoUrl()
  }

  componentWillUnmount() {
    this.mounted = false
  }

  handleDeleteClick = () => {
    const { handleDelete, url } = this.props

    handleDelete(url)
  }

  handleClickPlayer = isPlay => this.setState({ isPlay })

  handlePlayButtonClick = () => {
    this.setState({ isPlay: true }, this.player.setPlay)
  }

  _getVideoUrl = async () => {
    const { url, isShowErrorMessage, t } = this.props

    const result = await getMicrolinkObg({ url })

    if (!_.isObject(result.data.video) || !_.isObject(result.data.audio)) {
      this.mounted && this.setState({
        isRequest: false,
        poster: _.get(result, 'data.logo.url', null),
        title: _.get(result, 'data.title', ''),
        description: _.get(result, 'data.description', ''),
        link: _.get(result, 'data.url', url),
        isWebsite: true
      })
      return
    }

    if (result.error) {
      const linkObg = await getMicrolinkObg({ url, video: false, audio: false })

      if (isShowErrorMessage) toast.warn(t('videoUploadError'))

      this.mounted && this.setState({
        isRequest: false,
        poster: _.get(linkObg, 'data.image.url', null),
        title: _.get(linkObg, 'data.title', ''),
        description: _.get(linkObg, 'data.description', ''),
        link: _.get(linkObg, 'data.url', url),
        error: true,
        isWebsite: false
      })

      if (linkObg.error) this.handleDeleteClick()
    } else {
      this.mounted && this.setState({
        isRequest: false,
        video: _.get(result, 'data.video.url', null),
        audio: _.get(result, 'data.audio.url', null),
        poster: _.get(result, 'data.image.url', null),
        title: _.get(result, 'data.title', ''),
        description: _.get(result, 'data.description', ''),
        link: _.get(result, 'data.url', url),
        error: false,
        isWebsite: false
      })
    }
  }

  _renderCard = () => {
    const { url, disabled } = this.props
    const {
      isRequest,
      video,
      description,
      link,
      title,
      isPlay,
      poster,
      error,
      isWebsite
    } = this.state

    return (
      <CardContainer>
        {isRequest ? (
          <PreloadImgContainer>
            <PreloadImg mb={0} mt={0} src={icons.preload} width="100%" />
          </PreloadImgContainer>
        ) : (
          <>
            <VideoContainer>
              <VideoCover
                disabled={disabled || isWebsite}
                poster={poster}
                ref={ref => (this.player = ref)}
                videoUrl={video}
                onClick={this.handleClickPlayer}
              />
              {isPlay || disabled || error || isWebsite ? null : (
                <PlayButton onClick={this.handlePlayButtonClick} />
              )}
              {error && !isWebsite ? (
                <ErrorLink href={link || url} target="_blank">
                  <ErrorIcon />
                </ErrorLink>
              ) : null}
            </VideoContainer>
            <TextBlock href={link || url} target="_blank">
              <CardTitle>{title}</CardTitle>
              {_.isString(description) && !_.isEmpty(description) ? (
                <CardDescription>
                  {description
                    .split(' ')
                    .slice(0, 3)
                    .join(' ')}
                  ...
                </CardDescription>
              ) : null}
              <CardLink>{link || url}</CardLink>
            </TextBlock>
          </>
        )}
      </CardContainer>
    )
  }

  render() {
    const { handleDelete } = this.props

    return (
      <CardWrapper>
        {this._renderCard()}
        {_.isFunction(handleDelete) ? (
          <DeleteButton onClick={this.handleDeleteClick}>
            <img alt="" src={icons.closeWhite} />
          </DeleteButton>
        ) : null}
      </CardWrapper>
    )
  }
}

URLPreview.propTypes = {
  disabled: PropTypes.bool,
  handleDelete: PropTypes.func,
  isShowErrorMessage: PropTypes.bool,
  t: PropTypes.func,
  url: PropTypes.string
}

export default withNamespaces(['apiErrors'])(URLPreview)
