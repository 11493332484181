import React from 'react'
import styled from 'styled-components'
import { Button } from 'Components/ui'
import { fontFamily, fontSize } from 'Constants'
import RCSlider from 'rc-slider'

export const Content = styled.div`
  width: 50%;
  margin: 0 auto 40px;

  font-family: ${fontFamily};
`

export const ContentAvatar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 15px;
  & * {
    margin: auto;
  }
`
export const RaitingTitle = styled.div`
  margin: auto;
  font-size: ${fontSize.h1};
  font-family: ${fontFamily};
  font-weight: 600;
  margin-bottom: 15px;
`
export const SubTitleAvatar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  font-weight: 600;
`

export const TitleAvatarName = styled.div`
  padding-right: 5px;
`

export const SliderWrapper = styled.div`
  margin: 50px 0;
  &:last-child {
    cursor: pointer;
  }
`

export const StarIcon = styled.span`
  font-weight: bold;
  margin-right: 3px;
`

export const RateSliderText = styled.span``

export const Star = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
  top: -25px;
  color: ${p => (p.isActive ? p.theme.colors.black : p.theme.colors.premiumBg)};
`

export const SliderSection = styled.div`
  width: 20%;
  height: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${p => p.color};
  &:first-child {
    border-radius: 15px 0 0 15px;
  }
  &:last-child {
    border-radius: 0 15px 15px 0;
  }
`

export const FormikTitle = styled.div`
  margin: 15px 0;
  font-size: ${fontSize.h3};
  font-family: ${fontFamily};
  font-weight: bold;
  text-align: center;
  color: grey;
`

export const RewiewInput = styled.textarea`
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  height: 300px;
  margin-bottom: 50px;
  font-size: ${fontSize.h3};
  resize: none;
  &::placeholder {
    color: grey;
  }
`

export const SubmitButton = styled(props => <Button {...props} />)`
  position: static;
  width: 100%;
  margin-bottom: 50px;
  z-index: 10;
  ${p => p.disabled && 'pointer-events: none; opacity: 0.6;'};
`

const sliderHeaderStyle = {
  borderColor: 'blue',
  height: 28,
  width: 28,
  marginLeft: -14,
  marginTop: -9,
  backgroundColor: 'blue'
}

const sliderTrack = { height: 10, background: 'none' }

export const Slider = styled(RCSlider).attrs(() => ({
  handleStyle: sliderHeaderStyle,
  min: 0,
  max: 5,
  railStyle: sliderTrack,
  step: 1,
  trackStyle: sliderTrack
}))`
  display: flex;
`
SubmitButton.displayName = 'SubmitButton'