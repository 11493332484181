import React, { Component } from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'
import _ from 'lodash'
import { withNamespaces } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import * as routes from 'Routes/routesConstants'
import { filteredServices } from 'Helpers/services'
import { getCurrentService } from 'Helpers/services'

import ServicesAutocomplete from '../ServicesAutocomplete'
import icon from 'Assets/search'
import { options } from './config'
import {
  SearchWrap,
  SelectWrap,
  SearchInput,
  ButtonSearch,
  Icon,
  Select,
  Option,
  InputWrapper
} from './styles'

class Search extends Component {
  constructor(props) {
    super(props)

    const {
      location,
      services: { services }
    } = props

    const state = {
      isJob: location.pathname === routes.findJobs,
      isFocused: false,
      value: '',
      items: []
    }

    const filter = qs.parse(location.search.slice(1))
    if (_.isString(filter.keywords)) state.value = filter.keywords
    if (_.isString(filter.service)) {
      const service = getCurrentService(services, filter.service)
      if (service) state.value = service.title
    }

    this.state = state
  }

  handleChange = ({ target: { value } }) =>
    this.setState({ isJob: value === 'findJobs' })

  handleFocus = () => this.setState({ isFocused: true, value: '' })

  handleBlur = () => {
    setTimeout(() => {
      this.setState({ isFocused: false })
    }, 100)
  }

  handleChangeText = ({ target: { value } }) => {
    const {
      services: { services },
      t
    } = this.props
    this.setState({ value })

    _.debounce(() => {
      this.setState({
        items: filteredServices(services, this.state.value, title =>
          t(`services.${title}`)
        ).slice(0, 10)
      })
    }, 300)()
  }

  handleSelectService = service => {
    const { history, onSearchHelp, onSearchJob } = this.props
    const { isJob } = this.state

    this.setState({ value: service.title }, () => {
      if (_.isFunction(onSearchHelp) && !isJob) {
        onSearchHelp({ service: service._id })
        return
      }
      if (_.isFunction(onSearchJob) && isJob) {
        onSearchJob({ service: service._id })
        return
      }

      const url = isJob ? routes.findJobs : routes.findHelp

      history.push(`${url}?service=${service._id}`)
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    const { isJob } = this.state
    const { history, onSearchHelp, onSearchJob } = this.props
    const {
      target: {
        search: { value }
      }
    } = event

    const url = isJob ? routes.findJobs : routes.findHelp
    if (_.isFunction(onSearchHelp) && !isJob) {
      onSearchHelp({ keywords: value })
      return
    }
    if (_.isFunction(onSearchJob) && isJob) {
      onSearchJob({ keywords: value })
      return
    }

    history.push(`${url}?keywords=${value}`)
  }

  render() {
    const { isWider, t } = this.props
    const { isJob, value, items, isFocused } = this.state

    const wider = _.isBoolean(isWider)
    const optionList = options.map(option => (
      <Option key={option.id} value={option.value}>
        {t(option.value)}
      </Option>
    ))

    return (
      <SearchWrap isWider={wider} onSubmit={this.handleSubmit}>
        <SelectWrap className="search">
          <Select
            value={isJob ? 'findJobs' : 'findHelp'}
            onChange={this.handleChange}
          >
            {optionList}
          </Select>
          <Icon src={icon.arrow} />
        </SelectWrap>
        <InputWrapper>
          <SearchInput
            autoComplete="off"
            name="search"
            placeholder={t('searchHere')}
            type="text"
            value={value}
            onBlur={this.handleBlur}
            onChange={this.handleChangeText}
            onFocus={this.handleFocus}
          />
          <ButtonSearch />
          <ServicesAutocomplete
            data={items}
            visible={isFocused && Boolean(value) && !_.isEmpty(items)}
            onClick={this.handleSelectService}
          />
        </InputWrapper>
      </SearchWrap>
    )
  }
}

Search.propTypes = {
  history: PropTypes.object,
  services: PropTypes.object,
  value: PropTypes.string,
  onSearchHelp: PropTypes.func,
  onSearchJob: PropTypes.func
}

export default compose(
  withRouter,
  withNamespaces(['homePage', 'services'])
)(Search)

export { Search }