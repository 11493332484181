import React from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'
import { toast } from 'react-toastify'
import { isResolveTracking } from 'Helpers/tracking'
import * as routes from 'Routes/routesConstants'
import { jobStatuses } from 'Constants/jobStatuses'

import { connect } from 'react-redux'
import { getProfile } from 'Redux/selectors/user'
import { createStructuredSelector } from 'reselect'

export function withTracking(Component) {
  return ({ t, history, user, ...props }) => {
    const handleStartTracking = (job, geoLocation) => {
      if (
        job.jobStatus === jobStatuses.completed ||
        job.jobStatus === jobStatuses.done
      ) {
        toast.error(t('apiErrors:TrackingAfterJobIsCompleted'))
        return
      }

      if (isResolveTracking(job, user)) {
        history.push(`${routes.trackNow}/${job._id}/${job.doer}`)
      } else {
        toast.warn(t('jobTrack:TrackingMessageError'))
        history.push(`${routes.trackNow}`)
        return
      }
      if(!geoLocation[job._id] && isResolveTracking(job, user) && job.author === user._id) {
        toast.warn(t('LeftoverOnes:TrackingNotShowingWhenAvailable'))
        return
      }
      
    }

    return <Component {...props} onStartTracking={handleStartTracking} />
  }
}

const selectors = createStructuredSelector({
  user: getProfile
})

export default compose(
  withRouter,
  withNamespaces(['notifications', 'jobTrack']),
  connect(selectors),
  withTracking
)
