import React from 'react'
import { find, orderBy, uniqueId, isFunction } from 'lodash'
import { withNamespaces } from 'react-i18next'

import PHONES from 'Constants/phonesMobile'

import {
  CodeList,
  PhoneLi,
  PhoneCodeImg,
  CountryName,
  PhoneCode,
  EmptyFlag
} from './styles'

class Component extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      phones: [...PHONES],
      isSelectedCode: true,
      selectedCode: find(PHONES, country => country.phone === this.props.code) || PHONES[0]
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleSetCode = (item, setFieldValue) => {
    const newArrCountryCodes = orderBy(
      PHONES,
      lang => lang.code === item.code,
      'desc'
    )

    setFieldValue('code', item.phone)
    setFieldValue('local', item.code.slice(0, 2))
    this.setState({
      selectedCode: item,
      phones: newArrCountryCodes
    })
    isFunction(this.props.handleChange) && this.props.handleChange()
  }

  handleCodeListClick = () => {
    this.setState({
      isSelectedCode: !this.state.isSelectedCode
    })
  }

  handleCountryCode = setFieldValue => {
    return this.state.phones.map(item => {
      return (
        <PhoneLi
          key={uniqueId('id_')}
          value={item.code}
          onClick={() => this.handleSetCode(item, setFieldValue)}
        >
          <PhoneCodeImg alt="flag" src={item.flag} />
          <CountryName>{this.props.t(item.country)}</CountryName>
          <PhoneCode>{item.phone}</PhoneCode>
        </PhoneLi>
      )
    })
  }

  setWrapperRef = node => {
    this.wrapperRef = node
  }

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      !this.state.isSelectedCode &&
        this.setState({ isSelectedCode: !this.state.isSelectedCode })
    }
  }

  render() {
    const {
      setFieldValue,
      value,
      handleBlur,
      handleChange,
      isProfile,
      t
    } = this.props
    const { isSelectedCode, selectedCode } = this.state

    const selectedCountryCode = isSelectedCode && (
      <PhoneLi value={selectedCode.code}>
        {selectedCode.flag ? (
          <PhoneCodeImg alt="flag" src={selectedCode.flag} />
        ) : (
          <EmptyFlag />
        )}
        {this.props.isProfile && (
          <CountryName>{t(selectedCode.country)}</CountryName>
        )}
        <PhoneCode>{selectedCode.phone}</PhoneCode>
      </PhoneLi>
    )

    return (
      <CodeList
        className={`${!isSelectedCode ? 'open' : ''}`}
        isProfile={isProfile}
        name="code"
        ref={this.setWrapperRef}
        select={!this.state.isSelectedCode}
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
        onClick={this.handleCodeListClick}
        
      >
        {!isSelectedCode ? this.handleCountryCode(setFieldValue) : null}
        {selectedCountryCode}
      </CodeList>
    )
  }
}

export const PhoneListMobile = withNamespaces('phoneCodes')(Component)
