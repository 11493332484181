import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'

import { BreadCrumbs } from 'Components/blocks'

import { ContentWrapper,PreloadImg } from 'Components/ui'

import { Container } from './style'

import { getBreadCrumbs } from './memoize'

import Switch from 'react-switch'
import icons from 'Assets/icons'
//import sampleImage from '../../../../../Assets/ad/sample.png'

const table = {
  fontFamily: 'arial, sans-serif',
  borderCollapse: 'collapse',
  width: '100%'
}

const tableBody = {
  border:' 1px solid #dddddd',
  textAlign: 'center',
  padding: '8px',
 
}

const tableBodyAction ={
  border:' 1px solid #dddddd',
  textAlign: 'center',
  width:'100px',
  padding: '0px'
}

const tableHeader = {
  border:' 1px solid #dddddd',
  textAlign: 'center',
  padding: '8px',
  backgroundColor: '#e6e6e6',
  fontWeight: 'bold'
}


const editIcon = {
  margin: '0px',
  width: '20px',
  padding:'0px'
}

// const adImage = {
//   width: 100,
//   height: 20
// }

class CurrentAds extends Component {
  constructor(props) {
    super(props)
    this.state = { checked: false };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeOne = this.handleChangeOne.bind(this);
    this.handleChangeTwo = this.handleChangeTwo.bind(this);
    this.handleChangeThree = this.handleChangeThree.bind(this);
  }
  
  handleChange(checked) {
    this.setState({ checked });
  }

  handleChangeOne(checkedOne) {
    this.setState({ checkedOne });
  }
  handleChangeTwo(checkedTwo) {
    this.setState({ checkedTwo });
  }
  handleChangeThree(checkedThree) {
    this.setState({ checkedThree });
  }


  render() {
    const { t } = this.props
    const breadCrumbs = getBreadCrumbs(t)
    
    return (
      <>
        <BreadCrumbs data={breadCrumbs} />
        <ContentWrapper>
          <Container>
            {/* {t('postAdsTest')} */}
            <table  style={table}>
                <tr >
                  <th style={tableHeader}>{t('on-off-key')}</th>
                  <th style={tableHeader}>{t('ad')}</th>
                  <th style={tableHeader}>{t('ad-id')}</th>
                  <th style={tableHeader}>{t('campaign-title')}</th>
                  <th style={tableHeader}>{t('status')}</th>
                  <th style={tableHeader}>{t('address')}</th>
                  <th style={tableHeader}>{t('radius')}</th>
                  <th style={tableHeader}>{t('str-date')}</th>
                  <th style={tableHeader}>{t('end-date')}</th>
                  <th style={tableHeader}>{t('num-of-week')}</th>
                  <th style={tableHeader}>{t('total-cost')}</th>
                  <th style={tableHeader}>{t('action')}</th>
                </tr>
                <tr >
                  <td style={tableBody}>
                    <Switch onChange={this.handleChange}
                            checked={this.state.checked}
                            checkedIcon={null}
                            uncheckedIcon={null}
                            className="react-switch"
                            id="normal-switch" 
                            height={20}
                            width={40}  />
                  </td>
                  <td style={tableBody}>Image</td>
                  <td style={tableBody}>A23Cgn53</td>
                  <td style={tableBody}>Home painting</td>
                  <td style={tableBody}>Active</td>
                  <td style={tableBody}>288 Alma st, Dallas ...</td>
                  <td style={tableBody}>10</td>
                  <td style={tableBody}>Feb 12,2021</td>
                  <td style={tableBody}>Feb 19,2021</td>
                  <td style={tableBody}>1</td>
                  <td style={tableBody}>$2.00</td>
                  <td style={tableBodyAction} >
                  <PreloadImg
                      alt="edit"
                      style={editIcon}
                      src={icons.edit}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={tableBody}>
                  <Switch onChange={this.handleChangeOne}
                            checked={this.state.checkedOne}
                            checkedIcon={null}
                            uncheckedIcon={null}
                            className="react-switch"
                            id="normal-switch1" 
                            height={20}
                            width={40}  />
                  </td>
                  <td style={tableBody}>Image</td>
                  <td style={tableBody}>43436g</td>
                  <td style={tableBody}>Dancing class</td>
                  <td style={tableBody}>Inactive</td>
                  <td style={tableBody}>78 Peni ln, Allen TX...</td>
                  <td style={tableBody}>20</td>
                  <td style={tableBody}>Jan 01,2021</td>
                  <td style={tableBody}>Jan 15,2021</td>
                  <td style={tableBody}>2</td>
                  <td style={tableBody}>$7.50</td>
                  <td style={tableBodyAction}>
                  <PreloadImg
                      alt="edit"
                      style={editIcon}
                      src={icons.edit}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={tableBody}>
                  <Switch onChange={this.handleChangeTwo}
                            checked={this.state.checkedTwo}
                            checkedIcon={null}
                            uncheckedIcon={null}
                            className="react-switch"
                            id="normal-switch" 
                            height={20}
                            width={40}  />
                  </td>
                  <td style={tableBody}>Image</td>
                  <td style={tableBody}>Dg3568</td>
                  <td style={tableBody}>Looking for a vet</td>
                  <td style={tableBody}>In review</td>
                  <td style={tableBody}>288 Alma st, Dallas ...</td>
                  <td style={tableBody}>10</td>
                  <td style={tableBody}>Feb 12,2021</td>
                  <td style={tableBody}>Feb 19,2021</td>
                  <td style={tableBody}>1</td>
                  <td style={tableBody}>$5.00</td>
                  <td style={tableBodyAction}>
                  <PreloadImg
                      alt="edit"
                      style={editIcon}
                      src={icons.edit}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={tableBody}>
                  <Switch onChange={this.handleChangeThree}
                            checked={this.state.checkedThree}
                            checkedIcon={null}
                            uncheckedIcon={null}
                            className="react-switch"
                            id="normal-switch" 
                            height={20}
                            width={40}  />
                  </td>
                  <td style={tableBody}>Image</td>
                  <td style={tableBody}>56D23</td>
                  <td style={tableBody}>Adult Service</td>
                  <td style={tableBody}>Rejected</td>
                  <td style={tableBody}>288 Alma st, Dallas ...</td>
                  <td style={tableBody}>50</td>
                  <td style={tableBody}>Jan 01,2021</td>
                  <td style={tableBody}>Jan 15,2021</td>
                  <td style={tableBody}>2</td>
                  <td style={tableBody}>$100.00</td>
                  <td style={tableBodyAction}>
                  <PreloadImg
                      alt="edit"
                      style={editIcon}
                      src={icons.edit}
                    />
                  </td>
                </tr>
               
              </table>

              {/* <DataTable columns={columns}
                         customStyles={customStyles}
                         data={data}
                        //  selectableRows 
                         /> */}
                       
          </Container>
        </ContentWrapper>
      </>
    )
  }
}

export default withNamespaces('postAds')(CurrentAds)
