import React, { useState } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import { isEmpty, isObject, isFunction, isString } from 'lodash'
import moment from 'moment'
import { jobStatuses } from 'Constants/jobStatuses'
import * as routes from 'Routes/routesConstants'
import noImg from 'Assets/dummyImage/no_img.png'
import image from 'Helpers/jobImage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Link } from 'react-router-dom'
import { icons } from 'Assets/icons'
import { Button, RoundIcon, Premium, JobPopup } from 'Components/ui'
import {
  JobCardWrapper,
  Delete,
  TitleWrapper,
  BodyWrapper,
  DescriptionWrapper,
  LeftGroup,
  FooterWrapper,
  DistanceWrapper,
  IconGroup,
  DateGroup,
  Span,
  EditDeleteContainer,
  RoundIconsContainer,
  CanceledButton,
  ButtonWrapper,
  AcceptButton,
  ApplyButton,
  RejectButton,
  DateText,
  Description,
  Budget
} from './styles'

const JobCard = ({
  t,
  jobStatus,
  title,
  isPremium,
  pics,
  description,
  distance,
  budget,
  _id,
  startDate,
  jobRequest,
  author,
  doneBefore,
  expiryDate,
  onHandleFavouriteClick,
  onHandleDeleteClick,
  isLoadingFavourite,
  favouriteId,
  onHandleApply,
  onReject,
  onAccept,
  applicationId,
  onEditClick,
  onClick,
  location,
  onShareClick,
  onCancelClick,
  user,
  onSelect,
  path,
  isSubmitting,
  isRejecting,
  onTrackClick,
  dateDoerCancel,
  currency,
  budgetMyCountry,
  currencyMyCountry,
  authorStartJob,
  doerStartJob,
  dateAuthorCancel
}) => {
  const [requesting, setRequesting] = useState(false)
  const isRequestPage = isObject(jobRequest) && !isEmpty(jobRequest)
  const isMyPostedJobs =
    location.pathname === `${routes.myJobs}${routes.posted}`
  const isMyAppliedJobs =
    location.pathname === `${routes.myJobs}${routes.applied}`
  const isMyBookedJobs =
    location.pathname === `${routes.myJobs}${routes.booked}`
  const isMyJob = user.username === author || user.username === author?.username

  const _renderEditButton = () => {
    if (
      ((isMyJob && dateAuthorCancel && moment(dateAuthorCancel).isValid()) ||
        (!isMyJob &&
          dateDoerCancel &&
          moment(dateDoerCancel).isValid() &&
          jobStatus !== jobStatuses.completed)) &&
      authorStartJob &&
      doerStartJob
    )
      return null

    if (
      ((isMyAppliedJobs && jobStatus !== jobStatuses.done) ||
        (isMyBookedJobs && jobStatus === jobStatuses.booked) ||
        (jobStatus === jobStatuses.completed &&
          authorStartJob &&
          doerStartJob)) &&
      isFunction(onCancelClick)
    ) {
      return (
        <CanceledButton onClick={onCancelClick}>
          {t('LeftoverOnes:cancelJob')}
        </CanceledButton>
      )
    }

    if (
      isMyPostedJobs &&
      isFunction(onHandleDeleteClick) &&
      isFunction(onEditClick)
    ) {
      return (
        <EditDeleteContainer>
          <JobPopup
            onClickDelete={memoize(() => onHandleDeleteClick(_id))}
            onClickEdit={onEditClick}
          />
        </EditDeleteContainer>
      )
    }

    if (isFunction(onHandleDeleteClick)) {
      return <Delete onClick={memoize(() => onHandleDeleteClick(_id))} />
    }
  }

  const _renderFavouriteIcon = () => {
    if (
      isMyPostedJobs ||
      isMyBookedJobs ||
      isMyAppliedJobs ||
      user.username === author ||
      !isFunction(onHandleFavouriteClick) ||
      isFunction(onTrackClick)
    )
      return null

    return (
      <RoundIcon
        favourite={favouriteId}
        icon={favouriteId ? ['fas', 'heart'] : ['far', 'heart']}
        id={_id}
        loading={isLoadingFavourite}
        onClick={onHandleFavouriteClick}
      />
    )
  }

  const _renderAcceptRejectButton = () => (
    <ButtonWrapper>
      <RejectButton onClick={onReject} disabled={isRejecting}>
        {isRejecting ? (
          <img alt="" src={icons.threeDotsLoader} />
        ) : (
          t('serviceProvider:reject')
        )}
      </RejectButton>
      <AcceptButton onClick={onAccept} disabled={isSubmitting}>
        {isSubmitting ? (
          <img alt="" src={icons.threeDotsLoader} />
        ) : (
          t('serviceProvider:accept')
        )}
      </AcceptButton>
    </ButtonWrapper>
  )

  const _renderApplyButton = () => {
    if (
      isString(applicationId) ||
      isMyPostedJobs ||
      isMyBookedJobs ||
      isMyAppliedJobs ||
      !isFunction(onHandleApply) ||
      isMyJob ||
      isFunction(onTrackClick)
    )
      return null

    return (
      <ApplyButton onClick={_onHandleApply} disabled={requesting}>
        {requesting ? <img alt="" src={icons.threeDotsLoader} /> : t('apply')}
      </ApplyButton>
    )
  }

  const _onHandleApply = () => {
    setRequesting(true)
    onHandleApply(() => setRequesting(false))
  }

  const _renderSelectButton = () => {
    if (isFunction(onSelect)) {
      return <Button onClick={onSelect}>{t('LeftoverOnes:selectJob')}</Button>
    }
  }

  const _renderTrackButton = () => {
    if (isFunction(onTrackClick)) {
      return (
        <Button onClick={onTrackClick}>
          {t('landingPage:trackNow').toUpperCase()}
        </Button>
      )
    }
  }

  return (
    <JobCardWrapper>
      <TitleWrapper>
        {_renderEditButton()}
        <LeftGroup isPremium={isPremium}>
          {isFunction(onClick) ? (
            <Span onClick={onClick}>{title}</Span>
          ) : (
            <Link to={path || `${location.pathname}/${_id}`}>
              {title && <Span>{title}</Span>}
            </Link>
          )}
          {isPremium && <Premium />}
        </LeftGroup>
        {isRequestPage ? (
          <IconGroup>
            <RoundIconsContainer>
              {!isEmpty(user) && _renderFavouriteIcon()}
              {isFunction(onShareClick) && (
                <RoundIcon icon="paper-plane" onClick={onShareClick} />
              )}
            </RoundIconsContainer>
          </IconGroup>
        ) : (
          <>
            {_renderSelectButton()}
            {_renderTrackButton()}
            {!isEmpty(user) && _renderApplyButton()}
          </>
        )}
      </TitleWrapper>
      <BodyWrapper>
        {isFunction(onClick) ? (
          <div>
            <img
              alt={title}
              src={pics && pics.length !== 0 ? image(_id, pics[0]) : noImg}
              onClick={onClick}
            />
          </div>
        ) : (
          <Link to={`${location.pathname}/${_id}`}>
            <img
              alt={title}
              src={pics && pics.length !== 0 ? image(_id, pics[0]) : noImg}
            />
          </Link>
        )}
        <DescriptionWrapper>
          {description && <Description>{description}</Description>}
          <IconGroup>
            {expiryDate && (
              <DateGroup>
                <FontAwesomeIcon icon="calendar-alt" />
                <DateText>
                  {t(startDate ? 'myJobsDetail:apptOn' : 'before')}{' '}
                  {moment(startDate || expiryDate).format('lll')}
                </DateText>
              </DateGroup>
            )}
            {isRequestPage ? (
              _renderAcceptRejectButton()
            ) : (
              <RoundIconsContainer>
                {!isEmpty(user) && _renderFavouriteIcon()}
                {isFunction(onShareClick) && (
                  <RoundIcon icon="paper-plane" onClick={onShareClick} />
                )}
              </RoundIconsContainer>
            )}
          </IconGroup>
        </DescriptionWrapper>
      </BodyWrapper>

      <FooterWrapper>
        {distance && (
          <DistanceWrapper>
            <FontAwesomeIcon icon="map-marker-alt" />{' '}
            {distance.length.toFixed().toString().length < 4
              ? distance.length
              : distance.length.toFixed()}{' '}
            {t(`jobDetail:${distance.units}`)}
          </DistanceWrapper>
        )}
        <Budget>
          {budgetMyCountry &&
          currencyMyCountry &&
          currency !== currencyMyCountry
            ? `${t(
                'budget'
              )}: ${budgetMyCountry} ${currencyMyCountry}/ ${budget} ${currency}`
            : `${t('budget')}: ${budget} ${currency}`}
        </Budget>
      </FooterWrapper>
    </JobCardWrapper>
  )
}

JobCard.propTypes = {
  _id: PropTypes.string.isRequired,
  applicationId: PropTypes.string,
  budget: PropTypes.number,
  description: PropTypes.any,
  distance: PropTypes.any,
  doneBefore: PropTypes.any,
  editable: PropTypes.bool,
  expiryDate: PropTypes.any,
  favouriteId: PropTypes.string,
  img: PropTypes.string,
  isLoadingFavourite: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isRejecting: PropTypes.bool,
  isPremium: PropTypes.bool,
  location: PropTypes.object,
  path: PropTypes.string,
  pics: PropTypes.array,
  shortDescription: PropTypes.string,
  startDate: PropTypes.any,
  t: PropTypes.func,
  title: PropTypes.string,
  user: PropTypes.object,
  onAccept: PropTypes.func,
  onCancelClick: PropTypes.func,
  onClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onHandleApply: PropTypes.func,
  onHandleDeleteClick: PropTypes.func,
  onHandleFavouriteClick: PropTypes.func,
  onReject: PropTypes.func,
  onSelect: PropTypes.func,
  onShareClick: PropTypes.func,
  onTrackClick: PropTypes.func
}

export default JobCard
