import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from 'Redux/actions/api'

export const BUY_PLAN = createAsyncAction('subscription/BUY_PLAN')
export const buyPlan = (plan, callback) =>
  apiCall({
    endpoint: '/subscription/buy',
    types: BUY_PLAN,
    method: 'POST',
    query: {
      paymentMethod: 'points',
      subscriptionType: plan
    },
    callback
  })

export const BUY_POINTS = createAsyncAction('subscription/BUY_POINTS')
export const buyPoints = (
  { paymentMethod = 'paypal', amount, paymentId },
  callback
) =>
  apiCall({
    endpoint: '/pts/buy',
    types: BUY_POINTS,
    method: 'POST',
    qsParams: {
      redirect_uri: window.location.href
    },
    query: {
      paymentMethod,
      amount: Number(amount)
    },
    callback
  })

export const ADS_VIEWED = createAsyncAction('subscription/ADS_VIEWED')
export const viewedAds = (planValue, callback) =>
  apiCall({
    endpoint: '/pts/ad-seen',
    types: ADS_VIEWED,
    method: 'POST',
    query: {
      amount: planValue
    },
    callback
  })

export const LOAD_PLANS = createAsyncAction('subscriptions/LOAD_PLANS')
export const loadSubscriptionPlans = callback =>
  apiCall({
    endpoint: '/subscription/price',
    types: LOAD_PLANS,
    method: 'GET',
    callback
  })

export const LOAD_PTS_COST = createAsyncAction('subscription/LOAD_PTS_COST')
export const loadPtsCost = callback =>
  apiCall({
    endpoint: '/pts/price',
    types: LOAD_PTS_COST,
    method: 'GET',
    callback
  })
