import styled from 'styled-components'
import { fontFamily, fontSize } from 'Constants'

export const Container = styled.div`
  width: 272px;
  display: flex;
  flex-direction: column;
  position: relative;
`

export const InputContainer = styled.input`
  width: 100%;
  margin: 0 auto 5px;
  border: 2px solid #ececec;
  min-height: 46px;
  border-radius: 5px;
  outline: none;
  padding: 10px 15px;
  font-size: ${fontSize.h5};
  font-family: ${fontFamily};
  background-image: none !important;
  &:focus {
    border-color: #a7c0f4;
  }
  &:-webkit-autofill {
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0 1000px #5da6ff inset;
  }
`

export const BottomLabel = styled.div`
  text-align: left;
  font-size: ${fontSize.small};
  font-family: ${fontFamily};
`

export const Label = styled.label`
  text-align: left;
  font-size: ${fontSize.default};
  font-family: ${fontFamily};
  margin-bottom: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Error = styled.span`
  padding-top: 3px;
  color: #ff3e75;
  font-size: ${fontSize.small};
  font-family: ${fontFamily};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  bottom: -18px;
`
