import style from 'Assets/lang'

const LANGUAGES = {
  en: {
    type: 'English',
    flag: style.flagUs,
    code: 'en',
    phone: '+1',
    id: 1
  },
  fr: {
    type: 'French',
    flag: style.flagFr,
    code: 'fr',
    phone: '+33',
    id: 2
  },
  es: {
    type: 'Spanish',
    flag: style.flagSp,
    code: 'es',
    phone: '+34',
    id: 15
  },
  it: {
    type: 'Italian',
    flag: style.flagIt,
    code: 'it',
    phone: '+39',
    id: 12
  },
  pt: {
    type: 'Portuguese',
    flag: style.flagPg,
    code: 'pt',
    phone: '+351',
    id: 13
  },
  de: {
    type: 'German',
    flag: style.flagDe,
    code: 'de',
    phone: '+49',
    id: 3
  },
  ru: {
    type: 'Russian',
    flag: style.flagRu,
    code: 'ru',
    phone: '+38',
    id: 14
  },
  hi: {
    type: 'Hindi',
    flag: style.flagIn,
    code: 'hi',
    phone: '+91',
    id: 4
  },
  bn: {
    type: 'Bangla',
    flag: style.flagIn,
    code: 'bn',
    phone: '+91',
    id: 5
  },
  te: {
    type: 'Telugu',
    flag: style.flagIn,
    code: 'te',
    phone: '+91',
    id: 6
  },
  mr: {
    type: 'Marathi',
    flag: style.flagIn,
    code: 'mr',
    id: 10
  },
  ta: {
    type: 'Tamil',
    flag: style.flagIn,
    code: 'ta',
    id: 11
  },
  gu: {
    type: 'Gujarati',
    flag: style.flagIn,
    code: 'gu',
    phone: '+91',
    id: 7
  },
  kn: {
    type: 'Kannada',
    flag: style.flagIn,
    code: 'kn',
    phone: '+91',
    id: 8
  },
  ml: {
    type: 'Malayalam',
    flag: style.flagIn,
    code: 'ml',
    phone: '+91',
    id: 9
  }
}

export default LANGUAGES
