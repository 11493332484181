import React, { useState } from 'react'
import PersonalDetails from './PersonalDetails'
import AddressDetails from './AddressDetails'
import PriceDetails from './PriceDetails'
import CategoryDetails from './CategoryDetails'
import { Container } from './style'
import ValidatorConfig from './formStateConfig'
import { Button } from './style'

const PRICE = 2
const CANCELLATION_FEE = 3.45
const previousState = JSON.parse(JSON.stringify(ValidatorConfig))

const Layout = props => {
  const [validatorConfig, setValidatorConfig] = useState(ValidatorConfig)
  const [submitError, setSubmitError] = useState('')
  const [radius, setRadius] = useState(10);

  const onHandleChange = (event, isValid) => {
    const _validatorConfig = { ...validatorConfig }
    _validatorConfig[event.target.name].value = event.target.value
    _validatorConfig[event.target.name].isValid = isValid

    if (
      event.target.name === 'noOfWeek' ||
      event.target.name === 'cancelationFee' ||
      event.target.name === 'radius'
    ) {
      const cancelationFee = _validatorConfig['cancelationFee'].value
      const radius = _validatorConfig['radius'].value
      const total = _validatorConfig['total']

      total.value = (
        (radius / 10) * PRICE +
        (cancelationFee ? CANCELLATION_FEE : 0)
      ).toFixed(2)
      total.isValid = true
    }

    setValidatorConfig({ ..._validatorConfig })
  }

  const isFormValid = () => {
    const isValid = !Object.keys(validatorConfig).find(config => {
      return !validatorConfig[config].isValid
    })

    return isValid
  }

  const handleSubmit = () => {
    setSubmitError('')
    if (isFormValid()) {
      const result = {}
      Object.keys(validatorConfig).forEach(config => {
        result[config] = validatorConfig[config].value
      })
      console.log('json for form submission', result)
    } else {
      setSubmitError('Please fill all the required fields, marked with (*)')
    }
  }
  const onCancelForm = () => {
    setValidatorConfig({ ...previousState })
  }

   const { t } = props
   
  return (
    <div>
      
      <Container>
          <PersonalDetails
            {...props}
            validatorConfig={validatorConfig}
            onHandleChange={onHandleChange}
          />
          <AddressDetails
            validatorConfig={validatorConfig}
            onHandleChange={onHandleChange}
            radius={radius}
            {...props}
          />
          <PriceDetails
            validatorConfig={validatorConfig}
            onHandleChange={onHandleChange}
            radius={radius}
            onRadiusChange={setRadius}
            {...props}
          />
          <CategoryDetails
            validatorConfig={validatorConfig}
            onHandleChange={onHandleChange}
            {...props}
          />
          {submitError && <p style={{ color: 'red' }}>{submitError}</p>}
      </Container>
      <Container colorDisabled>
        <Button type="button" style={{ background: 'linear-gradient(-75deg,#b7b3b3,#888787)' }} onClick={onCancelForm}>
          {t('cancel')}
        </Button>
        <Button type="submit" submit onClick={handleSubmit}>
           {t('postad')}
        </Button>
      </Container>
    </div>
  )
}

export default Layout
