import { createReducer } from 'Helpers/redux'
import { inSocketTypes } from 'Constants/socketEventTypes'
import { jobStatuses } from 'Constants/jobStatuses'

import { LOAD_TRACK_JOBS } from 'Redux/actions/track'

const initialState = {
  data: [],
  geolocation: {}
}

const handlers = {
  [LOAD_TRACK_JOBS.SUCCESS]: (state, { payload }) => ({
    ...state,
    data: payload.list.filter(el => el.jobStatus === jobStatuses.booked)
  }),
  [`socket/${inSocketTypes.spTrackUpdated.toUpperCase()}`]: (
    state,
    { payload }
  ) => {
    // let geolocation = null
    // try {
    //   geolocation = JSON.parse(payload.geolocation)
    // } catch (err) {
    //   geolocation = state.geolocation
    // }

    return {
      ...state,
      geolocation: {
        ...state.geolocation,
        [payload.jobId]: JSON.parse(payload.geolocation)
      }
    }
  }
}

export default createReducer(initialState, handlers)
