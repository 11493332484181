import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withNamespaces } from 'react-i18next'
import { icons } from 'Assets/icons'
import { FACEBOOK, TWITTER, INSTAGRAM, LINKEDIN } from 'Constants/socials'

import { InformationModal } from 'Components/blocks'

import { footerNav, getText } from './config'
import {
  FooterSection,
  FooterList,
  FooterSubTitle,
  Registered,
  FooterTitle,
  FooterGrid,
  FooterLi,
  FooterCopyright,
  FooterSocial,
  FooterSocialIcon,
  FooterLine,
  FooterGridSocial,
  FooterWrap,
  FooterWrapNav,
  FooterLink,
  FooterLogo
} from './styles'

class FooterComponent extends PureComponent {
  state = {
    infoModalTextKey: null
  }

  handleSetInfoModalKey = key => this.setState({ infoModalTextKey: key })

  handleCloseInfoModal = () => this.setState({ infoModalTextKey: null })

  renderNav = () => {
    const { t, isAuth, black } = this.props
    const state = _.isEmpty(isAuth)
    const themeStyle = state ? 'unauth' : 'auth'
    const footerArr = footerNav.filter(x => x.tags.indexOf(themeStyle) > -1)
    const result = _.chunk(footerArr, 5)

    return result.map(i => {
      const col = i.map(item => (
        <FooterLi key={item.id} state={state}>
          {item.textKey ? (
            <span onClick={() => this.handleSetInfoModalKey(item.textKey)}>
              {t(item.trans)}
            </span>
          ) : (
            <FooterLink
              as={item.textKey ? 'span' : undefined}
              state={+black}
              to={item.href}
            >
              {t(item.trans)}
            </FooterLink>
          )}
        </FooterLi>
      ))
      return <FooterList key={Math.random()}>{col}</FooterList>
    })
  }

  render() {
    const { t, black } = this.props
    const { infoModalTextKey } = this.state

    return (
      <FooterSection state={black}>
        <FooterWrapNav>
          <FooterGrid>
            <FooterLogo>
              <FooterTitle state={black}>
                SpotJobs<Registered>&reg;</Registered>
              </FooterTitle>
              <FooterSubTitle state={black}>
                {t('sign:subTitle')}
              </FooterSubTitle>
            </FooterLogo>
            {this.renderNav()}
          </FooterGrid>
        </FooterWrapNav>

        <FooterLine />

        <FooterWrap>
          <FooterGridSocial>
            <FooterCopyright state={black}>
              <span>
                {t('copyright')} <span>&#169;</span> 2020 <b>Atomx llc. </b>
                {t('rightsReserved')}
              </span>
            </FooterCopyright>

            <FooterSocial>
              <FooterSocialIcon state={black}>
                <a href={FACEBOOK} rel="noopener noreferrer" target="_blank">
                  <img alt="facebook" src={icons.facebook} />
                </a>
              </FooterSocialIcon>
              <FooterSocialIcon state={black}>
                <a href={TWITTER} rel="noopener noreferrer" target="_blank">
                  <img alt="twitter" src={icons.twitter} />
                </a>
              </FooterSocialIcon>
              <FooterSocialIcon state={black}>
                <a href={INSTAGRAM} rel="noopener noreferrer" target="_blank">
                  <img alt="instagram" src={icons.instagram} />
                </a>
              </FooterSocialIcon>
              <FooterSocialIcon state={black}>
                <a href={LINKEDIN} rel="noopener noreferrer" target="_blank">
                  <img alt="linkedin" src={icons.linkedin} />
                </a>
              </FooterSocialIcon>
            </FooterSocial>
          </FooterGridSocial>
        </FooterWrap>
        <InformationModal
          isShow={Boolean(infoModalTextKey)}
          width="auto"
          onRequestClose={this.handleCloseInfoModal}
        >
          {getText(t, infoModalTextKey)}
        </InformationModal>
      </FooterSection>
    )
  }
}

FooterComponent.propTypes = {
  t: PropTypes.func
}
export const Footer = withNamespaces([
  'landingPage',
  'common',
  'findJobPage',
  'homePage',
  'sign'
])(FooterComponent)
