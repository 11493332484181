import { createSelector, createStructuredSelector } from 'reselect'

const getState = state => state.services

export const getPopularServices = createSelector(
  getState,
  data => data.popularServices
)

export const getFullLibraryData = createStructuredSelector({
  getPopularServices
})
