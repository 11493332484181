import styled from 'styled-components'
import { getThemeField } from 'Theme'
import { sizes } from 'Constants'

/* Profile
/* ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## */

export const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: ${props => (props.active ? 'auto' : 'none')};
  opacity: ${props => (props.active ? '1' : '0.5')};
`

export const AvatarImg = styled.div`
  & > img {
    width: 30px;
    height: 30px;
    background-color: ${getThemeField('colors.avatarBackground')};
    border-radius: 50%;
    color: ${getThemeField('palette.white')};
    object-fit: cover;
    @media (max-width: ${sizes.tablet}px) {
      width: 50px;
      height: 50px;
    }
  }
`

export const ProfileDropdownMenu = styled.div`
  margin-right: 10px;
  font-weight: 600;
  color: ${props => props.theme.colors.defaultFont};
  max-width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`
