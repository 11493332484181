import { Component } from 'react'
import _ from 'lodash'

class SuccessfulPayment extends Component {
  componentDidMount() {
    const { match } = this.props

    const paymentId = _.get(match, 'params.paymentId')

    if (paymentId) {
      localStorage.setItem('paymentId', paymentId)
    }
    window.close()
  }

  render() {
    return null
  }
}

SuccessfulPayment.propTypes = {}

export default SuccessfulPayment
