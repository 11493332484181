import memoize from 'memoize-one'
import {
  myJobs,
  applied,
  appliedServiceProviders
} from 'Routes/routesConstants'

export const getBreadCrumbs = memoize(t => [
  {
    displayName: t('homePage:myJobs')
  },
  {
    displayName: t('homePage:appliedJobs')
  }
])

export const getDescriptionBreadCrumbs = memoize((t, jobTitle) => [
  {
    displayName: t('homePage:myJobs'),
    url: myJobs
  },
  {
    displayName: t('homePage:appliedJobs'),
    url: `${myJobs}${applied}`
  },
  {
    displayName: jobTitle
  }
])

export const getPeopleWhoAppliedBreadCrumbs = memoize((t, jobTitle, jobId) => [
  {
    displayName: t('homePage:myJobs'),
    url: myJobs
  },
  {
    displayName: t('homePage:applied'),
    url: `${myJobs}${applied}`
  },
  {
    displayName: jobTitle,
    url: `${myJobs}${applied}/${jobId}`
  },
  {
    displayName: t('appServiceProvider:totalServiceProviders')
  }
])

export const getAppliedProfileBC = memoize((t, jobTitle, jobId, username) => [
  {
    displayName: t('homePage:myJobs'),
    url: myJobs
  },
  {
    displayName: t('homePage:postedJobs'),
    url: `${myJobs}${applied}`
  },
  {
    displayName: jobTitle,
    url: `${myJobs}${applied}/${jobId}`
  },
  {
    displayName: t('appServiceProvider:totalServiceProviders'),
    url: `${myJobs}${applied}/${jobId}${appliedServiceProviders}`
  },
  {
    displayName: username
  }
])
