import styled from 'styled-components'
import { fontFamily, sizes, fontSize } from 'Constants'

export const Wrapper = styled.div`
  box-shadow: ${props => props.theme.colors.boxShadow};
  background: ${props => props.theme.colors.componentsBackground};
  display: flex;
  padding: 5px 15px 10px;
  margin: 20px 0;
  flex-direction: column;
  font-size: ${fontSize.h4};
  font-family: ${fontFamily};
  font-weight: 800;
  @media only screen and (max-width: ${sizes.desktop}px) {
    align-items: center;
  }
  @media only screen and (max-width: ${sizes.tablet}px) {
    align-items: center;
  }
`

export const AvatarsWrapper = styled.div`
  display: flex;
  margin-top: 5px;
`

export const Avatar = styled.div`
  width: 60px;
  & > a > img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    box-shadow: ${props => props.theme.colors.boxShadow};
    padding: 3px;
    background: white;
    position: relative;
    z-index: ${props => props.index};
    object-fit: cover;
  }
  &:hover {
    & > a > img {
      z-index: 10;
    }
  }
`

export const ViewAll = styled.div`
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.defaultBlue}
  border-radius: 50%;
  color: white;
  word-wrap: break-word;
  font-weight: 600;
  padding: 0 10px;
  text-align: center;
  cursor: pointer;
  font-size: ${fontSize.default};
  font-family: ${fontFamily};
  &:hover {
      z-index: 10;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Title = styled.h1`
  font-size: ${p => p.theme.fontSize.h4};
`
