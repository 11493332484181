import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Wrapper, Label, InputWrapper, Input, SearchIcon } from './style'

class SearchInput extends PureComponent {
  state = {
    // value: ''
  }

  handleChange = ({ target: { value } }) => {
    const { searchChange } = this.props
    searchChange(value)
  }

  handleSubmit = event => {
    event.preventDefault()
    // const { onSubmit } = this.props
    // const { value } = this.state

    // onSubmit(value || event)
  }

  render() {
    const { label, id, placeholder, ...props } = this.props
    return (
      <Wrapper onSubmit={this.handleSubmit}>
        {label && <Label htmlFor={id}>{label}</Label>}
        <InputWrapper>
          <Input
            id={id}
            placeholder={placeholder || null}
            onChange={this.handleChange}
            {...props}
          />
          <SearchIcon onClick={this.handleSubmit} />
        </InputWrapper>
      </Wrapper>
    )
  }
}

SearchInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  searchChange: PropTypes.func,
  onSubmit: PropTypes.func
}

export { SearchInput }
