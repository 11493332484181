import { createSelector, createStructuredSelector } from 'reselect'

const getState = state => state.services

export const getServices = createSelector(
  getState,
  services => services
)

export const getFullLibraryData = createStructuredSelector({
  getServices
})
