import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'
import { withNamespaces } from 'react-i18next'

import { getWithdrawalHistories } from 'Redux/actions/paymentsTransactions'

import { makeSelectWithdrawalHistories } from 'Redux/selectors/paymentsTransactions'

import Component from './Withdrawals'

const selector = createStructuredSelector({
  withdrawalHistories: makeSelectWithdrawalHistories
})

const actions = {
  onGetWithdrawalHistories: getWithdrawalHistories
}

export default compose(
  withNamespaces('profilePage'),
  connect(selector, actions)
)(Component)
