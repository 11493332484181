import React from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'

import { MainTitle } from 'Components/ui'
import { ContainersWrapper, GridThreeElements, WidthLimiter } from './styles'

import Item from '../Item'

const ExplanationBlock = props => {
  const { t, items } = props
  return (
    <ContainersWrapper>
      <WidthLimiter className="explanation">
        <MainTitle>{t('howItWorks')}</MainTitle>
        <GridThreeElements>
          {items.map((item, i) => (
            <Item howItWorks key={i} noImage {...item} />
          ))}
        </GridThreeElements>
      </WidthLimiter>
    </ContainersWrapper>
  )
}

ExplanationBlock.propTypes = {
  items: PropTypes.array,
  t: PropTypes.func
}
export default withNamespaces('landingPage')(ExplanationBlock)
