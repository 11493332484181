import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'

import { ServicesAction, DropDownWrapper, Item } from './styles'

class DropDown extends Component {
  state = {
    isShow: false
  }

  toggleDropdown = () => {
    this.setState(prevState => ({
      isShow: !prevState.isShow
    }))
  }

  render() {
    const { isShow } = this.state
    const { t, onDelete, onEdit, id } = this.props
    return (
      <ServicesAction onClick={() => this.toggleDropdown()}>
        ...
        {isShow && (
          <DropDownWrapper>
            <Item onClick={() => onEdit(id)}>{t('edit')}</Item>
            <Item onClick={() => onDelete(id)}>{t('delete')}</Item>
          </DropDownWrapper>
        )}
      </ServicesAction>
    )
  }
}

export default withNamespaces('common')(DropDown)
