import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withNamespaces } from 'react-i18next'
import { getServiceImageUrl } from 'Helpers/services'

import {
  Container,
  Title,
  Row,
  Image,
  SubContainer,
  SubServiceRow,
  SubServiceTitle
} from './style'

const ServicesAutocomplete = ({ visible, className, data, t, onClick }) => (
  <Container className={className} isShow={visible}>
    {data.map(el => (
      <Row key={el._id} onClick={() => onClick(el)}>
        <Image src={getServiceImageUrl(el.iconName)} />
        <Title>{t(el.title)}</Title>
        {_.isEmpty(el.subservices) ? null : (
          <SubContainer>
            {el.subservices.map(item => (
              <SubServiceRow
                key={item._id}
                onClick={e => {
                  e.stopPropagation()
                  onClick(item)
                }}
              >
                <Image src={getServiceImageUrl(item.iconName)} />
                <SubServiceTitle>{t(item.title)}</SubServiceTitle>
              </SubServiceRow>
            ))}
          </SubContainer>
        )}
      </Row>
    ))}
  </Container>
)

ServicesAutocomplete.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  t: PropTypes.func,
  visible: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}

export default withNamespaces('services')(ServicesAutocomplete)
