import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { nearestSP } from 'Redux/actions/serviceProviders'
import { popularServices } from 'Redux/actions/services'

import { getNearestSP } from 'Redux/selectors/nearestSP'
import { getPopularServices } from 'Redux/selectors/popularServices'

import Component from './RightSidebar'

const actions = {
  onLoadPopularCategories: popularServices,
  onLoadServiceProviders: nearestSP
}

const selectors = createStructuredSelector({
  popularCategories: getPopularServices,
  serviceProviders: getNearestSP
})

export default connect(
  selectors,
  actions
)(Component)
