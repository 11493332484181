import React from 'react'
import { Wrapper, Icon, Overlay } from './styles'

import { icons } from 'Assets/icons'

export const Verified = () => {
  return (
    <Wrapper>
      <Overlay />
      <Icon src={icons.idVerified} alt="" />
    </Wrapper>
  )
}
