import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import _ from 'lodash'

import { Modal } from 'Components/ui'
import { JobCard } from 'Components/blocks'
import {
  Container,
  Header,
  Title,
  Content,
  CloseButton,
  ListEmptyText
} from './style'
import { getIsAllJobsHired } from './memoize'

class SelectJobModal extends PureComponent {
  state = {
    isRequest: false
  }

  componentDidMount() {
    this.handleLoadJobs()
  }

  getJobsCard = memoize((jobs, doer, disabledJobs) =>
    jobs.map(el => {
      const isDisabled = (el.requests || []).some(el => el.doer === doer)
      const isApplied = disabledJobs.indexOf(el._id) !== -1

      return (
        <JobCard
          {...el}
          isSelectModal={true}
          key={el._id}
          onSelect={
            isDisabled || isApplied ? undefined : () => this.handleSelectJob(el)
          }
        />
      )
    })
  )

  handleSelectJob = job => {
    const { onSelect } = this.props

    onSelect(job)
  }

  handleLoadJobs = () => {
    const { onLoadJobs } = this.props

    onLoadJobs({}, () => {
      this.setState({ isRequest: false })
    })
  }

  render() {
    const { t, isShow, onCloseModal, jobs, profile, disabledJobs } = this.props

    const username = _.get(profile, 'username')

    const data = this.getJobsCard(jobs.data, username, disabledJobs)

    const isShowMessage = getIsAllJobsHired(jobs.data, disabledJobs, username)

    return (
      <Modal isShow={isShow} width="600px" onRequestClose={onCloseModal}>
        <Container>
          <Header>
            <Title>{t('selectJob')}</Title>
            <CloseButton onClick={onCloseModal} />
          </Header>
          <Content>
            {_.isEmpty(jobs.data) ? (
              <ListEmptyText>{t('apiErrors:selectJobListEmpty')}</ListEmptyText>
            ) : (
              <>
                {isShowMessage && (
                  <ListEmptyText>
                    {t('apiErrors:selectJobListEmpty')}
                  </ListEmptyText>
                )}
                {data}
              </>
            )}
          </Content>
        </Container>
      </Modal>
    )
  }
}

SelectJobModal.propTypes = {
  disabledJobs: PropTypes.array,
  isShow: PropTypes.bool,
  jobs: PropTypes.object,
  profile: PropTypes.object,
  t: PropTypes.func,
  onCloseModal: PropTypes.func,
  onLoadJobs: PropTypes.func,
  onSelect: PropTypes.func
}

export default SelectJobModal
