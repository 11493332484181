import styled from 'styled-components'

export const Container = styled.div`
  & > .react-progress-bar.react-progress-bar-on-top {
    height: ${p => (p.isDisabled ? '100%' : 'auto')};
  }
  &
    > .react-progress-bar.react-progress-bar-on-top
    > .react-progress-bar-percent {
    background: #fff;
    box-shadow: 0 0 10px ${p => p.theme.colors.header};
  }
`
