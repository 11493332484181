import React from 'react'
import PropTypes from 'prop-types'
import { omit, isString } from 'lodash'
// import { toast } from 'react-toastify'

import { withNamespaces } from 'react-i18next'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { sendRequest } from 'Redux/actions/requests'

import { ConfirmModal, SelectJobModal } from 'Components/blocks'

function withSendHiringRequest(Component) {
  return class extends React.Component {
    static propTypes = {
      onSendRequest: PropTypes.func
    }

    state = {
      job: null,
      profile: null,
      isRequest: false,
      disabledJobs: []
    }

    profile = null

    handleOpenJobModal = profile => {
      // const { t } = this.props

      // if (!profile?.paypal_account_withdraw) {
      //   return toast.error(t('jobDetail:EmployerHiringRequestTip'))
      // }
      this.setState({ profile })
    }

    handleCloseJobModal = () => {
      this.setState({ profile: null, job: null, disabledJobs: [] })
      this.profile = null
    }

    handleSelectJob = job => {
      const { profile } = this.state

      this.profile = profile
      this.setState({ job, profile: null })
    }

    handleCloseConfirmModal = () => {
      this.setState({ job: null, profile: this.profile })
      this.profile = null
    }

    handleSubmit = () => {
      const { sendHiringRequest } = this.props
      const { job } = this.state

      const request = {
        userId: isString(this.profile) ? this.profile : this.profile.username,
        jobId: job._id
      }

      this.setState({ isRequest: true })

      sendHiringRequest(request, err => {
        if (err) {
          this.setState(prevState => ({
            isRequest: false,
            job: null,
            profile: this.profile,
            disabledJobs: [...prevState.disabledJobs, job._id]
          }))
          this.profile = null
        } else {
          this.setState({ isRequest: false, job: null, profile: null })
          this.profile = null
        }
      })
    }

    render() {
      const { t } = this.props
      const { isRequest, job, profile, disabledJobs } = this.state
      return (
        <>
          <Component
            {...omit(this.props, ['sendHiringRequest'])}
            onSendRequest={this.handleOpenJobModal}
          />
          <SelectJobModal
            disabledJobs={disabledJobs}
            isShow={Boolean(profile)}
            profile={profile}
            onCloseModal={this.handleCloseJobModal}
            onSelect={this.handleSelectJob}
          />
          <ConfirmModal
            isLoading={isRequest}
            isVisible={Boolean(job) && Boolean(this.profile)}
            submitText={t('sign:submit')}
            title={t('hiringRequests')}
            onCancel={this.handleCloseConfirmModal}
            onSubmit={this.handleSubmit}
          />
        </>
      )
    }
  }
}

const actions = {
  sendHiringRequest: sendRequest
}

export default compose(
  connect(null, actions),
  withNamespaces(['homePage', 'sign']),
  withSendHiringRequest
)
