import Ads from './ads.json'
import Res from './restrictedAds.json'


export default {
  campaignTitle: {
    placeholder: 'Campaign Title',
    isRequired: true,
    isValid: false,
    regex: null,
    errorMessage: '',
    requiredMessage: "Campaign Title is Required",
    value: null,
    name: 'campaignTitle',
    disabled: false
  },

  firstName: {
    placeholder: 'First Name',
    isRequired: true,
    isValid: false,
    regex: null,
    errorMessage: '',
    requiredMessage: "First Name is Required",
    value: null,
    name: 'firstName',
    disabled: false
  },
  lastName: {
    placeholder: 'Last Name',
    isRequired: true,
    isValid: false,
    regex: null,
    errorMessage: '',
    requiredMessage: "Last Name is Required",
    value: null,
    name: 'lastName',
    disabled: false
  },
  adforbussiness: {
    placeholder: 'Select',
    isValid: false,
    value: '',
    name: 'adforbussiness',
    option: [
      { id: 1, name: 'Yes' },
      { id: 2, name: 'No' }
    ],
    requiredMessage: 'Please select one option at least',
    disabled: false
  },
  addNotes: {
    placeholder: 'Add Notes',
    isRequired: false,
    isValid: true,
    regex: null,
    errorMessage: '',
    requiredMessage: '',
    value: null,
    name: 'addNotes',
    disabled: false
  },
  business: {
    placeholder: 'Business Name',
    isRequired: false,
    isValid: false,
    regex: null,
    errorMessage: '',
    value: null,
    name: 'business',
    disabled: false
  },
  email: {
    placeholder: 'Email ',
    isRequired: true,
    isValid: false,
    regex: '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}',
    errorMessage: 'Please Provide a Valid Email Address',
    requiredMessage: 'Email  is Required',
    value: null,
    name: 'email',
    disabled: false
  },
  select_country: {
    placeholder: 'Select Country',
    requiredMessage: 'Country is Required',
    isValid: true,
    value: 'us',
    name: 'select_country'
  },
  country: {
    isRequired: false,
    isValid: true,
    regex: null,
    errorMessage: '',
    requiredMessage: 'Please Select at least one option',
    value: null,
    name: 'country',
    disabled: true
  },
  city: {
    isRequired: false,
    isValid: true,
    regex: null,
    errorMessage: '',
    requiredMessage: '',
    value: null,
    name: 'city',
    disabled: true
  },
  postal_code: {
    isRequired: false,
    isValid: true,
    regex: null,
    errorMessage: '',
    requiredMessage: '',
    value: null,
    name: 'postal_code',
    disabled: true
  },
  state: {
    isRequired: false,
    isValid: true,
    regex: null,
    errorMessage: '',
    requiredMessage: '',
    value: null,
    name: 'state',
    disabled: true
  },
  mobile: {
    placeholder: 'Mobile Number ',
    isRequired: true,
    isValid: false,
    regex: '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$',
    errorMessage: 'Please Provide a Valid Mobile No',
    requiredMessage: 'Mobile is Required',
    value: null,
    name: 'mobile'
  },
  street_address: {
    placeholder: 'Street Address',
    isRequired: true,
    isValid: false,
    regex: null,
    errorMessage: '',
    requiredMessage: 'Street Address is Required',
    value: null,
    name: 'street_address'
  },
  startDate: {
    placeholder: 'Start Date',
    isRequired: true,
    isValid: false,
    regex: null,
    errorMessage: '',
    requiredMessage: 'Start Date is Required',
    value: null,
    name: 'startDate'
  },
  endDate: {
    placeholder: 'End Date',
    isRequired: true,
    isValid: false,
    regex: null,
    errorMessage: '',
    requiredMessage: 'End Date is Requried',
    value: null,
    name: 'endDate'
  },
  noOfWeek: {
    placeholder: 'No of Weeks',
    isRequired: true,
    isValid: false,
    regex: null,
    errorMessage: '',
    requiredMessage: 'No of Week is Required',
    value: null,
    name: 'noOfWeek'
  },
  radius: {
    type:'number',
    isRequired: false,
    isValid: true,
    regex: null,
    errorMessage: '',
    requiredMessage: 'Radius is Required',
    value: 50,
    name: 'radius'
  },
  category: {
    placeholder: 'Select Category',
    requiredMessage: 'Category is Required',
    isValid: false,
    value: null,
    name: 'category',
    option: [
      { id: 1, name: 'category 1' },
      { id: 2, name: 'category 2' }
    ]
  },
  category2: {
    placeholder: 'Select-Category',
    isValid: false,
    value: null,
    name: 'category2',
    option: [
      { id: 1, name: 'category 1' },
      { id: 2, name: 'category 2' }
    ]
  },
  videoLink: {
    placeholder: 'Add a website or video link',
    isRequired: false,
    isValid: false,
    regex: null,
    value: null,
    name: 'videoLink'
  },
  ads: {
    value: false,
    options: Ads,
    name: 'ads',
    isValid: true
  },
  restrictedContent: {
    value: false,
    options: Res,
    name: 'restrictedContent',
    isValid: true
  },
  termCond: {
    value: false,
    options: [],
    name: 'termCond',
    isValid: false
  },
  termCondNew: {
    value: false,
    options: [],
    name: 'Terms & Condtions',
    isValid: false
  },
  cancelationFee: {
    value: false,
    options: [],
    name: 'cancelationFee',
    isValid: true
  },
  banner300x50: {
    isValid: false,
    value: [],
    name: 'banner300x50'
  },
  banner300x100: {
    isValid: false,
    value: [],
    name: 'banner300x100'
  },
  banner970x90: {
    isValid: false,
    value: [],
    name: 'banner970x90'
  },
  banner468x60: {
    isValid: false,
    value: [],
    name: 'banner468x60'
  },
  banner728x90: {
    isValid: false,
    value: [],
    name: 'banner728x90'
  },

  total: {
    placeholder: '$',
    isRequired: true,
    isValid: false,
    regex: null,
    errorMessage: '',
    requiredMessage: 'Total Amount is required',
    value: null,
    name: 'total',
    disabled: true
  },

  radiusMap: {
    type:'number',  
    isRequired: true,
    isValid: true,
    regex: null,
    errorMessage: '',
    requiredMessage: 'Radius is Required',
    value: 11,
    name: 'radiusMap',   
  }
  
}

