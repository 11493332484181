export const more = [
  {
    title: 'needHand',
    image: 'images/spotjoblogo.png',
    text: 'postAJobDescribe'
  },
  {
    title: 'wantToEarn',
    image: 'images/spotjoblogo.png',
    text: 'setUpFullProfile'
  },
  {
    title: 'ServiceFees',
    image: 'images/spotjoblogo.png',
    text: 'ServiceFeesDescription'
  }
]
