import React, { Component } from 'react'
import { compose } from 'redux'
import { withNamespaces } from 'react-i18next'
import { GoogleApiWrapper } from 'google-maps-react'
import { gooogleApiKey } from 'Constants'

import { BreadCrumbs } from 'Components/blocks'
import { ContentWrapper } from 'Components/ui'
import { getBreadCrumbs } from './memoize'
import CreateAdsLayout from './layout'

class CreateAds extends Component {

  render() {
    const { t } = this.props
    const breadCrumbs = getBreadCrumbs(t)
    return (
      <>
        <BreadCrumbs data={breadCrumbs} />
        <ContentWrapper>
          <CreateAdsLayout {...this.props} />
        </ContentWrapper>
      </>
    )
  }
}

export default compose(
  withNamespaces('postAds'),
  GoogleApiWrapper({
    apiKey: gooogleApiKey,
    libraries: ['places']
  })
)(CreateAds)
