import styled from 'styled-components'
import { sizes } from 'Constants'

export const ShowVideo = styled.iframe`
  width: 560px;
  height: 315px;
  @media (max-width: ${sizes.phone}px) {
    max-width: 100%;
    max-height: 100%;
  }
`

export const DonateButton = styled.img`
  position: absolute;
  top: 80px;
  left: 20px;
  width: 300px;
  cursor: pointer;
  z-index: 999;
  @media (max-width: ${sizes.phone}px) {
    width: 150px;
  }
`

export const CookieDisclaimer = styled.div`
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  z-index: 999;
  background: #2777CC;
  padding: 20px 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: ${sizes.phone}px) {
    flex-direction: column;
  }
`

export const CookieText = styled.div`
  color: white;
  margin-right: 40px;
  width: 600px;
  font-size: 18px;
  @media (max-width: ${sizes.phone}px) {
    width: auto;
    margin-right: 0px;
    margin-bottom: 20px;
    font-size: 15px;
  }
`

export const CookieButton = styled.button`
  background: white;
  color: #2777CC;
  height: 40px;
  border-radius: 20px;
  padding: 0 20px;
  border-color: transparent;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  @media (max-width: ${sizes.phone}px) {
    font-size: 13px;
  }
`