import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import memoize from 'memoize-one'
import { TABS_LIST_MAP } from 'Constants/tabNav'
import { Switch, Route } from 'react-router-dom'
// import * as routes from 'Routes/routesConstants'
import { jobStatuses } from 'Constants/jobStatuses'

import { icons } from 'Assets/icons'

// import InfiniteScroll from 'react-infinite-scroller'
import {
  JobCard,
  RightSidebar,
  Ad,
  HeaderTabs,
  ProgressBar,
  RatingReview,
  ListAd,
  BreadCrumbs,
  MapView,
  JobView
} from 'Components/blocks'

import {
  CardWrapper,
  RightSideBarWrapper,
  DownPart,
  PreloadImg,
  ContentWrapper
  // LoadMoreWrapper
} from 'Components/ui'

import { path } from './config'
import { JobViewContainer, GoogleAdsFirst } from './style'

import {
  getBreadCrumbs,
  getDescriptionBreadCrumbs,
  getReviewBreadCrumbs
} from './memoize'

export class BookedJobs extends PureComponent {
  state = {
    isRequest: true,
    isLoadMoreRequest: false,
    activeTabIndex: 'a',
    activeCategoriesIds: []
  }

  componentDidMount() {
    const { onLoadBookedJobList } = this.props
    const { elements_per_page, pageNumber } = this.state

    const request = {
      elements_per_page,
      page_number: pageNumber
    }

    onLoadBookedJobList(request, () => this.setState({ isRequest: false }))
  }

  isBookedJob = memoize(
    jobStatus =>
      jobStatus === jobStatuses.booked ||
      jobStatus === jobStatuses.completed ||
      jobStatus === jobStatuses.done
  )

  handleTabClick = id => this.setState({ activeTabIndex: id })

  handleCancelClick = () => {
    const { specificJob, onCancelJob } = this.props

    onCancelJob(specificJob)
  }

  onFilter = (data = {}) => {
    const { onLoadBookedJobList } = this.props

    this.setState({
      isRequest: true,
      activeCategoriesIds: _.isEmpty(data.services) ? [] : data.services
    })

    const request = {
      categories: _.isEmpty(data.services)
        ? undefined
        : JSON.stringify(data.services)
    }

    onLoadBookedJobList(request, () => this.setState({ isRequest: false }))
  }

  onClickPopularService = ({ services }) => {
    const { activeCategoriesIds } = this.state
    const id = services[0]
    let result
    if (activeCategoriesIds.indexOf(id) === -1) {
      result = activeCategoriesIds.concat(services)
    } else {
      result = activeCategoriesIds.filter(el => el !== id)
    }

    this.onFilter({ services: result })
  }

  loadMoreItems = () => {
    const { onLoadBookedJobList } = this.props
    const { elements_per_page, pageNumber, isLoadMoreRequest } = this.state

    if (isLoadMoreRequest) return

    const request = {
      elements_per_page,
      page_number: pageNumber + 1
    }

    this.setState({ isLoadMoreRequest: true, pageNumber: pageNumber + 1 })
    onLoadBookedJobList(request, () => {
      this.setState({ isLoadMoreRequest: false })
    })
  }

  renderRateReview = props => {
    const { t, specificJob } = this.props
    const breadCrumbsData = getReviewBreadCrumbs(
      t,
      specificJob.title,
      specificJob._id
    )

    return (
      <>
        <BreadCrumbs data={breadCrumbsData} />
        <RatingReview {...props} />
      </>
    )
  }

  _renderListView = () => {
    const { bookedJobs, onClickJob, onShare, onCancelJob } = this.props
    const { isRequest, activeCategoriesIds } = this.state

    if (isRequest && _.isEmpty(bookedJobs.data)) {
      return <PreloadImg alt="loading" src={icons.preload} />
    }

    // if (_.isEmpty(bookedJobs.data)) return null

    return (
      <DownPart>
        <CardWrapper>
          <GoogleAdsFirst />
          {/*<InfiniteScroll*/}
          {/*hasMore={bookedJobs.isListEnd}*/}
          {/*loadMore={this.loadMoreItems}*/}
          {/*loader={*/}
          {/*<LoadMoreWrapper key={-1}>*/}
          {/*<PreloadImg*/}
          {/*alt="loading"*/}
          {/*className="loader"*/}
          {/*src={icons.preload}*/}
          {/*/>*/}
          {/*</LoadMoreWrapper>*/}
          {/*}*/}
          {/*useWindow*/}
          {/*>*/}
          {(bookedJobs.data || []).map((item, key) => (
            <Fragment key={key}>
              <JobCard
                {...item}
                onCancelClick={() => onCancelJob(item)}
                onClick={() => onClickJob(item)}
                onShareClick={() => onShare(item)}
              />
              <ListAd data={bookedJobs.data} index={key} />
            </Fragment>
          ))}
          {/*</InfiniteScroll>*/}
          <Ad />
        </CardWrapper>
        <RightSideBarWrapper>
          <RightSidebar
            activeCategoriesIds={activeCategoriesIds}
            handleFilter={this.onClickPopularService}
          />
        </RightSideBarWrapper>
      </DownPart>
    )
  }

  _renderMapView = () => {
    const { bookedJobs, onClickJob, onShare } = this.props
    const { isRequest } = this.state

    return (
      <>
        <ContentWrapper>
          <MapView
            data={bookedJobs.data}
            loading={isRequest}
            renderCard={props => (
              <JobCard
                {...props}
                onClick={() => onClickJob(props)}
                onShareClick={() => onShare(props)}
              />
            )}
          />
        </ContentWrapper>
      </>
    )
  }

  _renderJobDescription = () => {
    const { onMarkJobAs, t, specificJob, loadMarkJobId } = this.props

    const breadCrumbs = getDescriptionBreadCrumbs(
      t,
      _.get(specificJob, 'title', t('common:loading'))
    )

    const isBookedJob = this.isBookedJob(_.get(specificJob, 'jobStatus'))

    return (
      <>
        <BreadCrumbs data={breadCrumbs} />
        <ContentWrapper>
          <JobViewContainer>
            <JobView
              isLoadMarkAsButton={Boolean(loadMarkJobId)}
              onCancelClick={isBookedJob ? this.handleCancelClick : undefined}
              onMarkJob={onMarkJobAs}
            />
          </JobViewContainer>
        </ContentWrapper>
      </>
    )
  }

  _renderContent = () => {
    const { t } = this.props
    const { activeTabIndex } = this.state

    const breadCrumbs = getBreadCrumbs(t)

    return (
      <>
        <BreadCrumbs data={breadCrumbs} />
        <ContentWrapper>
          <HeaderTabs
            options={TABS_LIST_MAP}
            onClickLink={this.handleTabClick}
          />

          {activeTabIndex === 'b'
            ? this._renderMapView()
            : this._renderListView()}
        </ContentWrapper>
      </>
    )
  }

  render() {
    const { isRequest } = this.state

    return (
      <>
        <ProgressBar percent={isRequest ? 1 : 100} />

        <Switch>
          <Route
            exact
            path={path.jobDescription}
            render={this._renderJobDescription}
          />
          <Route exact path={path.review} render={this.renderRateReview} />
          <Route exact render={this._renderContent} />
        </Switch>
      </>
    )
  }
}

BookedJobs.propTypes = {
  bookedJobs: PropTypes.shape({
    data: PropTypes.array,
    isListEnd: PropTypes.bool
  }),
  loadMarkJobId: PropTypes.string,
  specificJob: PropTypes.object,
  onApply: PropTypes.func,
  onCancelJob: PropTypes.func,
  onClickJob: PropTypes.func,
  onLoadBookedJobList: PropTypes.func,
  onMarkJobAs: PropTypes.func,
  onShare: PropTypes.func
}
