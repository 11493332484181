import styled from 'styled-components'
import { fontFamily } from 'Constants'

export const About = styled.div`
  padding: 20px 0;
  color: ${props => props.theme.colors.lightGrayText};
`

export const CardWrapper = styled.div`
  background: ${props => props.theme.colors.componentsBackground};
  margin-bottom: 20px;
  width: 100%;
  padding: 10px 15px;
  align-self: center;
  justify-self: center;
  position: relative;
  box-shadow: ${props => props.theme.colors.boxShadow};
  border-radius: 3px 3px 0 0;
  transition: box-shadow 0.3s;
  overflow: auto;
  &:hover {
    box-shadow: 0 0 10px #b1bcd4;
  }
  & a {
    text-decoration: none;
    color: ${props => props.theme.colors.defaultGrey};
    color: ${props => props.theme.colors.defaultFontBlack};
  }
`
export const Date = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: #929292;
`

export const AboutTitle = styled.div`
  margin-top: 20px;
  font-family: ${fontFamily};
  font-weight: 600;
`

export const LeftGroup = styled.div`
  display: flex;
`

export const LeftProfileGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  height: 60px;
  margin-left: 10px;
`

export const Plumber = styled.div`
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  color: white;
  background: ${p => p.theme.colors.premiumBg};
  border-radius: 15px;
  width: fit-content;
  padding: 2px 5px;
`

export const ActionLink = styled.span`
  cursor: pointer;
  font-size: ${p => p.theme.fontSize.small};
  margin-bottom: 1px;
  &:hover {
    text-decoration: underline;
  }
`

export const ActionLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
`

export const Span = styled.span`
  font-weight: bold;
  font-size: ${p => p.theme.fontSize.h6};
  cursor: pointer;
`

export const StarGroup = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
  & span {
    margin-left: 10px;
  }
`

export const NameGroup = styled.div`
  display: flex;
  align-items: center;
`
