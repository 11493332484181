import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getServices } from 'Redux/selectors/services'

import Component from './Search'

const actions = {}

const selectors = createStructuredSelector({
  services: getServices
})

export default connect(
  selectors,
  actions
)(Component)
