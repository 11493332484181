import { createReducer } from 'Helpers/redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { LOAD_POSTED_JOB } from 'Redux/actions/postedJobs'
import { DELETE_JOB } from 'Redux/actions/job'
import { SEND_REQUEST } from 'Redux/actions/requests'

const persistConfig = {
  key: 'postedJobs',
  storage,
  blacklist: []
}

const initialState = {
  data: [],
  isListEnd: false
}

const handlers = {
  [LOAD_POSTED_JOB.SUCCESS]: (prevState, { payload }) => ({
    ...prevState,
    data: payload.list,
    isListEnd: !payload.isListEnd
  }),
  [DELETE_JOB.SUCCESS]: (prevState, { meta }) => ({
    ...prevState,
    data: prevState.data.filter(el => el._id !== meta.id)
  }),
  [SEND_REQUEST.SUCCESS]: (state, { payload }) => ({
    ...state,
    data: state.data.map(el =>
      el._id === payload.job
        ? {
            ...el,
            requests: (el.requests || []).concat([payload])
          }
        : el
    )
  })
}

export default persistReducer(
  persistConfig,
  createReducer(initialState, handlers)
)
