import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import * as routes from 'Routes/routesConstants'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { arrows } from 'Assets/arrows'
import { icons } from 'Assets/icons'
import { paymentsTransactions, root } from 'Routes/routesConstants'
import {
  ProfileBackground,
  FormContainer,
  Logo,
  Registered,
  SubTitle,
  Modal
} from 'Components/ui'

import {
  ContentHeaderContainer,
  ContentHeader,
  ContnentHeaderBackButton,
  Loading,
  SectionCenter,
  Text,
  Button,
  Icon,
  Container,
  Header,
  Content,
  Title,
  Form,
  Input,
  Buttons,
  SubmitButton,
  CancelButton,
  Error,
  ImgLoading,
  Row
} from './styles'
import { requestUrl } from './config'
import { formatPrice } from 'Constants/payment'

class WithdrawalMoney extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isRequest: false,
      amountWithdraw: String(props.user?.total_amount) || null,
      isShowModal: false,
      isWithdrawing: false,
      error: null
    }
  }

  componentDidMount() {
    const { history, location, onConnectPaypal, onFetchUser, t } = this.props
    const query = new URLSearchParams(location.search)
    const code = query.get('code')

    if (code) {
      this.setState({ isRequest: true })
      onConnectPaypal({ code }, error => {
        history.push(routes.withdrawalMoney)
        if (error) {
          this.setState({ isRequest: false })
          return toast.error(error.message || '')
        }
        toast.success(t('common:success'))
        onFetchUser(error => {
          this.setState({ isRequest: false })
          if (error) {
            return toast.error(error.message || '')
          }
        })
      })
    }
  }

  renderButton = () => {
    const { user, t } = this.props

    if (user?.paypal_account_withdraw) {
      return (
        <Button isConnected onClick={this.handleOpenModal}>
          {t('profilePage:withdrawMoney')}
        </Button>
      )
    }
    return (
      <>
        <Text size={16} color="#aaa" width="80%" bottom={10}>
          {t('profilePage:withdrawMoneyTip')}
        </Text>
        <Button onClick={this.handleClick}>
          <Icon src={icons.paypal} />
          {t('profilePage:connectWithPaypal')}
        </Button>
      </>
    )
  }

  handleClick = () => window.open(requestUrl, '_self')

  handleWithdraw = () => {
    const { onWithdraw, user, t } = this.props
    const { amountWithdraw } = this.state
    if (!amountWithdraw || Number(amountWithdraw) < 0.3 || formatPrice(amountWithdraw - this.getServiceFee()) <= 0) {
      return this.setState({ error: t('profilePage:invalidAmount') })
    }

    if (amountWithdraw > user.total_amount) {
      this.setState({ error: null })
      return toast.warn(t('profilePage:amountNotEnough'))
    }

    if (amountWithdraw.split('.')?.length > 2) {
      return toast.warn(t('profilePage:invalidAmount'))
    }

    this.setState({ isWithdrawing: true })
    onWithdraw(this.formatAmount(amountWithdraw, 'number'), error => {
      if (error) {
        this.setState({ isWithdrawing: false, amountWithdraw: String(user?.total_amount || 0), error: null })
        return toast.error(error.message)
      }
      this.setState({ isWithdrawing: false, isShowModal: false, amountWithdraw: String(user?.total_amount || 0), error: null })
      toast.success(t('git:success'))
    })
  }

  handleChangeWithdraw = event => {
    this.setState({ amountWithdraw: event.target.value })
  }

  handleOpenModal = () => this.setState({ isShowModal: true })

  handleCloseModal = () => this.setState({ isShowModal: false, amountWithdraw: String(this.props.user?.total_amount || 0), error: null })

  renderWithdrawModal = () => {
    const { t } = this.props
    const { amountWithdraw, isWithdrawing, isShowModal, error } = this.state

    return (
      <Modal
        isShow={isShowModal}
        zIndex={1010}
        // onRequestClose={this.handleCloseModal}
        width="400px"
      >
        <Container>
          <Header>
            <Title>{t('withdrawMoney')}</Title>
          </Header>
          <Content>
            <Form>
              <Input
                placeholder={t('withdrawlPlaceHolder')}
                onChange={this.handleChangeWithdraw}
                value={this.formatAmount(amountWithdraw)}
                type="number"
              />
              {error && <Error>{error}</Error>}
              <Text>USD</Text>
            </Form>
            <Row>
              <Text>{t('profilePage:serviceFee')}:</Text>
              <Text>$ {this.getServiceFee()}</Text>
            </Row>
            <Row>
              <Text>{t('totalAmountReceive')}:</Text>
              <Text>$ {formatPrice(amountWithdraw - this.getServiceFee()) > 0 ? formatPrice(amountWithdraw - this.getServiceFee()) : 0}</Text>
            </Row>
          </Content>
          <Buttons>
            <CancelButton onClick={this.handleCloseModal}>
              {t('jobPost:cancel')}
            </CancelButton>
            <SubmitButton
              disabled={isWithdrawing}
              onClick={this.handleWithdraw}
            >
              {isWithdrawing ? (
                <ImgLoading alt="" src={icons.threeDotsLoader} />
              ) : (
                t('profilePage:submit')
              )}
            </SubmitButton>
          </Buttons>
        </Container>
      </Modal>
    )
  }

  formatAmount = (amount, type = 'string') => {
    const { t } = this.props
    if (type === 'string') {
      if (String(amount)) {
        const _amount = amount.split('.')
        if (_amount.length === 2) {
          if (_amount[1].length > 2) {
            return `${_amount[0]}.${_amount[1].slice(0, 2)}`
          }
        }

        if (_amount.length > 2) {
          toast.warn(t('profilePage:amountNotEnough'))
        }
        return amount
      }
      return amount
    }

    const _amount = amount.split('.')
    if (_amount.length === 2) {
      if (_amount[1].length > 2) {
        return Number(`${_amount[0]}.${_amount[1].slice(0, 2)}`)
      }
    }

    if (_amount.length > 2) {
      return toast.warn(t('profilePage:amountNotEnough'))
    }
    return Number(amount)
  }

  getServiceFee = () => {
    const { amountWithdraw } = this.state
    if (amountWithdraw && Number(amountWithdraw) > 0) {
      return formatPrice(amountWithdraw * 0.029 + 0.3)
    }
    return 0.00
  }

  render() {
    const { t, user } = this.props
    const { isRequest } = this.state
    return (
      <ProfileBackground>
        <Link to={root}>
          <Logo>
            SpotJobs<Registered>&reg;</Registered>
          </Logo>
          <SubTitle>{t('signInHeadDescription')}</SubTitle>
        </Link>
        <FormContainer>
          <Fragment>
            <ContentHeaderContainer>
              <ContentHeader>{t('withdrawMoney')}</ContentHeader>
              <Link to={paymentsTransactions}>
                <ContnentHeaderBackButton src={arrows.arrowBack} />
              </Link>
            </ContentHeaderContainer>

            {isRequest ? (
              <Loading>
                <img alt="" src={icons.preload} />
              </Loading>
            ) : (
              <SectionCenter>
                <Text size={18} fontWeight={600} bottom={10}>
                  {t('yourTransferableBalance')}
                </Text>
                <Text size={30} fontWeight={700} bottom={20}>
                  $ {formatPrice(user?.total_amount || 0).toFixed(2)}
                </Text>
                {this.renderButton()}
                {this.renderWithdrawModal()}
              </SectionCenter>
            )}
          </Fragment>
        </FormContainer>
      </ProfileBackground>
    )
  }
}

WithdrawalMoney.propTypes = {
  earnings: PropTypes.array,
  onGetEarnings: PropTypes.func,
  onConnectPaypal: PropTypes.func,
  onFetchUser: PropTypes.func,
  onWithdraw: PropTypes.func
}

export default WithdrawalMoney
