import React, { Component } from 'react'
import { Formik } from 'formik'
import { withNamespaces } from 'react-i18next'
import { compose } from 'redux'
import _ from 'lodash'
import { GoogleApiWrapper } from 'google-maps-react'
import ImageUploader from 'react-images-upload'
import image from 'Helpers/jobImage'
import { toast } from 'react-toastify'
import shortId from 'shortid'
import { gooogleApiKey } from 'Constants'

import * as routes from 'Routes/routesConstants'
import ImagesManager from 'Services/Images/ImagesManager'
import { imageTypes } from 'Services/Constants/images.constants'
import profileLimits from 'Configs/profileLimits'
import { PARSE_LOCATION_INFO } from 'Constants/parseLocationInfo'
import { LinksUploader } from 'Components/blocks/ProfileForm'
import {
  AddLinkModal,
  Header,
  BurgerMenu,
  BreadCrumbs,
  Footer,
  LocationAutocomplete,
  Map
} from 'Components/blocks'
import { icons } from 'Assets/icons'
// import ads from 'Configs/ads'

import {
  BtnSubmit,
  InputWrapper,
  DatePicker,
  SelectCurrency
  // LoadMoreWrapper,
  // PreloadImg
} from 'Components/ui'

import {
  ALLOWED_IMG_EXTENSIONS,
  MAX_IMG_SIZE
} from 'Components/blocks/ProfileForm/config'

import { getValidationSchema } from './config'
import {
  Main,
  Wrapper,
  Section,
  Content,
  // AsideRight,
  AsideLeft,
  // Card,
  // CardOverlay,
  Title,
  FormBox,
  BoxTitle,
  BoxInfo,
  Row,
  Input,
  Textarea,
  Label,
  Col6,
  Col12,
  JobPost,
  Error,
  CancelButton,
  BudgetInput,
  CurrencyContainer,
  BudgetWrapper,
  Text,
  styles
} from './styles'

import SelectInput from './Select'
import { getBudget } from 'Constants/payment'
import withCurrency from '../../hoc/withCurrency'

const userRole = 'free'

class JobPosts extends Component {
  constructor(props) {
    super(props)
    var dateDoneBefore = new Date()
    dateDoneBefore.setDate(dateDoneBefore.getDate() + 1)
    this.state = {
      doneBefore: dateDoneBefore,
      expiryDate: new Date(),
      addLinkType: null,
      websiteLinks: null,
      isRequest: false,
      isExpChanged: false,
      isDoneChanged: false,
      isAdsLoading: false,
      isAdsLoadingSecond: false,
      location: _.get(props, 'history.location.params.data.geolocation', ''),
      rate: 1
    }
    this.budget = React.createRef()
  }

  componentDidMount() {
    const { services, onGetServices } = this.props

    // this.budget.current.addEventListener('wheel', e => e.preventDefault())
    // setTimeout((window.adsbygoogle = window.adsbygoogle || []).push({}), 3000)
    // setTimeout((window.adsbygoogle = window.adsbygoogle || []).push({}), 4000)

    if (_.isEmpty(services.services)) onGetServices()
  }

  componentWillUnmount() {
    // this.budget.current.removeEventListener('wheel', e => e.preventDefault())
  }

  handleSetBeforeDate = date => {
    this.setState({
      isDoneChanged: true,
      doneBefore: date
    })
  }

  handleSetExpDate = date => {
    this.setState({
      isExpChanged: true,
      expiryDate: date
    })
  }

  handleImgListChanged = (listType, filesArray, base64Array, setFieldValue) => {
    setFieldValue(listType, filesArray, base64Array)
  }

  handleOpenAddLinkModal = addLinkType => {
    this.setState({ addLinkType })
  }

  handleMaxItemsUploaded = () => {
    const { t } = this.props

    toast.error(t('overflowError'))
  }

  handleAddLink = (newLink, type, values, setFieldValue) => {
    const nextLinksList = values[type] || []

    nextLinksList.push(newLink)

    setFieldValue(type, nextLinksList)

    this.handleCloseAddLinkModal()

    this.setState({ websiteLinks: nextLinksList })
  }

  handleCloseAddLinkModal = () => {
    this.setState({
      addLinkType: null
    })
  }

  handleDeleteLink = (indexToDelete, type, values, setFieldValue) => {
    setFieldValue(
      type,
      values[type].filter((item, index) => index !== indexToDelete)
    )
  }

  handleSubmit = async data => {
    const { history, t, user, onPostJob, onUpdateJob } = this.props
    const {
      location,
      doneBefore,
      expiryDate,
      isDoneChanged,
      isExpChanged,
      rate
    } = this.state
    if (!location) {
      toast.warn(t('errorAddressLocated'))
      return
    }

    if (getBudget(data.budget, rate, data.currency, 'number') < 10) {
      return toast.warn(t('budgetDescription'))
    }

    this.setState({ isRequest: true })
    if (!history.location.params) {
      const category = data.category.split(',')
      const _id = shortId.generate()
      const preparedData = {
        _id: _id,
        author: user.username,
        category: category[0],
        service: category[1] && category[1],
        title: data.title,
        doneBefore,
        expiryDate,
        description: data.description,
        budget: getBudget(data.budget, rate, data.currency, 'number'),
        videoLinks: data.websiteLinks,
        streetAddress: data.address,
        city: data.city,
        state: data.state,
        zipCode: data.zip,
        country: data.country,
        geolocation: location,
        currency: 'USD',
        budgetMyCountry: Number(data.budget),
        currencyMyCountry: data.currency || 'USD'
      }

      if (data.pics.base64Array) {
        try {
          const jobPicsIds = await ImagesManager.updateImages(
            imageTypes.jobPics,
            data.pics.base64Array,
            { subfolder: _id }
          )
          preparedData.pics = jobPicsIds
        } catch (err) {
          console.log('err: ', err)
        }
      }

      try {
        onPostJob(preparedData, error => {
          if (!error) {
            history.push(`${routes.myJobs}${routes.posted}/${_id}`)
          } else {
            this.setState({ isRequest: false })
          }
        })
      } catch (err) {
        this.setState({ isRequest: false })
      }
    } else {
      const prevData = history.location.params.data
      const category = data.category.split(',')
      const updateData = {
        category:
          prevData.service !== category[0] && prevData.category !== category[0]
            ? category[0]
            : null,
        service:
          (category[1] && prevData.service !== category[1] && category[1]) ||
          null,
        title: prevData.title !== data.title ? data.title : null,
        doneBefore: isDoneChanged
          ? this.state.doneBefore
          : data.dateNeed || null,
        expiryDate: isExpChanged
          ? this.state.expiryDate
          : data.dateExpiry || null,
        description:
          prevData.description !== data.description ? data.description : null,
        budget:
          prevData.budget !== data.budget
            ? getBudget(data.budget, rate, data.currency, 'number')
            : null,
        videoLinks: !_.isEqual(prevData.videoLinks, data.websiteLinks)
          ? data.websiteLinks
          : null,
        streetAddress:
          prevData.streetAddress !== data.address ? data.address : null,
        city: prevData.city !== data.city ? data.city : null,
        state: prevData.state !== data.state ? data.state : null,
        zipCode: prevData.zipCode !== data.zip ? data.zip : null,
        country: prevData.country !== data.country ? data.country : null,
        geolocation:
          prevData.geolocation !== this.state.location
            ? this.state.location
            : null,
        currency: 'USD',
        budgetMyCountry: prevData.budget !== data.budget ? data.budget : null,
        currencyMyCountry:
          prevData.currency !== data.currency ? data.currency : null
      }

      const allImages = data.pics.base64Array.map(img => {
        if (!!/(data:image\/.*?;base64),/g.exec(img)) {
          return img
        } else {
          const arr = img.split('/')
          return arr[arr.length - 1]
        }
      })

      _.get(prevData, 'pics', []).forEach(img => {
        if (allImages.indexOf(img) === -1) {
          allImages.push({ status: 'deleted', _id: img })
        }
      })

      const jobPicsIds = await ImagesManager.updateImages(
        imageTypes.jobPics,
        allImages,
        { subfolder: prevData._id }
      )

      updateData.pics = !_.isEqual(prevData.pics, jobPicsIds)
        ? jobPicsIds
        : null

      const filteredUpdate = {}

      Object.keys(updateData).forEach(key => {
        if (updateData[key] !== null) filteredUpdate[key] = updateData[key]
      })

      if (Object.keys(filteredUpdate).length !== 0) {
        try {
          onUpdateJob(filteredUpdate, prevData._id, error => {
            if (!error) {
              history.push(`${routes.myJobs}${routes.posted}/${prevData._id}`)
            } else this.setState({ isRequest: false })
          })
        } catch (err) {
          this.setState({ isRequest: false })
        }
      } else {
        toast.success('No changes detected')
        this.setState({ isRequest: false })
      }
    }
  }

  handleAddressChanged = (
    locationInfo,
    setFieldValue,
    fromMapClick = false
  ) => {
    const { address_components, geometry } = locationInfo
    PARSE_LOCATION_INFO.forEach(infoType => {
      const info = _.find(
        address_components,
        item => item.types.indexOf(infoType.searchForType) > -1
      )
      if (info) {
        setFieldValue(infoType.putToField, infoType.pickField(info))
      } else {
        setFieldValue(infoType.putToField, '')
      }
    })

    const location = _.get(geometry, 'location', {})

    if (!fromMapClick && location.lat && location.lng) {
      this.setState({
        location: `${location.lat()}/${location.lng()}`
      })
    } else if (!fromMapClick) {
      this.setState({
        location: ''
      })
    }

    let address = locationInfo.formatted_address

    const route = _.find(
      address_components,
      item => item.types.indexOf('route') > -1
    )

    if (route && route.long_name) {
      address = route.long_name

      const streetNumber = _.find(
        address_components,
        item => item.types.indexOf('street_number') > -1
      )

      if (streetNumber && streetNumber.long_name) {
        address += `, ${streetNumber.long_name}`
      }
    }
    setFieldValue('address', address)
  }

  handleMapClick = (t, map, coord, setFieldValue) => {
    const { latLng } = coord
    this.setState({
      location: `${latLng.lat()}/${latLng.lng()}`
    })
    this.props.onGetGeo(latLng.lat(), latLng.lng(), (error, response) => {
      this.handleAddressChanged(response.results[0], setFieldValue, true)
    })
  }

  render() {
    const {
      match,
      location,
      user,
      t,
      // onViewedAds,
      // onTokenRehydrate
      onGetRatio
    } = this.props
    const { addLinkType, rate } = this.state
    const data = _.get(location, 'params.data')
    const pics = _.get(data, 'pics', []).map(img => image(data._id, img))

    const initialValues = data
      ? {
          category: data.service || data.category || undefined,
          budget: data.budgetMyCountry || data.budget || '',
          city: data.city || '',
          country: data.country || '',
          description: data.description || '',
          pics: { base64Array: pics || [] },
          state: data.state || '',
          title: data.title || '',
          zip: data.zipCode || '',
          dateNeed: new Date(data.doneBefore) || '',
          dateExpiry: new Date(data.expiryDate) || '',
          address: data.streetAddress || '',
          currency: data.currencyMyCountry || data.currency || 'USD',
          websiteLinks: data.videoLinks
        }
      : {
          category: undefined,
          budget: '',
          city: '',
          country: '',
          description: '',
          pics: [],
          state: '',
          title: '',
          zip: '',
          dateNeed: '',
          dateExpiry: '',
          address: '',
          currency: 'USD'
        }
    var dateDoneBefore = new Date()
    dateDoneBefore.setDate(dateDoneBefore.getDate() + 1)

    return (
      <JobPost className="job-posts">
        <BurgerMenu user={user} />
        <Header postJob={true} view={false} />
        <BreadCrumbs location={location} match={match} />
        <Main>
          <Wrapper>
            <Title>{t('title')}</Title>
            <Section>
              <AsideLeft />
              <Content>
                <Formik
                  initialValues={initialValues}
                  validationSchema={getValidationSchema(t)}
                  onSubmit={this.handleSubmit}
                >
                  {({
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                    setFieldValue,
                    submitCount
                  }) => {
                    const isFormTouched = submitCount > 0
                    return (
                      <form onSubmit={handleSubmit}>
                        <FormBox>
                          <Row>
                            <Col12>
                              <InputWrapper>
                                <BoxTitle>{t('selectCategory')}</BoxTitle>
                                <SelectInput
                                  name="category"
                                  services={this.props.services.services}
                                  value={values.category}
                                  onChange={handleChange}
                                />
                                {errors.category &&
                                  (touched.category || isFormTouched) && (
                                    <Error>{t('validation.category')}</Error>
                                  )}
                              </InputWrapper>
                            </Col12>
                          </Row>
                        </FormBox>
                        <FormBox>
                          <Row>
                            <Col12>
                              <BoxTitle>{t('jobAddress')}</BoxTitle>
                              <BoxInfo>({t('infoBox')})</BoxInfo>
                              <Label htmlFor="street">{t('street')}</Label>
                              <LocationAutocomplete
                                InputComponent={Input}
                                inputProps={{
                                  errors: errors.address,
                                  label: t('fields.address'),
                                  mr: '15px',
                                  name: 'address',
                                  width: '100%',
                                  touched: touched.address || isFormTouched,
                                  onBlur: handleBlur
                                }}
                                modal={true}
                                setFieldValue={setFieldValue}
                                value={values.address}
                                onChange={text =>
                                  setFieldValue('address', text)
                                }
                                onSelect={data =>
                                  this.handleAddressChanged(data, setFieldValue)
                                }
                              />
                            </Col12>
                          </Row>
                          <Row>
                            <Col6>
                              <Label htmlFor="city">{t('city')}</Label>
                              <Input
                                autoComplete="city"
                                data-lpignore="true"
                                id="city"
                                type="city"
                                value={values.city}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Col6>
                            <Col6>
                              <Label htmlFor="state">{t('state')}</Label>
                              <Input
                                autoComplete="state"
                                data-lpignore="true"
                                id="state"
                                type="state"
                                value={values.state}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Col6>
                          </Row>
                          <Row>
                            <Col6>
                              <Label htmlFor="zipCode">{t('zip')}</Label>
                              <Input
                                autoComplete="zip"
                                data-lpignore="true"
                                id="zip"
                                type="zip"
                                value={values.zip}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Col6>
                            <Col6>
                              <Label htmlFor="country">{t('country')}</Label>
                              <Input
                                autoComplete="countries"
                                data-lpignore="true"
                                id="country"
                                type="countries"
                                value={values.country}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Col6>
                          </Row>
                        </FormBox>
                        <FormBox>
                          <Map
                            google={this.props.google}
                            location={this.state.location}
                            marker
                            onHandleClick={(t, map, coord) =>
                              this.handleMapClick(t, map, coord, setFieldValue)
                            }
                          />
                        </FormBox>
                        <FormBox>
                          <BoxTitle>{t('details')}</BoxTitle>
                          <Row>
                            <InputWrapper>
                              <Label htmlFor="title">{t('jobTitle')}</Label>
                              <Input
                                data-lpignore="true"
                                id="title"
                                type="text"
                                value={values.title}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                maxLength={200}
                              />
                              {errors.title &&
                                (touched.title || isFormTouched) && (
                                  <Error>{t('validation.title')}</Error>
                                )}
                            </InputWrapper>
                          </Row>
                          <Row>
                            <InputWrapper>
                              <Label htmlFor="description">
                                {t('jobDescription')}
                              </Label>
                              <Textarea
                                cols="33"
                                data-lpignore="true"
                                id="description"
                                type="text"
                                value={values.description}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </InputWrapper>
                          </Row>
                          <Row>
                            <InputWrapper>
                              <Label htmlFor="budget">{t('budget')}</Label>
                              <BudgetWrapper>
                                <BudgetInput
                                  data-lpignore="true"
                                  id="budget"
                                  min="0"
                                  ref={this.budget}
                                  type="number"
                                  value={values.budget}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                />
                                <CurrencyContainer>
                                  <SelectCurrency
                                    value={values.currency}
                                    onSelect={value =>
                                      onGetRatio(value, rate => {
                                        this.setState({ rate })
                                        setFieldValue('currency', value)
                                      })
                                    }
                                  />
                                </CurrencyContainer>
                              </BudgetWrapper>
                            </InputWrapper>
                            {errors.budget &&
                              (touched.budget || isFormTouched) && (
                                <Error>{t('validation.budget')}</Error>
                              )}
                          </Row>
                          <Row>
                            <Input
                              value={getBudget(
                                values.budget,
                                rate,
                                values.currency
                              )}
                              style={styles.budgetConvert}
                            />
                            <Input value="USD" style={styles.currency} />
                          </Row>
                          <Row>
                            <Text>{t('budgetDescription')}</Text>
                          </Row>
                        </FormBox>
                        <FormBox>
                          <Row>
                            <Col12>
                              <BoxTitle>{t('setDates')}</BoxTitle>
                            </Col12>
                            <Col6>
                              <Label>{t('expDate')}</Label>
                              <DatePicker
                                data-lpignore="true"
                                dateFormat="MMMM d, yyyy h:mm aa"
                                handleChange={date =>
                                  this.handleSetExpDate(date)
                                }
                                minDate={new Date()}
                                selected={
                                  this.state.isExpChanged
                                    ? this.state.expiryDate
                                    : values.dateExpiry || this.state.expiryDate
                                }
                                showTimeSelect
                                timeCaption={t('time')}
                                timeFormat="HH:mm"
                                timeIntervals={15}
                              />
                            </Col6>
                            <Col6>
                              <Label>{t('doneBefore')}</Label>
                              <DatePicker
                                data-lpignore="true"
                                dateFormat="MMMM d, yyyy"
                                endDate={this.state.doneBefore}
                                handleChange={date =>
                                  this.handleSetBeforeDate(date)
                                }
                                minDate={dateDoneBefore}
                                selected={
                                  this.state.isDoneChanged
                                    ? this.state.doneBefore
                                    : values.dateNeed || this.state.doneBefore
                                }
                                selectsEnd
                                startDate={new Date()}
                              />
                            </Col6>
                          </Row>
                        </FormBox>
                        <FormBox>
                          <Row>
                            <Col12>
                              <BoxTitle>{t('pics')}</BoxTitle>
                              <ImageUploader
                                buttonText={null}
                                imgExtension={ALLOWED_IMG_EXTENSIONS}
                                label={null}
                                maxFileSize={MAX_IMG_SIZE}
                                maxItemsCount={
                                  profileLimits.picsOfWork[userRole]
                                }
                                value={_.get(values, 'pics.base64Array', [])}
                                withIcon={false}
                                withPreview
                                onChange={(files, b64s) => {
                                  this.handleImgListChanged(
                                    'pics.base64Array',
                                    files,
                                    b64s,
                                    setFieldValue
                                  )
                                }}
                                onMaxItemsOverflow={this.handleMaxItemsUploaded}
                              />
                            </Col12>
                          </Row>
                        </FormBox>
                        <FormBox>
                          <Row>
                            <Col12>
                              <BoxTitle>{t('video')}</BoxTitle>
                              <LinksUploader
                                maxItemsCount={
                                  profileLimits.VIDEO_LINKS_MAX_COUNT[userRole]
                                }
                                setFieldValue={setFieldValue}
                                type="websiteLinks"
                                values={values.websiteLinks}
                                onAddBtnClick={this.handleOpenAddLinkModal}
                                onDeleteClick={(index, type) =>
                                  this.handleDeleteLink(
                                    index,
                                    type,
                                    values,
                                    setFieldValue
                                  )
                                }
                                onMaxItemsOverflow={this.handleMaxItemsUploaded}
                              />
                              <AddLinkModal
                                isOpen={!!addLinkType}
                                onCancel={this.handleCloseAddLinkModal}
                                onRequestClose={this.handleCloseAddLinkModal}
                                onSubmit={({ Link }) =>
                                  this.handleAddLink(
                                    Link,
                                    addLinkType,
                                    values,
                                    setFieldValue
                                  )
                                }
                              />
                            </Col12>
                          </Row>
                        </FormBox>
                        <Row>
                          <CancelButton
                            className="outline"
                            isDisabled={!dirty || isSubmitting}
                            type="button"
                            width="45%"
                            onClick={handleReset}
                          >
                            {t('cancel')}
                          </CancelButton>
                          {this.state.isRequest ? (
                            <BtnSubmit disabled width="45%">
                              <img alt="" src={icons.threeDotsLoader} />
                            </BtnSubmit>
                          ) : (
                            <BtnSubmit
                              isDisabled={Object.keys(errors).length !== 0}
                              type="submit"
                              width="45%"
                            >
                              {t('postJob')}
                            </BtnSubmit>
                          )}
                        </Row>
                      </form>
                    )
                  }}
                </Formik>
              </Content>
              {/* <AsideRight>
                <Card
                  onClick={() => {
                    if (!isAdsLoadingSecond) {
                      this.setState({ isAdsLoading: true })
                      onViewedAds(ads.simple, () => {
                        onTokenRehydrate(() =>
                          this.setState({ isAdsLoading: false })
                        )
                      })
                    }
                  }}
                >
                  {isAdsLoading ? (
                    <LoadMoreWrapper isAdsPosts={true}>
                      <PreloadImg
                        alt="loading"
                        className="loader"
                        isAds={true}
                        src={icons.preload}
                      />
                    </LoadMoreWrapper>
                  ) : (
                    // <>
                    //   <CardOverlay onClick={() => console.log()} />
                    <ins
                      className="adsbygoogle"
                      style={{
                        display: 'block',
                        minWidth: '100%',
                        minHeight: '100%',
                        maxHeight: '200px',
                        // border: '1px solid red',
                        borderRadius: '5px',
                        cursor: 'pointer'
                      }}
                      // data-ad-client="ca-pub-5502345004497283"
                      // data-ad-slot="2960184572"
                      data-ad-format="auto"
                      data-full-width-responsive="true"
                      onClick={() => alert('google adsClick')}
                    ></ins>
                    // </>
                  )}
                </Card>
                <Card
                  onClick={() => {
                    if (!isAdsLoading) {
                      this.setState({ isAdsLoadingSecond: true })
                      onViewedAds(ads.simple, () => {
                        onTokenRehydrate(() =>
                          this.setState({ isAdsLoadingSecond: false })
                        )
                      })
                    }
                  }}
                >
                  {/* {isAdsLoadingSecond ? (
                    <LoadMoreWrapper isAdsPosts={true}>
                      <PreloadImg
                        alt="loading"
                        className="loader"
                        isAds={true}
                        src={icons.preload}
                      />
                    </LoadMoreWrapper>
                  ) : (
                    // <>
                    //   <CardOverlay
                    //     onClick={() => {
                    //       if (!isAdsLoading) {
                    //         this.setState({ isAdsLoadingSecond: true })
                    //         onViewedAds(ads.simple, () => {
                    //           onTokenRehydrate(() =>
                    //             this.setState({ isAdsLoadingSecond: false })
                    //           )
                    //         })
                    //       }
                    //     }}
                    //   />
                    <ins
                      className="adsbygoogle"
                      style={{
                        display: 'block',
                        minWidth: '100%',
                        minHeight: '100%',
                        maxHeight: '200px',
                        // border: '1px solid red',
                        borderRadius: '5px',
                        cursor: 'pointer'
                      }}
                      data-ad-client="ca-pub-5502345004497283"
                      data-ad-slot="2960184572"
                      data-ad-format="auto"
                      data-full-width-responsive="true"
                      onClick={() => alert('google adsClick')}
                    ></ins>
                    // </>
                  )} */}
              {/* </Card>
              </AsideRight> */}
              <AsideLeft />
            </Section>
          </Wrapper>
        </Main>
        <Footer black={true} isAuth={user} />
      </JobPost>
    )
  }
}

export default compose(
  withNamespaces('jobPost'),
  GoogleApiWrapper({
    apiKey: gooogleApiKey,
    libraries: ['places']
  }),
  withCurrency
)(JobPosts)
