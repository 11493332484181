import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { compose } from 'redux'
import { connect } from 'react-redux'

import {
  addJobToFavourites,
  removeJobFromFavourites
} from 'Redux/actions/favourites'

function withFavouriteJob(Component) {
  return class extends React.PureComponent {
    static propTypes = {
      addJobToFavourites: PropTypes.func,
      removeJobFromFavourites: PropTypes.func
    }

    state = {
      loadFavouriteJobId: null
    }

    handleFavourite = job => {
      const { addJobToFavourites, removeJobFromFavourites } = this.props

      this.setState({ loadFavouriteJobId: job._id })
      if (job.favouriteId) {
        removeJobFromFavourites(job.favouriteId, () => {
          this.setState({ loadFavouriteJobId: null })
        })
      } else {
        addJobToFavourites(job._id, () => {
          this.setState({ loadFavouriteJobId: null })
        })
      }
    }

    render() {
      const { loadFavouriteJobId } = this.state
      return (
        <Component
          {..._.omit(this.props, [
            'addJobToFavourites',
            'removeJobFromFavourites'
          ])}
          loadFavouriteJobId={loadFavouriteJobId}
          onFavouriteClick={this.handleFavourite}
        />
      )
    }
  }
}

const actions = {
  addJobToFavourites,
  removeJobFromFavourites
}

export default compose(
  connect(
    null,
    actions
  ),
  withFavouriteJob
)
