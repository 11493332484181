import React, { PureComponent } from 'react'
import { withNamespaces } from 'react-i18next'
import { Formik } from 'formik'
import { Link } from 'react-router-dom'

import { verifyPassword, signIn, root } from 'Routes/routesConstants'
import { arrows } from 'Assets/arrows'
import { icons } from 'Assets/icons'

import { getValidationSchema } from './config'

import {
  Background,
  ArrowBack,
  Registered,
  Logo,
  SubTitle,
  Form,
  Label,
  AuthInput,
  AuthFormWrapper,
  FormTitle,
  Error,
  InputWrapper
} from 'Components/ui'

import { BtnSubmitWrapper, BtnSubmit } from './styles'

class ForgotIdPass extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isRequest: false
    }
  }

  handleSubmit = data => {
    const { onForgotPassword } = this.props

    this.setState({
      isRequest: !this.state.isRequest
    })
    onForgotPassword(data.email, error => {
      if (error) {
        this.setState({
          isRequest: !this.state.isRequest
        })
      } else {
        this.setState({
          isRequest: !this.state.isRequest
        })
        this.props.history.push({
          pathname: verifyPassword,
          params: data.email
        })
      }
    })
  }

  render() {
    const { t } = this.props
    return (
      <Background>
        <Link to={root}>
          <Logo>
            SpotJobs<Registered>&reg;</Registered>
          </Logo>
          <SubTitle>{t('subTitle')}</SubTitle>
        </Link>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={getValidationSchema(t)}
          onSubmit={this.handleSubmit}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              submitCount
            } = props
            const isFormTouched = submitCount > 0
            return (
              <Form onSubmit={handleSubmit}>
                <FormTitle>
                  <ArrowBack to={signIn}>
                    <img alt="arrow" src={arrows.arrowBack} />
                  </ArrowBack>
                  {t('ForgotPass')}
                </FormTitle>
                <AuthFormWrapper>
                  <InputWrapper>
                    <Label htmlFor="email">{t('forgotId')}</Label>
                    <AuthInput
                      id="email"
                      placeholder={t('enterYourForgot')}
                      type="text"
                      value={values.email || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {errors.email && (touched.email || isFormTouched) && (
                      <Error>{errors.email}</Error>
                    )}
                  </InputWrapper>
                  {this.state.isRequest ? (
                    <BtnSubmitWrapper>
                      <BtnSubmit disabled>
                        <img alt="" src={icons.threeDotsLoader} />
                      </BtnSubmit>
                    </BtnSubmitWrapper>
                  ) : (
                    <BtnSubmitWrapper>
                      <BtnSubmit
                        isDisabled={Object.keys(errors).length !== 0}
                        type="submit"
                      >
                        {t('submit')}
                      </BtnSubmit>
                    </BtnSubmitWrapper>
                  )}
                </AuthFormWrapper>
              </Form>
            )
          }}
        </Formik>
      </Background>
    )
  }
}

export default withNamespaces('sign')(ForgotIdPass)
