import React from 'react'
import styled from 'styled-components'
import { sizes } from 'Constants'
import i18n from 'i18next'

import { AuthFormWrapper, Error, Label, ForgetLink } from 'Components/ui'

export const StyledAuthFormWrapper = styled(props => <AuthFormWrapper {...props} />)`
  @media only screen and (max-width: ${sizes.phone}px) {
    width: 100%;
  }
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  position: relative;
  @media (max-width: ${sizes.phone}px) {
    flex-direction: row-reverse;
  }
`

export const CountryCodeWrap = styled.div`
  position: absolute;
  z-index: 5;
  bottom: 51px;
  transform: translateY(-100%);
  width: 100%;
  @media only screen and (max-width: ${sizes.tablet}px) {
    /* bottom: 56px; */
  }
  @media only screen and (max-width: ${sizes.phone}px) {
    left: 0;
  }
  ${i18n.language === 'ta' || i18n.language === 'it' || i18n.language === 'ml' ?  ' top:12%; ' : ''}
`

export const TermsError = styled(props => <Error {...props} />)`
  margin-top: 6px;
  ${i18n.language === 'de' || i18n.language === 'ta' ? 'top: 37px; word-break: break-all; text-align: right; width: auto;' : 'top: 15px'}
`

export const TermsLabel = styled(props => <Label {...props} />)`
  justify-content: center;
  ${i18n.language === 'ta' ? 'margin-bottom: 25px' : 'margin-bottom: 0' }
`

export const TermsLink = styled(props => <ForgetLink {...props} />)`
  margin-left: 0;
`

export const BtnSubmitWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`

export const TextError = styled(props => <Error {...props} />)`
  width: max-content;
  ${i18n.language === 'ta' ? 'bottom: -3px' : 'bottom: 0'}
`
export const LabelPhone = styled(props => <Label {...props} />)`
  ${i18n.language === 'ta' || i18n.language === 'it' || i18n.language === 'ml' ? 'width: 90%; top: 2px' : ''}
`
