import styled from 'styled-components'
import { fontSize } from 'Constants'

export const Container = styled.div`
  position: absolute;
  top: 45px;
  transition: transform 0.2s, opacity 0.3s, z-index 0.3s;
  transform: translateY(${p => (p.isShow ? 0 : '-30px')});
  z-index: ${p => (p.isShow ? 3 : -1)};
  opacity: ${p => (p.isShow ? 1 : 0)};
  box-shadow: 0 0 10px silver;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  background-color: #fff;
  max-height: 400px;
`

export const SubContainer = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  box-shadow: 10px 0 10px silver;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: -1;
  max-height: 400px;
  background-color: #fff;
  opacity: 0;
  left: 90%;
  width: auto;
  pointer-events: none;
  transition: opacity 0.3s, left 0.3s;
`

export const Title = styled.span`
  font-size: ${fontSize.default};
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const SubServiceTitle = styled.span`
  font-size: ${fontSize.default};
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const Image = styled.img`
  max-height: 20px;
  margin-right: 20px;
`

export const SubServiceRow = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 3px 10px;
  border-bottom: solid 1px silver;
  transition: border-bottom-color 0.3s, color 0.3s;
  position: relative;
  &:hover ${SubServiceTitle} {
    color: ${p => p.theme.colors.joinUs};
  }
`

export const Row = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 3px 10px;
  border-bottom: solid 1px silver;
  transition: border-bottom-color 0.3s, color 0.3s;
  position: relative;
  &:hover ${Title} {
    color: ${p => p.theme.colors.joinUs};
  }
  &:hover ${SubContainer} {
    opacity: 1;
    left: 100%;
    z-index: 3;
    pointer-events: all;
  }
`

Row.displayName = 'Row'
SubServiceRow.displayName = 'SubServiceRow'