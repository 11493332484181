import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller'
import qs from 'qs'
import _ from 'lodash'
import { icons } from 'Assets/icons'
import { TABS_LIST_MAP } from 'Constants/tabNav'
import memoize from 'memoize-one'
import * as routes from 'Routes/routesConstants'

import { Switch, Route } from 'react-router-dom'
import {
  BreadCrumbs,
  Header,
  HelpCard,
  RightSidebar,
  Filter,
  BodySearch,
  Sort,
  Footer,
  BurgerMenu,
  HeaderTabs,
  ProgressBar,
  ListAd,
  FreelancerProfileView,
  RatingsProfile,
  MapView
} from 'Components/blocks'

import {
  PageWrapper,
  ContentWrapper,
  CardWrapper,
  RightSideBarWrapper,
  TopPart,
  DownPart,
  FiltersGroup,
  SSGroup,
  PreloadImg,
  LoadMoreWrapper
} from 'Components/ui'

import { Wrapper, GoogleAdsFirst } from './style'
import { sortData, path } from './config'
import { getProfileBC } from './memoize'

class FindHelp extends React.Component {
  constructor(props) {
    super(props)

    const state = {
      isRequest: true,
      providers: [],
      prevProvidersList: [],
      elements_per_page: 10,
      pageNumber: 0,
      order_by: 'distance',
      order: 'asc',
      keywords: _.get(props.location.search.slice(1), 'keywords', ''),
      isLoadMoreRequest: false,
      activeCategoriesIds: [],
      activeTabId: 'a',
      location: {
        location: undefined
      },
      hasMore: false
    }

    const queryService = _.get(
      qs.parse(props.location.search.slice(1)),
      'service'
    )

    if (queryService) state.activeCategoriesIds.push(queryService)

    this.state = state
  }

  componentDidMount() {
    const { location, onGetProvidersList, user } = this.props
    const { elements_per_page, pageNumber, order_by, order } = this.state
    const request = {
      elements_per_page,
      page_number: pageNumber,
      order_by,
      order,
      radius: user.defaultRadius || 10,
      geolocation: user.geolocation
    }

    const keywords = _.get(qs.parse(location.search.slice(1)), 'keywords')
    const service = _.get(qs.parse(location.search.slice(1)), 'service')

    if (_.isString(keywords)) request.keywords = keywords
    if (_.isString(service)) request.categories = JSON.stringify([service])

    onGetProvidersList(request, (error, response) => {
      if (response && response.isListEnd === false) {
        return this.setState({ isRequest: false, hasMore: true })
      }
      this.setState({ isRequest: false, hasMore: false})
    }, true)
  }

  onFilterModalSubmit = data => {
    if (data.clear) {
      this.setState({ location: {}, activeCategoriesIds: [] }, this.onFilter)
    } else this.onFilter(data)
  }

  onFilter = (data = {}) => {
    const { user, onGetProvidersList } = this.props
    const {
      elements_per_page,
      // pageNumber,
      order_by,
      order,
      keywords
    } = this.state

    let location = this.state.location
    if (!_.isEmpty(data.location)) {
      location = {
        ...data.locationFields,
        location: data.location
      }
    }
    this.setState({
      isRequest: true,
      activeCategoriesIds: _.isEmpty(data.services) ? [] : data.services,
      location,
      pageNumber: 0
    })

    const request = {
      elements_per_page: elements_per_page,
      page_number: 0,
      order_by: order_by,
      order: order,
      categories: _.isEmpty(data.services)
        ? undefined
        : JSON.stringify(data.services),
      keywords: keywords && keywords,
      geolocation: data.location ? data.location : null,
      radius: user.defaultRadius || 10
    }

    onGetProvidersList(request, (error, response) => {
      if (response && response.isListEnd === false) {
        return this.setState({ hasMore: true, isRequest: false})
      }
      this.setState({ isRequest: false })
    }, true)
  }

  handleClearKeywords = () => this.setState({ keywords: '' })

  handleTabClick = id => this.setState({ activeTabId: id })

  handleSearchByKeywords = keywords =>
    this.setState({ keywords }, this.onFilter)

  loadMoreItems = () => {
    const { onGetProvidersList, user } = this.props
    const {
      isLoadMoreRequest,
      elements_per_page,
      order_by,
      order,
      keywords,
      pageNumber
    } = this.state

    if (isLoadMoreRequest) return

    const request = {
      elements_per_page: elements_per_page,
      page_number: pageNumber + 1,
      order_by: order_by,
      order: order,
      keywords: keywords ? _.trim(keywords) : undefined,
      radius: 99999,
      geolocation: user.geolocation
    }

    this.setState({ isLoadMoreRequest: true, pageNumber: pageNumber + 1 })
    onGetProvidersList(request, (error, response) => {
      if (response && response.isListEnd === false) {
        return this.setState({ hasMore: true, isLoadMoreRequest: false})
      }
      this.setState({ hasMore: false, isLoadMoreRequest: false })
    })
  }

  onClickPopularService = ({ services }) => {
    const { activeCategoriesIds } = this.state

    const id = services[0]
    let result = []
    if (activeCategoriesIds.indexOf(id) === -1) {
      result = activeCategoriesIds.concat(services)
    } else {
      result = activeCategoriesIds.filter(el => el !== id)
    }

    this.onFilter({ services: result })
  }

  handleSorting = ({ order, orderBy }) => {
    this.setState({ order_by: orderBy, order }, this.onFilter)
  }

  handleDeleteClick = id => {
    const { onRemove } = this.props

    onRemove(id)
  }

  handleOpenReviews = profile => {
    const { history } = this.props

    history.push(
      `${routes.findHelp}/${profile.username}${routes.ratingProfile}`
    )
  }

  onHeaderFilter = ({ keywords, service }) => {
    const { history } = this.props
    history.replace(routes.findHelp)
    if (_.isString(keywords)) this.setState({ keywords }, this.onFilter)
    if (_.isString(service)) this.onFilter({ services: [service] })
  }

  renderMapCard = card => {
    const {
      onShare,
      onFavouriteSPClick,
      loadFavouriteSPId,
      onSendRequest
    } = this.props

    return (
      <HelpCard
        data={card}
        isLoadingFavourite={loadFavouriteSPId === card.username}
        onHandleFavouriteClick={memoize(() => onFavouriteSPClick(card))}
        onSendRequest={() => onSendRequest(card)}
        onShareClick={memoize(() => onShare(card))}
      />
    )
  }

  renderProfile = props => {
    const { t, profile, onSendRequest } = this.props
    const breadCrumbs = getProfileBC(
      t,
      profile.short_name
    )

    return (
      <>
        <HeaderTabs
          isHidden={true}
          options={TABS_LIST_MAP}
          onClickLink={this.handleTabClick}
        />
        <BreadCrumbs data={breadCrumbs} />
        <ContentWrapper>
          <Wrapper>
            <FreelancerProfileView
              comments={profile.reviewsCount}
              reviewProfileLink={`${routes.findHelp}/${profile.username}${routes.ratingProfile}`}
              onHire={() => onSendRequest(profile)}
            />
          </Wrapper>
        </ContentWrapper>
      </>
    )
  }

  renderReviewProfile = props => {
    const { t, profile, onSendRequest } = this.props
    const breadCrumbs = getProfileBC(
      t,
      profile.short_name
    )

    return (
      <>
        <HeaderTabs
          isHidden={true}
          options={TABS_LIST_MAP}
          onClickLink={this.handleTabClick}
        />
        <BreadCrumbs data={breadCrumbs} />
        <ContentWrapper>
          <Wrapper>
            <RatingsProfile
              currentProfile={profile}
              onHire={() => onSendRequest(profile)}
              {...props}
            />
          </Wrapper>
        </ContentWrapper>
      </>
    )
  }

  renderProfileList = () => {
    const {
      providers,
      onShare,
      onSendRequest,
      onFavouriteSPClick,
      loadFavouriteSPId,
    } = this.props
    const { isRequest, activeCategoriesIds, activeTabId, hasMore } = this.state

    if (activeTabId === 'b') {
      return (
        <MapView
          cardWidth="600px"
          data={providers.serviceProvidersList}
          extraData={[loadFavouriteSPId]}
          loading={isRequest}
          renderCard={this.renderMapCard}
        />
      )
    }

    return (
      <DownPart>
        <CardWrapper>
          {isRequest && _.isEmpty(providers.serviceProvidersList) ? (
            <PreloadImg alt="loading" src={icons.preload} />
          ) : (
            <InfiniteScroll
              hasMore={hasMore}
              loadMore={this.loadMoreItems}
              loader={
                <LoadMoreWrapper key={-1}>
                  <PreloadImg
                    alt="loading"
                    className="loader"
                    src={icons.preload}
                  />
                </LoadMoreWrapper>
              }
              useWindow={true}
            >
              <GoogleAdsFirst />
              {_.isEmpty(providers.serviceProvidersList)
                ? null
                : providers.serviceProvidersList.map((item, key) => {
                    return (
                      <Fragment key={key}>
                        <HelpCard
                          comments={item.reviewsCount}
                          data={item}
                          isLoadingFavourite={loadFavouriteSPId === item._id}
                          onCommentClick={() => this.handleOpenReviews(item)}
                          onHandleDeleteClick={this.handleDeleteClick}
                          onHandleFavouriteClick={() =>
                            onFavouriteSPClick(item)
                          }
                          onSendRequest={() => onSendRequest(item)}
                          onShareClick={() => onShare(item)}
                        />
                        <ListAd
                          data={providers.serviceProvidersList}
                          index={key}
                        />
                      </Fragment>
                    )
                  })}
              {!_.isEmpty(providers.serviceProvidersList) && <GoogleAdsFirst />}
            </InfiniteScroll>
          )}
        </CardWrapper>
        <RightSideBarWrapper>
          <RightSidebar
            activeCategoriesIds={activeCategoriesIds}
            handleFilter={this.onClickPopularService}
          />
        </RightSideBarWrapper>
      </DownPart>
    )
  }

  renderContent = props => {
    const { match, location, services } = this.props
    const {
      order_by: orderBy,
      location: savedLocation,
      activeCategoriesIds
    } = this.state

    return (
      <>
        <HeaderTabs options={TABS_LIST_MAP} onClickLink={this.handleTabClick} />
        <BreadCrumbs location={location} match={match} />
        <ContentWrapper>
          <TopPart>
            <FiltersGroup>
              <Filter
                handleFilter={this.onFilterModalSubmit}
                initialValues={{
                  location: savedLocation,
                  services: activeCategoriesIds
                }}
              />
              <SSGroup>
                <BodySearch
                  services={services.services}
                  onFocus={this.handleClearKeywords}
                  onSetService={service =>
                    this.onFilter({ services: [service] })
                  }
                  onSubmit={this.handleSearchByKeywords}
                />
                <Sort
                  data={sortData}
                  value={orderBy}
                  onSelect={this.handleSorting}
                />
              </SSGroup>
            </FiltersGroup>
          </TopPart>
          {this.renderProfileList()}
        </ContentWrapper>
      </>
    )
  }

  render() {
    const { user } = this.props
    const { isRequest } = this.state

    return (
      <PageWrapper>
        <ProgressBar percent={isRequest ? 1 : 100} />
        {user && <BurgerMenu user={user} />}
        <Header
          handleSearchHelp={this.onHeaderFilter}
          postJob={false}
          view={_.isEmpty(user)}
        />
        <Switch>
          <Route path={path.review} render={this.renderReviewProfile} />
          <Route path={path.profile} render={this.renderProfile} />
          <Route render={this.renderContent} />
        </Switch>
        <Footer black={true} isAuth={user} />
      </PageWrapper>
    )
  }
}

FindHelp.propTypes = {
  loadFavouriteSPId: PropTypes.string,
  location: PropTypes.object,
  match: PropTypes.object,
  profile: PropTypes.object,
  providers: PropTypes.object,
  services: PropTypes.object,
  t: PropTypes.func,
  user: PropTypes.object.isRequired,
  onFavouriteSPClick: PropTypes.func,
  onGetProvidersList: PropTypes.func,
  onRemove: PropTypes.func,
  onSendRequest: PropTypes.func,
  onShare: PropTypes.func
}

export default FindHelp
