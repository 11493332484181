import styled from 'styled-components'

export const Owerlay = styled.div`
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${p => p.theme.colors.Scorpion06};
  transition: all 0.4s;
  opacity: ${p => (p.isActive ? 1 : 0)};
  z-index: ${p => (p.isActive ? 1000 : -1)};
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
`

export const Content = styled.div`
  & > .image-gallery-slide > img {
    width: auto;
    height: 100vh;
  }
`
