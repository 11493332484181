import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { getGeocodeObg } from 'Helpers/maps'
import { icons } from 'Assets/icons'

import { Map as GoogleMap, Marker } from 'google-maps-react'
import { Ad } from 'Components/blocks'

import {
  Wrapper,
  MapWrapper,
  MapWrapperOverlay,
  MapWrapperOverlayText
} from './style'

class Tracker extends PureComponent {
  state = {
    center: null,
    zoom: 13,
    // myPosition: null,
    lastPosition: null,
    isLocationError: false
  }

  // watchId = null

  componentDidMount() {
    const { match, onLoadJob, onLoadProfile } = this.props
    const { jobId, userId } = match.params

    onLoadJob(jobId)
    onLoadProfile(userId)
    this.setTracking(jobId)
    // this.setWatcher()

    navigator.geolocation.getCurrentPosition(
      initialPosition => this.setState({ initialPosition }),
      error => {
        this.setState({ isLocationError: true })
      },
      { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
    )
    this.watchID = navigator.geolocation.watchPosition(lastPosition => {
      this.setState({ lastPosition })
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { geolocation, user, job, match } = this.props
    const { jobId } = match.params
    const { lastPosition } = this.state
    if (!_.isEmpty(job) && !_.isEmpty(user)) {
      if (job.author.username !== user.username) {
        if (!prevState.center && lastPosition !== prevState.lastPosition) {
          this.setState({
            center: {
              lat: lastPosition.coords.latitude,
              lng: lastPosition.coords.longitude
            }
          })
        }
      } else {
        // geolocation !== prevProps.geolocation &&
        if (!prevState.center && geolocation) {
          this.setState({ center: geolocation[jobId] || null })
        }
      }
    }
  }

  componentWillUnmount() {
    const { onUnsubscribeSpTrack, user, match } = this.props
    const { userId, jobId } = match.params
    navigator.geolocation.clearWatch(this.watchID)
    // navigator.geolocation.clearWatch(this.watchId)

    if (user.username !== userId) onUnsubscribeSpTrack(userId, jobId)
  }

  // setWatcher = () => {
  //   const { onTrackUpdate, user, match } = this.props
  //   const { userId } = match.params
  //   this.watchId = navigator.geolocation.watchPosition(({ coords }) => {
  //     const { latitude, longitude } = coords
  //     if (user.username === userId) {
  //       _.debounce(() => {
  //         const geolocation = {
  //           lat: latitude,
  //           lng: longitude
  //         }
  //         this.setState({ myPosition: geolocation })
  //         onTrackUpdate(JSON.stringify(geolocation))
  //       }, 300)()
  //     }
  //   })
  // }

  setTracking = (jobId) => {
    const { onStartTrack, match, user } = this.props
    const { userId } = match.params

    if (user.username !== userId) onStartTrack(userId, jobId)
  }

  renderDoerLocation(google, geolocation, user, job) {
    if (job.author.username === user.username) {
      if (geolocation[job._id]) {
        return (
          <Marker
            icon={{
              url: icons.marker,
              anchor: new google.maps.Point(20, 20),
              scaledSize: new google.maps.Size(50, 50)
            }}
            position={geolocation[job._id] || null}
          />
        )
      }
      return null
    }
  }

  render() {
    const { google, user, job, geolocation } = this.props
    const { zoom, lastPosition, center, isLocationError } = this.state
    const jobGeolocation = getGeocodeObg(_.get(job, 'geolocation'))

    if (center && !isLocationError) {
      return (
        <Wrapper>
          <Ad />
          <MapWrapper>
            <MapWrapperOverlay>
              <MapWrapperOverlayText>Hi</MapWrapperOverlayText>
            </MapWrapperOverlay>
            <GoogleMap
              center={center}
              google={google}
              initialCenter={center}
              zoom={zoom}
            >
              {jobGeolocation && <Marker position={jobGeolocation} />}
              {!_.isEmpty(job) &&
                !_.isEmpty(user) &&
                geolocation &&
                this.renderDoerLocation(google, geolocation, user, job)}
              {lastPosition && (
                <Marker
                  icon={{
                    url: icons.userMarker,
                    anchor: new google.maps.Point(20, 20),
                    scaledSize: new google.maps.Size(25, 25)
                  }}
                  position={{
                    lat: lastPosition.coords.latitude,
                    lng: lastPosition.coords.longitude
                  }}
                />
              )}
            </GoogleMap>
          </MapWrapper>
        </Wrapper>
      )
    }
    return (
      <Wrapper>
        <Ad />
        <MapWrapper>
          {!_.isEmpty(jobGeolocation) && (
            <GoogleMap
              center={center}
              google={google}
              initialCenter={jobGeolocation}
              zoom={zoom}
            >
              {jobGeolocation && <Marker position={jobGeolocation} />}
              {!_.isEmpty(job) &&
                !_.isEmpty(user) &&
                geolocation &&
                this.renderDoerLocation(google, geolocation, user, job)}
              {lastPosition && (
                <Marker
                  icon={{
                    url: icons.userMarker,
                    anchor: new google.maps.Point(20, 20),
                    scaledSize: new google.maps.Size(25, 25)
                  }}
                  position={{
                    lat: lastPosition.coords.latitude,
                    lng: lastPosition.coords.longitude
                  }}
                />
              )}
            </GoogleMap>
          )}
        </MapWrapper>
      </Wrapper>
    )
  }
}

Tracker.propTypes = {
  geolocation: PropTypes.object,
  google: PropTypes.object,
  job: PropTypes.object,
  loadJobId: PropTypes.string,
  loadProfileId: PropTypes.string,
  match: PropTypes.object,
  profile: PropTypes.object,
  user: PropTypes.object,
  onLoadJob: PropTypes.func,
  onLoadProfile: PropTypes.func,
  onStartTrack: PropTypes.func,
  onUnsubscribeSpTrack: PropTypes.func
}

export default Tracker
