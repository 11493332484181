import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlag } from '@fortawesome/free-solid-svg-icons'
import { fontFamily, sizes, fontSize } from 'Constants'

export const CodeList = styled.ul`
  width: ${props => (props.isProfile ? '263px' : '105px')};
  position: absolute;
  list-style: none;
  padding: ${props => (props.isProfile ? '4px 15px' : '5px')};
  border: 2px solid #eee;
  border-radius: 5px;
  min-height: ${props => (props.isProfile ? '46px' : '43px')};
  max-height: 250px;
  font-size: ${props => (props.isProfile ? fontSize.h5 : fontSize.default)};
  font-family: ${fontFamily};
  outline: none;
  ${props => props.isProfile && 'top: 1px'};
  appearance: none;
  background: #fff;
  margin: ${props => (props.isProfile ? '0' : '10px 0 20px 0')};
  z-index: 2;
  overflow-y: scroll; 
  //${props => props.select && 'bottom: 0'}
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: ${props => (props.isProfile ? '25px' : '21px')};
    right: ${props => (props.isProfile ? '20px' : '10px')};
    display: block;
    width: 2px;
    height: 8px;
    margin-top: ${props => (props.isProfile ? '-7px' : '-4px')};
    background: #555;
    border-radius: 3px;
    transition: All 0.3s;
  }
  &:after {
    right: ${props => (props.isProfile ? '25px' : '15px')};
    transform: ${props =>
      props.select ? 'rotate(45deg);' : 'rotate(135deg);'};
  }
  &:before {
    transform: ${props =>
      props.select ? 'rotate(-45deg);' : 'rotate(-135deg);'};
  }
  &.open {
    width: 338px;
    @media only screen  and (max-width: ${sizes.phone}px) {
      width: 100%;
    }
    li {
      width: 100%;
    }
  }

  @media only screen and (max-width: ${sizes.desktop}px) {
    width: 110px;
    &:before,
    &:after {
      right: 10px;
    }
    &:after {
      right: 15px;
    }
  }
  
  @media only screen  and (max-width: ${sizes.phone}px) {
    width: ${props => (props.isProfile ? '100px' : '85px')};
    
  }
  

 
`

export const PhoneLi = styled.li`
  padding: 10px 20px 10px 0;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  &:first-child {
    padding-top: 6px;
  }
  &:last-child {
    padding-bottom: 0;
  }
  @media only screen and (max-width: ${sizes.desktop}px) {
    padding: 10px 20px 10px 0;
  }
  span {
    margin-left:2px;
  }
`

export const PhoneCodeImg = styled.img`
  width: 25px;
  height: 18px;
  margin-bottom: 0;
`

export const CountryName = styled.span`
  text-align: center;
`

export const PhoneCode = styled.span`
  white-space: nowrap;
`

export const EmptyFlag = styled(FontAwesomeIcon).attrs(props => ({
  icon: faFlag,
  color: '#69bcf9'
}))``
