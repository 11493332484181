import styled from 'styled-components'

export const AuthBackground = styled.div`
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  height: 100vh;
  background: linear-gradient(
    to bottom,
    #1e5799 0%,
    #2989d8 0%,
    #207cca 15%,
    #fff 85%
  );
`

export const ProfileBackground = styled.div`
  min-height: 100vh;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;
  & a {
    text-decoration: none;
  }
  background: linear-gradient(
    to bottom,
    #1e5799 0%,
    #2989d8 0%,
    #207cca 15%,
    #fff 100%
  );
`
