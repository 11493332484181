import React from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'

import { Modal } from 'Components/ui'

import {
  Container,
  Header,
  Title,
  Buttons,
  SubmitButton,
  CancelButton
} from './style'

const ConfirmModal = ({
  isLoading,
  isVisible,
  onCancel,
  onSubmit,
  t,
  title,
  submitText
}) => (
  <Modal isShow={isVisible} zIndex={1010} onRequestClose={onCancel}>
    <Container>
      <Header>
        <Title>{title || t('applyForThisJob')}</Title>
      </Header>
      <Buttons>
        <CancelButton onClick={onCancel}>{t('jobPost:cancel')}</CancelButton>
        <SubmitButton disabled={isLoading} onClick={onSubmit}>
          {submitText || t('findJobPage:apply')}
        </SubmitButton>
      </Buttons>
    </Container>
  </Modal>
)

ConfirmModal.propTypes = {
  isLoading: PropTypes.bool,
  isVisible: PropTypes.bool,
  submitText: PropTypes.string,
  t: PropTypes.func,
  title: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
}

export default withNamespaces(['jobDetail', 'findJobPage', 'jobPost'])(
  ConfirmModal
)
