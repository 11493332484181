import React from 'react'
import Modal from 'react-modal'
import { Formik } from 'formik'

import {
  modalContentStyle,
  modalOverlayStyle,
  Footer,
  Btn,
  Input,
  FormContainer
} from './styles'

import { validationSchema } from './config'

const RenderForm = ({
  handleChange,
  handleBlur,
  values: valuesList,
  touched: touchedList,
  errors: errorsList,
  submitCount,
  handleSubmit,
  handleReset
}) => {
  const errors = errorsList || {}
  const values = valuesList || {}
  const touched = touchedList || {}
  
  return (
    <FormContainer onSubmit={handleSubmit}>
      <Input
        errors={errors.Link}
        label={'Link'}
        mr="15px"
        name="Link"
        touched={touched.Link}
        value={values.Link || ''}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <Footer>
        <Btn outline type="reset" onClick={handleReset}>
          Cancel
        </Btn>
        <Btn type="submit" onClick={handleSubmit}>
          Save
        </Btn>
      </Footer>
    </FormContainer>
  )
}

const handleNewLinkSubmit = data => {
  let newLink = data.Link
  if (!newLink.startsWith('http://') && !newLink.startsWith('https://')) {
    newLink = 'http://' + newLink
  }
  data.Link = newLink
  return data;
}

const AddLinkModal = ({ isOpen, onRequestClose, onCancel, onSubmit }) => {

  const handleCancel = () => onCancel()
  const handleSubmit = data => {
    onSubmit(handleNewLinkSubmit(data));
  }
  return (
    <Modal
      appElement={document.getElementById('root')}
      className="modal-form"
      isOpen={isOpen}
      style={{
        overlay: modalOverlayStyle,
        content: modalContentStyle
      }}
      onRequestClose={onRequestClose}
    >
      <Formik
        className="form-link"
        render={RenderForm}
        validationSchema={validationSchema}
        onReset={handleCancel}
        onSubmit={handleSubmit}
      />
    </Modal>
  )
}

export { AddLinkModal, RenderForm, handleNewLinkSubmit }
