import React from 'react'
import PropTypes from 'prop-types'

import { CustomRadioButton } from './style'

const Radio = ({ isChecked, onClick, ...props }) => (
  <CustomRadioButton checked={isChecked} onClick={onClick} {...props} />
)

Radio.propTypes = {
  isChecked: PropTypes.bool,
  onClick: PropTypes.func
}

export { Radio }
