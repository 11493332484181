import { object, string, ref } from 'yup'

export const formikProps = {
  mapPropsToValues: () => ({
    old: '',
    password: '',
    confirm: '',
    isRequest: false
  }),
  validationSchema: object().shape({
    old: string()
      .required('validation.password')
      .min(8, 'validation.passwordLength')
      .matches(/[a-z]/, 'validation.passwordLowerCase')
      .matches(/[A-Z]/, 'validation.passwordUpperCase')
      .matches(/[0-9]/, 'validation.passwordNumber')
      .matches(/[a-zA-Z0-9]+$/, 'validation.passwordLatin'),
    password: string()
      .required('validation.password')
      .min(8, 'validation.passwordLength')
      .matches(/[a-z]/, 'validation.passwordLowerCase')
      .matches(/[A-Z]/, 'validation.passwordUpperCase')
      .matches(/[0-9]/, 'validation.passwordNumber')
      .matches(/[a-zA-Z0-9]+$/, 'validation.passwordLatin'),
    confirm: string()
      .oneOf([ref('password'), null], 'validation.passwordsDoNotMuch')
      .required('validation.passwordConfirm')
  }),
  handleSubmit: (values, { setFieldValue }) => {
    // console.log('values: ', values)

    setFieldValue('isRequest', true)

    setTimeout(() => {
      setFieldValue('isRequest', false)
    }, 2000)
  }
}
