import * as routes from 'Routes/routesConstants'
import request from 'ajax-request'

export const path = {
  getRateReview: (jobId, reviewId) =>
    `${routes.myJobs}${routes.booked}/${jobId}${routes.ratingReview}${
      reviewId ? `?reviewId=${reviewId}` : ''
    }`
}

export const getImageBase64 = url => {
  return new Promise((resolve, reject) => {
    request({
      url: url,
      isBuffer: true
    }, (err, res, body) => {
      if (err) {
        reject(err);
      }
      console.log(res);
      const data = 'data:image/png;base64,' + body.toString('base64');
      if (data) {
        resolve(data)
      }
    })
  })
}
