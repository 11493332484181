import { createSelector, createStructuredSelector } from 'reselect'

const getState = state => state.user || {}

export const getProfile = createSelector(
  getState,
  ({ user }) => user
)

export const getFullLibraryData = createStructuredSelector({
  getProfile
})
