import React from 'react'
import _ from 'lodash'
import { withNamespaces } from 'react-i18next'

import Area from './Area'
import { Wrapper, AreaWrapper } from './styles'

const Component = props => {
  const { t, data } = props

  if (_.isEmpty(data)) return null

  return (
    <Wrapper>
      <h3>{t('areaIServices')}</h3>
      <AreaWrapper>
        {data &&
          data.map((service, key) => <Area key={key}>{service.title}</Area>)}
      </AreaWrapper>
    </Wrapper>
  )
}

export const AreaOfServices = withNamespaces('serviceProvider')(Component)
