import * as Yup from 'yup'
import profileLimits from 'Configs/profileLimits'

export const ALLOWED_IMG_EXTENSIONS = ['.jpg', 'jpeg', '.png']
export const MAX_IMG_SIZE = 5242880

export const getValidationSchema = (role, t) => {
  return Yup.object().shape({
    avatarB64: Yup.string(),
    firstName: Yup.string().required(
      t('fields.firstName.validationErrors.required')
    ),
    lastName: Yup.string().required(
      t('fields.lastName.validationErrors.required')
    ),
    birthdate: Yup.string().required(
      t('fields.birthdate.validationErrors.required')
    ),
    address: Yup.string().required(
      t('fields.address.validationErrors.required')
    ),
    city: Yup.string().required(t('fields.city.validationErrors.required')),
    state: Yup.string().required(t('fields.state.validationErrors.required')),
    zip: Yup.string().required(t('fields.zipCode.validationErrors.required')),
    country: Yup.string().required(
      t('fields.country.validationErrors.required')
    ),
    isProvider: Yup.bool()
      .default(false)
      .required(),
    aboutYourself: Yup.string().required(
      t('fields.aboutYourself.validationErrors.required')
    ),
    certificates: Yup.array().max(profileLimits.CERTIFICATES_MAX_COUNT[role]),
    picsOfWork: Yup.array().max(profileLimits.PICS_OF_WORK_MAX_COUNT[role]),
    idPics: Yup.array().max(profileLimits.ID_PICS_MAX_COUNT[role]),
    websiteLinks: Yup.array().max(profileLimits.WEBSITE_LINKS_MAX_COUNT[role]),
    videoLinks: Yup.array().max(profileLimits.VIDEO_LINKS_MAX_COUNT[role]),
    services: Yup.array().max(profileLimits.SERVICES_MAX_COUNT[role])
  })
}

export const PARSE_LOCATION_INFO = [
  {
    searchForType: 'country',
    putToField: 'country',
    pickField: item => item.long_name
  },
  {
    searchForType: 'postal_code',
    putToField: 'zip',
    pickField: item => item.long_name
  },
  {
    searchForType: 'administrative_area_level_1',
    putToField: 'state',
    pickField: item => item.long_name
  },
  {
    searchForType: 'locality',
    putToField: 'city',
    pickField: item => item.long_name
  }
]
