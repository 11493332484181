import React from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'

import { Modal } from 'Components/ui'

import {
  Container,
  Header,
  Title,
  Buttons,
  SubmitButton,
  CancelButton,
  Content,
  Block,
  Row,
  Text,
  Description,
  // MethodImage
  CloseIcon
} from './style'
// import { paymentMethods } from 'Constants'
// import { icons } from 'Assets/icons'
import { getServiceFees, getTax, getTotal } from 'Helpers/payments'

// const methods = [
//   // {
//   //   id: 1,
//   //   method: paymentMethods.credit,
//   //   icon: icons.creditCard
//   // },
//   {
//     id: 2,
//     method: paymentMethods.paypal,
//     icon: icons.paypalCard
//   }
// ]

const PaymentModal = ({
  isLoading,
  isVisible,
  onCancel,
  onSubmit,
  t,
  title,
  submitText,
  budget,
  serviceFees,
  // isJobDone,
  isAuthor
}) => {
  // const [paymentMethod, setPaymentMethod] = useState(paymentMethods.paypal)

  // const handleChangePaymentMethod = method => () => {
  //   if (method !== paymentMethod) {
  //     setPaymentMethod(method)
  //   }
  // }

  // const handleSubmit = () => onSubmit()

  // const renderMethod = () =>
  //   methods.map(({ id, method, icon }) => (
  //     <MethodImage
  //       key={id}
  //       active={paymentMethod === method}
  //       onClick={handleChangePaymentMethod(method)}
  //       url={icon}
  //     />
  //   ))

  return (
    <Modal
      isShow={isVisible}
      zIndex={1010}
      onRequestClose={onCancel}
      width="600px"
    >
      <Container>
        <Header>
          <Title>{title || t('profilePage:IDPayment')}</Title>
        </Header>
        <Content>
          <Row>
            <Description>
              {t('profilePage:paymenDescription')}
              {isAuthor && ` ${t('jobDetail:StartingTheJobTip')}`}
            </Description>
          </Row>
          <Block>
            <Row>
              <Text flex={1}>{t('profilePage:budget')}</Text>
              <Text isBold>$ {budget.toFixed(2)}</Text>
            </Row>
            <Row>
              <Text flex={1}>{t('profilePage:serviceFee')}</Text>
              <Text isBold>$ {getServiceFees(budget, serviceFees).toFixed(2)}</Text>
            </Row>
            <Row>
              <Text flex={1}>{t('jobDetail:processingFee')}</Text>
              <Text isBold>$ 0.30</Text>
            </Row>
            <Row>
              <Text flex={1}>+ 2.9% ({t('myJobsDetail:paypalFeeDescription')})</Text>
              <Text isBold>$ {getTax(budget, serviceFees).toFixed(2)}</Text>
            </Row>
            <Row>
              <Text flex={1}>{t('profilePage:newBalance')}</Text>
              <Text isBold>$ {getTotal(budget, serviceFees).toFixed(2)}</Text>
            </Row>
            {/* <Row>
              <Text flex={1}>{t('profilePage:recurring')}</Text>
              <Switch
                checked={isRecurring}
                checkedIcon={null}
                uncheckedIcon={null}
                onChange={handleChangeRecurring}
              />
            </Row>
            <Row>
              <Text flex={1}>{t('profilePage:method')}</Text>
              {renderMethod()}
            </Row> */}
          </Block>
        </Content>
        <Buttons>
          <CancelButton onClick={onCancel} disabled={isLoading}>
            {t('jobPost:cancel')}
          </CancelButton>
          <SubmitButton disabled={isLoading} onClick={onSubmit}>
            {submitText || t('profilePage:submit')}
          </SubmitButton>
        </Buttons>
      </Container>
      <CloseIcon onClick={onCancel}>×</CloseIcon>
    </Modal>
  )
}

PaymentModal.propTypes = {
  isLoading: PropTypes.bool,
  isVisible: PropTypes.bool,
  submitText: PropTypes.string,
  t: PropTypes.func,
  title: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  budget: PropTypes.number,
  serviceFees: PropTypes.number,
  isAuthor: PropTypes.bool
}

export default withNamespaces(['jobDetail', 'findJobPage', 'jobPost'])(
  PaymentModal
)
