const accepted = 'accepted'
const canceled = 'canceled'
const applied = 'applied'
const booked = 'booked'
const posted = 'posted'
const completed = 'completed'
const done = 'done'

export const jobStatuses = {
  accepted,
  canceled,
  applied,
  booked,
  posted,
  completed,
  done,
}
