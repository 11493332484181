import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { fontFamily } from 'Constants'

export const Container = styled(Link)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  width: 100%;
  padding: 10px 0;
  cursor: pointer;
  border-top: ${props => (props.isTop ? '1px solid #ddd' : 'none')};
`

export const Text = styled.span`
  flex: 1;
  font-size: 16px;
  color: #555;
  font-weight: bold;
  font-family: ${fontFamily};
`

export const Icon = styled.img``
