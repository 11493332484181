import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { withNamespaces } from 'react-i18next'

import { loadChatList } from 'Redux/actions/chats'

import { getChats } from 'Redux/selectors/chats'
import { getProfile } from 'Redux/selectors/user'

import Component from './Chats'

const actions = {
  onLoadChatList: loadChatList
}

const selectors = createStructuredSelector({
  user: getProfile,
  chats: getChats
})

export default compose(
  withNamespaces(['landingPage', 'homePage']),
  connect(
    selectors,
    actions
  )
)(Component)
