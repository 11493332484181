import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { gooogleApiKey } from 'Constants'

import { withJob, withServiceProviderProfile } from 'Components/hoc'
import { GoogleApiWrapper } from 'google-maps-react'

import { getProfile } from 'Redux/selectors/user'
import { getGeolocation } from 'Redux/selectors/track'
import {
  subscribeTrack,
  updateTrack,
  unsubscribeSpTrack
} from 'Redux/actions/track'

import Component from './Tracker'

const actions = {
  onStartTrack: subscribeTrack,
  onTrackUpdate: updateTrack,
  onUnsubscribeSpTrack: unsubscribeSpTrack
}

const selectors = createStructuredSelector({
  geolocation: getGeolocation,
  user: getProfile
})

export default compose(
  GoogleApiWrapper({
    apiKey: gooogleApiKey,
    libraries: ['places']
  }),
  withServiceProviderProfile,
  withJob,
  connect(
    selectors,
    actions
  )
)(Component)
