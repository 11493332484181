import React from 'react'
import styled from 'styled-components'
import { BtnSubmit as Btn } from 'Components/ui'

export const BtnSubmitWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`

export const BtnSubmit = styled(props => <Btn {...props} />)`
  margin-top: 5px;
`
