import React from 'react'
import { Social, SocialBtn } from './styles'

export const SocialButtons = ({ onHandleClick, disabled = false }) => {
  return (
    <Social>
      <SocialBtn
        disabled={disabled}
        style={{ background: '#3d5d97' }}
        onClick={() => onHandleClick('facebook')}
      >
        Facebook
      </SocialBtn>
      <SocialBtn
        disabled={disabled}
        style={{ background: '#cd5947' }}
        onClick={() => onHandleClick('google')}
      >
        Gmail
      </SocialBtn>
      <SocialBtn
        disabled={disabled}
        style={{ background: '#2567b3' }}
        onClick={() => onHandleClick('linkedin')}
      >
        LinkedIn
      </SocialBtn>
      <SocialBtn
        disabled={disabled}
        style={{ background: '#e0e0e0', color: '#000' }}
        onClick={() => onHandleClick('apple')}
      >
        Apple
      </SocialBtn>
    </Social>
  )
}
