import React from 'react'
import { withNamespaces } from 'react-i18next'
import _ from 'lodash'

import { RoundIcon, Premium, Avatar, Pro, Divider } from 'Components/ui'

import {
  Wrapper,
  HeaderWrapper,
  HeaderLeft,
  HeaderRight,
  BodyWrapper,
  InfoSection,
  InfoLeft,
  InfoRight,
  Comments,
  Star,
  ScrollWrap,
  Text,
  TextVerified,
  Row,
  Col,
  Distance
} from './styles'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCommentDots,
  faStar
} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { idVerificationStatus } from 'Constants/idVerification'

const Component = props => {
  const {
    id,
    isPremium,
    isPro,
    isVerified,
    stars,
    avatar,
    description,
    favouriteId,
    t,
    onFavouriteClick,
    isLoadingFavourite,
    onShareClick,
    data: { name, commentsLink, jobsDone, distance: distanceToJob, metric: unitsForJob },
    comments,
    reviewProfileLink
  } = props

  if (!id) return null

  return (
    <Wrapper>
      <HeaderWrapper>
        <HeaderLeft>
          <Avatar avatar={avatar} id={id} isVerified={isVerified === idVerificationStatus.success} />
          <Col>
            <Row>
              <span>{name}</span>
              {isPremium && <Premium />}
              {isPro && <Pro />}
            </Row>
            {isVerified === idVerificationStatus.success && (
              <TextVerified>{t('profilePage:identificationVerified')}</TextVerified>
            )}
          </Col>
        </HeaderLeft>
        <HeaderRight>
          {_.isFunction(onFavouriteClick) && (
            <RoundIcon
              favourite={favouriteId}
              icon={favouriteId ? ['fas', 'heart'] : ['far', 'heart']}
              id={id}
              loading={isLoadingFavourite}
              onClick={onFavouriteClick}
            />
          )}
          <RoundIcon icon="paper-plane" onClick={onShareClick} />
        </HeaderRight>
      </HeaderWrapper>
      <BodyWrapper>
        <InfoSection>
          <InfoLeft>
            <span>{jobsDone}</span>
            {t('serviceProvider:jobsDone')}
          </InfoLeft>
          <InfoRight>
            {stars && (
              <Star>
                <FontAwesomeIcon icon={faStar} />
                <span>{stars}</span>
              </Star>
            )}
            {commentsLink && (
              <Comments>
                <Divider />
                <Link to={commentsLink}>
                  <FontAwesomeIcon icon={faCommentDots} />
                  <span>{comments}</span>
                </Link>
              </Comments>
            )}
            {/* {distance && (
              <Distance>
                <Divider />
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                <span>
                  {distance} {t('distance', { count: distance })}
                </span>
              </Distance>
            )} */}

            <Comments>
              <Divider />
              <Link to={reviewProfileLink ? reviewProfileLink : '#'}>
                <FontAwesomeIcon icon={faCommentDots} />
                <span>{comments || 0}</span>
              </Link>
            </Comments>

            {distanceToJob && (
              <Distance>
                <Divider />
                <FontAwesomeIcon icon="map-marker-alt" />
                <span>
                  {distanceToJob} {t(`jobDetail:${unitsForJob}`)}
                </span>
              </Distance>
            )}
          </InfoRight>
        </InfoSection>
        {description && (
          <ScrollWrap>
            <Text>{description}</Text>
          </ScrollWrap>
        )}
      </BodyWrapper>
    </Wrapper>
  )
}

export const FreelanceInfo = withNamespaces([
  'freelancerProfile',
  'jobDetail',
  'serviceProvider'
])(Component)
