import React from 'react'
import { Link } from 'react-router-dom'

import { findHelp } from 'Routes/routesConstants'
import { Premium, Pro } from 'Components/ui'

import { Wrapper } from './styles'

export const Name = ({ id, name, isPremium = false, isPro = false }) => {
  return (
    <Wrapper>
      <Link to={`${findHelp}/${id}`}>
        <span>{name}</span>
      </Link>
      {isPremium && <Premium />}
      {isPro && <Pro />}
    </Wrapper>
  )
}
