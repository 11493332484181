import React from 'react'
import _ from 'lodash'
import { withNamespaces } from 'react-i18next'

import PHONES from 'Constants/phones'

import { CodeList, PhoneLi, PhoneCodeImg, CountryName } from './styles'

class Component extends React.Component {
  constructor(props) {
    super(props)
    const phones = Object.values(PHONES)
    const selectedCode =
      this.props.code &&
      _.find(phones, country => country.phone === this.props.code)

    this.state = {
      phones,
      isSelectedCode: true,
      selectedCode: selectedCode || phones[0]
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleSetCode = (item, setFieldValue) => {
    const newArrCountryCodes = _.orderBy(
      Object.values(PHONES),
      lang => lang.code === item.code,
      'desc'
    )
    setFieldValue('code', item.phone)
    this.setState({
      selectedCode: item,
      phones: newArrCountryCodes
    })
  }

  handleCodeListClick = () => {
    this.setState({
      isSelectedCode: !this.state.isSelectedCode
    })
  }

  handleCountryCode = setFieldValue => {
    return this.state.phones.map(item => {
      if (Array.isArray(item.phone)) {
        item.phone.map(phone => (
          <PhoneLi
            key={_.uniqueId('id_')}
            value={item.code}
            onClick={() => this.handleSetCode(item, setFieldValue)}
          >
            <PhoneCodeImg alt="flag" src={item.flag} />
            {this.props.isProfile && (
              <CountryName>{this.props.t(item.code)}</CountryName>
            )}
            <span>{phone}</span>
          </PhoneLi>
        ))
      }
      return (
        <PhoneLi
          key={_.uniqueId('id_')}
          value={item.code}
          onClick={() => this.handleSetCode(item, setFieldValue)}
        >
          <PhoneCodeImg alt="flag" src={item.flag} />
          {this.props.isProfile && (
            <CountryName>{this.props.t(item.code)}</CountryName>
          )}
          <span>{item.phone}</span>
        </PhoneLi>
      )
    })
  }

  setWrapperRef = node => {
    this.wrapperRef = node
  }

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      !this.state.isSelectedCode &&
        this.setState({ isSelectedCode: !this.state.isSelectedCode })
    }
  }

  render() {
    const {
      setFieldValue,
      value,
      handleBlur,
      handleChange,
      isProfile,
      t
    } = this.props
    const { isSelectedCode, selectedCode } = this.state

    const selectedCountryCode = isSelectedCode && (
      <PhoneLi value={selectedCode.code}>
        <PhoneCodeImg alt="flag" src={selectedCode.flag} />
        {this.props.isProfile && (
          <CountryName>{t(selectedCode.code)}</CountryName>
        )}
        {selectedCode.phone}
      </PhoneLi>
    )
    return (
      <CodeList
        className="code__list"
        isProfile={isProfile}
        name="code"
        ref={this.setWrapperRef}
        select={!this.state.isSelectedCode}
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
        onClick={this.handleCodeListClick}
      >
        {!isSelectedCode ? this.handleCountryCode(setFieldValue) : null}
        {selectedCountryCode}
      </CodeList>
    )
  }
}

export const PhoneList = withNamespaces('phoneCodes')(Component)
