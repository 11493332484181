import styled from 'styled-components'
import { fontFamily, fontSize } from 'Constants'
import { Link } from 'react-router-dom'

export const NotificationWrapper = styled(Link)`
  display: flex;
  box-shadow: ${props => props.theme.colors.boxShadow};
  margin-bottom: 15px;
  padding: 10px;
  align-items: center;
  position: relative;
  background: #fff;
`

export const JobImage = styled.img`
  border-radius: 5px;
  height: 55px;
  width: 55px;
  object-fit: cover;
`

export const TextPart = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-left: 10px;
  color: red;
  & > span {
    font-size: ${fontSize.small};
    font-family: ${fontFamily};
    margin-top: 5px;
    color: ${props => props.theme.colors.lightGrayText};
  }
`

export const Indicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 3%;
  transform: translateY(-50%);
  background: ${props => props.theme.colors.accentColor};
`
