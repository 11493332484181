import { combineReducers } from 'redux'
import services from './services'
import auth from './auth'
import user from './user'
import job from './job'
import favourites from './favourites'
import providers from './serviceProviders'
import postedJobs from './postedJobs'
import appliedJobs from './appliedJobs'
import bookedJobs from './bookedJobs'
import requests from './requests'
import socket from './socket'
import chats from './chats'
import notifications from './notifications'
import track from './track'
import settings from './settings'
import subscriptions from './subscriptions'
import review from './review'
import paymentsTransactions from './paymentsTransactions'

const rootReducer = combineReducers({
  auth,
  user,
  services,
  job,
  favourites,
  providers,
  postedJobs,
  appliedJobs,
  bookedJobs,
  requests,
  socket,
  chats,
  notifications,
  track,
  settings,
  subscriptions,
  review,
  paymentsTransactions
})

export default rootReducer
