import { library } from '@fortawesome/fontawesome-svg-core'
import arrowLeft from './arrow_back.svg'
import arrowHeader from './arrow_header.svg'
import camera from './camera.svg'
import avatar from './avatar.png'
import closeWhite from './closeWhite.svg'
import threeDotsLoader from './threeDotsLoader.svg'
import twitter from './twitter.svg'
import instagram from './instagram.svg'
import facebook from './facebook.svg'
import linkedin from './linkedin.svg'
import preload from './preload.svg'
import cone from './cone.svg'
import msg from './msg.svg'
import cloud from './cloud.svg'
import calendar from './31-calendar-day.svg'
import markMap from './location-point.svg'
import adsHome from './iphone-1451614_1280.png'
import marker from './iconfinder_park_372997.png'
import userMarker from './user-location.png'
import simCard from './simCard.png'
import bgCreditCard from './bgCreditCard.jpg'
import paypal from './paypal.svg'
import amexCard from './amex_card.png'
import creditCard from './credit_card.png'
import discoverCard from './discover_card.png'
import maestroCard from './maestro_card.png'
import masterCard from './master_card.png'
import paypalCard from './paypal_card.png'
import visaCard from './visa_card.png'
import edit from './edit.svg'
import idVerified from './idVerified.png'
import faceVerify from './face_verify.svg'
import documentVerify from './document_verify.svg'
import upload from './upload.png'
import cameraBlack from './camera.png'
import cameraMode from './camera_mode.png'
import donateButton from './donate_button.png'

import {
  faBell,
  faComments,
  faCrown,
  faCalendarAlt,
  faPaperPlane,
  faMapMarkerAlt,
  faSearch,
  faStar,
  faHeart as heart
} from '@fortawesome/free-solid-svg-icons'

import { faHeart } from '@fortawesome/free-regular-svg-icons'

library.add(
  faBell,
  faComments,
  faCrown,
  faCalendarAlt,
  faPaperPlane,
  faMapMarkerAlt,
  faHeart,
  faSearch,
  faStar,
  heart
)

export const icons = {
  arrowLeft,
  arrowHeader,
  camera,
  avatar,
  closeWhite,
  threeDotsLoader,
  twitter,
  instagram,
  facebook,
  linkedin,
  cone,
  msg,
  preload,
  cloud,
  calendar,
  markMap,
  adsHome,
  marker,
  userMarker,
  simCard,
  bgCreditCard,
  paypal,
  amexCard,
  creditCard,
  discoverCard,
  maestroCard,
  masterCard,
  paypalCard,
  visaCard,
  edit,
  idVerified,
  faceVerify,
  documentVerify,
  upload,
  cameraBlack,
  cameraMode,
  donateButton
}

export default icons
