import React from 'react'
import styled from 'styled-components'
import { Button } from 'Components/ui/Button'
import { Link } from 'react-router-dom'
import { sizes } from 'Constants'

export const HelpCardWrapper = styled.div`
  background: ${props => props.theme.colors.componentsBackground};
  margin-bottom: 20px;
  width: 100%;
  align-self: center;
  justify-self: center;
  position: relative;
  box-shadow: ${props => props.theme.colors.boxShadow};
  border-radius: 3px 3px 0 0;
  transition: box-shadow 0.3s;
  overflow: auto;
  &:hover {
    box-shadow: 0 0 10px #b1bcd4;
  }
  & a {
    text-decoration: none;
    color: ${props => props.theme.colors.defaultGrey};
    color: ${props => props.theme.colors.defaultFontBlack};
  }
`

export const TitleWrapper = styled.div`
  margin: 15px 20px 0 20px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > button {
    width: 100px;
    font-size: ${p => p.theme.fontSize.small};
    font-family: ${p => p.theme.fontFamily.default};
    text-transform: uppercase;
    margin: 0 0 0 10px;
    font-weight: 600;
  }
`
export const LeftGroup = styled.div`
  display: flex;
`
export const Avatar = styled.div`
  & > a > img {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    object-fit: cover;
  }
  & > div {
    position: absolute;
    top: 35px;
  }
  position: relative;
`

export const GeneralInfo = styled.div`
  margin-left: 10px;
  @media (max-width: ${sizes.phone}px) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`

export const NameGroup = styled.div`
  display: flex;
`

export const Span = styled.span`
  font-weight: bold;
  font-size: ${p => p.theme.fontSize.h4};
  cursor: pointer;
`

export const StarGroup = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  & span {
    margin-left: 10px;
  }
  @media (max-width: ${sizes.phone}px) {
    width: 250px;
    margin-top: 30px;
    margin-left: -25px;
    overflow: hidden;
  }
`

export const Comments = styled.div`
  display: flex;
  align-items: center;
  & > a > svg {
    & > path {
      fill: ${props => props.theme.colors.defaultGreen};
    }
  }
`

export const Distance = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    & > path {
      fill: ${props => props.theme.colors.accentColor};
    }
  }
`

export const SkillGroup = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;
  color: white;
  & > span {
    font-size: ${p => p.theme.fontSize.small};
    font-family: ${p => p.theme.fontFamily.default};
    margin-right: 15px;
    font-weight: bold;
    color: ${props => props.theme.colors.joinUs};
  }
  @media (max-width: ${sizes.phone}px) {
    width: 250px;
    margin-left: -25px;
    overflow: hidden;
  }
`

export const IconGroup = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-start;
  top: 20px;
  right: 40px;
  & > div {
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  & > div:last-child {
    margin-left: 10px;
  }
`

export const BodyWrapper = styled.div`
  margin: 10px 20px 5px 20px;
  display: flex;
  min-height: 100px;
  flex-wrap: nowrap;
  & > img {
    border-radius: 3px;
    width: 80px;
    height: 75px;
    object-fit: cover;
  }
`

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${p => p.theme.fontSize.small};
  font-family: ${p => p.theme.fontFamily.default};
  height: 100px;
  min-width: 50%;
  color: ${props => props.theme.colors.lightGrayText};
  margin-left: 15px;
  padding-bottom: 20px;
  & > p {
    font-size: ${p => p.theme.fontSize.h5};
    word-wrap: break-word;
    line-height: 1.5;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 0;
    padding-right: 140px;
  }
`

export const ButtonHire = styled(props => <Button {...props} />)`
  width: 130px;
  height: 50px;
  text-transform: uppercase;
  font-size: ${p => p.theme.fontSize.default};
  font-family: ${p => p.theme.fontFamily.default};
  font-weight: bold;
  letter-spacing: 2px;
  margin-top: 7px;
  position: absolute;
  bottom: 30px;
  right: 0;
  transform: translate(-20px, -5px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
`

// jobImages

export const MainImagesWrapper = styled.div`
  max-width: 50%;
  height: 80px;
  display: flex;
  flex-wrap: nowrap;
  @media (max-width: ${p => sizes.phone}px) {
    display: none;
  }
`

export const ImageWrapper = styled(Link)`
  & > div {
    color: white;
  }
  font-weight: 600;
  position: relative;
  font-size: ${p => p.theme.fontSize.h5};
  font-family: ${p => p.theme.fontFamily.default};
  margin-right: 5px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 #cbcbcb;
  & > img {
    width: 80px;
    border-radius: 6px;
    height: 100%;
    cursor: pointer;
    object-fit: cover;
  }
`

export const CountImage = styled.div`
  position: absolute;
  border-radius: 6px;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.55);
  width: 100%;
  height: 100%;
  cursor: pointer;
`
export const Delete = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  right: 10px;
  top: 10px;
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    right: 50%;
    width: 2px;
    height: 16px;
    background: black;
    transform: translate(50%, -50%) rotate(45deg);
  }
  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    right: 50%;
    width: 16px;
    height: 2px;
    background: black;
    transform: translate(50%, -50%) rotate(45deg);
    z-index: 1;
  }
`

export const TextVerified = styled.span`
  padding: 2px 10px;
  border-radius: 10px;
  color: ${props => props.theme.colors.joinButtonColor};
  background-color: ${props => props.theme.colors.defaultGreen};
  font-weight: bold;
  font-size: 12px;
`
