import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'
import _ from 'lodash'

import { OutsideClick } from 'Components/blocks/OutsideClick'
import {
  Container,
  Text,
  Popup,
  List,
  Row,
  Currency,
  Code,
  TextContainer
} from './style'

const currencies = require('../../../Constants/spotJobs-country-currencies/data.json')

class SelectCurrency extends PureComponent {
  state = {
    isVisible: false
  }

  handleShow = () => this.setState({ isVisible: true })

  handleHide = () => this.setState({ isVisible: false })

  handleSelect = code => {
    const { onSelect } = this.props
    this.handleHide()
    if (_.isFunction(onSelect) && _.isString(code)) onSelect(code)
  }

  _renderListItem = ({ currency, code }, key) => {
    const { t } = this.props
    return (
      <Row key={key} onClick={() => this.handleSelect(code)}>
        <Currency>{t(currency)}</Currency>
        <Code>{code}</Code>
      </Row>
    )
  }

  render() {
    const { value } = this.props
    const { isVisible } = this.state
    return (
      <Container>
        <TextContainer onClick={this.handleShow}>
          <Text>{value}</Text>
        </TextContainer>
        <Popup isVisible={isVisible}>
          <OutsideClick onOutsideClick={this.handleHide}>
            <List>{_.uniqBy(currencies, 'code').map(this._renderListItem)}</List>
          </OutsideClick>
        </Popup>
      </Container>
    )
  }
}

SelectCurrency.propTypes = {
  t: PropTypes.func,
  value: PropTypes.string,
  onSelect: PropTypes.func
}

export default withNamespaces(['currency'])(SelectCurrency)
