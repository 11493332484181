import styled from 'styled-components'
import { fontFamily, fontSize } from 'Constants'

export const ServicesAction = styled.div`
  writing-mode: vertical-rl;
  position: absolute;
  top: 0;
  right: 0;
  color: #888;
  font-size: ${fontSize.h3};
  font-family: ${fontFamily};
  font-weight: bold;
  cursor: pointer;
`

export const DropDownWrapper = styled.div`
  position: absolute;
  transform: rotate(-90deg);
  top: 4px;
  right: 32px;
  z-index: 2;
  width: 55px;
  height: 80px;
  box-shadow: ${props => props.theme.colors.boxShadow};
  font-size: ${fontSize.default};
  font-family: ${fontFamily};
  background: white;
  border-radius: 3px;
`

export const Item = styled.div`
  padding: 5px;
  &:hover {
    color: ${props => props.theme.colors.defaultFont};
    background: ${props => props.theme.colors.lightGrayText};
  }
  &:first-child {
    border-radius: 3px 3px 0 0;
  }
  &:last-child {
    border-radius: 0 0 3px 3px;
  }
`
