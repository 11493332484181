import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, isObject, get } from 'lodash'
import { icons } from 'Assets/icons'
import { toast } from 'react-toastify'

import {
  Msg,
  PreloadImg,
  PreloadWrap,
  ScrollChattingPart,
  ImagesModal
} from 'Components/ui'

import {
  AreaOfServices,
  FreelanceInfo,
  Gallery,
  VideosAndWebsitesLinks,
  Report,
  ReportModal
} from '../index'
import {
  BodyPart,
  ImagesPart,
  StyledButton,
  ApplicationButtons,
  AcceptButton,
  RejectButton
} from './style'
import { getFreelancerInfo, getModalImages } from './memoize'
import { Ad } from 'Components/blocks'

class FreelancerProfileView extends PureComponent {
  state = {
    isOpenImagesModal: false,
    isReportModalOpen: false,
    isRequesting: false
  }

  componentDidMount() {
    const { onLoadProfile, onResetError } = this.props
    onResetError()
    onLoadProfile()
  }

  componentDidUpdate() {
    const { error, onResetError, history } = this.props
    if (!isEmpty(error) && error.code && error.code === 'UserIsDisabled') {
      onResetError()
      history.goBack()
    }
  }

  handleCloseImagesModal = () => this.setState({ isOpenImagesModal: false })

  handleOpenImagesModal = () => this.setState({ isOpenImagesModal: true })

  handleThumbnailClick = (value, index) => this.imagesModal.slideToIndex(index)

  handleShareProfile = () => {
    const { profile, onShare } = this.props

    onShare(profile)
  }

  handleReportClick = () => this.setState({ isReportModalOpen: true })

  handleCloseReportModal = () => this.setState({ isReportModalOpen: false })

  handleSendReport = reason => {
    const { profile, onReport, t } = this.props

    onReport(profile.username, reason)
    this.setState({ isReportModalOpen: false }, () => {
      toast.success(t('common:reportWasSent'))
    })
  }

  _renderImagesModal = () => {
    const { profile } = this.props
    const { isOpenImagesModal } = this.state

    if (!profile || isEmpty(profile.picsOfWork)) return null

    const modalImages = getModalImages(profile.picsOfWork, profile.certificates, profile.username)

    return (
      <ImagesModal
        data={modalImages}
        visible={isOpenImagesModal}
        onClickOverflow={this.handleCloseImagesModal}
        onCreateRef={ref => (this.imagesModal = ref)}
      />
    )
  }

  _renderHireButton = () => {
    const {
      onHire,
      t,
      isDisabledApplyButton,
      profile,
      user,
      application
    } = this.props

    if (
      isDisabledApplyButton ||
      profile.username === user.username ||
      isObject(application)
    )
      return null

    return (
      <StyledButton height="50px" width="100%" onClick={onHire}>
        {t('hireNow')}
      </StyledButton>
    )
  }

  _handelAcceptButton = () => {
    const { application, onAcceptApplication, onHireApplication } = this.props
    if (application.status === 'applied') {
      onAcceptApplication()
    } else {
      onHireApplication()
    }
  }

  _renderApplicationButtons = () => {
    const {
      application,
      t,
      onRejectApplication,
      isLoadingApplication,
      isMyJob
    } = this.props

    if (!isObject(application) || !isMyJob) return null

    return (
      <ApplicationButtons>
        <RejectButton
          disabled={isLoadingApplication}
          onClick={onRejectApplication}
        >
          {t('appServiceProvider:reject')}
        </RejectButton>
        <AcceptButton
          disabled={isLoadingApplication}
          onClick={this._handelAcceptButton}
        >
          {application.status === 'applied'
            ? t('appServiceProvider:accept')
            : t('hireNow')}
        </AcceptButton>
      </ApplicationButtons>
    )
  }

  _renderChatButton = () => {
    const {
      profile,
      user,
      history,
      loadChatUsername,
      application: { user: currentUserView } = {},
      isMyJob,
      job: { jobDescription }
    } = this.props

    if (user.username === get(profile, 'username') || !isMyJob) return null
    const jobByUser = jobDescription?.peopleWhoApplied.find(({ userId }) => userId === currentUserView?._id) || {}
    const showChatButton = jobByUser.status === 'accepted'

    if (!showChatButton) {
      return null
    }

    return (
      <Msg
        isRequest={Boolean(loadChatUsername)}
        onClick={() => history.push(`/chats/${jobDescription._id}#${jobByUser.user.chatId || ''}`)}
      />
    )
  }

  _renderReportModal = () => {
    const { isReportModalOpen } = this.state

    return (
      <ReportModal
        isShow={isReportModalOpen}
        onClose={this.handleCloseReportModal}
        onSubmit={this.handleSendReport}
      />
    )
  }

  render() {
    const {
      comments,
      profile,
      isLoadProfile,
      isLoadingFavourite,
      onFavouriteClick,
      user,
      onConnectToChat,
      reviewProfileLink
    } = this.props

    const freelancerInfo = profile && getFreelancerInfo(profile)
    let profilePics = []
    if (!isLoadProfile && !isEmpty(profile)) {
      if (!isEmpty(profile.picsOfWork)) {
        profilePics.push(...profile.picsOfWork)
      }
      if (!isEmpty(profile.certificates)) {
        profilePics.push(...profile.certificates)
      }
    }

    return (
      <>
        {!isLoadProfile && !isEmpty(profile) ? (
          <BodyPart>
            <ImagesPart>
              {!isEmpty(profilePics) && (
                <Gallery
                  anotherTypeImagesArrLength={
                    !isEmpty(profile.picsOfWork)
                      ? profile.picsOfWork.length
                      : null
                  }
                  id={profile.username}
                  images={profilePics}
                  isFreelancerProfile
                  onClickFullScreen={this.handleOpenImagesModal}
                  onThumbnailClick={this.handleThumbnailClick}
                />
              )}
              {!isEmpty(profile.videoLinks) && (
                <VideosAndWebsitesLinks
                  data={profile.videoLinks}
                  title="videoLinks"
                />
              )}
              {!isEmpty(profile.websiteLinks) && (
                <VideosAndWebsitesLinks
                  data={profile.websiteLinks}
                  title="websiteLinks"
                />
              )}
            </ImagesPart>
            <ScrollChattingPart>
              <Ad />
              <FreelanceInfo
                avatar={profile.avatarURL}
                comments={comments}
                data={freelancerInfo}
                description={profile.about}
                distanceToJob={
                  !isEmpty(user) && !isEmpty(profile.distance)
                    ? profile.distance.length
                    : null
                }
                unitsForJob={
                  !isEmpty(user) && !isEmpty(profile.distance)
                    ? profile.distance.units
                    : null
                }
                reviewProfileLink={reviewProfileLink}
                favouriteId={profile.favouriteId}
                id={profile.username}
                isLoadingFavourite={isLoadingFavourite}
                isPremium={profile.isPremium}
                isPro={profile.isPro}
                isVerified={profile.id_verification}
                stars={profile.rate}
                onConnectToChat={onConnectToChat}
                onFavouriteClick={
                  user.username !== get(profile, 'username') &&
                  onFavouriteClick
                }
                onShareClick={this.handleShareProfile}
              />
              <AreaOfServices data={profile.services} />
              {/*<Ad />*/}

              <Report
                isVisible={profile.username !== user.username}
                name={freelancerInfo.name}
                onClick={this.handleReportClick}
              />
              {this._renderApplicationButtons()}
              {this._renderHireButton()}
              {this._renderChatButton()}
            </ScrollChattingPart>
          </BodyPart>
        ) : (
            <PreloadWrap>
              <PreloadImg alt="loading" src={icons.preload} />
            </PreloadWrap>
          )}
        {this._renderImagesModal()}
        {this._renderReportModal()}
      </>
    )
  }
}

FreelancerProfileView.propTypes = {
  application: PropTypes.object,
  isDisabledApplyButton: PropTypes.bool,
  isLoadProfile: PropTypes.bool,
  isLoadingApplication: PropTypes.bool,
  isLoadingFavourite: PropTypes.bool,
  isMyJob: PropTypes.bool,
  job: PropTypes.object,
  loadChatUsername: PropTypes.string,
  loadProfileId: PropTypes.string,
  profile: PropTypes.object,
  t: PropTypes.func.isRequired,
  user: PropTypes.object,
  onAcceptApplication: PropTypes.func,
  onConnectToChat: PropTypes.func,
  onFavouriteClick: PropTypes.func,
  onHire: PropTypes.func,
  onHireApplication: PropTypes.func,
  onLoadProfile: PropTypes.func,
  onRejectApplication: PropTypes.func,
  onShare: PropTypes.func,
  error: PropTypes.object,
  onResetError: PropTypes.func
}

export default FreelancerProfileView
