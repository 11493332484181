export const fontSize = {
  h1: '30px',
  h2: '25px',
  h3: '20px',
  h4: '18px',
  h5: '16px',
  large: '40px',
  default: '14px',
  small: '12px'
}
