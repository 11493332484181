import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { withRouter } from 'react-router-dom'

import { getProfile } from 'Redux/selectors/user'

import Component from './CustomerInfo'

const actions = {}

const selectors = createStructuredSelector({
  user: getProfile
})

export default compose(
  withRouter,
  connect(
    selectors,
    actions
  )
)(Component)
