import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { CustomerInfo } from '../index'
import { getCustomerInfo } from './memoize'

class DoerInfo extends PureComponent {
  componentDidMount() {
    const { onLoadProfile, userId, jobId } = this.props
    onLoadProfile(userId, null, jobId)
  }

  render() {
    const { profile, t, onReport, isNotAuthor, userId } = this.props
    if (!profile) return null
    const data = getCustomerInfo(profile)
    return (
      <CustomerInfo
        data={data}
        isNotAuthor={isNotAuthor}
        title={t('serviceProvider')}
        usernameId={userId}
        onReport={onReport}
      />
    )
  }
}

DoerInfo.propTypes = {
  loadProfileId: PropTypes.string,
  profile: PropTypes.object,
  t: PropTypes.func,
  userId: PropTypes.string,
  onLoadProfile: PropTypes.func
}

export default DoerInfo
