import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import * as routes from 'Routes/routesConstants.js'
import { jobStatuses } from 'Constants/jobStatuses.js'
import memoize from 'memoize-one'
import { isBookedPage } from 'Helpers/jobs'
import image from 'Helpers/jobImage'
import { createCalendarEvent } from 'Helpers/googleCalendar'
import { toast } from 'react-toastify'
import moment from 'moment'

import {
  ImagesModal,
  Msg,
  ScrollChattingPart,
  PreloadImg,
  LoadMoreWrapper
} from 'Components/ui'
import {
  Ad,
  CategoryInfo,
  CustomerInfo,
  Gallery,
  VideosAndWebsitesLinks,
  WhoApplied,
  JobDescription,
  MapModal,
  ProgressBar,
  DoerInfo
} from '../index'

import { ReportModal, RecurringModal } from 'Components/blocks'

import {
  BodyPart,
  ImagesPart,
  StyledButton,
  VideosAndWebsitesLinksBlock,
  IconWrapper,
  IconSection,
  Line,
  MarkButton,
  RateButton,
  IconText,
  CalendarIcon,
  MapMarkerIcon,
  CancelButton,
  RequestButtonsWrapper,
  RejectButton,
  AcceptButton,
  StartButton,
  DisputeButton,
  ImgLoading
} from './styles'
import { icons } from 'Assets/icons'
import { path, getImageBase64 } from './config'
import { getCategoryInfo, getCustomerInfo, getJobDescr } from './memoize'
import ImagesManager from 'Services/Images/ImagesManager'
import { imageTypes } from 'Services/Constants/images.constants'
import { getJobImage } from 'Helpers/getImageUri'
import shortId from 'shortid'

class JobView extends PureComponent {
  constructor(props) {
    super(props)

    const { user } = props

    this.state = {
      isFavouriteLoading: false,
      isShowImagesModal: false,
      isRequest: true,
      inOpenMapModal: false,
      isResponceOpen: false,
      isCustumerReport: false,
      isStaringTheJobRequest: false,
      isShowRecurringModal: false,
      isMarkJobDone: false,
      isRecurring: false,
      dateCompleted: null,
      application: null,
      isMarkingJob: false
    }

    this.isBookedJobs = memoize(isBookedPage)
    this.isOwnVacancy = memoize(job => {
      const author = _.get(job, 'author.username', job.author)
      return author === user.username
    })
  }

  componentDidMount() {
    const {
      onGetJobDescription,
      match,
      history,
      jobId,
      location,
      user,
      t
    } = this.props
    if (_.isEmpty(user)) {
      toast.warn(t('apiErrors:notVerifiedLogin'))
      if (location?.key) {
        return history.goBack()
      }
      return history.push(routes.signIn)
    }
    onGetJobDescription(_.get(match, 'params.jobId', jobId), error => {
      this.setState({ isRequest: false })
      if (error) history.goBack()
    })
  }

  activeImageIndex = 0

  handleFavouriteClick = (id, favourite) => {
    const { onRemoveFromFavourites, onAddToFavourites } = this.props
    this.setState({ isFavouriteLoading: true })
    if (favourite) {
      onRemoveFromFavourites(favourite, () =>
        this.setState({ isFavouriteLoading: false })
      )
    } else {
      onAddToFavourites(id, () => this.setState({ isFavouriteLoading: false }))
    }
  }

  handleThumbnailClick = (value, index) => {
    this.activeImageIndex = index
    this.imagesModal.slideToIndex(index)
  }

  handleFullScreenClick = () => this.setState({ isShowImagesModal: true })

  handleCloseFullScreen = () => this.setState({ isShowImagesModal: false })

  handleShowMap = () => this.setState({ inOpenMapModal: true })

  handleHideMap = () => this.setState({ inOpenMapModal: false })

  handleReviewClick = () => {
    const { history, job, user } = this.props
    const review = (job.review || []).find(el => el.author === user.username)
    history.push(path.getRateReview(job._id, _.get(review, '_id')))
  }

  handleAddCalendarEvent = () => {
    const { job, user } = this.props

    createCalendarEvent(job, user)
  }

  handleApplyClick = () => {
    const { job, onApplyJob } = this.props

    onApplyJob(job)
  }

  handleOpenApplyProfile = application => {
    const { onOpenApplyByProfile } = this.props

    onOpenApplyByProfile(application.user, _.omit(application, 'user'))
  }

  handleChatClick = () => {
    const { job, history, user } = this.props
    const { peopleWhoApplied = [] } = job
    const jobByUser =
      peopleWhoApplied.find(item => item.userId === user._id) || {}

    if (this.isBookedJobs(job) && job.author.username === user.username) {
      history.push(`/chats/${job._id}#${job.chatId}`)
    } else {
      history.push(`/chats/${job._id}#${jobByUser?.user?.chatId || ''}`)
    }
  }

  handleReport = isCustumer => {
    const { isResponceOpen } = this.state
    if (isCustumer) {
      this.setState({ isResponceOpen: !isResponceOpen, isCustumerReport: true })
    } else {
      this.setState({ isResponceOpen: !isResponceOpen })
    }
  }

  handleSubmitReport = reason => {
    const { job, onReport, user } = this.props
    const { isResponceOpen, isCustumerReport } = this.state

    onReport(isCustumerReport ? user._id : job.doer, reason)
    if (isCustumerReport) {
      this.setState({
        isResponceOpen: !isResponceOpen,
        isCustumerReport: false
      })
    } else {
      this.setState({ isResponceOpen: !isResponceOpen })
    }
  }

  handleStartingJob = () => {
    const { onStartingTheJob } = this.props
    this.setState({ isStaringTheJobRequest: true })
    onStartingTheJob(() => {
      this.setState({ isStaringTheJobRequest: false })
    })
  }

  handleDispute = () => this.props.onDisputeJob()

  _renderApplyButton = () => {
    const { t, job, user } = this.props

    const request = _.get(job, 'jobRequests[0]')

    if (
      this.isBookedJobs(job) ||
      this.isOwnVacancy(job) ||
      job.applicationId ||
      _.get(job, 'peopleWhoApplied', []).some(
        el => el.userId === user.username
      ) ||
      request
    )
      return null

    return (
      <StyledButton height="50px" width="100%" onClick={this.handleApplyClick}>
        {t('apply')}
      </StyledButton>
    )
  }

  _renderImagesModal = () => {
    const { job } = this.props
    const { isShowImagesModal } = this.state

    const result = _.get(job, 'pics', []).map(url => ({
      original: image(job._id, url),
      thumbnail: image(job._id, url),
      size: 0.1
    }))

    return (
      <ImagesModal
        data={result}
        startIndex={this.activeImageIndex}
        visible={isShowImagesModal}
        onClickOverflow={this.handleCloseFullScreen}
        onCreateRef={ref => (this.imagesModal = ref)}
      />
    )
  }

  _renderBookedActions = () => {
    const { t, job, onStartTracking, user, geoLocation } = this.props
    const { isStaringTheJobRequest, isMarkingJob } = this.state

    if (!this.isBookedJobs(job)) return null

    const isOwnVacancy = this.isOwnVacancy(job)

    const disableCalendar = job.jobStatus !== jobStatuses.booked
    const enableRate =
      job.jobStatus === jobStatuses.done ||
      (job.author?.username !== user?.username &&
        job.jobStatus === jobStatuses.completed)
    const disabledComplete = job?.isPaymentViaOurService
      ? job.jobStatus === jobStatuses.done ||
        (!isOwnVacancy &&
          (job.jobStatus === jobStatuses.completed || job.dateDoerCancel)) ||
        this.state.loadMarkJobId ||
        !job.authorStartJob ||
        !job.doerStartJob
      : job.jobStatus === jobStatuses.done ||
        (!isOwnVacancy && job.jobStatus === jobStatuses.completed) ||
        this.state.loadMarkJobId

    const disabledStartingJob =
      (job.author?.username === user?.username && job.authorStartJob) ||
      (job.author?.username !== user?.username && job.doerStartJob) ||
      (job.author?.username !== user?.username &&
        job.jobStatus === jobStatuses.completed) ||
      job.jobStatus === jobStatuses.done ||
      isStaringTheJobRequest

    return (
      <>
        <IconWrapper>
          <IconSection
            disabled={disableCalendar}
            onClick={this.handleAddCalendarEvent}
          >
            <CalendarIcon />
            <IconText>{t('myJobsDetail:googleCalendar')}</IconText>
          </IconSection>
          <Line />
          <IconSection onClick={() => onStartTracking(job, geoLocation)}>
            <MapMarkerIcon />
            <IconText>{t('myJobsDetail:trackServiceProvider')}</IconText>
          </IconSection>
        </IconWrapper>

        {job?.isPaymentViaOurService ? (
          <StartButton
            disabled={disabledStartingJob}
            onClick={this.handleStartingJob}
          >
            {isStaringTheJobRequest ? (
              <ImgLoading alt="" src={icons.threeDotsLoader} />
            ) : (
              t('jobDetail:startingTheJob')
            )}
          </StartButton>
        ) : null}

        <MarkButton disabled={disabledComplete} onClick={this._onMarkJob}>
          {isMarkingJob ? (
            <ImgLoading alt="" src={icons.threeDotsLoader} />
          ) : isOwnVacancy ? (
            t('jobDetail:markAsDone')
          ) : (
            t('markJobAsCompleted')
          )}
        </MarkButton>

        <RateButton disabled={!enableRate} onClick={this.handleReviewClick}>
          {t('rateReview')}
        </RateButton>
      </>
    )
  }

  _renderRequestButtons = () => {
    const {
      t,
      onRejectRequest,
      job,
      onAcceptRequest,
      location,
      isSubmitting,
      isRejecting
    } = this.props

    const request = _.get(job, 'jobRequests[0]')
    if (
      !_.isObject(request) ||
      !_.isFunction(onRejectRequest) ||
      !_.isFunction(onAcceptRequest) ||
      location.pathname.includes(routes.applied)
    ) {
      return null
    }

    return (
      <RequestButtonsWrapper>
        <RejectButton onClick={onRejectRequest} disabled={isRejecting}>
          {isRejecting ? (
            <img alt="" src={icons.threeDotsLoader} />
          ) : (
            t('appServiceProvider:reject')
          )}
        </RejectButton>
        <AcceptButton onClick={onAcceptRequest} disabled={isSubmitting}>
          {isSubmitting ? (
            <img alt="" src={icons.threeDotsLoader} />
          ) : (
            t('appServiceProvider:accept')
          )}
        </AcceptButton>
      </RequestButtonsWrapper>
    )
  }

  _renderCancelButton = () => {
    const { t, job, onCancelClick, user } = this.props
    const { dateCompleted } = this.state

    const disabled = job?.isPaymentViaOurService
      ? (job.jobStatus === jobStatuses.completed &&
          job.author?.username === user?.username) ||
        job.jobStatus === jobStatuses.done ||
        (job?.dateDoerCancel && !this.isOwnVacancy(job)) ||
        (job?.dateAuthorCancel &&
          moment(job?.dateAuthorCancel).isValid() &&
          this.isOwnVacancy(job) &&
          job?.authorStartJob &&
          job?.doerStartJob)
      : job.jobStatus === jobStatuses.completed ||
        job.jobStatus === jobStatuses.done

    if (!_.isFunction(onCancelClick) && !disabled) return null

    if (!job?.isPaymentViaOurService) {
      return (
        <CancelButton disabled={disabled} onClick={onCancelClick}>
          {t('myJobsDetail:cancelTheJob')?.toUpperCase()}
        </CancelButton>
      )
    }

    if (
      job?.dateAuthorCancel &&
      moment(job.dateAuthorCancel).isValid() &&
      this.isOwnVacancy(job) &&
      job?.authorStartJob &&
      job?.doerStartJob
    ) {
      return (
        <CancelButton disabled jobClose>
          {moment.utc(job.dateAuthorCancel).format('MMMM DD YYYY')} -{' '}
          {t('myJobsDetail:jobCompletedClose')}
        </CancelButton>
      )
    }

    if (
      job?.dateDoerCancel &&
      moment(job.dateDoerCancel).isValid() &&
      !this.isOwnVacancy(job) &&
      job?.authorStartJob &&
      job?.doerStartJob
    ) {
      return (
        <CancelButton disabled jobClose>
          {moment.utc(job.dateDoerCancel).format('MMMM DD YYYY')} -{' '}
          {t('myJobsDetail:jobCompletedClose')}
        </CancelButton>
      )
    }

    if (
      (job?.dateCompleted &&
        moment(job.dateCompleted).isValid() &&
        !this.isOwnVacancy(job) &&
        job?.jobStatus === jobStatuses.completed &&
        job?.authorStartJob &&
        job?.doerStartJob) ||
      dateCompleted
    ) {
      return (
        <CancelButton disabled={disabled} jobClose onClick={onCancelClick}>
          {moment
            .utc(job.dateCompleted || dateCompleted)
            .format('MMMM DD YYYY')}{' '}
          - {t('myJobsDetail:jobCompletedClose')}
        </CancelButton>
      )
    }

    if (job?.isExpired) {
      return (
        <CancelButton disabled jobClose>
          {moment.utc(new Date()).format('MMMM DD YYYY')} -{' '}
          {t('myJobsDetail:jobCancelClose')}
        </CancelButton>
      )
    }

    return (
      <CancelButton disabled={disabled} onClick={onCancelClick}>
        {t('myJobsDetail:cancelTheJob')?.toUpperCase()}
      </CancelButton>
    )
  }

  _renderChatButton = () => {
    const { user, job, loadChatUsername } = this.props
    const { peopleWhoApplied = [] } = job

    const jobByUser =
      peopleWhoApplied.find(item => item.userId === user._id) || {}
    const showChatButton =
      jobByUser.status === 'accepted' ||
      (this.isBookedJobs(job) && job.author.username === user.username)

    if (showChatButton) {
      return (
        <Msg
          isRequest={Boolean(loadChatUsername)}
          onClick={this.handleChatClick}
        />
      )
    }

    return null
  }

  _renderCustomerInfo = () => {
    const { job } = this.props
    const isBookedJob = this.isBookedJobs(job)
    const usernameId = job?.author?.username
    if (isBookedJob)
      return (
        <DoerInfo
          isNotAuthor={job.author !== job.doer}
          userId={job.doer}
          onReport={this.handleReport}
          jobId={job._id}
        />
      )
    else if (_.get(job, 'author')) {
      const customerInfo = getCustomerInfo(job.author)
      return (
        <CustomerInfo
          data={customerInfo}
          usernameId={usernameId}
          key={1}
          onReport={() => this.handleReport(true)}
        />
      )
    }

    return null
  }

  _renderReportModal = () => {
    const { isResponceOpen } = this.state
    return (
      <ReportModal
        isShow={isResponceOpen}
        onClose={this.handleReport}
        onSubmit={this.handleSubmitReport}
      />
    )
  }

  _renderDisputeButton = () => {
    const { job, t } = this.props
    if (!this.isBookedJobs(job) || !job?.isPaymentViaOurService) return null

    const disabled =
      !job.authorStartJob ||
      !job.doerStartJob ||
      job.jobStatus === jobStatuses.done ||
      job?.authorDispute ||
      job?.doerDispute

    return (
      <DisputeButton disabled={disabled} onClick={this.handleDispute}>
        {t('jobDetail:dispute')}
      </DisputeButton>
    )
  }

  _renderContent = () => {
    const {
      user,
      job: data = {},
      onAppliedByClick,
      onShare,
      onEditClick,
      onDeleteClick,
      onOpenPeopleWhoApplied,
      onOpenApplyByProfile
    } = this.props
    const { isFavouriteLoading } = this.state

    const isUserJob = _.get(data, 'author.username') === user.username

    const author = _.get(data, 'author')

    const customerInfo = author && getCustomerInfo(author)

    const categoryInfo = getCategoryInfo(data)

    const jobDescr = getJobDescr(data)

    const isBookedJobs = this.isBookedJobs(data)

    return (
      <BodyPart>
        <ImagesPart>
          {(!_.isEmpty(data.pics) && (
            <Gallery
              id={data._id}
              images={data.pics || []}
              onClickFullScreen={this.handleFullScreenClick}
              onThumbnailClick={this.handleThumbnailClick}
            />
          )) || <Gallery id={data._id} images="noImg" />}

          {this._renderCustomerInfo()}

          {!isBookedJobs &&
            !_.isEmpty(data.peopleWhoApplied) &&
            data.jobStatus !== 'booked' &&
            data.jobStatus !== 'done' &&
            data.jobStatus !== 'completed' && (
              <WhoApplied
                author={author}
                data={data.peopleWhoApplied}
                onClick={onOpenPeopleWhoApplied}
                onClickProfile={
                  _.isFunction(onOpenApplyByProfile) &&
                  this.handleOpenApplyProfile
                }
              />
            )}
          {_.isEmpty(data.videoLinks) ? null : (
            <VideosAndWebsitesLinksBlock>
              <VideosAndWebsitesLinks
                data={data.videoLinks}
                title="videoLinks"
              />
            </VideosAndWebsitesLinksBlock>
          )}
        </ImagesPart>
        <ScrollChattingPart>
          <JobDescription
            data={jobDescr}
            isEditableVacancy={
              this.isOwnVacancy(data) && data.jobStatus === jobStatuses.posted
            }
            isFavouriteLoading={isFavouriteLoading}
            isUserJob={isUserJob}
            onClick={() =>
              _.isFunction(onAppliedByClick) && onAppliedByClick(data)
            }
            onDelete={onDeleteClick}
            onEdit={onEditClick}
            onFavouriteClick={this.handleFavouriteClick}
            onShareClick={() => onShare(data)}
            status={data.jobStatus}
          />
          <CategoryInfo
            data={categoryInfo}
            isShowAddress={isBookedJobs}
            key={2}
            onShowMap={this.handleShowMap}
          />
          {isBookedJobs && author && (
            <CustomerInfo
              data={customerInfo}
              key={3}
              usernameId={author?.username}
            />
          )}
          {this._renderBookedActions()}
          {this._renderRequestButtons()}
          {this._renderCancelButton()}
          {this._renderApplyButton()}
          {this._renderDisputeButton()}
          <Ad small />
          {this._renderChatButton()}
        </ScrollChattingPart>
      </BodyPart>
    )
  }

  checkDisableEmployer = () => {
    const { job, user } = this.props
    return job.author?.username === user?.username && job.authorCancel
  }

  handleCloseRecurringModal = () =>
    this.setState({ isShowRecurringModal: false })

  _onMarkJob = async () => {
    const {
      job: jobDescription,
      onMarkJob,
      user,
      onSendMessage,
      handleDisputeJob
    } = this.props
    this.setState({ loadMarkJobId: jobDescription._id, isMarkingJob: true })

    const isOwnDispute = !this.isOwnVacancy(jobDescription) && jobDescription?.doerDispute
    if (jobDescription?.disputeReason && isOwnDispute) {
      const type = this.isOwnVacancy(jobDescription)
        ? jobStatuses.done
        : jobStatuses.completed
      await handleDisputeJob(
        { disputeReason: jobDescription.disputeReason, type },
        true
      )
    }

    onMarkJob(jobDescription._id, (err, data) => {
      this.setState({ isMarkingJob: false })
      if (data && data.jobStatus === jobStatuses.done) {
        const newMessage = {
          createdAt: new Date().getTime(),
          text: `-- ${this.props.t('chat:ChatJobCompleted')} --`,
          _id: _.uniqueId('_id'),
          type: 'complete_message',
          sender: user.username
        }

        const payload = {
          chatId: jobDescription.chatId,
          message: newMessage,
          userReceived: jobDescription.doerId,
          jobId: jobDescription._id
        }
        onSendMessage(payload, user.username)
        if (this.isOwnVacancy(jobDescription) && jobDescription.isRecurring) {
          this.setState({ isShowRecurringModal: true })
        }
      }
      if (
        data &&
        data.jobStatus === jobStatuses.completed &&
        data.dateCompleted &&
        !this.isOwnVacancy(jobDescription)
      ) {
        this.setState({ dateCompleted: new Date(), isJobCompleted: true })
      }
      this.setState({ loadMarkJobId: null, isMarkJobDone: false })
    })
  }

  handleRecurringJob = async data => {
    const { job, onRecurringJob, profile, onHireNow, history } = this.props
    this.setState({
      isMarkJobDone: true,
      isRecurring: true
    })

    const {
      expiryDate,
      doneBefore,
      appointmentDate,
      budget,
      currency,
      isRecurring,
      isHireNow,
      budgetMyCountry
    } = data
    const _id = shortId.generate()
    let jobPics = []

    const pics = job?.pics ? job.pics.map(pic => getJobImage(job._id, pic)) : []

    if (!_.isEmpty(pics)) {
      const _pics = await new Promise(async resolve => {
        let i = 0
        let result = []
        do {
          const data = await getImageBase64(pics[i])
          result.push(data)

          if (i === pics.length - 1) {
            resolve(result)
          }

          i++
        } while (i < pics.length)
      })
      const images = await ImagesManager.updateImages(
        imageTypes.jobPics,
        _pics,
        {
          subfolder: _id
        }
      )

      if (!_.isEmpty(images)) {
        jobPics = images.map(image => {
          const arr = image.split('/')
          return arr[arr.length - 1]
        })
      }
    }

    const body = {
      _id,
      jobId: job._id,
      expiryDate,
      doneBefore,
      pics: jobPics,
      budget: Number(budget),
      currency: 'USD',
      budgetMyCountry: Number(budgetMyCountry),
      currencyMyCountry: currency
    }
    onRecurringJob(body, (error, response) => {
      if (error) {
        return toast.error(error?.message)
      }

      if (isHireNow) {
        onHireNow(
          {
            budget,
            currency,
            isRecurring,
            date: appointmentDate,
            _id: response._id,
            jobId: response._id,
            userId: profile?._id,
            budgetMyCountry
          },
          () =>
            this.setState({
              isMarkJobDone: false,
              isRecurring: false,
              isShowRecurringModal: false
            })
        )
      } else {
        this.setState(
          {
            isMarkJobDone: false,
            isRecurring: false,
            isShowRecurringModal: false
          },
          () => history.push(`${routes.myJobs}${routes.posted}/${_id}`)
        )
      }
    })
  }

  render() {
    const { job } = this.props

    const {
      isRequest,
      inOpenMapModal,
      isShowRecurringModal,
      isMarkJobDone,
      isRecurring
    } = this.state

    return (
      <>
        <ProgressBar percent={isRequest ? 1 : 100} />
        {this._renderReportModal()}
        {isRequest && (
          <LoadMoreWrapper>
            <PreloadImg
              alt="loading"
              className="loader"
              isAds={true}
              src={icons.preload}
            />
          </LoadMoreWrapper>
        )}
        {_.isEmpty(job) ? null : this._renderContent()}
        {this.isBookedJobs(job) && (
          <MapModal
            isShow={inOpenMapModal}
            job={job}
            onClose={this.handleHideMap}
          />
        )}
        {this._renderImagesModal()}
        <RecurringModal
          isVisible={isShowRecurringModal}
          onCancel={this.handleCloseRecurringModal}
          onSubmit={this.handleRecurringJob}
          isLoading={isMarkJobDone || isRecurring}
          budget={job?.budgetMyCountry || job?.budget}
          currency={job?.currencyMyCountry || job?.currency}
          isShowDescription={!job?.isPaymentViaOurService}
        />
      </>
    )
  }
}

JobView.propTypes = {
  history: PropTypes.object,
  isLoadMarkAsButton: PropTypes.bool,
  isRejecting: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  job: PropTypes.object,
  jobId: PropTypes.string,
  loadChatUsername: PropTypes.string,
  match: PropTypes.object,
  t: PropTypes.func,
  user: PropTypes.object,
  onAcceptRequest: PropTypes.func,
  onAddToFavourites: PropTypes.func,
  onAppliedByClick: PropTypes.func,
  onApplyJob: PropTypes.func,
  onCancelClick: PropTypes.func,
  onConnectToChat: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onGetJobDescription: PropTypes.func,
  onMarkJob: PropTypes.func,
  onOpenApplyByProfile: PropTypes.func,
  onOpenPeopleWhoApplied: PropTypes.func,
  onRejectRequest: PropTypes.func,
  onRemoveFromFavourites: PropTypes.func,
  onShare: PropTypes.func,
  onStartTracking: PropTypes.func,
  onStartingTheJob: PropTypes.func
}

export default JobView
