import services from './services.json'
import landingPage from './landingPage.json'
import languages from './languages.json'
import profilePage from './profilePage.json'
import sign from './sign.json'
import headerBlock from './headerBlock.json'
import advertising from './advertising.json'
import common from './common.json'
import AddServiceCatScreen from './AddServiceCatScreen.json'
import AddServiceSubCatScreen from './AddServiceSubCatScreen.json'
import jobPost from './jobPost.json'
import serviceProvider from './serviceProvider.json'
import findJobPage from './findJobPage'
import rating from './rating.json'
import jobDetail from './jobDetail.json'
import notifications from './notifications.json'
import subscriptionScreen from './subscriptionScreen.json'
import settings from './settings.json'
import deleteAccountScreen from './deleteAccountScreen.json'
import homePage from './homePage.json'
import phoneCodes from './phoneCodes.json'
import jobDescription from './jobDescription.json'
import apiErrors from './apiErrors.json'
import currency from './currency.json'
import appServiceProvider from './appServiceProvider.json'
import LeftoverOnes from './LeftoverOnes.json'
import myJobsDetail from './myJobsDetail.json'
import jobTrack from './jobTrack.json'
import chat from './chat.json'
import postAds from './postAds.json'

export default {
  sign,
  services,
  landingPage,
  languages,
  profilePage,
  headerBlock,
  advertising,
  common,
  AddServiceCatScreen,
  AddServiceSubCatScreen,
  jobPost,
  serviceProvider,
  rating,
  jobDetail,
  notifications,
  subscriptionScreen,
  settings,
  deleteAccountScreen,
  homePage,
  phoneCodes,
  jobDescription,
  apiErrors,
  currency,
  appServiceProvider,
  LeftoverOnes,
  myJobsDetail,
  jobTrack,
  findJobPage,
  chat,
  postAds,
}
