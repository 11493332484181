import styled from 'styled-components'
import { sizes } from 'Constants'

/* Notifications
/* ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## */

export const SvgDiv = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  & > a > svg {
    height: auto;
    width: 18px !important;
  }
  @media (max-width: ${sizes.desktop}px) {
    padding-left: 1em;
  }
  @media (max-width: ${sizes.tablet}px) {
    transform: scale(1.5);
    top: 15px;
    right: -25px;
    border: 1px solid #ccc;
    border-radius: 50%;
    padding: 15px;
  }
  & * {
    color: ${props => props.theme.colors.defaultFont};
  }
`

export const Active = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.notification};
  top: 5px;
  right: 4px;
`
