import styled from 'styled-components'
import { fontFamily, sizes, fontSize } from 'Constants'

export const DefaultBoldText = styled.p`
  margin: 0;
  padding-top: 1.5em;
  font-size: ${fontSize.h4};
  font-family: ${fontFamily};
  font-weight: 900;
  color: #565656;
`

export const DefaultText = styled.p`
  font-size: ${fontSize.h4};
  font-family: ${fontFamily};
  margin: ${props => (props.more ? '1em 0 0 0' : '0 0 1em 0')};
  color: #565656;
  margin-top: 5px;
`

export const ItemDefaultWrapper = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  ${props => props.wrapped && `border: 1px solid #e1e5e8`};
  margin: 7px;
  width: ${props => (props.howItWorks ? '30%' : '23%')};
  position: relative;
  @media (max-width: ${sizes.desktop}px) {
    width: 30%;
  }
  @media (max-width: ${sizes.tablet}px) {
    width: 45%;
  }
  @media (max-width: ${sizes.phone}px) {
    width: 90%;
  }
`

export const ItemImage = styled.img`
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 1.5em;
  max-width: ${p => (p.isBigger ? 75 : 70)}px;
  @media (max-width: ${sizes.tablet}px) {
    max-width: ${p => (p.isBigger ? 65 : 60)}px;
  }
`

export const ItemPadder = styled.div`
  padding: 85px 15px 10px;
  margin: 15px 15px 0;
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const HowItWorksPadder = styled.div`
  padding: 15px 15px 0;
  margin: 15px 15px 0;
`
