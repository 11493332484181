import styled from 'styled-components'
import { width } from 'styled-system'

export const Container = styled.div.attrs(props => ({
  onClick: e => e.stopPropagation()
}))`
  max-width: ${p => p.width || '400px'};
  ${p => p.height && `height: ${p.height}`};
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px silver;
  ${width};
  max-width: 100%;
`

export const Overflow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin: auto;
  height: 100%;
  background-color: ${p => p.theme.colors?.porcelain05};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: z-index 0.3s, opacity 0.3s;
  z-index: ${p => (p.isShow ? p.zIndex || 1000 : -1)};
  opacity: ${p => (p.isShow ? 1 : 0)};
  & > ${Container} {
    transition: opacity 0.3s, transform 0.3s;
    transform: scale(${p => (p.isShow ? 1 : 0)});
    opacity: ${p => (p.isShow ? 1 : 0)};
  }
  padding: 7px;
`
