import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.span``

export const Message = styled.span`
  font-size: 18;
  color: ${p => (p.isRead ? '#000' : p.theme.colors.joinUs)};
  ${p => p.disabled && 'pointer-events: none;'};
`

export const TextLink = styled(Link)`
  font-size: 18;
  font-weight: bold;
  color: ${p => p.theme.colors.joinUs};
`
