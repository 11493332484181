import styled from 'styled-components'
import { fontFamily, fontSize } from 'Constants'

export const HorizontalLineWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Line = styled.div`
  border: 1px solid ${props => props.theme.colors.lineGrey};
  width: 30vw;
`

export const SymbolChecked = styled.span`
  font-size: ${fontSize.h3};
  font-family: ${fontFamily};
  color: ${props => props.theme.colors.lineGrey};
  padding: 1em 10px;
`
