import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { fontSize, sizes, fontFamily } from 'Constants'
import { slide as Menu } from 'react-burger-menu'

export const MenuWrap = styled(Menu)`
  & > div {
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export const BurgerWrap = styled.div`
  @media (min-width: ${sizes.tablet}px) {
    display: none;
  }
`

export const SignInLink = styled(Link)`
  font-size: ${fontSize.h2};
  font-family: ${fontFamily};
  text-decoration: none;
  font-weight: 900;
  color: #459aee;
  outline: none;
  @media (max-width: ${sizes.desktop}px) {
    font-size: ${fontSize.h5};
    text-align: center;
    padding: 30px 0;
    width: 100%;
    display: inline-block;
  }
`

export const JoinUsNav = styled(Link)`
  color: #fff;
  font-size: ${fontSize.h2};
  font-family: ${fontFamily};
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 30px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background: linear-gradient(to right, #76c1f7 0%, #459aee 99%);
  border: 2px solid;
  @media (max-width: ${sizes.desktop}px) {
    width: 100%;
    font-size: ${fontSize.h4};
  }
  @media (max-width: ${sizes.tablet}px) {
    font-size: ${fontSize.default};
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
`

export const Tooltips = styled.div`
  display: flex;
  justify-content: center;
`

export const Flex = styled.div`
  outline: none;
  padding-bottom: 1em;
  margin-bottom: 1em;
  border-bottom: 1px solid #ccc;
`
