import styled from 'styled-components'
import { Button } from 'Components/ui'

export const Container = styled.div`
  background: #fff;
  border-radius: 12px;
`

export const Header = styled.header`
  padding: 15px 0;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 5px;
`

export const Title = styled.span`
  color: #000;
  font-size: ${p => p.theme.fontSize?.h4};
  font-weight: bold;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
`

const ConfirmButton = styled(Button)`
  border-radius: 0;
  flex-basis: 50%;
  font-weight: bold;
`

export const SubmitButton = styled(ConfirmButton)`
  ${p => p.disabled && 'pointer-events: none; opacity: 0.6;'};
  border-bottom-right-radius: 12px;
`

export const CancelButton = styled(ConfirmButton)`
  filter: grayscale(100%);
  border-bottom-left-radius: 12px;
`

export const Content = styled.div`
  padding-right: 40px;
  padding-left: 40px;
`

export const Block = styled.div``

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

export const Text = styled.span`
  ${({ flex }) => flex && { flex }};
  font-size: ${({ isBold }) => (isBold ? '20px' : '18px')};
  font-weight: ${({ isBold }) => (isBold ? 'bold' : 'normal')};
`

export const Description = styled.span`
  text-align: center;
  font-weight: bold;
  width: 100%;
  font-size: 18px;
`

export const MethodImage = styled.div`
  background-image: ${({ url }) => `url(${url})`};
  height: 40px;
  width: 70px;
  background-position: center;
  background-size: cover;
  margin-left: 10px;
  border-radius: 5px;
  border: 2px solid ${({ active }) => (active ? 'red' : '#ddd')};
  cursor: pointer;
`
