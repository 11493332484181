import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
  withShare,
  withApplyForAJob,
  withSendHiringRequest,
  withHireSP,
  withCancelJob
} from 'Components/hoc'
import { withNamespaces } from 'react-i18next'

import { popularServices } from 'Redux/actions/services'
import { jobList, removeJob, deleteJob } from 'Redux/actions/job'
import {
  addJobToFavourites,
  removeJobFromFavourites
} from 'Redux/actions/favourites'

import { getProfile } from 'Redux/selectors/user'
import {
  getJobList,
  getIsEndJobsList,
  getJobsFilters,
  getJobsPage
} from 'Redux/selectors/jobList'
import { getServices } from 'Redux/selectors/services'
import { getNearestSP } from 'Redux/selectors/nearestSP'
import { getServiceProvider } from 'Redux/selectors/serviceProvider'

import Component from './FindJob'

const selector = createStructuredSelector({
  user: getProfile,
  job: getJobList,
  services: getServices,
  nearestSP: getNearestSP,
  serviceProvider: getServiceProvider,
  isEndJobsList: getIsEndJobsList,
  jobsFilters: getJobsFilters,
  jobsPage: getJobsPage,
})

const actions = {
  onGetJobList: jobList,
  onGetPopularServices: popularServices,
  onAddToFavourites: addJobToFavourites,
  onRemoveFromFavourites: removeJobFromFavourites,
  onRemove: removeJob,
  onDelete: deleteJob
}

export default compose(
  withCancelJob,
  withSendHiringRequest,
  withApplyForAJob,
  withHireSP,
  withShare,
  connect(
    selector,
    actions
  ),
  withNamespaces(['findJobPage', 'headerBlock'])
)(Component)
