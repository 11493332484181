import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { withNamespaces } from 'react-i18next'
import {
  withShare,
  withFavouriteSP,
  withRequests,
  withFavouriteJob,
  withSendHiringRequest
} from 'Components/hoc'

import { loadRequestsJobs, loadRequestsSP } from 'Redux/actions/requests'
import { removeJob } from 'Redux/actions/job'

import { getProfile } from 'Redux/selectors/user'
import { getJobsRequests, getSPRequests } from 'Redux/selectors/requests'
import { getSpecificSP } from 'Redux/selectors/serviceProvider'
import { getSpecificJob } from 'Redux/selectors/jobList'

import Component from './MyRequests'

const selector = createStructuredSelector({
  user: getProfile,
  jobs: getJobsRequests,
  sp: getSPRequests,
  spProfile: getSpecificSP,
  job: getSpecificJob
})

const actions = {
  onRemove: removeJob,
  onLoadRequestsJobs: loadRequestsJobs,
  onLoadRequestsSP: loadRequestsSP
}

export default compose(
  withShare,
  withFavouriteSP,
  withRequests,
  withFavouriteJob,
  withSendHiringRequest,
  withNamespaces(['findJobPage', 'homePage', 'appServiceProvider']),
  connect(
    selector,
    actions
  )
)(Component)
