import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'

import { withNamespaces } from 'react-i18next'

import { Avatar } from 'Components/ui/Avatar'
import { Premium } from 'Components/ui/Icons/Premium'
import { Pro } from 'Components/ui/Icons/Pro'
import { Star } from 'Components/ui/ScreenWrappers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

import {
  About,
  CardWrapper,
  Date,
  LeftGroup,
  LeftProfileGroup,
  NameGroup,
  Plumber,
  ActionLink,
  ActionLinkWrapper,
  Span,
  StarGroup
} from './style'
import { idVerificationStatus } from 'Constants/idVerification'

const ReviewPuck = ({
  author,
  rate,
  comment,
  t,
  createdAt,
  isShowButtons,
  onEdit,
  onDelete
}) => (
  <CardWrapper>
    <LeftGroup>
      <Avatar
        avatar={author.avatarURL}
        id={author.username}
        isVerified={author?.id_verification === idVerificationStatus.success}
      />
      <LeftProfileGroup>
        <NameGroup>
          <Span>{author.short_name}</Span>
          <Premium visible={author.isPremium} />
          <Pro visible={author.isPro} />
        </NameGroup>
        <StarGroup>
          <Star>
            <FontAwesomeIcon icon={faStar} />
            <span>{rate || 0}</span>
          </Star>
        </StarGroup>
        {_.isEmpty(author.services) ? null : (
          <Plumber>
            {t(`services:${author.services[0].title} & `)}
            {author.services.length - 1} {t('more')}
          </Plumber>
        )}
      </LeftProfileGroup>
      {isShowButtons ? (
        <ActionLinkWrapper>
          <ActionLink onClick={onEdit}>{t('edit')}</ActionLink>
          <ActionLink onClick={onDelete}>{t('delete')}</ActionLink>
        </ActionLinkWrapper>
      ) : null}
    </LeftGroup>
    <About>{comment}</About>
    {moment(createdAt).isValid() ? (
      <Date>{moment(createdAt).format('DD/MM/YYYY')}</Date>
    ) : null}
  </CardWrapper>
)

ReviewPuck.propTypes = {
  author: PropTypes.shape({
    avatarURL: PropTypes.string,
    username: PropTypes.string,
    isVerified: PropTypes.bool,
    short_name: PropTypes.string,
    isPremium: PropTypes.bool,
    isPro: PropTypes.bool
  }),
  comment: PropTypes.string,
  createdAt: PropTypes.string,
  isShowButtons: PropTypes.bool,
  rate: PropTypes.string,
  t: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func
}

export default withNamespaces(['common', 'services'])(ReviewPuck)

export { ReviewPuck }