import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from 'Redux/actions/api'

export const LINK_CARD = createAsyncAction('paymentsTransactions/LINK_CARD')
export const linkCard = (params, callback) =>
  apiCall({
    endpoint: '/credit-card/create',
    types: LINK_CARD,
    method: 'POST',
    query: {
      ...params
    },
    callback
  })

export const GET_CARD = createAsyncAction('paymentsTransactions/GET_CARD')
export const getCard = (cardId, callback) =>
  apiCall({
    endpoint: `/credit-card/${cardId}/index`,
    types: GET_CARD,
    method: 'GET',
    meta: {
      cardId
    },
    callback
  })

export const UPDATE_CARD = createAsyncAction('paymentsTransactions/UPDATE_CARD')
export const updateCard = (cardId, query, callback) =>
  apiCall({
    endpoint: `/credit-card/${cardId}/update`,
    types: UPDATE_CARD,
    method: 'PUT',
    query,
    meta: {
      cardId
    },
    callback
  })

export const DELETE_CARD = createAsyncAction('paymentsTransactions/DELETE_CARD')
export const deleteCard = (cardId, callback) =>
  apiCall({
    endpoint: `/credit-card/${cardId}/delete`,
    types: DELETE_CARD,
    method: 'DELETE',
    callback
  })

export const GET_EARNINGS = createAsyncAction(
  'paymentsTransactions/GET_EARNINGS'
)
export const getEarnings = callback =>
  apiCall({
    endpoint: `/earnings/index`,
    types: GET_EARNINGS,
    method: 'GET',
    callback
  })

export const GET_PAYMENTS = createAsyncAction('paymentsTransactions/GET_PAYMENTS')
export const getPayments = callback =>
  apiCall({
    endpoint: `/payments/index`,
    types: GET_PAYMENTS,
    method: 'GET',
    callback
  })

export const CONNECT_PAYPAL = createAsyncAction('paymentsTransactions/CONNECT_PAYPAL')
export const connectPaypal = (query, callback) =>
  apiCall({
    endpoint: '/paypal/connect',
    types: CONNECT_PAYPAL,
    method: 'POST',
    query,
    meta: query,
    callback
  })

export const WITHDRAW = createAsyncAction('paymentsTransactions/WITHDRAW')
export const withdraw = (amount, callback) =>
  apiCall({
    endpoint: '/paypal/withdraw',
    types: WITHDRAW,
    method: 'POST',
    query: {
      amount,
    },
    meta: {
      amount
    },
    callback
  })

export const GET_WITHDRAWAL_HISTORIES = createAsyncAction('paymentsTransactions/GET_WITHDRAWAL_HISTORIES')
export const getWithdrawalHistories = callback =>
  apiCall({
    endpoint: '/paypal/withdraw/histories',
    types: GET_WITHDRAWAL_HISTORIES,
    method: 'GET',
    callback
  })

export const GET_CURRENCY_RATIO = createAsyncAction('GET_CURRENCY_RATIO')
export const getCurrencyRatio = (currency, callback) =>
  apiCall({
    endpoint: `/get-ratio?currency=${currency}`,
    types: GET_CURRENCY_RATIO,
    method: 'GET',
    callback
  })

export const ON_DONATE = createAsyncAction('paymentsTransactions/ON_DONATE')
export const onDonate = (query, callback) =>
  apiCall({
    endpoint: '/donation/index',
    types: ON_DONATE,
    method: 'POST',
    query,
    callback
  })

export const GET_PAYMENT_MAINTENANCE = createAsyncAction('paymentsTransactions/GET_PAYMENT_MAINTENANCE')
export const getPaymentMaintenance = callback =>
  apiCall({
    endpoint: '/maintenance',
    types: GET_PAYMENT_MAINTENANCE,
    method: 'GET',
    callback,
  })