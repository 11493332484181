import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'
import { withNamespaces } from 'react-i18next'

import { getPayments } from 'Redux/actions/paymentsTransactions'

import { makeSelectPayments } from 'Redux/selectors/paymentsTransactions'

import Component from './PaymentsHistory'

const selector = createStructuredSelector({
  payments: makeSelectPayments
})

const actions = {
  onGetPaymentsHistory: getPayments
}

export default compose(
  withNamespaces('profilePage'),
  connect(selector, actions)
)(Component)
