import React, { PureComponent } from 'react'
import 'Localization'
import AppRouter from 'Routes'
import { connect } from 'react-redux'
import { rehydrateToken } from 'Redux/actions/auth'
import { loadSubscriptionPlans, loadPtsCost } from 'Redux/actions/subscription'

class App extends PureComponent {
  componentDidMount() {
    const { onTokenRehydrate, onLoadPlans, onLoadPrice } = this.props
    onTokenRehydrate()
    onLoadPlans()
    onLoadPrice()
  }

  render() {
    return (
      <div className="app">
        <AppRouter />
      </div>
    )
  }
}

const actions = {
  onTokenRehydrate: rehydrateToken,
  onLoadPlans: loadSubscriptionPlans,
  onLoadPrice: loadPtsCost
}

export default connect(
  null,
  actions
)(App)
