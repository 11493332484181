import styled from 'styled-components'
import { fontSize } from 'Constants'
import { Link } from 'react-router-dom'

export const Arrow = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 7px;
    right: 0;
    display: block;
    width: 2px;
    height: 8px;
    background: #fff;
    border-radius: 3px;
    transition: All 0.3s;
  }
  &:after {
    right: 5px;
    transform: ${props =>
      props.select ? 'rotate(45deg);' : 'rotate(135deg);'};
  }
  &:before {
    transform: ${props =>
      props.select ? 'rotate(-45deg);' : 'rotate(-135deg);'};
  }
  &:hover {
    color: #f2f1f4;
    transition: All 0.3s;
    &:before,
    &:after {
      transition: All 0.3s;
    }
  }
`

export const Popap = styled.div`
  position: absolute;
  visibility: ${props => (props.select ? 'visible' : 'hidden')};
  right: -20px;
  top: 30px;
  z-index: 100;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
`

export const ListLink = styled.span`
  text-decoration: none;
  cursor: pointer;
  display: block;
  padding: 8px 14px;
  font-size: ${fontSize.small};
  font-weight: 400;
  line-height: 18px;
  white-space: nowrap;
  color: #49596a;
  box-shadow: inset 0 -1px 0 rgba(73, 89, 106, 0.1);
  &:hover {
    color: #4fa6d3;
    background: rgba(202, 238, 255, 0.2);
  }
`

export const ProfileLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  display: block;
  padding: 8px 14px;
  font-size: ${fontSize.small};
  font-weight: 400;
  line-height: 18px;
  white-space: nowrap;
  color: #49596a;
  box-shadow: inset 0 -1px 0 rgba(73, 89, 106, 0.1);
  &:hover {
    color: #4fa6d3;
    background: rgba(202, 238, 255, 0.2);
  }
`
