import styled from 'styled-components'
import { Button } from 'Components/ui'

export const Container = styled.div`
  background: #fff;
`

export const Header = styled.header`
  padding: 15px 0;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 5px;
`

export const Title = styled.span`
  color: #000;
  font-size: ${p => p.theme.fontSize?.h4};
  font-weight: bold;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
`

const ConfirmButton = styled(Button)`
  border-radius: 0;
  flex-basis: 50%;
  font-weight: bold;
`

export const SubmitButton = styled(ConfirmButton)`
  ${p => p.disabled && 'pointer-events: none; opacity: 0.6;'};
`

export const CancelButton = styled(ConfirmButton)`
  filter: grayscale(100%);
`
