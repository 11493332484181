import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { SettingsSection } from 'Components/ui'

import { Container, Button } from './style'

const Section = ({ title, subTitle, value, onClick, buttonText }) => {
  return (
    <>
      <SettingsSection.Title>{title}</SettingsSection.Title>
      <Container>
        <SettingsSection.SubTitle>{subTitle}</SettingsSection.SubTitle>
        {_.isString(buttonText) ? (
          <Button onClick={onClick}>{buttonText}</Button>
        ) : (
          <SettingsSection.Switch value={value} onClick={onClick} />
        )}
      </Container>
    </>
  )
}

Section.propTypes = {
  subTitle: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.bool,
  onClick: PropTypes.func
}

export { Section }
