import { put } from 'redux-saga/effects'

import _ from 'lodash'

import { mountChannels } from 'Redux/actions/socket'
import { updateToken } from 'Redux/actions/auth'

function* onTokenRehydrateSuccess(action) {
  try {
    const refreshToken = _.get(action, 'payload.data.RefreshToken', null)

    if (refreshToken) {
      yield put(updateToken(refreshToken))
    }

    yield put(mountChannels())
  } catch (err) {
    console.error(err)
  }
}

export default onTokenRehydrateSuccess
