import React from 'react'
import styled from 'styled-components'
import { Ad } from 'Components/blocks'

export const JobViewContainer = styled.div`
  margin-top: 20px;
`

export const GoogleAdsFirst = styled(props => <Ad {...props} />)`
  margin-bottom: 20px;
`
