import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { services, popularServices } from 'Redux/actions/services'
import { nearestSP } from 'Redux/actions/serviceProviders'

import { loadNotifications } from 'Redux/actions/notifications'
import { mountChannels } from 'Redux/actions/socket'
import { markChatAllAsRead } from 'Redux/actions/chats'
import { getServices } from 'Redux/selectors/services'
import { getPopularServices } from 'Redux/selectors/popularServices'
import { getProfile } from 'Redux/selectors/user'
import { getNearestSP } from 'Redux/selectors/nearestSP'
import { getIsConnectedSocket } from 'Redux/selectors/socket'

import Component from './HomePage'

const selector = createStructuredSelector({
  services: getServices,
  popularServices: getPopularServices,
  nearestSP: getNearestSP,
  user: getProfile,
  isConnectedSocket: getIsConnectedSocket
})

const actions = {
  onGetServices: services,
  onGetNearestSP: nearestSP,
  onGetPopularServices: popularServices,
  onConnectSocket: mountChannels,
  onLoadNotifications: loadNotifications,
  onLoadIsUnReadMessage: markChatAllAsRead
}

export default connect(selector, actions)(Component)
