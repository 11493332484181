import React from 'react'
import { CardWrapper } from 'Components/ui'
import styled from 'styled-components'

export const NotificationWrapper = styled.div`
  width: 100%;
`

export const MarkAsRead = styled.div`
  align-self: flex-end;
  margin-bottom: 10px;
  font-weight: 700;
  color: ${props => props.theme.colors.defaultBlue};
  display: flex;
  flex-direction: row;
  cursor: pointer;
`

export const Section = styled.section`
  padding: 30px 0;
`

export const SmallLoaderWrapper = styled.div`
  margin-right: 10px;
`

export const StyledCardWrapper = styled(props => <CardWrapper {...props} />)`
  margin: 0 auto;
`
