import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { debounce, map, get, uniqueId, isEmpty } from 'lodash'
import VoicesService from 'Services/Voices'
import { ReactMic } from '@cleandersonlobo/react-mic'
import * as routes from 'Routes/routesConstants'
import { blockChatConst } from './config'

import { icons } from 'Assets/icons'
import { PreloadImg, LoadMoreWrapper } from 'Components/ui'
import { formatMessageTime } from 'Helpers/time'
import { jobStatuses } from 'Constants/jobStatuses'

import { SearchInput, ChatPuck, ChatHeader, MessagesBlock } from 'Components/ui'
import { ProgressBar } from '../index'
import {
  Container,
  ChatsListWrapper,
  ChatWrapper,
  ChatsList,
  ChatPuckWrapper,
  InputWrapper,
  Input,
  VoiceButton,
  SelectChatText,
  SubmitButton,
  Mic
} from './style'

class Chat extends Component {
  state = {
    message: '',
    isRequest: false,
    islockChatRequest: false,
    isRecording: false,
    loadingChat: false,
    searchKeyboard: '',
    chatMessages: [],
    loadMoreChatList: false,
    searching: false,
    isFocus: false,
    blob: null
  }
  chatList = React.createRef()

  componentDidMount() {
    this.handleLoadChats(() => {
      const jobId = this.props.history.location.pathname.split('/')[2]
      const chatId = this.props.history.location.hash.replace('#', '')

      if (jobId) {
        this.setState({ loadingChat: true }, () => {
          this.props.getMessageDetail({ id: jobId, chat_id: chatId || null }, () => {
            this.setState({ loadingChat: false })
          })
        })
      }
    })

    this.chatList.current.addEventListener('scroll', this.trackScrolling)
    this.debounceSearch = debounce(this.search, 700)
  }

  static isBottom(el) {
    return el.scrollHeight - el.scrollTop === el.clientHeight
  }

  componentWillUnmount() {
    this.chatList.current.removeEventListener('scroll', this.trackScrolling)
  }

  trackScrolling = () => {
    if (Chat.isBottom(this.chatList.current) && !this.state.loadMoreChatList) {
      const { chats: { currentPage = 0, isListEnd }, onLoadChatList } = this.props

      if (!isListEnd) {
        this.setState({ loadMoreChatList: true })
        const payload = {
          page_number: currentPage + 1,
          search: this.state.searchKeyboard
        }
        onLoadChatList(payload, () => {
          this.setState({ loadMoreChatList: false })
        })
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { islockChatRequest } = this.state
    if (prevProps.activeChat.blockedBy !== this.props.activeChat.blockedBy) {
      if (islockChatRequest) {
        this.setState({ islockChatRequest: false })
      }
    }
  }

  getChatPucks = (data, user) => {
    return map(data, el => {
      const lastMessage = el?.message || {}
      const { avatarURL = '', username = '', short_name = '' } = el?.partner || {}
      const isActive = this.props.activeChat._id === el._id
      const isServiceProvider = user?._id === el?.doerId
      const isRead = isServiceProvider ? el?.doerIsRead : el?.userIsRead

      return (
        <ChatPuckWrapper isActive={isActive} key={el._id}>
          <ChatPuck
            isRead={isRead}
            isActive={isActive}
            avatarUrl={avatarURL}
            date={formatMessageTime(el.message ? el.message.createdAt: el.createdAt)}
            isChatBlocked={el.blockedBy}
            jobTitle={el.jobName || 'This job is longer availbility'}
            name={short_name}
            text={
              lastMessage.type && lastMessage.type !== 'voice_message'
                ? lastMessage.text
                : null
            }
            jobId={el.jobId}
            type={lastMessage.type}
            username={username}
            onClick={() => this.getMessageDetail(el)}
          />
        </ChatPuckWrapper>
      )
    })
  }

  getMessageDetail = ele => {
    const { onMarkAsRead, user, onLoadIsUnreadMessage } = this.props
    this.props.history.replace(`/chats/${ele.jobId}`)
    const isServiceProvider = user?._id === ele?.doerId
    const isRead = isServiceProvider ? ele?.doerIsRead : ele?.userIsRead

    this.setState({ loadingChat: true }, () => {
      this.props.getMessageDetail({ id: ele.jobId, chat_id: ele._id }, (error, data) => {
        if (data) {
          this.setState({ loadingChat: false })
        }
        if(!isRead) {
          return onMarkAsRead(ele._id, ele.doerId === user?._id, () => onLoadIsUnreadMessage(user?._id))
        }
      })
    })
  }

  handleLockChat = () => {
    const { activeChat, onBlockChat } = this.props
    const { islockChatRequest } = this.state
    if (!islockChatRequest) {
      this.setState({ islockChatRequest: true })
      onBlockChat(activeChat._id, () => this.setState({ islockChatRequest: false }))
    }
  }

  handleChangeMessage = ({ target: { value } }) => {
    this.setState({ message: value })
  }

  handleFocusMessage = () => {
    const { onSocketAuthentificate, token } = this.props
    if (token) {
      onSocketAuthentificate(token)
    }
    this.setState({ isFocus: true })
  }

  handleBlurMessage = () => {
    this.setState({ isFocus: false })
  }

  handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.handleSendMessage()
    }
  }

  handleLoadChats = callback => {
    const { onLoadChatList } = this.props

    this.setState({ isRequest: true }, () => {
      onLoadChatList({ page_number: 0 }, () => {
        this.setState({ isRequest: false }, callback)
      })
    })
  }

  search = () => {
    const { onLoadChatList } = this.props
    const payload = {
      search: this.state.searchKeyboard
    }

    this.setState({ searching: true })

    onLoadChatList(payload, () => {
      this.setState({ searching: false })
    })
  }

  handleSearch = (searchKeyboard = '') => {
    this.setState({ searchKeyboard }, this.debounceSearch)
  }

  handleSendMessage = () => {
    const { onSendMessage, user, activeChat } = this.props
    const { message } = this.state
    const id = uniqueId('_id')

    if (message) {
      this.setState({ message: '' }, () => {
        const newMessage = {
          createdAt: new Date().getTime(),
          text: message,
          _id: id,
          type: 'regular_message',
          sender: user.username
        }
        const payload = {
          chatId: activeChat._id,
          message: newMessage,
          jobId: activeChat.jobId,
          userReceived: user.username === activeChat.userId ? activeChat.doerId : activeChat.userId,
          id
        }
        onSendMessage(payload, user.username)
      })
    }
  }
  onStop = async (recordedBlob) => {
    const { user, onSendMessage, activeChat } = this.props

    this.setState({ blob: recordedBlob.blob})
    if (window.confirm('Would you like to send the voice mesage?')) {
      const params = {
        chatId: activeChat._id,
        kmsKey: process.env.REACT_APP_PUT_PARAMS_KMS_KEY || null,
        bucket: process.env.REACT_APP_PROFILE_IMAGES_BUCKET_NAME
      }
      const createdAt = new Date().getTime()
      var file = new File([recordedBlob.blob], `${createdAt}.mp3`, {
        type: recordedBlob.blob.type,
        lastModified: Date.now()
      });

      const { filename } =  await VoicesService.putObject(file, params)
      const id = uniqueId('_id')
      if (filename) {
        const newMessage = {
          createdAt,
          text: filename,
          _id: id,
          type: 'voice_message',
          sender: user.username
        }
        onSendMessage({ chatId: activeChat._id, message: newMessage, id, userReceived: user.username === activeChat.userId ? activeChat.doerId : activeChat.userId, jobId: activeChat.jobId, }, user.username)
      }
  }

  }
  handleStartRecord = () =>
    this.setState({
      isRecording: true
    })

  handleStopRecord = () =>
    this.setState({
      isRecording: false
    })

  handleVoiceButton = () => {
    const { isRecording } = this.state
    if (isRecording) {
      this.handleStopRecord()
    } else {
      this.handleStartRecord()
    }
  };

  renderEmpty = () => {
    const { t } = this.props
    return <SelectChatText>{t('homePage:chats')}</SelectChatText>
  }

  renderChat = () => {
    const { user, activeChat, t, onMarkAsRead, onLoadIsUnreadMessage, chats: { chats } } = this.props
    const { message, islockChatRequest, isRecording, loadingChat, isFocus } = this.state
    const chatMessages = activeChat.messages || []

    map(chats, el => {
    const isServiceProvider = user?._id === el?.doerId
    const isRead = isServiceProvider ? el?.doerIsRead : el?.userIsRead

    if(isFocus === true && !isRead && this.props.activeChat._id === el._id ) {
      return onMarkAsRead(el._id, el.doerId === user?._id, () => onLoadIsUnreadMessage(user?._id))
    }

  })

    if (loadingChat) return (
      <LoadMoreWrapper>
        <PreloadImg
          alt="loading"
          className="loader"
          isAds={true}
          src={icons.preload}
        />
      </LoadMoreWrapper>
    )

    if (isEmpty(activeChat)) return this.renderEmpty()

    const { jobStatus, userId, status, jobName, blockedBy } = activeChat
    const { avatarURL, username, short_name } = get(activeChat, 'partner', {})
    let chatStatus = jobStatus

    if (userId !== user._id && chatStatus === jobStatuses.posted) {
      chatStatus = jobStatuses.applied
    }

    const pathJob = `${routes.myJobs}/${chatStatus}/${activeChat.jobId}`
    const showButton = (status === 'active' && jobName) || (status === 'blocked' && blockedBy === user._id)

    return (
      <>
        <ChatHeader
          avatarUrl={avatarURL}
          isChatBlocked={activeChat.status === 'blocked'}
          showButton={ showButton }
          islockChatRequest={islockChatRequest}
          jobTitle={activeChat.jobName}
          pathJob={pathJob}
          pathProfile={`${routes.findHelp}/${username}`}
          profileName={short_name}
          username={username}
          onLockChat={this.handleLockChat}
        />
        <MessagesBlock
          blockChatConst={blockChatConst}
          chatId={activeChat._id}
          data={chatMessages}
          userName={user.username}
          onResend={this.handleResendMessage}
        />
        <InputWrapper
          active={activeChat.status === 'active' && activeChat.jobName}
          onSubmit={this.handleSendMessage}
        >
          <>
            <Input
              value={message}
              onChange={this.handleChangeMessage}
              onKeyDown={this.handleKeyDown}
              onFocus={this.handleFocusMessage}
              onBlur={this.handleBlurMessage}
              autoFocus={this.getMessageDetail}
            />
            <SubmitButton onClick={this.handleSendMessage}>
              {t('homePage:send')}
            </SubmitButton>
            <Mic>
              <ReactMic
                record={this.state.isRecording}
                onStop={this.onStop}
                mimeType="audio/mp3"
              />
            </Mic>
            <VoiceButton
              recording={isRecording.toString()}
              onClick={this.handleVoiceButton}
            />
          </>
        </InputWrapper>
      </>
    )
  }

  loading = () => (
    <LoadMoreWrapper key={-1}>
      <PreloadImg
        alt="loading"
        className="loader"
        src={icons.preload}
      />
    </LoadMoreWrapper>
  )

  handleResendMessage = (idFail, message) => {
    const { onSendMessage, user, activeChat } = this.props
    const _id = uniqueId('_id')

    const newMessage = {
      createdAt: new Date().getTime(),
      text: message,
      _id: _id,
      type: 'regular_message',
      sender: user.username
    }
    const payload = {
      chatId: activeChat._id,
      message: newMessage,
      jobId: activeChat.jobId,
      userReceived: user.username === activeChat.userId ? activeChat.doerId : activeChat.userId,
      idFail
    }

    onSendMessage(payload, user.username)
  }

  render() {
    const { t, chats: { chats }, user } = this.props
    const { isRequest } = this.state
    const chatPucks = this.getChatPucks(chats, user)
    return (
      <>
        <ProgressBar percent={isRequest ? 1 : 100}/>
        <Container>
          <ChatsListWrapper>
            <SearchInput
              id="chatListSearch"
              placeholder={t('findJobPage:search')}
              searchChange={this.handleSearch}
              onSubmit={this.handleSearch}
            />
            <ChatsList ref={this.chatList}>
              {
                this.state.searching ? this.loading() : chatPucks
              }
              {this.state.loadMoreChatList && this.loading()}
            </ChatsList>
          </ChatsListWrapper>
          <ChatWrapper>{this.renderChat()}</ChatWrapper>
        </Container>
      </>
    )
  }
}

Chat.propTypes = {
  chats: PropTypes.object,
  loadChatUsername: PropTypes.string,
  t: PropTypes.func,
  user: PropTypes.object,
  onLoadChatList: PropTypes.func,
  onSendMessage: PropTypes.func,
  onMarkAsRead: PropTypes.func,
  onLoadIsUnreadMessage: PropTypes.func,
  onSocketAuthentificate: PropTypes.func,
}

export default Chat
