import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import Slider from 'react-slick'
import {orderBy} from 'lodash'
import PropTypes from 'prop-types'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { findHelp } from 'Routes/routesConstants'
import { CarouselNextArrow, CarouselPrevArrow } from 'Components/ui/Arrows'
import { ServicesModal } from 'Components/blocks'
import CarouselItem from './CarouselItem'

import {
  MainTitle,
  SliderWrapper,
  SectionCategories,
  ServicesButtonAll,
  ItemWrap,
  Item
} from './styles'

class ServicesCarousel extends Component {
  state = {
    isOpenModal: false
  }

  handleModalDismiss = () => {
    this.setState({ isOpenModal: false })
  }

  handleClick = (item) => () => {
    if (!item) {
      this.setState({ isOpenModal: true })
      return
    }
    const { filterJobs } = this.props
    filterJobs && filterJobs([item._id])
  }

  render() {
    const { items, t } = this.props
    const { isOpenModal } = this.state
    const settings = {
      infinite: true,
      speed: 500,
      arrows: true,
      slidesToShow: 6,
      slidesToScroll: 6,
      nextArrow: <CarouselNextArrow />,
      prevArrow: <CarouselPrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            centerPadding: 0
          }
        }
      ]
    }

    return (
      <SectionCategories className="services">
        <MainTitle>{t('popularCategories')}</MainTitle>
        <SliderWrapper>
          <Slider {...settings}>
            {orderBy(items, 'popularity', 'desc').map((item, i) => (
              <ItemWrap key={i}>
                <Item
                  state={{ filter: item._id }}
                  to={findHelp}
                  onClick={this.handleClick(item)}
                >
                  <CarouselItem {...item} />
                </Item>
              </ItemWrap>
            ))}
          </Slider>
          <ServicesButtonAll onClick={this.handleClick()}>
            {t('viewAll').toUpperCase()}
          </ServicesButtonAll>
          <ServicesModal
            isOpen={isOpenModal}
            main={true}
            onRequestClose={this.handleModalDismiss}
          />
        </SliderWrapper>
      </SectionCategories>
    )
  }
}

ServicesCarousel.propTypes = {
  items: PropTypes.array,
  filterJobs: PropTypes.func,
  t: PropTypes.func
}

export const Services = withNamespaces('landingPage')(ServicesCarousel)

export { ServicesCarousel }