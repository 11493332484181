import styled from 'styled-components'
import { sizes } from 'Constants'

export const Container = styled.div`
  padding: 30px 0 10px;
  border-bottom: solid 1px ${p => p.theme.colors.silver05};
  &:last-child {
    margin-bottom: 30px;
  }
  @media (max-width: ${sizes.phone}px) {
    padding-left: 5px;
    padding-right: 5px;
  }
`
