import styled from 'styled-components'

export const Container = styled.div`
  max-width: 700px;
  width: 100%;
  margin: 20px auto;
  background-color: #fff;
  box-shadow: 0 0 5px silver;
  overflow: hidden;
  border-radius: 5px;
  font-family: ${p => p.theme.fontFamily.default};
`

export const Title = styled.h1`
  margin: 0;
  padding: 15px 0 15px 15px;
  font-size: ${p => p.theme.fontSize.h3};
  border-bottom: solid 1px silver;
  font-weight: bolder;
`

export const List = styled.div`
  margin: 10px 0 0;
`
