import styled from 'styled-components'

export const Messages = styled.div`
  position: fixed;
  display: inline-block;
  margin-top: 50px;
  top: 30%;
  right: 10px;
  transition: all 0.3s ease;
  ${p => p.disabled && 'pointer-events: none; opacity: 0.6;'};
  &:hover {
    > div {
      visibility: visible;
      top: -35px;
      opacity: 1;
      transition: all 0.3s ease;
    }
  }
`

export const Img = styled.img`
  width: 60px;
  cursor: pointer;
`

export const Popap = styled.div`
  position: absolute;
  visibility: hidden;
  background: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  width: max-content;
  top: -30px;
  left: 100%;
  transform: translateX(-100%);
  opacity: 0;
  transition: all 0.3s ease;
  &:after {
    content: '';
    position: absolute;
    bottom: -7px;
    right: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: #333 transparent transparent transparent;
  }
`
