import styled from 'styled-components'

export const Container = styled.div`
  padding-top: 30px;
`

export const AdWrapper = styled.div``

export const Content = styled.div`
  margin: 20px 0;
`

export const Title = styled.h1`
  font-size: ${p => p.theme.fontSize.h1};
  color: #000;
  font-weight: bold;
  text-align: center;
`

export const ChatWrapper = styled.div`
  margin: 10px 0;
`
