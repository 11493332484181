import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'
import moment from 'moment'
import toast from 'react-toastify'

import { arrows } from 'Assets/arrows'
import { icons } from 'Assets/icons'
import { paymentsTransactions, root } from 'Routes/routesConstants'
import { PaymentCard } from 'Components/blocks'
import {
  ProfileBackground,
  FormContainer,
  Logo,
  Registered,
  SubTitle,
  EmptyList
} from 'Components/ui'

import {
  ContentHeaderContainer,
  ContentHeader,
  ContnentHeaderBackButton,
  Section,
  Block,
  Loading
} from './styles'
import { formatPrice } from 'Constants/payment'

class Withdrawals extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isRequest: false
    }
  }

  componentDidMount() {
    const { onGetWithdrawalHistories } = this.props
    this.setState({ isRequest: true })
    onGetWithdrawalHistories(error => {
      this.setState({ isRequest: false })
      if (error) {
        toast.error(error.message)
      }
    })
  }

  renderContent = () => {
    const { withdrawalHistories } = this.props

    return (
      <Block>
        {isEmpty(withdrawalHistories) ? (
          <EmptyList />
        ) : (
          withdrawalHistories.map(item => {
            const date = moment(item.createdAt).format('MM/DD/YYYY')
            const budget = item?.serviceFee
              ? item.amountWithdrawn - item.serviceFee
              : item.amountWithdrawn

            return (
              <PaymentCard
                key={item.id}
                date={date}
                refValue={item._id}
                budget={formatPrice(budget)}
                totalAmount={formatPrice(item.amountWithdrawn)}
                serviceFees={item?.serviceFee}
                isPayment
              />
            )
          })
        )}
      </Block>
    )
  }

  render() {
    const { t } = this.props
    const { isRequest } = this.state
    return (
      <ProfileBackground>
        <Link to={root}>
          <Logo>
            SpotJobs<Registered>&reg;</Registered>
          </Logo>
          <SubTitle>{t('signInHeadDescription')}</SubTitle>
        </Link>
        <FormContainer>
          <Fragment>
            <ContentHeaderContainer>
              <ContentHeader>{t('withdrawals')}</ContentHeader>
              <Link to={paymentsTransactions}>
                <ContnentHeaderBackButton src={arrows.arrowBack} />
              </Link>
            </ContentHeaderContainer>
            {isRequest ? (
              <Loading>
                <img alt="" src={icons.preload} />
              </Loading>
            ) : (
              <Section>{this.renderContent()}</Section>
            )}
          </Fragment>
        </FormContainer>
      </ProfileBackground>
    )
  }
}

Withdrawals.propTypes = {
  withdrawalHistories: PropTypes.array,
  onGetWithdrawalHistories: PropTypes.func
}

export default Withdrawals
