import React from 'react'
import PropTypes from 'prop-types'
import { omit, isString, get, isFunction, isEmpty } from 'lodash'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getSpecificSP } from 'Redux/selectors/serviceProvider'
import { serviceProvider } from 'Redux/actions/serviceProviders'

import { withRouter } from 'react-router-dom'

function withServiceProviderProfile(Component) {
  return class extends React.Component {
    static displayName = 'withServiceProviderProfile'

    static propTypes = {
      loadServiceProvider: PropTypes.func,
      profile: PropTypes.object
    }

    state = {
      loadProfileId: null
    }

    handleLoadProfile = (id, callback, jobId = null) => {
      const { loadServiceProvider, profile, match } = this.props

      const username = id || get(match, 'params.freelancerId')

      if (!isString(username) || !username) return

      if (get(profile, 'username') === username && isFunction(callback)) {
        callback()
        return
      }

      this.setState({ loadProfileId: username }, () => {
        loadServiceProvider(username, () => {
          this.setState({ loadProfileId: null }, callback)
        }, jobId)
      })
    }

    render() {
      const { profile } = this.props
      const { loadProfileId } = this.state

      return (
        <Component
          {...omit(this.props, ['loadServiceProvider'])}
          loadProfileId={loadProfileId}
          profile={loadProfileId || isEmpty(profile) ? null : profile}
          onLoadProfile={this.handleLoadProfile}
        />
      )
    }
  }
}

const actions = {
  loadServiceProvider: serviceProvider
}

const selectors = createStructuredSelector({
  profile: getSpecificSP
})

export default compose(
  withRouter,
  connect(
    selectors,
    actions
  ),
  withServiceProviderProfile
)
