import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import { Container, Overflow } from './style'

class Modal extends PureComponent {
  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyboardExitClick)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyboardExitClick)
  }

  handleKeyboardExitClick = ({ keyCode }) => {
    const { isShow, isOpen, onRequestClose } = this.props

    if (!isShow || !isOpen) return
    if (keyCode === 27) onRequestClose()
  }

  _renderContent = () => {
    const {
      children,
      isShow,
      onRequestClose,
      width,
      zIndex,
      height
    } = this.props
    return (
      <Overflow isShow={isShow} zIndex={zIndex} onClick={onRequestClose}>
        <Container height={height} width={width}>
          {children}
        </Container>
      </Overflow>
    )
  }

  render() {
    const { isOpen } = this.props

    if (!isOpen) return null

    return ReactDOM.createPortal(
      this._renderContent(),
      document.getElementById('root-modal')
      || document.createElement('root-modal')
    )
  }
}

Modal.propTypes = {
  children: PropTypes.node,
  height: PropTypes.string,
  isOpen: PropTypes.bool,
  isShow: PropTypes.bool,
  width: PropTypes.string,
  zIndex: PropTypes.number,
  onRequestClose: PropTypes.func
}

Modal.defaultProps = {
  isOpen: true
}

export { Modal }
