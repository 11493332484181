import { takeLatest } from 'redux-saga/effects'

import { SEND_REQUEST } from 'Redux/actions/requests'
import { HIRE_JOB } from 'Redux/actions/postedJobs'

import onSendRequest from './onSendRequest'
import onHireSP from './onHireSP'

export default function*() {
  yield takeLatest(SEND_REQUEST.SUCCESS, onSendRequest)
  yield takeLatest(HIRE_JOB.SUCCESS, onHireSP)
}
