import React from 'react'
import styled from 'styled-components'
import { Ad } from 'Components/blocks'

export const Wrapper = styled.div`
  padding-top: 30px;
`

export const GoogleAdsBanner = styled(props => <Ad {...props} />)`
  margin-bottom: 20px;
`
