import { createSelector, createStructuredSelector } from 'reselect'

const data = store => store.favourites

export const getFavouritesJobs = createSelector(
  data,
  data => data.favouritesJobs
)

export const getFavouritesProviders = createSelector(
  data,
  data => data.favouritesProviders
)

export const getFullLibraryData = createStructuredSelector({
  favouritesJobs: getFavouritesJobs,
  favouritesSP: getFavouritesProviders
})
