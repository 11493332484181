import { compose } from 'redux'
import { gooogleApiKey } from 'Constants'

import { withNamespaces } from 'react-i18next'
import { GoogleApiWrapper } from 'google-maps-react'

import Component from './MapModal'

export default compose(
  withNamespaces(),
  GoogleApiWrapper({
    apiKey: gooogleApiKey,
    libraries: ['places']
  })
)(Component)
