import React from 'react'
import styled from 'styled-components'
import { AuthFormWrapper, FormTitle as Title } from 'Components/ui'
import { fontFamily, fontSize, sizes } from 'Constants'

export const StyledAuthFormWrapper = styled(props => <AuthFormWrapper {...props} />)`
  @media (max-width: ${sizes.phone}px) {
    width: 100%;
  }
`

export const FormTitle = styled(props => <Title {...props} />)`
  font-size: ${fontSize.h2};
  font-family: ${fontFamily};
`

export const BtnSubmitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`
