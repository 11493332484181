import arrowLeft from './arrow-left.svg'
import arrowRight from './arrow-right.svg'
import arrowRightBlack from './angle-right-solid.svg'
import arrowLeftBlack from './angle-left-solid.svg'
import arrowBack from './arrow-back.svg'
import arrowDown from './arrow-down.svg'
import arrowDownPng from './arrow-down.png'

export const arrows = {
  arrowLeft,
  arrowRight,
  arrowRightBlack,
  arrowLeftBlack,
  arrowBack,
  arrowDown,
  arrowDownPng
}

export default arrows
