import React from 'react'
import { withNamespaces } from 'react-i18next'
import { jobPosts } from 'Routes/routesConstants'
import { PostJobWrapper, PostJobAdd } from './styles'

const PostJob = () => {
  return (
    <PostJobWrapper>
      <PostJobAdd to={jobPosts} />
    </PostJobWrapper>
  )
}

export const PostJobBtn = withNamespaces('headerBlock')(PostJob)
