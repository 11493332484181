import styled, { keyframes } from 'styled-components'
import { fontFamily, fontSize } from 'Constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const scaleAnimation = keyframes`
  0% {
    opacity: 1;
  }
  
  50% {
    opacity: 0.3;
  }
  
  100% {
    opacity: 1;
  }
`

export const Wrapper = styled.button`
  width: ${props => props.size || '40'}px;
  height: ${props => props.size || '40'}px;
  border-radius: 50%;
  border: none;
  outline: none;
  font-size: ${fontSize.default};
  font-family: ${fontFamily};
  cursor: ${p => (p.disabled ? 'default' : 'pointer')};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  background: ${p =>
    p.disabled
      ? 'silver'
      : `linear-gradient(
    to right,
    rgba(10, 148, 241, 1) 0%,
    rgba(10, 148, 241, 1) 56%,
    rgba(100, 195, 255, 1) 100%
  )`};
  color: ${props => props.theme.colors?.joinButtonColor};
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    width: ${props => props.iconSize || '20'}px !important;
    animation: ${p => p.isLoading && scaleAnimation} 0.5s linear infinite;
    & > path {
      fill: ${props => (props.active ? 'red' : 'white')};
    }
  }
  &:last-child {
    margin-left: 10px;
  }
  &:hover {
    ${p => !p.disabled && 'opacity: 0.8'};
  }
`

export const Icon = styled(FontAwesomeIcon)``
