import React from 'react'
import _ from 'lodash'

import { compose } from 'redux'
import { connect } from 'react-redux'

import { acceptRequest, rejectRequest } from 'Redux/actions/requests'

import { toast } from 'react-toastify'

function withRequests(Component) {
  return class extends React.Component {
    state = {
      loadRequestId: null
    }

    handleAccept = (job, callback) => {
      const { acceptRequest } = this.props

      const requestId = _.get(job, 'jobRequest._id')
      const customerId = _.get(job, 'author.username')
      const jobId = _.get(job, '_id')

      acceptRequest({ requestId, customerId, jobId }, error => {
        !error && toast.success('SUCCESS')
        callback && callback()
      })
    }

    handleReject = (job, callback) => {
      const { rejectRequest } = this.props

      const requestId = _.get(job, 'jobRequest._id')

      rejectRequest(requestId, error => {
        !error && toast.success('SUCCESS')
        callback && callback()
      })
    }

    render() {
      const { loadRequestId } = this.state

      return (
        <Component
          {..._.omit(this.props, ['acceptRequest', 'rejectRequest'])}
          loadRequestId={loadRequestId}
          onAcceptRequest={this.handleAccept}
          onRejectRequest={this.handleReject}
        />
      )
    }
  }
}

const actions = {
  acceptRequest,
  rejectRequest
}

export default compose(connect(null, actions), withRequests)
