import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import Component from './VerifyEmail'

const selector = createStructuredSelector({})

const actions = {}

export default connect(
  selector,
  actions
)(Component)
