import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'

import {
  loadChatList,
  sendMessage,
  blockChat,
  getMessageDetailAndActive,
  markChatsAsRead,
  markChatAllAsRead
} from 'Redux/actions/chats'
import { socketAuthentificate } from 'Redux/actions/socket'

import { getChats, getActiveChat } from 'Redux/selectors/chats'
import { getProfile } from 'Redux/selectors/user'
import { getAccessToken } from 'Redux/selectors/auth'

import Component from './Chat'

const actions = {
  onLoadChatList: loadChatList,
  onSendMessage: sendMessage,
  onBlockChat: blockChat,
  getMessageDetail: getMessageDetailAndActive,
  onMarkAsRead: markChatsAsRead,
  onLoadIsUnreadMessage: markChatAllAsRead,
  onSocketAuthentificate: socketAuthentificate
}

const selectors = createStructuredSelector({
  user: getProfile,
  chats: getChats,
  activeChat: getActiveChat,
  token: getAccessToken
})

export default compose(
  withRouter,
  withNamespaces(['landingPage', 'homePage', 'findJobPage']),
  connect(
    selectors,
    actions
  )
)(Component)
