import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from './api'
import { getParamsWithUnits } from 'Helpers/user'

import { getSpecificJob } from 'Redux/selectors/jobList'
import { getProfile } from 'Redux/selectors/user'

import { JOBS_ELEMENTS_PER_PAGE } from 'Constants/pagination'

export const POST_JOB = createAsyncAction('job/POST_JOB')
export const postJob = (prepearedData, callback) =>
  apiCall({
    method: 'post',
    endpoint: '/job',
    query: prepearedData,
    types: POST_JOB,
    callback
  })

export const GET_JOB_DESCRIPTION = createAsyncAction('job/GET_JOB_DESCRIPTION')
export const jobDescription = (id, callback) =>
  apiCall({
    method: 'get',
    endpoint: `/job/${id}`,
    types: GET_JOB_DESCRIPTION,
    preFormat: getParamsWithUnits,
    postFormat: job => {
      if (job.payload.author === null) {
        return {
          error: {
            code: 'notifications:jobLongerAvailable'
          }
        }
      }
      return job
    },
    callback
  })

export const GET_JOB_LIST = createAsyncAction('job/GET_JOB_LIST')
export const jobList = ({
  filters = {},
  callback = () => {},
  isPagination = false,
  isClearRemoved = false,
  savedFilters = {}
}) => {
  // console.log('JOBS_ELEMENTS_PER_PAGE', JOBS_ELEMENTS_PER_PAGE)

  // console.log('&&& action filters', filters)
  // console.log('&&& action callback', callback)
  // console.log('&&& action isPagination', isPagination)
  // console.log('&&& action isClearRemoved', isClearRemoved)
  // console.log('&&& action savedFilters', savedFilters)

  return apiCall({
    endpoint: '/jobs',
    types: GET_JOB_LIST,
    method: 'GET',
    qsParams: {
      ...filters,
      elements_per_page: JOBS_ELEMENTS_PER_PAGE
    },
    meta: {
      isPagination,
      filters: savedFilters,
      isClearRemoved
    },
    preFormat: getParamsWithUnits,
    callback
  })
}

export const UPDATE_JOB = createAsyncAction('job/UPDATE_JOV')
export const updateJob = (preparedData, id, callback) =>
  apiCall({
    method: 'put',
    endpoint: `/job/${id}`,
    query: preparedData,
    types: UPDATE_JOB,
    callback
  })

export const APPLY_JOB = createAsyncAction('job/APPLY_JOB')
export const applyJob = (id, callback) =>
  apiCall({
    method: 'post',
    endpoint: '/applications/apply',
    query: {
      jobId: id
    },
    types: APPLY_JOB,
    postFormat: (data, store) => {
      const { payload } = data
      const job = getSpecificJob(store.getState()) || {}

      if (job._id === payload.jobId) {
        const user = getProfile(store.getState())

        return {
          ...data,
          payload: {
            ...data.payload,
            user
          }
        }
      }

      return data
    },
    callback
  })

export const DELETE_JOB = createAsyncAction('job/DELETE_JOB')
export const deleteJob = (id, callback) =>
  apiCall({
    method: 'delete',
    endpoint: `/job/${id}`,
    meta: {
      id
    },
    types: DELETE_JOB,
    callback
  })

export const REMOVE_JOB = 'job/REMOVE'
export const removeJob = id => ({
  type: REMOVE_JOB,
  id
})
