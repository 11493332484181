import styled from 'styled-components'
import { Button } from '../Button'

const SelectBtn = styled(Button)`
  font-size: 14px;
  margin: 5px 0;
  padding: 0 5px;
  font-weight: bold;
  width: auto;
  min-width: 100px;
  &:first-child {
    margin-top: 25px;
  }
`

const UseButton = styled(SelectBtn)`
  background: transparent;
  border: solid 1px ${p => p.theme.colors.watchButtonColor};
  color: ${p => p.theme.colors.watchButtonColor};
  &:hover {
    background: transparent;
  }
`

export const SubsctioptionButtons = {
  SelectBtn,
  UseButton
}
