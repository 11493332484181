import styled from 'styled-components'
import { getThemeField } from 'Theme'
import { sizes } from 'Constants'
export const formMaxWidth = 900

/* eslint-disable max-len  */
export const FormContainer = styled.div`
  min-height: calc(100vh - 150px);
  width: 40rem;
  max-width: 90%;
  border-radius: 10px;
  position: relative;
  background-color: ${getThemeField('palette.white')};
  margin-top: 20px;
  margin-bottom: 0;
  padding: 20px 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  @media (max-width: ${sizes.phone}px) {
    width: 90%;
  }
`
/* eslint-enable max-len */

export const FormContainerFooter = styled.div`
  position: absolute;
  bottom: 0;
  height: 6em;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${getThemeField('palette.white')};
`

export const ScrollChattingPart = styled.div`
  flex-basis: 65%;
  max-width: 65%;
  padding: 0 0 30px 30px;
  @media (max-width: ${sizes.desktop}px) {
    padding: 0 0 30px;
  }
  @media (max-width: ${p => p.theme.sizes.tablet}px) {
    max-width: 100%;
  }
`
