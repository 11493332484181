import { createReducer } from 'Helpers/redux'
import { inSocketTypes } from 'Constants/socketEventTypes'
import _ from 'lodash'

import { LOAD_NOTIFICATIONS, MARK_AS_READ } from 'Redux/actions/notifications'

const initialState = {
  data: [],
  currentPage: 0,
  isListEnd: true,
  list: [],
}

const handlers = {
  [LOAD_NOTIFICATIONS.SUCCESS]: (state, { payload }) => {
    const { currentPage, isListEnd, list } = payload
    let newState = {
      currentPage,
      isListEnd,
    }
    const newList = list

    if (currentPage === 0) {
      newState.list= newList
    } else {
      newState.list =[...state.list, ...newList]
    }

    return newState
  },
  [`socket/${inSocketTypes.notificationReceived.toUpperCase()}`]: (
    state,
    { payload }
  ) => ({
    ...state,
    list:
      payload.notificationType === 'jobUnavailable'
        ? state.list
        : _.uniqBy([payload, ...state.list], '_id')
  }),
  [MARK_AS_READ.SUCCESS]: state => ({
    ...state,
    list: state.list.map(el => ({
      ...el,
      read: true
    }))
  })
}

export default createReducer(initialState, handlers)
