import styled, { keyframes } from 'styled-components'
import { Link } from 'react-router-dom'
import { fontSize, sizes } from 'Constants'

export const Wrapper = styled.div`
  width: 70rem;
  margin: 0 auto;
  max-width: 90%;
  @media (max-width: ${sizes.desktop}px) {
    width: 90%;
  }
  @media (min-width: ${sizes.tablet}px) {
    width: 90%;
  }
`

export const BoldSubTitle = styled.h3`
  font-weight: 600;
  font-size: ${fontSize.h2};
  margin: 1em 0 3em;
  color: ${props => props.theme.colors.defaultFontGrey};
  text-align: center;
  @media (max-width: ${sizes.tablet}px) {
    font-size: ${fontSize.h3};
    margin: 30px 0;
  }
`

export const JumbotronWrapper = styled.div`
  height: calc(100vh - 75px);
  background-image: url('/images/jumbotron.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
  overflow: hidden;

  & :after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  @media (max-width: ${sizes.tablet}px) {
    background-image: url('/images/jumbotron-tablet.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: 75px;
  }
  @media (max-width: ${sizes.phone}px) {
    background-image: url('/images/jumbotron-phone.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: 75px;
  }
`

export const MainTitle = styled.h2`
  text-align: center;
  margin: 0.1em;
  font-size: ${fontSize.large};
  font-weight: 300;
  color: ${props => props.theme.colors.defaultGrey};
  @media (max-width: ${sizes.desktop}px) {
    font-size: ${fontSize.h1};
  }
  @media (max-width: ${sizes.tablet}px) {
    font-size: ${fontSize.h2};
  }
  @media (max-width: ${sizes.phone}px) {
    font-size: ${fontSize.h3};
  }
`

export const WidthLimiter = styled.div`
  width: 90%;
  max-width: 1168px;
  margin: 0 auto;
`

const vertKeyframe = keyframes`
	0% { visibility: hidden; }
	5% { opacity: 0; -webkit-transform: rotateX(180deg); }
	10% { opacity: 1; -webkit-transform: translateY(0px); }
	25% { visibility: visible; -webkit-transform: translateY(0px); }
	30% { opacity: 0; -webkit-transform: translateY(0px); }
	65% { opacity: 0; }
	90% { visibility: hidden; }
`

export const VerticalFlip = styled.div`
  display: flex;
  text-indent: 8px;
  float: left;
  height: 60px;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  @media (max-width: ${sizes.phone}px) {
    margin-top: 20px;
    margin-bottom: 0;
  }

  & span {
    animation: ${vertKeyframe} 12s ease infinite 0s;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    backface-visibility: hidden;
  }
  & span:nth-child(2) {
    animation-delay: 3s;
  }
  & span:nth-child(3) {
    animation-delay: 6s;
  }
  & span:nth-child(4) {
    animation-delay: 9s;
  }
  & span:nth-child(5) {
    animation-delay: 12s;
  }
`

export const VerticalFlipSpan = styled.span`
  font-weight: 500;
  margin-top: 2em;
  font-size: ${fontSize.h1};
  max-width: 600px;
  text-align: center;
  white-space: normal;
  ${props => (props.purple ? 'color: ' + props.theme.colors.purple + ';' : '')};
  ${props => (props.pink ? 'color: ' + props.theme.colors.pink + ';' : '')};
  ${props => (props.blue ? 'color: ' + props.theme.colors.blue + ';' : '')};
  @media (max-width: ${sizes.desktop}px) {
    font-size: ${fontSize.h2};
    padding-top: 1em;
  }
  @media (max-width: ${sizes.phone}px) {
    font-size: ${fontSize.h3};
    margin-top: 1em;
  }
`

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -4px;
`

export const JoinButton = styled(Link)`
  margin: 4px 20px;
  padding: 10px 0;
  width: 35%;
  text-transform: uppercase;
  font-size: ${fontSize.h4};
  border-radius: 7px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background: linear-gradient(
    to right,
    rgba(10, 148, 241, 1) 0%,
    rgba(10, 148, 241, 1) 56%,
    rgba(100, 195, 255, 1) 100%
  );
  color: ${props => props.theme.colors.joinButtonColor};
  box-shadow: 8px 10px 20px 0 rgba(46, 61, 73, 0.15);
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 2px 4px 8px 0 rgba(46, 61, 73, 0.2);
  }
  @media (max-width: ${sizes.tablet}px) {
    width: 60%;
    font-size: ${fontSize.h4};
  }
  @media (max-width: ${sizes.phone}px) {
    width: 100%;
    margin: 4px 0px;
    font-size: ${fontSize.h5};
  }
`

export const VideoButton = styled.span`
  margin: 4px 20px;
  padding: 10px 0;
  width: 35%;
  text-transform: uppercase;
  font-size: ${fontSize.h3};
  border-radius: 7px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  background: ${props => props.theme.colors.watchButton};
  background: linear-gradient(to right, #ccc 0%, #eee 50%, #dce0e6 100%);
  color: ${props => props.theme.colors.watchButtonColor};
  box-shadow: 8px 10px 20px 0 rgba(46, 61, 73, 0.15);
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 2px 4px 8px 0 rgba(46, 61, 73, 0.2);
  }
  @media (max-width: ${sizes.tablet}px) {
    width: 60%;
    font-size: ${fontSize.h4};
  }
  @media (max-width: ${sizes.phone}px) {
    width: 100%;
    margin: 4px 0px;
    font-size: ${fontSize.h5};
  }
`
