import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { rehydrateToken, oauthAuthenticate } from 'Redux/actions/auth'
import { services, popularServices } from 'Redux/actions/services'
import { getProfile } from 'Redux/selectors/user'
import { getServices } from 'Redux/selectors/services'
import { getPopularServices } from 'Redux/selectors/popularServices'
import { onDonate } from 'Redux/actions/paymentsTransactions'

import Component from './LandingPage'

const selector = createStructuredSelector({
  services: getServices,
  user: getProfile,
  popularServices: getPopularServices
})

const actions = {
  onGetServices: services,
  onGetPopularServices: popularServices,
  onTokenRehydrate: rehydrateToken,
  onOauthAuthorize: oauthAuthenticate,
  onDonate
}

export const LandingPage = connect(
  selector,
  actions
)(Component)
