import React from 'react'
import PropsTypes from 'prop-types'
import Truncate from 'Components/ui/Truncate'
import { withNamespaces } from 'react-i18next'
import { linkAppleStore, linkPlayStore } from 'Constants/api'
import {
  BackGroundWrapper,
  DefaultBoldText,
  DefaultBold,
  GridDownloadApp,
  DownloadLeft,
  DownloadRight,
  DownloadMarket,
  DownloadMarketImage,
  DowloadMarketLink
} from './styles'
import DownloadCarousel from './DownloadCarousel'

const DownloadBlock = ({ t }) => (
  <BackGroundWrapper>
    <GridDownloadApp>
      <DownloadLeft>
        <DownloadCarousel />
      </DownloadLeft>
      <DownloadRight>
        <DefaultBoldText>{t('downloadSpotJobs')}</DefaultBoldText>
        <Truncate t={t}>{t('downloadText')}</Truncate>
        <DefaultBold>{t('connectingCommunities')}</DefaultBold>
        <DownloadMarket>
          <DowloadMarketLink href={linkAppleStore} target="_blank">
            <DownloadMarketImage src="images/app_store.png" />
          </DowloadMarketLink>
          <DowloadMarketLink href={linkPlayStore} target="_blank">
            <DownloadMarketImage src="images/play_market.png" />
          </DowloadMarketLink>
        </DownloadMarket>
      </DownloadRight>
    </GridDownloadApp>
  </BackGroundWrapper>
)

DownloadBlock.propTypes = {
  t: PropsTypes.func
}
export default withNamespaces('landingPage')(DownloadBlock)
