import { createReducer } from 'Helpers/redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import _ from 'lodash'

import { LOAD_APPLIED_JOB, CANCEL_APPLIED_JOB } from 'Redux/actions/appliedJobs'
import { CANCEL_BOOKED_JOB } from 'Redux/actions/bookedJobs'
import { jobStatuses } from 'Constants/jobStatuses'

const persistConfig = {
  key: 'appliedJobs',
  storage,
  blacklist: []
}

const initialState = {
  data: []
}

const handlers = {
  [LOAD_APPLIED_JOB.SUCCESS]: (prevState, { payload }) => ({
    ...prevState,
    data: _.uniqBy(payload.list, el => el._id)
  }),
  [CANCEL_BOOKED_JOB.SUCCESS]: (state, { meta, payload }) => {
    if (payload?.jobStatus === jobStatuses.posted) {
      return {
        ...state,
        data: state.data.filter(el => el._id !== meta.jobId)
      }
    }

    const index = state.data.findIndex(el => el._id === meta.jobId)

    if (index > -1) {
      return {
        ...state,
        data: [
          ...state.data.slice(0, index),
          {
            ...state.data[index],
            ...payload,
          },
          ...state.data.slice(index + 1)
        ]
      }
    }

    return { ...state }
  },
  [CANCEL_APPLIED_JOB.SUCCESS]: (state, { meta, payload }) => {
    if (payload?.jobStatus === jobStatuses.posted) {
      return {
        ...state,
        data: state.data.filter(el => el._id !== meta.jobId)
      }
    }

    const index = state.data.findIndex(el => el._id === meta.jobId)

    if (index > -1) {
      return {
        ...state,
        data: [
          ...state.data.slice(0, index),
          {
            ...state.data[index],
            ...payload,
          },
          ...state.data.slice(index + 1)
        ]
      }
    }

    return { ...state }
  },
}

export default persistReducer(
  persistConfig,
  createReducer(initialState, handlers)
)
