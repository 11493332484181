import styled from 'styled-components'
import { icons } from 'Assets/icons'
import { sizes } from 'Constants'

export const Container = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  &:hover .edit {
    display: block;
  }
  margin-top: 10px;
  ${({isHideBg}) => !isHideBg && ({backgroundImage: `url(${icons.faceVerify})`})};
  background-position: ${props => (props.isCenter ? 'center' : 'left')};
  background-size: cover;
  &:first-child {
    margin-right: 20px;
  }
`

export const Button = styled.button.attrs({
  type: 'button'
})`
  width: 100px;
  height: 100px;
  background: rgba(90, 132, 235, 0.2);
  border-radius: 5px;
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 10px;
  transition: all 0.2s ease-in;
  cursor: pointer;
  outline: none;
  border: 1px dashed rgba(90, 132, 235, 1);
  &:hover {
    background: rgba(90, 132, 235, 0.5);
    div:before,
    div:after {
      background-color: #fff;
    }
  }
`

export const Icon = styled.div`
  width: 40%;
  height: 40%;
  position: relative;
  margin: 0 auto;
  &:before {
    top: 0;
    left: 50%;
    width: 4px;
    height: 100%;
    margin-left: -2px;
    content: '';
    position: absolute;
    background-color: rgba(90, 132, 235, 0.5);
  }
  &:after {
    top: 50%;
    left: 0;
    width: 100%;
    height: 4px;
    margin-top: -2px;
    content: '';
    position: absolute;
    background-color: rgba(90, 132, 235, 0.5);
  }
`

export const Input = styled.input.attrs({
  type: 'file',
  accept: 'image/*'
})`
  opacity: 0;
  position: absolute;
  z-index: -1;
  width: 0;
`

export const EditIcon = styled.img`
  height: 20px;
  width: 20px;
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
  display: none;
`

export const Image = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 5px;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #d0dbe4;
  cursor: pointer;
  object-fit: cover;
  &:hover {
    opacity: 0.5;
  }
`

export const DeleteButton = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  color: #fff;
  background: rgba(90, 132, 235, 1);
  border-radius: 50%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1;

  & > img {
    height: 11px;
    width: 11px;
  }
`

export const VerifyIcon = styled.img`
  width: 100%;
  height: 100%;
`

export const IdVerifyError = styled.div`
  color: ${props => props.theme.colors.iconRed};
  margin-top: 15px;
`

export const Content = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  ${({ isCenter }) =>
    isCenter && {
      alignItems: 'center'
    }}
`

export const CameraIcon = styled.img`
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
  border: none;
  background: #fff;
  cursor: pointer;
  ${({ isCamera }) =>
    isCamera && {
      position: 'absolute',
      bottom: 10,
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }}
`

export const UploadIcon = styled.img`
  width: 40px;
  ${({ isUpload }) => isUpload && ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: 0.3
   })}
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
`

export const ArrowIcon = styled.img`
  height: 40px;
  width: 40px;
  border: 1px solid #ddd;
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`

export const Title = styled.span`
  align-items: center;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
`

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 20px;
`

export const CameraContent = styled.img`
  width: 360px;
  height: 200px;
  margin-bottom: 20px;
  object-fit: cover;
`

export const UploadContent = styled.div`
  background-color: ${props => props.theme.colors.mainBackground};
  width: 360px;
  height: 200px;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
  border: 2px dashed ${props => props.theme.colors.lightGrayBorder};
  @media (max-width: ${sizes.phone}px) {
    width: 85vw;
    height: 50vw;
  }
`

export const cameraStyle = {
  position: 'relative',
  width: '95%',
  maxHeight: '65vh',
  border: '1px solid #000'
}

export const CameraMode = styled.img`
  height: 30px;
  width: 30px;
  object-fit: cover;
  border-radius: 50%;
  border: none;
  background: #fff;
  cursor: pointer;
  position: absolute;
  top: 70px;
  right: 35px;
`