import qs from 'qs'
import moment from 'moment'
import { isUSA } from './user'

export const createCalendarEvent = (job, user) => {
  const url = 'https://calendar.google.com/calendar/render'
  const action = 'TEMPLATE'
  const text =
    'SpotJobs ' +
    job.title +
    '' +
    moment(job.startDate).format(
      `DD MMM YYYY ${isUSA(user) ? 'hh:mmA' : 'HH:mm'}`
    )
  const location = job.streetAddress
  const allDay = true

  const params = qs.stringify({ action, text, location, allDay })

  const result = `${url}?${params}`

  window.open(result, '_blank')
}
