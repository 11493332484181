import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withNamespaces } from 'react-i18next'
import { TabWrap, TabList, TabLi, Span } from './styles'

class Tabs extends Component {
  state = {
    activeTabId: this.props.initialRoute || 'a'
  }

  handleSetId = id => {
    const { onClickLink } = this.props

    this.setState(
      {
        activeTabId: id
      },
      () => {
        if (_.isFunction(onClickLink)) onClickLink(id)
      }
    )
  }

  render() {
    const { activeTabId } = this.state
    const { options, t, activeTabIndex, isHidden = false } = this.props

    if (isHidden) return null

    const renderTabList = options.map(menu => (
      <TabLi key={menu.id} onClick={() => this.handleSetId(menu.setId)}>
        <Span active={(activeTabIndex || activeTabId) === menu.setId}>
          {t(menu.text)}
        </Span>
      </TabLi>
    ))
    return (
      <TabWrap>
        <TabList>{renderTabList}</TabList>
      </TabWrap>
    )
  }
}

export const HeaderTabs = withNamespaces(['headerBlock', 'homePage'])(Tabs)

Tabs.propTypes = {
  activeTabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  initialRoute: PropTypes.string,
  options: PropTypes.array,
  t: PropTypes.func,
  onClickLink: PropTypes.func
}
