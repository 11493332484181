import { createAsyncAction, createSocketAction } from 'Helpers/redux'
import { apiCall, socketEvent } from 'Redux/actions/api'
import { api } from 'Configs/App'
import { outSocketTypes } from 'Constants/socketEventTypes'

export const LOAD_CHAT_LIST = createAsyncAction('chats/LOAD_LIST')
export const loadChatList = (params, callback) => {
  return apiCall({
    url: api.second,
    endpoint: '/chat-list',
    types: LOAD_CHAT_LIST,
    qsParams: {
      ...params
    },
    method: 'GET',
    callback
  })
}

export const CONNECT_TO_CHAT = createAsyncAction('chats/CONNECT')
export const connectToChat = (username, jobId, jobTitle, callback) =>
  apiCall({
    url: api.second,
    endpoint: '/chat/connect',
    types: CONNECT_TO_CHAT,
    method: 'POST',
    query: {
      userId: username,
      jobId: jobId,
      jobTitle: jobTitle,
      blockedBy: 'disabled'
    },
    callback
  })

export const SEND_MESSAGE = createSocketAction('chats/SEND_MESSAGE')
export const sendMessage = (
  { chatId, message, jobId, userReceived, type='regular_message', idFail },
  userName
) =>
  socketEvent({
    endpoint: outSocketTypes.sendMessage,
    types: SEND_MESSAGE,
    meta: {
      author: userName,
      chatId,
      message,
      type,
      idFail
    },
    query: {
      chatId,
      jobId,
      userReceived,
      message,
    }
  })

export const BLOCK_CHAT = createAsyncAction('chats/BLOCK')
export const blockChat = (chatId, callback) =>
  apiCall({
    endpoint: '/chat/block',
    types: BLOCK_CHAT,
    method: 'PUT',
    url: process.env.REACT_APP_SECOND_API,
    query: {
      chatId
    },
    meta: {
      chatId
    },
    callback
  })

export const GET_MESSAGE_DETAIL = createAsyncAction('chats/GET_MESSAGE_DETAIL')
export const getMessageDetail = ({ id, chat_id }, callback) =>
  apiCall({
    endpoint: `chat/${id}`,
    types: GET_MESSAGE_DETAIL,
    method: 'GET',
    url: process.env.REACT_APP_SECOND_API,
    qsParams: {
      chat_id
    },
    callback
  })

export const GET_MESSAGE_DETAIL_AND_ACTIVE = createAsyncAction('chats/GET_MESSAGE_DETAIL_AND_ACTIVE')
export const getMessageDetailAndActive = ({ id, chat_id }, callback) =>
  apiCall({
    endpoint: `chat/${id}`,
    types: GET_MESSAGE_DETAIL_AND_ACTIVE,
    method: 'GET',
    url: process.env.REACT_APP_SECOND_API,
    qsParams: {
      chat_id
    },
    callback
  })

export const MARK_AS_READ = createAsyncAction('chats/MARK_READ')
export const markChatsAsRead = (chatId, isServiceProvider, callback) =>
  apiCall({
    url: api.second,
    endpoint:`/chat/${chatId}/mark-as-read`,
    types: MARK_AS_READ,
    method: 'PUT',
    meta: {
      chatId,
      isServiceProvider
    },
    callback
  })
export const MARK_ALL_AS_READ = createAsyncAction('chats/MARK_ALL_READ')
export const markChatAllAsRead = (userId) =>
  apiCall({
    url: api.second,
    endpoint: `/chats/unread-message`,
    types: MARK_ALL_AS_READ,
    method: 'GET',
    meta: {
      userId
    }
  })
