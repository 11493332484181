import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    to bottom,
    #1e5799 0%,
    #2989d8 0%,
    #207cca 15%,
    #fff 100%
  );
  padding: 30px 0;
  b, p, h2 {
    font-weight: revert;
  }
  ul {
   list-style-type: disc;
   list-style-position: inside;
  }
  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }
`

export const Wrapper = styled.div`
  width: 70rem;
  max-width: 90%;
  margin: 0 auto;
`

export const Article = styled.article`
  width: 100%;
  background: #fff;
  padding: 30px;
  margin: 30px 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  line-height: 2;
`

export const InnerWrap = styled.div``
