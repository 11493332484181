import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import _ from 'lodash'
import * as routes from 'Routes/routesConstants'
import { Switch, Route } from 'react-router-dom'

import { PageWrapper, ContentWrapper, RightSideBarWrapper } from 'Components/ui'
import {
  BreadCrumbs,
  Header,
  Footer,
  Ad,
  JobCard,
  ListAd,
  Tracker,
  RightSidebar,
  ProgressBar,
  BurgerMenu
} from 'Components/blocks'
import { Wrapper, CardsWrapper, Content } from './styled'
import { getJobsBC, getTrackerBC } from './memoize'
import { path } from './config'

class TrackNow extends Component {
  state = {
    isRequest: true
  }

  componentDidMount() {
    this.handleUpdateList()
  }

  handleUpdateList = () => {
    const { onLoadTrackJobs } = this.props

    this.setState({ isRequest: true }, () => {
      onLoadTrackJobs(() => this.setState({ isRequest: false }))
    })
  }

  getCards = memoize(data =>
    data.map((el, index) => (
      <Fragment key={el._id}>
        <JobCard
          {...el}
          path={`${routes.myJobs}${routes.booked}/${el._id}`}
          onTrackClick={() => this.handleOpenTrack(el)}
        />
        <ListAd data={data} index={index} />
      </Fragment>
    ))
  )

  handleOpenTrack = job => {
    const { onStartTracking, geoLocation } = this.props

    onStartTracking(job, geoLocation)
  }

  renderJobs = () => {
    const { jobs, t } = this.props

    const breadCrumbs = getJobsBC(t)
    const cards = this.getCards(jobs)

    return (
      <>
        <BreadCrumbs data={breadCrumbs} />
        <ContentWrapper>
          <Content>
            <CardsWrapper>
              <Ad />
              {cards}
              {!_.isEmpty(cards) && <Ad />}
            </CardsWrapper>
            <RightSideBarWrapper>
              <RightSidebar />
            </RightSideBarWrapper>
          </Content>
        </ContentWrapper>
      </>
    )
  }

  renderTracker = props => {
    const { t, profile } = this.props
    const { userId } = props.match.params

    const userName =
      userId === _.get(profile, 'username') ? profile.short_name : '...'

    const breadCrumbs = getTrackerBC(t, userName)

    return (
      <>
        <BreadCrumbs data={breadCrumbs} />
        <ContentWrapper>
          <Tracker {...props} />
        </ContentWrapper>
      </>
    )
  }

  render() {
    const { user } = this.props
    const { isRequest } = this.state

    return (
      <PageWrapper>
        <ProgressBar percent={isRequest ? 1 : 100} />
        <BurgerMenu user={user} />
        <Header postJob={true} />
        <Wrapper>
          <Switch>
            <Route exact path={path.tracker} render={this.renderTracker} />
            <Route render={this.renderJobs} />
          </Switch>
        </Wrapper>
        <Footer black isAuth={user} />
      </PageWrapper>
    )
  }
}

TrackNow.propTypes = {
  jobs: PropTypes.array,
  t: PropTypes.func,
  user: PropTypes.object,
  onLoadTrackJobs: PropTypes.func,
  onStartTracking: PropTypes.func
}

TrackNow.defaultProps = {
  jobs: [],
  user: {}
}

export default TrackNow
