import memoize from 'memoize-one'
import * as routes from 'Routes/routesConstants'
import { path } from './config'

export const getSPListBC = memoize(t => [
  {
    displayName: t('homePage:myRequests')
  },
  {
    displayName: t('homePage:hiringRequests')
  }
])

export const getJobsBC = memoize(t => [
  {
    displayName: t('homePage:myRequests')
  },
  {
    displayName: t('homePage:bookingRequests')
  }
])

export const getJobDetailBC = memoize((t, jobTitle) => [
  {
    displayName: t('homePage:myRequests'),
    url: routes.myRequests
  },
  {
    displayName: t('homePage:bookingRequests'),
    url: path.bookingRequests
  },
  {
    displayName: jobTitle
  }
])

export const breadSPProfileBC = memoize((t, username) => [
  {
    displayName: t('homePage:myRequests'),
    url: routes.myRequests
  },
  {
    displayName: t('homePage:hiringRequests'),
    url: path.hiringRequests
  },
  {
    displayName: username
  }
])

export const getPeopleWhoAppliedBC = memoize(
  (t, jobId, jobTitle, requestId) => [
    {
      displayName: t('homePage:myRequests'),
      url: routes.myRequests
    },
    {
      displayName: t('homePage:bookingRequests'),
      url: path.bookingRequests
    },
    {
      displayName: jobTitle,
      url: `${path.bookingRequests}/${requestId}/${jobId}`
    },
    {
      displayName: t('appServiceProvider:totalServiceProviders')
    }
  ]
)

export const getAppliedProfileBC = memoize(
  (t, jobId, jobTitle, profile = {}, requestId) => [
    {
      displayName: t('homePage:myRequests'),
      url: routes.myRequests
    },
    {
      displayName: t('homePage:bookingRequests'),
      url: path.bookingRequests
    },
    {
      displayName: jobTitle,
      url: `${path.bookingRequests}/${requestId}/${jobId}`
    },
    {
      displayName: t('appServiceProvider:totalServiceProviders'),
      url: `${path.bookingRequests}/${requestId}/${jobId}${routes.appliedServiceProviders}`
    },
    {
      displayName: profile.short_name
    }
  ]
)
