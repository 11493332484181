import flagUs from './english.svg'
import flagFr from './french.svg'
import flagDe from './deutsch.svg'
import flagIn from './indian.svg'
import flagIt from './italian.svg'
import flagPg from './portuguese.svg'
import flagRu from './russian.svg'
import flagSp from './spanish.svg'

import ethiopia from './flags/001-ethiopia.svg'
import oman from './flags/002-oman.svg'
import tanzania from './flags/003-tanzania.svg'
import slovenia from './flags/004-slovenia.svg'
import puertoRico from './flags/005-puerto-rico.svg'
import mozambique from './flags/006-mozambique.svg'
import iraq from './flags/007-iraq.svg'
import lebanon from './flags/008-lebanon.svg'
import uganda from './flags/009-uganda.svg'
import nigeria from './flags/010-nigeria.svg'
import italy from './flags/011-italy.svg'
import malta from './flags/012-malta.svg'
import tunisia from './flags/013-tunisia.svg'
import nicaragua from './flags/014-nicaragua.svg'
import elSalvador from './flags/015-el-salvador.svg'
import zambia from './flags/016-zambia.svg'
import wales from './flags/017-wales.svg'
import dominicanRepublic from './flags/018-dominican-republic.svg'
import qatar from './flags/019-qatar.svg'
import namibia from './flags/020-namibia.svg'
import mauritius from './flags/021-mauritius.svg'
import europeanUnion from './flags/022-european-union.svg'
import luxembourg from './flags/023-luxembourg.svg'
import hawaii from './flags/024-hawaii.svg'
import lithuania from './flags/025-lithuania.svg'
import jamaica from './flags/026-jamaica.svg'
import honduras from './flags/027-honduras.svg'
import myanmar from './flags/028-myanmar.svg'
import kenya from './flags/029-kenya.svg'
import cyprus from './flags/030-cyprus.svg'
import pakistan from './flags/031-pakistan.svg'
import latvia from './flags/032-latvia.svg'
import japan from './flags/033-japan.svg'
import kazakhstan from './flags/034-kazakhstan.svg'
import serbia from './flags/035-serbia.svg'
import scotland from './flags/036-scotland.svg'
import northKorea from './flags/037-north-korea.svg'
import uruguay from './flags/038-uruguay.svg'
import syria from './flags/039-syria.svg'
import guatemala from './flags/040-guatemala.svg'
import iceland from './flags/041-iceland.svg'
import jordan from './flags/042-jordan.svg'
import monaco from './flags/043-monaco.svg'
import spain from './flags/044-spain.svg'
import slovakia from './flags/045-slovakia.svg'
import unitedNations from './flags/046-united-nations.svg'
import panama from './flags/047-panama.svg'
import newZealand from './flags/048-new-zealand.svg'
import ecuador from './flags/049-ecuador.svg'
import romania from './flags/050-romania.svg'
import chile from './flags/051-chile.svg'
import finland from './flags/052-finland.svg'
import hungary from './flags/053-hungary.svg'
import belgium from './flags/054-belgium.svg'
import southKorea from './flags/055-south-korea.svg'
import malaysia from './flags/056-malaysia.svg'
import venezuela from './flags/057-venezuela.svg'
import norway from './flags/058-norway.svg'
import saudiArabia from './flags/059-saudi-arabia.svg'
import israel from './flags/060-israel.svg'
import czechRepublic from './flags/061-czech-republic.svg'
import colombia from './flags/062-colombia.svg'
import iran from './flags/063-iran.svg'
import argentina from './flags/064-argentina.svg'
import ukraine from './flags/065-ukraine.svg'
import germany from './flags/066-germany.svg'
import hongKong from './flags/067-hong-kong.svg'
import unitedArabEmirates from './flags/068-united-arab-emirates.svg'
import laos from './flags/069-laos.svg'
import ireland from './flags/070-ireland.svg'
import greece from './flags/071-greece.svg'
import denmark from './flags/072-denmark.svg'
import sweden from './flags/073-sweden.svg'
import peru from './flags/074-peru.svg'
import southAfrica from './flags/075-south-africa.svg'
import philippines from './flags/076-philippines.svg'
import france from './flags/077-france.svg'
import indonesia from './flags/078-indonesia.svg'
import egypt from './flags/079-egypt.svg'
import taiwan from './flags/080-taiwan.svg'
import cookIslands from './flags/081-cook-islands.svg'
import cocosIsland from './flags/082-cocos-island.svg'
import christmasIsland from './flags/083-christmas-island.svg'
import ceuta from './flags/084-ceuta.svg'
import americanSamoa from './flags/085-american-samoa.svg'
import morocco from './flags/086-morocco.svg'
import anguilla from './flags/087-anguilla.svg'
import corsica from './flags/088-corsica.svg'
import canaryIslands from './flags/089-canary-islands.svg'
import comoros from './flags/090-comoros.svg'
import centralAfricanRepublic from './flags/091-central-african-republic.svg'
import caymanIslands from './flags/092-cayman-islands.svg'
import britishIndianOceanTerritory from './flags/093-british-indian-ocean-territory.svg'
import bhutan from './flags/094-bhutan.svg'
import chad from './flags/095-chad.svg'
import capeVerde from './flags/096-cape-verde.svg'
import switzerland from './flags/097-switzerland.svg'
import benin from './flags/098-benin.svg'
import aruba from './flags/099-aruba.svg'
import abkhazia from './flags/100-abkhazia.svg'
import balearicIslands from './flags/101-balearic-islands.svg'
import andorra from './flags/102-andorra.svg'
import burundi from './flags/103-burundi.svg'
import antiguaAndBarbuda from './flags/104-antigua-and-barbuda.svg'
import alandIslands from './flags/105-aland-islands.svg'
import cameroon from './flags/106-cameroon.svg'
import brunei from './flags/107-brunei.svg'
import poland from './flags/108-poland.svg'
import bonaire from './flags/109-bonaire.svg'
import belarus from './flags/110-belarus.svg'
import barbados from './flags/111-barbados.svg'
import britishVirginIslands from './flags/112-british-virgin-islands.svg'
import bosniaAndHerzegovina from './flags/113-bosnia-and-herzegovina.svg'
import bermuda from './flags/114-bermuda.svg'
import belize from './flags/115-belize.svg'
import bahrain from './flags/116-bahrain.svg'
import albania from './flags/117-albania.svg'
import burkinaFaso from './flags/118-burkina-faso.svg'
import turkey from './flags/119-turkey.svg'
import basqueCountry from './flags/120-basque-country.svg'
import armenia from './flags/121-armenia.svg'
import afghanistan from './flags/122-afghanistan.svg'
import britishColumbia from './flags/123-british-columbia.svg'
import angola from './flags/124-angola.svg'
import azerbaijan from './flags/125-azerbaijan.svg'
import algeria from './flags/126-algeria.svg'
import botswana from './flags/127-botswana.svg'
import bangladesh from './flags/128-bangladesh.svg'
import cuba from './flags/129-cuba.svg'
import australia from './flags/130-australia.svg'
import costaRica from './flags/131-costa-rica.svg'
import cambodia from './flags/132-cambodia.svg'
import bolivia from './flags/133-bolivia.svg'
import croatia from './flags/134-croatia.svg'
import bulgaria from './flags/135-bulgaria.svg'
import galapagosIslands from './flags/136-galapagos-islands.svg'
import martinique from './flags/137-martinique.svg'
import tonga from './flags/138-tonga.svg'
import stLucia from './flags/139-st-lucia.svg'
import rapaNui from './flags/140-rapa-nui.svg'
import singapore from './flags/141-singapore.svg'
import palau from './flags/142-palau.svg'
import niue from './flags/143-niue.svg'
import macao from './flags/144-macao.svg'
import fiji from './flags/145-fiji.svg'
import faroeIslands from './flags/146-faroe-islands.svg'
import dominica from './flags/147-dominica.svg'
import vanuatu from './flags/148-vanuatu.svg'
import sierraLeone from './flags/149-sierra-leone.svg'
import seychelles from './flags/150-seychelles.svg'
import kosovo from './flags/151-kosovo.svg'
import england from './flags/152-england.svg'
import unitedStatesOfAmerica from './flags/153-united-states-of-america.svg'
import guinea from './flags/154-guinea.svg'
import orkneyIslands from './flags/155-orkney-islands.svg'
import turksAndCaicos from './flags/156-turks-and-caicos.svg'
import transnistria from './flags/157-transnistria.svg'
import tokelau from './flags/158-tokelau.svg'
import gambia from './flags/159-gambia.svg'
import stVincentAndTheGrenadines from './flags/160-st-vincent-and-the-grenadines.svg'
import southSudan from './flags/161-south-sudan.svg'
import somaliland from './flags/162-somaliland.svg'
import solomonIslands from './flags/163-solomon-islands.svg'
import vietnam from './flags/164-vietnam.svg'
import sintMaarten from './flags/165-sint-maarten.svg'
import sintEustatius from './flags/166-sint-eustatius.svg'
import saintKittsAndNevis from './flags/167-saint-kitts-and-nevis.svg'
import sabaIsland from './flags/168-saba-island.svg'
import pitcairnIslands from './flags/169-pitcairn-islands.svg'
import palestine from './flags/170-palestine.svg'
import ossetia from './flags/171-ossetia.svg'
import northernMarianasIslands from './flags/172-northern-marianas-islands.svg'
import nauru from './flags/173-nauru.svg'
import portugal from './flags/174-portugal.svg'
import montserrat from './flags/175-montserrat.svg'
import melilla from './flags/176-melilla.svg'
import mauritania from './flags/177-mauritania.svg'
import kuwait from './flags/178-kuwait.svg'
import guernsey from './flags/180-guernsey.svg'
import guam from './flags/181-guam.svg'
import grenada from './flags/182-grenada.svg'
import greenland from './flags/183-greenland.svg'
import thailand from './flags/184-thailand.svg'
import gibraltar from './flags/185-gibraltar.svg'
import gabon from './flags/186-gabon.svg'
import falklandIslands from './flags/187-falkland-islands.svg'
import virginIslands from './flags/188-virgin-islands.svg'
import austria from './flags/189-austria.svg'
import vaticanCity from './flags/190-vatican-city.svg'
import tubalu from './flags/191-tubalu.svg'
import turkmenistan from './flags/192-turkmenistan.svg'
import togo from './flags/193-togo.svg'
import bahamas from './flags/194-bahamas.svg'
import netherlands from './flags/195-netherlands.svg'
import azoresIslands from './flags/196-azores-islands.svg'
import suriname from './flags/197-suriname.svg'
import somalia from './flags/198-somalia.svg'
import sicily from './flags/199-sicily.svg'
import sardinia from './flags/200-sardinia.svg'
import saoTomeAndPrincipe from './flags/201-sao-tome-and-principe.svg'
import norfolkIsland from './flags/202-norfolk-island.svg'
import niger from './flags/203-niger.svg'
import micronesia from './flags/204-micronesia.svg'
import marshallIsland from './flags/205-marshall-island.svg'
import canada from './flags/206-canada.svg'
import mali from './flags/207-mali.svg'
import kyrgyzstan from './flags/208-kyrgyzstan.svg'
import guineaBissau from './flags/209-guinea-bissau.svg'
import eritrea from './flags/210-eritrea.svg'
import djibouti from './flags/211-djibouti.svg'
import curacao from './flags/212-curacao.svg'
import stBarts from './flags/213-st-barts.svg'
import sanMarino from './flags/214-san-marino.svg'
import northenCyprus from './flags/215-northen-cyprus.svg'
import liechtenstein from './flags/216-liechtenstein.svg'
import india from './flags/217-india.svg'
import liberia from './flags/218-liberia.svg'
import yemen from './flags/219-yemen.svg'
import uzbekistan from './flags/220-uzbekistan.svg'
import sudan from './flags/221-sudan.svg'
import sahrawiArabDemocraticRepublic from './flags/222-sahrawi-arab-democratic-republic.svg'
import republicOfMacedonia from './flags/223-republic-of-macedonia.svg'
import otan from './flags/224-otan.svg'
import libya from './flags/225-libya.svg'
import eastTimor from './flags/226-east-timor.svg'
import tibet from './flags/227-tibet.svg'
import russia from './flags/228-russia.svg'
import papuaNewGuinea from './flags/229-papua-new-guinea.svg'
import montenegro from './flags/230-montenegro.svg'
import moldova from './flags/231-moldova.svg'
import maldives from './flags/232-maldives.svg'
import madeira from './flags/233-madeira.svg'
import frenchPolynesia from './flags/234-french-polynesia.svg'
import trinidadAndTobago from './flags/235-trinidad-and-tobago.svg'
import tajikistan from './flags/236-tajikistan.svg'
import sriLanka from './flags/238-sri-lanka.svg'
import mexico from './flags/239-mexico.svg'
import republicOfTheCongo from './flags/240-republic-of-the-congo.svg'
import equatorialGuinea from './flags/241-equatorial-guinea.svg'
import zimbabwe from './flags/242-zimbabwe.svg'
import rwanda from './flags/243-rwanda.svg'
import lesotho from './flags/244-lesotho.svg'
import ivoryCoast from './flags/245-ivory-coast.svg'
import isleOfMan from './flags/246-isle-of-man.svg'
import haiti from './flags/247-haiti.svg'
import samoa from './flags/248-samoa.svg'
import nepal from './flags/249-nepal.svg'
import brazil from './flags/250-brazil.svg'
import mongolia from './flags/251-mongolia.svg'
import malawi from './flags/252-malawi.svg'
import madagascar from './flags/253-madagascar.svg'
import jersey from './flags/254-jersey.svg'
import ghana from './flags/255-ghana.svg'
import georgia from './flags/256-georgia.svg'
import estonia from './flags/257-estonia.svg'
import democraticRepublicOfCongo from './flags/258-democratic-republic-of-congo.svg'
import senegal from './flags/259-senegal.svg'
import paraguay from './flags/260-paraguay.svg'
import china from './flags/261-china.svg'
import unitedKingdom from './flags/262-united-kingdom.svg'
import antarctica from './flags/Antarctica.svg'
import guyana from './flags/guyanese.svg'
import kiribati from './flags/kiribati.svg'
import macau from './flags/macau.svg'
import macedonia from './flags/macedonia.svg'
import marshallIslands from './flags/marshall-Islands.svg'
import mayotte from './flags/mayotte.svg'
import netherlandsAntilles from './flags/netherlands-antilles.png'
import newCaledonia from './flags/new-caledonia.svg'
import reunion from './flags/reunion.svg'
import saintMartin from './flags/saint-martin.svg'
import saintHelena from './flags/saint-helena.svg'
import saintLucia from './flags/saint-lucian.svg'
import saintPierreAndMiquelon from './flags/saint-pierre-and-miquelon.svg'
import svalbardAndJanMayen from './flags/svalbard-and-jan-mayen.svg'
import swaziland from './flags/swazi.svg'
import turksAndCaicosIslands from './flags/turks-and-caicos-islands.svg'
import wallisAndFutuna from './flags/wallis-and-futuna.svg'
import saintBarthelemy from './flags/saint-barthelemy.svg'


const flags = {
  ethiopia,
  oman,
  tanzania,
  slovenia,
  puertoRico,
  mozambique,
  iraq,
  lebanon,
  uganda,
  nigeria,
  italy,
  malta,
  tunisia,
  nicaragua,
  elSalvador,
  zambia,
  wales,
  dominicanRepublic,
  qatar,
  namibia,
  mauritius,
  europeanUnion,
  luxembourg,
  hawaii,
  lithuania,
  jamaica,
  honduras,
  myanmar,
  kenya,
  cyprus,
  pakistan,
  latvia,
  japan,
  kazakhstan,
  serbia,
  scotland,
  northKorea,
  uruguay,
  syria,
  guatemala,
  iceland,
  jordan,
  monaco,
  spain,
  slovakia,
  unitedNations,
  panama,
  newZealand,
  ecuador,
  romania,
  chile,
  finland,
  hungary,
  belgium,
  southKorea,
  malaysia,
  venezuela,
  norway,
  saudiArabia,
  israel,
  czechRepublic,
  colombia,
  iran,
  argentina,
  ukraine,
  germany,
  hongKong,
  unitedArabEmirates,
  laos,
  ireland,
  greece,
  denmark,
  sweden,
  peru,
  southAfrica,
  philippines,
  france,
  indonesia,
  egypt,
  taiwan,
  cookIslands,
  cocosIsland,
  christmasIsland,
  ceuta,
  americanSamoa,
  morocco,
  anguilla,
  corsica,
  canaryIslands,
  comoros,
  centralAfricanRepublic,
  caymanIslands,
  britishIndianOceanTerritory,
  bhutan,
  chad,
  capeVerde,
  switzerland,
  benin,
  aruba,
  abkhazia,
  balearicIslands,
  andorra,
  burundi,
  antiguaAndBarbuda,
  alandIslands,
  cameroon,
  brunei,
  poland,
  bonaire,
  belarus,
  barbados,
  britishVirginIslands,
  bosniaAndHerzegovina,
  bermuda,
  belize,
  bahrain,
  albania,
  burkinaFaso,
  turkey,
  basqueCountry,
  armenia,
  afghanistan,
  britishColumbia,
  angola,
  azerbaijan,
  algeria,
  botswana,
  bangladesh,
  cuba,
  australia,
  costaRica,
  cambodia,
  bolivia,
  croatia,
  bulgaria,
  galapagosIslands,
  martinique,
  tonga,
  stLucia,
  rapaNui,
  singapore,
  palau,
  niue,
  macao,
  fiji,
  faroeIslands,
  dominica,
  vanuatu,
  sierraLeone,
  seychelles,
  kosovo,
  england,
  unitedStatesOfAmerica,
  guinea,
  orkneyIslands,
  turksAndCaicos,
  transnistria,
  tokelau,
  gambia,
  stVincentAndTheGrenadines,
  southSudan,
  somaliland,
  solomonIslands,
  vietnam,
  sintMaarten,
  sintEustatius,
  saintKittsAndNevis,
  sabaIsland,
  pitcairnIslands,
  palestine,
  ossetia,
  northernMarianasIslands,
  nauru,
  portugal,
  montserrat,
  melilla,
  mauritania,
  kuwait,
  guernsey,
  guam,
  grenada,
  greenland,
  thailand,
  gibraltar,
  gabon,
  falklandIslands,
  virginIslands,
  austria,
  vaticanCity,
  tubalu,
  turkmenistan,
  togo,
  bahamas,
  netherlands,
  azoresIslands,
  suriname,
  somalia,
  sicily,
  sardinia,
  saoTomeAndPrincipe,
  norfolkIsland,
  niger,
  micronesia,
  marshallIsland,
  canada,
  mali,
  kyrgyzstan,
  guineaBissau,
  eritrea,
  djibouti,
  curacao,
  stBarts,
  sanMarino,
  northenCyprus,
  liechtenstein,
  india,
  liberia,
  yemen,
  uzbekistan,
  sudan,
  sahrawiArabDemocraticRepublic,
  republicOfMacedonia,
  otan,
  libya,
  eastTimor,
  tibet,
  russia,
  papuaNewGuinea,
  montenegro,
  moldova,
  maldives,
  madeira,
  frenchPolynesia,
  trinidadAndTobago,
  tajikistan,
  sriLanka,
  mexico,
  republicOfTheCongo,
  equatorialGuinea,
  zimbabwe,
  rwanda,
  lesotho,
  ivoryCoast,
  isleOfMan,
  haiti,
  samoa,
  nepal,
  brazil,
  mongolia,
  malawi,
  madagascar,
  jersey,
  ghana,
  georgia,
  estonia,
  democraticRepublicOfCongo,
  senegal,
  paraguay,
  china,
  unitedKingdom,
  antarctica,
  guyana,
  kiribati,
  macau,
  macedonia,
  marshallIslands,
  mayotte,
  netherlandsAntilles,
  newCaledonia,
  saintMartin,
  saintHelena,
  saintLucia,
  saintPierreAndMiquelon,
  svalbardAndJanMayen,
  turksAndCaicosIslands,
  swaziland,
  reunion,
  wallisAndFutuna,
  saintBarthelemy,
  flagUs,
  flagFr,
  flagDe,
  flagIn,
  flagIt,
  flagPg,
  flagRu,
  flagSp
}

export default flags
