import styled from 'styled-components'
import { fontFamily, fontSize } from 'Constants'

export const ButtonBlue = styled.button`
  color: ${props => props.theme.colors.joinButtonColor};
  border: 2px solid ${props => props.theme.colors.defaultButtonBorder};
  background: ${props =>
    props.disabled
      ? props.theme.colors.lineGrey
      : props.theme.colors.joinButton};
  text-transform: uppercase;
  outline: none;
  font-size: ${fontSize.h5};
  font-family: ${fontFamily};
  font-weight: 900;
  padding: 12px;
  min-width: '170px';
  border-radius: 22px;
  position: 'fixed';
  bottom: 20px;
  white-space: nowrap;
  cursor: pointer;
  z-index: 10;
`
