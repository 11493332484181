import styled from 'styled-components'
import { sizes } from 'Constants'

export const SubsctiptionWrapper = styled.div`
  position: relative;
  flex-basis: 48%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${props => props.isPremium && '40%'};
  border: 1px solid ${props => props.theme.colors.lightGrayBorder};
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  padding-bottom: 10px;
  ::before {
    content: ${props =>
      // eslint-disable-next-line no-useless-escape
      props.isMostPopular ? `\'${props.isMostPopular}\'` : null};
    background-color: ${props => props.theme.colors.defaultBlue};
    color: white;
    transform: translateY(-100%);
    padding: 5px 10px;
    border-radius: 5px 5px 0 0;
    position: absolute;
  }
  & > h3 {
    width: 100%;
    text-align: center;
    padding-bottom: 16px;
    border-bottom: 1px solid ${props => props.theme.colors.lightGrayBorder};
  }
  & > h2 {
    color: ${props => props.theme.colors.defaultGreen};
    font-size: 24px;
    vertical-align: top;
    margin: 0;
    & > span {
      color: ${props => props.theme.colors.lightGrayText};
      font-size: 16px;
      font-weight: 400;
      vertical-align: bottom;
    }
  }
  & > h4 {
    font-size: ${p => p.theme.fontSize.h5};
    color: ${p => p.theme.colors.watchButtonColor};
    text-align: center;
    margin: 5px 0 32px 0;
  }
  @media (max-width: ${sizes.phone}px) {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
`
