import { types } from 'Helpers/notifications'
import * as routes from 'Routes/routesConstants'

export const getUrl = (type, jobId, userId, requestId, jobDoer, isAuthor) => {
  switch (type) {
    case types.newJobRequest:
      return `${routes.myRequests}${routes.hiringRequests}/${jobId}`
    case types.newMessage:
      return `${routes.chats}?userId=${userId}`
    case types.jobRequestRejected:
      return `${routes.findJobs}${routes.listView}/${jobId}`
    case types.jobRequestAccepted:
      return `${routes.findJobs}${routes.listView}/${jobId}`
    case types.jobCancelled:
      return `${routes.findJobs}${routes.listView}/${jobId}`
    case types.appointmentScheduled:
      return `${routes.findJobs}${routes.listView}/${jobId}`
    case types.appliedForJob:
      return `${routes.myJobs}${routes.posted}/${jobId}`
    case types.trackingAvailable:
      return `${routes.trackNow}/${jobId}/${jobDoer}`
    case types.jobUnavailable:
      return `${routes.notifications}`
    case types.jobDone:
      return isAuthor ? routes.paymentsHistory : routes.earnings
    default:
      return `${routes.findJobs}${routes.listView}/${jobId}`
  }
}
