import styled from 'styled-components'
import { fontFamily, fontSize } from 'Constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

export const SideBarWrapper = styled.aside`
  width: 100%;
  margin-bottom: 40px;
  padding: 20px;
  background: ${props => props.theme.colors.componentsBackground};
  border-radius: 5px;
  font-weight: 600;
  box-shadow: ${props => props.theme.colors.boxShadow};
`

export const SideBarTitle = styled.h2`
  margin: 0;
  font-size: ${fontSize.h4};
  font-family: ${fontFamily};
  padding-bottom: 1em;
  display: flex;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

//PopularCategories

export const PopularCategoriesItemWrapper = styled.div`
  position: relative;
  cursor: pointer;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0.5em 0 0.5em 5px;
  transition: color 0.3s;
  border-radius: 10px;
  ${p => p.isActive && `color: ${p.theme.colors.joinUs}`};
  &:hover {
    color: ${p => p.theme.colors.joinUs};
  }
  & > span {
    font-size: ${fontSize.default};
    font-family: ${fontFamily};
    margin-left: 60px;
  }
`

export const Icon = styled.img`
  position: absolute;
  width: auto;
  height: 40px;
  margin-right: width + 15px;
`

export const TimesIcon = styled(FontAwesomeIcon).attrs(props => ({
  icon: faTimes,
  color: props.theme.colors.iconRed
}))`
  margin-top: 2px;
  margin-left: auto;
`
//ServiceProviders

export const ServiceProvidersItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1em 0;
`

export const InfoGroup = styled.div`
  padding-left: 20px;
  max-width: 80%;
`

export const Title = styled.h3`
  display: flex;
  margin: 0;
  font-size: ${fontSize.default};
  font-family: ${fontFamily};
`

export const Stars = styled.div`
  display: flex;
  margin: 5px 0;
  align-items: center;
  & > svg {
    margin-right: 5px;
    & > path {
      fill: ${props => props.theme.colors.premiumBg};
    }
  }
`

export const Icons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ProContainer = styled.div`
  transform: scale(0.9);
`
