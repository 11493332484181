import styled from 'styled-components'
import { fontFamily, sizes, fontSize } from 'Constants'

/*********************************************************************
 ******************************LANG***********************************
 *********************************************************************/

export const LangList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  position: absolute;
  min-width: 50px;
  background: ${props => (props.select ? '#fff' : 'transparent;')};
  box-shadow: ${props =>
    props.select ? '0 4px 8px 0 rgba(46,61,73,.12);' : 'none;'};
  border-radius: 0.375rem;
  left: ${props => (props.view ? '-200px' : '-140px')};
  top: ${props => (props.view ? '7px' : '-2px')};
  z-index: 10;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 20px;
    right: 15px;
    display: block;
    width: 2px;
    height: 8px;
    margin-top: -5px;
    background: ${props =>
      props.select ? props.theme.colors.defaultGrey : '#fff'};
    border-radius: 3px;
    transition: All 0.3s;
    @media (max-width: ${sizes.desktop}px) {
      // right: -5px;
    }
    @media (max-width: ${sizes.tablet}px) {
      background: #fff;
      right: 15px;
    }
  }
  &:after {
    right: 20px;
    transform: ${props =>
      props.select ? 'rotate(45deg);' : 'rotate(135deg);'};
    @media (max-width: ${sizes.desktop}px) {
      // right: -10px;
    }
    @media (max-width: ${sizes.tablet}px) {
      background: #fff;
      right: 20px;
    }
  }
  &:before {
    transform: ${props =>
      props.select ? 'rotate(-45deg);' : 'rotate(-135deg);'};
  }
  &:hover {
    color: #f2f1f4;
    transition: All 0.3s;
    &:before,
    &:after {
      transition: All 0.3s;
    }
  }
  @media (max-width: ${sizes.desktop}px) {
    // left: -60px;
  }
  @media (max-width: ${sizes.tablet}px) {
    left: 0;
    position: relative;
    background: #0a94f1;
  }
  margin-bottom: 5em;
`

export const LangLi = styled.li`
  padding: 8px 30px 8px 8px;
  min-width: 90px;
  &:first-child {
    padding-top: 10px;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    &:hover {
      background: none;
    }
  }
  &:last-child {
    padding-bottom: 10px;
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
  &:hover {
    background: rgba(202, 238, 255, 0.2);
  }
  @media (max-width: ${sizes.desktop}px) {
    /* padding: 10px; */
    /* min-width: 60px; */
  }
  @media (max-width: ${sizes.tablet}px) {
    /* padding: 10px 35px 10px 10px; */
    /* min-width: 90px; */
    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }
`

export const LangLink = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const LangImg = styled.img`
  margin-right: 0.75rem;
  max-width: 25px;
  border-radius: 4px;
`

export const LangLabel = styled.label`
  text-transform: capitalize;
  color: ${props => (props.select ? props.theme.colors.defaultGrey : '#fff')};
  font-weight: ${props => (props.select ? '500' : '900')};
  cursor: pointer;
  font-size: ${fontSize.default};
  font-family: ${fontFamily};
  @media (max-width: ${sizes.desktop}px) {
    // display: none;
  }
  @media (max-width: ${sizes.tablet}px) {
    color: #fff;
    display: block;
    font-size: ${fontSize.h5};
    font-weight: 900;
  }
`
