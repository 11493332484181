import React, { useState } from 'react'
import {
  InputLabel,
  // Label,
  // CheckboxInput,
  // CheckBoxSpan,
  // CheckBoxText,
  Row,
  // Col4,
  // Col9,
  Col12,
  Col6,
} from '../style'


import { toast } from 'react-toastify'
import {
  ALLOWED_IMG_EXTENSIONS,
  MAX_IMG_SIZE
} from 'Components/blocks/ProfileForm/config'
import ImageUploader from 'react-images-upload'
// import _ from 'lodash'
import Input from '../Atom/Input-field'
import Select from '../Atom/Select-field'
import CheckBoxInput from '../Atom/CheckBoxInput'

const CategoryDetails = ({ t, validatorConfig, onHandleChange }) => {
  const {
    category,
    category2,
    ads,
    addNotes,
    restrictedContent,
    termCond,
    // termCondNew,    
    videoLink,
    banner300x50,
    banner300x100,
    banner970x90,
    banner468x60,
    banner728x90
  } = validatorConfig
  // const userRole = 'free'
  const [selectedBanner1, setSelectedBanner1] = useState([])
  const [selectedBanner2, setSelectedBanner2] = useState([])
  const [selectedBanner3, setSelectedBanner3] = useState([])
  const [selectedBanner4, setSelectedBanner4] = useState([])
  const [selectedBanner5, setSelectedBanner5] = useState([])

  const handleMaxItemsUploaded = () => {
    toast.error(t('overflowError'))
  }

  // useEffect(() => {
  //   console.log("value coming 1",termCondNew);
  //   console.log("value 2",validatorConfig);
  //   console.log("value coming 2: ",validatorConfig.termCondNew.name);
  //   console.log(t);
  // })

  const handleImgListChanged = (
    listType,
    base64Array,
    filesArray,
    propertyName,
    value
  ) => {
    if (value === 1) {
      setSelectedBanner1([...base64Array])
    } else if (value === 2) {
      setSelectedBanner2([...base64Array])
    } else if (value === 3) {
      setSelectedBanner3([...base64Array])
    } else if (value === 4) {
      setSelectedBanner4([...base64Array])
    } else if (value === 5) {
      setSelectedBanner5([...base64Array])
    }
    if (base64Array.length > 0) {
      onHandleChange(
        {
          target: {
            name: propertyName,
            value: base64Array
          }
        },
        true
      )
    } else {
      onHandleChange(
        {
          target: {
            name: propertyName,
            value: base64Array
          }
        },
        false
      )
    }
  }

  return (
    <div>
      <Row>
        <Col12>
          <InputLabel mandatory>{t('category')}</InputLabel>
          <Select {...category} onHandleChange={onHandleChange}></Select>
        </Col12>
      </Row>

      <Row>
        <Col12>
          <InputLabel mandatory>
             {t('upload-banner-ads')}
          </InputLabel>
        </Col12>
      </Row>

      <Row>
        <Col6>
          <InputLabel mandatory>{t('banner-size-one')}</InputLabel>
          <ImageUploader
            buttonText={null}
            imgExtension={ALLOWED_IMG_EXTENSIONS}
            label={null}
            maxFileSize={MAX_IMG_SIZE}
            maxItemsCount={1}
            value={selectedBanner1}
            withIcon={false}
            withPreview
            maxNumber={1}
            onChange={(files, b64s) => {
              handleImgListChanged(
                'pics.base64Array',
                files,
                b64s,
                banner300x50.name,
                1
              )
            }}
            onMaxItemsOverflow={handleMaxItemsUploaded}
          />
        </Col6>
        <Col6>
          <InputLabel mandatory>{t('banner-size-two')}</InputLabel>
          <ImageUploader
            maxNumber={1}
            buttonText={null}
            imgExtension={ALLOWED_IMG_EXTENSIONS}
            label={null}
            maxFileSize={MAX_IMG_SIZE}
            maxItemsCount={1}
            value={selectedBanner2}
            withIcon={false}
            withPreview
            onChange={(files, b64s) => {
              handleImgListChanged(
                'pics.base64Array',
                files,
                b64s,
                banner300x100.name,
                2
              )
            }}
            onMaxItemsOverflow={handleMaxItemsUploaded}
          />
        </Col6>
      </Row>
      <Row>
        <Col6>
          <InputLabel mandatory>{t('banner-size-three')}</InputLabel>
          <ImageUploader
            maxNumber={1}
            buttonText={null}
            imgExtension={ALLOWED_IMG_EXTENSIONS}
            label={null}
            maxFileSize={MAX_IMG_SIZE}
            maxItemsCount={1}
            value={selectedBanner3}
            withIcon={false}
            withPreview
            onChange={(files, b64s) => {
              handleImgListChanged(
                'pics.base64Array',
                files,
                b64s,
                banner970x90.name,
                3
              )
            }}
            onMaxItemsOverflow={handleMaxItemsUploaded}
          />
        </Col6>
        <Col6>
          <InputLabel mandatory>{t('banner-size-four')}</InputLabel>
          <ImageUploader
            maxNumber={1}
            buttonText={null}
            imgExtension={ALLOWED_IMG_EXTENSIONS}
            label={null}
            maxFileSize={MAX_IMG_SIZE}
            maxItemsCount={1}
            value={selectedBanner4}
            withIcon={false}
            withPreview
            onChange={(files, b64s) => {
              handleImgListChanged(
                'pics.base64Array',
                files,
                b64s,
                banner468x60.name,
                4
              )
            }}
            onMaxItemsOverflow={handleMaxItemsUploaded}
          />
        </Col6>
      </Row>
      <Row>
        <Col6>
          <InputLabel mandatory>{t('banner-size-five')}</InputLabel>
          <ImageUploader
            maxNumber={1}
            buttonText={null}
            imgExtension={ALLOWED_IMG_EXTENSIONS}
            label={null}
            maxFileSize={MAX_IMG_SIZE}
            maxItemsCount={1}
            value={selectedBanner5}
            withIcon={false}
            withPreview
            onChange={(files, b64s) => {
              handleImgListChanged(
                'pics.base64Array',
                files,
                b64s,
                banner728x90.name,
                5
              )
            }}
            onMaxItemsOverflow={handleMaxItemsUploaded}
          />
        </Col6>
      </Row>
      <Row>
        <Col12>
          <InputLabel>{t('category')}</InputLabel>
          <Select {...category2} onHandleChange={onHandleChange}></Select>
        </Col12>
      </Row>
      <Row>
        <Col12>
          <InputLabel>{t('website-video-link')}</InputLabel>
          <Input {...videoLink} onHandleChange={onHandleChange} />
        </Col12>
      </Row>
      <Row>
        <Col12>
          <InputLabel>{t('prohibited-contents')}</InputLabel>
          <br />
          <InputLabel>
             {t('ad-contains')}
          </InputLabel>
          <CheckBoxInput {...ads} t={t} onHandleChange={onHandleChange} />
        </Col12>
      </Row>

      <Row>
        <Col12>
          <InputLabel >{t('restrictedContents')}</InputLabel>
          <br />
          <InputLabel>
            {t('ad-contains')}
          </InputLabel><br/>
             
          <p>
            {t('ad-comply')}
          </p>
          <CheckBoxInput
            {...restrictedContent}
            t={t}
            onHandleChange={onHandleChange}
          />
        </Col12>
      </Row>
      <Row>
        <Col12>
          <Input {...addNotes} onHandleChange={onHandleChange} />
        </Col12>
      </Row>
      <Row>
      <Col12 style={{display:'flex'}}>
          <CheckBoxInput
            style={{ fontWeight: 'bold', fontSize: '15px' }}
            {...termCond} 
            t={t}
            onHandleChange={onHandleChange}             
             />
			     <a href="../terms-conditions-new" style={{color:"#3ad6ff",padding:"3px"}} className="dXLcjS" target='_blank'>{t('termCondNew')}s      </a>           
          </Col12>
      </Row>
    </div>
  )
}

export default CategoryDetails
