import React from 'react'

import { AddButton, AddButtonIcon } from './styles'

export const AddLinkButton = props => {
  return (
    <div>
      <AddButton {...props}>
        <AddButtonIcon />
      </AddButton>
    </div>
  )
}
