import { palette } from './palette'
import { sizes, fontSize, fontFamily } from 'Constants'

const theme = {
  palette,
  colors: {
    header: '#0a94f1',
    jumbotron: '#BDBDBD',
    silver05: 'rgba(192, 192, 192, 0.5)',
    joinUs: '#2989d8',
    defaultFont: palette.white,
    defaultGrey: palette.grey,
    defaultFontGrey: palette.fontGrey,
    defaultFontBlack: palette.black,
    downloadGrey: '#f2f1f4',
    joinButton: 'linear-gradient(to right, #02a7ff 0%,#3ad6ff 99%)',
    joinButtonColor: '#fff',
    watchButton: 'linear-gradient(to right, #ffffff 0%,#dce0e6 99%)',
    grayButton: 'linear-gradient(to right,#adadad 0%,#c1c1c1 99%)',
    myMessage: 'linear-gradient(to right, #e1f5ff 0%, #BADCFF 99%)',
    watchButtonColor: '#6b6b6c',
    watchButtonColor05: 'rgba(107, 107, 108, 0.5)',
    greenCircle: 'rgba(0, 185, 73, 0.6)',
    redCircle: 'rgba(185, 0, 0, 0.6)',
    itemBorder: '#e1e5e8',
    porcelain05: 'rgba(225, 229, 232, 0.5)',
    jumbotronBoldText: '#565656',
    defaultButtonBorder: '#fff',
    lineGrey: '#d4d3d3',
    avatarBackground: '#EBF2FC',
    postJob: '#af2c00',
    notification: '#ef2927',
    premiumBg: '#e9bd40',
    lightGrayText: '#898989',
    blueIcon: '#3c83ef',
    defaultBlue: '#4b86e8',
    blueGradientToTop: 'linear-gradient(to top, #02a7ff 0%,#3ad6ff 99%)',
    greyBackGround: '#ebeef3',
    iconRed: '#f32621',
    purple: '#800080',
    pink: '#ff627d',
    headerText: '#7f3d61',
    blue: '#0a94f1',
    componentsBackground: 'white',
    mainBackground: '#f9fcff',
    boxShadow: '0 1px 4px 0 #c9cfde, 0 0px 3px 0 #b1bcd4',
    Scorpion06: 'rgba(97, 97, 97, 0.6)',
    lightGrayBorder: '#efefef',
    divideLine: '#f7f7f7',
    accentColor: '#f42923',
    defaultGreen: '#01b151',
    lightBlue: '#69bcf9'
  },
  sizes,
  fontSize,
  fontFamily: {
    default: fontFamily
  }
}

export default theme
