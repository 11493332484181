import React from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'
import { signIn } from 'Routes/routesConstants'
import {
  DefaultQuotes,
  QuoteBlock,
  WidthLimiter,
  GetStartedBtn
} from './styles'
const Quoutes = props => {
  const { t } = props
  return (
    <QuoteBlock>
      <WidthLimiter>
        <DefaultQuotes>{t('comingTogether')}</DefaultQuotes>
        <GetStartedBtn to={signIn}>{t('getStarted')}</GetStartedBtn>
      </WidthLimiter>
    </QuoteBlock>
  )
}

Quoutes.propTypes = {
  t: PropTypes.func
}

export default withNamespaces('landingPage')(Quoutes)
