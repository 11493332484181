import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { isFunction } from 'lodash'

import {
  MessageWrapper,
  Time,
  Text,
  MessageCommpleteWrapper,
  TextCompleted,
  AudioWrapper,
  PauseIcon,
  PlayIcon,
  TimelineWrapper,
  Timeline,
  TimeBlocked,
  MessageFailWrapper,
  MessageFailText
} from './style'
import { formatTimeWithFormat } from 'Helpers/time'
import VoicesService from 'Services/Voices'

class Message extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isPlaying: false,
      currentTime: 0,
      currentDuration: 0,
      url: null
    }
    this.audio = null
    this.myInterval = null
  }


  componentDidMount() {
    const { item, chatId } = this.props

    if (item.type === 'voice_message') {
      const params = {
        filePath: `chats/${chatId}/${item.text}`,
        bucket: process.env.REACT_APP_PROFILE_IMAGES_BUCKET_NAME
      }

      const url = VoicesService.getSignedURLForObject(item.text, params)

      this.setState({ url })
    }
  }

  renderCompleteMessage = (item) => {
    const { t } = this.props
    let text = ''
    switch (item.type) {
      case 'block_user_message':
        text = t('BlockedChat')
        break
      case 'unblock_user_message':
        text = t('UnblockedChat')
        break
      default:
        text = t('ChatJobCompleted')
        break
    }

    return (
      <MessageCommpleteWrapper key={item.createdAt}>
        <TextCompleted>{`-- ${text} --`}</TextCompleted>
        <TimeBlocked>{formatTimeWithFormat(+item.createdAt, 'd mmmm yyyy, h:MM TT')}</TimeBlocked>
      </MessageCommpleteWrapper>
    )
  }

  renderRegularMessage = ({ item, isMine }) => {
  const {onResend, t} = this.props

  return item.isFail && isFunction(onResend) ? (
      <>
        <MessageFailWrapper
          isMyMessage={isMine}
          isVisible={true}
          key={item.createdAt}
          onClick={this.handleResendMessage(item._id, item.text)}
        >
          <Text>{item.text}</Text>
          <Time>{formatTimeWithFormat(+item.createdAt, 'h:MM TT')}</Time>
        </MessageFailWrapper>
        <MessageFailText>{t('SendMessageFailed')}</MessageFailText>
      </>
    ) : (
      <MessageWrapper
        isMyMessage={isMine}
        isVisible={true}
        key={item.createdAt}
      >
        <Text>{item.text}</Text>
        <Time>{formatTimeWithFormat(+item.createdAt, 'h:MM TT')}</Time>
      </MessageWrapper>
    )
  }


  handlePlaySound = id => {
    const { isPlaying } = this.state
    const { currentAudioPlay } = this.props
    this.props.handlePlay(id)

    if (currentAudioPlay !== id) {
      this.audio.pause()
      this.setState({ isPlaying: false })
      clearInterval(this.myInterval)
    }

    if (!isPlaying) {
      this.audio.play()
      this.setState({
        isPlaying: true,
        currentDuration: this.audio.duration
      })

      this.myInterval = setInterval(() => {
        if (this.audio.currentTime !== this.audio.duration) {
          this.setState({ currentTime: this.audio.currentTime })
        } else {
          this.setState({
            currentTime: 0,
            currentDuration: 0,
            isPlaying: false
          })
          clearInterval(this.myInterval)
        }
      }, 100)


    } else {
      this.audio.pause()
      this.setState({ isPlaying: false })
      clearInterval(this.myInterval)
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.currentAudio !== this.props.item._id && this.state.isPlaying) {
      this.audio.pause()
      this.setState({
        isPlaying: false
      })
      clearInterval(this.myInterval)
    }
  }

  renderVoiceMessage = () => {
    const { item, isMine, currentAudio } = this.props
    const {
      isPlaying,
      currentTime,
      currentDuration
    } = this.state

    return (<MessageWrapper
        isMyMessage={isMine}
        isVisible={true}
        key={item.createdAt}
      >
        <AudioWrapper>
          <audio
            ref={ref => this.audio = ref}
            src={this.state.url}
            style={{ display: 'none' }}
          />
          {currentAudio === item._id && isPlaying ? (
            <PauseIcon onClick={() => this.handlePlaySound(item._id)}/>
          ) : (
            <PlayIcon onClick={() => this.handlePlaySound(item._id)}/>
          )}
          <TimelineWrapper>
            <Timeline width={(currentTime / currentDuration) * 100 || 0}/>
          </TimelineWrapper>
        </AudioWrapper>
        <Time>{formatTimeWithFormat(+item.createdAt, 'h:MM TT')}</Time>
      </MessageWrapper>
    )
  }

  handleResendMessage = (idFail, message) => () => this.props.onResend(idFail, message)

  render() {
    const { item, isMine } = this.props

    const messageType = ['complete_message', 'block_user_message', 'unblock_user_message']
    if (messageType.indexOf(item.type) !== -1) {
      return this.renderCompleteMessage(item)
    }

    if (item.type === 'voice_message') {
      return this.renderVoiceMessage()
    }

    return this.renderRegularMessage({ item, isMine })
  }
}

Message.propTypes = {
  item: PropTypes.object,
  currentAudio: PropTypes.string,
  chatId: PropTypes.string,
  handlePlay: PropTypes.func,
  isMine: PropTypes.bool
}

export default Message
