import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Player from 'react-video-cover'

class VideoCover extends Component {
  componentDidMount() {
    const { audioUrl = null } = this.props
    this.audioPlayer = audioUrl ? new Audio(audioUrl) : null
  }

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.videoUrl !== this.props.videoUrl ||
      nextProps.audioUrl !== this.props.audioUrl
    )
  }

  componentWillUnmount() {
    if (this.audioPlayer) {
      this.audioPlayer.pause()
    }
  }

  handleClick = () => {
    const { onClick, disabled } = this.props

    if (disabled) return

    if (this.videoPlayer && this.videoPlayer.paused) this.setPlay()
    else if (this.videoPlayer) this.setPaused()

    if (_.isFunction(onClick)) onClick(Boolean(!this.videoPlayer.paused))
  }

  setPlay = () => {
    if (this.audioPlayer) {
      this.audioPlayer.play()
    }
    this.videoPlayer.play()
  }

  setPaused = () => {
    if (this.audioPlayer) {
      this.audioPlayer.pause()
    }
    this.videoPlayer.pause()
  }

  render() {
    const { videoUrl, poster } = this.props

    return (
      <>
        <Player
          videoOptions={{
            poster,
            src: videoUrl,
            ref: ref => (this.videoPlayer = ref),
            onClick: this.handleClick
          }}
        />
      </>
    )
  }
}

VideoCover.propTypes = {
  audioUrl: PropTypes.string,
  disabled: PropTypes.bool,
  poster: PropTypes.string,
  videoUrl: PropTypes.string,
  onClick: PropTypes.func
}

export { VideoCover }
