import { isEmpty } from 'lodash'

import { getAwsCredential } from 'Helpers/awsCredential'

const AWS = require('aws-sdk')

const awsCredential = JSON.parse(localStorage.getItem('awsCredential') || "{}")
const awsCredentialDecript = isEmpty(awsCredential) ? awsCredential : getAwsCredential(awsCredential)

const AWScreds = new AWS.Credentials({
  accessKeyId: awsCredentialDecript.AccessKeyId || 'ACCESS_KEY_ID',
  secretAccessKey: awsCredentialDecript.SecretAccessKey || 'SECRET_ACCESS_KEY',
  sessionToken: awsCredentialDecript.SessionToken || 'SESSION_TOKEN',
})

export { AWScreds }
