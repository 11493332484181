import React from 'react'
import PropTypes from 'prop-types'

import Ads from '../Ads'

const ListAd = ({ data = [], index, children }) => {
  if (data[index + 1] && (index % 10 === 1 || index % 10 === 5)) {
    return children || <Ads />
  }

  return null
}

ListAd.propTypes = {
  children: PropTypes.any,
  data: PropTypes.array,
  index: PropTypes.any
}

export { ListAd }
