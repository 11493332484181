import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  addProviderToFavourites,
  removeProviderFromFavourites
} from 'Redux/actions/favourites'

function withFavouriteSP(Component) {
  return class extends React.Component {
    static propTypes = {
      addToFavourite: PropTypes.func,
      removeFromFavourite: PropTypes.func
    }

    state = {
      loadProfileUserName: null
    }

    handleFavouriteClick = profile => {
      const { addToFavourite, removeFromFavourite } = this.props
      this.setState({ loadProfileUserName: profile.username }, () => {
        if (_.isString(profile.favouriteId)) {
          removeFromFavourite(profile.favouriteId, () => {
            this.setState({ loadProfileUserName: null })
          })
        } else {
          addToFavourite(profile.username, () => {
            this.setState({ loadProfileUserName: null })
          })
        }
      })
    }

    render() {
      const { loadProfileUserName } = this.state

      return (
        <Component
          {..._.omit(this.props, ['addToFavourite', 'removeFromFavourite'])}
          loadFavouriteSPId={loadProfileUserName}
          onFavouriteSPClick={this.handleFavouriteClick}
        />
      )
    }
  }
}

const actions = {
  addToFavourite: addProviderToFavourites,
  removeFromFavourite: removeProviderFromFavourites
}

export default compose(
  connect(
    null,
    actions
  ),
  withFavouriteSP
)
