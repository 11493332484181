import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'
import { compose } from 'redux'
import { withFormik } from 'formik'

import { PreloadImg } from 'Components/ui'
import { icons } from 'Assets/icons'

import { Input } from './innerBlocks'

import { formikProps } from './config'
import {
  Container,
  Row,
  InputWrapper,
  ChangeButton,
  ButtonWrapper,
  Content,
  LoaderWrapper,
  Title
} from './style'

class ChangePassword extends PureComponent {
  handleSubmit = (onChangePassword, values) => {
    onChangePassword(values.old, values.password)
  }

  render() {
    const {
      t,
      handleChange,
      handleBlur,
      values = {},
      touched = {},
      errors,
      onChangePassword
    } = this.props

    const inputProps = {
      handleChange,
      handleBlur
    }

    return (
      <Container>
        <Title>
          {t('settings:changePassword')}
        </Title>
        <Content>
          <Row mt="20px">
            <InputWrapper>
              <Input
                disabled={values.isRequest}
                error={
                  touched.old && errors.old
                    ? t(`sign:${errors.old}`)
                    : undefined
                }
                id="old"
                label={t('settings:oldPassword')}
                value={values.old}
                {...inputProps}
              />
            </InputWrapper>
            <InputWrapper>
              <Input
                disabled={values.isRequest}
                error={
                  touched.password && errors.password
                    ? t(`sign:${errors.password}`)
                    : undefined
                }
                id="password"
                label={t('settings:newPassword')}
                value={values.password}
                {...inputProps}
              />
            </InputWrapper>
          </Row>
          <Row>
            <InputWrapper>
              <Input
                disabled={values.isRequest}
                error={
                  touched.confirm && errors.confirm
                    ? t(`sign:${errors.confirm}`)
                    : undefined
                }
                id="confirm"
                label={t('settings:confirmNewPassword')}
                value={values.confirm}
                {...inputProps}
              />
            </InputWrapper>
            <ButtonWrapper>
              <ChangeButton
                id="submitForm"
                disabled={values.isRequest}
                onClick={() => this.handleSubmit(onChangePassword, values)}
              >
                {t('settings:change')}
              </ChangeButton>
            </ButtonWrapper>
          </Row>

          <LoaderWrapper isVisible={values.isRequest}>
            <PreloadImg mb={0} mt={0} src={icons.preload} />
          </LoaderWrapper>
        </Content>
      </Container>
    )
  }
}

ChangePassword.propTypes = {
  t: PropTypes.func,
  user: PropTypes.object
}

export default compose(
  withNamespaces(['settings', 'sign']),
  withFormik(formikProps)
)(ChangePassword)
