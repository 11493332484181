import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from 'Redux/actions/api'
import _ from 'lodash'
import { getParamsWithUnits } from 'Helpers/user'
import { getProfile } from 'Redux/selectors/user'

export const LOAD_APPLIED_JOB = createAsyncAction('job/LOAD_APPLIED')
export const loadAppliedJobList = (request, callback) =>
  apiCall({
    endpoint: '/applications',
    types: LOAD_APPLIED_JOB,
    method: 'GET',
    qsParams: {
      ...request,
      elements_per_page: 1000,
      no_geolocation_filter: true
    },
    preFormat: getParamsWithUnits,
    callback
  })

export const CANCEL_APPLIED_JOB = createAsyncAction('appliedJobs/CANCEL')
export const cancelAppliedJob = ({ jobId, reason }, callback) =>
  apiCall({
    endpoint: `/jobs/${jobId}/cancele`,
    types: CANCEL_APPLIED_JOB,
    method: 'POST',
    meta: {
      jobId
    },
    query: {
      reason
    },
    postFormat: (data, store) => {
      const user = getProfile(store.getState())

      return {
        ...data,
        payload: {
          ...data.payload,
          peopleWhoApplied: _.get(data, 'peopleWhoApplied', []).filter(
            el => el.userId !== user.username
          )
        }
      }
    },
    callback
  })
