import { createSelector, createStructuredSelector } from 'reselect'

const getState = state => state.providers

export const getNearestSP = createSelector(
  getState,
  data => data.nearestSP
)

export const getFullLibraryData = createStructuredSelector({
  getNearestSP
})
