import { memo } from 'react'
import { compose } from 'redux'

import { checkProps } from './config'

import { withNamespaces } from 'react-i18next'

import Component from './SubscriptionPremiumPuck'

export default compose(withNamespaces(['subscriptionScreen']))(
  memo(Component, checkProps)
)
