import memoize from 'memoize-one'
import _ from 'lodash'

export const getGeocodeObg = memoize(geocode => {
  if (_.isString(geocode)) {
    const geodata = geocode.split('/')
    return {
      lat: Number(geodata[0]),
      lng: Number(geodata[1])
    }
  }

  return {}
})
