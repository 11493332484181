import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from 'Redux/actions/api'
import { api } from 'Configs/App'

export const LOAD_NOTIFICATIONS = createAsyncAction('notifications/LOAD')
export const loadNotifications = (params, callback) =>
  apiCall({
    url: api.second,
    endpoint: '/notification/list',
    types: LOAD_NOTIFICATIONS,
    method: 'GET',
    qsParams: {
      elements_per_page: 20,
      ...params
    },
    callback
  })

export const MARK_AS_READ = createAsyncAction('notifications/MARK_READ')
export const markNotificationsAsRead = (notifications = [], callback) =>
  apiCall({
    url: api.second,
    endpoint: '/notification/mark-as-read',
    types: MARK_AS_READ,
    method: 'POST',
    query: {
      notificationsIds: notifications
    },
    callback
  })
