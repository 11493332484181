import React from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

import { findHelp } from 'Routes/routesConstants'
import { Premium, Pro, Profession, Avatar, Star } from 'Components/ui'
import {
  NearServiceProvidersWrapper,
  ProvidersWrapper,
  GeneralInfo,
  Provider,
  NameGroup,
  StarSpan,
  ViewAll,
  Header
} from '../styles'
import { idVerificationStatus } from 'Constants/idVerification'

const NearServiceProviders = ({ t, items }) => (
  <NearServiceProvidersWrapper>
    <Header>
      <h3>{t('nearestServiceProviders')}</h3>
      <ViewAll>
        <Link to={findHelp}>{t('viewAll')}</Link>
      </ViewAll>
    </Header>
    <ProvidersWrapper isLeftAlign={items.length === 2}>
      {items.map((item, key) => (
        <Provider isLeftAlign={items.length === 2} key={key}>
          <Avatar
            avatar={item.avatarURL}
            id={item._id}
            isVerified={item?.id_verification === idVerificationStatus.success}
          />
          <GeneralInfo>
            <NameGroup>
              <Link to={`${findHelp}/${item.username}`}>
                <span>
                  {
                    item.short_name
                  }
                </span>
              </Link>
              {item.isPremium && <Premium />}
              {item.isPro && <Pro />}
            </NameGroup>
            {item.rate && (
              <Star>
                <FontAwesomeIcon icon={faStar} />
                <StarSpan>{item.rate}</StarSpan>
              </Star>
            )}
            {item.services && item.services.length !== 0 && (
              <Profession professions={item.services} />
            )}
          </GeneralInfo>
        </Provider>
      ))}
    </ProvidersWrapper>
  </NearServiceProvidersWrapper>
)

NearServiceProviders.propTypes = {
  items: PropTypes.array,
  t: PropTypes.func
}

export default withNamespaces('landingPage')(NearServiceProviders)
