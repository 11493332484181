import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from 'Redux/actions/api'

import { getProfile } from 'Redux/selectors/user'
import { getParamsWithUnits } from 'Helpers/user'

export const LOAD_POSTED_JOB = createAsyncAction('job/LOAD_POSTED')
export const loadPostedJobList = (request, callback) => {
  return apiCall({
    endpoint: '/jobs',
    types: LOAD_POSTED_JOB,
    method: 'GET',
    qsParams: {
      ...request,
      no_geolocation_filter: true,
      radius: 99999999,
      elements_per_page: 100
    },
    preFormat: (request, store) => {
      const author = getProfile(store.getState())

      const units = getParamsWithUnits(request, store)

      return {
        params: {
          ...request.params,
          ...units.params,
          author: author.username
        }
      }
    },
    callback
  })
}

export const ACCEPT_JOB = createAsyncAction('job/ACCEPT')
export const acceptJob = ({ applicationId, doerId, jobId }, callback) =>
  apiCall({
    endpoint: `/applications/${applicationId}/accept`,
    types: ACCEPT_JOB,
    method: 'POST',
    meta: {
      applicationId
    },
    query: {
      applicationId,
      doerId,
      jobId
    },
    callback
  })

export const REJECT_JOB = createAsyncAction('job/REJECT')
export const rejectJob = (applicationId, callback) =>
  apiCall({
    endpoint: `/applications/${applicationId}/reject`,
    types: REJECT_JOB,
    method: 'POST',
    meta: {
      applicationId
    },
    callback
  })

export const HIRE_JOB = createAsyncAction('job/HIRE')
export const hireJob = (
  { jobId, userId, startDate, budget, currency, ...rest },
  callback
) =>
  apiCall({
    endpoint: '/applications/hire',
    types: HIRE_JOB,
    method: 'POST',
    query: {
      jobId,
      userId,
      startDate,
      budget,
      currency,
      ...rest,
    },
    meta: {
      jobId,
      ...rest,
    },
    callback
  })
