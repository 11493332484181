import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withNamespaces } from 'react-i18next'
import { Formik } from 'formik'

import ServicesAutocomplete from '../ServicesAutocomplete'

import {
  SearchWrapper,
  Input,
  FormWrapper,
  SearchBtn,
  InputWrapper
} from './styles'
import { filteredServices } from 'Helpers/services'

class BodySearch extends PureComponent {
  state = {
    isFocused: false,
    isChanged: false,
    items: []
  }

  input = React.createRef()

  handleFocus = () => {
    const { onFocus } = this.props
    const { isChanged } = this.state
    this.setState({ isFocused: true }, () => {
      this.formik.setFieldValue('search', '')
      if (isChanged) {
        this.setState({ isChanged: false })
      }
      if (_.isFunction(onFocus)) onFocus()
    })
  }

  handleBlur = () => {
    const { onBlur } = this.props
    setTimeout(() => {
      this.setState({ isFocused: false }, () => {
        if (_.isFunction(onBlur)) onBlur()
      })
    }, 100)
  }

  handleChangeText = ({ target: { value } }) => {
    const { services, t } = this.props
    this.formik.setFieldValue('search', value)

    _.debounce(() => {
      this.setState({
        items: filteredServices(
          services,
          this.formik.state.values.search,
          title => t(`services:${title}`)
        ).slice(0, 10)
      })
    }, 300)()
  }

  handleSelectService = (service = {}) => {
    const { onSetService } = this.props
    const { isChanged } = this.state
    this.formik.setFieldValue('search', service.title)
    if (!isChanged) {
      this.setState({ isChanged: true })
    }
    if (_.isFunction(onSetService)) onSetService(service._id)
  }

  handleSubmit = values => {
    const { onSubmit } = this.props
    const { isChanged } = this.state
    this.input.current.blur()
    if (isChanged) {
      this.setState({ isChanged: false })
    }
    if (_.isFunction(onSubmit)) {
      onSubmit(values.search)
    }
  }

  _renderForm = ({ values, handleSubmit }) => {
    const { t } = this.props
    const { isFocused, items } = this.state

    return (
      <>
        <form onSubmit={handleSubmit}>
          <InputWrapper>
            <Input
              autoComplete="off"
              id="search"
              placeholder={t('category')}
              ref={this.input}
              type="text"
              value={values.search || ''}
              onBlur={this.handleBlur}
              onChange={this.handleChangeText}
              onFocus={this.handleFocus}
            />
            <ServicesAutocomplete
              data={items}
              visible={isFocused && Boolean(values.search) && !_.isEmpty(items)}
              onClick={this.handleSelectService}
            />
          </InputWrapper>
        </form>
        <SearchBtn onClick={handleSubmit} />
      </>
    )
  }

  render() {
    const { t } = this.props
    const { isChanged } = this.state
    return (
      <SearchWrapper>
        <span>{t('search')}</span>
        <FormWrapper>
          <Formik
            initialValues={{ search: '' }}
            ref={ref => (this.formik = ref)}
            render={this._renderForm}
            onSubmit={isChanged && this.handleSubmit}
          />
        </FormWrapper>
      </SearchWrapper>
    )
  }
}

BodySearch.propTypes = {
  services: PropTypes.array,
  t: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onSetService: PropTypes.func,
  onSubmit: PropTypes.func
}

export default withNamespaces(['findJobPage', 'services'])(BodySearch)
