import styled from 'styled-components'

export const Wrapper = styled.div`
  padding-top: 30px;
`

export const CardsWrapper = styled.div`
  width: 100%;
`

export const Content = styled.div`
  display: flex;
  padding-top: 30px;
`
