import React from 'react'
import arrows from 'Assets/arrows'
import './slick.custom.css'
import { PrevArrow, NextArrow } from './styles'

export function CarouselNextArrow(props) {
  const { onClick } = props
  return <PrevArrow src={arrows.arrowLeft} onClick={onClick} />
}

export function CarouselPrevArrow(props) {
  const { onClick } = props
  return <NextArrow src={arrows.arrowRight} onClick={onClick} />
}
