import styled from 'styled-components'
import { Circle } from 'google-maps-react'

export const Marker = styled(Circle).attrs(p => ({
  fillColor: p.isActive ? p.theme.colors.greenCircle : p.theme.colors.redCircle,
  strokeColor: p.theme.colors.redCircle,
  fillOpacity: 1,
  radius: 500,
  strokeOpacity: 1,
  strokeWeight: 1
}))``
