import React from 'react'
import PropTypes from 'prop-types'

import { Container, Label, SInput, Error } from './style'

const Input = ({
  handleChange,
  handleBlur,
  name,
  value,
  error,
  label,
  ...props
}) => {
  return (
    <Container>
      <Label>{label}</Label>

      <SInput
        {...props}
        isError={error}
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <Error isVisible={Boolean(error)}>{error || 'error'}</Error>
    </Container>
  )
}

Input.propTypes = {
  error: PropTypes.string,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string
}

export { Input }
