import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { notifications } from 'Routes/routesConstants.js'
import { Active, SvgDiv } from './styles'

export const Notifications = ({ isActive }) => (
  <SvgDiv>
    <Link to={notifications}>
      <FontAwesomeIcon icon={faBell} />
      {isActive && <Active />}
    </Link>
  </SvgDiv>
)
