import React from 'react'
import PropTypes from 'prop-types'
import Message from './innerBlocks/Message'

import { Wrapper } from './style'
import { withNamespaces } from 'react-i18next'


class Messages extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isPlaying: false,
      currentTime: 0,
      currentDuration: 0,
      currentItemId: null,
      currentAudioPlay: null
    }
    this.audio = []
  }

  container = React.createRef()

  scrollToBottom = () => {
    if (this.container.current) {
      this.container.current.scrollTo(0, this.container.current.scrollHeight)
    }
  }

  componentDidMount() {
    this.scrollToBottom()
  }

  componentDidUpdate() {
    this.scrollToBottom()
  }

  handlePlay = id => {
    this.setState({ currentAudioPlay: id })
  }

  render() {
    const { data, userName, chatId, onResend } = this.props

    return (
      <Wrapper id="chatListWrapper" ref={this.container}>
        {
          data.map((item, index) => (
            <Message
              chatId={chatId}
              t={this.props.t}
              item={item}
              isMine={item.sender === userName}
              currentAudio={this.state.currentAudioPlay}
              handlePlay={this.handlePlay}
              key={index}
              onResend={onResend}
            />
          ))
        }
      </Wrapper>
    )
  }
}

Messages.propTypes = {
  data: PropTypes.array,
  chatId: PropTypes.string,
  userName: PropTypes.string.isRequired
}

const MessagesBlock =  withNamespaces('chat')(Messages)

export { MessagesBlock }
