import { takeLatest } from 'redux-saga/effects'

import { BUY_POINTS, BUY_PLAN } from 'Redux/actions/subscription'
import { START_THE_JOB } from 'Redux/actions/bookedJobs'
import { PAYMENT_IDENTITY } from 'Redux/actions/user'
import { ON_DONATE } from 'Redux/actions/paymentsTransactions'

import onCatchPayment from './onCatchPayment'
import onSuccessfulBuyPlan from './onSuccessfulBuyPlan'
import onSuccessfulStartingJob from './onSuccessfulStartingJob'
import onPaymentIdentity from './onPaymentIdentity'
import onDonate from './onDonate'

export default function*() {
  yield takeLatest(BUY_POINTS.SUCCESS, onCatchPayment)
  yield takeLatest(BUY_PLAN.SUCCESS, onSuccessfulBuyPlan)
  yield takeLatest(START_THE_JOB.SUCCESS, onSuccessfulStartingJob)
  yield takeLatest(PAYMENT_IDENTITY.SUCCESS, onPaymentIdentity)
  yield takeLatest(ON_DONATE.SUCCESS, onDonate)
}
