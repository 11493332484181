import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  margin: 0 auto 40px;
  font-family: ${p => p.theme.fontFamily.default};
  @media screen and (max-width: ${p => p.theme.sizes.phone}px) {
    width: 100%;
  }
`

export const ContentAvatar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 15px;
`

export const AvatarWrapper = styled.div`
  & > img {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    object-fit: cover;
  }
`

export const RaitingTitle = styled.div`
  font-size: ${p => p.theme.fontSize.h1};
  font-family: ${p => p.theme.fontFamily.default};
  font-weight: 600;
  margin: 10px auto 15px;
  @media screen and (max-width: ${p => p.theme.sizes.tablet}px) {
    font-size: ${p => p.theme.fontSize.h3};
  }
`
export const SubTitleAvatar = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 15px auto 5px;
  font-weight: 600;
`

export const ReviewsWrapper = styled(SubTitleAvatar)`
  align-items: center;
  margin-bottom: 20px;
  color: ${props => props.theme.colors.defaultBlue};
`

export const StarWrapper = styled.div`
  display: flex;
`

export const TitleAvatarName = styled.div`
  font-size: ${p => p.theme.fontSize.h3};
  text-transform: capitalize;
`

export const Avatar = styled.div`
  & > a > img {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    object-fit: cover;
  }
  & > div {
    position: absolute;
    top: 35px;
  }
  position: relative;
`

export const GeneralInfo = styled.div`
  margin-left: 10px;
`
