import styled from 'styled-components'
import arrows from 'Assets/arrows'

export const SortWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.div`
  margin-bottom: 1px;
  font-size: ${props => props.theme.fontSize.h5};
  font-family: ${props => props.theme.fontFamily.default};
  font-weight: 600;
  color: black;
`

export const Container = styled.div`
  position: relative;
  width: 250px;
  height: 43px;
  border: 2px solid #efefef;
  color: ${props => props.theme.colors.lightGrayText};
  border-radius: 5px;
  margin: 6px 0 0 0;
  display: flex;
  align-items: center;
  background: white;
  font-size: ${props => props.theme.fontSize.default};
  font-weight: 400;
  box-sizing: border-box;
  -webkit-appearance: none;
  & > span {
    font-size: ${props => props.theme.fontSize.h5};
    font-family: ${props => props.theme.fontFamily.default};
    font-weight: 600;
    color: black;
  }
  @media (max-width: ${props => props.theme.sizes.desktop}px) {
    width: 200px;
  }
  /* @media (max-width: ${props => props.theme.sizes.tablet}px) {
    width: 80%;
  } */
`

export const TextWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px 0 10px 10px;
  background: 95% no-repeat url(${arrows.arrowDownPng});
  background-size: auto 10px;
  cursor: pointer;
`

export const Text = styled.span`
  font-family: ${props => props.theme.fontFamily.default};
  font-size: ${props => props.theme.fontSize.default};
  font-weight: 400;
`

export const Popup = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: ${p => (p.isWidthSmall ? '250px' : '320px')};
  background-color: #fff;
  border: 2px solid #efefef;
  border-top: none;
  max-height: ${p => (p.isVisible ? '300px' : 0)};
  overflow-x: hidden;
  overflow-y: auto;
  transition: max-height 0.3s, opacity 0.3s;
  opacity: ${p => (p.isVisible ? 1 : 0)};
  z-index: 5;
  @media (max-width: ${props => props.theme.sizes.phone}px) {
    width: 210px;
  }
`

export const List = styled.div``

export const SubmitBtn = styled.div`
  position: relative;
  right: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  float: right;
  width: 90px;
  height: 28px;
  margin: 5px;
  margin-bottom: 10px;
  font-size: ${props => props.theme.fontSize.default};
  text-transform: uppercase;
  color: white;
  background: #0a94f1;
  border-radius: 5px;
  cursor: pointer;
`
TextWrapper.displayName = 'TextWrapper'
SubmitBtn.displayName = 'SubmitBtn'