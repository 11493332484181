import React, { useState } from 'react'
import Autocomplete from 'react-google-autocomplete'

import GoogleMap from '../MyGoogleMap'
import { Input, InputLabel, Row, Col6, Col12, Col4 } from '../style'
import { fontFamily, fontSize } from 'Constants'

const automCompleteStyles = {
  width: '100%',
  margin: '0 auto',
  marginTop: '10px',
  border: '2px solid #ececec',
  minHeight: '46px',
  borderRadius: '5px',
  outline: 'none',
  padding: '10px 15px',
  fontSize: fontSize.h5,
  fontFamily
}

const transformAddress = place => {
  let country = '',
    postal_code = '',
    state = '',
    city = '',
    locality = '',
    route = '',
    street_number = ''

  if (!place.address_components)
    return {};
  place.address_components.forEach(address => {
    if (address.types.includes('country')) {
      country = address.short_name
    }
    if (address.types.includes('postal_code')) {
      postal_code = address.short_name
    }
    if (address.types.includes('administrative_area_level_1')) {
      state = address.short_name
    }
    if (address.types.includes('administrative_area_level_2')) {
      city = address.short_name
    }
    if (address.types.includes('locality')) {
      locality = address.short_name
    }
    if (address.types.includes('route')) {
      route = address.short_name
    }
    if (address.types.includes('street_number')) {
      street_number = address.short_name
    }
  })

  return {
    country,
    postal_code,
    state,
    city,
    locality,
    street_address: `${street_number} ${route} ${locality}`
  }
}
const places = ['city', 'state', 'country', 'postal_code']

const AddressDetails = ({ google, validatorConfig, onHandleChange , t, radius}) => {
  const [location, setLocation] = useState({ lat: '', lng: '' })
  const {
    country,
    state,
    // street_address,
    city,
    postal_code,
    // select_country
  } = validatorConfig
  // const { value } = street_address
  const [values, setValues] = useState({});
console.log("t:", t)
  return (
    <div>
      <Row>
        <Col12>
          <InputLabel mandatory>{t('starting-add-for-add')}</InputLabel>
        </Col12>
      </Row>
      <Row>
        <Col6>
          <InputLabel mandatory>{t('street-address')}</InputLabel>
          <Autocomplete
            style={automCompleteStyles}
            onPlaceSelected={place => {
              const placeAddress = transformAddress(place)
              setValues(placeAddress);
              console.log(placeAddress);
              const location = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
              }
              setLocation({ ...location })
              places.forEach(name => {
                onHandleChange(
                  {
                    target: {
                      name,
                      value: placeAddress[name]
                    }
                  },
                  true
                )
              })
              onHandleChange(
                {
                  target: {
                    name: 'street_address',
                    value: `${placeAddress.street_address}`
                  }
                },
                true
              )
            }}
            types={[]}
          />
        </Col6>
        <Col6>
          <InputLabel>{t('city')}</InputLabel>
          <Input {...city} value={values.locality} />
                    
        </Col6>
      </Row>
      <Row>
        <Col4>
          <InputLabel>{t('state')}</InputLabel>
          <Input {...state} value={values.state} />
        </Col4>
        <Col4>
          <InputLabel>{t('zip')}</InputLabel>
          <Input {...postal_code} disabled={false} />
        </Col4>
        <Col4>
          <InputLabel>{t('country')}</InputLabel>
          <Input {...country} value={values.country} />
        </Col4>
      </Row>
      <Row>
        <Col12>
          <GoogleMap
            radius={radius}
            google={google}
            {...location}
            onHandleChange={onHandleChange}
          />
        </Col12>
      </Row>
      <Row>
        <Col12>
          <InputLabel>{t('select-radius')}</InputLabel>
        </Col12>
      </Row>
    </div>
  )
}

export default AddressDetails
