import styled from 'styled-components'
import { fontFamily, sizes, fontSize } from 'Constants'

export const MainTitle = styled.h2`
  text-align: center;
  margin: 0.1em;
  font-size: ${fontSize.large};
  font-family: ${fontFamily};
  font-weight: 300;
  color: ${props => props.theme.colors.defaultGrey};
  @media (max-width: ${sizes.desktop}px) {
    font-size: ${fontSize.h1};
  }
  @media (max-width: ${sizes.tablet}px) {
    font-size: ${fontSize.h2};
  }
  @media (max-width: ${sizes.phone}px) {
    font-size: ${fontSize.h3};
  }
`
