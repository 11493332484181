import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

export const Wrapper = styled.form``

export const Label = styled.label`
  font-weight: bold;
  font-size: ${p => p.theme.fontSize.small};
`

export const InputWrapper = styled.div`
  border: solid 1px silver;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  border-radius: 5px;
`

export const Input = styled.input`
  width: 92%;
  height: 100%;
  background: transparent;
  border: none
  display: flex;
  align-items: center;
  outline: none;
  font-size: ${p => p.theme.fontSize.default};
  padding: 5px 10px;
`

export const SearchIcon = styled(FontAwesomeIcon).attrs(() => ({
  icon: faSearch
}))`
  color: #000;
  cursor: pointer;
`
