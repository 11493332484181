import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import * as routes from 'Routes/routesConstants'
import { Switch, Route, Redirect } from 'react-router-dom'
import memoize from 'memoize-one'

import { TAB_JOBS } from 'Constants/tabNav'
import { jobStatuses } from 'Constants/jobStatuses'
import { Header, Footer, BurgerMenu, HeaderTabs } from 'Components/blocks'

import { PageWrapper } from 'Components/ui'

import { PostedJobs, BookedJobs, AppliedJobs } from './innerBlocks'
import {
  PostedJobsKeys,
  BookedJobsKeys,
  AppliedJobsKeys,
  getHeaderTabsInitialId
} from './config'

class MyJobs extends PureComponent {
  state = {
    loadMarkJobId: null
  }

  getActiveTabIndex = memoize(url => {
    if (url.includes(routes.posted)) return 'a'
    if (url.includes(routes.applied)) return 'b'
    if (url.includes(routes.booked)) return 'c'
  })

  subRoute = memoize(getHeaderTabsInitialId)

  handleTabClick = id => {
    const { history } = this.props

    switch (id) {
      case 'b':
        history.push(`${routes.myJobs}${routes.applied}`)
        break
      case 'c':
        history.push(`${routes.myJobs}${routes.booked}`)
        break
      default:
        history.push(`${routes.myJobs}${routes.posted}`)
        break
    }
  }

  handleSetActivePostedJob = activeJob => {
    const { history } = this.props

    history.push(`${routes.myJobs}${routes.posted}/${activeJob._id}`)
  }

  handleSetActiveAppliedJob = activeJob => {
    const { history } = this.props

    history.push(`${routes.myJobs}${routes.applied}/${activeJob._id}`)
  }

  handleSetActiveBookedJob = activeJob => {
    const { history } = this.props

    history.push(`${routes.myJobs}${routes.booked}/${activeJob._id}`)
  }

  handleMarkJob = () => {
    const {
      job: { jobDescription },
      onMarkJob,
      user,
      onSendMessage
    } = this.props

    this.setState({ loadMarkJobId: jobDescription._id })

    onMarkJob(jobDescription._id, (err, data) => {
      if (data && data.jobStatus === jobStatuses.done) {
        const newMessage = {
          createdAt: new Date().getTime(),
          text: `-- ${this.props.t('chat:ChatJobCompleted')} --`,
          _id: _.uniqueId('_id'),
          type: 'complete_message',
          sender: user.username
        }

        const payload = {
          chatId: jobDescription.chatId,
          message: newMessage,
          userReceived: jobDescription.doerId,
          jobId: jobDescription._id
        }
        onSendMessage(payload, user.username)
      }
      this.setState({ loadMarkJobId: null })
    })
  }

  render() {
    const { user, location } = this.props
    const { loadMarkJobId } = this.state

    const subRoute = this.subRoute(location)

    const getProps = keys => ({
      ..._.pick(this.props, keys),
      defaultRadius: user.defaultRadius
    })
    this.props.onClose()
    return (
      <PageWrapper>
        {user && <BurgerMenu user={user} />}
        <Header postJob={false} view={Object.keys(user).length === 0} />
        <HeaderTabs
          activeTabIndex={this.getActiveTabIndex(location.pathname)}
          initialRoute={subRoute}
          options={TAB_JOBS}
          onClickLink={this.handleTabClick}
        />

        <Switch>
          <Route
            path={`${routes.myJobs}${routes.applied}`}
            render={() => (
              <AppliedJobs
                {...getProps(AppliedJobsKeys)}
                onClickJob={this.handleSetActiveAppliedJob}
              />
            )}
          />
          <Route
            path={`${routes.myJobs}${routes.booked}`}
            render={() => (
              <BookedJobs
                {...getProps(BookedJobsKeys)}
                loadMarkJobId={loadMarkJobId}
                onClickJob={this.handleSetActiveBookedJob}
                onMarkJobAs={this.handleMarkJob}
              />
            )}
          />
          <Route
            path={`${routes.myJobs}${routes.posted}`}
            render={() => (
              <PostedJobs
                {...getProps(PostedJobsKeys)}
                userName={user.username}
                onClickJob={this.handleSetActivePostedJob}
              />
            )}
          />
          <Redirect to={`${routes.myJobs}${routes.posted}`} />
        </Switch>

        <Footer black isAuth={user} />
      </PageWrapper>
    )
  }
}

MyJobs.propTypes = {
  location: PropTypes.object,
  services: PropTypes.object,
  t: PropTypes.func,
  user: PropTypes.object,
  onGetJobList: PropTypes.func,
  onGetPopularServices: PropTypes.func,
  onMarkJob: PropTypes.func,
  onSendMessage: PropTypes.func,
  onShare: PropTypes.func
}

export default MyJobs
