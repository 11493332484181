import styled from 'styled-components'
import arrows from 'Assets/arrows'

import { fontFamily, sizes, fontSize } from 'Constants'
import { Button } from 'Components/ui'

export const AdWrap = styled.div`
  margin-top: 20px;
  position: relative;
`

export const Content = styled.div`
  padding: -10px 0;
`

export const SlideWrap = styled.div`
  & .image-gallery-right-nav,
  .image-gallery-left-nav {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 0;
    border: 1px solid ${props => props.theme.colors.lightGrayText};
    color: ${props => props.theme.colors.defaultFontBlack};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${fontSize.h3};
    font-family: ${fontFamily};
    &::before {
      content: '';
      display: block;
      width: 18px;
      height: 18px;
    }
  }

  & .image-gallery-right-nav {
    transform: translate(150%, -50%);
    @media (max-width: ${sizes.tablet}px) {
      right: 25px;
    }
    &::before {
      transform: translateX(2%);
      background: center no-repeat url(${arrows.arrowRightBlack});
    }
    @media (max-width: ${sizes.tablet}px) {
      display: none;
    }
  }

  & .image-gallery-left-nav {
    transform: translate(-150%, -50%);
    @media (max-width: ${sizes.tablet}px) {
      left: 25px;
    }
    @media (max-width: ${sizes.tablet}px) {
      display: none;
    }
    &::before {
      transform: translateX(-7%);
      background: center no-repeat url(${arrows.arrowLeftBlack});
    }
  }
`

// ExplanationBlock

export const HowItWorksWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const HowItWorksItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${p => p.theme.sizes.phone}px) {
    flex-direction: column;
  }
`

export const HowItWorksItem = styled.div`
  margin: 25px 0;
  display: flex;
  flex-basis: 31%;
  flex-direction: column;
  text-align: center;
  line-height: 1.7;
  color: ${props => props.theme.colors.lightGrayText};
  & > span {
    font-weight: 400;
    color: #333;
    font-size: ${p => p.theme.fontSize.h2};
    @media (max-width: ${p => p.theme.sizes.desktop}px) {
      font-size: ${p => p.theme.fontSize.h3};
    }
    @media (max-width: ${p => p.theme.sizes.tablet}px) {
      font-size: ${fontSize.h4};
    }
  }
  @media (max-width: ${p => p.theme.sizes.phone}px) {
    margin: 15px 0;
  }
`

export const NearServiceProvidersContiner = styled.div`
  width: 70rem;
  max-width: 90%;
  margin: 0 auto;
`

export const NearServiceProvidersWrapper = styled.div``

export const ProvidersWrapper = styled.div`
  display: flex;
  justify-content: ${p => (p.isLeftAlign ? 'flex-start' : 'space-between')};
  @media (max-width: ${p => p.theme.sizes.tablet}px) {
    flex-direction: column;
  }
`

export const Provider = styled.div`
  padding: 10px;
  display: flex;
  flex-basis: 32%;
  align-items: center;
  &:last-child {
    ${p => p.isLeftAlign && 'margin: 0 0 0 10px'};
  }
  background: ${props => props.theme.colors.mainBackground};
  box-shadow: ${props => props.theme.colors.boxShadow};
  * a {
    text-decoration: none;
    color: ${props => props.theme.colors.lightGrayText};
  }
  @media (max-width: ${p => p.theme.sizes.desktop}px) {
    margin: 0 0 15px 0;
    justify-content: center;
    &:last-child {
      margin: inherit;
    }
  }
`

export const NameGroup = styled.div`
  display: flex;
  margin-bottom: 5px;
`
export const GeneralInfo = styled.div`
  margin-left: 10px;
`

export const StarSpan = styled.span`
  margin: 5px;
  font-weight: 600;
`

//SelectWhatYouNeed block
export const WrapperSelect = styled.div`
  margin: 10px 0;
`

export const WhatYouNeedWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 50px;
  background: white;
  margin: 20px 0;
`

export const WhatYouNeedLinks = styled.div`
  display: flex;
  width: 700px;
  justify-content: space-between;
  margin-top: 60px;
  @media (max-width: ${p => p.theme.sizes.desktop}px) {
    width: 100%;
  }
  & > a > button {
    padding: 10px 15px;
    @media (max-width: ${p => p.theme.sizes.desktop}px) {
      max-width: 230px;
    }
    & > svg {
      margin-right: 5px;
      & > path {
        fill: ${props => props.theme.colors.lightGrayText};
      }
    }
  }
  @media (max-width: ${p => p.theme.sizes.desktop}px) {
    flex-direction: column;
    align-items: center;
    max-width: 318px;
    & > a {
      margin-bottom: 10px;
      width: 100% & > button {
        width: 250px;
        max-width: 250px;
      }
    }
  }
`

export const SelectBtn = styled(Button)`
  max-width: 230px;
  height: 100%;
  background: #ccc;
  transition: 0.2s;
  & > svg > path {
    fill: #fff !important;
  }
  &:hover {
    background: linear-gradient(
      to right,
      rgba(10, 148, 241, 1) 0%,
      rgba(10, 148, 241, 1) 56%,
      rgba(100, 195, 255, 1) 100%
    );
  }
  @media (max-width: ${p => p.theme.sizes.tablet}px) {
    max-width: 310px !important;
    width: 100% !important;
  }
`

export const ViewAll = styled.div`
  text-align: right;
  color: #2989d8;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
`

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const SearchContainer = styled.div`
  max-width: 450px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const Row = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  &:first-child {
    margin-top: 20px;
  }
`

export const Title = styled.span`
  font-weight: 400;
  line-height: 1.7;
  flex-basis: 31%;
  font-size: ${p => p.theme.fontSize.h2};
  color: #333;
  text-align: center;
  margin-bottom: 10px;
  @media (max-width: ${p => p.theme.sizes.desktop}px) {
    font-size: ${p => p.theme.fontSize.h3};
  }
  @media (max-width: ${p => p.theme.sizes.tablet}px) {
    font-size: ${p => p.theme.fontSize.h4};
  }
`

export const Body = styled.span`
  color: ${props => props.theme.colors.lightGrayText};
  line-height: 1.7;
  flex-basis: 31%;
  margin-bottom: 40px;
  text-align: center;
`

export const Items = styled.div`
  display: flex;
  flex-direction: column;
`
