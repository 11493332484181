import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { verify, resendCode } from 'Redux/actions/auth'
import Component from './Verify'

const selector = createStructuredSelector({})

const actions = {
  onVerify: verify,
  onResend: resendCode
}

export default connect(
  selector,
  actions
)(Component)
