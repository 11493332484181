import { createSelector } from 'reselect'

const getReducer = store => store.track

export const getTrackJobList = createSelector(
  getReducer,
  data => data.data
)

export const getGeolocation = createSelector(
  getReducer,
  data => data.geolocation
)
