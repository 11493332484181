import React from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'
import { SERVICE_URL } from 'Constants'
import { PopularCategoriesItemWrapper, Icon, TimesIcon } from '../styles'

const Item = ({ item, isActive, t, onClick }) => {
  return (
    <PopularCategoriesItemWrapper isActive={isActive} onClick={onClick}>
      <Icon src={SERVICE_URL + item.iconName} />
      <span>{t(item.title)}</span>
      {isActive ? <TimesIcon /> : null}
    </PopularCategoriesItemWrapper>
  )
}

Item.propTypes = {
  isActive: PropTypes.bool,
  item: PropTypes.shape({
    title: PropTypes.string,
    iconName: PropTypes.string
  }),
  t: PropTypes.func,
  onClick: PropTypes.func
}

export default withNamespaces('services')(Item)
