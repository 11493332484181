import React, { useCallback, useState, useEffect } from 'react'
import { Input } from '../style'
const InputWrap = ({
  placeholder,
  type,
  name,
  regex,
  isRequired,
  errorMessage,
  requiredMessage,
  onHandleChange,
  value,
  disabled,
  minValue,
  t
}) => {
  const [error, setError] = useState(false)
  const [inputValue, setInputValue] = useState(value)
  const [message, setErrorMessage] = useState()


  useEffect(() => {
    setInputValue(value || '')
  }, [value])

  const onInputChange = useCallback(
    event => {
      const {
        target: { value: val }
      } = event
      if (val) {
        if (isRequired && regex) {
          const r = RegExp(regex)
          if (r.test(val)) {
            setError(false)
          } else {
            setError(true)
            setErrorMessage(errorMessage)
          }
        } else {
          setError(false)
        }
      } else {
        setError(true)
        setErrorMessage(requiredMessage)
      }
      setInputValue(val)
    },
    [isRequired, regex, errorMessage, requiredMessage, setErrorMessage]
  )


  return (
    <div>
      
      <Input
        value={inputValue === null? '':inputValue }     //custom null Code handle
        type={type}
        placeholder={placeholder}
        name={name}
        onChange={event => onHandleChange(event)}
        onBlur={onInputChange}
        disabled={disabled}
        min={minValue}
      />
      {error && <span style={{ color: 'red', margin: '5px' }}>{message}</span>}
    </div>
  )
}

export default InputWrap
