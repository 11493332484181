import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Modal } from 'Components/ui'

import {
  Container,
  Header,
  Title,
  CloseIcon,
  Content,
  Label,
  Textarea,
  SubmitButton,
  // Row,
  // Text,
  // CheckBox,
  ReasonError
} from './style'

const CancelJobModal = ({
  isShow,
  value,
  onChange,
  onClose,
  t,
  onSubmit,
  isLoading,
  // isNoShow
}) => {
  const [isChange, setIsChange] = useState(false)
  // const [checked, setChecked] = useState(true)

  const handleChange = e => {
    onChange(e)
    if (!isChange) {
      setIsChange(true)
    }
  }

  // const handleChangeChecked = () => setChecked(!checked)

  return (
    <Modal isShow={isShow} onRequestClose={onClose}>
      <Container>
        <Header>
          <Title>{t('reasonCancelling')}</Title>
          <CloseIcon onClick={onClose} />
        </Header>
        <Content>
          {/* {isNoShow && (
            <Row>
              <Text>{t('noShow')}</Text>
              <CheckBox type="checkbox" checked={checked} onChange={handleChangeChecked} />
            </Row>
          )} */}
          <Label htmlFor="cancelReason">{t('writeReason')}</Label>
          <Textarea id="cancelReason" value={value} onChange={handleChange} />
          {!value && isChange && (
            <ReasonError>
              {t('deleteAccountScreen:reasonIsRequired')}
            </ReasonError>
          )}
        </Content>
        <SubmitButton disabled={isLoading || !value} onClick={onSubmit}>
          {t('cancelJob')}
        </SubmitButton>
      </Container>
    </Modal>
  )
}

CancelJobModal.propTypes = {
  isShow: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  isNoShow: PropTypes.bool
}

export default CancelJobModal
