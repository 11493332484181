import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'Components/ui'

export const Container = styled.div`
  padding: 20px 10px 10px;
`

export const Header = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const Title = styled.h1`
  font-size: ${p => p.theme.fontSize.h3};
  color: #000;
  text-align: center;
  font-weight: bold;
`

export const CloseIcon = styled(FontAwesomeIcon).attrs(() => ({
  icon: faTimes
}))`
  position: absolute;
  top: -20px;
  transform: translateY(-50%);
  right: -20px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.joinUs};
  background: #fff;
  width: 25px !important;
  height: 25px;
  border-radius: 50%;
  box-shadow: 0 1px 4px 0 #c9cfde;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.5;
  }
`

export const Label = styled.label`
  font-size: ${p => p.theme.fontSize.small};
  display: block;
  margin-bottom: 5px;
`

export const Content = styled.div`
  margin: 20px auto 0;
  max-width: 300px;
`

export const Textarea = styled.textarea`
  border: solid 1px silver;
  border-radius: 5px;
  width: 100%;
  resize: none;
  height: 150px;
  outline: none;
  font-size: ${p => p.theme.fontSize.default};
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const ConfirmButton = styled(Button)`
  border-radius: 0;
  flex-basis: 50%;
  font-weight: bold;
  width: 150px;
`

export const SubmitButton = styled(ConfirmButton)`
  ${p => p.disabled && 'pointer-events: none; opacity: 0.6;'};
  border-bottom-right-radius: 12px;
`

export const CancelButton = styled(ConfirmButton)`
  filter: grayscale(100%);
  border-bottom-left-radius: 12px;
`

export const DisputeTip = styled.span``
