import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import styled from 'styled-components'
import { fontFamily, sizes, fontSize } from 'Constants'
import { arrows } from 'Assets/arrows'
import { Error as Errors, BtnSubmit } from 'Components/ui'

export const Main = styled.main`
  background: #f9fcff;
  min-height: 100vh;
  padding: 30px 0;
`

export const Title = styled.h3`
  margin: 0;
  text-align: center;
  font-size: ${fontSize.h1};
  font-family: ${fontFamily};
`

export const Wrapper = styled.div`
  width: 70rem;
  max-width: 90%;
  margin: 0 auto;
`

export const Section = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 30px;
`

export const Content = styled.div`
  width: 50%;
  @media (max-width: ${sizes.tablet}px) {
    width: 75%;
  }
  @media (max-width: ${sizes.phone}px) {
    width: 100%;
  }
`

export const AsideLeft = styled.aside`
  width: calc(25% - 25px);
  margin-right: 25px;
  @media (max-width: ${sizes.tablet}px) {
    display: none;
  }
`

export const AsideRight = styled.aside`
  width: calc(25% - 25px);
  margin-left: 25px;
  @media (max-width: ${sizes.phone}px) {
    display: none;
  }
`

export const Card = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  /* width: 336px;
  height: 280px; */
  border-radius: 5px;
  margin-bottom: 10px;
  background: repeating-linear-gradient(
    45deg,
    #ccc,
    #ccc 10px,
    #eee 10px,
    #eee 20px
  );
`

// export const CardOverlay = styled.div`
//   position: absolute;
//   width: 100%;
//   height: 200px;
//   border-radius: 5px;
//   cursor: pointer;
//   background-color: orange;
// `

export const FormBox = styled.div`
  width: 100%;
  margin-bottom: 20px;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
`

export const BoxTitle = styled.h3`
  margin: 0;
  font-size: ${fontSize.h4};
  font-family: ${fontFamily};
`

export const BoxInfo = styled.h3`
  margin: 0;
  color: #999;
  font-size: ${fontSize.small};
  font-family: ${fontFamily};
  font-weight: 400;
`

export const Input = styled.input`
  width: 100%;
  padding: 10px 15px;
  margin-top: 10px;
  font-size: ${fontSize.h3};
  font-family: ${fontFamily};
  border: 2px solid #eee;
  border-radius: 5px;
  &:-webkit-autofill {
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0 1000px #5da6ff inset;
    border: none;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
`

export const Textarea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  font-size: ${fontSize.h3};
  font-family: ${fontFamily};
  border: 2px solid #eee;
  border-radius: 5px;
  &:-webkit-autofill {
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0 1000px #5da6ff inset;
    border: none;
  }
`

export const Label = styled.label`
  display: inline-block;
  font-size: ${fontSize.default};
  font-family: ${fontFamily};
  padding-bottom: 5px;
  align: left;
`

export const Select = styled.select`
  width: 100%;
  border: 2px solid #eee;
  background: #fff;
  padding: 10px;
  margin-top: 10px;
  font-size: ${fontSize.h5};
  font-family: ${fontFamily};
  position: relative;
  background: url('${arrows.arrowDown}') no-repeat;
  background-position: 95% 50%;
  appearance: none;
  &::-ms-expand {
    display: none;
  }
  &:-webkit-autofill {
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0 1000px #5da6ff inset;
    border: none;
  }
  @media (max-width: ${sizes.phone}px) {
    text-indent: 0px;
    font-size: ${fontSize.h6};
    padding-left: 0;
    /* & option {
      background: red;
    } */

	/* text-indent:-1.5px;
	padding-left:2.5px;
	padding-left:5px; */

  }
`

export const Row = styled.div`
  width: 100%;
  padding: 5px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: ${props => (props.noWrap ? 'nowrap' : 'wrap')};

  @media (max-width: ${sizes.tablet}px) {
    ${props =>
      !props.noBreak &&
      `flex-direction: column;
      justify-content: flex-start;
      align-items: center;`};
  }
`

export const Col12 = styled.div`
  flex: 0 0 100%;
  padding: 0 5px;
  width: 100%;
`

export const Col6 = styled.div`
  flex: 0 0 50%;
  padding: 0 5px;
  & .react-datepicker-wrapper,
  react-datepicker__input-container {
    width: 100%;
  }
  @media (max-width: ${sizes.tablet}px) {
    flex: 0 0 100%;
    width: 100%;
  }
`

export const JobPost = styled.div`
  background: #f9fcff;
  @media (max-width: ${sizes.tablet}px) {
    margin-top: 75px;
  }
`

export const CancelButton = styled(props => <BtnSubmit {...props} />)`
  background: linear-gradient(-75deg, #f0f0f0, #cfcfcf);
  color: ${p => p.theme.colors.watchButtonColor};
  &:hover {
    background: linear-gradient(-120deg, #cfcfcf, #f0f0f0);
  }
`

export const Error = styled(props => <Errors {...props} />)`
  position: initial;
  margin-top: 5px;
`

export const BudgetInput = styled(props => <Input {...props} />)`
  width: calc(100% - 120px);
`

export const CurrencyContainer = styled.div`
  width: 90px;
  max-height: 48px;
  border-radius: 5px;
  border: 2px solid #eee;
  background: #fff;
  margin-top: 10px;
  margin-left: 5px;
`

export const BudgetWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const styles = {
  budgetConvert: {
    width: 'calc(100% - 120px)',
    pointerEvents: 'none'
  },
  currency: {
    width: 90,
    marginLeft: 5,
    fontSize: 18,
    textAlign: 'center',
    pointerEvents: 'none'
  }
}

export const Text = styled.span`
  font-size: 18px;
  font-weight: 300;
`
