import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faAward } from '@fortawesome/free-solid-svg-icons'

export const Pro = ({ size = null, visible = true }) => {
  if (!visible) return null

  const iconSize = size && size - 6
  return (
    <Wrapper iconSize={iconSize} size={size}>
      <FontAwesomeIcon icon={faAward} />
    </Wrapper>
  )
}

Pro.propTypes = {
  size: PropTypes.number,
  visible: PropTypes.bool
}
