import styled from 'styled-components'
import { fontFamily, sizes, fontSize } from 'Constants'

export const Wrapper = styled.div`
  background: ${props => props.theme.colors.componentsBackground};
  margin-bottom: 20px;
  width: 100%;
  box-shadow: ${props => props.theme.colors.boxShadow};
  border-radius: 4px;
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid ${props => props.theme.colors.lightGrayBorder};
`

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  & > div div span {
    margin-left: 15px;
    font-weight: 700;
    font-size: ${fontSize.h3};
    font-family: ${fontFamily};
  }
`

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`

export const BodyWrapper = styled.div`
  padding: 0 20px;
  & > p {
    padding-bottom: 15px;
    line-height: 2;
    color: ${props => props.theme.colors.lightGrayText};
  }
`

export const InfoSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding: 10px 0;
`

export const InfoLeft = styled.div`
  & > span {
    font-weight: 600;
    color: ${props => props.theme.colors.defaultGreen};
    margin-right: 5px;
  }
`

export const InfoRight = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  & a {
    text-decoration: none;
    color: ${props => props.theme.colors.defaultFontBlack};
  }
  & span {
    margin-left: 10px;
  }
`
export const Star = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    & > path {
      fill: ${props => props.theme.colors.premiumBg};
    }
  }
`

export const Comments = styled.div`
  display: flex;
  align-items: center;
  & > a > svg {
    & > path {
      fill: ${props => props.theme.colors.defaultGreen};
    }
  }
`

export const Distance = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    & > path {
      fill: ${props => props.theme.colors.accentColor};
    }
  }
`

export const ScrollWrap = styled.div`
  max-height: 200px;
  overflow-y: scroll;
  padding-bottom: 20px;
  border-bottom: 20px solid #fff;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 5px #fff;
  }
  @media (max-width: ${sizes.desktop}px) {
    padding: 0 0 30px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export const Text = styled.p`
  margin: 0;
  line-height: 1.5;
  color: #555;
  font-family: ${fontFamily};
  font-size: ${fontSize.h5};
  word-wrap: break-word;
`

export const TextVerified = styled.div`
  padding: 2px 10px;
  border-radius: 10px;
  color: ${props => props.theme.colors.joinButtonColor};
  background-color: ${props => props.theme.colors.defaultGreen};
  font-weight: bold;
  font-size: 12px;
  margin-left: 15px;
  margin-top: 5px;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
`

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`