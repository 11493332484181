import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
  withShare,
  withCancelJob,
  withHireSP,
  withSendHiringRequest
} from 'Components/hoc'
import { withNamespaces } from 'react-i18next'

import { popularServices } from 'Redux/actions/services'
import { deleteJob } from 'Redux/actions/job'
import { loadPostedJobList } from 'Redux/actions/postedJobs'
import { loadAppliedJobList } from 'Redux/actions/appliedJobs'
import { loadBookedJobList, markAsJob } from 'Redux/actions/bookedJobs'
import { sendMessage } from 'Redux/actions/chats'

import {
  addJobToFavourites,
  removeJobFromFavourites
} from 'Redux/actions/favourites'

import { getProfile } from 'Redux/selectors/user'
import { getJobList, getSpecificJob } from 'Redux/selectors/jobList'
import { getSpecificSP } from 'Redux/selectors/serviceProvider'
import { getServices } from 'Redux/selectors/services'
import { getNearestSP } from 'Redux/selectors/nearestSP'
import { getPostedJobs } from 'Redux/selectors/postedJobs'
import { getAppliedJobs } from 'Redux/selectors/appliedJobs'
import { getBookedJobs } from 'Redux/selectors/bookedJobs'

import Component from './PostAds'

const selector = createStructuredSelector({
  user: getProfile,
  job: getJobList,
  postedJobs: getPostedJobs,
  services: getServices,
  nearestSP: getNearestSP,
  appliedJobs: getAppliedJobs,
  bookedJobs: getBookedJobs,
  specificJob: getSpecificJob,
  serviceProfile: getSpecificSP
})

const actions = {
  onGetPostedJobList: loadPostedJobList,
  onGetPopularServices: popularServices,
  onAddToFavourites: addJobToFavourites,
  onRemoveFromFavourites: removeJobFromFavourites,
  onDeleteJob: deleteJob,
  onLoadAppliedJobList: loadAppliedJobList,
  onLoadBookedJobList: loadBookedJobList,
  onMarkJob: markAsJob,
  onSendMessage: sendMessage,
}

export default compose(
  withSendHiringRequest,
  withHireSP,
  withShare,
  withCancelJob,
  connect(
    selector,
    actions
  ),
  withNamespaces([
    'findJobPage',
    'homePage',
    'appServiceProvider',
    'rating',
    'common',
    'chat'
  ])
)(Component)
