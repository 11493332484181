import { api } from 'Configs/App'

const makeid = length => {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const handleSocialClick = provider => {
  if (provider === 'apple') {
    const state = makeid(10)
    window.location.replace(
      `https://appleid.apple.com/auth/authorize?response_type=code%20id_token&client_id=com.spotjobs.service.mobile&redirect_uri=${api.defaultUrl}/auth/oauth/apple/web/callback&state=${state}&scope=email%20name&response_mode=form_post`
    )
    return
  }
  window.location.replace(
    `${api.defaultUrl}/auth/oauth/${provider}/authenticate?redirect_uri=${window.location.href}`
  )
}