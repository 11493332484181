import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import _ from 'lodash'

import { createReducer } from 'Helpers/redux'
import processProfile from 'Helpers/processProfile'

import { SIGN_IN, LOG_OUT } from 'Redux/actions/auth'
import { UPDATE_USER, FETCH_USER, VERIFY_ATTRIBUTE, ID_VERIFICATION } from 'Redux/actions/user'
import { ADS_VIEWED } from 'Redux/actions/subscription'
import { LINK_CARD, DELETE_CARD, WITHDRAW } from 'Redux/actions/paymentsTransactions'
import { idVerificationStatus } from 'Constants/idVerification'
import { SET_PREFERRED_LANGUAGE } from 'Redux/actions/settings'

const persistConfig = {
  key: 'user',
  storage,
  blacklist: []
}

const initialState = {
  user: {}
}

const handlers = {
  [SIGN_IN.SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      user: processProfile(payload.user)
    }
  },
  [UPDATE_USER.SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      user: processProfile(payload)
    }
  },
  [LOG_OUT.REQUEST]: () => initialState,
  [FETCH_USER.SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      user: processProfile(payload)
    }
  },
  [VERIFY_ATTRIBUTE.SUCCESS]: (prevState, { meta }) => {
    return {
      ...prevState,
      user: {
        ...prevState.user,
        [meta.changedAttrKey]: true
      }
    }
  },
  [ADS_VIEWED.SUCCESS]: state => ({
    ...state,
    user: {
      ...state.user,
      ptsAmount: _.get(state, 'user.ptsAmount', 0)
    }
  }),
  [LINK_CARD.SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      user: {
        ...state.user,
        credit_card_id: _.get(payload, 'data.credit_card_id')
      }
    }
  },
  [DELETE_CARD.SUCCESS]: (state) => {
    delete state.user.credit_card_id

    return {
      ...state,
    }
  },
  [WITHDRAW.SUCCESS]: (state, {meta}) => ({
    ...state,
    user: {
      ...state.user,
      total_amount: state.user.total_amount - meta.amount
    }
  }),
  [ID_VERIFICATION.SUCCESS]: (state, { payload }) => payload?.status === 'success' ? ({
    ...state,
    user: {
      ...state.user,
      id_verification: idVerificationStatus.success
    }
  }) : ({
    ...state,
    user: {
      ...state.user,
      id_verification: idVerificationStatus.failed,
      isPaidIdentityVerification: false
    }
  }),
  [ID_VERIFICATION.FAILURE]: (state) => ({
    ...state,
    user: {
      ...state.user,
      id_verification: idVerificationStatus.failed,
      isPaidIdentityVerification: false
    }
  }),
  // [CONNECT_PAYPAL.SUCCESS]: (state, { meta }) => ({
  //   ...state,
  //   user: {
  //     ...state.user,
  //     paypal_account_withdraw: meta?.code || true
  //   }
  // })
  [SET_PREFERRED_LANGUAGE.SUCCESS]: (state, { payload: { local } }) => ({
    ...state,
    user: {
      ...state.user,
      local,
    }
  })
}

export default persistReducer(
  persistConfig,
  createReducer(initialState, handlers)
)
