import React, { Component } from 'react'
import { Map as Gmap, Circle, Marker } from 'google-maps-react'
import { withNamespaces } from 'react-i18next'
import { MapWrapper } from './styles'

class MapContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lat: 0,
      lng: 0,
      location: null,
      radius: null
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.location && nextProps.location !== prevState.location) {
      const location = nextProps.location.split('/')
      return {
        lat: Number.parseFloat(location[0]),
        lng: Number.parseFloat(location[1]),
        location: nextProps.location
      }
    }
    if (nextProps.radius !== prevState.radius) {
      return {
        radius: nextProps.radius
      }
    }
    return null
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return (
      nextState.lat !== this.state.lat ||
      nextState.lng !== this.state.lng ||
      nextProps.radius !== this.state.radius
    )
  }

  render() {
    const { google, radius, onHandleClick, marker } = this.props
    const { lat, lng, location } = this.state
    return (
      <MapWrapper>
        <Gmap
          center={{ lat: lat || 0, lng: lng || 0 }}
          google={google}
          initialCenter={{ lat: lat, lng: lng }}
          zoom={location ? 13 : 1}
          onClick={onHandleClick}
        >
          {!marker && location && lat && lng && (
            <Circle
              center={{ lat, lng }}
              fillColor="#FF0000"
              fillOpacity={0.2}
              radius={radius * 100}
              strokeColor="transparent"
              strokeOpacity={0}
              strokeWeight={5}
            />
          )}
          {marker && location && lat && lng && (
            <Marker
              name={'Your Position'}
              position={{ lat: lat, lng: lng }}
            />
          )}
        </Gmap>
      </MapWrapper>
    )
  }
}

export const Map = withNamespaces('map')(MapContainer)
