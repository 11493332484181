import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { GoogleApiWrapper } from 'google-maps-react'
import { withNamespaces } from 'react-i18next'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Formik } from 'formik'
import Switch from 'react-switch'
import Services from './innerBlocks/ServicesList'
import ImageUpload from './innerBlocks/ImageUploader'
import moment from 'moment'
import _ from 'lodash'
import * as routes from 'Routes/routesConstants'

import ProfileImageManager from 'Services/Images/ProfileImagesManager'
import { profileImageTypes } from 'Services/Constants/profileImages.constants'
import { gooogleApiKey } from 'Constants'
import PHONES from 'Constants/phones'
import { arrows } from 'Assets/arrows'
import { icons } from 'Assets/icons'

import profileLimits from 'Configs/profileLimits'

import { DEFAULT_RADIUS } from 'Constants/filters'
import { PARSE_LOCATION_INFO } from 'Constants/parseLocationInfo'

import {
  ServicesModal,
  AddLinkModal,
  ProfileImage,
  LocationAutocomplete,
  AttributeVerifyModal,
  // PhoneList,
  PhoneListMobile,
  Map,
  LinksUploader,
  UploadImage
} from 'Components/blocks'

import {
  DatePicker,
  ProfileBackground,
  FormContainer,
  Logo,
  Registered,
  SubTitle,
  InputWrapper,
  ChekboxInput,
  CheckBoxSpan,
  ForgetLink,
  CheckBoxText,
  TextBlock
} from 'Components/ui'

import { FormContainerFooter as Footer } from 'Components/ui/Containers'

import { root, termsCond } from 'Routes/routesConstants'

import {
  Loading,
  ContentHeaderContainer,
  ContentHeader,
  ContnentHeaderBackButton,
  Form,
  Row,
  Input,
  InputShort,
  AvatarContainer,
  AvatarImg,
  AvatarCameraImgWrapper,
  AvatarCameraImg,
  ProviderHeader,
  ProviderInfo,
  Section,
  SectionHeader,
  SectionSubheader,
  SectionHeaderContainer,
  Frame,
  AddServicesButton,
  AvatarInput,
  SaveButton,
  TextArea,
  TitleWrap,
  Wrap,
  DateBottomLabel,
  DateLabel,
  DatePickerWrapper,
  Label,
  TermsError,
  PhoneWrapper,
  DivRelative,
  CountryCodeLabel,
  ProSublabel,
  UsernameContainer,
  UsernameLabel,
  AlertContainer,
  AlertTitle,
  AlertContent,
  ResendEmail,
  ImageWrapper,
  VerifyButton,
  VerificationError
} from './styles'

import {
  ALLOWED_IMG_EXTENSIONS,
  MAX_IMG_SIZE,
  getValidationSchema,
  MAX_IMG_SIZE_STR
} from './config'
import qs from 'qs'
import { idVerificationStatus } from 'Constants/idVerification'
import { isPhoneNumber, formatPhoneNumber } from 'Constants/regex'

class ProfilePage extends PureComponent {
  constructor(props) {
    super(props)
    const isPremium = _.get(props, 'user.isPremium', false)
    const userRole = isPremium ? 'premium' : 'free'
    this.state = {
      userRole,
      isResend: false,
      isUndo: false,
      isFirstFetch: true,
      isAddServicesModalVisible: false,
      addLinkType: null,
      validationSchema: getValidationSchema(userRole, props.t),
      services: _.get(props, 'user.services') || [],
      editId: null,
      isServicesTouched: false,
      certificate: false,
      picsOfWork: false,
      idPic: false,
      videoLinks: false,
      websiteLinks: false,
      isRequest: false,
      check: this.props.user.isAgreedWithTerms || false,
      errors: null,
      submitCount: null,
      location: this.props.user.geolocation || '',
      attributeToVerify: null,
      minDate: new Date('1900-01-01'),
      maxDate: new Date(),
      isUndoSuccess: false,
      isFocusPhonenumber: false,
      verification_type: 'id_card',
      idVerifyBase64: null,
      faceVerifyBase64: null,
      idVerifySize: 0,
      faceVerifySize: 0,
      isVerifying: false
    }
  }

  componentDidMount() {
    this.props.onFetchUser(() => {
      this.setState({ isFirstFetch: false })
    })
    window.addEventListener('storage', this.handleVerify)
    const { location, t, history } = this.props
    const query = qs.parse(location.search.slice(1)) || {}
    if (query) {
      if (query.status === 'success') {
        history.replace(`${routes.profile}`)
        toast.success(t('profilePage:paymentIdentity.success'))
      }
      if (query.status === 'failed') {
        history.replace(`${routes.profile}`)
        toast.error(t('profilePage:paymentIdentity.error'))
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('storage', this.handleVerify)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user.verifyEmail !== this.props.user.verifyEmail) {
      this.formik.setFieldValue('email', nextProps.user.email)
    }
  }

  handleVerify = e => {
    if (e.storageArea.verify === 'true') {
      this.setState({
        check: true
      })
      localStorage.setItem('verify', null)
    }
  }

  avatarInput = React.createRef()

  handleSubmit = async data => {
    const { user, t } = this.props
    this.setState({ isRequest: true })

    let defaultRadius = user.defaultRadius

    try {
      defaultRadius = parseInt(data.defaultRadius)
    } catch (e) {}
    const userUpdate = {
      email: data.email !== user.email ? data.email : undefined,
      firstName:
        data.firstName !== user.given_name ? data.firstName : undefined,
      lastName: data.lastName !== user.family_name ? data.lastName : undefined,
      birthdate:
        data.birthdate &&
        moment.utc(data.birthdate).format('YYYY-MM-DD') !== user.birthdate
          ? moment.utc(data.birthdate).format()
          : undefined,
      defaultRadius:
        defaultRadius !== user.defaultRadius ? defaultRadius : undefined,
      address: data.address !== user.address ? data.address : undefined,
      city: data.city !== user.city ? data.city : undefined,
      state: data.state !== user.state ? data.state : undefined,
      zip: data.zip !== user.zip ? data.zip : undefined,
      country: data.country !== user.country ? data.country : undefined,
      isProvider:
        data.isProvider !== user.isProvider ? data.isProvider : undefined,
      isPro: data.isPro !== user.isPro ? data.isPro : undefined,
      aboutYourself:
        data.aboutYourself && data.aboutYourself !== user.about
          ? data.aboutYourself
          : undefined,
      websiteLinks: this.state.websiteLinks ? data.websiteLinks : undefined,
      videoLinks: this.state.videoLinks ? data.videoLinks : undefined,
      services: this.state.isServicesTouched ? this.state.services : undefined,
      mail_subject: t('updatePendingVerification'),
      mail_content: t('contentMailConfirmUpdateEmail'),
      mail_button_name: t('mailButtonName')
    }

    // upload certificates to S3
    const certificates = _.get(data, 'certificate.base64Array', [])
    if (
      data.isPro &&
      _.filter(certificates, item => _.isString(item)).length === 0
    ) {
      toast.error(t('fields.AreYouPro.validationErrors.noCertificates'))

      this.setState({ isRequest: false })
      return
    }

    if (this.state.location) {
      if (this.state.location !== user.geolocation) {
        userUpdate.geolocation = this.state.location
      }
    } else {
      toast.error(t('locationErr'))
      this.setState({ isRequest: false })
      return
    }

    if (this.state.certificate && certificates) {
      const certsIds = await ProfileImageManager.updateProfileImages(
        profileImageTypes.certificates,
        certificates,
        user.username
      )

      userUpdate.certificates = certsIds
    }

    // upload avatar to S3
    if (data.avatarB64 && data.avatarB64 !== user.avatarURL) {
      const avatarResponse = await ProfileImageManager.putProfileImage(
        {
          imageType: profileImageTypes.avatar,
          image: data.avatarB64
        },
        {
          imageName: user.username
        }
      )

      userUpdate.avatarB64 = avatarResponse.filename
    }

    if (this.state.check !== user.isAgreedWithTerms) {
      userUpdate.isAgreedWithTerms = this.state.check
    }

    if (formatPhoneNumber(data.phone)) {
      const phone = data.code + formatPhoneNumber(data.phone)
      if (phone !== user.phone_number_format) {
        userUpdate.phoneNumber = phone
        userUpdate.country_code = data.code
      }
    }

    // upload pics of work to S3
    const picsOfWork = _.get(data, 'picsOfWork.base64Array', null)
    if (this.state.picsOfWork && picsOfWork) {
      const picsOfWorkIds = await ProfileImageManager.updateProfileImages(
        profileImageTypes.picsOfWork,
        picsOfWork,
        user.username
      )

      userUpdate.picsOfWork = picsOfWorkIds
    }

    if (data.local && data.local?.toLowerCase() !== user.local?.toLowerCase()) {
      userUpdate.local = data.local.toLowerCase()
    }

    const filteredUpdate = {}

    Object.keys(userUpdate).forEach(key => {
      if (userUpdate[key] !== undefined) {
        filteredUpdate[key] = userUpdate[key]
      }
    })

    if (Object.keys(filteredUpdate).length !== 0) {
      this.props.onUpdateProfile(filteredUpdate, error => {
        if (!error) {
          toast.success('Saved')
        }
        this.setState({
          isRequest: false,
          isUndoSuccess: false,
          idVerifyBase64: null,
          faceVerifyBase64: null
        })
      })
    } else {
      toast.success('No changes detected')
      this.setState({ isRequest: false })
    }
  }

  handleVerifyAttribute = code => {
    const { onVerifyAttribute, t } = this.props

    const { attributeToVerify } = this.state
    onVerifyAttribute(attributeToVerify, code, err => {
      if (!err) {
        this.handleCloseAttributeVerifyModal()
        toast.success(t(`verifyPass${attributeToVerify}`))
      }
    })
  }

  handleSendAttribVerificationCode = (attribute = null) => {
    const { onSendAttributeVerificationCode, t } = this.props

    const { attributeToVerify } = this.state

    onSendAttributeVerificationCode(attribute || attributeToVerify, error => {
      !error && toast.success(t('verifySended'))
    })
  }

  handleAvatarClick = () => {
    this.avatarInput.current.click()
  }

  handleOpenAddLinkModal = addLinkType => {
    this.setState({
      addLinkType
    })
  }

  handleCloseAddLinkModal = () => {
    this.setState({
      addLinkType: null
    })
  }

  handleOpenAttributeVerifyModal = attributeToVerify => {
    const { onSendAttributeVerificationCode, t } = this.props
    onSendAttributeVerificationCode(attributeToVerify || null, error => {
      !error && toast.success(t('verifySended'))
    })
    this.setState({ attributeToVerify })
  }

  handleCloseAttributeVerifyModal = () => {
    this.setState({
      attributeToVerify: null
    })
  }

  handleImgListChanged = (listType, filesArray, base64Array, setFieldValue) => {
    this.setState({
      [listType]: true
    })

    setFieldValue(listType, {
      filesArray,
      base64Array
    })
  }

  handleMaxItemsUploaded = () => {
    const { t } = this.props

    toast.error(t('overflowError'))
  }

  handleRemoveImage = (image, imageId, type, values, setFieldValue) => {
    let nextVal = []

    if (/(data:image\/.*?;base64),/g.exec(image)) {
      nextVal = values.filter((item, index) => index !== imageId)
    } else {
      nextVal = values.map((item, index) => {
        if (index === imageId) {
          return {
            _id: image,
            status: 'deleted'
          }
        }

        return item
      })
    }

    this.setState({
      [type]: true
    })

    setFieldValue(type, { base64Array: nextVal })
  }

  handleOnCheckBoxClick = () => {
    this.setState({
      check: !this.state.check
    })
  }

  handleMapClick = (t, map, coord, setFieldValue) => {
    const { latLng } = coord
    this.setState({
      location: `${latLng.lat()}/${latLng.lng()}`
    })
    this.props.onGetGeo(latLng.lat(), latLng.lng(), (error, response) => {
      this.handleAddressChanged(response.results[0], setFieldValue, true)
    })
  }

  handleAddressChanged = (
    locationInfo = {},
    setFieldValue,
    fromMapClick = false
  ) => {
    const { address_components, geometry } = locationInfo
    PARSE_LOCATION_INFO.forEach(infoType => {
      const info = _.find(
        address_components,
        item => item.types.indexOf(infoType.searchForType) > -1
      )
      if (info) {
        if (infoType.putToField === 'country' && info.short_name) {
          setFieldValue('local', info.short_name)
        }
        setFieldValue(infoType.putToField, infoType.pickField(info))
      } else {
        setFieldValue(infoType.putToField, '')
      }
    })

    const location = _.get(geometry, 'location', {})

    if (!fromMapClick && location.lat && location.lng) {
      this.setState({
        location: `${location.lat()}/${location.lng()}`
      })
    } else if (!fromMapClick) {
      this.setState({
        location: ''
      })
    }

    let address = locationInfo.formatted_address

    const route = _.find(
      address_components,
      item => item.types.indexOf('route') > -1
    )

    if (route && route.long_name) {
      address = route.long_name

      const streetNumber = _.find(
        address_components,
        item => item.types.indexOf('street_number') > -1
      )

      if (streetNumber && streetNumber.long_name) {
        address += `, ${streetNumber.long_name}`
      }
    }
    setFieldValue('address', address)
  }

  handleToggleAddServicesModalShown = () => {
    this.setState(prevState => ({
      editId: null,
      isAddServicesModalVisible: !prevState.isAddServicesModalVisible
    }))
  }

  handleServicesModalDone = services => {
    this.setState({
      isAddServicesModalVisible: false,
      isServicesTouched: true,
      editId: null,
      services
    })
  }

  handleAvatarChanged = (event, setFieldValue, setFieldError) => {
    const file = event.target.files[0]

    if (!file) return

    if (file.size > MAX_IMG_SIZE) {
      this.handleImgSizeOverflowException()

      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => setFieldValue('avatarB64', reader.result)

    reader.onerror = error => setFieldError(error.message)
  }

  handleAddLink = (newLink, type, values, setFieldValue) => {
    const nextLinksList = values[type] || []

    nextLinksList.push(newLink)

    setFieldValue(type, nextLinksList)

    this.handleCloseAddLinkModal()

    this.setState({
      websiteLinks: nextLinksList,
      [type]: true
    })
  }

  handleDeleteLink = (indexToDelete, type, values, setFieldValue) => {
    this.setState({
      [type]: true
    })
    setFieldValue(
      type,
      values[type].filter((item, index) => index !== indexToDelete)
    )
  }

  findIndex = (id, child) =>
    child
      ? _.findIndex(this.state.services, { serviceId: id })
      : _.findIndex(this.state.services, { categoryId: id })

  getStatus = (id, child = false) => {
    return this.state.services[this.findIndex(id, child)].status
  }

  statusChange = (id, child = false) => {
    const arr = this.state.services

    arr[this.findIndex(id, child)].status =
      arr[this.findIndex(id, child)].status === 'active' ? 'inactive' : 'active'

    this.setState({
      services: [...arr],
      isServicesTouched: true
    })
  }

  handleDeleteService = id => {
    this.state.services.splice(this.findIndex(id), 1)
    this.setState(prevState => ({
      services: [...prevState.services],
      isServicesTouched: true
    }))
  }

  handleEditService = id => {
    this.setState(prevState => ({
      editId: id,
      isAddServicesModalVisible: !prevState.isAddServicesModalVisible
    }))
  }

  handleCheckGeocode = () => {
    const { t } = this.props

    this.formik.setFieldTouched('address')

    setTimeout(() => {
      if (!this.state.location) toast.warn(t('errorAddressLocated'))
    }, 100)
  }

  handleChangeDefaultRadius = (e, handleChange, defaultRadiusValue) => {
    // if (_.isNumber(defaultRadiusValue)) {
    if (defaultRadiusValue.toString().length < 11) {
      handleChange(e)
    }
    // }
  }

  resendEmailClick = () => {
    const { onResendEmail, t } = this.props
    this.setState({ isResend: true })
    const dataResendMail = {
      mail_subject: t('updatePendingVerification'),
      mail_content: t('contentMailConfirmUpdateEmail'),
      mail_button_name: t('mailButtonName')
    }
    onResendEmail(dataResendMail, error => {
      if (!error) {
        toast.success(t('sentEmail'))
      } else if (error.code === 'Verify_NotFound') {
        toast.success(t('verifySuccessAndReload'))
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      }
      this.setState({ isResend: false })
    })
  }

  undoChangeEmailClick = () => {
    const { onUndoChangeEmail, onFetchUser, t } = this.props
    this.setState({ isUndo: true })

    onUndoChangeEmail(error => {
      if (!error) {
        onFetchUser()
        toast.success(t('undoChangeEmailSuccess'))
      } else if (error.code === 'Verify_NotFound') {
        toast.error(t('verifySuccessAndReload'))
      }
      this.setState({ isUndo: false, isUndoSuccess: true })
    })
  }

  renderForm = props => {
    const {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
      setFieldError,
      submitCount,
      isSubmitting,
      isValidating
    } = props

    const {
      addLinkType,
      userRole,
      isRequest,
      check,
      isResend,
      isUndo
    } = this.state

    const { t, user = {}, services } = this.props

    const isFormTouched = submitCount > 0
    if (isSubmitting && !isValidating && Object.keys(errors).length !== 0) {
      window.scrollTo({ top: 30, behavior: 'smooth' })
    }

    const isValid = isPhoneNumber.test(values.phone)

    const handleFocusPhonenumber = () => {
      if (!this.state.isFocusPhonenumber) {
        this.setState({ isFocusPhonenumber: true })
      }
    }

    return (
      <Form onSubmit={handleSubmit}>
        <Frame>
          <Row>
            <AvatarContainer onClick={this.handleAvatarClick}>
              <AvatarImg>
                <ProfileImage
                  src={values.avatarB64 || icons.avatar}
                  type="avatar"
                  userId={user && user.username}
                />
              </AvatarImg>
              <AvatarCameraImgWrapper>
                <AvatarCameraImg src={icons.camera} />
              </AvatarCameraImgWrapper>
              <AvatarInput
                accept={ALLOWED_IMG_EXTENSIONS}
                multiple={false}
                name="avatarInput"
                ref={this.avatarInput}
                type="file"
                onChange={event =>
                  this.handleAvatarChanged(event, setFieldValue, setFieldError)
                }
              />
            </AvatarContainer>
            <UsernameContainer>
              <UsernameLabel>
                {user.authProvider !== 'cognito' && user.username.includes('_')
                  ? user.username.slice(0, user.username.indexOf('_'))
                  : user.username}
              </UsernameLabel>
            </UsernameContainer>
          </Row>
          {user.verifyEmail && !this.state.isUndoSuccess ? (
            <Row>
              <AlertContainer>
                <AlertTitle>{t('updatePendingVerification')}</AlertTitle>
                <AlertContent>
                  {t('updatePendingVerificationDescription').replace(
                    '[0]',
                    user.verifyEmail.email
                  )}
                </AlertContent>
                <ResendEmail
                  onClick={isResend || isUndo ? null : this.resendEmailClick}
                >
                  {t(isResend ? 'resendingEmail' : 'resendEmail')}
                </ResendEmail>
                <ResendEmail
                  onClick={
                    isResend || isUndo ? null : this.undoChangeEmailClick
                  }
                >
                  {t(isUndo ? 'undoEmailChangeInprogress' : 'undoEmailChange')}
                </ResendEmail>
              </AlertContainer>
            </Row>
          ) : null}
          <Row>
            <Input
              data-lpignore="true"
              errors={errors.firstName}
              id="firstName"
              label={t('fields.firstName.label')}
              mr="15px"
              touched={touched.firstName || isFormTouched}
              value={values.firstName}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <Input
              data-lpignore="true"
              errors={errors.lastName}
              label={t('fields.lastName.label')}
              name="lastName"
              touched={touched.lastName || isFormTouched}
              value={values.lastName || ''}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Row>
          <Row>
            {user.authProvider === 'cognito' ? (
              <Input
                data-lpignore="true"
                errors={errors.email}
                label={t('fields.email.label')}
                name="email"
                touched={touched.email || isFormTouched}
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            ) : (
              <Input
                data-lpignore="true"
                label={t('fields.email.label')}
                disabled="true"
                value={values.email}
                onBlur={handleBlur}
              />
            )}
            <DatePickerWrapper>
              <DateLabel>{t('fields.birthdate.label')}</DateLabel>
              <DatePicker
                data-lpignore="true"
                errors={errors.birthdate}
                handleChange={date => setFieldValue('birthdate', date)}
                maxDate={this.state.maxDate}
                minDate={this.state.minDate}
                mr="15px"
                name="birthdate"
                showMonthDropdown
                showYearDropdown
                touched={touched.birthdate || isFormTouched}
                value={values.birthdate || null}
                onBlur={handleBlur}
              />
              <DateBottomLabel>
                {t('fields.birthdate.bottomLabel')}
              </DateBottomLabel>
            </DatePickerWrapper>
          </Row>
          <Row>
            <PhoneWrapper>
              <DivRelative>
                <CountryCodeLabel>
                  {t('fields.countryCode.label')}
                </CountryCodeLabel>
                <PhoneListMobile
                  code={values.code}
                  handleBlur={handleBlur}
                  isProfile
                  setFieldValue={setFieldValue}
                  value={values.code}
                  handleChange={handleFocusPhonenumber}
                />
              </DivRelative>
              <Input
                data-lpignore="true"
                errors={
                  errors.phone ||
                  (!isValid &&
                    this.state.isFocusPhonenumber &&
                    t('sign:WrongPhoneNumberFormat')) ||
                  ''
                }
                label={t('fields.phoneNumber.label')}
                marginLeft={100}
                name="phone"
                phone
                touched={
                  !user.phone_number_verified || touched.phone || isFormTouched
                }
                value={values.phone}
                onBlur={handleBlur}
                onChange={handleChange}
                onErrorClick={() =>
                  !errors.phone &&
                  isValid &&
                  this.handleOpenAttributeVerifyModal('phone_number')
                }
                onFocus={handleFocusPhonenumber}
              />
            </PhoneWrapper>
          </Row>
          <Row>
            <LocationAutocomplete
              InputComponent={Input}
              inputProps={{
                errors: errors.address,
                label: t('fields.address.label'),
                mr: '15px',
                name: 'address',
                width: '100%',
                touched: touched.address || isFormTouched,
                onBlur: this.handleCheckGeocode
              }}
              ref={ref => (this.locationAutocomplete = ref)}
              value={values.address}
              onChange={text => setFieldValue('address', text)}
              onSelect={data => this.handleAddressChanged(data, setFieldValue)}
            />
            <Input
              autoComplete="none"
              data-lpignore="true"
              errors={errors.city}
              label={t('fields.city.label')}
              name="city"
              touched={touched.city || isFormTouched}
              value={values.city}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Row>
          <Row mt={10}>
            <InputShort
              autoComplete="none"
              data-lpignore="true"
              errors={errors.state}
              label={t('fields.state.label')}
              mr="15px"
              name="state"
              touched={touched.state || isFormTouched}
              value={values.state}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <InputShort
              autoComplete="none"
              data-lpignore="true"
              errors={errors.zip}
              label={t('fields.zipCode.label')}
              mr="15px"
              name="zip"
              touched={touched.zip || isFormTouched}
              value={values.zip}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <InputShort
              autoComplete="none"
              data-lpignore="true"
              errors={errors.country}
              label={t('fields.country.label')}
              mr="15px"
              name="country"
              touched={touched.country || isFormTouched}
              value={values.country}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <InputShort
              data-lpignore="true"
              errors={errors.defaultRadius}
              label={t('DefaultRadius')}
              name="defaultRadius"
              touched={touched.defaultRadius || isFormTouched}
              type="number"
              value={values.defaultRadius}
              onBlur={handleBlur}
              // onChange={handleChange}
              onChange={e =>
                this.handleChangeDefaultRadius(
                  e,
                  handleChange,
                  values.defaultRadius
                )
              }
            />
          </Row>
          <Row mt={10}>
            <TextBlock
              text={t('paymentsTransactions')}
              isTop
              to="/payments-transactions"
            />
          </Row>
          <Row>
            <Map
              google={this.props.google}
              location={this.state.location}
              radius={values.defaultRadius}
              onHandleClick={(t, map, coord) =>
                this.handleMapClick(t, map, coord, setFieldValue)
              }
            />
          </Row>
          <Row>
            <InputWrapper>
              <Label htmlFor="terms">
                <ChekboxInput
                  id="terms"
                  type="checkbox"
                  value={check}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onClick={() => this.handleOnCheckBoxClick()}
                />
                <CheckBoxSpan toggle={check} />
                <CheckBoxText>
                  {t('Agree')}{' '}
                  <ForgetLink target="_blank" to={termsCond}>
                    {t('TermsConditions')}
                  </ForgetLink>
                </CheckBoxText>
              </Label>
              {!check && (touched.terms || isFormTouched) && (
                <TermsError>{t('errorTerms')}</TermsError>
              )}
            </InputWrapper>
          </Row>
          {this.renderIdVerification(props)}
        </Frame>
        <Frame>
          <Row noBreak noWrap>
            <ProviderHeader>{t('fields.isProvider.label')}</ProviderHeader>
            <Switch
              checked={values.isProvider || false}
              checkedIcon={null}
              name="isProvider"
              uncheckedIcon={null}
              onChange={checked => setFieldValue('isProvider', checked)}
            />
          </Row>
        </Frame>
        <ProviderInfo hidden={!values.isProvider}>
          <Section>
            <SectionHeaderContainer>
              <SectionHeader>{t('fields.services.label')}</SectionHeader>
            </SectionHeaderContainer>
            {this.state.services.length !== 0 && (
              <Services
                handleGetStatus={this.getStatus}
                handleStatusChange={this.statusChange}
                services={services && services.services}
                stateServices={this.state.services}
                values={values}
                onDropdownDelete={this.handleDeleteService}
                onDropdownEdit={this.handleEditService}
              />
            )}
            <AddServicesButton
              type="button"
              onClick={this.handleToggleAddServicesModalShown}
            >
              {t('fields.services.addButtonLabel')}
            </AddServicesButton>
          </Section>
          <Section>
            <SectionHeaderContainer>
              <SectionHeader>{t('fields.aboutYourself.label')}</SectionHeader>
              <TextArea
                name="aboutYourself"
                value={values.aboutYourself || ''}
                onChange={handleChange}
              />
            </SectionHeaderContainer>
          </Section>
          <Wrap>
            <ProviderHeader>{t('fields.AreYouPro.label')}</ProviderHeader>
            <Switch
              checked={values.isPro || false}
              checkedIcon={null}
              name="isPro"
              uncheckedIcon={null}
              onChange={checked => setFieldValue('isPro', checked)}
            />
          </Wrap>
          <ProSublabel>
            {values.isPro && t('fields.AreYouPro.sublabel')}
          </ProSublabel>
          <Section>
            <SectionHeaderContainer>
              <SectionHeader>{t('fields.certificates.label')}</SectionHeader>
              <ImageUpload
                setFieldValue={setFieldValue}
                type="certificate"
                userRole={userRole}
                username={user.username}
                value={_.get(values, 'certificate.base64Array', [])}
                onHandleImgListChanged={this.handleImgListChanged}
                onHandleRemoveImage={this.handleRemoveImage}
              />
            </SectionHeaderContainer>
          </Section>
          <Section>
            <SectionHeaderContainer>
              <SectionHeader>{t('fields.picsOfWork.label')}</SectionHeader>
              <ImageUpload
                setFieldValue={setFieldValue}
                type="picsOfWork"
                userRole={userRole}
                username={user.username}
                value={_.get(values, 'picsOfWork.base64Array', [])}
                onHandleImgListChanged={this.handleImgListChanged}
                onHandleRemoveImage={this.handleRemoveImage}
              />
            </SectionHeaderContainer>
          </Section>
          <Section>
            <SectionHeaderContainer>
              <SectionHeader>{t('fields.videoLinks.label')}</SectionHeader>
              <LinksUploader
                maxItemsCount={profileLimits.VIDEO_LINKS_MAX_COUNT[userRole]}
                type="videoLinks"
                values={values.videoLinks}
                onAddBtnClick={this.handleOpenAddLinkModal}
                onDeleteClick={(index, type) =>
                  this.handleDeleteLink(index, type, values, setFieldValue)
                }
                onMaxItemsOverflow={this.handleMaxItemsUploaded}
              />
            </SectionHeaderContainer>
          </Section>
          <Section>
            <SectionHeaderContainer>
              <SectionHeader>{t('fields.websiteLinks.label')}</SectionHeader>
              <LinksUploader
                maxItemsCount={profileLimits.WEBSITE_LINKS_MAX_COUNT[userRole]}
                setFieldValue={setFieldValue}
                type="websiteLinks"
                values={values.websiteLinks}
                onAddBtnClick={this.handleOpenAddLinkModal}
                onDeleteClick={(index, type) =>
                  this.handleDeleteLink(index, type, values, setFieldValue)
                }
                onMaxItemsOverflow={this.handleMaxItemsUploaded}
              />
            </SectionHeaderContainer>
          </Section>
        </ProviderInfo>
        <AddLinkModal
          isOpen={!!addLinkType}
          onCancel={this.handleCloseAddLinkModal}
          onRequestClose={this.handleCloseAddLinkModal}
          onSubmit={({ Link }) =>
            this.handleAddLink(Link, addLinkType, values, setFieldValue)
          }
        />
        <Footer>
          {isRequest ? (
            <SaveButton disabled>
              <img alt="" src={icons.threeDotsLoader} />
            </SaveButton>
          ) : (
            <SaveButton
              disabled={
                (isFormTouched && Object.keys(errors).length !== 0) || !isValid
              }
              type="submit"
            >
              {t('saveBtnLabel')}
            </SaveButton>
          )}
        </Footer>
      </Form>
    )
  }

  handleChangeVerificationType = type => () =>
    this.setState({ verification_type: type })

  setIdVerifySize = size => this.setState({ idVerifySize: size })
  setFaceVerifySize = size => this.setState({ faceVerify: size })

  renderIdVerification = props => {
    const { values, setFieldValue } = props
    const { t, user = {} } = this.props
    const { idVerifyBase64, faceVerifyBase64 } = this.state

    const _setFieldValue = base64 => setFieldValue('idPic', base64)
    const _setFaceValue = base64 => setFieldValue('faceVerify', base64)

    return (
      <Section>
        <SectionHeaderContainer>
          <TitleWrap>
            <SectionHeader>{t('fields.picsID.label')}</SectionHeader>
            <SectionSubheader>{t('fields.picsID.sublabel')}</SectionSubheader>
          </TitleWrap>
          <ImageWrapper>
            {user?.id_verification !== idVerificationStatus.success &&
              (
                <UploadImage
                  {...this.props}
                  value={values?.faceVerify}
                  onChange={_setFaceValue}
                  type="faceVerify"
                  userId={user._id}
                  idVerification={user?.id_verification}
                  idVerificationDeclinedCode={
                    user?.id_verification_declined_code
                  }
                  base64={faceVerifyBase64}
                  setBase64={this.handleEditFaceVerify}
                  setSize={this.setFaceVerifySize}
                />
              )}
            <UploadImage
              {...this.props}
              value={values?.idPic}
              onChange={_setFieldValue}
              type="idPic"
              userId={user._id}
              idVerification={user?.id_verification}
              idVerificationDeclinedCode={user?.id_verification_declined_code}
              base64={idVerifyBase64}
              setBase64={this.handleEditIdVerify}
              setSize={this.setIdVerifySize}
              isCenter
            />
          </ImageWrapper>
          {user?.id_verification === idVerificationStatus.failed && (
            <VerificationError>{t('verifyError')}</VerificationError>
          )}
          {this.renderVerifyButton()}
        </SectionHeaderContainer>
      </Section>
    )
  }

  handleEditIdVerify = data => this.setState({ idVerifyBase64: data })

  handleEditFaceVerify = data => this.setState({ faceVerifyBase64: data })

  handleImgSizeOverflowException = () => {
    const { t } = this.props

    toast.error(t('sizeOverflowError', { maxImgSize: MAX_IMG_SIZE_STR }))
  }

  handleStartVerify = async () => {
    const { onVerification, user, t } = this.props
    const {
      idVerifyBase64,
      faceVerifyBase64,
      idVerifySize,
      faceVerifySize
    } = this.state

    this.setState({ isVerifying: true })

    if (
      (idVerifyBase64 && !faceVerifyBase64) ||
      (!idVerifyBase64 && faceVerifyBase64)
    ) {
      this.setState({ isVerifying: false })
      return toast.error(t('profilePage:faceVerificationError'))
    }

    if (idVerifySize > 16777216 || faceVerifySize > 16777216) {
      this.setState({ isVerifying: false })
      return toast.error(t('profilePage:faceVerificationError'))
    }

    try {
      // upload verification pic to S3
      if (idVerifyBase64 && faceVerifyBase64) {
        const verificationImages = await ProfileImageManager.updateProfileImages(
          profileImageTypes.idPics,
          [idVerifyBase64, faceVerifyBase64],
          user.username
        )
        if (verificationImages && verificationImages.length === 2) {
          const body = {
            documentImage: verificationImages[0],
            faceImage: verificationImages[1]
          }

          onVerification(body, (err, response) => {
            this.setState({
              isVerifying: false,
              idVerifyBase64: null,
              faceVerifyBase64: null
            })
            if (err) {
              return toast.error(t('profilePage:verifyError'))
            }
            if (response?.status === 'success') {
              return toast.success(t('profilePage:verifySucceed'))
            }
            toast.error(t('profilePage:verifyError'))
          })
        }
      }
    } catch (error) {
      this.setState({
        isVerifying: false,
        idVerifyBase64: null,
        faceVerifyBase64: null
      })
      toast.error(error?.message)
    }
  }

  renderVerifyButton = () => {
    const { user, t } = this.props
    const { idVerifyBase64, faceVerifyBase64, isVerifying } = this.state

    if (
      [idVerificationStatus.success, idVerificationStatus.pending].includes(
        user?.id_verification
      )
    ) {
      return null
    }

    return isVerifying ? (
      <VerifyButton disabled>
        <img alt="" src={icons.threeDotsLoader} />
      </VerifyButton>
    ) : (
      <VerifyButton
        disabled={!idVerifyBase64 || !faceVerifyBase64}
        onClick={this.handleStartVerify}
      >
        {t('startVerification')}
      </VerifyButton>
    )
  }

  render() {
    const { user, t } = this.props
    const {
      userRole,
      validationSchema,
      attributeToVerify,
      isFirstFetch
    } = this.state
    let initialValues = {}
    const phones = Object.values(PHONES)
    const phone =
      user?.phone_number_format?.slice(user?.country_code?.length || 2) || ''
    const code = user.country_code || '+1'
    if (user) {
      initialValues = {
        avatarB64: user.avatarURL || null,
        firstName: user.given_name || '',
        lastName: user.family_name || '',
        birthdate: user.birthdate ? new Date(user.birthdate) : '',
        address: user.address || '',
        city: user.city || '',
        state: user.state || '',
        zip: user.zip || '',
        country: user.country || '',
        isProvider: user.isProvider || false,
        isPro: user.isPro || false,
        aboutYourself: user.about || '',
        defaultRadius: user.defaultRadius || DEFAULT_RADIUS,
        services: user.services || [],
        websiteLinks: user.websiteLinks || [],
        videoLinks: user.videoLinks || [],
        certificate: { base64Array: user.certificates || [] },
        idPic: null,
        picsOfWork: { base64Array: user.picsOfWork || [] },
        email: user.email || '',
        phone: phone || '',
        code: code || phones[0].phone,
        local: user.local,
        faceVerify: null
      }
    }

    return (
      <ProfileBackground>
        <Link to={root}>
          <Logo>
            SpotJobs<Registered>&reg;</Registered>
          </Logo>
          <SubTitle>{t('signInHeadDescription')}</SubTitle>
        </Link>
        <FormContainer>
          <Fragment>
            <ContentHeaderContainer>
              <ContentHeader>{t('title')}</ContentHeader>
              <Link to="/">
                <ContnentHeaderBackButton src={arrows.arrowBack} />
              </Link>
            </ContentHeaderContainer>
            {isFirstFetch ? (
              <Loading>
                <img alt="" src={icons.preload} />
              </Loading>
            ) : (
              <Formik
                // enableReinitialize
                initialValues={initialValues}
                ref={ref => (this.formik = ref)}
                render={this.renderForm}
                validationSchema={validationSchema}
                onSubmit={this.handleSubmit}
              />
            )}
            <ServicesModal
              editId={this.state.editId}
              isOpen={this.state.isAddServicesModalVisible}
              isProfilePage={true}
              maxItemsCount={profileLimits.SERVICES_MAX_COUNT[userRole]}
              profileServices={this.state.services}
              onMaxItemsOverflow={this.handleMaxItemsUploaded}
              onRequestClose={this.handleToggleAddServicesModalShown}
              onRequestDone={this.handleServicesModalDone}
            />
            <AttributeVerifyModal
              attribute={attributeToVerify}
              onRequestClose={this.handleCloseAttributeVerifyModal}
              onResendClick={this.handleSendAttribVerificationCode}
              onSubmit={this.handleVerifyAttribute}
            />
          </Fragment>
        </FormContainer>
      </ProfileBackground>
    )
  }
}

ProfilePage.propTypes = {
  user: PropTypes.object,
  onUserVerify: PropTypes.func
}

export default compose(
  withNamespaces('profilePage'),
  GoogleApiWrapper({
    apiKey: gooogleApiKey,
    libraries: ['places']
  })
)(ProfilePage)
