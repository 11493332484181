import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { createReducer } from 'Helpers/redux'

import { SERVICES, POPULAR_SERVICES } from 'Redux/actions/services'

const persistConfig = {
  key: 'services',
  storage,
  blacklist: []
}

const initialState = {
  services: [],
  popularServices: []
}

const handlers = {
  [SERVICES.SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      services: payload
    }
  },
  [POPULAR_SERVICES.SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      popularServices: payload
    }
  }
}

export default persistReducer(
  persistConfig,
  createReducer(initialState, handlers)
)
