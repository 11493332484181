import React from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'
import {
  ContainersWrapper,
  GridFourElements,
  SubTitle,
  MainTitle,
  WidthLimiter
} from './styles'
import Item from '../Item'

const Advantages = props => {
  const { t, firstRow, secondRow, isCards } = props
  return (
    <ContainersWrapper>
      <WidthLimiter>
        <MainTitle>{t('useSpotjob')}</MainTitle>
        <SubTitle>{t('findSmallJobs')}</SubTitle>
        <GridFourElements>
          {firstRow.map((item, i) => (
            <Item key={i} wrapped {...item} isCards={isCards} />
          ))}
        </GridFourElements>
        <SubTitle>{t('findProfessional')}</SubTitle>
        <GridFourElements>
          {secondRow.map((item, i) => (
            <Item
              isBigger={i === 0}
              key={i}
              wrapped
              {...item}
              isCards={isCards}
            />
          ))}
        </GridFourElements>
      </WidthLimiter>
    </ContainersWrapper>
  )
}

Advantages.propTypes = {
  firstRow: PropTypes.array,
  isCards: PropTypes.bool,
  secondRow: PropTypes.array,
  t: PropTypes.func
}

export default withNamespaces('landingPage')(Advantages)
