import styled from 'styled-components'
import { fontFamily, fontSize } from 'Constants'
import { Button } from 'Components/ui'

export const Container = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px 40px;
`

export const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
`

export const Title = styled.h1`
  font-size: ${p => p.theme.fontSize.h4};
  color: #000;
  font-weight: bold;
`

export const DatePickerWrapper = styled.div`
  flex-direction: column;
  margin-bottom: 10px;
  align-self: flex-start;
  width: 100%;
  & > .react-datepicker-wrapper {
    width: 100%;
  }
`

export const BudgetWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 45px;
`

export const Input = styled.input`
  width: 100%;
  height: 100%;
  padding: 10px 15px;
  font-size: ${fontSize.h3};
  font-family: ${fontFamily};
  border: 2px solid #eee;
  border-radius: 5px;
  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
`

export const CurrencyContainer = styled.div`
  width: 90px;
  border-radius: 5px;
  border: 2px solid #eee;
  background: #fff;
  margin-left: 5px;
  height: 100%;
`

export const Label = styled.label`
  display: block;
  font-size: ${fontSize.default};
  font-family: ${fontFamily};
  padding-bottom: 5px;
  align: left;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const ModalButton = styled(Button)`
  transition: box-shadow 0.3s;
  ${p => p.disabled && 'pointer-events: none; opacity: 0.6;'};
  &:hover {
    box-shadow: 0 0 10px silver;
  }
`

export const SubmitButton = styled(ModalButton)`
  border-radius: 0 0 10px 0;
`

export const CancelButton = styled(ModalButton)`
  background: ${p => p.theme.colors.grayButton};
  border-radius: 0 0 0 10px;

  &:hover {
    background: ${p => p.theme.colors.grayButton};
  }
`

export const Row = styled.div`
  width: 100%;
  margin-top: ${({ top }) => top ? top : 0}px;
  height: 45px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: ${props => (props.noWrap ? 'nowrap' : 'wrap')};
`

export const styles = {
  budgetConvert: {
    width: 'calc(100% - 80px)',
    pointerEvents: 'none'
  },
  currency: {
    width: 75,
    marginLeft: 5,
    fontSize: 18,
    textAlign: 'center',
    pointerEvents: 'none'
  }
}

export const Text = styled.span`
  font-size: 18px;
  font-weight: 300;
`

export const Description = styled.p`
  text-align: center;
  max-width: 550px;
  padding: 0 20px 30px;
  margin-top: -25px;
`