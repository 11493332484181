import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
  withShare,
  withSendHiringRequest,
  withFavouriteSP
} from 'Components/hoc'
import { withNamespaces } from 'react-i18next'

import { popularServices } from 'Redux/actions/services'
import { serviceProvidersList, removeSP } from 'Redux/actions/serviceProviders'
import { loadUserReviews } from 'Redux/actions/review'

import { getSpecificSP } from 'Redux/selectors/serviceProvider'
import { getServiceProvidersList } from 'Redux/selectors/serviceProvidersList'
import { getProfile } from 'Redux/selectors/user'
import { getServices } from 'Redux/selectors/services'

import Component from './FindHelp'

const selector = createStructuredSelector({
  user: getProfile,
  providers: getServiceProvidersList,
  services: getServices,
  profile: getSpecificSP
})

const actions = {
  onGetProvidersList: serviceProvidersList,
  onGetPopularServices: popularServices,
  onRemove: removeSP,
  onLoadUserReviews: loadUserReviews
}

export default compose(
  withFavouriteSP,
  withNamespaces(['findJobPage', 'homePage']),
  withShare,
  withSendHiringRequest,
  connect(
    selector,
    actions
  )
)(Component)
