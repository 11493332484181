import React from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'
import { signIn } from 'Routes/routesConstants'
import { ButtonsWrapper, JoinButton, VideoButton } from './styles'

const JumbotronButtonBlock = ({ t, onShowVideo }) => (
  <ButtonsWrapper>
    <JoinButton to={signIn}>{t('joinFree')}</JoinButton>
    <VideoButton onClick={onShowVideo}>{t('watchVideo')}</VideoButton>
  </ButtonsWrapper>
)

JumbotronButtonBlock.propTypes = {
  t: PropTypes.func,
  onShowVideo: PropTypes.func
}

export default withNamespaces('landingPage')(JumbotronButtonBlock)
