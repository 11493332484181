import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  height: fit-content;
`

export const VerifiedContainer = styled.div`
  position: absolute;
  bottom: 5px;
  left: -5px;
`

export const AvatarContainer = styled.div`
  & > img {
    width: ${p => (p.isBig ? '115px' : '55px')};
    height: ${p => (p.isBig ? '115px' : '55px')};
    border-radius: 50%;
    box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.2);
    object-fit: cover;
  }
`

export const CameraWrapper = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  display: flex;
  justify-content: space-around;
  align-items: space-around;
  width: 30px;
  height: 30px;
  background: ${props => props.theme.colors.defaultBlue};
  border-radius: 50%;
`
