import React from 'react'
import PropTypes from 'prop-types'
import {
  BurgerMenu,
  Header,
  Footer,
  BreadCrumbs,
  Ad,
  Chat
} from 'Components/blocks'
import { ContentWrapper, PageWrapper } from 'Components/ui'

import { getBC } from './memoize'
import { Container, AdWrapper, Content, Title, ChatWrapper } from './style'

const Chats = ({ user, t }) => (
  <PageWrapper>
    {user && <BurgerMenu user={user} />}
    <Header postJob={false} view={Object.keys(user).length === 0} />
    <ContentWrapper>
      <BreadCrumbs data={getBC(t)} />
      <Container>
        <AdWrapper>
          <Ad />
        </AdWrapper>
        <Content>
          <Title>{t('homePage:chats')}</Title>
          <ChatWrapper>
            <Chat />
          </ChatWrapper>
        </Content>
        <AdWrapper>
          <Ad />
        </AdWrapper>
      </Container>
    </ContentWrapper>
    <Footer black={true} isAuth={user} />
  </PageWrapper>
)

Chats.propTypes = {
  t: PropTypes.func,
  user: PropTypes.object
}

export default Chats
