export const privilege = [
  'fullAccess',
  'provideUnlimitedServices',
  'uploadPicturesAndVideo',
  'trackingAvailableAppointmentDate'
]

export function checkProps(prevProps, nextProps) {
  return (
    prevProps.lng === nextProps.lng &&
    prevProps.isVisible === nextProps.isVisible
  )
}
