import React, { PureComponent } from 'react'
import { withNamespaces } from 'react-i18next'
import { Formik } from 'formik'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import axios from 'axios'

import { root } from 'Routes/routesConstants'
import { icons } from 'Assets/icons'
import {
  Background,
  Registered,
  Logo,
  SubTitle,
  Form,
  Label,
  AuthInput,
  Error,
  InputWrapper,
  BtnSubmit
} from 'Components/ui'

import { api } from 'Configs/App'
import { getValidationSchema } from './config'
import { StyledAuthFormWrapper, FormTitle, FormTitleSub, BtnSubmitWrapper, Loading } from './styles'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Verify extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      token: null,
      isRequest: false,
      showPassword: false,
      isValid: false,
      isLoading: true
    }
  }

  componentDidMount() {
    const { location } = this.props
    const query = location.search.replace(/^\?/, '').split('&')
    const params = query.reduce((obj, q) => {
      const [k, v] = q.split('=')
      obj[k] = decodeURI(v)
      return obj
    }, {})
    this.setState({ ...params })
    const headers = { Authorization: `Bearer ${params.token}` }
    axios
      .post(api.defaultUrl + '/user/verify-email/check', { email: params.email }, { headers })
      .then(({ data: dataRes }) => {
        if (dataRes.code === "Check_Verify_Success") {
          this.setState({ isValid: true, isLoading: false })
        }
      }).catch(err => {
        this.setState({ isLoading: false })
      })
  }

  handleSubmit = (data) => {
    const { t, history } = this.props
    const { password } = data
    this.setState({ isRequest: true })

    const headers = { Authorization: `Bearer ${this.state.token}` }

    axios
      .put(api.defaultUrl + '/user/verify-email/update-email', { password }, { headers })
      .then(({ data: dataRes }) => {
        if (dataRes.code === "Update_Email_Success") {
          window.location.href = 'app://com.spotjobs.mobile/profile'

          setTimeout(() => {
            toast.success(t('updateEmailSuccessfully'))
            history.push('profile')
          }, 3000)
        }
      })
      .catch(err => {
        const { response } = { ...err }
        let messageKey = 'passwordIncorrect'
        let showGoProfile = false
        if (response?.data?.code === 'Unauthorized' || response?.data?.code === 'Verify_Expired') {
          messageKey = 'sessionExpired'
          showGoProfile = true
        }
        if (response?.data?.code === 'Verify_NotFound') {
          messageKey = 'verifiedEmail'
          showGoProfile = true
        }
        if (showGoProfile) {
          setTimeout(() => {
            history.push('/profile')
          }, 3000)
        }
        toast.error(t(messageKey))
      })
      .finally(() => {
        this.setState({ isRequest: false })
      })
  }

  handleToggleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  render() {
    const { t } = this.props
    const { showPassword, isValid, isLoading } = this.state
    if (isLoading) {
      return (
        <Background>
          <Loading>
            <img alt="" src={icons.preload} />
          </Loading>
        </Background>
      )
    }
    if (isValid) {
      return (
        <Background>
          <Link to={root}>
            <Logo>
              SpotJobs<Registered>&reg;</Registered>
            </Logo>
            <SubTitle>{t('subTitle')}</SubTitle>
          </Link>
          <Formik
            validationSchema={getValidationSchema(t)}
            onSubmit={this.handleSubmit}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                submitCount
              } = props

              const isFormTouched = submitCount > 0
              return (
                <Form onSubmit={handleSubmit}>
                  <FormTitle>{t('confirmUpdateEmail')}</FormTitle>
                  <FormTitleSub>{this.state.email}</FormTitleSub>
                  <StyledAuthFormWrapper>
                    <InputWrapper>
                      <Label htmlFor="password">{t('Password')}</Label>
                      <AuthInput
                        id="password"
                        placeholder={t('Password')}
                        type={showPassword ? 'text' : 'password'}
                        value={values.password || ''}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <FontAwesomeIcon
                        icon={this.state.showPassword ? faEye : faEyeSlash}
                        onClick={this.handleToggleShowPassword}
                      />
                      {errors.password && (touched.password || isFormTouched) && (
                        <Error>{errors.password}</Error>
                      )}
                    </InputWrapper>
                    <BtnSubmitWrapper>
                      {this.state.isRequest ? (
                        <BtnSubmit disabled>
                          <img alt="" src={icons.threeDotsLoader} />
                        </BtnSubmit>
                      ) : (
                          <BtnSubmit
                            isDisabled={Object.keys(errors).length !== 0}
                            type="submit"
                          >
                            {t('confirm')}
                          </BtnSubmit>
                        )}
                    </BtnSubmitWrapper>
                  </StyledAuthFormWrapper>
                </Form>
              )
            }}
          </Formik>
        </Background>
      )
    }
    return (
      <Background>
        <h3>{t('verifyEmailExpired')}</h3>
      </Background>
    )
  }
}

export default withNamespaces('sign')(Verify)
