import React, { Fragment } from 'react'
// import { root } from 'Routes/routesConstants'
import { Button } from 'Components/ui'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Scroll from 'react-scroll'
import _ from 'lodash'

import {
  Container,
  Wrapper,
  Article,
  InnerWrap,
  FooterModal,
  FloatingButtonBlock,
  FloatingButton,
  CustomLink
} from './styles'

class termsCond extends React.Component {
  state = {
    query: null
  }

  componentDidMount() {
    const { location, history } = this.props

    const queryString = location.search.substr(1)
    if (queryString.startsWith('redirect_uri=')) {
      const query = decodeURIComponent(
        queryString.substr('redirect_uri='.length)
      )
      this.setState({
        query: query
      })
      this.props.history.replace({
        ...history.location,
        search: ''
      })
    }
  }

  handleClick = () => {
    const { history } = this.props
    const { query } = this.state
    if (_.isString(query)) {
      return (window.location.href = query)
    } else {
      localStorage.setItem('verify', 'true')
      window.close()
    }
    history.goBack()
  }

  handleMoveToBottom = () => {
    Scroll.animateScroll.scrollToBottom()
  }

  _renderFloatingButton = () => {
    return (
      <FloatingButtonBlock>
        <FloatingButton onClick={this.handleMoveToBottom}>
          <FontAwesomeIcon icon={faChevronDown} />
        </FloatingButton>
      </FloatingButtonBlock>
    )
  }

  render() {
    return (
      <Fragment>
        <Container>
          <Wrapper>
            <Article>
              <InnerWrap>
                <h2>Terms & Conditions</h2>
                <p>Welcome to SpotJobs!</p>
                <p>
                  SPOTJOBS helps users research jobs, setup profiles, post jobs
                  (jobs also referred to as “Service Contract”), hire, rate,
                  track, and review a variety of Service providers and Job
                  Providers (collectively, “Service Providers” and “User(s)”).
                  The following Terms of Use outline your obligations when using
                  the SPOTJOBS websites, mobile applications, and services.
                  SPOTJOBS DBA SPOTGIGS. SPOTJOBS and SPOTGIGS are one and the
                  same application. Therefore, Terms & Conditions mentioning
                  SPOTJOBS applies automatically to SPOTGIGS even if SPOTGIGS is
                  not mentioned.
                </p>
                <b>ACCEPTANCE OF TERMS</b>
                <p>
                  SPOTJOBS’s Internet site is available at spotjobsapp.com. The
                  SPOTJOBS mobile application, all related sites and mobile
                  applications, and the various content, features, and services
                  offered on and in connection with these sites and
                  applications, including the magazine (collectively, the “Sites
                  and Services”) are owned and operated by ATOMX LLC and
                  subsidiaries and can only be accessed and used by you under
                  the Terms of Use described below (“Terms of Use”). PLEASE READ
                  THESE TERMS OF USE CAREFULLY. BY ACCESSING, DOWNLOADING, OR
                  USING ANY OF THE SITES AND SERVICES, YOU AGREE TO BECOME BOUND
                  BY THESE TERMS AND CONDITIONS. IF YOU DO NOT AGREE TO ALL THE
                  TERMS AND CONDITIONS, THEN YOU MAY NOT ACCESS AND USE THE
                  SITES AND SERVICES.
                </p>
                <b>MODIFICATIONS OF TERMS OF USE</b>
                <p>
                  SPOTJOBS may, in its sole discretion, modify these Terms of
                  Use at any time effective upon posting the modified Terms of
                  Use on and in connection with the Sites and Services, with or
                  without additional notice to you. You are responsible for
                  regularly reviewing information posted on the Sites and
                  Services to obtain timely notice of such changes. If you do
                  not agree to the amended terms, you agree to immediately stop
                  using the Sites and Services and to provide SPOTJOBS notice to
                  remove you from any distribution lists or other communication
                  list that are available to you through your use of the
                  SPOTJOBS Sites and Services. YOUR CONTINUED USE OF THE SITE
                  AND SERVICES AFTER SUCH POSTING (OR OTHER NOTIFICATION, IF
                  ANY) MEANS YOU ACCEPT AND AGREE TO BE BOUND BY THE MODIFIED
                  TERMS OF USE. USE OF THE SITE AND SERVICES Subject to full
                  compliance with these Terms of Use, SPOTJOBS grants authorized
                  users a nonexclusive, nontransferable, nonsublicensable,
                  terminable license to access and use the Sites and Services
                  for your personal use. You agree to not access, reproduce,
                  duplicate, copy, sell, resell, modify, distribute, transmit,
                  or otherwise exploit the Sites or Services or any of their
                  content for any purpose except for your personal use and as
                  described in these Terms of Use, without the express written
                  consent of ATOMX LLC. ATOMX LLC may modify, update, suspend or
                  discontinue the SPOTJOBS Sites and Services, in whole or in
                  part, at our sole discretion for any or no reason, at any time
                  and with or without notice. SPOTJOBS shall not be liable to
                  any user or other third party for any such modification,
                  update, suspension or discontinuance.
                </p>
                <b>USER CONDUCT</b>
                <p>
                  As a condition of your access and use of the SPOTJOBS Sites
                  and Services and your submission or access to any ratings,
                  reviews, communications, information, data, text, photographs,
                  audio clips, audiovisual works, or other materials on the
                  Sites and Services (collectively, the “Content”), you agree
                  not to use the Sites and Services for any purpose that is
                  unlawful or prohibited by these Terms of Use, or any other
                  purpose not reasonably intended by SPOTJOBS. By way of
                  example, and not as a limitation, you agree not to:
                  <br />
                  violate these Terms of Use, other applicable agreement with
                  SPOTJOBS, and any applicable local, state, national or
                  international law, and any rules and regulations having the
                  force of law; use the Sites and Services in any manner that
                  violates any relevant law or that infringes, misappropriates
                  or violates any third party’s rights, including, but not
                  limited to, transmitting any Content that may infringe,
                  misappropriate or violate a third party’s rights of publicity,
                  contractual rights, fiduciary rights or intellectual property
                  rights;
                  <br />
                  use the Sites and Services or its Content for any purposes not
                  authorized by these Terms of Use, including commercial,
                  political, or religious purposes, including the submission or
                  transmission of any Content that contains advertisements,
                  promotional materials, junk mail, or any other form of
                  solicitation;
                  <br />
                  reproduce, duplicate, copy, modify, sell, re-sell or exploit
                  any Content or the Sites and Services for any commercial,
                  educational, or any other non-personal purpose or any for any
                  purpose unrelated to your personal purchasing decisions,
                  without the express written consent of ATOMX LLC, which
                  consent may be withheld by ATOMX LLC and subsidiaries in our
                  sole discretion;
                  <br />
                  post irrelevant Content, repeatedly post the same or similar
                  Content or otherwise impose an unreasonable or
                  disproportionately large load on our infrastructure, interfere
                  or attempt to interfere with the proper working of the Sites
                  and Services or any activities conducted on the Sites and
                  Services; harass, threaten, intimidate, impersonate, or
                  attempt to impersonate, any other person, falsify your contact
                  or other information, misrepresent a relationship with any
                  person or entity, including misrepresenting a relationship
                  with ATOMX LLC and subsidiaries, or otherwise attempt to
                  mislead others as to the identity of the sender or the origin
                  of a review or rating;
                  <br />
                  knowingly provide or submit false or misleading information;
                  <br />
                  use the Sites and Services if you are under the age of
                  eighteen (18);
                  <br />
                  take any action that would undermine the review and rating
                  process under the Sites and Services;
                  <br />
                  attempt to gain unauthorized access to the Sites and Services,
                  other user accounts, or other computer systems or networks
                  connected to the Sites and Services;
                  <br />
                  use the Sites and Services in any way that could interfere
                  with the rights of ATOMX LLC and subsidiaries or the rights of
                  other users of the Sites and Services;
                  <br />
                  transmit or submit any transmission or other materials that
                  are encrypted or that contains viruses, Trojan horses, worms,
                  time bombs, spiders, cancelbots or other computer programming
                  routines that is likely or intended to damage, interfere with,
                  disrupt, impair, disable or otherwise overburden the Sites and
                  Services;
                  <br />
                  access, download, monitor, or copy any information contained
                  on our Sites and Services through artificial means (including
                  but not limited to use any ‘deep-link’, ‘scraper’, ‘robot’,
                  ‘spider’ or other automatic device, program, algorithm or
                  methodology, or any similar or equivalent automatic or manual
                  process, or in any way reproduce or circumvent the
                  navigational structure or presentation of the Sites and
                  Services or any content, to obtain or attempt to obtain any
                  Content, materials, documents or information through any means
                  not purposely made available through the Sites and Services;
                  or probe, scan or test the vulnerability of the Sites and
                  Services or any network connected to the Sites and Services,
                  nor breach the security or authentication measures on or of
                  the Sites and Services or any network connected to the Sites
                  and Services. You may not reverse look-up, trace or seek to
                  trace any information on any other user of the Sites and
                  Services, or any other customer of SPOTJOBS, including any
                  SPOTJOBS account not owned by you, to its source, or exploit
                  the Sites and Services or any service or information made
                  available or offered by or through the Sites and Services, in
                  any way where the purpose is to reveal any information,
                  including but not limited to personal identification or
                  information other than your own information, except as
                  expressly authorized by SPOTJOBS and provided for by the
                  SPOTJOBS Sites and Services.
                </p>
                <b>SPOTJOBS’S SERVICES</b>
                <p>
                  When using, accessing, or purchasing particular services or
                  features of the Sites and Services, you shall be subject to
                  any posted agreements, guidelines, or rules applicable to such
                  services or features that may be posted from time to time. All
                  such agreements, guidelines, or rules are hereby incorporated
                  by reference into the Terms of Use.
                </p>
                <b>REGISTRATION INFORMATION</b>
                <p>
                  We may require that you create an account to use or access
                  certain parts of the Sites and Services and use certain
                  products and features. We may require that you provide login
                  information such as a username, email and password to access
                  and utilize your account. As a condition of your use of the
                  Sites and Service, you agree to (a) provide SPOTJOBS with
                  true, accurate, current and complete information as prompted
                  by the SPOTJOBS’s registration forms, when registering for or
                  using the Sites and Services and (b) update and maintain the
                  truthfulness, accuracy and completeness of such information.
                  You are responsible for maintaining the confidentiality of any
                  password or other account information not generally available
                  to others and are fully responsible for all activities that
                  occur under your username and password. While there are
                  limited, legitimate reasons for creating multiple accounts,
                  creating serial or overlapping accounts may result in account
                  termination.
                  <br />
                  You may cancel your Membership at any time. Member
                  registration fees are non-refundable. Please contact us if you
                  have questions about managing multiple accounts.
                </p>
                <b>USE OF COMMUNICATION SERVICES</b>
                <p>
                  The SPOTJOBS Sites and services may contain chat areas,
                  bulletin board services, news groups, forums, communities,
                  personal web pages, calendars, and/or other message or
                  communication facilities designed to enable you to communicate
                  with Service Providers, Job Providers and/or the public at
                  large or with a group, you agree to use SPOTJOBS only to post,
                  send and receive messages and material that are proper and
                  related to the particular of SPOTJOBS.
                  <br />
                  By way of example, and not as a limitation, you agree that
                  when using SPOTJOBS Sites and services, you will not: defame,
                  abuse, harass, stalk, threaten or otherwise violate the legal
                  rights (such as rights of privacy and publicity) of others;
                  publish, post, upload, distribute or disseminate any
                  inappropriate, profane, defamatory, infringing, obscene,
                  indecent or unlawful topic, name, material or information;
                  upload files that contain software or other material protected
                  by intellectual property laws (or by rights of privacy of
                  publicity) unless you own or control the rights thereto or
                  have received all necessary consents; upload files that
                  contain viruses, corrupted files, or any other similar
                  software or programs that may damage the operation of
                  another’s computer; advertise or offer to sell or buy any
                  goods or services for any business purpose, unless such
                  SPOTJOBS specifically allows such messages; conduct or forward
                  surveys, contests, pyramid schemes or chain letters; download
                  any file posted by another user of SPOTJOBS Sites and services
                  that you know, or reasonably should know, cannot be legally
                  distributed in such manner; falsify or delete any author
                  attributions, legal or other proper notices or proprietary
                  designations or labels of the origin or source of software or
                  other material contained in a file that is uploaded, restrict
                  or inhibit any other user from using and enjoying the SPOTJOBS
                  Sites and services; violate any code of conduct or other
                  guidelines which may be applicable for any particular of
                  SPOTJOBS Sites and services; harvest or otherwise collect
                  information about others, including e-mail addresses, without
                  their consent; violate any applicable laws or regulations.
                  Furthermore, SPOTJOBS reserve the right to delete any data,
                  images, content or otherwise, posted by users on “Sites and
                  Services”, if deemed necessary due to expired subscription or
                  otherwise; in which case ATOMX LLC and subsidiaries cannot be
                  liable for loss of any data or materials posted by users.
                </p>
                <b>SUBMITTING CONTENT</b>
                <p>
                  As a condition of submitting any Content or other materials to
                  the Sites or Services, you agree that: you grant to ATOMX LLC
                  and SPOTJOBS a royalty free, perpetual, irrevocable,
                  worldwide, nonexclusive, transferable, and sublicensable
                  license to use, reproduce, copy, adapt, modify, merge,
                  distribute, publicly display, create derivative works from,
                  incorporate such Content into other works;
                  <br />
                  you grant ATOMX LLC and SPOTJOBS all rights necessary to
                  publish or refrain from publishing your name and address in
                  connection with your Content; sublicense through multiple
                  tiers the Content, and acknowledge that this license cannot be
                  terminated by you once your Content is submitted to the Sites
                  and Services;
                  <br />
                  you grant to ATOMX LLC and SPOTJOBS all rights necessary to
                  prohibit the subsequent aggregation, display, copying,
                  duplication, reproduction, distribution, or exploitation of
                  your Content by any other party;
                  <br />
                  your name and report information may be made available to the
                  public and to the Service Providers on which you report;
                  <br />
                  you represent that you own or have secured all legal rights
                  necessary for the Content submitted by you to be used by you,
                  SPOTJOBS, and others as described and otherwise contemplated
                  in these Terms of Use;
                  <br />
                  you represent and warrant that each person identified,
                  depicted, or shown in in your Content, if any, (and if a
                  minor, the parent or guardian of the minor) has provided
                  consent to the use of the Content consistent with these Terms
                  of Use;
                  <br />
                  you are solely responsible for your reviews and ratings;
                  <br />
                  ATOMX LLC and SPOTJOBS may, in its sole discretion, choose to
                  remove or not to remove reviews and ratings once published;
                  <br />
                  you will not submit any reviews that may be considered by
                  ATOMX LLC and subsidiaries to be infringing, harassing,
                  libelous, abusive, threatening, obscene, profane, hateful,
                  offensive, harmful, vulgar, distasteful, defamatory, or
                  otherwise violates any relevant law or right of any other
                  party, or racially, ethnically or otherwise objectionable;
                  <br />
                  all of your reviews and ratings will either be based upon (i)
                  your actual first-hand experiences with the Service or Job
                  Providers you are reviewing or (ii) as provided below, an
                  individual and that individual’s actual first-hand experience
                  with a health care or wellness provider whereby you have the
                  legal authority to disclose such health information and
                  experience of such individual;
                  <br />
                  all of your reviews and ratings of the Service Providers that
                  you are rating will be accurate, honest, truthful, and
                  complete in all respects;
                  <br />
                  you do not work for, own any interest in or serve on the board
                  of directors of, any of the Service or Job Providers for which
                  you submit reviews and ratings; you are not in any way related
                  (by blood, adoption or marriage, if the Service or Job
                  Provider is an individual) to any of the Service or Job
                  Providers for which you submit reviews or ratings;
                  <br />
                  you have not received any form of compensation to post reviews
                  and ratings;
                  <br />
                  you will submit thorough and thoughtful reviews of the Service
                  or Job Providers you review (for example, submitting a review
                  describing a service contractor as “He/She is great.” Without
                  additional commentary is not a thorough and thoughtful
                  review);
                  <br />
                  you will not submit reviews that comment on other users or the
                  reviews of other users;
                  <br />
                  you will not submit reviews with hyperlinks; or
                  <br />
                  the reviews and ratings that you provide do not reflect the
                  views of ATOMX LLC, its officers, managers, owners, employees,
                  agents, designees or other users.
                </p>
                <b>PUBLICATION AND DISTRIBUTION OF CONTENT</b>
                <p>
                  SPOTJOBS do not guarantee the accuracy, integrity, quality or
                  appropriateness of any Content transmitted to or through the
                  Service. You acknowledge that SPOTJOBS simply acts as a
                  passive conduit and an interactive computer service provider
                  and job providers for the publication and distribution of
                  Content and for the publication and distribution of any
                  content posted by Service Providers and Job providers in
                  response to Content (“Service Provider and Job Provider
                  Content”). You understand that all Content, Service Provider
                  and Job provider Content posted on, transmitted through or
                  linked through the Service, are the sole responsibility of the
                  person from whom such Content originated. You understand that
                  SPOTJOBS, ATOMX LLC and subsidiaries do not control, and are
                  not responsible for Content which any User has made available
                  through the Service, and that by using the Service, You may be
                  exposed to Content that is inaccurate, misleading, or
                  offensive. You agree that You must evaluate and make Your own
                  judgment, and bear all risks associated with, the use of any
                  Content from any User using SPOTJOBS.
                  <br />
                  You further acknowledge that SPOTJOBS, ATOMX LLC and
                  subsidiaries have no obligation to screen, preview, monitor or
                  approve any Content or Users, or Content posted or submitted
                  by any other SPOTJOBS member. However, SPOTJOBS reserves the
                  right to review and delete any Content that, in its sole
                  judgment, violates the terms and conditions of this Agreement.
                  By using the Service, You agree that it is solely YOUR
                  RESPONSIBILITY to evaluate Your risks to bear associated with
                  the use, accuracy, usefulness, completeness or appropriateness
                  of any Content that You submit, receive, access, transmit or
                  otherwise convey through the Service. Under no circumstances
                  will SPOTJOBS, ATOMX LLC and subsidiaries be liable in any way
                  for any Content, including, but not limited to, any Content
                  posted by any User that contains any errors, omissions,
                  defamatory statements, or confidential or private information
                  (including, but not limited to, health information) or for any
                  loss or damage of any kind incurred as a result of the use of
                  users’ Content submitted, accessed, transmitted or otherwise
                  conveyed via the Service. You waive the right to bring or
                  assert any claim against SPOTJOBS, ATOMX LLC and subsidiaries
                  relating to Content displayed in SPOTJOBS, and release ATOMX
                  LLC and subsidiaries from any and all liability for or
                  relating to any user Content including but not limited
                  toService Provider and Job Provider Content. You may, however,
                  report Content that you believe violates these Terms of Use or
                  is otherwise unlawful by sending an email to
                  support@spotjobsapp.com (for copyright complaints, please see
                  below). Please note that you may be liable for damages
                  (including costs and attorneys’ fees) for unlawful
                  misrepresentations. If you are uncertain whether an activity
                  is unlawful, we recommend seeking advice of an attorney. You
                  agree that ATOMX LLC and subsidiaries may establish general
                  practices, policies and limits, which may or may not be
                  published, concerning the use of the Sites and Services,
                  including without limitation, the time that reviews and
                  ratings will be retained, the maximum number of reviews and
                  ratings that may be sent from an account, the length of
                  reviews and ratings sent, and the maximum number of times and
                  the maximum duration for which you may access the Sites and
                  Services in a given period of time. You agree that ATOMX LLC
                  and subsidiaries have no responsibility or liability for the
                  deletion or failure to store any Content or other materials
                  maintained or transmitted by or through the Sites and
                  Services. You agree that ATOMX LLC and subsidiaries have the
                  right to change these general practices and limits at any
                  time, in its sole discretion, with or without notice.
                </p>
                <b>USERS, SERVICE PROVIDERS AND JOB PROVIDERS</b>
                <p>
                  SPOTJOBS, ATOMX LLC and subsidiaries do not endorse and are
                  not responsible or liable for any Content, data, advertising,
                  products, goods or services available or unavailable from, or
                  through, SPOTJOBS or any third party (which includes, but is
                  not limited to, health care and wellness providers). You agree
                  that should you use or rely on such Content, data,
                  advertisement, products, goods or services, available or
                  unavailable from, or through SPOTJOBSand any third party
                  (which includes, but is not limited to, health care and
                  wellness providers), SPOTJOBS, ATOMX LLC and subsidiaries are
                  not responsible or liable, indirectly or directly, for any
                  damage or loss caused or alleged to be caused by or in
                  connection with such use or reliance. Your dealings with, or
                  participation in promotions of any User (which includes, but
                  is not limited to, health care and wellness providers), and
                  any other terms, conditions, representations or warranties
                  associated with such dealings, are between you and such
                  Users(which includes, but is not limited to, health care and
                  wellness providers) exclusively and do not involve SPOTJOBS,
                  ATOMX LLC and subsidiaries. You should make whatever
                  investigation or other resources that you deem necessary or
                  appropriate before hiring or engaging with Users, Service
                  Providers and Job Providers (which includes, but is not
                  limited to, health care and wellness providers).
                  <br />
                  Third parties and Service Providers may link or otherwise
                  direct Internet users to our Sites and Services for the
                  purpose of utilizing one or more of the services we provide on
                  behalf of others. Additionally, we may provide links or
                  otherwise direct you to third party or Service Provider
                  websites. ATOMX LLC and subsidiaries do not control or operate
                  any such third party or Userswebsites. Any information you
                  provide to these third party or Userswebsites while on these
                  third party or Userswebsites are subject to the respective
                  policies of those third parties, and not ATOMX LLC and
                  subsidiariespolicies. It is your responsibility to review such
                  third-partypolicies, including any relevant privacy policies.
                  You agree that ATOMX LLC and subsidiarieswill not be
                  responsible or liable for, and does not endorse any content,
                  advertising, goods or services provided on or through these
                  outside websites or for your use or inability to use such
                  websites. ATOMX LLC and subsidiaries do not make any
                  representations or warranties as to the security of any
                  information (including, without limitation, credit card and
                  other personal information) you might be requested to give to
                  any third-partysites. You use these third-party or other Users
                  websites at your own risk.
                  <br />
                  You agree that ATOMX LLC and subsidiariesare not responsible
                  for the accessibility or unavailability of any User(which
                  includes, but is not limited to, health care and wellness
                  providers) or for your interactions and dealings with them,
                  waive the right to bring or assert any claim against ATOMX LLC
                  and subsidiaries relating to any interactions or dealings with
                  any User, Service Provider and/or Job Provider (which
                  includes, but is not limited to, health care and wellness
                  providers), and release ATOMX LLC and subsidiaries from any
                  and all liability for or relating to any interactions or
                  dealings with any User, Service Providers and/or Job Provider
                  (which includes, but is not limited to, health care and
                  wellness providers). Without limiting the foregoing, your
                  correspondence or business dealings with, consumption of
                  products or services of, or participation in promotions of,
                  third parties or Users found on or through the use of the
                  Sites and Services, including payment for and delivery or
                  fulfillment of related goods or services, and any other terms,
                  conditions, warranties, or representations associated with
                  such dealings, are solely between you and such third party.
                  You agree that ATOMX LLC and subsidiaries shall not be
                  responsible or liable, directly or indirectly, for any loss or
                  damage of any sort incurred as the result of any such dealings
                  or as the result of the presence of such third parties or User
                  on the SPOTJOBS Sites and Services.
                </p>
                <b>HEALTH RELATED PROVIDERS</b>
                <p>
                  The Sites and Services and its Content are for informational
                  purposes only. The Content is not intended to be a substitute
                  for professional medical advice, diagnosis, or treatment.
                  Always seek the advice of your physician or other qualified
                  health provider with any questions you may have regarding a
                  medical condition. Never disregard professional medical advice
                  or delay in seeking it because of something you have read on
                  the Sites and Services.
                  <br />
                  If you think you may have a medical emergency, call your
                  doctor or the emergency service immediately. ATOMX LLC and
                  subsidiaries do not recommend or endorse any specific tests,
                  physicians, products, procedures, opinions, or other
                  information that may be mentioned on the Sites and Services.
                  SPOTJOBS is not a health or wellness provider and cannot
                  recommend or refer you to any health or wellness provider.
                  Reliance on any information provided by ATOMX LLC and
                  subsidiaries, its employees and others appearing on the Sites
                  and Services at the invitation of SPOTJOBS and/or ATOMX LLC,
                  or other visitors to the Sites and Services is solely at your
                  own risk. The Sites and Services may contain health- related
                  materials that are sexually explicit. If you find these
                  materials offensive, should not use the Sites and Services.
                  You should be aware that if you post any health-related
                  information about yourself or anyone else on the Sites and
                  Services, you do so at your own risk. If you post health
                  information about services rendered to another individual, you
                  represent that you have the legal authority to receive health
                  information about that individual from that individual’s
                  health care providers and that you have the legal authority to
                  further disclose such health information. If you post
                  health-related information, you will be placing it into the
                  public domain which may violate federal or state laws that
                  protect the privacy of health information. You also
                  acknowledge that the health care or wellness provider about
                  whom you submit Content may submit Content that contain your
                  private or confidential health information in response to
                  Content you submit. ATOMX LLC and subsidiaries are not liable
                  for any such Content. ATOMX LLC and subsidiaries cannot be
                  expected to keep your health information confidential if you
                  post it to the Sites or Services or otherwise make it
                  available to others.
                  <br />
                  Termination of Access
                  <br />
                  ATOMX LLC and subsidiaries may terminate your privilege to use
                  or access the Sites and Services immediately and without
                  notice for any reason whatsoever. Upon such termination, you
                  must immediately cease accessing or using the Sites and
                  Services and agree not to access or make use of, or attempt to
                  use, the Sites and Services. Furthermore, you acknowledge that
                  ATOMX LLC and subsidiaries reserves the right to take action
                  -- technical, legal or otherwise -- to block, nullify or deny
                  your ability to access the Sites and Services. You understand
                  that ATOMX LLC and subsidiaries may exercise this right in its
                  sole discretion, and this right shall be in addition to and
                  not in substitution for any other rights and remedies
                  available to ATOMX LLC and subsidiaries. All provisions of
                  these Terms of Use which by their nature should survive
                  termination shall survive the termination of your access to
                  the Sites and Services, including, without limitation,
                  provision regarding ownership, warranty disclaimers,
                  indemnity, and limitations of liability.
                </p>
                <b>NOTICE FOR CALIFORNIA USERS</b>
                <p>
                  Under California Civil Code Section 1789.3, California web
                  users are entitled to the following specific consumer rights
                  notice: The Services are provided by ATOMX LLC. If you have
                  any questions, concerns, or complaints regarding the Services,
                  please contact ATOMX LLC by email at{' '}
                  <CustomLink href="mailto:support@spotjobsapp.com">
                    support@spotjobsapp.com
                  </CustomLink>
                  ; Attn: SPOTJOBS Member Services.
                </p>
                <b>TRADEMARKS AND COPYRIGHTS</b>
                <p>
                  SPOTJOBS, and other Sites and Services graphics, logos,
                  designs, page headers, button icons, scripts, and service
                  names are the trademarks or trade dress of ATOMX LLC in the
                  U.S. and/or other countries. These trademarks and trade dress
                  may not be used, including as part of trademarks and/or as
                  part of domain names, keyword advertisements, or email
                  addresses, or in connection with any product or service in any
                  manner that is likely to cause confusion. You should assume
                  all Content and material made available on the Sites and
                  Services is protect by copyright law. Aside from
                  user-submitted Content, all other materials and other
                  information on the Sites and Services, including, but not
                  limited to, all text, graphics, logos, icons, images, audio
                  and video clips, downloads, data compilations and software are
                  the exclusive property of ATOMX LLC and/or its licensors and
                  are protected by all United States and international copyright
                  laws.
                </p>
                <b>Notices</b>
                <p>
                  You agree that ATOMX LLC and subsidiaries may communicate any
                  notices to You under these Terms of Use, through electronic
                  mail, regular mail or posting the notices on the Sites and
                  Services. All notices to ATOMX LLC and subsidiaries will be
                  provided by email to{' '}
                  <CustomLink href="mailto:support@spotjobsapp.com">
                    support@spotjobsapp.com
                  </CustomLink>
                  , Attn: SPOTJOBS copyright or trademark infringement. Such
                  notices will be deemed delivered upon the earlier of the
                  verification of delivery or two (2) business days after being
                  sent. In accordance with the Digital Millennium Copyright Act
                  of 1998, Title 17 of the United States Code, Section 512
                  (“DMCA”), ATOMX LLC and subsidiaries will respond promptly to
                  claims of copyright or trademark infringement that are
                  reported at{' '}
                  <CustomLink href="mailto:support@spotjobsapp.com">
                    support@spotjobsapp.com
                  </CustomLink>
                  , Subject line Copyright or Trademark infringement.
                  <br />
                  If You are a copyright or trademark owner (or authorized to
                  act on behalf of the copyright or trademark owner) and believe
                  that Your work’s copyright or trademark has been infringed,
                  please report Your notice of infringement to us with a written
                  notification of claimed infringement that includes
                  substantially the following:
                  <br />a physical or electronic signature of a person
                  authorized to act on behalf of the owner of an exclusive right
                  that is allegedly infringed;
                  <br />
                  identification of the copyrighted work or trademark claimed to
                  have been infringed, or, if multiple copyrighted works or
                  trademarks at a single online site are covered by a single
                  notification, a representative list of such works at that
                  site;
                  <br />
                  identification of the material that is claimed to be
                  infringing or to be the subject of infringing activity and
                  that is to be removed or access to which is to be disabled,
                  and information reasonably sufficient to permit us to locate
                  the material;
                  <br />
                  information reasonably sufficient to permit us to contact You,
                  such as an address, telephone number, and, if available, an
                  electronic mail address at which You may be contacted;
                  <br />a statement that You have a good faith belief that use
                  of the material in the manner complained of is not authorized
                  by the copyright or trademark owner, its agent, or the law;
                  and
                  <br />a statement that the information in the notification is
                  accurate, and under penalty of perjury, that You are
                  authorized to act on behalf of the owner of an exclusive right
                  that is allegedly infringed.
                  <br />
                  ATOMX LLC will investigate notices of copyright and trademark
                  infringement and take appropriate actions under the DMCA.
                  Inquiries that do not follow this procedure may not receive a
                  response.
                </p>
                <b>DELAYS AND ACCESSIBILITY</b>
                <p>
                  The Sites and Services may be subject to limitations, delays,
                  failure, and other problems inherent in the use of the
                  Internet and electronic communications. SPOTJOBS, ATOMX LLC
                  and subsidiaries are not responsible for any delays, failures
                  or other damage resulting from such problems.
                </p>
                <b>USER FEEDBACK</b>
                <p>
                  SPOTJOBS appreciates hearing from you, as well as our other
                  users, and welcomes your comments regarding our Sites and
                  Services. Please be advised, however, that our policy does not
                  permit us to accept or consider creative ideas, suggestions,
                  or materials other than those which we have specifically
                  requested. Although we do value your feedback on our Sites and
                  Services, please be specific in your comments regarding our
                  services and do not submit creative ideas, suggestions or
                  materials. If, despite our request, you send us creative
                  suggestions, ideas, drawings, concepts or other information
                  (collectively, the “Submissions”), such Submissions will be
                  the property of ATOMX LLC and subsidiaries. In addition, none
                  of the Submissions will be subject to any obligations of
                  confidentiality and ATOMX LLC and subsidiaries will not be
                  liable for any future use or disclosure of such Submissions.
                  <br />
                  Warranties and Disclaimers
                  <br />
                  You acknowledge that ATOMX LLC and subsidiaries have no
                  control over, and no duty to take any action regarding: which
                  users gain access to or use the Sites and Services; what
                  effects the content on or in connection with the Sites and
                  Services may have on you; how you may interpret or use the
                  content on or in connection with the Sites and Services; or
                  what actions you may take as a result of having been exposed
                  to the content on or in connection with the Sites and
                  Services. You release ATOMX LLC and subsidiaries from all
                  liability for you having acquired or not acquired content or
                  information through the Sites and Services. The Sites and
                  Services may contain, or direct you to sites containing,
                  information that some people may find inaccurate, offensive or
                  inappropriate. ATOMX LLC and subsidiaries make no
                  representations concerning any content contained in or
                  accessed through the Sites and Services, and ATOMX LLC and
                  subsidiaries will not be responsible or liable for the
                  accuracy, copyright compliance, legality or decency of
                  material contained in or accessed through the Sites and
                  Services. ATOMX LLC and subsidiaries make no guarantee or
                  warranty, express or implied, as to the reliability, accuracy,
                  timeliness or completeness of that information and assumes no
                  responsibility for any errors or omissions therein. YOU ACCESS
                  AND USE THE SITES AND SERVICES AT YOUR OWN RISK. THE SITES AND
                  SERVICES ARE PROVIDED ON AN ‘AS IS, AS AVAILABLE’ BASIS
                  WITHOUT WARRANTY OF ANY KIND AND ANY AND ALL WARRANTIES OF
                  MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR
                  NON-INFRINGEMENT ARE SPECIFICALLY DISCLAIMED. NEITHER ATOMX
                  LLC NOR ITS AFFILIATES, EMPLOYEES, AGENTS OR THIRD-PARTY
                  CONTENT PROVIDERS SHALL BE LIABLE FOR ANY LOSS RESULTING FROM
                  USE OR UNAVAILABILITY OF INFORMATION OR CONTENT ON OR IN
                  CONNECTION WITH THE SITES AND SERVICES, INCLUDING BUT NOT
                  LIMITED TO ANY LOST PROFITS, LOSS OR DAMAGE TO DATA, OR ANY
                  DIRECT, INDIRECT, SPECIAL, CONSEQUENTIAL, COMPENSATORY OR
                  INCIDENTAL DAMAGES, EVEN IF THEY HAVE BEEN ADVISED OF THE
                  POSSIBILITY OF SUCH DAMAGES. THIS DISCLAIMER IS APPLICABLE TO
                  ANY DAMAGE OR INJURY RESULTING FROM NEGLIGENCE OR OMISSION OF
                  ATOMX LLC AND SUBSIDIARIES, COMPUTER VIRUS OR OTHER SIMILAR
                  ITEM, TELECOMMUNICATIONS ERRORS, OR UNAUTHORIZED ACCESS TO, OR
                  USE OF USER INFORMATION THROUGH THEFT OR ANY OTHER MEANS. WE
                  ARE NOT LIABLE FOR ANY CRIMINAL, TORTUOUS, OR NEGLIGENT
                  ACTIONS OR OMISSIONS OF USERS OR THIRD PARTIES THAT AFFECT THE
                  SITES AND SERVICES, OR MAYBE MISLEADING. IN NO EVENT WILL
                  ATOMX LLC AND SUBSIDIARIES OR ANY OF ITS DIRECTORS, OFFICERS,
                  AFFILIATES, AGENTS, EMPLOYEES, ASSIGNS OR THIRD-PARTY CONTENT
                  PROVIDERS BE HELD LIABLE FOR ANY TORTUOUS OR ILLEGAL CONDUCT
                  OR DAMAGES INFLICTED BY USERS. IN NO EVENT WILL ATOMX LLC AND
                  SUBSIDIARIES OR ANY OF ITS AFFILIATES, AGENTS, EMPLOYEES OR
                  ASSIGNS BE HELD LIABLE FOR ANY DAMAGE TO EQUIPMENT, HARDWARE
                  OR OTHER PROPERTY OF USER OR PERSONAL INJURY THAT ARISES IN
                  CONNECTION WITH USE OF THE SITES AND SERVICES.
                  <br />
                  ANY MATERIAL ACCESSED, DOWNLOADED, OR OTHERWISE OBTAINED
                  THROUGH THE USE OF THE SITES AND SERVICES IS DONE AT YOUR OWN
                  DISCRETION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY
                  DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS
                  FROM THE DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR
                  INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM
                  SPOTJOBS OR THROUGH OR FROM THE SITES AND/OR SERVICES SHALL
                  CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TERMS. EXCEPT
                  AS OTHERWISE EXPRESSLY PROVIDED IN THE TERMS, ATOMX LLC AND
                  SUBSIDIARIES DO NOT REPRESENT OR WARRANT THAT (I) THE SITES
                  AND/OR SERVICES WILL MEET YOUR REQUIREMENTS, (II) THE SITES
                  AND/OR SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
                  ERROR-FREE, (III) THE RESULTS THAT MAY BE OBTAINED FROM THE
                  USE OF THE SITES AND/OR SERVICES WILL BE ACCURATE OR RELIABLE,
                  (IV) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR
                  OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE APP
                  AND/OR SITES WILL MEET YOUR EXPECTATIONS, AND (V) ANY ERRORS
                  IN THE SOFTWARE WILL BE CORRECTED. YOU AGREE THAT FROM TIME TO
                  TIME WE MAY REMOVE THE SERVICE FOR INDEFINITE PERIODS OF TIME,
                  OR CANCEL THE SERVICE AT ANY TIME, WITHOUT NOTICE TO YOU.
                  BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF
                  IMPLIED WARRANTIES, THE ABOVE EXCLUSION OF IMPLIED WARRANTIES
                  MAY NOT APPLY TO YOU. BECAUSE SOME STATES OR JURISDICTIONS DO
                  NOT ALLOW THE EXCLUSION OR THE LIMITATION OF LIABILITY FOR
                  CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES OR
                  JURISDICTIONS, ATOMX LLC AND SUBSIDIARIES LIABILITY SHALL BE
                  LIMITED TO THE EXTENT PERMITTED BY LAW. ATOMX LLC AND
                  SUBSIDIARIES SHALL USE REASONABLE EFFORTS TO PROTECT
                  INFORMATION SUBMITTED BY YOU IN CONNECTION WITH THE SERVICES,
                  BUT YOU ACKNOWLEDGE AND AGREE THAT YOUR SUBMISSION OF SUCH
                  INFORMATION IS AT YOUR SOLE RISK, AND ATOMX LLC AND
                  SUBSIDIARIES HEREBY DISCLAIMS ANY AND ALL LIABILITY TO YOU FOR
                  ANY LOSS OR LIABILITY RELATING TO SUCH INFORMATION IN ANY WAY.
                  <br />
                  ATOMX LLC and subsidiaries have no control over and no duty to
                  take any action regarding: other users’ behavior; what effects
                  Content may have on you; how you may interpret or use Content;
                  or what actions you may take as a result of having been
                  exposed to Content. You release ATOMX LLC and subsidiaries
                  from all liability for you having acquired or not acquired
                  Content through the Sites and Services. ATOMX LLC and
                  subsidiaries make no representations concerning any Content,
                  including the accuracy thereof, contained in or accessed
                  through the Sites and Services, and ATOMX LLC and subsidiaries
                  will not be responsible or liable for the accuracy, copyright
                  compliance, legality or decency of material contained in or
                  accessed through the Sites and Services. The Sites and
                  Services may display links to other Internet sites or
                  resources. Because ATOMX LLC and subsidiaries have no control
                  over such sites and resources, you acknowledge and agree that
                  ATOMX LLC and subsidiaries are not responsible for the
                  availability of such external sites or resources, and does not
                  endorse and is not responsible or liable for any content,
                  advertising, products or other materials on or available from
                  such sites or resources.
                  <br />
                  You further acknowledge and agree that ATOMX LLC and
                  subsidiaries shall not be responsible or liable, directly or
                  indirectly, for any damage or loss caused or alleged to be
                  caused by or in connection with use of or reliance on any such
                  content, goods or services available on or through any such
                  site or resource.
                  <b>Indemnity</b>. You agree to indemnify, defend and hold
                  harmless ATOMX LLC and subsidiaries, its officers, managers,
                  owners, employees, agents, designees, users, successors,
                  assigns, service providers and suppliers from and against all
                  losses, liabilities, expenses, damages, claims, demands and
                  costs, including reasonable attorneys’ fees and court costs
                  due to or arising from: (a) any violation of these Terms of
                  Use by you; (b) the inaccurate or untruthful Content or other
                  information provided by you to ATOMX LLC and subsidiaries or
                  that you submit, transmit or otherwise make available through
                  the Service; or (c) any intentional or willful violation of
                  any rights of another or harm you may have caused to another.
                  ATOMX LLC and subsidiaries will have sole control of the
                  defense of any such damage or claim.
                </p>
                <b>Limitation of liability</b>
                <p>
                  YOU EXPRESSLY UNDERSTAND AND AGREE THAT ATOMX LLC AND
                  SUBSIDIARIES WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT,
                  INCIDENTAL, SPECIAL, PUNITIVE, COMPENSATORY, CONSEQUENTIAL OR
                  EXEMPLARY DAMAGES (EVEN IF ATOMX LLC AND SUBSIDIARIES HAVE
                  BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES)
                  (COLLECTIVELY, “DAMAGES”), RESULTING FROM: (A) THE USE OR
                  INABILITY TO USE THE SERVICE; (B) THE COST OF ANY GOODS AND/OR
                  SERVICES PURCHASED OR OBTAINED AS A RESULT OF THE USE OF THE
                  SERVICE; (C) DISCLOSURE OF, UNAUTHORIZED ACCESS TO OR
                  ALTERATION OF YOUR INFORMATION OR CONTENT; (D) CONTENT YOU
                  SUBMIT, RECEIVE, ACCESS, TRANSMIT OR OTHERWISE CONVEY THROUGH
                  THE SERVICE; (E) STATEMENTS OR CONDUCT OF ANY USER OR OTHER
                  THIRD PARTY THROUGH THE SERVICE; (F) ANY OTHER MATTER RELATING
                  TO THE SERVICE; (G) ANY BREACH OF THIS AGREEMENT BY ATOMX LLC
                  AND SUBSIDIARIES OR THE FAILURE OF SPOTJOBS TO PROVIDE THE
                  SERVICE UNDER THIS AGREEMENT (H) MEETING AND INTERACTING
                  OUTSIDE SPOTJOBS WITH ANY USER AS A RESULT OF THE USE OF THE
                  SERVICE, OR (G) ANY OTHER DEALINGS OR INTERACTIONS YOU HAVE
                  WITH ANY USER (OR ANY OF THEIR REPRESENTATIVES OR AGENTS).
                  THESE LIMITATIONS SHALL APPLY TO THE FULLEST EXTENT PERMITTED
                  BY LAW. In some jurisdictions, limitations of liability are
                  not permitted. In such jurisdictions, some of the foregoing
                  limitations may not apply to You. You understand and agree
                  that Your unlimited access to SPOTJOBS Contents represents a
                  substantial portion of the value and that You receive access
                  based on your Premium Membership Fee. THEREFORE, TO THE EXTENT
                  SPOTJOBS IS FOUND LIABLE FOR ANYTHING RELATED TO THIS
                  AGREEMENT OR THE USE OF THE SERVICE, ATOMX LLC AND
                  SUBSIDIARIES LIABILITY FOR DAMAGES WILL NOT EXCEED THE
                  EQUIVALENT OF ONE (1) MONTH OF YOUR PREMIUM MEMBERSHIP FEE
                  (I.E., THE AMOUNT OF YOUR ANNUAL MEMBERSHIP FEE DIVIDED BY
                  TWELVE); FREE USERS AND NON-PREMIUM MEMBERS WILL BE ENTITLE TO
                  $0.01 (ONE CENT) FOR ANY MONETARY DAMAGES.
                  <br />
                  A Payment Services (the “Service”) may be available for Users’
                  convenience but is optional. All payments are processed via
                  PayPal. By accessing or using the Service, you agree to be
                  bound by the terms and conditions of PayPal and those listed
                  below. If you do not accept the terms and conditions of this
                  Agreement, you may not access or use the Service. We may
                  modify this Agreement from time to time, and such
                  modifications shall be effective immediately upon posting of
                  the modified Agreement. By continuing to access or use the
                  Service following such modifications, you agree to be bound by
                  the modified Agreement. SPOTJOBS has no obligation to notify
                  users of the posting of a modified Agreement.
                  <br />
                  By submitting payments through the Service, you authorize
                  SPOTJOBS to process your payments. You are responsible for any
                  legal, regulatory, or banking penalties and fees that may be
                  assessed for supplying false information to us for use with
                  the Service. It is your responsibility to establish and
                  maintain your Account and to pay any and all fees associated
                  with the transactions. By accepting these Terms and
                  Conditions, you represent and warrant to SPOTJOBS and its
                  affiliates that: (i) you are 18 years old or older; (ii) you
                  are using your actual identity and any information you provide
                  is accurate and complete; (iii ) you are legally authorized to
                  make payments; and (iv) your use of the Service will not
                  violate any local, state, national or international laws or
                  regulations.
                  <br />
                  <b>Our Responsibilities. </b>SPOTJOBS will use all reasonable
                  efforts to process all payments authorizations promptly and
                  properly, provided the authorizations are actually received by
                  SPOTJOBS. We will not be responsible for any failure to
                  process a payment authorization that is not actually and
                  completely received by SPOTJOBS for any reason, including user
                  error, equipment malfunction, natural disasters or
                  impediments, or inaccurate or incomplete information.
                  <br />
                  SPOTJOBS shall not be responsible and shall not be liable for
                  any of your losses if it is unable to complete a payment
                  authorization or reception of money initiated because of
                  incorrect account setup or due to the existence of any one or
                  more of circumstances for which our system cannot forward or
                  see the transaction and/or PayPal’s will refuse the
                  transaction.
                  <br />
                  DISCLAIMER OF WARRANTIES. SPOTJOBS IS PROVIDING THE SERVICE
                  “AS IS” WITHOUT ANY WARRANTY OF ANY KIND, EXPRESS OR IMPLIED,
                  INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
                  MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                  NON-INFRINGEMENT. SPOTJOBS DOES NOT WARRANT THAT THE SERVICE
                  IS ERROR-FREE, OR THAT ACCESS TO AND USE OF THE SERVICE WILL
                  BE AVAILABLE OR UNINTERRUPTED.
                  <br />
                  LIMITATION OF LIABILITY. IN NO EVENT SHALL SPOTJOBS OR ANY OF
                  ITS REPRESENTATIVES OR AFFILIATES BE LIABLE FOR ANY PUNITIVE,
                  INCIDENTAL, INDIRECT, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING
                  OUT OF THE DELIVERY, PERFORMANCE, OR USE OF THE PAYMENT
                  SERVICE, WHETHER INCURRED BY YOU OR ANY THIRD PARTY, EVEN IF
                  SPOTJOBS OR ITS REPRESENTATIVES, AFFILIATES, OR SUPPLIERS HAVE
                  BEEN ADVISED OR MAY OTHERWISE KNOW OF THE POSSIBILITY OF SUCH
                  DAMAGES. Some jurisdictions prohibit exclusion or limitation
                  of liability for implied warranties or consequential or
                  incidental damages, so the above limitation may not apply to
                  you.
                  <br />
                  You understand that the content in each report or record on
                  SPOTJOBS has significant value to ATOMX LLC and subsidiaries
                  and that the damage caused to ATOMX LLC and subsidiaries for
                  any violation of these Terms of Use pertaining to a report or
                  record will be difficult to accurately estimate. Thus you
                  shall be liable to pay us the following amounts as liquidated
                  damages, and you agree that the liquidated damages are a
                  reasonable estimate of ATOMX LLC damages for the specified
                  breaches of these Terms of Use:
                  <ul>
                    <li>
                      If you post Content in violation of these Terms of Use,
                      you agree to promptly pay ATOMX LLC One Thousand Dollars
                      ($1,000) for each item of Content posted in violation of
                      these Terms of Use. We may (but shall not be required to)
                      to issue you a warning before assessing damages.
                    </li>
                    <li>
                      If you display, copy, duplicate, reproduce, sell, re-sell
                      or exploit for any purpose any Content in violation of
                      these Terms of Use, you agree to pay One Hundred Dollars
                      ($100) for each record or report that you displayed,
                      copied, duplicated, reproduced, sold, re-sold or exploited
                      for any purpose.
                    </li>
                    <li>
                      If you use computer programming routines that are intended
                      to aggregate records or reports from the Sites and
                      Services or otherwise damage, interfere with, disrupt,
                      impair, disable or otherwise overburden the Sites and
                      Services, you agree to pay One Hundred Dollars ($100) for
                      each report or record that is aggregated, disrupted,
                      damaged or otherwise affected by you.
                    </li>
                  </ul>
                  Except as set forth in the foregoing subparagraphs (a) through
                  (c), inclusive, you agree to pay the actual damages suffered
                  by ATOMX LLC and subsidiaries, including, but not limited to
                  attorneys’ fees and court costs, to the extent such actual
                  damages can be reasonably calculated. Notwithstanding any
                  other provision of these Terms of Use, we reserve the right to
                  seek the remedy of specific performance of any term contained
                  herein, or a preliminary or permanent injunction against the
                  breach of any such term or in aid of the exercise of any power
                  granted in these Terms of Use, or any combination thereof.
                </p>
                <b>ENTIRE AGREEMENT</b>
                <p>
                  These Terms of Use and other agreements, rules, and policies
                  incorporated by reference to these Terms including, without
                  limitation, the Privacy Policy, constitutes the entire
                  agreement between you and ATOMX LLC and subsidiaries. It
                  supersedes any prior or contemporaneous negotiations,
                  discussions or agreements, whether written or oral, between
                  you and ATOMX LLC and subsidiaries regarding the subject
                  matter contained in these Terms of Use. Additional terms and
                  conditions may exist between you and third parties, including
                  but not limited to, Users, Service Providers, Job Providers
                  and others. You represent and warrant that those third-party
                  agreements do not interfere with your obligations and duties
                  to SPOTJOBS, ATOMX LLC and subsidiaries under these Terms of
                  Use.
                </p>
                <b>ACCOUNT PERMISSIONS</b>
                <p>
                  You agree not to request or allow another person to create an
                  Account on your behalf, for your use, or for your benefit. you
                  are fully responsible and liable for the User’s acts and
                  omissions, including for obligations and liabilities relating
                  to making payments and entering into Service Contracts and the
                  Terms of Service. If any such User violates the Terms of
                  Service, it may affect your ability to use the Site and Site
                  Services. Upon closure of an Account, we may close any or all
                  related Accounts.
                </p>
                <b>RELATIONSHIP WITH SPOTJOBS</b>
                <p>
                  SPOTJOBS merely makes the Site and Site Services available to
                  enable Users to find and transact directly with each other.
                  SPOTJOBS does not introduce Users to each other, or find Jobs
                  or Service providers/Freelancers for each other. Through the
                  Site and Site Services, Users that are Service Providers may
                  be notified of other Users that may be seeking the services
                  they offer, and Users that have posted jobs may be notified of
                  Service Providers that may offer the services they seek; users
                  may receive job notifications matching their profile if set
                  accordingly in users’ profiles, and at all times. However,
                  Users are responsible for evaluating and determining the
                  suitability of any Job, Project, Employers or Service
                  Providers/ Freelancers on their own. If Users decide to enter
                  into a Service Contract, and therefor to work together, the
                  Service Contract is directly between Users and SPOTJOBS is not
                  a party to that Service Contract, there for will not be liable
                  for any outcome.
                  <br />
                  You acknowledge, agree, and understand that SPOTJOBS is not a
                  party to the relationship or any dealings between Clients/
                  Employers and Service Providers/Freelancers . Without
                  limitation, Users are solely responsible for: (a) ensuring the
                  accuracy and legality of any User Content; (b) determining the
                  suitability of other Users for a Service Contract (such as any
                  interviews, vetting, background checks, or similar actions);
                  (c) negotiating, agreeing to, and executing any terms or
                  conditions of Service Contracts; (d) performing Service
                  Providers/Freelancers Services; or (e) paying for Service
                  Providers/Freelancers Services. You further acknowledge,
                  agree, and understand that you are solely responsible for
                  assessing whether to enter into a Service Contract with
                  another. SPOTJOBS does not make any representations about or
                  guarantee the truth or accuracy of any Service Providers’
                  /Freelancers ’ or Clients’/ Employers’ listings or other User
                  Content on the Site; does not verify any feedback or
                  information provided by Users about Service
                  Providers/Freelancers or Clients/ Employers; and does not vet
                  or otherwise perform background checks on Service
                  Providers/Freelancers or Clients/ Employers. You acknowledge,
                  agree, and understand that SPOTJOBS does not, in any way,
                  supervise, direct, control, or evaluate Service
                  Providers/Freelancers or their work and is not responsible for
                  any Job, Project, Project terms or Work Product. SPOTJOBS
                  makes no representations about and does not guarantee, and you
                  agree not to hold SPOTJOBS responsible for, the quality,
                  safety, or legality of Service Providers/Freelancers Services;
                  the qualifications, background, or identities of Users; the
                  ability of Service Providers/Freelancers to deliver Service
                  Providers/Freelancers Services; the ability of Clients/
                  Employers to pay for Service Providers/Freelancers Services;
                  User Content and statements or posts made by Users; or the
                  ability or willingness of a Clients/ Employers or Service
                  Providers/Freelancers to actually complete a transaction.
                  <br />
                  You also acknowledge, agree, and understand that Service
                  Providers/Freelancers are solely responsible for determining,
                  and have the sole right to determine, which Jobs, Projects to
                  accept; the time, place, manner, and means of providing any
                  Service Providers/Freelancers Services; the type of services
                  they provide; and the price they charge for their services or
                  how that pricing is determined or set. You further
                  acknowledge, agree, and understand that: (i) you are not an
                  employee of SPOTJOBS, and you are not eligible for any of the
                  rights or benefits of employment (including unemployment
                  and/or workers compensation insurance); (ii) SPOTJOBS will not
                  have any liability or obligations, including under or related
                  to Service Contracts and/or Service Providers/Freelancers
                  Services for any acts or omissions by you or other Users;
                  (iii) SPOTJOBS does not, in any way, supervise, direct, or
                  control any Service Providers/Freelancers or their Services;
                  does not impose quality standards or a deadline for completion
                  of any Service Providers/Freelancers Services; and does not
                  dictate the performance, methods or process Service
                  Providers/Freelancers uses to perform services; (iv) Service
                  Providers/Freelancers and Clients/ Employers are free to
                  determine when and if to perform Service Providers/Freelancers
                  Services, including the days worked and time periods of work,
                  and SPOTJOBS does not set or have any control over Service
                  Providers/Freelancers and Clients/ Employers pricing, work
                  hours, work schedules, or work location, nor is SPOTJOBS
                  involved in any other way in determining the nature and amount
                  of any compensation that may be charged by or paid to Service
                  Providers/Freelancers for a Job or Project; (v) Service
                  Providers/Freelancers will be paid by the Employer at such
                  times and amounts as agreed for the given Service Contract at
                  the completion of the Job and Project, and SPOTJOBS does not,
                  in any way, provide or guarantee Service Providers/Freelancers
                  a regular salary or any minimum, regular payment; (vi)
                  SPOTJOBS does not provide Service Providers/Freelancers with
                  training or any equipment, labor, tools, or materials related
                  to any Service Contract; (vii) SPOTJOBS does not provide the
                  premises at which Service Providers/Freelancers will perform
                  the work. Service Providers/Freelancers are free to use
                  subcontractors or employees to perform Service
                  Providers/Freelancers Services and may delegate work on
                  fixed-price contracts; and (viii) SPOTJOBS does not provide
                  shipping services for any physical Work Product. If a Service
                  Providers/Freelancers uses subcontractors or employees,
                  Service Providers/Freelancers further agrees and acknowledges
                  that the Service Providers/Freelancers are solely responsible
                  for Service Providers/Freelancer‘s subcontractors and
                  employees.
                  <br />
                  Without limiting the foregoing paragraph, if you are an Agency
                  or Agency Member, you expressly acknowledge, agree, and
                  understand that: (1) the Agency is solely responsible for
                  paying its Agency Members for work performed on behalf of the
                  Agency and that such payments will not be made through the
                  Site; (2) SPOTJOBS is not a party to any agreement between the
                  Agency and its Agency Members and does not have any liability
                  or obligations under or related to any such agreement, even if
                  the Agency or Agency Member defaults; (3) neither Agencies nor
                  Agency Members are employees or agents of SPOTJOBS; (4)
                  SPOTJOBS does not, in any way, supervise, direct, or control
                  the Agency or Agency Members; (5) SPOTJOBS does not set
                  Agencies’ or Agency Members’ contract terms amongst themselves
                  or with Clients/ Employers (including determining whether the
                  contract will be hourly or fixed price), fees, pricing, work
                  hours, work schedules, or location of work; (6) SPOTJOBS does
                  not provide Agencies or Agency Members with training or any
                  equipment, labor, tools, or materials needed for any Service
                  Contract; (7) SPOTJOBS does not provide the premises at which
                  the Agency or Agency Members will perform the work; and (8)
                  SPOTJOBS makes no representations as to the reliability,
                  capability, or qualifications of any Agency or Agency Member
                  or the ability or willingness of any Agency to make payments
                  to or fulfill any other obligations to Agency Members, and
                  SPOTJOBS disclaims any and all liability relating thereto.
                  <br />
                  Nothing in this Agreement is intended to prohibit or
                  discourage (nor should be construed as prohibiting or
                  discouraging) any User from engaging in any other business
                  activities or providing any services through any other
                  channels they choose. Users are free at all times to engage in
                  such other business activities and services and are encouraged
                  to do so
                </p>
                <b>TAXES AND BENEFITS</b>
                <p>
                  Users acknowledge and agree that they are solely responsible
                  (a) for all tax liability associated with payments received
                  directly or indirectly through SPOTJOBS, and that SPOTJOBS
                  will not withhold any taxes from payments; (b) to obtain any
                  liability, health, workers’ compensation, disability,
                  unemployment, or other insurance needed, desired, or required
                  by law, and that Users are not covered by or eligible for any
                  insurance from SPOTJOBS; (c) for determining whether Users are
                  required by applicable law to issue any particular invoices
                  for the Service Providers/Freelancers Fees and for issuing any
                  invoices so required; (d) for determining whether Service
                  Providers/Freelancers are required by applicable law to remit
                  to the appropriate authorities any value added tax or any
                  other taxes or similar charges applicable to the Service
                  Providers/Freelancers Fees and remitting any such taxes or
                  charges to the appropriate authorities, as appropriate; and
                  (e) if outside of the United States, for determining if
                  SPOTJOBS is required by applicable law to withhold any amount
                  of the Service Providers/Freelancers Fees and for notifying
                  SPOTJOBS of any such requirement and indemnifying SPOTJOBS for
                  any requirement to pay any withholding amount to the
                  appropriate authorities (including penalties and interest). In
                  the event of an audit of SPOTJOBS, Users agrees to promptly
                  cooperate with SPOTJOBS and provide copies of Users’ tax
                  returns and other documents as may be reasonably requested for
                  purposes of such audit, including but not limited to records
                  showing Users are engaging in an independent business as
                  represented to SPOTJOBS.
                </p>
                <b>SERVICE CONTRACTS (JOBS OR PROJECTS)</b>
                <p>
                  If Users decide to enter into a Job or Project, also called a
                  Service Contract, the Service Contract is a contractual
                  relationship directly between the Clients/ Employers and
                  Service Providers/Freelancers. Clients/ Employers and Service
                  Providers/Freelancers have complete discretion both with
                  regard to whether to enter into a Service Contract with each
                  other and with regard to the terms of any Service Contract.
                  You acknowledge, agree, and understand that SPOTJOBS is not a
                  party to any Service Contract, that the formation of a Service
                  Contract between Users will not, under any circumstance,
                  create an employment or other service relationship between
                  SPOTJOBS and any User or a partnership or joint venture
                  between SPOTJOBS and any User.
                  <br />
                  With respect to any Service Contract, Clients/ Employers and
                  Service Providers/Freelancers may enter into any written
                  agreements that they deem appropriate (e.g., confidentiality
                  agreements, invention assignment agreements, assignment of
                  rights, etc.) provided that any such agreements do not
                  conflict with, narrow, or expand SPOTJOBS’s rights and
                  obligations under the Terms of Service, including this
                  Agreement and the applicable Payment Instructions. Users are
                  responsible for complying with any local requirements,
                  including applicable laws, rules, and regulations. Each
                  situation is highly fact-specific and requirements vary by
                  situation and jurisdiction and therefore any party should seek
                  legal advice from a licensed attorney in the relevant
                  jurisdictions. SPOTJOBS expressly disclaims any and all
                  liability with respect to actions or omissions based on any
                  contract entering between users.
                  <br />
                  When the Clients/ Employers Marks a job as done, he agrees
                  that he requests that SPOTJOBS releases the funds to the
                  Service Providers/Freelancers, therefore once that action is
                  completed, those funds cannot be refundable by SPOTJOBS.
                  Service Providers/Freelancers are not able to refund any
                  payment via SPOTJOBS. Furthermore, Users agree and acknowledge
                  that SPOTJOBS in some cases may refund the Employer or Pay the
                  Service provider without prior notice to Users, in which Users
                  agrees to have SPOTJOBS release the funds as needed, and that
                  SPOTJOBS and its Affiliates have no responsibility and will
                  not be able to recover such funds after the transactions are
                  completed.
                  <br />
                  DISPUTES AND PAYMENTS OPTIONS VIA SPOTJOBS MAY BE AVAILABLE AT
                  TIMES, BUT ARE ONLY AVAILABLE AND APPLICABLE FOR SERVICE
                  PROVIDERS/FREELANCERS WHO HAVE SETUP AND CONNECTED THEIR
                  PROFILES FOR PAYMENT VIA PAYPAL THROUGH SPOTJOBS. THEREFORE,
                  ALL CLAUSES RELATED TO PAYMENT AND DISPUTE DO NOT APPLY TO
                  USERS WHO WILL GET PAID DIRECTLY BY THEIR EMPLOYER, AND
                  OUTSIDE SPOTJOBS; IN WHICH CASE, USERS AGREE THAT THEY MAY
                  PURSUE ANY DISPUTE INDEPENDENTLY, BUT ACKNOWLEDGE AND AGREE
                  THAT SPOTJOBS WILL NOT AND IS NOT OBLIGATED TO ASSIST.
                </p>
                <b>DISPUTES AMONG USERS.</b>
                <p>
                  For disputes arising between Users, you agree to abide by the
                  dispute process of SPOTJOBS . If the dispute process does not
                  resolve your dispute, you may pursue your dispute
                  independently, but you acknowledge and agree that SPOTJOBS
                  will not and is not obligated to provide any dispute
                  assistance. If Users intends to obtain an order from any
                  arbitrator or any court that might direct SPOTJOBS, or our
                  Affiliates to take or refrain from taking any action with
                  respect to any payment made or received, that party will (a)
                  give us at least five business days’ prior notice of the
                  hearing; (b) include in any such order a provision that, as a
                  precondition to obligation affecting SPOTJOBS, we be paid in
                  full for any amounts to which we would otherwise be entitled;
                  and (c) be paid for the reasonable value of the services to be
                  rendered pursuant to such order.
                </p>
                <b>
                  SPOTJOBS PAYMENT SERVICES, INSTRUCTIONS AND FEES WHEN USING
                  SPOTJOBS PAYMENT SYSTEM
                </b>
                <p>
                  <i>
                    This section describes what fees you agree to pay to
                    SPOTJOBS in exchange for SPOTJOBS providing the Site and
                    Site Services to you and what taxes SPOTJOBS may collect, as
                    detailed below.
                  </i>
                  <br />
                  All Users agree to accept payment directly between Users, and
                  that SPOTJOBS will not be held liable for any dispute or
                  issues that may arise between Users including payment
                  transaction done outside SPOTJOBS' Payment Services.
                  <br />
                  For Users convenience SPOTJOBS may make available (through
                  PayPal payment services) Payment Services to Users to deliver,
                  hold, and/or receive payment for jobs, and to pay fees to
                  SPOTJOBS (“Payment Services”). The Payment Services are
                  intended for business use, and you agree to use the Payment
                  Services only for business purposes and not for consumer,
                  personal, family, or household purposes.
                  <br />
                  The Payment Services is optional for all Users. However, when
                  using SPOTJOBS Payment Services Users agree to the following
                  fees:
                  <br />
                  <b>Service Fees.</b> Clients/ Employers will pay SPOTJOBS an
                  8% service fee of the total cost of the job Budget, for the
                  use of the Site Services, as well as payment processing fees
                  of $0.30 +2.9% of the new total, per transaction (this amount
                  may vary). The Service Fees (to use the Site Services) are
                  paid solely by the Client/Employer and are based of the total
                  budget of the booked job by the Employer. Client/Employers
                  will pay a cancellation fee (including but not limited to the
                  payment processing fees) on jobs which are cancelled after
                  having started (A job is considered started when the Employer
                  clicks on “Starting The Job”). When an Employer pays a Service
                  Provider/ Freelancer via SPOTJOBS using our Payment Services
                  (when available) for a job or Project or when funds related to
                  a Job or Project are otherwise released to a Service Provider/
                  Freelancer, SPOTJOBS will debit the Employer for the full
                  amount paid, and then subtract and disburse to SPOTJOBS the
                  Service Fee. Clients/ Employer and Service Provider/
                  Freelancer hereby irrevocably authorizes and instructs
                  SPOTJOBS to deduct the Service Fee from the Employer’s Account
                  and pay SPOTJOBS on Employer’s behalf.
                  <br />
                  <b>Membership Fees.</b> Users may subscribe to different
                  levels of participation and privileges on the Site to access
                  additional features and Site Service, by payment of premium
                  subscription membership.
                  <br />
                  <b>Payout Fee.</b> Service providers/ Freelancers will pay
                  SPOTJOBS a $0.25 Payout Fee per transaction, at the reception
                  of any payment received via SPOTJOBS (“Payout Fee”). The
                  Payout Fee is paid to SPOTJOBS in consideration of costs
                  incurred and administration of disbursements via the
                  disbursement method requested by users and may varies by
                  disbursement method. In addition to fees charged by SPOTJOBS,
                  your disbursement method may also charge activation,
                  maintenance, or other account fees. However, if the SPOTJOBS
                  payment method is not available and/or if users get paid
                  outside SPOTJOBS, no Service fees, processing fees or Payout
                  fees will be taken by SPOTJOBS.
                  <br />
                  <b>Budget.</b> The minimum budget allowed for any job posting
                  is $10.00.
                </p>
                <b>NO FEE FOR INTRODUCING OR FOR FINDING PROJECTS.</b>
                <p>
                  SPOTJOBS does not introduce Clients/ Employers to Service
                  provider/ Freelancer and does not help Service provider/
                  Freelancers secure Jobs or Projects. SPOTJOBS merely makes the
                  Site and Site Services available to enable Freelancers to do
                  so themselves and may from time to time highlight Jobs that
                  may be of interest. Therefore, SPOTJOBS does not charge a fee
                  when a Service provider finds a suitable Employer, job or
                  Project. In addition, SPOTJOBS does not charge any fee or dues
                  for posting public feedback and composite or compiled
                  feedback, including Composite Information.
                </p>
                <b>PAYPAL FEES AND TIMING</b>
                <p>
                  SPOTJOBS uses PayPal for all payment transactions done on the
                  Site.
                  <br />
                  <b>Timing:</b> In most cases, your payment transfers
                  immediately. However, there are occasions when it may take up
                  to 24 hours. If it has been more than 24 hours, please contact
                  PayPal.
                  <br />
                  You must use your own registered PayPal account. You should
                  determine which type of PayPal account works for you. PayPal
                  may limit your account functionality until you pass their
                  verification procedures and they usually require a bank
                  account or credit card.
                  <br />
                  Fees: In addition to a small per-transfer fee SPOTJOBS
                  charges, there may be additional costs on PayPal's side.
                  PayPal changes their minimum and maximum transaction amounts
                  from time to time, and you should check with PayPal for
                  current information. Typically, transactions have a minimum of
                  $1.00 and a maximum of $10,000. PayPal's outgoing withdrawal
                  fees vary by method, destination country, and currency choice
                  (see{' '}
                  <CustomLink href="https://www.paypal.com/us/cgi-bin/webscr?cmd=_display-withdrawal-fees-outside">
                    PayPal's withdrawal fee calculator
                  </CustomLink>{' '}
                  for details). PayPal also may charge additional fees based on
                  your location. For example, PayPal India classifies transfers
                  from SPOTJOBS as payments for services and charges{' '}
                  <CustomLink href="https://www.paypal.com/in/cgi-bin/webscr?cmd=_display-xborder-fees-outside&countries=">
                    additional fees.
                  </CustomLink>
                </p>
                <b>VAT AND OTHER TAXES</b>
                <p>
                  SPOTJOBS may be required by applicable law to collect taxes or
                  levies including, without limitation, withholding income tax
                  or VAT (while some countries may refer to VAT using other
                  terms, e.g. GST, we’ll just refer to VAT, GST and any local
                  sales taxes collectively as “VAT”) in the jurisdiction of the
                  Service provider/ Freelancer (the "Taxes"). In such instances,
                  any amounts SPOTJOBS is required to collect or withhold for
                  the payment of any such Taxes shall be collected in addition
                  to the fees owed to SPOTJOBS under the Terms of Service.
                </p>
                <b>DIGITAL SIGNATURE</b>
                <p>
                  By clicking “Submit” to proceed with any payment, Users are
                  deemed to have executed these Payment Instructions
                  electronically, effective on the date the User clicks to
                  accept the engagement, pursuant to California Civil Code
                  section 1633.8 and the federal Electronic Signatures in Global
                  and National Commerce Act, 15 U.S.C. Sec. 7001, et seq., as
                  may be amended from time to time (the "E-Sign Act"). Doing so
                  constitutes an acknowledgement that you are able to
                  electronically receive, download, and print these Payment
                  Instructions. All references to the Payment in these Payment
                  Instructions will include the initial funding approval and any
                  additional funding approval for jobs based on a fix budget,
                  and other in app purchases, included but not limited to
                  Premium memberships.
                </p>
                <b>RELEASE AND DELIVERY OF MONEY</b>
                <p>
                  Users irrevocably authorize and instruct SPOTJOBS to release
                  applicable job budget amount also called “Price” ( “
                  <b>Release</b>”) to their Service Provider/ Freelancer Payment
                  Account or Client/ Employer Payment Account, as applicable,
                  upon the occurrence of and in accordance with one or more
                  Release Conditions provided below or as otherwise required by
                  applicable law or the Terms of Service. The amount of the
                  Release will be delivered to the applicable Payment Account in
                  accordance with the Terms of Service.
                </p>
                <b>RELEASE CONDITIONS</b>
                <p>
                  Funds can be Released or returned only if a job has been
                  marked as started by both the Employer and the Service
                  Provider. As used in these Payment’s Instructions, “
                  <b>Release Condition</b>” means, but is not limited to, any of
                  the following:
                  <ul>
                    <li>
                      Client/ Employer and Service provider/ Freelancer agree
                      that to validate a job as completed both users need to
                      first register the job as having started by clicking on
                      the button “Starting the job” to acknowledge the presence
                      of both users at the job site, and then “Mark job as done”
                      by Client/ Employer to release the funds to the Service
                      provider/ Freelancer.
                    </li>
                    <li>
                      Client/ Employer clicks on “Mark Job As Done” to release
                      funds to Service provider/ Freelancer, once sent those
                      cannot be retrieved. All payments sent are final.
                    </li>
                    <li>
                      When Service Provider/ Freelancer click on “Mark Job As
                      Completed” he requests a Release of payment for a job
                      completed, and is requesting payment for a job which has
                      been marked as started by both users. Client/ Employer has
                      72 hours from that date to release the funds to the
                      Service Provider/ Freelancer. Both Service Provider/
                      Freelancer and Client/ Employer agree that SPOTJOBS is
                      authorized and irrevocably instructed to release the funds
                      to the Service provider/ Freelancer after that time period
                      has passed.
                    </li>
                    <li>
                      Service Provider/ Freelancer cancels a job which has been
                      marked as started, and before a Job payment has been
                      released to Service Provider/ Freelancer, in which case
                      the funds are to be returned to the Client/ Employer, and
                      Service Provider/ Freelancer and Client/ Employer agree
                      that SPOTJOBS is authorized and irrevocably instructed to
                      release to Client/ Employer the amount associated with the
                      applicable Job in connection with such Release request.
                    </li>
                    <li>
                      Client/ Employer cancels a job which has been marked as
                      started, Service Provider/ Freelancer would need to cancel
                      the job as well, in which case the funds are to be
                      returned to the Client/ Employer, and Service Provider/
                      Freelancer and Client/ Employer agree that SPOTJOBS is
                      authorized and irrevocably instructed to immediately
                      return to Client/ Employer the amount associated with the
                      applicable Job in connection with such Release request.
                      Those funds will not be retrievable by SPOTJOBS.
                    </li>
                    <li>
                      If a job has been marked as started by both users, but the
                      Employer/ Client has not clicked on “mark job as done” And
                      the Service Provider/ Freelance did not request to get
                      paid by clicking on “Mark Job As Completed”, the job will
                      remain open until users take actions to close or cancel
                      the job; Service Provider/ Freelancer and Client/ Employer
                      agree that SPOTJOBS is authorized and irrevocably
                      instructed to hold the amount associated with the
                      applicable Job, until users take actions. In such
                      circumstances were the job becomes dormant after a certain
                      amount of time, Service Provider/ Freelancer and Client/
                      Employer agree that SPOTJOBS is authorized and irrevocably
                      instructed to release or refund the funds amount
                      associated with the applicable Job, to either the Service
                      Provider/ Freelancer or Client/ Employer, at SPOTJOBS'
                      sole discretion. All funds released will be subject to the
                      applicable processing and/or Payout Fees. SPOTJOBS has no
                      obligation to notify Users of any decision made related to
                      those circumstances.
                    </li>
                    <li>
                      A Dispute button may be available to help Users resolve
                      disagreements, but does not influence the payment workflow
                      as set by SPOTJOBS, therefore if Client/ Employer or
                      Service Provider/ Freelancer have failed to come to an
                      agreement within the timeline it takes for any payments to
                      be sent or returned to the Client/ Employer or Service
                      Provider/ Freelancer, You the User agree that SPOTJOBS
                      will not be liable for any loss of funds or damages
                      accruing due to Users not being able to resolve any
                      Dispute. Furthermore, Service Provider/ Freelancer and
                      Client/ Employer agree that SPOTJOBS is authorized and
                      irrevocably instructed to release to the Service Provider/
                      Freelancer or to the Client/ Employer any amount
                      associated with the applicable Job as per SPOTJOBS payment
                      workflow, even if that specific payment case or workflow
                      is not listed under the <b>“Release Condition”</b> and/or
                      the terms & conditions.
                    </li>
                  </ul>
                  In such circumstances were Users are not responding to each
                  other or to a Dispute, or taking necessary actions to
                  facilitate payment or refunds for a job that has been marked
                  as started, or if a job becomes Dormant after a certain amount
                  of time, Service Provider/ Freelancer and Client/ Employer
                  agree that SPOTJOBS is authorized and irrevocably instructed
                  to release or refund the funds amount associated “mark job as
                  done” And the Service Provider/ Freelancer did not request to
                  get paid by clicking the job will remain open until users take
                  actions to close or cancel with the applicable Job, to either
                  the Service Provider/ Freelancer or Client/ Employer, at
                  SPOTJOBS' sole discretion. All funds released will be subject
                  to the applicable processing and/or Payout Fees. SPOTJOBS has
                  no obligation to notify Users of any decision made related to
                  those circumstances.
                  <br />
                  Service Provider/ Freelancer and Client/ Employer agree that
                  SPOTJOBS and its affiliates are not responsible for any
                  parties mistakes or misdeeds, and all parties authorize and
                  irrevocably instruct SPOTJOBS to release funds at it sole
                  discretion.
                  <br />
                  Issuance of the final order of a court of competent
                  jurisdiction from which appeal is not taken, in which case the
                  funds will be released in accordance with such order.
                  <br />
                  We believe, in our sole discretion, that fraud, an illegal
                  act, or a violation of SPOTJOBS's Terms of Service has been
                  committed or is being committed or attempted, in which case
                  Client/ Employer and Service Provider/ Freelancer irrevocably
                  authorize and instruct SPOTJOBS to take such actions as we
                  deem appropriate in our sole discretion and in accordance with
                  applicable law, in order to prevent or remedy such acts,
                  including without limitation to return the funds associated
                  with such acts to their source of payment.
                </p>
                <b>INSTRUCTIONS IRREVOCABLE</b>
                <p>
                  Client/ Employer and Service Provider/ Freelancer are deemed
                  to and hereby agree that the instruction to SPOTJOBS and its
                  subsidiaries to release or return funds is irrevocable.
                  Without limiting the foregoing, Client/ Employer’s instruction
                  to SPOTJOBS and its subsidiaries to pay the Service Provider/
                  Freelancer or to refund a Client/ Employer is irrevocable.
                  Such instruction is Client/ Employer’s authorization to pay
                  the Service Provider/ Freelancer from the Client/ Employer
                  Account or authorization to charge Client/Employer’s Payment
                  Method. Such instruction is also Client/Employer’s
                  representation that Client/Employer’s has received, inspected
                  and accepted the service rendered by the Service
                  provider/Freelancer, as done and completed successfully.
                  Client/Employer acknowledges and agrees that when the
                  Client/Employer “Mark job as done” he pays the Service
                  Provider/Freelancer directly by transferring funds into the
                  Service provider/ Freelancer’s account, and that SPOTJOBS, and
                  other Affiliates have no responsibility and will not be able
                  to recover such funds. Therefore, and in consideration of
                  services described in this Agreement, Client/Employer agrees
                  that once SPOTJOBS or its subsidiary has charged
                  Client/Employer’s Payment Method, the processing fees and
                  other charges, are non-refundable. Furthermore, Client/
                  Employer and Service Provider/ Freelancer agree and understand
                  that if any payment related to any booked job done via
                  SPOTJOBS is executed outside the SPOTJOBS payment system,
                  SPOTJOBS and its affiliates are not liable or responsible for
                  any payment received or not received between Users, regardless
                  of the outcome of the job.
                </p>
                <b>
                  REFUNDS AND CANCELLATIONS WHEN USING SPOTJOBS PAYMENT SYSTEM
                </b>
                <p>
                  Client/ Employer and Service Provider/ Freelancer are
                  encouraged to come to a mutual agreement if refunds or
                  cancellations are necessary. Job cancellations/ refunds must
                  be initiated by Client/ Employer or Service Provider/
                  Freelancer by following the steps below.
                </p>
                <ul>
                  <li>
                    If Service Provider/ Freelancer click to cancel a job which
                    has already started, Client/ Employer must click to cancel
                    the job as well to validate. When both Users clicks cancel
                    the job, agree that SPOTJOBS is authorized and irrevocably
                    instructed to immediately return the funds (funds after
                    deductions of applicable fees for job cancelation) to
                    Client/ Employer associated with that job/ contract.
                  </li>
                  <li>
                    If Client/ Employer click to cancel a job which has already
                    started, Service Provider/ Freelancer must click to cancel
                    the job as well to validate. When both Users clicks cancel
                    the job, agree that SPOTJOBS is authorized and irrevocably
                    instructed to immediately return the funds (funds after
                    deductions of applicable fees for job cancelation) to
                    Client/ Employer associated with that job/ contract.
                  </li>
                </ul>
                <b>DISPUTE ASSISTANCE PROGRAM</b>
                <p>
                  The “Dispute Assistance Program” Is only available for Jobs
                  funded via the SPOTJOBS payment method. If users fail to come
                  to a mutual resolution by way of the Cancellation process as
                  stated, SPOTJOBS provides this Dispute Assistance Program as a
                  mechanism to resolve the Dispute via the Dispute button
                  available when applicable, and in the booked job detailed page
                  bottom section.
                </p>
                <b>1 DEFINITIONS AND KEY DATES</b>
                <p>
                  "<b>Arbitration</b>" means non-binding based assistance offers
                  by SPOTJOBS’ team.
                  <br />"<b>Arbitration Limitations Date</b>" means the date 30
                  days in average after the date a Dispute is filed via the
                  Site. All disputes must be submitted in English.
                  <br />"<b>Dispute</b>" means a dispute between Employer/
                  Client and Service Provider /Freelancer concerning a Job
                  marked started by both users and covered by this Dispute
                  Assistance Program.
                  <br />"<b>Dispute Assistance Deadline</b>" means within 15
                  days after the job appointment date. All disputes must be
                  submitted in English.
                  <br />
                  "Dispute Assistance Program" means the Dispute assistance
                  program set forth in this Section DISPUTE ASSISTANCE PROGRAM.
                  <br />"<b>SPOTJOBS Dispute Assistance</b>" means the Dispute
                  assistance provided by SPOTJOBS as set forth in this Section.
                </p>
                <b>2 AVAILABILITY OF SPOTJOBS DISPUTE ASSISTANCE</b>
                <p>
                  Dispute Assistance is only available for Users paying each
                  other via the SPOTJOBS payment method, and via the Dispute
                  button when active and (i) after initial funding for the Job
                  of Payment Account associated has been charged with the full
                  budget amount set in the set Job, and (ii) prior to the
                  Dispute Assistance Deadline. Dispute Assistance is not
                  available to either the Employer/ Client or Service Provider
                  /Freelancer via the Site after the Dispute Assistance
                  Deadline. The language accepted of the dispute assistance will
                  be English only.
                </p>
                <b>3 NON-BINDING ASSISTANCE</b>
                <p>
                  SPOTJOBS will first attempt to assist Employer/ Client and
                  Service Provider /Freelancer by reviewing the Dispute and
                  proposing a mutual, non-binding resolution.
                  <br />
                  The SPOTJOBS Disputes team may notify Employer/ Client and
                  Service Provider /Freelancer concerning the ticket by
                  providing a notice of dispute along with a request for
                  information and supporting documentation (if any).
                  <br />
                  If both Employer/ Client and Service Provider /Freelancer
                  respond to the notice and request for information, then the
                  Disputes team will review the documentation submitted and any
                  information available on the Site that pertains to the
                  Dispute. After review, the Disputes team will propose a
                  mutual, non-binding resolution based on the results of the
                  review.
                  <br />
                  The proposed resolution is non-binding; Employer/ Client and
                  Service Provider /Freelancer can choose whether or not to
                  agree to it. If Employer/ Client and Service Provider
                  /Freelancer agree in writing to the proposed resolution,
                  Employer/ Client and Service Provider /Freelancer agree to
                  immediately send or return payment in accordance with the
                  proposed resolution when possible via SPOTJOBS or if agreed by
                  both parties, outside SPOTJOBS.
                  <br />
                  If Employer/ Client and Service Provider /Freelancer rejects
                  SPOTJOBS’s proposed, non-binding resolution, they may proceed
                  to arbitration among themselves outside SPOTJOBS. In that case
                  Employer/ Client and Service Provider /Freelancer agree that
                  SPOTJOBS is authorized and irrevocably instructed to
                  immediately release to Client/ Employer held funds and that no
                  further actions will be needed from SPOTJOBS. SPOTJOBS will be
                  released from all obligations and cannot be liable in any
                  shape or form regarding that dispute.
                </p>
                <b>NOTICES</b>
                <p>
                  All notices to a User required by these Payment Instructions
                  will be made via email sent by SPOTJOBS to the User’s
                  registered email address. Users are solely responsible for
                  maintaining a current, active email address registered with
                  SPOTJOBS, for checking their email and for responding to
                  notices sent by SPOTJOBS to the User’s registered email
                  address.
                </p>
                <b>COOPERATION WITH THE DISPUTE ASSISTANCE PROGRAM</b>
                <p>
                  All claims, disputes or other disagreements between you and
                  another User that are covered by the Dispute Assistance
                  Program must be resolved in accordance with the terms in the
                  Dispute Assistance Program. All claims filed or brought
                  contrary to the Dispute Assistance Program will be considered
                  improperly filed, and SPOTJOBS will have the right to take any
                  other action, including suspension or termination of User’s
                  Account, and any other legal action as SPOTJOBS deems
                  appropriate in its sole discretion.
                </p>
                <b>ABUSE</b>
                <p>
                  SPOTJOBS, in its sole discretion, reserves the right to
                  suspend or terminate your Account immediately without having
                  to give notice to you if SPOTJOBS believes you may be abusing
                  the Dispute Assistance Program or as otherwise permitted by
                  the Terms & Conditions of service. However, any Disputes for
                  any Job that existed prior to termination will be subject to
                  the Terms & Conditions.
                </p>
                <b>ACCOUNTS</b>
                <p>
                  Service Providers/ Freelancers who would like to get paid
                  directly via SPOTJOBS would need to setup their Payment
                  Account with PayPal under the section “Payments &
                  Transactions,” if no Payment Account is setup, Service
                  Providers/ Freelancers will have to get paid directly by the
                  Client/ Employer and outside SPOTJOBS. Users will have to
                  discuss payment and define a method of payment which is best
                  suited for both of them. It is SPOTJOBS' recommendation to
                  negotiate a payment method before the job appointment.
                  <br />
                  As a Service Provider/ Freelancer set up to receive payment
                  via SPOTJOBS, PayPal will release funds in connection with the
                  Job/Project, when the Client/Employer releases the funds at
                  job completion, and/or according to payment workflows via
                  SPOTJOBS.
                  <br />
                  You hereby authorize and instruct SPOTJOBS via PayPal, to act
                  as an agent in connection with the Payment Accounts and the
                  payment, holding, and receipt of funds for each Project/ Job
                  and other specified purposes in accordance with the Terms of
                  Service and the applicable Payment Instructions.
                </p>
                <b>LANGUAGES</b>
                <p>
                  For a better user-friendly experience SPOTJOBS offer for its
                  platform (website and apps) to be translatable in several
                  languages. However, SPOTJOBS’ official language of
                  communication is English. All communications including
                  disputes need to be submitted in English only, if any
                  communication is sent in any other languages it will be
                  disregarded.
                </p>
                <b>ACCOUNT PERMISSIONS</b>
                <p>
                  You agree not to request or allow another person to create an
                  Account on your behalf, for your use, or for your benefit. you
                  are fully responsible and liable for the User’s acts and
                  omissions, including for obligations and liabilities relating
                  to making payments and entering into Service Contracts and the
                  Terms of Service. If any such User violates the Terms of
                  Service, it may affect your ability to use the Site and Site
                  Services. Upon closure of an Account, we may close any or all
                  related Accounts.
                </p>
                <b>ID VERIFICATION</b>
                <p>
                  ID verification is done via an artificial intelligence process
                  managed by a third-party entity not affiliated in any manner
                  with SPOTJOBS or its affiliates. Therefore, SPOTJOBS and its
                  affiliates do not guaranty the accuracy of the ID verification
                  process and/or the accuracy of the display of its result’s
                  data on SPOTJOBS, security or results; therefore all users
                  agree (a) that SPOTJOBS and its affiliates cannot be liable in
                  any shape or form regarding any decision a user makes based on
                  other users being ID verified or not; (b) SPOTJOBS and its
                  affiliates, shall not be liable in respect of any injury, loss
                  of profits, income, revenue or business opportunities; any
                  special, indirect or consequential loss or damages; or losses
                  arising out of a Force Majeure Event. (c) by accepting to go
                  through the process of being ID verification, You (The User)
                  release SPOTJOBS and its affiliates from all liabilities that
                  may result in going through the process. If you decide to use
                  Identity Verification you declare that you are at least 18
                  years of age, and agree to the collection, processing, and/or
                  storage of your personal information by Shufti Pro for the
                  purpose(s) of identity verification, and that the information
                  you provide is true and accurate to the best of your knowledge
                  and you shall be fully responsible in case you provide wrong
                  information or any of the documents you use are fake, forged,
                  counterfeit etc. You agree to the above statement, and you
                  have read Shufti Pro's{' '}
                  <CustomLink href="https://shuftipro.com/privacy-policy/">
                    Privacy Policy.
                  </CustomLink>
                </p>
                <b>CHAT MESSAGES</b>
                <p>
                  You (the user) agree not to circulate messages that are
                  illegal, dangerous to national security, violate IT laws or
                  are objectionable. SPOTJOBS don't want such content and are
                  not responsible for it. You are solely responsible for the
                  content you post, if you are sharing or posting anything in
                  the Chat you alone will be fully responsible for the post you
                  shared or posted. Anything shared or posted in the Chat will
                  be shared by SPOTJOBS and admin with other groups and people
                  in public without your permission therefore do not post
                  anything if you have any kind of privacy concerns. SPOTJOBS
                  keeps the right to remove any User at any time without any
                  intimation or reason.
                  <br />
                  All users agree (a) that SPOTJOBS and its affiliates cannot be
                  liable in any shape or form regarding any communications or
                  missed communications between Users, technical issues, missed
                  messages, or any decision made based on other users chat
                  messages; (b) SPOTJOBS and its affiliates, shall not be liable
                  in respect of any injury, loss of profits, income, revenue or
                  business opportunities; any special, indirect or consequential
                  loss or damages; or losses arising out of a the use of Chat
                  messages communication. (c) by accepting to use Chat messaging
                  via the Sites and Services, You (The User) release SPOTJOBS
                  and its affiliates from all liabilities.
                </p>
                <b>TRACKING</b>
                <p>
                  Various technologies are used to determine location, including
                  IP address, GPS, and other sensors to better control when and
                  if location data is to be collected from devices. When we have
                  location information, we use it to tailor our Services for you
                  and others, including but not limited to helping you check-in
                  when tracking is available for a job appointment, find local
                  Service Providers/ Freelancers, and job offers in your area.
                </p>
                <b>CONTENT DISCLAMER</b>
                <p>
                  SPOTJOBS and its affiliates, are not responsible or liable for
                  any posts, shares or transmits on our Sites and Services, or
                  anything on any other third-party link displayed on our Sites
                  and Services. Users are solely responsible for engaging with
                  anyone on the platform SPOTJOBS, therefore any decision made.
                  You waive the right to bring or assert any claim against
                  SPOTJOBS, ATOMX LLC and subsidiaries relating to Content
                  displayed in SPOTJOBS, and release ATOMX LLC and subsidiaries
                  from any and all liability for or relating to any User Content
                  including but not limited to Service Providers and Job
                  Providers Content.
                </p>
                <b>GOVERNING LAW</b>
                <p>
                  These Terms of Use and the relationship between You and ATOMX
                  LLC and subsidiaries will be governed by the laws of the State
                  of Texas, notwithstanding the choice of law provisions of the
                  venue where any action is brought, where the violation
                  occurred, where You may be located or any other jurisdiction.
                  You agree and consent to the exclusive jurisdiction of the
                  state or federal courts located in Collin County, Texas and
                  waive any defense of lack of personal jurisdiction or improper
                  venue or forum non convenience to a claim brought in such
                  court, except that ATOMX LLC may elect, in its sole
                  discretion, to litigate the action in the county or state
                  where any breach by You occurred or where You can be found.
                  You agree that regardless of any statute or law to the
                  contrary, any claim or cause of action arising out or related
                  to Your use of the Site or Service or these Terms of Use shall
                  be filed within one (1) year after such claim or cause of
                  action arose or will forever be barred.{' '}
                </p>
                <b>MISCELLANEOUS</b>
                <p>
                  If you breach any term of these Terms of Use or other
                  agreement with SPOTJOBS, ATOMX LLC and subsidiaries
                  (collectively “We”), We may pursue any legal or equitable
                  remedy available, including but not limited to, direct,
                  consequential, and punitive damages and injunctive relief.
                  ATOMX LLC and subsidiaries remedies are cumulative and not
                  exclusive. Failure of ATOMX LLC and subsidiaries to exercise
                  any remedy or enforce any portion of the Terms of Use at any
                  time shall not operate as a waiver of any remedy or of the
                  right to enforce any portion of the Agreement at any time
                  thereafter. If any provision of the Terms of Use is found to
                  be unenforceable or invalid, that provision shall be limited
                  or eliminated to the minimum extent necessary so that the
                  Terms shall otherwise remain in full force and effect and
                  enforceable. These Terms of Use are not assignable,
                  transferable or sublicensable by you except with ATOMX LLC and
                  subsidiaries prior written consent. We may transfer, assign or
                  delegate the Terms and its rights and obligations without
                  consent. Users of this Sites and Services are responsible for
                  compliance with all applicable regulations and laws. No joint
                  venture, partnership, employment or agency relationship exists
                  between you and ATOMX LLC and subsidiaries as a result of
                  these Terms of Use or use of the Sites and Services. You
                  acknowledge and agree that each of the Released Parties shall
                  be an intended third-party beneficiary of these Terms of Use.
                </p>
                <b>Contacting Us</b>
                <p>
                  Contacting Us If you have any comments or questions regarding
                  these Terms, or wish to report any violation of these Terms of
                  Service, please contact us at{' '}
                  <CustomLink href="mailto:support@spotjobsapp.com" isDefault>
                    support@spotjobsapp.com
                  </CustomLink>
                  . We will address any issue to the best of our abilities.
                </p>
              </InnerWrap>
            </Article>
            <FooterModal>
              <Button onClick={() => this.handleClick()}>I agree</Button>
            </FooterModal>
          </Wrapper>
          {this._renderFloatingButton()}
        </Container>
      </Fragment>
    )
  }
}

export default termsCond
