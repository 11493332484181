import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { forgotPassword } from 'Redux/actions/auth'

import Component from './ForgotIdPass'

const selector = createStructuredSelector({})

const actions = {
  onForgotPassword: forgotPassword
}

export default connect(
  selector,
  actions
)(Component)
