import { connect } from 'react-redux'
import { compose } from 'redux'
import { withNamespaces } from 'react-i18next'
import { viewedAds } from 'Redux/actions/subscription'
import { rehydrateToken } from 'Redux/actions/auth'

import Component from './Ads'

const actions = {
  onViewedAds: viewedAds,
  onTokenRehydrate: rehydrateToken,
}

export default compose(
  connect(null, actions),
  withNamespaces('jobDescription')
)(Component)
