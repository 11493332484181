import { CheckBoxText, CheckBoxLink } from 'Components/ui'
import React, { useState } from 'react'
import { Label, CheckboxInput } from '../style'
const Checkbox = ({
  options,
  name,
  onHandleChange,
  checked,
  t,
  style,
  linkedURL
}) => {
  const [selected] = useState([])

  const onCheckedItems = (event, item, index) => {
    if (event.target.checked) {
      selected.push(item)
      onHandleChange(
        {
          target: {
            name: name,
            // value: selected
          }
        },
        true
      )
    } else {
      if (selected.length > 0) {
        selected.splice(index, 1)
        onHandleChange(
          {
            target: {
              name: name,
              value: selected
            }
          },
          true
        )
      } else {
        onHandleChange(
          {
            target: {
              name: name,
              value: []
            }
          },
          false
        )
      }
    }
  }

  const onCheckedItem = event => {
    if (event.target.checked) {
      onHandleChange(
        {
          target: {
            name: name,
            value: true
          }
        },
        true
      )
    } else {
      onHandleChange(
        {
          target: {
            name: name,
            value: false
          }
        },
        false
      )
    }
  }

  return (
    <div>
      {options.length > 0 ? (
        options.map((item, i) => (
          <Label htmlFor={item.name} key={i}>
            <CheckboxInput
              key={i}
              id={item.id}
              type="checkbox"
              name={item.name}
              onClick={event => onCheckedItems(event, item, i)}
            />
            <CheckBoxText>{t(item.name)}</CheckBoxText>
          </Label>
        ))
      ) : (
        <Label htmlFor={name} style={{margin: 0}}>
          <CheckboxInput type="checkbox" name={name} onClick={onCheckedItem} />
          {linkedURL ? (
            <CheckBoxLink href={linkedURL}>{t(name)}</CheckBoxLink>
          ) : (
            <CheckBoxText style={style}>{t(name)}</CheckBoxText>
          )}
        </Label>
      )}
    </div>
  )
}

export default Checkbox
