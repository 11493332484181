import styled from 'styled-components'
import { fontSize, fontFamily } from 'Constants'

export const Wrapper = styled.div`
  background: ${props => props.theme.colors.componentsBackground};
  margin-bottom: 20px;
  box-shadow: ${props => props.theme.colors.boxShadow};
  border-radius: 4px;
  padding: 15px;
  & > h3 {
    margin-top: 0;
  }
`

export const AreaWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
`

export const Title = styled.div`
  height: 14px;
  background-color: ${props => props.theme.colors.premiumBg};
  border-radius: 10px;
  font-size: ${fontSize.small};
  font-family: ${fontFamily};
  font-weight: 500;
  color: ${props => props.theme.colors.defaultFont};
  padding: 0 10px;
  margin: 0 15px 15px 0;
`
