import styled from 'styled-components'

export const PaymentFailedContainer = styled.div`
  background: ${p => p.theme.colors.notification};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-radius: 4px;
`

export const PaymentFailedText = styled.span`
  font-size: ${({ size }) => size ? size : 16 }px;
  margin-bottom: ${({ bottom }) => bottom ? bottom : 0}px;
  color: ${p => p.theme.colors.downloadGrey};
  font-weight: 600;
`
