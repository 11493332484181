import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import * as routes from 'Routes/routesConstants'
import { toast } from 'react-toastify'
import { withNamespaces } from 'react-i18next'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { connectToChat } from 'Redux/actions/chats'
import { getSpecificJob } from 'Redux/selectors/jobList'
import { getPostedJobs } from 'Redux/selectors/postedJobs'
import { getProfile } from 'Redux/selectors/user'
import { getActiveChat } from 'Redux/selectors/chats'

import { withSendHiringRequest, withApplyForAJob } from './index'

const getOmitProps = props =>
  _.omit(props, ['onConnectToChat', '_job', '_postedJobs', 'user'])

function withConnectToChat(Component) {
  return class extends React.Component {
    static propTypes = {
      _job: PropTypes.object,
      getError: PropTypes.func,
      onConnectToChat: PropTypes.func,
      onSendRequest: PropTypes.func
    }

    state = {
      loadChatUsername: null,
      // islockChatRequestHOC: true
    }

    handleConnectToChat = (profile, job, config = {}) => {
      const {
        user,
        onConnectToChat,
        history,
        _job,
        onSendRequest,
        onApplyJob,
        _postedJobs,
        t
      } = this.props
      const { fromJob } = config
      const companionId = _.isString(profile) ? profile : profile.username
      const jobId = !_.isEmpty(job) ? job._id : null
      const jobTitle = !_.isEmpty(job) ? job.title : null

      this.setState({ loadChatUsername: companionId })

      onConnectToChat(companionId, jobId, jobTitle, error => {
        this.setState({ loadChatUsername: null }, () => {
          if (_.get(error, 'code') === 'ForbiddenChat') {
            const peopleWhoApplied = _.get(_job, 'peopleWhoApplied', [])
            const isAlreadySendRequest = _postedJobs.data.some(el =>
              el.requests.some(
                request =>
                  request.doer === companionId && request.jobId === jobId
              )
            )

            if (
              fromJob
                ? peopleWhoApplied.some(el => el.userId === user.username)
                : isAlreadySendRequest
            ) {
              toast.error(t('apiErrors:chatWaitError'))
            } else {
              toast.warn(t('apiErrors:ForbiddenChat'))
              if (fromJob && !_.isEmpty(_job)) onApplyJob(_job)
              else onSendRequest(profile)
            }
          } else if (!error) {
            console.log('>>>>>>>>>>> profile', profile)
            // this.setState({ islockChatRequestHOC: false })
            history.push(`${routes.chats}?userId=${companionId}`)
          }
        })
      })
    }

    render() {
      const { loadChatUsername } = this.state

      return (
        <Component
          loadChatUsername={loadChatUsername}
          onConnectToChat={this.handleConnectToChat}
          {...getOmitProps(this.props)}
          jobTitle={
            !_.isEmpty(this.props.activeChat)
              ? this.props.activeChat.jobTitle
              : null
          }
        />
      )
    }
  }
}

const actions = {
  onConnectToChat: connectToChat
}

const selectors = createStructuredSelector({
  _job: getSpecificJob,
  _postedJobs: getPostedJobs,
  user: getProfile,
  activeChat: getActiveChat
})

export default compose(
  withNamespaces(['apiErrors']),
  withApplyForAJob,
  withSendHiringRequest,
  withRouter,
  connect(selectors, actions),
  withConnectToChat
)
