import React from 'react'
import PropTypes from 'prop-types'
import ImageGallery from 'react-image-gallery'
import noImg from 'Assets/dummyImage/no_img.png'
import ProfileImagesManager from 'Services/Images/ProfileImagesManager'

import image from 'Helpers/jobImage'
import { GalleryWrapper, OneImage } from './styles'

export const Gallery = ({
  images,
  id,
  anotherTypeImagesArrLength = 0,
  isFreelancerProfile = false,
  onClickFullScreen,
  onThumbnailClick,
  onCreateRef
}) => {
  const items =
    images !== 'noImg'
      ? id &&
        images.map((url, index) => {
          return {
            original: isFreelancerProfile
              ? ProfileImagesManager.getSignedURLForProfileImage(
                  anotherTypeImagesArrLength > index
                    ? 'picsOfWork'
                    : 'certificate',
                  url,
                  id
                )
              : image(id, url),
            thumbnail: isFreelancerProfile
              ? ProfileImagesManager.getSignedURLForProfileImage(
                  anotherTypeImagesArrLength > index
                    ? 'picsOfWork'
                    : 'certificate',
                  url,
                  id
                )
              : image(id, url)
          }
        })
      : [{ original: noImg }]

  return (
    <GalleryWrapper>
      {items.length > 1 ? (
        <ImageGallery
          items={items}
          ref={onCreateRef}
          showNav={false}
          showPlayButton={false}
          thumbnailPosition="left"
          onClick={onClickFullScreen}
          onThumbnailClick={onThumbnailClick}
        />
      ) : (
        <OneImage
          alt={id}
          src={items[0].original}
          onClick={onClickFullScreen}
        />
      )}
    </GalleryWrapper>
  )
}

Gallery.propTypes = {
  id: PropTypes.string,
  images: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onClickFullScreen: PropTypes.func,
  onCreateRef: PropTypes.func,
  onThumbnailClick: PropTypes.func
}
