import React from 'react'
import _ from 'lodash'
import { getJobImage, getProfileAvatar } from 'Helpers/getImageUri'

import createEmail from 'Helpers/emailNotificationGenerator'
import { logoPath } from 'Constants/api'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getProfile } from 'Redux/selectors/user'
import { withNamespaces } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { clientUrl } from '../../Constants/api'

function withShare(Component) {
  return props => {
    const share = body => {
      const { t } = props
      const title = `${t('sign:spotJobs')} ${t('notifications:notifications')}`
      const resultBody = `body=${encodeURIComponent(body)}`
      window.location.href = `mailto:?subject=${title}&${resultBody}`
    }

    const shareJob = job => {
      const { t, user } = props
      let jobImage = _.get(job, 'pics[0]')

      const shortUserName = `${user.givenName} ${user.familyName?.slice(0,1)}.`

      if (jobImage) jobImage = getJobImage(job._id, jobImage)
      else
        jobImage =
          'https://gladstoneentertainment.com/wp-content/uploads/2018/05/avatar-placeholder.gif'

      const jobUrl = `${clientUrl}/find-jobs/list/${job._id}`

      const body = createEmail({
        title: `${t('sign:spotJobs')} ${t('notifications')}`,
        message: `${t('hello')} ${t(
          'notifications:followingJob'
        )} ${job.title} ${t(
          'notifications:wasRecomended'
        )} ${shortUserName} ${t(
          'notifications:shareLink'
        )}`,
        buttonText: t('notifications:viewDetails'),
        buttonPath: jobUrl,
        logoUrl: logoPath,
        imageUrl: jobImage
      })

      share(body)
    }

    const shareSP = profile => {
      const { user, t } = props

      const shortUserName = `${user.givenName} ${user.familyName?.slice(0,1)}`

      const shortProfileName = profile.short_name

      const avatar = profile.avatarURL
        ? getProfileAvatar(profile.avatarURL, profile.username)
        : 'https://gladstoneentertainment.com/wp-content/uploads/2018/05/avatar-placeholder.gif'

      const profileUrl = `${clientUrl}/find-help/${profile.username}`

      const body = createEmail({
        title: `${t('sign:spotJobs')} ${t('notifications:notifications')}`,
        message: `${t('notifications:hello')} ${t(
          'notifications:profileOfServiceProvider'
        )} ${shortProfileName} ${t(
          'notifications:wasRecomended'
        )} ${shortUserName} ${t(
          'notifications:shareLink'
        )}`,
        buttonText: t('notifications:viewDetails'),
        buttonPath: profileUrl,
        logoUrl: logoPath,
        imageUrl: avatar
      })

      share(body)
    }

    const handleShare = (data = {}) => {
      if (_.isString(data.title)) shareJob(data)
      else if (_.isString(data.username)) shareSP(data)
    }

    return <Component {...props} onShare={handleShare} />
  }
}

const actions = {}

const selectors = createStructuredSelector({
  user: getProfile
})

export default compose(
  withRouter,
  withNamespaces(['notifications', 'sign']),
  connect(selectors, actions),
  withShare
)
