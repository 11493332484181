import React from 'react'
import PropTypes from 'prop-types'

import arrows from 'Assets/arrows'
import { Container, Text, Icon } from './style'

const TextBlock = ({
  text,
  isTop,
  to,
}) => (
  <Container isTop={isTop} to={to}>
    <Text>{text}</Text>
    <Icon src={arrows.arrowRight} />
  </Container>
)

TextBlock.propTypes = {
  text: PropTypes.string.isRequired,
  isTop: PropTypes.bool,
  to: PropTypes.string.isRequired,
}

export { TextBlock }
