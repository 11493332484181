import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Modal } from 'Components/ui'

import {
  Container,
  Header,
  Title,
  CloseIcon,
  Content,
  Label,
  Textarea,
  SubmitButton
} from './style'

class ReportModal extends PureComponent {
  state = {
    reason: ''
  }

  handleChange = event => {
    this.setState({ reason: event.target.value })
  }

  render() {
    const { isShow, onClose, t, onSubmit, isLoading } = this.props
    const { reason } = this.state
    return (
      <Modal isShow={isShow} onRequestClose={onClose}>
        <Container>
          <Header>
            <Title>{t('report')}</Title>
            <CloseIcon onClick={onClose} />
          </Header>
          <Content>
            <Label htmlFor="cancelReason">{t('writeReason')}</Label>
            <Textarea
              id="responceReason"
              value={reason}
              onChange={this.handleChange}
            />
          </Content>
          <SubmitButton disabled={isLoading} onClick={() => onSubmit(reason)}>
            {t('submit')}
          </SubmitButton>
        </Container>
      </Modal>
    )
  }
}

ReportModal.propTypes = {
  isShow: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
}

export default ReportModal
