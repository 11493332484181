import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons'
import { faYoutube } from '@fortawesome/fontawesome-free-brands'

export const CardWrapper = styled.div`
  margin: 10px;
  position: relative;

  ${props =>
    props.disabled &&
    `
`}
`

export const DeleteButton = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  color: #fff;
  background: rgba(90, 132, 235, 1);
  border-radius: 50%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1;

  & > img {
    height: 11px;
    width: 11px;
    object-fit: cover;
  }
`

export const CardContainer = styled.div`
  width: 100px;
  min-height: 100px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${p => p.theme.colors.downloadGrey};
  }
`

export const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
`

export const TextBlock = styled.a`
  width: 100px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  color: #000;
  overflow: hidden;
  margin-top: 105px;
`

export const CardTitle = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bold;
`

export const CardDescription = styled.span`
  font-weight: normal;
`

export const CardLink = styled(CardTitle)`
  font-weight: normal;
  font-size: 0.8em;
`

export const PreloadImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
`

export const ErrorLink = styled.a``

export const PlayButton = styled(FontAwesomeIcon).attrs(props => ({
  icon: faPlayCircle,
  size: '3x',
  color: '#fff'
}))`
  position: absolute;
  top: 30px;
  left: 30px;
  cursor: pointer;
`

export const ErrorIcon = styled(FontAwesomeIcon).attrs(props => ({
  icon: faYoutube,
  size: '3x',
  color: 'red'
}))`
  position: absolute;
  top: 30px;
  left: 30px;
  cursor: pointer;
`
PlayButton.displayName = 'PlayButton'