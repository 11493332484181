import React from 'react'
import PropTypes from 'prop-types'

import { SubsctiptionWrapper } from 'Components/ui'

import {
  ArrowBlock,
  BottomArrow,
  BuyExtraPoints,
  BuyExtraPointsBtn,
  InputWrapper,
  TopArrow,
  TotalPoints,
  TotalPointsText,
  Input
} from './styles'
import { checkProps } from './config'

const ExtraPoints = ({
  t,
  value,
  onIncrement,
  onDecrement,
  totalPrice,
  onBuyClick,
  onChange
}) => (
  <SubsctiptionWrapper>
    <BuyExtraPoints>
      <b>{t('buyExtraPts')}</b>
      <div>{t('minPts')}</div>
      <InputWrapper>
        <Input
          max={500}
          min={0}
          type="number"
          value={value}
          onChange={onChange}
          disabled
        />
        <ArrowBlock>
          <TopArrow onClick={onIncrement} />
          <BottomArrow onClick={onDecrement} />
        </ArrowBlock>
      </InputWrapper>
      <TotalPoints>
        <TotalPointsText>{t('totalPts')}</TotalPointsText>
        <TotalPointsText>{totalPrice}$</TotalPointsText>
      </TotalPoints>
      <BuyExtraPointsBtn onClick={onBuyClick}>
        {t('buyExtraPts')}
      </BuyExtraPointsBtn>
    </BuyExtraPoints>
  </SubsctiptionWrapper>
)

ExtraPoints.propTypes = {
  t: PropTypes.func,
  totalPrice: PropTypes.string,
  value: PropTypes.string,
  onBuyClick: PropTypes.func,
  onDecrement: PropTypes.func,
  onIncrement: PropTypes.func
}

export default React.memo(ExtraPoints, checkProps)
