import { createReducer } from 'Helpers/redux'
import { persistReducer } from 'redux-persist'
import _ from 'lodash'
import storage from 'redux-persist/lib/storage'

import {
  LOAD_REQUESTS_JOBS,
  LOAD_REQUESTS_SP,
  ACCEPT_REQUEST,
  REJECT_REQUEST
} from 'Redux/actions/requests'
import {
  ADD_JOB_TO_FAVOURITES,
  ADD_PROVIDER_TO_FAVOURITES,
  REMOVE_PROVIDER_FROM_FAVOURITES,
  REMOVE_JOB_FROM_FAVOURITES
} from 'Redux/actions/favourites'

const persistConfig = {
  key: 'requests',
  storage,
  blacklist: []
}

const initialState = {
  jobs: {
    list: [],
    isListEnd: false
  },
  sp: {
    list: [],
    isListEnd: false
  }
}

const handlers = {
  [LOAD_REQUESTS_JOBS.SUCCESS]: (state, { payload }) => ({
    ...state,
    jobs: payload
  }),
  [LOAD_REQUESTS_SP.SUCCESS]: (state, { payload }) => ({
    ...state,
    sp: payload
  }),
  [ACCEPT_REQUEST.SUCCESS]: (state, { meta }) => ({
    ...state,
    jobs: {
      ...state.jobs,
      list: state.jobs.list.filter(
        el => _.get(el, 'jobRequest._id') !== meta.jobRequestId
      )
    }
  }),
  [REJECT_REQUEST.SUCCESS]: (state, { meta }) => ({
    ...state,
    jobs: {
      ...state.jobs,
      list: state.jobs.list.filter(
        el => _.get(el, 'jobRequest._id') !== meta.jobRequestId
      )
    }
  }),

  // favourites
  [ADD_JOB_TO_FAVOURITES.SUCCESS]: (state, { payload: { jobId, _id } }) => ({
    ...state,
    jobs: {
      ...state.jobs,
      list: state.jobs.list.map(el =>
        el._id === jobId ? { ...el, favouriteId: _id } : el
      )
    }
  }),
  [ADD_PROVIDER_TO_FAVOURITES.SUCCESS]: (
    state,
    { payload: { spId, _id } }
  ) => ({
    ...state,
    sp: {
      ...state.sp,
      list: state.sp.list.map(el =>
        _.get(el, 'doer.username') === spId
          ? { ...el, doer: { ...el.doer, favouriteId: _id } }
          : el
      )
    }
  }),
  [REMOVE_JOB_FROM_FAVOURITES.REQUEST]: (state, { meta }) => ({
    ...state,
    jobs: {
      ...state.jobs,
      list: state.jobs.list.map(el =>
        el.favouriteId === meta.favouriteJobId
          ? { ...el, favouriteId: null }
          : el
      )
    }
  }),
  [REMOVE_PROVIDER_FROM_FAVOURITES.REQUEST]: (state, { meta }) => ({
    ...state,
    sp: {
      ...state.sp,
      list: state.sp.list.map(el =>
        _.get(el, 'doer.favouriteId') === meta.favouriteSpId
          ? { ...el, doer: { ...el.doer, favouriteId: null } }
          : el
      )
    }
  })
}

export default persistReducer(
  persistConfig,
  createReducer(initialState, handlers)
)
