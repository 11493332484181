export const types = {
  newMessage: 'newMessage',
  jobMatchesProfile: 'jobMatchesProfile',
  jobRecomendation: 'jobRecomendation',
  spRecomendation: 'spRecomendation',
  trackingAvailable: 'trackingAvailable',
  newJobRequest: 'newJobRequest',
  jobRequestAccepted: 'jobRequestaccepted',
  jobRequestRejected: 'jobRequestRejected',
  appointmentScheduled: 'appointmentScheduled',
  appointmentRemider: 'appointmentRemined',
  jobCancelled: 'jobCancelled',
  jobUnavailable: 'jobUnavailable',
  appliedForJob: 'appliedForJob',
  startJob: 'startJob',
  jobCompleted: 'jobCompleted',
  dispute: 'dispute',
  jobAppointmentHasExpired: 'jobAppointmentHasExpired',
  authorCancelJobNoShow: 'authorCancelJobNoShow',
  jobDone: "jobDone",
}
