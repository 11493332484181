import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { withFavouriteSP } from 'Components/hoc'

import Component from './RequestSPDescription'

import { serviceProvider } from 'Redux/actions/serviceProviders'

import { getSPRequests } from 'Redux/selectors/requests'
import { getSpecificSP } from 'Redux/selectors/serviceProvider'

const actions = {
  onLoadServiceProvider: serviceProvider
}

const selectors = createStructuredSelector({
  serviceProvider: getSpecificSP,
  requests: getSPRequests
})

export default compose(
  connect(
    selectors,
    actions
  ),
  withFavouriteSP
)(Component)
