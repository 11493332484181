import styled from 'styled-components'
import { fontFamily, fontSize, sizes } from 'Constants'

/* Search
/* ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## */

export const SearchWrap = styled.form`
  background: #eaf2fc;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 5px;
  width: ${props => (props.isWider ? '700px' : '380px')};
  @media (max-width: ${sizes.desktop}px) {
    width: ${props => (props.isWider ? '500px' : '250px')};
  }
  @media (max-width: ${sizes.tablet}px) {
    width: ${props => (props.isWider ? 'auto' : '250px')};
  }
`

export const SelectWrap = styled.div`
  position: relative;
  background: #eaf2fc;
  min-width: max-content;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  @media (max-width: ${sizes.desktop}px) {
    overflow: hidden;
    z-index: 10;
    width: 15%;
  }
  @media (min-width: ${sizes.tablet}px) {
  }
`

export const SearchInput = styled.input`
  background: #fff;
  display: flex;
  width: 100%;
  height: 41px;
  padding: 10px 45px 10px 10px;
  border: 1px solid #f2f1f4;
  outline: none;
  color: #444;
  font-size: ${fontSize.default};
  font-family: ${fontFamily};
  border-radius: 4px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03);
  transition: 0.4s;
  align-items: center;
  &:-webkit-autofill {
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0 1000px #5da6ff inset;
    border: none;
  }
`
export const ButtonSearch = styled.button`
  width: 22px;
  height: 22px;
  border: none;
  background: transparent;
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -8px;
  outline: none;
  cursor: pointer;
  &:before {
    content: ' ';
    border: 2px solid #f2f1f4;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
  }
  &:after {
    content: ' ';
    border-top: 2px solid #f2f1f4;
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 8px;
    height: 1px;
    transition: width 0.1s linear;
    transform-origin: 100% 50%;
    transform: rotate(-135deg);
  }
  @media (max-width: ${sizes.desktop}px) {
  }
  @media (min-width: ${sizes.tablet}px) {
  }
`

export const Icon = styled.img`
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  position: absolute;
`

export const Select = styled.select`
  background: transparent;
  border: none;
  font-size: ${fontSize.default};
  color: #555;
  font-family: ${fontFamily};
  margin: 0 0 0 10px;
  outline: none;
  width: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 100%;
  z-index: 1;
  padding-right: 20px;
  @media (max-width: ${sizes.desktop}px) {
    font-size: ${fontSize.default};
  }
  @media (min-width: ${sizes.tablet}px) {
  }
`

export const Option = styled.option`
  @media (max-width: ${sizes.desktop}px) {
  }
  @media (min-width: ${sizes.tablet}px) {
  }
`

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`
SearchWrap.displayName = 'SearchWrap'