import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from './api'
import { getParamsWithUnits } from 'Helpers/user'

import { SERVICE_PROVIDERS_ELEMENTS_PER_PAGE } from 'Constants/pagination'

export const GET_SERVICE_PROVIDER = createAsyncAction('sp/GET_SERVICE_PROVIDER')
export const serviceProvider = (id, callback, jobID = null) =>
  apiCall({
    method: 'GET',
    endpoint: `/service-provider/${id}`,
    types: GET_SERVICE_PROVIDER,
    preFormat: getParamsWithUnits,
    qsParams: {
      job_id: jobID
    },
    callback
  })

export const REMOVE_SP = 'sp/REMOVE'
export const removeSP = id => ({
  type: REMOVE_SP,
  id
})

export const GET_NEAREST_SERVICE_PROVIDERS_LIST = createAsyncAction(
  'sp/GET_NEAREST_SERVICE_PROVIDERS_LIST'
)

export const nearestSP = callback =>
  apiCall({
    method: 'GET',
    endpoint: `/service-providers`,
    qsParams: {
      elements_per_page: 40,
      order: 'asc',
      order_by: 'distance',
      page_number: 0,
      radius: 99999999,
      no_geolocation_filter: true
    },
    types: GET_NEAREST_SERVICE_PROVIDERS_LIST,
    preFormat: getParamsWithUnits,
    callback
  })

export const GET_SERVICE_PROVIDERS_LIST = createAsyncAction(
  'sp/GET_GET_SERVICE_PROVIDERS_LIST'
)

export const serviceProvidersList = (request, callback, isFilter = false) =>
  apiCall({
    method: 'GET',
    endpoint: `/service-providers`,
    qsParams: {
      ...request,
      elements_per_page: SERVICE_PROVIDERS_ELEMENTS_PER_PAGE
    },
    types: GET_SERVICE_PROVIDERS_LIST,
    meta: {
      isFilter
    },
    preFormat: getParamsWithUnits,
    callback
  })

export const RESET_ERROR = createAsyncAction('sp/RESET_ERROR')
export const resetError = () => ({
  type: RESET_ERROR
})