import styled from 'styled-components'
import { fontFamily, fontSize } from 'Constants'

export const Wrapper = styled.div`
  cursor: pointer;
  margin-top: 0;
  font-size: ${fontSize.small};
  font-family: ${fontFamily};
  cursor: ${props => (props.isNotAuthor ? 'pointer' : '')};
  & > svg {
    & > path {
      fill: ${props => props.theme.colors.accentColor};
    }
  }
  & > span {
    margin-left: 5px;
    color: ${props => (props.isNotAuthor ? 'red' : '#000')};
    font-size: ${fontSize.default};
  }
`
