import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'

import { SERVICE_URL } from 'Constants/serviceUrl'
import { CarouselImage, CarouselText, CarouselItemWrapper } from './styles'

class CarouselItem extends Component {
  // sortByItem = item => {}

  render() {
    const { altImage, title, iconName, t } = this.props

    return (
      <CarouselItemWrapper>
        <CarouselImage alt={altImage} src={SERVICE_URL + iconName} />
        <CarouselText>{t(title)}</CarouselText>
      </CarouselItemWrapper>
    )
  }
}
CarouselItem.propTypes = {
  altImage: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string
}

export default withNamespaces('services')(CarouselItem)
