import React from 'react'
import styled from 'styled-components'
import { fontSize, fontFamily } from 'Constants'
import { Input as UIInput, Button } from 'Components/ui'

export const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'rgba(0,0,0, 0.3)',
  zIndex: 10,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}

export const modalContentStyle = {
  position: 'relative',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  padding: '10px',
  width: '90%',
  maxWidth: '500px',
  border: '0',
  backgroundColor: '#fff',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'column'
}

export const Footer = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`

export const Btn = styled(props => <Button {...props} />)`
  margin: 0;
  width: 45%;
  ${props => (props.outline ? 'background: #d2d2d2' : '')};
`

export const Input = styled(props => <UIInput {...props} />)`
  width: 100%;
`

export const FormContainer = styled.form`
  width: 100%;
  & > div > label {
    font-size: ${fontSize.h1};
    margin-bottom: 5px;
    text-align: center;
    font-family: ${fontFamily};
  }
`
