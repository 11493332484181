import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { withNamespaces } from 'react-i18next'

import { setPreferredLanguage } from 'Redux/actions/settings'

import { getActiveLanguage } from 'Redux/selectors/settings'

import Component from './Lang'

const selector = createStructuredSelector({
  activeLanguage: getActiveLanguage
})

const actions = {
  onSetPreferredLanguage: setPreferredLanguage
}

export default compose(
  withNamespaces('languages'),
  connect(
    selector,
    actions
  )
)(Component)
