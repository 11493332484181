import memoize from 'memoize-one'
import { trackNow } from 'Routes/routesConstants'

export const getJobsBC = memoize(t => [
  {
    displayName: t('landingPage:trackNow')
  }
])

export const getTrackerBC = memoize((t, userName) => [
  {
    displayName: t('landingPage:trackNow'),
    url: trackNow
  },
  {
    displayName: userName
  }
])
