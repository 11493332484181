import React from 'react'

import { Wrapper, Icon } from './style'

const NotificationIcon = className => (
  <Wrapper className={className}>
    <Icon />
  </Wrapper>
)

export { NotificationIcon }
