import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { withNamespaces } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import {
  withShare,
  withApplyForAJob,
  withConnectToChat,
  withTracking,
  withDisputeJob,
  withHireSP
} from 'Components/hoc'

import {
  addJobToFavourites,
  removeJobFromFavourites
} from 'Redux/actions/favourites'
import { jobDescription } from 'Redux/actions/job'
import { report } from 'Redux/actions/report'
import { recurringJob } from 'Redux/actions/bookedJobs'

import { getProfile } from 'Redux/selectors/user'
import { getSpecificJob } from 'Redux/selectors/jobList'
import {
  getSpecificSP,
  getServiceProvider
} from 'Redux/selectors/serviceProvider'

import Component from './JobView'
import { markAsJob } from 'Redux/actions/bookedJobs'
import { sendMessage } from 'Redux/actions/chats'
import { getGeolocation } from 'Redux/selectors/track'

const actions = {
  onAddToFavourites: addJobToFavourites,
  onRemoveFromFavourites: removeJobFromFavourites,
  onGetJobDescription: jobDescription,
  onReport: report,
  onMarkJob: markAsJob,
  onSendMessage: sendMessage,
  onRecurringJob: recurringJob,
  geoLocation: getGeolocation,
}

const selectors = createStructuredSelector({
  user: getProfile,
  job: getSpecificJob,
  profile: getSpecificSP,
  profileLibraryData: getServiceProvider
})

export default compose(
  withDisputeJob,
  withTracking,
  withConnectToChat,
  withRouter,
  withShare,
  withApplyForAJob,
  withHireSP,
  withNamespaces([
    'findJobPage',
    'jobDetail',
    'myJobsDetail',
    'appServiceProvider'
  ]),
  connect(selectors, actions),
)(Component)
