import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 40;
  margin-bottom: 40;
`

export const Text = styled.p`
  text-align: center;
  font-size: 20px;
  color: ${p => p.theme.colors.jumbotronBoldText};
`
