import React from 'react'
import PropTypes from 'prop-types'

import { Modal } from 'Components/ui'

import {
  Container,
  Header,
  Title,
  CloseIcon,
  Content,
  Label,
  Textarea,
  Buttons,
  SubmitButton,
  CancelButton,
  DisputeTip
} from './style'

const DisputeJobModal = ({
  isShow,
  value,
  onChange,
  onClose,
  t,
  onSubmit,
  isLoading
}) => (
  <Modal isShow={isShow} onRequestClose={onClose}>
    <Container>
      <Header>
        <Title>{t('jobDetail:dispute')}</Title>
        <CloseIcon onClick={onClose} />
      </Header>
      <Content>
        <Label htmlFor="disputeReason">{t('disputeReason')}</Label>
        <Textarea id="disputeReason" value={value} onChange={onChange} />
      </Content>
      <DisputeTip>{t('notifications:disputeMessage')}</DisputeTip>
    </Container>
    <Buttons>
      <CancelButton onClick={onClose}>{t('jobPost:cancel')}</CancelButton>
      <SubmitButton disabled={isLoading} onClick={onSubmit}>
        {t('rating:submit')}
      </SubmitButton>
    </Buttons>
  </Modal>
)

DisputeJobModal.propTypes = {
  isShow: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
}

export default DisputeJobModal
