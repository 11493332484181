import React from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'
import _ from 'lodash'
import { jobStatuses } from 'Constants/jobStatuses'

import { icons } from 'Assets/icons'
import { Profession, Avatar, Premium, PreloadImg } from 'Components/ui'
import {
  Container,
  StarIcon,
  Content,
  AvatarContainer,
  Info,
  NameBlock,
  Name,
  Stars,
  Footer,
  RejectButton,
  AcceptButton
} from './style'
import { idVerificationStatus } from 'Constants/idVerification'

const ListItem = ({
  isMyJob,
  t,
  family_name: familyName,
  given_name: givenName,
  username,
  stars,
  status,
  isLoading,
  avatarURL,
  isMapView,
  isPremium,
  isPro,
  services,
  onAccept,
  onReject,
  onHire,
  onClickProfile,
  path,
  short_name,
  id_verification,
}) => {

  const _renderFooter = () => {
    if (isMyJob) {
      return (
        <Footer isLoading={isLoading}>
          {isLoading ? (
            <PreloadImg mb={0} mt={0} src={icons.preload} />
          ) : (
            <>
              <RejectButton onClick={onReject}>{t('reject')}</RejectButton>
              <AcceptButton
                onClick={status === jobStatuses.accepted ? onHire : onAccept}
              >
                {t(
                  status === jobStatuses.accepted
                    ? 'serviceProvider:hireNow'
                    : 'accept'
                )}
              </AcceptButton>
            </>
          )}
        </Footer>
      )
    }

    return null
  }

  return (
    <Container isBordered={status !== 'applied'} isMapView={isMapView}>
      <Content>
        <AvatarContainer>
          <Avatar
            avatar={avatarURL}
            id={username}
            isVerified={id_verification === idVerificationStatus.success}
            path={path}
            onClick={_.isFunction(onClickProfile) && onClickProfile}
          />
        </AvatarContainer>

        <Info>
          <NameBlock>
            <Name onClick={onClickProfile}>{short_name}</Name>
            <Premium visible={isPremium} />
          </NameBlock>
          <Stars>
            <StarIcon />
            {stars || 0}
          </Stars>
          <Profession professions={[] || services} />
        </Info>
      </Content>
      {_renderFooter()}
    </Container>
  )
}

ListItem.propTypes = {
  avatarURL: PropTypes.string,
  isLoading: PropTypes.bool,
  isMapView: PropTypes.bool,
  isPremium: PropTypes.bool,
  isPro: PropTypes.bool,
  services: PropTypes.array,
  stars: PropTypes.number,
  status: PropTypes.any,
  t: PropTypes.func,
  username: PropTypes.string,
  onAccept: PropTypes.func,
  onReject: PropTypes.func
}

export default withNamespaces(['appServiceProvider', 'serviceProvider'])(
  ListItem
)
