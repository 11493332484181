import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import i18n from 'i18next'

import { Modal } from 'Components/ui'
import { icons } from 'Assets/icons'
import {
  Container,
  Header,
  Title,
  Content,
  Input,
  Button,
  Label,
  CloseIcon,
  LoadingIcon
} from './style'

const DonateModal = ({ isVisible, onSubmit, isLoading, onCancel }) => {
  const [amount, setAmount] = useState('.00')

  const handleChange = e => setAmount(e.target.value)

  const handleSubmit = () => {
    if (Number(amount)) {
      if (Number(amount) >= 1) {
        // call api
        onSubmit(amount)
        return
      }
      return toast.error(i18n.t('landingPage:DonationMinimum'))
    }
    toast.error(i18n.t('profilePage:invalidAmount'))
  }

  const handleCancel = () => {
    onCancel()
    setAmount('.00')
  }

  return (
    <Modal isShow={isVisible} onRequestClose={handleCancel}>
      <Container>
        <Header>
          <Title>{i18n.t('landingPage:enterYourDonation')}</Title>
        </Header>
        <Content>
          <Input value={amount} onChange={handleChange} type="number" />
          <Label>$</Label>
        </Content>
        <Button onClick={handleSubmit}>
          {isLoading ? (
            <LoadingIcon alt="" src={icons.threeDotsLoader} />
          ) : (
            i18n.t('landingPage:continue')
          )}
        </Button>
      </Container>
      <CloseIcon onClick={handleCancel}>×</CloseIcon>
    </Modal>
  )
}

DonateModal.propTypes = {
  isShow: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
}

export default DonateModal
