import React from 'react'
import _ from 'lodash'
import { withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { toast } from 'react-toastify'
import { createStructuredSelector } from 'reselect'

import { applyJob } from 'Redux/actions/job'
import { getProfile } from 'Redux/selectors/user'

import { ConfirmModal } from 'Components/blocks'

function withApplyForAJob(Component) {
  return class extends React.Component {
    state = {
      loadApplyJobId: null,
      activeJobId: null
    }

    callback = null

    handleOpenApplyModal = (job, callback = null) => {
      const { _user, t } = this.props
      const { loadApplyJobId } = this.state

      if (_.isString(loadApplyJobId)) return

      if (!_user.isProvider) {
        toast.warn(t('isProviderError'))
      }
      // else if (!isMatchService(job, _user)) {
      //   toast.warn(t('matchServiceError'))
      // }
      else {
        // if (!_user?.paypal_account_withdraw) {
        //   _.isFunction(callback) && callback()
        //   return toast.error(t('jobDetail:SPApplyTheJobTip'))
        // }

        this.callback = callback

        this.setState({ activeJobId: job._id })
      }
    }

    handleCloseModal = () => {
      this.setState({ activeJobId: null })
      if (_.isFunction(this.callback)) {
        this.callback()
        this.callback = null
      }
    }

    handleApplyJob = () => {
      const { _onApply } = this.props
      const { activeJobId } = this.state

      this.setState({ loadApplyJobId: activeJobId })
      _onApply(activeJobId, () => {
        this.setState({ loadApplyJobId: null, activeJobId: null })
        if (_.isFunction(this.callback)) {
          this.callback()
          this.callback = null
        }
      })
    }

    render() {
      const { loadApplyJobId, activeJobId } = this.state

      return (
        <>
          <Component
            {..._.omit(this.props, ['_onApply', '_user'])}
            loadApplyJobId={loadApplyJobId}
            onApplyJob={this.handleOpenApplyModal}
          />
          <ConfirmModal
            isLoading={Boolean(loadApplyJobId)}
            isVisible={Boolean(activeJobId)}
            onCancel={this.handleCloseModal}
            onSubmit={this.handleApplyJob}
          />
        </>
      )
    }
  }
}

const actions = {
  _onApply: applyJob
}

const selectors = createStructuredSelector({
  _user: getProfile
})

export default compose(
  connect(selectors, actions),
  withNamespaces('apiErrors'),
  withApplyForAJob
)
