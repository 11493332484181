import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faPlayCircle, faPauseCircle } from '@fortawesome/free-solid-svg-icons'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  background: gainsboro;
  padding: 0 10px;
`

export const MessageCommpleteWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media not all and (min-resolution:.001dpcm) {
    margin-bottom: 50px;
  }
`

export const MessageWrapper = styled.div`
  background: ${p =>
    p.isMyMessage ? p.theme.colors.myMessage : p.theme.colors.joinButton};
  border-radius: ${p =>
    p.isMyMessage ? '20px 20px 0 20px' : '20px 20px 20px 0'};
  box-shadow: 0 5px 5px lightgray;
  margin: 10px 0;
  color: ${p => (p.isMyMessage ? '#000' : '#fff')};
  flex-direction: column;
  padding: 15px;
  width: max-content;
  min-width: 200px;
  max-width: 80%;
  ${p => p.isMyMessage && 'align-self: flex-end'};
  display: ${p => (p.isVisible ? 'flex' : 'none')};
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  /* display: none; */

@media not all and (min-resolution:.001dpcm) {
  padding-bottom: 15px;
  display: inline-block;
  min-width: 200px;
  max-width: 100%;
}
`

export const Text = styled.span`
  font-size: ${p => p.theme.fontSize.default};
  @media not all and (min-resolution:.001dpcm) {
    float: left;
    width: 100%;
  }
`
export const TimeBlocked = styled.span`
  font-size: ${p => p.theme.fontSize.default};
`

export const TextCompleted = styled.span`
  font-size: ${p => p.theme.fontSize.default};
  font-weight: 700;
`

export const Time = styled.span`
  align-self: flex-end;
  margin-top: 5px;
  font-size: ${p => p.theme.fontSize.small};
  font-weight: bold;
  @media not all and (min-resolution:.001dpcm) {
    margin-left: 70%;
  }
`

export const AudioWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const TimelineWrapper = styled.div`
  width: max-content;
  min-width: 150px;
  max-width: 80%;
  height: 5px;
  border-radius: 5px;
  /* background-color: '#bbbcbe'; */
  background-color: #bbbcbe;
  overflow: hidden;
`

export const Timeline = styled.div`
  /* width: 50%; */
  width: ${p => p.width + '%'};
  height: 100%;
  background-color: white;
`

export const PlayIcon = styled(FontAwesomeIcon).attrs(() => ({
  icon: faPlayCircle
}))`
  /* color: ${p => p.theme.colors.joinUs}; */
  margin-right: 5px;
  color: 'black';
  transform: scale(1.2);
  cursor: pointer;
`

export const PauseIcon = styled(PlayIcon).attrs(() => ({
  icon: faPauseCircle
}))``

export const MessageFailWrapper = styled.div`
  background: ${p =>
    p.isMyMessage ? p.theme.colors.myMessage : p.theme.colors.joinButton};
  border-radius: ${p =>
    p.isMyMessage ? '20px 20px 0 20px' : '20px 20px 20px 0'};
  box-shadow: 0 5px 5px lightgray;
  margin: 10px 0;
  color: ${p => (p.isMyMessage ? '#000' : '#fff')};
  flex-direction: column;
  padding: 15px;
  width: max-content;
  min-width: 200px;
  max-width: 80%;
  ${p => p.isMyMessage && 'align-self: flex-end'};
  display: ${p => (p.isVisible ? 'flex' : 'none')};
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  cursor: pointer;
  opacity: 0.5;

@media not all and (min-resolution:.001dpcm) {
  padding-bottom: 15px;
  display: inline-block;
  min-width: 200px;
  max-width: 100%;
}
`

export const MessageFailText = styled.span`
  color: red;
  margin-left: auto;
`