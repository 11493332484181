import React from 'react'
import styled from 'styled-components'
import { space, width } from 'styled-system'
import { getThemeField } from 'Theme'
import { fontFamily, sizes, fontSize } from 'Constants'

import { Input as UIInput, Label as Labe, Error } from 'Components/ui'
import { icons } from 'Assets/icons'

export const Loading = styled.div`
  display: flex;
  height: calc(100vh - 225px);
  width: 100%;
  justify-content: center;
  & img {
    margin: auto;
    height: 16px;
  }
`

export const ContentHeaderContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  position: relative;
`

export const ContentHeader = styled.h1`
  margin: 0;
  font-size: ${fontSize.h1};
  font-family: ${fontFamily};
  @media (max-width: ${sizes.tablet}px) {
    font-size: ${fontSize.h2};
  }
  @media (max-width: ${sizes.phone}px) {
    font-size: ${fontSize.h3};
  }
`

export const ContnentHeaderBackButton = styled.img`
  position: absolute;
  left: 40px;
  top: 3px;
  width: 2em;
  height: 2em;
  @media (max-width: ${sizes.tablet}px) {
    top: -3px;
  }
  @media (max-width: ${sizes.phone}px) {
    left: 10px;
  }
`

export const Frame = styled.div`
  padding: 10px 40px;
  border-bottom-color: #fcfcfc;
  border-bottom-style: solid;
  border-width: 0 0 2px 0;
  @media (max-width: ${sizes.phone}px) {
    padding: 10px 15px;
  }
`

export const Form = styled.form`
  width: 100%;
  margin-bottom: 5em;
`

export const Row = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  margin-top: ${props => props.mt || '5'}px;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: ${props => (props.noWrap ? 'nowrap' : 'wrap')};
  & .react-datepicker-popper {
    z-index: 2;
  }
  & > label {
    text-align: left;
    font-size: ${fontSize.default};
    font-family: ${fontFamily};
    margin: 10px 0 3px 0;
  }

  @media only screen and (max-width: ${sizes.desktop}px) {
    ${props =>
      !props.noBreak &&
      `
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    `};
  }

  ${space}
`

export const Input = styled(props => <UIInput {...props} />)`
  margin-top: 10px;
  max-width: 450px;
  margin-left: ${props => (props.marginLeft && `${props.marginLeft}px`) || 0};
  width: 47%;
  @media only screen and (max-width: ${sizes.desktop}px) {
    width: ${props => (props.phone ? '75%' : '100%')};
    margin-right: 0;
    margin-left: 0;
    ${props =>
      props.phone && 'align-self: flex-start; width: calc(100% - 120px)'};
  }
  @media only screen and (max-width: 576px) {
    ${props => props.phone && 'align-self: flex-start'}
  }
  ${space}
  ${width}
`

export const TextArea = styled.textarea`
  width: 100%;
  margin-top: 10px;
  resize: none;
  height: 100px;
  border: 3px solid #ececec;
  border-radius: 5px;
  outline: none;
  padding: 10px;
  font-size: 20px;
  font-family: ${fontFamily};
  &:focus {
    border-color: #a7c0f4;
  }
`

export const InputShort = styled(props => <Input {...props} />)`
  width: 20%;
  @media only screen and (max-width: ${sizes.desktop}px) {
    width: 100%;
  }
`

export const UsernameContainer = styled.div`
  width: 100%;
`

export const UsernameLabel = styled.h3`
  text-align: center;
  margin: 0px auto 10px;
  text-transform: capitalize;
`

export const AlertContainer = styled.div`
  padding: 20px;
  background-color: #207cca;
  color: white;
  width: 100%;
  padding-right: 0px;
`
export const AlertTitle = styled.h3`
  margin: 0px;
`

export const AlertContent = styled.p`
  margin-top: 5px;
  margin-bottom: 10px;
`

export const ResendEmail = styled.a`
  color: #ffff00;
  border: none;
  text-decoration: none;
  font-size: 13px;
  cursor: pointer;
  :last-child {
    margin-left: 10px;
  }
`

export const AvatarInput = styled.input`
  visibility: hidden;
  width: 0;
  height: 0;
  position: absolute;
`

export const AvatarImg = styled.div`
  & > img {
    width: 100px;
    height: 100px;
    background-color: ${getThemeField('colors.avatarBackground')};

    border-radius: 100px;
    color: ${getThemeField('palette.white')};
  }
`

export const AvatarCameraImgWrapper = styled.div`
  position: absolute;
  right: 5px;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 30px;

  border-radius: 15px;
  background-color: #3a82ef;
`

export const ProviderHeader = styled.div`
  font-weight: bold;
  font-size: ${fontSize.h5};
  font-family: ${fontFamily};
  max-width: 60%;
  @media (max-width: ${sizes.phone}px) {
    font-size: ${fontSize.default};
  }
`

export const ProSublabel = styled.div`
  font-size: 12px;
  color: rgb(116, 116, 116);
  margin-top: -10px;
  padding: 0 0 10px 0;
`

export const Section = styled.div`
  width: 100%;
  padding-bottom: 1em;
  margin-bottom: 1em;
  border-bottom: 1px solid #eee;
  display: flex;
  flex-direction: column;
`

export const TitleWrap = styled.span`
  font-size: ${({ size }) => (size ? `${size}px` : '15px')};
  text-align: left;
`

export const SectionHeader = styled.h4`
  font-weight: bold;
  font-size: 16px;
  font-family: ${fontFamily};
  margin: 0;
  color: #555;
`

export const ServicesList = styled.ul`
  padding: 1em 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
`

export const ServicesLi = styled.li`
  flex: 0 0 50%;
  position: relative;
  @media (max-width: ${sizes.tablet}px) {
    flex: 0 0 100%;
  }
`

export const ServicesItem = styled.div`
  padding-bottom: 0;
  margin: 0 1em 1em;
  border-bottom: 1px solid #ccc;
`

export const ServicesImg = styled.img`
  float: left;
  max-width: 40px;
  margin-right: 20px;
`

export const ServicesTitle = styled.p`
  margin: 0;
  max-width: 90%;
  height: 38px;
  font-size: ${fontSize.default};
  font-family: ${fontFamily};
  color: #555;
`

export const StatusTitle = styled.p`
  margin: 0;
  font-size: 0.9em;
  font-family: ${fontFamily};
  color: #ccc;
  width: 90px;
  text-transform: capitalize;
`

export const ServicesStatus = styled.label`
  display: flex;
  align-items: center;
  justify-self: flex-end;
`

export const ServicesSwitch = styled.div`
  transform: scale(0.6);
`

export const SectionSubheader = styled.p`
  font-weight: normal;
  font-size: 12px;
  font-family: ${fontFamily};
  margin: 0;
  color: ${getThemeField('palette.fontGrey')};
`

export const SectionHeaderContainer = styled.div`
  padding-bottom: 1em;
`

export const AddServicesButton = styled.button`
  cursor: pointer;
  max-width: 275px;
  height: 35px;
  padding: 0 20px;
  margin: auto;
  background: linear-gradient(-75deg, #f0f0f0, #cfcfcf);
  color: #989a9c;
  font-size: 18px;
  font-family: ${fontFamily};
  outline: none;
  border: none;
  border-radius: 25px;
  transition: 0.3s;
  text-transform: uppercase;
  &:hover {
    background: linear-gradient(-120deg, #cfcfcf, #f0f0f0);
  }
`

export const SaveButton = styled.button`
  border: 2px solid ${props => props.theme.colors.defaultButtonBorder};
  background: ${props => props.theme.colors.lineGrey};
  text-transform: uppercase;
  outline: none;
  font-size: ${fontSize.h4};
  font-family: ${fontFamily};
  font-weight: 900;
  padding: 12px;
  min-width: 170px;
  border-radius: 22px;
  bottom: 1em;
  cursor: pointer;
  z-index: 10;

  &:disabled {
    cursor: not-allowed;
  }

  & > img {
    height: 8px;
  }
`

export const Wrap = styled.div`
  width: 100%;
  padding-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const DatePickerWrapper = styled.div`
  width: 47%;
  position: relative;
  & > .react-datepicker-wrapper {
    width: 100%;
    margin-top: 3px;
    & > .react-datepicker__input-container {
      width: 100%;
      & > input {
        width: 100%;
        margin: 0 auto;
        border: 2px solid #ececec;
        border-radius: 5px;
        outline: none;
        padding: 10px 15px;
        font-size: 16px;
        background-image: none !important;
        &:focus {
          border-color: #a7c0f4;
        }
        &:-webkit-autofill {
          -webkit-text-fill-color: #fff;
          -webkit-box-shadow: 0 0 0 1000px #5da6ff inset;
        }
      }
    }
  }
  margin-top: 10px;
  max-width: 450px;

  @media only screen and (max-width: ${sizes.desktop}px) {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }

  ${space}
  ${width}
`

export const DateLabel = styled.label`
  font-size: ${fontSize.default};
  margin-bottom: 10px;
`

export const DateBottomLabel = styled(props => <DateLabel {...props} />)`
  font-size: 12px;
  left: 0;
  bottom: 0;
  transform: translateY(105%);
`

export const DivRelative = styled.div`
  position: relative;
`

export const CountryCodeLabel = styled.label`
  position: relative;
  top: -18px;
  font-size: ${fontSize.default};
  @media only screen and (max-width: 576px) {
    top: 2px;
  }
`

export const Label = styled(props => <Labe {...props} />)`
  float: right;
  margin: 25px 0 15px 0;
`

export const TermsError = styled(props => <Error {...props} />)`
  font-size: 12px;
  padding-top: 3px;
`

export const PhoneWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  & > label {
    text-align: left;
    font-size: ${fontSize.default};
    font-family: ${fontFamily};
    margin: 10px 0 3px 0;
  }
  @media only screen and (max-width: ${sizes.desktop}px) {
    max-width: 450px;
  }

  ${space}
`

export const RadioInputWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const RadioInput = styled.input`
  margin: 0;
  margin-right: 10px;
  font-size: 20px;
  width: 25px;
  height: 25px;
  cursor: pointer;
`

export const RadioLabel = styled.label``

export const ImgBankAccount = styled.div`
  background-color: #ddd;
  width: 80%;
  height: 200px;
  border-radius: 10px;
  margin-bottom: 20px;
  position: relative;
`

export const CardNumber = styled.span`
  position: absolute;
  bottom: ${props => props.bottom}px;
  left: ${props => props.left}px;
`

export const WrapperRelative = styled.div`
  position: relative;
  .number-input {
    width: 272px;
  }
`

export const TipWrapper = styled.div`
  position: absolute;
  z-index: 999;
  padding: 8px;
  background-color: #ddd;
  border-radius: 4px;
  border: 2px solid #a7c0f4;
`

export const TipDescription = styled.span``

export const CardTypeWrapper = styled.div`
  border-radius: 4px;
  width: 84px;
  height: 54px;
  margin-top: 20px;
  cursor: pointer;
  ${({ disabled }) =>
    disabled && {
      pointerEvents: 'none',
      opacity: 0.4
    }}
  .active {
    border: 2px solid #67c0f4;
  }
`

export const CardTypeImg = styled.img`
  width: 80px;
  height: 50px;
  border-radius: 4px;
`

export const BgCreditCard = styled.div`
  background: url('${icons.bgCreditCard}');
  width: 420px;
  height: 270px;
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-right: 5%;
  margin-left: 5%;
  border-radius: 30px;
  position: relative;
`

export const TitleCard = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  position: absolute;
  right: 20px;
  top: 20px;
`

export const SimCard = styled.div`
  position: absolute;
  bottom: 100px;
  left: 20px;
`

export const UserCard = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  position: absolute;
  bottom: 20px;
  left: 20px;
`

export const SimCardImg = styled.img`
  width: 70px;
  height: 50px;
`

export const IdCardNumber = styled.h2`
  color: #fff;
  font-size: 30px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  letter-spacing: 3px;
`

export const ExpiryDateType = styled.span`
  position: absolute;
  right: 50px;
  bottom: 125px;
  text-transform: uppercase;
  color: #fff;
  font-size: 13px;
`

export const ExpiryDateCard = styled.span`
  position: absolute;
  right: 50px;
  bottom: 100px;
  font-size: 20px;
  color: #fafafa;
`
