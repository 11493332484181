import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { services, popularServices } from 'Redux/actions/services'
import { getGeo } from 'Redux/actions/googleGeocoding'
import {
  fetchUser,
  updateUser,
  resendEmail,
  undoChangeEmail,
  sendAttributeVerificationCode,
  verifyAttribute,
  idVerification
} from 'Redux/actions/user'

import { getCredentials } from 'Redux/selectors/auth'
import { getServices } from 'Redux/selectors/services'
import { getProfile } from 'Redux/selectors/user'

import Component from './ProfilePage'

const selector = createStructuredSelector({
  user: getProfile,
  services: getServices,
  credentials: getCredentials
})

const actions = {
  onFetchUser: fetchUser,
  onResendEmail: resendEmail,
  onUndoChangeEmail: undoChangeEmail,
  onGetServices: services,
  onUpdateProfile: updateUser,
  onGetPopularServices: popularServices,
  onSendAttributeVerificationCode: sendAttributeVerificationCode,
  onVerifyAttribute: verifyAttribute,
  onGetGeo: getGeo,
  onVerification: idVerification
}

export default connect(
  selector,
  actions
)(Component)
