import { createAsyncAction } from 'Helpers/redux'

import { apiCall } from './api'
import { clientUrl } from 'Constants/api'
import * as routes from 'Routes/routesConstants'
import { api } from 'Configs/App'

export const FETCH_USER = createAsyncAction('user/FETCH_USER')
export const fetchUser = callback =>
  apiCall({
    endpoint: '/user',
    types: FETCH_USER,
    callback
  })

export const RESEND_EMAIL = createAsyncAction('user/RESEND_EMAIL')
export const resendEmail = (updateData, callback) =>
  apiCall({
    method: 'POST',
    endpoint: '/user/verify-email/resend-email',
    types: RESEND_EMAIL,
    query: updateData,
    callback
  })

export const UNDO_EMAIL = createAsyncAction('user/UNDO_EMAIL')
export const undoChangeEmail = callback =>
  apiCall({
    method: 'DELETE',
    endpoint: '/user/verify-email/undo-email-change',
    types: UNDO_EMAIL,
    callback
  })

export const UPDATE_USER = createAsyncAction('user/UPDATE_USER')
export const updateUser = (updateData, callback) =>
  apiCall({
    method: 'PUT',
    endpoint: '/user',
    query: updateData,
    types: UPDATE_USER,
    callback
  })

export const SEND_ATTRIBUTE_VERIFICATION_CODE = createAsyncAction(
  'user/SEND_ATTRIBUTE_VERIFICATION_CODE'
)
export const sendAttributeVerificationCode = (attributeName, callback) => {
  return apiCall({
    method: 'POST',
    endpoint: '/user/send-attrib-verification-code',
    query: {
      attributeName
    },
    types: SEND_ATTRIBUTE_VERIFICATION_CODE,
    callback
  })
}

export const VERIFY_ATTRIBUTE = createAsyncAction('user/VERIFY_ATTRIBUTE')
export const verifyAttribute = (attributeName, verificationCode, callback) => {
  return apiCall({
    method: 'POST',
    endpoint: '/user/verify-attribute',
    query: {
      attributeName,
      code: verificationCode
    },
    meta: {
      changedAttrKey:
        attributeName === 'email' ? 'email_verified' : 'phone_number_verified'
    },
    types: VERIFY_ATTRIBUTE,
    callback
  })
}

export const PAYMENT_IDENTITY = createAsyncAction('user/PAYMENT_IDENTITY')
export const paymentIdentity = callback => {
  return apiCall({
    method: 'POST',
    endpoint: '/payment/identity/create',
    query: {
      redirectUrl: `${clientUrl}${routes.profile}`
    },
    types: PAYMENT_IDENTITY,
    callback
  })
}

export const ID_VERIFICATION = createAsyncAction('user/ID_VERIFICATION')
export const idVerification = (query, callback) => {
  return apiCall({
    url: api.second,
    method: 'POST',
    endpoint: '/user/id-verification',
    query,
    types: ID_VERIFICATION,
    callback
  })
}
