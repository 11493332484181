import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import Switch from 'react-switch'
import _ from 'lodash'
import { SERVICE_URL } from 'Constants/serviceUrl'
import DropDown from '../dropdown'
import {
  ServicesLi,
  ServicesImg,
  ServicesTitle,
  StatusTitle,
  ServicesStatus,
  ServicesSwitch,
  ServicesItem,
  ServicesList
} from '../../styles'

class Services extends Component {
  state = {
    subServices: null
  }

  componentDidMount() {
    const subServices = []
    this.props.services &&
      this.props.services.forEach(
        service =>
          service.subservices.length !== 0 &&
          subServices.push(...service.subservices)
      )
    this.setState({
      subServices
    })
  }

  prepareSelectedServices = services => {
    if (this.state.subServices && services.length !== 0) {
      const preparedServices = []
      preparedServices.push(
        services.map(service => {
          if (service.serviceId) {
            const parent = _.find(this.props.services, {
              _id: service.categoryId
            })
            const child = _.find(this.state.subServices, {
              _id: service.serviceId
            })
            const title = `${parent.title} - ${child.title}`
            if (child.title.indexOf('-') === -1) {
              child.title = title
            }
            return { ...child, child: true }
          } else {
            return _.find(this.props.services, { _id: service.categoryId })
          }
        })
      )
      return preparedServices[0]
    } else return []
  }

  render() {
    const {
      values,
      t,
      stateServices,
      handleGetStatus,
      handleStatusChange,
      onDropdownDelete,
      onDropdownEdit
    } = this.props

    return (
      <ServicesList>
        {this.prepareSelectedServices(stateServices || values.services).map(
          item => {
            const status = handleGetStatus(item._id, item.child && item.child)
            return (
              <ServicesLi key={item._id}>
                <ServicesItem>
                  <ServicesImg
                    alt={item.title}
                    src={SERVICE_URL + item.iconName}
                  />
                  <div>
                    <ServicesTitle>{t(item.title)}</ServicesTitle>
                    <ServicesStatus htmlFor="status">
                      <StatusTitle>
                        {t('status')}: {t(`serviceStatus.${status}`)}
                      </StatusTitle>
                      <ServicesSwitch>
                        <Switch
                          checked={status === 'active'}
                          checkedIcon={null}
                          id="status"
                          name="status"
                          uncheckedIcon={null}
                          onChange={() =>
                            handleStatusChange(
                              item._id,
                              item.child && item.child
                            )
                          }
                        />
                      </ServicesSwitch>
                    </ServicesStatus>
                  </div>
                </ServicesItem>
                <DropDown
                  id={item._id}
                  onDelete={onDropdownDelete}
                  onEdit={onDropdownEdit}
                />
              </ServicesLi>
            )
          }
        )}
      </ServicesList>
    )
  }
}

export default withNamespaces('services')(Services)
