import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
`

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  color: ${p => p.theme.colors.header};
  font-size: ${p => p.theme.fontSize.default};
  border: solid 1px ${p => p.theme.colors.header};
  border-radius: 30px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  &:hover {
    color: #fff;
    background-color: ${p => p.theme.colors.header};
  }
`
