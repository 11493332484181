import { createSelector } from 'reselect'

const getReducer = store => store.paymentsTransactions

export const makeSelectCard = createSelector(getReducer, state => state.cards)

export const makeSelectEarnings = createSelector(
  getReducer,
  state => state.earnings
)

export const makeSelectPayments = createSelector(
  getReducer,
  state => state.payments
)

export const makeSelectWithdrawalHistories = createSelector(
  getReducer,
  state => state.withdrawalHistories
)
