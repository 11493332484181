import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { sizes } from 'Constants'
import withSizes from 'react-sizes'

import ImageGallery from 'react-image-gallery'

import './style.css'

import { Owerlay, Container, Content } from './style'

const ImagesModal = ({
  data,
  visible,
  startIndex,
  onClickOverflow,
  onCreateRef,
  isMobile
}) => {
  if (_.isEmpty(data)) return null

  return (
    <Owerlay isActive={visible} onClick={onClickOverflow}>
      <Container>
        <Content onClick={e => e.stopPropagation()}>
          <ImageGallery
            items={data}
            ref={onCreateRef}
            showNav={false}
            showPlayButton={false}
            startIndex={startIndex}
            thumbnailPosition={isMobile ? 'bottom' : 'left'}
          />
        </Content>
      </Container>
    </Owerlay>
  )
}

ImagesModal.propTypes = {
  data: PropTypes.array,
  startIndex: PropTypes.number,
  visible: PropTypes.bool,
  onClickOverflow: PropTypes.func,
  onCreateRef: PropTypes.func
}

const mapSisesToProps = ({ width }) => ({
  isMobile: width <= sizes.phone
})

export default withSizes(mapSisesToProps)(ImagesModal)
