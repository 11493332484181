import styled from 'styled-components'
import { space } from 'styled-system'
import { fontFamily, sizes, fontSize } from 'Constants'
import { Button as ButtonUI } from 'Components/ui'

export const ContentHeaderContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  position: relative;
`

export const ContentHeader = styled.h1`
  margin: 0;
  font-size: ${fontSize.h1};
  font-family: ${fontFamily};
  @media (max-width: ${sizes.tablet}px) {
    font-size: ${fontSize.h2};
  }
  @media (max-width: ${sizes.phone}px) {
    font-size: ${fontSize.h3};
  }
`

export const ContnentHeaderBackButton = styled.img`
  position: absolute;
  left: 40px;
  top: 3px;
  width: 2em;
  height: 2em;
  @media (max-width: ${sizes.tablet}px) {
    top: -3px;
  }
  @media (max-width: ${sizes.phone}px) {
    left: 10px;
  }
`

export const Row = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  margin-top: ${props => props.mt || '5'}px;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: ${props => (props.noWrap ? 'nowrap' : 'wrap')};
  & .react-datepicker-popper {
    z-index: 2;
  }
  & > label {
    text-align: left;
    font-size: ${fontSize.default};
    font-family: ${fontFamily};
    margin: 10px 0 3px 0;
  }

  @media only screen and (max-width: ${sizes.desktop}px) {
    ${props =>
      !props.noBreak &&
      `
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    `};
  }

  ${space}
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 40px;
  margin-top: 20px;
`

export const Block = styled.div`
  margin-bottom: 20;
`

export const Loading = styled.div`
  display: flex;
  height: calc(100vh - 225px);
  width: 100%;
  justify-content: center;
  & img {
    margin: auto;
    height: 16px;
  }
`

export const SectionCenter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 40px;
`

export const Text = styled.span`
  font-size: ${({ size }) => (size ? size : 16)}px;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 'normal')};
  width: ${({ width }) => (width ? width : 'auto')};
  color: ${({ color }) => (color ? color : '#000')};
  margin: ${({ bottom }) => (bottom ? bottom : 0)}px;
  text-align: center;
`

export const Button = styled(ButtonUI)`
  height: 45px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: ${props => !props.isConnected && props.theme.colors.defaultFontBlack};
  background: ${props => !props.isConnected && props.theme.colors.jumbotron};
  &:hover {
    background: ${props => !props.isConnected && props.theme.colors.jumbotron};
    opacity: 0.8;
  }
`

export const Icon = styled.img`
  width: 20px;
  margin-right: 10px;
`

export const Container = styled.div`
  background: #fff;
  border-radius: 12px;
`

export const Header = styled.header`
  padding: 15px 0;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 5px;
`

export const Title = styled.span`
  color: #000;
  font-size: ${p => p.theme.fontSize?.h4};
  font-weight: bold;
`

export const Content = styled.div`
  padding-right: 40px;
  padding-left: 40px;
`

export const Form = styled.div`
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  position: relative;
`

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 18px;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
`

const ConfirmButton = styled(ButtonUI)`
  border-radius: 0;
  flex-basis: 50%;
  font-weight: bold;
`

export const SubmitButton = styled(ConfirmButton)`
  ${p => p.disabled && 'pointer-events: none; opacity: 0.6;'};
  border-bottom-right-radius: 12px;
`

export const CancelButton = styled(ConfirmButton)`
  filter: grayscale(100%);
  border-bottom-left-radius: 12px;
`

export const Error = styled.span`
  color: red;
  position: absolute;
  bottom: -20px;
`

export const ImgLoading = styled.img`
  width: 30%;
`