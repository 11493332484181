import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { serviceProvider } from 'Redux/actions/serviceProviders'
import { createSPReview, updateReview } from 'Redux/actions/review'

import { getProfile } from 'Redux/selectors/user'
import { getSpecificJob } from 'Redux/selectors/jobList'
import { getSpecificSP } from 'Redux/selectors/serviceProvider'

import Component from './RatingReview'

const actions = {
  onLoadSP: serviceProvider,
  onCreateReview: createSPReview,
  onUpdateReview: updateReview
}

const selectors = createStructuredSelector({
  user: getProfile,
  job: getSpecificJob,
  profile: getSpecificSP
})

export default connect(
  selectors,
  actions
)(Component)
