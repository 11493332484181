import styled from 'styled-components'
import { fontFamily, fontSize } from 'Constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

export const TruncateText = styled.p`
  display: -webkit-box;
  font-size: ${fontSize.h4};
  font-family: ${fontFamily};
  -webkit-line-clamp: ${props => (props.isTruncated ? 3 : 50)};
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0.5em 0;
  color: #565656;
`

export const ArrowIcon = styled(FontAwesomeIcon).attrs(p => ({
  icon: p.active === 'true' ? faChevronDown : faChevronUp
}))`
  color: silver;
  cursor: pointer;
`
