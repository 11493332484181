import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { isEmpty, omit } from 'lodash'
import { toast } from 'react-toastify'
import { createStructuredSelector } from 'reselect'

import { disputeJob } from 'Redux/actions/bookedJobs'
import { DisputeJobModal } from 'Components/blocks'
import { getSpecificJob } from 'Redux/selectors/jobList'
import { getSpecificSP } from 'Redux/selectors/serviceProvider'
import { getProfile } from 'Redux/selectors/user'
import { jobStatuses } from 'Constants/jobStatuses'

function withDisputeJob(Component) {
  return class extends React.Component {
    static displayName = 'withDisputeJob'

    static propTypes = {
      disputeJob: PropTypes.func
    }

    state = {
      isRequest: false,
      isShowModal: false,
      reason: ''
    }

    handleChangeReason = ({ target: { value } }) =>
      this.setState({ reason: value })

    handleShowModal = () => this.setState({ isShowModal: true })

    handleHideModal = () => this.setState({ isShowModal: false, reason: '' })

    handleSubmit = ({ disputeReason, type }, isCancel) => {
      const { disputeJob, t, job, serviceProvider, user } = this.props
      const { reason } = this.state

      if (isEmpty(reason) && isEmpty(disputeReason)) {
        return toast.error(t('jobDetail:disputeReason'))
      }

      const disputeSendBy =
        job?.author?.username === user?.username
          ? 'Employer'
          : 'Service Provider'
      const username = user?.username || user?.short_name
      let disputeCancelTitle = 'Job Cancelled'
      if (type === jobStatuses.completed) {
        disputeCancelTitle = 'Mark job as completed'
      }
      if (type === jobStatuses.done) {
        disputeCancelTitle = 'Mark job as done'
      }

      const content = disputeReason
        ? `${disputeCancelTitle}<br>Job Id: ${job?._id}<br>Job Title: ${job?.title}<br>Dispute send by: ${disputeSendBy}<br>User name: ${username}<br>Reason: ${disputeReason}<br>Budget: ${job?.budget} ${job?.currency}<br>Employer Email: ${job?.author?.email}<br>Service Provider Email: ${serviceProvider?.email}\n`
        : `Job Id: ${job?._id}<br>Job Title: ${job?.title}<br>Dispute send by: ${disputeSendBy}<br>User name: ${username}<br>Reason: ${reason}<br>Budget: ${job?.budget} ${job?.currency}<br>Employer Email: ${job?.author?.email}<br>Service Provider Email: ${serviceProvider?.email}\n`

      let request = {
        subject: disputeReason ? 'Dispute Cancelled' : t('disputeSubject'),
        content,
        jobId: job?._id,
        reason: disputeReason || reason,
        cancelDispute: !!disputeReason,
        isCancel
      }

      if (!isCancel) {
        request = {
          ...request,
          sender: job?.author?.username === user?.username ? 'author' : 'doer'
        }
      }

      this.setState({ isRequest: true })
      disputeJob(request, error => {
        this.setState({ isRequest: false, isShowModal: false, reason: '' })
        if (error) {
          if (error === 'dipute_error' || error?.message === 'dipute_error') {
            return toast.error(t('jobDetail:limitDispute'))
          }
          return toast.error(error?.message)
        }

        if (!isCancel) {
          toast.success(t('common:success'))
        }
      })
    }

    render() {
      const { isRequest, isShowModal, reason } = this.state

      return (
        <>
          <Component
            {...omit(this.props, ['disputeJob'])}
            onDisputeJob={this.handleShowModal}
            handleDisputeJob={this.handleSubmit}
          />
          <DisputeJobModal
            isLoading={isRequest}
            isShow={isShowModal}
            value={reason}
            onChange={this.handleChangeReason}
            onClose={this.handleHideModal}
            onSubmit={this.handleSubmit}
          />
        </>
      )
    }
  }
}

const actions = {
  disputeJob
}

const selectors = createStructuredSelector({
  job: getSpecificJob,
  serviceProvider: getSpecificSP,
  user: getProfile
})

export default compose(
  connect(selectors, actions),
  withNamespaces(['LeftoverOnes']),
  withDisputeJob
)
