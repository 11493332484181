export const options = [
  {
    id: 1,
    option: 'findJobs',
    value: 'findJobs'
  },
  {
    id: 2,
    option: 'findHelp',
    value: 'findHelp'
  }
]
