import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'Components/ui'

export const Container = styled.div`
  background-color: #fff;
  z-index: 10;
  width: 100%;
  font-family: ${p => p.theme.fontFamily.default};
  max-height: 80vh;
  overflow-y: auto;
`

export const Content = styled.div`
  margin-top: 30px;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
`

export const TitleRow = styled.div`
  margin-top: 30px;
  position: relative;
`

export const Title = styled.h1`
  margin: 0;
  text-align: center;
  font-size: ${p => p.theme.fontSize.h2};
  font-weight: bold;
`

export const CloseIcon = styled(FontAwesomeIcon).attrs(props => ({
  icon: faTimes
}))`
  position: absolute;
  top: 50%;
  right: 15px;
  color: ${p => p.theme.colors.joinUs};
  transform: translateY(-50%);
  cursor: pointer;
`

export const SubTitle = styled.h3`
  margin: 0 0 15px;
  font-weight: bold;
  font-size: ${p => p.theme.fontSize.default};
`

export const Info = styled.span`
  font-size: ${p => p.theme.fontSize.default};
  color: ${p => p.theme.colors.jumbotron};
`

export const RadioGroup = styled.div`
  margin: 30px 0 25px;
`

export const RadioRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
`

export const RadioLabel = styled.label`
  font-size: ${p => p.theme.fontSize.default};
  margin-left: 5px;
`

export const WriteContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
`

export const WriteLabel = styled.label`
  font-size: ${p => p.theme.fontSize.default};
  font-weight: bold;
`

export const Textarea = styled.textarea`
  margin-top: 5px;
  border: solid 1px silver;
  border-radius: 5px;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  outline: none;
  transition: box-shadow 0.3s;
  min-height: 70px;
  &:focus {
    box-shadow: 0 0 10px silver;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`

export const DeleteButton = styled(props => <Button {...props} />)`
  font-size: ${p => p.theme.fontSize.h5};
  font-weight: bold;
`

export const ReasonError = styled.div`
  position: absolute;
  bottom: -12.5px;
  left: 0;
  font-size: 12px;
  color: ${p => p.theme.colors.iconRed};
`
