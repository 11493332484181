import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons'

import {
  ServiceTitle,
  ServiceWrapper,
  ServiceImage,
  ServiceBlock,
  EditIcon
} from './styles'

class Service extends Component {
  state = {
    subServicesString: ''
  }

  componentDidMount() {
    console.log('run componentDidMount');
    console.log('this.props', this.props.subservices);
    
    if (this.props.subservices && this.props.subservices.length !== 0) {
      let subServicesString = ''
      this.props.subservices.forEach(service => {
        subServicesString =
          subServicesString + this.props.t(service.title).toLowerCase() + ' '
      })
      this.setState({
        subServicesString
      })
    }
  }

  handleSetCaption = () => {
    if (this.props.subservices && this.props.subservices.length !== 0) {
      this.props.onHandleSubCategories(
        this.props.subservices,
        this.props._id,
        this.props.title
      )
    }
  }

  shouldRender = (filter, title) => {
    if (!filter) {
      return true
    } else if (title.indexOf(filter) !== -1) {
      return true
    } else return this.state.subServicesString.indexOf(filter) !== -1
  }

  render() {
    const {
      iconName,
      title,
      _id,
      url,
      onButtonClick,
      subservices,
      isSelected,
      filter,
      t,
      editId,
      orderKey,
      updatedAt,
      createdAt,
    } = this.props
    return (
      <>
        {this.shouldRender(filter, t(title).toLowerCase()) && (
          <ServiceBlock
            active={isSelected !== -1}
            isRed={_id === editId}
            onClick={() => {
              this.handleSetCaption(_id)
              onButtonClick(
                _id,
                !(isSelected !== -1),
                subservices && subservices.length,
                null,
                iconName,
                orderKey,
                updatedAt,
                createdAt,
                title,
                subservices || []
              )
            }}
          >
            <ServiceWrapper>
              <ServiceImage src={url + iconName} />
              <ServiceTitle>{t(title)}</ServiceTitle>
            </ServiceWrapper>
            {subservices && subservices.length !== 0 && (
              <FontAwesomeIcon icon={faFolderOpen} />
            )}
            {_id === editId ? <EditIcon /> : null}
          </ServiceBlock>
        )}
      </>
    )
  }
}

export default withNamespaces('services')(Service)
