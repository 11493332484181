import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withNamespaces } from 'react-i18next'
import ImageGallery from 'react-image-gallery'

import {
  Header,
  Ad,
  HorizontalLine,
  Services,
  BurgerMenu,
  Footer
} from 'Components/blocks'
import { PageWrapper, GreyBgWrapper, ContentWrapper } from 'Components/ui'
import { more } from 'Constants/mockHowItWorks'
import { findJobs } from 'Routes/routesConstants'
import {
  ExplanationBlock,
  SelectWhatYouNeed,
  NearServiceProviders
} from './innerBlocks'
import {
  AdWrap,
  SlideWrap,
  Content,
  NearServiceProvidersContiner
} from './styles'
import { images } from './mock'

class HomePage extends Component {
  componentDidMount() {
    const {
      onGetNearestSP,
      onGetServices,
      onGetPopularServices,
      isConnectedSocket,
      onConnectSocket,
      onLoadNotifications,
      onLoadIsUnReadMessage,
      user
    } = this.props
    onGetServices()
    onGetPopularServices()
    onGetNearestSP()
    setTimeout(onLoadNotifications, 1000)
    onLoadIsUnReadMessage(user._id)

    if (!isConnectedSocket) onConnectSocket()
  }

  _renderSlider = () => (
    <SlideWrap>
      <ImageGallery
        items={images}
        showFullscreenButton={false}
        showNav={true}
        showPlayButton={false}
        showThumbnails={false}
      />
    </SlideWrap>
  )

  filterJobs = (categories) => {
    this.props.history.push(findJobs, { filter: { categories }})
  }

  render() {
    const { services, user, nearestSP } = this.props
    return (
      <>
        <PageWrapper>
          <BurgerMenu user={user} />
          <Header postJob={true} />
          <Content>
            <ContentWrapper>
              <AdWrap>
                <Ad />
              </AdWrap>
              <Services items={services.popularServices} filterJobs={this.filterJobs} />
              <HorizontalLine />
              <SelectWhatYouNeed />
              <HorizontalLine />
              <ExplanationBlock items={more} />
            </ContentWrapper>
          </Content>
        </PageWrapper>
        {_.isEmpty(nearestSP) ? null : (
          <GreyBgWrapper>
            <NearServiceProvidersContiner>
              <NearServiceProviders items={nearestSP.slice(0, 3)} />
            </NearServiceProvidersContiner>
          </GreyBgWrapper>
        )}
        <Footer black={true} isAuth={user} />
      </>
    )
  }
}

HomePage.propTypes = {
  isConnectedSocket: PropTypes.bool,
  nearestSP: PropTypes.array,
  popularServices: PropTypes.array,
  services: PropTypes.object,
  user: PropTypes.object,
  onConnectSocket: PropTypes.func,
  onGetNearestSP: PropTypes.func,
  onGetPopularServices: PropTypes.func,
  onGetServices: PropTypes.func,
  onLoadNotifications: PropTypes.func
}

export default withNamespaces('homePage')(HomePage)
