import React from 'react'
import { HorizontalLineWrapper, Line, SymbolChecked } from './styles'

export const HorizontalLine = () => {
  return (
    <HorizontalLineWrapper className="horizontal-line">
      <Line />
      <SymbolChecked>&#10003;</SymbolChecked>
      <Line />
    </HorizontalLineWrapper>
  )
}
