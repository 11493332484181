import { put, select } from 'redux-saga/effects'
import { get } from 'lodash'

import { mountChannels } from 'Redux/actions/socket'
import LANGUAGES from 'Constants/lang'
import { getProfile } from 'Redux/selectors/user'
import { setPreferredLanguage } from 'Redux/actions/settings'

function* onSignInSuccess(action) {
  const token = get(action, 'payload.tokens.RefreshToken', null)

  if (get(action, 'meta.remember', false) && token) {
    localStorage.setItem('user_token', token)
    const { email, password } = action.meta
    if (email && password) {
      localStorage.setItem('email', email)
      localStorage.setItem('password', password)
    }
  }

  if (!get(action, 'meta.remember', false)) {
    localStorage.removeItem('email')
    localStorage.removeItem('password')
  }

  const accessToken = get(action, 'payload.tokens.AccessToken', null)

  put(mountChannels())
  if (accessToken) {
    localStorage.setItem('access', accessToken)
  }

  const localLang = localStorage.getItem('lang');
  const language = LANGUAGES[localLang] ? localLang : 'en';
  const user = yield select(getProfile) || {};

  if (language !== user?.local) {
    yield put(setPreferredLanguage(language));
  }

  yield true
}

export default onSignInSuccess
