import React from 'react'
import styled from 'styled-components'
import { fontSize, fontFamily } from 'Constants'
import { Input as UIInput, Button } from 'Components/ui'

export const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'rgba(0,0,0, 0.3)',
  zIndex: 10,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}

export const modalContentStyle = {
  position: 'relative',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  padding: '40px',
  width: '90%',
  maxWidth: '400px',
  border: '0',
  backgroundColor: '#fff',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'column'
}

export const Footer = styled.div`
  padding-top: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Btn = styled(props => <Button {...props} />)`
  margin: 0;
  width: 100%;
  ${props => (props.outline ? 'background: #d2d2d2' : '')};
`

export const Input = styled(props => <UIInput {...props} />)`
  width: 100%;

  & > label {
    text-align: center;
  }
`

export const FormContainer = styled.div`
  width: 100%;
`

export const Description = styled.div`
  text-align: center;
  font-size: ${fontSize.h5};
  font-family: ${fontFamily};

  margin-bottom: 10px;
`

export const Text = styled.div`
  margin-top: 10px;
  text-align: left;
  font-size: ${fontSize.default};
  font-family: ${fontFamily};
`

export const ResendButton = styled.div`
  margin-top: 5px;
  cursor: pointer;
  font-size: ${fontSize.default};
  font-family: ${fontFamily};

  text-decoration: underline;
  font-weight: 600;
`
