import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

class OutsideClick extends PureComponent {
  componentDidMount() {
    document.body.addEventListener('click', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleClickOutside)
  }

  isFocused = false

  handleMouseEnter = () => {
    this.isFocused = true
  }

  handleMouseLeave = () => {
    this.isFocused = false
  }

  handleClickOutside = () => {
    const { onOutsideClick } = this.props

    if (_.isFunction(onOutsideClick) && !this.isFocused) onOutsideClick()
  }

  render() {
    const { children } = this.props
    return (
      <div
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {children}
      </div>
    )
  }
}

OutsideClick.propTypes = {
  onOutsideClick: PropTypes.func
}

export { OutsideClick }
