import * as Yup from 'yup'
import profileLimits from 'Configs/profileLimits'

import { urlRegex, isPhoneNumber } from 'Constants/regex'

export const ALLOWED_IMG_EXTENSIONS = ['.jpg', 'jpeg', '.png']
export const MAX_IMG_SIZE = 5242880
export const MAX_IMG_SIZE_STR = '5 MB'

const UrlSchema = Yup.string().matches(urlRegex)

export const getValidationSchema = (role, t) => {
  return Yup.object().shape({
    avatarB64: Yup.string()
      .nullable()
      .notRequired(),
    firstName: Yup.string().required(
      t('fields.firstName.validationErrors.required')
    ),
    lastName: Yup.string().required(
      t('fields.lastName.validationErrors.required')
    ),
    // birthdate: Yup.string().required(
    //   t('fields.birthdate.validationErrors.required')
    // ),
    email: Yup.string()
      .required()
      .matches(/@/, t('sign:validation.validEmail')),
    address: Yup.string().required(
      t('fields.address.validationErrors.required')
    ),
    // city: Yup.string().required(t('fields.city.validationErrors.required')),
    // state: Yup.string().required
    // (t('fields.state.validationErrors.required')),
    // zip: Yup.string().required
    // (t('fields.zipCode.validationErrors.required')),
    // country: Yup.string().required(
    //   t('fields.country.validationErrors.required')
    // ),
    isProvider: Yup.bool()
      .default(false)
      .required(),
    aboutYourself: Yup.string(),
    certificate: Yup.object().shape({
      base64Array: Yup.array().max(profileLimits.certificate[role])
    }),
    picsOfWork: Yup.object().shape({
      base64Array: Yup.array().max(profileLimits.picsOfWork[role])
    }),
    websiteLinks: Yup.array()
      .max(profileLimits.WEBSITE_LINKS_MAX_COUNT[role])
      .of(UrlSchema),
    videoLinks: Yup.array()
      .max(profileLimits.VIDEO_LINKS_MAX_COUNT[role])
      .of(UrlSchema),
    services: Yup.array().max(profileLimits.SERVICES_MAX_COUNT[role]),
    phone: Yup.string()
      .matches(
        isPhoneNumber,
        `${t('sign:WrongPhoneNumberFormat')}`
      )
      .required(`${t('fields.phoneNumber.validationErrors.required')}`)
  })
}
