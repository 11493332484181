export const settings = {
  autoplay: true,
  autoplaySpeed: 3000,
  infinite: true,
  speed: 500,
  dots: true,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1
}
