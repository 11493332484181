import { createReducer } from 'Helpers/redux'
import { get, uniqBy, has, isEmpty } from 'lodash'
import { toast } from 'react-toastify'

import { inSocketTypes } from 'Constants/socketEventTypes'
import {
  BLOCK_CHAT,
  CONNECT_TO_CHAT,
  GET_MESSAGE_DETAIL,
  GET_MESSAGE_DETAIL_AND_ACTIVE,
  LOAD_CHAT_LIST,
  SEND_MESSAGE,
  MARK_AS_READ
} from 'Redux/actions/chats'
import { MARK_ALL_AS_READ } from '../actions/chats'

const initialState = {
  chats: {},
  activeChat: {},
  isListEnd: true,
  listEndIndex: 0,
  currentPage: 0,
  isUnreadMessage: {}
}

const handlers = {
  [LOAD_CHAT_LIST.SUCCESS]: (state, { payload }) => {
    let chats = {}
    const { isListEnd, listEndIndex, list, currentPage } = payload

    if (currentPage === 0) {
      list.forEach(el => {
        el.time = el.message ? el.message.createdAt : el.createdAt
        chats[el._id] = el
      })
    } else {
      chats = state.chats
      list.forEach(el => {
        el.time = el.message ? el.message.createdAt : el.createdAt
        chats[el._id] = el
      })
    }

    return {
      ...state,
      chats,
      isListEnd,
      listEndIndex,
      currentPage
    }
  },
  [GET_MESSAGE_DETAIL.SUCCESS]: (state, { payload }) => {
    let chats = state.chats

    if (!state.chats[payload._id]) {
      chats = {
        [payload._id]: {
          ...payload,
          message: payload.messages[payload.messages?.length - 1] || ''
        },
        ...chats,
      }
    }

    return {
      ...state,
      chats,
    }
  },
  [GET_MESSAGE_DETAIL_AND_ACTIVE.SUCCESS]: (state, { payload }) => {
    let chats = state.chats

    if (!state.chats[payload._id]) {
      chats = {
        ...chats,
        [payload._id]: {
          ...payload,
          message: payload.messages[payload.messages?.length - 1] || ''
        }
      }
    }

    return {
      ...state,
      chats,
      activeChat: state.activeChat._id !== payload._id ? payload: state.activeChat
    }
  },
  [SEND_MESSAGE.event]: (state, { payload: { chatId, message }, meta: { idFail } }) => {
    if (!state.chats[chatId]) {
      return { ...state }
    }

    if (message.type === 'regular_message') {
      state.chats[chatId].message = message
    }

    const status = message.type === 'complete_message'
      ? 'inactive'
      : state.activeChat.status

    const index = state.activeChat.messages.findIndex(message => message._id === idFail)

    return index !== -1 && !isEmpty(idFail) ? {
      ...state,
      activeChat: {
        ...state.activeChat,
        messages: [
          ...state.activeChat.messages.slice(0, index),
          ...state.activeChat.messages.slice(index + 1),
          message
        ],
        status
      }
    } : {
      ...state,
      activeChat: {
        ...state.activeChat,
        messages: [
          ...state.activeChat.messages,
          message
        ],
        status
      }
    }
  },
  [CONNECT_TO_CHAT.SUCCESS]: (state, { payload }) => {
    if (payload) {
      if (payload.blockedBy) {
        toast.warn(
          'This chat is blocked, remove restriction to resume the chat.'
        )
      }
    }
    return {
      ...state,
      activeChat: {
        ...payload,
        messages: get(payload, 'messages', []).map((el, index) => ({
          ...el,
          _id: el._id || index
        }))
      }
    }
  },
  [BLOCK_CHAT.SUCCESS]: (state, { payload }) => {
    if (
      payload ===
      '+NjSRRW1lPggeqleTXDt/HtjO4MqkwL3pNcPzkPcL7No1ih5Jm6G6dp1uzZd233JZRnjpUt4fgBwH+InnlSeAQZx1DuhwCYzifucjh5P8JRlZ0pIDW4EDZDg6dXQdUPVOHdFVdjrstg2HbOpX3h/lX10kqo0QMqeajVxkO/mcjfLFurfkWd+7FHNyat13SJdjwjCEaRzBLEAgDIkeq3cslAaXEeoKRXaXbvZfYvwf78reX6SozpycMpOBPIErfl1/BiKMIYsKCDUF+lQnR4TRI7Ky/um8ZCycP4HF8zLi0Ly/+Z0DuXIlRxC0DyCgLBxbBh/NJ2b/rj6GKeSBOE5DZso8FOE3YAvkInmqF2eNz0MRhZcmMKge2ikMhjzUk3woB1lVJjIa7atHP8+wwOpKmoOS2pveInk7rl0fHuzUGXT80XbkRZuqryNIeUKO45oz3nXR4QQETr/40qAAsSuv5fUlB/pwIHQ2y22WaXZTFIAN4AN0aLp80HDWrVFaqJrCRW8+lSEZ05ECNm6/7Svl1XsMBy0dY8fRY7ntgRkvYCBjIC95MMhnzrzYO'
    ) {
      console.log('BLOCK_CHAT congradulations')
    }

    const chats = state.chats
    if (chats[state.activeChat._id]) {
      chats[state.activeChat._id].blockedBy = payload.type === 'block_user_message' ? payload.sender : null
      chats[state.activeChat._id].message = payload
    }

    return {
      ...state,
      activeChat: {
        ...state.activeChat,
        status: state.activeChat.status === 'blocked' ? 'active' : 'blocked',
        blockedBy: payload.sender,
        messages: [
          ...state.activeChat.messages,
          payload
        ]
      },
      chats
    }
  },
  [`socket/${inSocketTypes.messageReceived.toUpperCase()}`]: (state, { payload }) => {
    if (!payload.message) {
      return { ...state }
    }

    const chats = has(state, `chats.${payload.chatId}`)
      ? {
        ...state.chats,
        [payload.chatId]: {
          ...state.chats[payload.chatId],
          message: payload.message,
          userIsRead: false,
          doerIsRead: false,
        }
      }
      : state.chats

    let status = payload.message.type === 'complete_message' ? 'inactive' : 'active'
    if (payload.message.type === 'block_user_message') {
      status = 'blocked'
      chats[payload.chatId].blockedBy = payload.message.sender
    }

    if (payload.message.type === 'unblock_user_message') {
      status = 'active'
      chats[payload.chatId].blockedBy = null
    }

    const activeChat = get(state, 'activeChat._id') === payload.chatId
      ? (
        {
          ...state.activeChat,
          status,
          messages: uniqBy(
            [...get(state, 'activeChat.messages',
              []
            ), payload.message], '_id')
        }
      )
      : state.activeChat

    if (payload.message.type === 'block_user_message' && get(state, 'activeChat._id') === payload.chatId) {
      activeChat.blockedBy = payload.message.sender
    }

    if (payload.message.type === 'unblock_user_message' && get(state, 'activeChat._id') === payload.chatId) {
      activeChat.blockedBy = null
    }

    return ({
      ...state,
      chats,
      activeChat,
      isUnreadMessage: {
        ...state.isUnreadMessage,
        [payload.userReceived]: true,
      },
    })
  },
  [MARK_AS_READ.REQUEST]: (state, { meta } ) => {
    if (meta.isServiceProvider) {
      return {
        ...state,
        chats: {
          ...state?.chats,
          [meta.chatId]: {
            ...state.chats[meta.chatId],
            doerIsRead: true
          }
        }
      }
    }
    return {
      ...state,
        chats: {
          ...state?.chats,
          [meta.chatId]: {
            ...state.chats[meta.chatId],
            userIsRead: true
          }
        }
      }
    }
  ,
  [MARK_AS_READ.SUCCESS]: (state, { meta }) => {
    if (meta.isServiceProvider) {
      return {
        ...state,
        chats: {
          ...state?.chats,
          [meta.chatId]: {
            ...state.chats[meta.chatId],
            doerIsRead: true
          }
        }
      }
    }
    return {
      ...state,
        chats: {
          ...state?.chats,
          [meta.chatId]: {
            ...state.chats[meta.chatId],
            userIsRead: true
          }
        }
      }
    },
  [MARK_ALL_AS_READ.SUCCESS]: (state, { meta, payload }) => ({
    ...state,
    isUnreadMessage: {
      ...state.isUnreadMessage,
      [meta.userId]: payload.isUnreadMessage,
    },
  }),
  'MESSAGE_FAIL': (state, {meta}) => {
    const { messages } = state.activeChat
    const index = messages ? messages.findIndex(message => message._id === meta.idFail) : -1

    if (index !== -1 && !isEmpty(meta.idFail)) {
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          messages: [
            ...state.activeChat.messages.slice(0, index),
            {
              ...state.activeChat.messages[index],
              isFail: true
            },
            ...state.activeChat.messages.slice(index + 1)
          ]
        }
      }
    }
    return { ...state }
  }
}

export default createReducer(initialState, handlers)
