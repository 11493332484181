import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { sizes } from 'Constants'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    to bottom,
    #1e5799 0%,
    #2989d8 0%,
    #207cca 15%,
    #fff 100%
  );
  padding: 30px 0;
  b, p, h2 {
    font-weight: revert;
    margin: 0;
  }
  ul {
    list-style-type: '-    ';
    margin-left: 40px;
  }
`

export const Wrapper = styled.div`
  width: 70rem;
  max-width: 90%;
  margin: 0 auto;
`

export const Article = styled.article`
  width: 100%;
  background: #fff;
  padding: 30px;
  margin: 30px 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  line-height: 2;
`

export const InnerWrap = styled.div``

export const FooterModal = styled.div`
  padding-top: 1em;
  text-align: center;
`

export const AgreeLink = styled(Link)`
  text-decoration: none;
`

export const FloatingButtonBlock = styled.div`
  position: fixed;
  bottom: 12%;
  right: 4%;
  @media (max-width: ${sizes.phone}px) {
    bottom: 20%;
  }
`

export const FloatingButton = styled.div`
  justify-content: center;
  cursor: pointer;
  align-items: center;
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
  background: ${p => p.theme.colors.joinButton};
`

export const CustomLink = styled.a`
  text-decoration: ${p => p.isDefault ? 'none' : 'underline'};
  color: #000;
`