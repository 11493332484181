import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { GoogleApiWrapper } from 'google-maps-react'
import { Formik } from 'formik'
import _ from 'lodash'
import Modal from 'react-modal'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { gooogleApiKey, SERVICE_URL } from 'Constants'
import { LocationAutocomplete, Map } from 'Components/blocks'
import { PARSE_LOCATION_INFO } from 'Constants/parseLocationInfo'
import { findJobs } from 'Routes/routesConstants'
import { ButtonBlue } from 'Components/ui/Buttons'
import Service from './ServiceItem'
import {
  modalOverlayStyle,
  modalContentStyle,
  ServiceTitle,
  InputTitle,
  ModalInput,
  ServicesWrapper,
  HeaderTitle,
  HeaderClose,
  HeaderStep,
  ModalBody,
  ModalContainer,
  ModalHeader,
  ModalWrapper,
  ModalButtonWrapper,
  ModalBodyWrapper,
  SearchButton,
  SearchInput,
  HeaderBack,
  Form,
  InputShort,
  Row,
  InputWrapperRow,
  InputWrapper,
  Label,
  AutoWrapper,
  ModalButtonWrapperBottom,
  ModalButtonWrapperRight,
  ModalButtonWrapperLeft
} from './styles'

import { SubCategoryTitle } from './SubCategoryTitle'
import { getValidationSchema } from './config'

class ServicesModal extends Component {
  state = {
    firstStage: true,
    services: [],
    totalServices: [],
    profileServices: [],
    editId: null,
    search: null,
    subCategories: null,
    mainId: null,
    mainTitle: null,
    subCategoriesCounter: 0,
    profileServicesCounter: null,
    location: false,
    selectAll: -1,
    allServices: _.map(this.props.services.services, '_id')
  }
  

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.profileServices &&
      nextProps.profileServices !== prevState.profileServices
    ) {
      const temp = []
      nextProps.profileServices.length !== 0 &&
        nextProps.profileServices.forEach(service => {
          service.serviceId && temp.push(service.serviceId)
          temp.push(service.categoryId)
        })
      return {
        profileServices: nextProps.profileServices,
        services: [...new Set(temp)],
        profileServicesCounter: nextProps.profileServices.length
      }
    } else if (nextProps.editId && nextProps.editId !== prevState.editId) {
      const temp = []
      let isSubService = false
      let index = null
      let subcategoryCount = null
      nextProps.profileServices.forEach(service => {
        if (
          service.categoryId !== nextProps.editId &&
          service.serviceId !== nextProps.editId
        ) {
          service.serviceId && temp.push(service.serviceId)
          temp.push(service.categoryId)
        } else if (service.serviceId) {
          isSubService = true
          index = nextProps.services.services.findIndex(
            x => x._id === service.categoryId
          )
          nextProps.services.services[index].subservices.forEach(category => {
            if (prevState.services.indexOf(category._id) !== -1) {
              temp.push(service.categoryId)
              subcategoryCount += 1
            }
          })
        }
      })
      return {
        profileServices: nextProps.profileServices,
        services: [...new Set(temp)],
        profileServicesCounter: nextProps.profileServices.length - 1,
        editId: nextProps.editId,
        firstStage: !isSubService,
        subCategories:
          isSubService && nextProps.services.services[index].subservices,
        mainId: isSubService && nextProps.services.services[index]._id,
        mainTitle: isSubService && nextProps.services.services[index].title,
        subCategoriesCounter: isSubService && subcategoryCount - 1
      }
    }
    return null
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps !== this.props ||
      nextState.firstStage !== this.state.firstStage ||
      nextState.search !== this.state.search ||
      nextState.services !== this.state.services ||
      nextState.location !== this.state.location
    )
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(
        prevProps.initialValues.services,
        this.props.initialValues.services
      )
    ) {
      this.setState({ services: this.props.initialValues.services })
    }

    if (
      !_.isEqual(
        prevProps.initialValues.location,
        this.props.initialValues.location
      ) &&
      !_.isEmpty(this.props.initialValues.location)
    ) {
      const {
        initialValues: {
          location: { city, country, state, street, zip, address}
        }
      } = this.props

      // need to save currecnt location when filter is hidden
      // this.setState({ location })
      if (this.form)
        this.form.setValues({ city, country, state, street, zip, address })
    }
  }

  handleSearch = e => {
    this.setState({
      search: e.target.value.toLowerCase()
    })
  }

  checkStatus = id => {
    const i = this.state.profileServices.findIndex(x => x._id === id)
    if (i !== -1) {
      return this.state.profileServices[i].status
    } else {
      return 'active'
    }
  }

  prepareDataForProfilePage = () => {
    const data = []

    this.state.services.forEach(service => {
      const idForCheck = this.props.services.services.findIndex(
        x => x._id === service
      )
      if (
        idForCheck !== -1 &&
        this.props.services.services[idForCheck].subservices.length === 0
      ) {
        data.push({ categoryId: service, status: this.checkStatus(service) })
      } else {
        this.props.services.services.forEach(item => {
          if (item.subservices.length !== 0) {
            const parentId = item._id
            if (item.subservices.findIndex(x => x._id === service) !== -1) {
              data.push({
                categoryId: parentId,
                serviceId: service,
                status: this.checkStatus(service)
              })
            }
          }
        })
      }
    })

    return data
  }

  handleAddSelectedItems = () => {
    if (this.props.isProfilePage) {
      this.props.onRequestDone(this.prepareDataForProfilePage())
      this.setState({
        firstStage: true,
        editId: null
      })
    }
  }

  handleAddSubCategories = (
    id,
    caption,
    length,
    emptyCategory,
    iconName,
    orderKey,
    updatedAt,
    createdAt,
    title,
    subservices
  ) => {
    const { totalServices } = this.state
    if (caption) {
      this.props.maxItemsCount <= this.state.profileServicesCounter
        ? this.props.onMaxItemsOverflow()
        : this.setState(prevState => ({
            services: [...prevState.services, id],
            totalServices: !subservices.length
              ? [
                  ...prevState.totalServices,
                  {
                    iconName,
                    orderKey,
                    updatedAt,
                    _id: id,
                    createdAt,
                    subservices,
                    title
                  }
                ]
              : totalServices,
            subCategoriesCounter: prevState.subCategoriesCounter + 1,
            profileServicesCounter: prevState.profileServicesCounter + 1
          }))
    } else {
      this.setState(prevState => ({
        services: [
          ...prevState.services.slice(0, prevState.services.indexOf(id)),
          ...prevState.services.slice(prevState.services.indexOf(id) + 1)
        ],
        totalServices: totalServices.filter(item => item._id !== id),
        subCategoriesCounter: prevState.subCategoriesCounter - 1,
        profileServicesCounter: prevState.profileServicesCounter - 1
      }))
    }
  }

  handleRequestBack = () => {
    if (this.state.subCategoriesCounter === 0) {
      this.handleOnServiceItemClick(this.state.mainId, false, false, true)
      this.setState({
        firstStage: true
      })
    } else {
      this.setState({
        firstStage: true
      })
    }
  }

  countSubCategories = subCategories => {
    let count = 0
    subCategories.forEach(
      category => this.state.services.indexOf(category._id) !== -1 && count++
    )
    return count
  }

  handleSubCategories = (subCategories, mainId, mainTitle) => {
    this.setState({
      firstStage: false,
      subCategoriesCounter: this.countSubCategories(subCategories),
      subCategories,
      mainId,
      mainTitle
    })
  }

  handleOnServiceItemClick = (
    id,
    caption,
    length,
    emptyCategory,
    iconName,
    orderKey,
    updatedAt,
    createdAt,
    title,
    subservices
  ) => {
    const { totalServices } = this.state
    if (caption) {
      this.props.maxItemsCount <= this.state.profileServicesCounter
        ? this.props.onMaxItemsOverflow()
        : this.setState(prevState => ({
            services: [...prevState.services, id],
            totalServices: !subservices.length
              ? [
                  ...prevState.totalServices,
                  {
                    iconName,
                    orderKey,
                    updatedAt,
                    _id: id,
                    createdAt,
                    subservices,
                    title
                  }
                ]
              : totalServices,
            profileServicesCounter:
              (!length && prevState.profileServicesCounter + 1) ||
              prevState.profileServicesCounter
          }))
    } else if (!length) {
      let tempServices = []
      let tempTotalServices = []
      if (this.state.services.indexOf(id) !== -1) {
        tempServices = [
          ...this.state.services.slice(0, this.state.services.indexOf(id)),
          ...this.state.services.slice(this.state.services.indexOf(id) + 1)
        ]
        tempTotalServices = totalServices.filter(item => item._id !== id)
      } else {
        tempServices = this.state.services
        tempTotalServices = totalServices
      }
      this.setState(prevState => ({
        services: tempServices,
        totalServices: tempTotalServices,
        profileServicesCounter:
          (emptyCategory && prevState.profileServicesCounter) ||
          (prevState.profileServicesCounter
            ? prevState.profileServicesCounter - 1
            : 0)
      }))
    }
  }

  handleAddressChanged = (
    locationInfo,
    setFieldValue,
    fromMapClick = false
  ) => {
    const { address_components, geometry } = locationInfo
    PARSE_LOCATION_INFO.forEach(infoType => {
      const info = _.find(
        address_components,
        item => item.types.indexOf(infoType.searchForType) > -1
      )
      if (info) {
        setFieldValue(infoType.putToField, infoType.pickField(info))
      } else {
        setFieldValue(infoType.putToField, '')
      }
    })

    const location = _.get(geometry, 'location', {})

    if (!fromMapClick && location.lat && location.lng) {
      this.setState({
        location: `${location.lat()}/${location.lng()}`
      })
    } else if (!fromMapClick) {
      this.setState({
        location: ''
      })
    }

    let address = locationInfo.formatted_address

    const route = _.find(
      address_components,
      item => item.types.indexOf('route') > -1
    )

    if (route && route.long_name) {
      address = route.long_name

      const streetNumber = _.find(
        address_components,
        item => item.types.indexOf('street_number') > -1
      )

      if (streetNumber && streetNumber.long_name) {
        address += `, ${streetNumber.long_name}`
      }
    }
    setFieldValue('address', address)
  }

  handleMapClick = (t, map, coord, setFieldValue) => {
    const { latLng } = coord
    this.setState({
      location: `${latLng.lat()}/${latLng.lng()}`
    })
    this.props.onGetGeo(latLng.lat(), latLng.lng(), (error, response) => {
      this.handleAddressChanged(response.results[0], setFieldValue, true)
    })
  }

  clearRequest = () => {
    this.setState({
      firstStage: true,
      services: [],
      profileServices: [],
      editId: null,
      search: null,
      subCategories: null,
      mainId: null,
      mainTitle: null,
      subCategoriesCounter: 0,
      profileServicesCounter: null
    })
  }

  handleSelectAll = () => {
    const { selectAll, allServices } = this.state
    this.setState({
      selectAll: selectAll === -1 ? 0 : -1,
      services: selectAll === -1 ? allServices : []
    })
  }

  handleSubmit = data => {
    if (this.props.main) {
      const filter = {
        location: this.state.location || null,
        categories: this.state.services
      }
      this.props.history.push(findJobs, { filter })
    } else {
      let response = {}
      if (_.find(data, el => el) || this.state.services.length !== 0) {
        response = {
          location: this.state.location && this.state.location,
          locationFields: data,
          services: this.state.services
        }
      }

      //
      // let tempResponse = {}

      // if (!this.state.firstStage && this.state.subCategoriesCounter === 0) {
      //   tempResponse = [
      //     ...totalServices,
      //     { _id: mainId, subservices: subCategories }
      //   ]
      //   this.setState({ totalServices: [...tempResponse] })
      //   // tempResponse = subCategories
      // } else {
      //   tempResponse = [...totalServices]
      // }

      this.props.onRequestDone(response)
      this.props.onRequestClose()
    }
  }

  handleClearFilters = () => {
    this.form.setValues({
      city: '',
      country: '',
      state: '',
      street: '',
      zip: '',
      address: ''
    })
    this.clearRequest()
  }

  renderForm = ({
    handleChange,
    handleBlur,
    values: valuesList,
    touched: touchedList,
    errors: errorsList,
    submitCount,
    handleSubmit,
    setFieldValue
  }) => {
    const { t } = this.props
    const { location } = this.state

    const errors = errorsList || {}
    const values = valuesList || {}
    const touched = touchedList || {}
    const isFormTouched = submitCount > 0

    return (
      <Form onSubmit={handleSubmit}>
        <InputWrapperRow>
          <AutoWrapper>
            <Label>{t('fields.address')}</Label>
            <LocationAutocomplete
              InputComponent={ModalInput}
              inputProps={{
                errors: errors.address,
                label: t('fields.address'),
                mr: '15px',
                name: 'address',
                width: '100%',
                touched: touched.address || isFormTouched,
                onBlur: handleBlur
              }}
              modal={true}
              setFieldValue={setFieldValue}
              value={values.address}
              onChange={text => setFieldValue('address', text)}
              onSelect={data => this.handleAddressChanged(data, setFieldValue)}
            />
          </AutoWrapper>
        </InputWrapperRow>
        <InputWrapperRow>
          <InputWrapper>
            <Label>{t('fields.city')}</Label>
            <InputShort
              autoComplete="none"
              data-lpignore="true"
              errors={errors.city}
              name="city"
              touched={touched.city || isFormTouched}
              value={values.city}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputWrapper>
          <InputWrapper>
            <Label>{t('fields.state')}</Label>
            <InputShort
              autoComplete="none"
              data-lpignore="true"
              errors={errors.state}
              name="state"
              touched={touched.state || isFormTouched}
              value={values.state}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputWrapper>
          <InputWrapper>
            <Label>{t('fields.zipCode')}</Label>
            <InputShort
              autoComplete="none"
              data-lpignore="true"
              errors={errors.zip}
              name="zip"
              touched={touched.zip || isFormTouched}
              value={values.zip}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputWrapper>
          <InputWrapper>
            <Label>{t('fields.country')}</Label>
            <InputShort
              autoComplete="none"
              data-lpignore="true"
              errors={errors.country}
              name="country"
              touched={touched.country || isFormTouched}
              value={values.country}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputWrapper>
        </InputWrapperRow>
        <Row>
          <Map
            google={this.props.google}
            location={location}
            marker
            onHandleClick={(t, map, coord) =>
              this.handleMapClick(t, map, coord, setFieldValue)
            }
          />
        </Row>
        <ModalButtonWrapperBottom>
          <ModalButtonWrapperLeft>
            <ButtonBlue type="button" onClick={this.handleClearFilters}>
              {t('clearAll')}
            </ButtonBlue>
          </ModalButtonWrapperLeft>
          <ModalButtonWrapperRight>
            <ButtonBlue type="submit">
              {!this.state.firstStage && this.state.subCategoriesCounter === 0
                ? t('skip')
                : t('applyFilters')}
            </ButtonBlue>
          </ModalButtonWrapperRight>
        </ModalButtonWrapperBottom>
      </Form>
    )
  }

  render() {
    const {
      t,
      isOpen,
      onRequestClose,
      services,
      isProfilePage,
      initialValues
    } = this.props
    const { firstStage, subCategories, mainTitle, search, editId } = this.state
    const apiServices = services.services || []
    const apiPopularServices = services.popularServices || []

    return (
      <Modal
        appElement={document.getElementById('root')}
        isOpen={isOpen}
        style={{
          overlay: modalOverlayStyle,
          content: modalContentStyle
        }}
        onRequestClose={() => {
          this.clearRequest()
          onRequestClose()
        }}
      >
        <ModalContainer>
          <ModalWrapper>
            {isProfilePage && firstStage && (
              <ModalHeader>
                <HeaderBack>
                  <HeaderStep>{t('stepServices')} 1/2</HeaderStep>
                </HeaderBack>
                <HeaderTitle>{t('addServices')}</HeaderTitle>
                <HeaderClose
                  onClick={() => {
                    this.clearRequest()
                    onRequestClose()
                  }}
                >
                  &#x2715;
                </HeaderClose>
              </ModalHeader>
            )}
            {isProfilePage && !firstStage && (
              <ModalHeader>
                <HeaderBack onClick={() => this.handleRequestBack()}>
                  <FontAwesomeIcon icon={faChevronLeft} />
                  <HeaderStep>{t('stepServices')} 2/2</HeaderStep>
                </HeaderBack>
                <HeaderTitle>{t('addServices')}</HeaderTitle>
                <HeaderClose
                  onClick={() => {
                    this.clearRequest()
                    onRequestClose()
                  }}
                >
                  &#x2715;
                </HeaderClose>
              </ModalHeader>
            )}
            {!isProfilePage && (
              <ModalHeader>
                {!firstStage && (
                  <HeaderBack onClick={() => this.handleRequestBack()}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </HeaderBack>
                )}
                <HeaderTitle>{t('filter')}</HeaderTitle>

                <HeaderClose
                  onClick={() => {
                    this.clearRequest()
                    onRequestClose()
                  }}
                >
                  &#x2715;
                </HeaderClose>
              </ModalHeader>
            )}
            <ModalBody>
              {!isProfilePage && (
                <Formik
                  initialValues={{
                    city:
                      (!_.isEmpty(initialValues.adress) &&
                        initialValues.adress.city) ||
                      '',
                    country:
                      (!_.isEmpty(initialValues.adress) &&
                        initialValues.adress.country) ||
                      '',
                    state:
                      (!_.isEmpty(initialValues.adress) &&
                        initialValues.adress.state) ||
                      '',
                    street:
                      (!_.isEmpty(initialValues.adress) &&
                        initialValues.adress.street) ||
                      '',
                    zip:
                      (!_.isEmpty(initialValues.adress) &&
                        initialValues.adress.zip) ||
                      '',
                    address:
                      (!_.isEmpty(initialValues.adress) &&
                        initialValues.adress.address) ||
                      ''
                  }}
                  ref={ref => (this.form = ref)}
                  render={this.renderForm}
                  validationSchema={getValidationSchema(t)}
                  onSubmit={this.handleSubmit}
                />
              )}
              {firstStage ? (
                <ModalBodyWrapper>
                  <InputTitle>{t('selectCategory')}</InputTitle>
                  <SearchInput
                    value={search}
                    onChange={e => this.handleSearch(e)}
                  >
                    <SearchButton>&#9906;</SearchButton>
                    <ModalInput />
                  </SearchInput>
                  <ServiceTitle bold>{t('popularCategories')}</ServiceTitle>
                  <ServicesWrapper>
                    {_.orderBy(apiPopularServices, 'popularity', 'desc').map(
                      (service, i) => (
                        <Service
                          editId={editId}
                          filter={search}
                          isSelected={this.state.services.indexOf(service._id)}
                          key={i}
                          url={SERVICE_URL}
                          {...service}
                          onButtonClick={this.handleOnServiceItemClick}
                          onHandleSubCategories={this.handleSubCategories}
                        />
                      )
                    )}
                  </ServicesWrapper>
                  <ServiceTitle bold>{t('allCategories')}</ServiceTitle>
                  <ServicesWrapper>
                    {!isProfilePage && (
                      <Service
                        editId={editId}
                        filter={search}
                        iconName="All.png"
                        isSelected={this.state.selectAll}
                        title="All"
                        url={SERVICE_URL}
                        onButtonClick={this.handleSelectAll}
                      />
                    )}
                    {_.orderBy(apiServices, 'orderKey', 'asc').map(
                      (service, i) => (
                        <Service
                          editId={editId}
                          filter={search}
                          isSelected={this.state.services.indexOf(service._id)}
                          key={i}
                          url={SERVICE_URL}
                          {...service}
                          onButtonClick={this.handleOnServiceItemClick}
                          onHandleSubCategories={this.handleSubCategories}
                        />
                      )
                    )}
                  </ServicesWrapper>
                  {!isProfilePage && (<div style={{ paddingBottom: 30 }}></div>)}
                  {isProfilePage && (
                    <ModalButtonWrapper>
                      <ButtonBlue
                        disabled={this.state.services.length === 0}
                        onClick={this.handleAddSelectedItems}
                      >
                        {t('save')}
                      </ButtonBlue>
                    </ModalButtonWrapper>
                  )}
                </ModalBodyWrapper>
              ) : (
                <ModalBodyWrapper>
                  <ServiceTitle bold>
                    {t('selectSubCategory')}{' '}
                    <SubCategoryTitle title={mainTitle} />
                  </ServiceTitle>
                  <ServicesWrapper mb={45}>
                    {_.orderBy(subCategories, 'orderKey', 'asc').map(
                      (service, i) => (
                        <Service
                          editId={editId}
                          isSelected={this.state.services.indexOf(service._id)}
                          key={i}
                          url={SERVICE_URL}
                          {...service}
                          onButtonClick={this.handleAddSubCategories}
                        />
                      )
                    )}
                    {isProfilePage && (
                      <ModalButtonWrapper>
                        {this.state.firstStage ||
                          (!this.state.firstStage &&
                            (this.state.subCategoriesCounter !== 0 ||
                              this.state.editId) && (
                              <ButtonBlue
                                disabled={this.state.services.length === 0}
                                onClick={this.handleAddSelectedItems}
                              >
                                {t('save')}
                              </ButtonBlue>
                            ))}
                      </ModalButtonWrapper>
                    )}
                  </ServicesWrapper>
                </ModalBodyWrapper>
              )}
            </ModalBody>
          </ModalWrapper>
        </ModalContainer>
      </Modal>
    )
  }
}

ServicesModal.propTypes = {
  initialValues: PropTypes.shape({
    services: PropTypes.array, // services ids
    location: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
      state: PropTypes.string,
      street: PropTypes.string,
      zip: PropTypes.string,
      address: PropTypes.string
    })
  })
}

ServicesModal.defaultProps = {
  initialValues: {
    services: [],
    location: {}
  }
}

export default compose(
  withNamespaces('landingPage'),
  GoogleApiWrapper({
    apiKey: gooogleApiKey,
    libraries: ['places']
  }),
  withRouter
)(ServicesModal)

export { ServicesModal }