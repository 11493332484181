import flags from 'Assets/lang'

const PHONES = [
  {country:'Afghanistan',phone:'+93',code:'AF / AFG',flag:flags.afghanistan},
  {country:'Albania',phone:'+355',code:'AL / ALB',flag:flags.albania},
  {country:'Algeria',phone:'+213',code:'DZ / DZA',flag:flags.algeria},
  {country:'American Samoa',phone:'+1-684',code:'AS / ASM',flag:flags.americanSamoa},
  {country:'Andorra',phone:'+376',code:'AD / AND',flag:flags.andorra},
  {country:'Angola',phone:'+244',code:'AO / AGO',flag:flags.angola},
  {country:'Anguilla',phone:'+1-264',code:'AI / AIA',flag:flags.anguilla},
  {country:'Antarctica',phone:'+672',code:'AQ / ATA',flag:flags.antarctica},
  {country:'Antigua and Barbuda',phone:'+1-268',code:'AG / ATG',flag:flags.antiguaAndBarbuda},
  {country:'Argentina',phone:'+54',code:'AR / ARG',flag:flags.argentina},
  {country:'Armenia',phone:'+374',code:'AM / ARM',flag:flags.armenia},
  {country:'Aruba',phone:'+297',code:'AW / ABW',flag:flags.aruba},
  {country:'Australia',phone:'+61',code:'AU / AUS',flag:flags.australia},
  {country:'Austria',phone:'+43',code:'AT / AUT',flag:flags.austria},
  {country:'Azerbaijan',phone:'+994',code:'AZ / AZE',flag:flags.azerbaijan},
  {country:'Bahamas',phone:'+1-242',code:'BS / BHS',flag:flags.bahamas},
  {country:'Bahrain',phone:'+973',code:'BH / BHR',flag:flags.bahrain},
  {country:'Bangladesh',phone:'+880',code:'BD / BGD',flag:flags.bangladesh},
  {country:'Barbados',phone:'+1-246',code:'BB / BRB',flag:flags.barbados},
  {country:'Belarus',phone:'+375',code:'BY / BLR',flag:flags.belarus},
  {country:'Belgium',phone:'+32',code:'BE / BEL',flag:flags.belgium},
  {country:'Belize',phone:'+501',code:'BZ / BLZ',flag:flags.belize},
  {country:'Benin',phone:'+229',code:'BJ / BEN',flag:flags.benin},
  {country:'Bermuda',phone:'+1-441',code:'BM / BMU',flag:flags.bermuda},
  {country:'Bhutan',phone:'+975',code:'BT / BTN',flag:flags.bhutan},
  {country:'Bolivia',phone:'+591',code:'BO / BOL',flag:flags.bolivia},
  {country:'Bosnia and Herzegovina',phone:'+387',code:'BA / BIH',flag:flags.bosniaAndHerzegovina},
  {country:'Botswana',phone:'+267',code:'BW / BWA',flag:flags.botswana},
  {country:'Brazil',phone:'+55',code:'BR / BRA',flag:flags.brazil},
  {country:'British Indian Ocean Territory',phone:'+246',code:'IO / IOT',flag:flags.britishIndianOceanTerritory},
  {country:'British Virgin Islands',phone:'+1-284',code:'VG / VGB',flag:flags.britishVirginIslands},
  {country:'Brunei',phone:'+673',code:'BN / BRN',flag:flags.brunei},
  {country:'Bulgaria',phone:'+359',code:'BG / BGR',flag:flags.bulgaria},
  {country:'Burkina Faso',phone:'+226',code:'BF / BFA',flag:flags.burkinaFaso},
  {country:'Burundi',phone:'+257',code:'BI / BDI',flag:flags.burundi},
  {country:'Cambodia',phone:'+855',code:'KH / KHM',flag:flags.cambodia},
  {country:'Cameroon',phone:'+237',code:'CM / CMR',flag:flags.cameroon},
  {country:'Canada',phone:'+1',code:'CA / CAN',flag:flags.canada},
  {country:'Cape Verde',phone:'+238',code:'CV / CPV',flag:flags.capeVerde},
  {country:'Cayman Islands',phone:'+1-345',code:'KY / CYM',flag:flags.caymanIslands},
  {country:'Central African Republic',phone:'+236',code:'CF / CAF',flag:flags.centralAfricanRepublic},
  {country:'Chad',phone:'+235',code:'TD / TCD',flag:flags.chad},
  {country:'Chile',phone:'+56',code:'CL / CHL',flag:flags.chile},
  {country:'China',phone:'+86',code:'CN / CHN',flag:flags.china},
  {country:'Christmas Island',phone:'+61',code:'CX / CXR',flag:flags.christmasIsland},
  {country:'Cocos Islands',phone:'+61',code:'CC / CCK',flag:flags.cocosIsland},
  {country:'Colombia',phone:'+57',code:'CO / COL',flag:flags.colombia},
  {country:'Comoros',phone:'+269',code:'KM / COM',flag:flags.comoros},
  {country:'Cook Islands',phone:'+682',code:'CK / COK',flag:flags.cookIslands},
  {country:'Costa Rica',phone:'+506',code:'CR / CRI',flag:flags.costaRica},
  {country:'Croatia',phone:'+385',code:'HR / HRV',flag:flags.croatia},
  {country:'Cuba',phone:'+53',code:'CU / CUB',flag:flags.cuba},
  {country:'Curacao',phone:'+599',code:'CW / CUW',flag:flags.curacao},
  {country:'Cyprus',phone:'+357',code:'CY / CYP',flag:flags.cyprus},
  {country:'Czech Republic',phone:'+420',code:'CZ / CZE',flag:flags.czechRepublic},
  {country:'Democratic Republic of the Congo',phone:'+243',code:'CD / COD',flag:flags.democraticRepublicOfCongo},
  {country:'Denmark',phone:'+45',code:'DK / DNK',flag:flags.denmark},
  {country:'Djibouti',phone:'+253',code:'DJ / DJI',flag:flags.djibouti},
  {country:'Dominica',phone:'+1-767',code:'DM / DMA',flag:flags.dominica},
  {country:'Dominican Republic',phone:'+1-809',code:'DO / DOM',flag:flags.dominicanRepublic},
  {country:'Dominican Republic',phone:'+1-829',code:'DO / DOM',flag:flags.dominicanRepublic},
  {country:'Dominican Republic',phone:'+1-849',code:'DO / DOM',flag:flags.dominicanRepublic},
  {country:'East Timor',phone:'+670',code:'TL / TLS',flag:flags.eastTimor},
  {country:'Ecuador',phone:'+593',code:'EC / ECU',flag:flags.ecuador},
  {country:'Egypt',phone:'+20',code:'EG / EGY',flag:flags.egypt},
  {country:'El Salvador',phone:'+503',code:'SV / SLV',flag:flags.elSalvador},
  {country:'Equatorial Guinea',phone:'+240',code:'GQ / GNQ',flag:flags.equatorialGuinea},
  {country:'Eritrea',phone:'+291',code:'ER / ERI',flag:flags.eritrea},
  {country:'Estonia',phone:'+372',code:'EE / EST',flag:flags.estonia},
  {country:'Ethiopia',phone:'+251',code:'ET / ETH',flag:flags.ethiopia},
  {country:'Falkland Islands',phone:'+500',code:'FK / FLK',flag:flags.falklandIslands},
  {country:'Faroe Islands',phone:'+298',code:'FO / FRO',flag:flags.faroeIslands},
  {country:'Fiji',phone:'+679',code:'FJ / FJI',flag:flags.fiji},
  {country:'Finland',phone:'+358',code:'FI / FIN',flag:flags.finland},
  {country:'France',phone:'+33',code:'FR / FRA',flag:flags.france},
  {country:'French Polynesia',phone:'+689',code:'PF / PYF',flag:flags.frenchPolynesia},
  {country:'Gabon',phone:'+241',code:'GA / GAB',flag:flags.gabon},
  {country:'Gambia',phone:'+220',code:'GM / GMB',flag:flags.gambia},
  {country:'Georgia',phone:'+995',code:'GE / GEO',flag:flags.georgia},
  {country:'Germany',phone:'+49',code:'DE / DEU',flag:flags.germany},
  {country:'Ghana',phone:'+233',code:'GH / GHA',flag:flags.ghana},
  {country:'Gibraltar',phone:'+350',code:'GI / GIB',flag:flags.gibraltar},
  {country:'Greece',phone:'+30',code:'GR / GRC',flag:flags.greece},
  {country:'Greenland',phone:'+299',code:'GL / GRL',flag:flags.greenland},
  {country:'Grenada',phone:'+1-473',code:'GD / GRD',flag:flags.grenada},
  {country:'Guam',phone:'+1-671',code:'GU / GUM',flag:flags.guam},
  {country:'Guatemala',phone:'+502',code:'GT / GTM',flag:flags.guatemala},
  {country:'Guernsey',phone:'+44-1481',code:'GG / GGY',flag:flags.guernsey},
  {country:'Guinea',phone:'+224',code:'GN / GIN',flag:flags.guinea},
  {country:'Guinea-Bissau',phone:'+245',code:'GW / GNB',flag:flags.guineaBissau},
  {country:'Guyana',phone:'+592',code:'GY / GUY',flag:flags.guyana},
  {country:'Haiti',phone:'+509',code:'HT / HTI',flag:flags.haiti},
  {country:'Honduras',phone:'+504',code:'HN / HND',flag:flags.honduras},
  {country:'Hong Kong',phone:'+852',code:'HK / HKG',flag:flags.hongKong},
  {country:'Hungary',phone:'+36',code:'HU / HUN',flag:flags.hungary},
  {country:'Iceland',phone:'+354',code:'IS / ISL',flag:flags.iceland},
  {country:'India',phone:'+91',code:'IN / IND',flag:flags.india},
  {country:'Indonesia',phone:'+62',code:'ID / IDN',flag:flags.indonesia},
  {country:'Iran',phone:'+98',code:'IR / IRN',flag:flags.iran},
  {country:'Iraq',phone:'+964',code:'IQ / IRQ',flag:flags.iraq},
  {country:'Ireland',phone:'+353',code:'IE / IRL',flag:flags.ireland},
  {country:'Isle of Man',phone:'+44-1624',code:'IM / IMN',flag:flags.isleOfMan},
  {country:'Israel',phone:'+972',code:'IL / ISR',flag:flags.israel},
  {country:'Italy',phone:'+39',code:'IT / ITA',flag:flags.italy},
  {country:'Ivory Coast',phone:'+225',code:'CI / CIV',flag:flags.ivoryCoast},
  {country:'Jamaica',phone:'+1-876',code:'JM / JAM',flag:flags.jamaica},
  {country:'Japan',phone:'+81',code:'JP / JPN',flag:flags.japan},
  {country:'Jersey',phone:'+44-1534',code:'JE / JEY',flag:flags.jersey},
  {country:'Jordan',phone:'+962',code:'JO / JOR',flag:flags.jordan},
  {country:'Kazakhstan',phone:'+7',code:'KZ / KAZ',flag:flags.kazakhstan},
  {country:'Kenya',phone:'+254',code:'KE / KEN',flag:flags.kenya},
  {country:'Kiribati',phone:'+686',code:'KI / KIR',flag:flags.kiribati},
  {country:'Kosovo',phone:'+383',code:'XK / XKX',flag:flags.kosovo},
  {country:'Kuwait',phone:'+965',code:'KW / KWT',flag:flags.kuwait},
  {country:'Kyrgyzstan',phone:'+996',code:'KG / KGZ',flag:flags.kyrgyzstan},
  {country:'Laos',phone:'+856',code:'LA / LAO',flag:flags.laos},
  {country:'Latvia',phone:'+371',code:'LV / LVA',flag:flags.latvia},
  {country:'Lebanon',phone:'+961',code:'LB / LBN',flag:flags.lebanon},
  {country:'Lesotho',phone:'+266',code:'LS / LSO',flag:flags.lesotho},
  {country:'Liberia',phone:'+231',code:'LR / LBR',flag:flags.liberia},
  {country:'Libya',phone:'+218',code:'LY / LBY',flag:flags.libya},
  {country:'Liechtenstein',phone:'+423',code:'LI / LIE',flag:flags.liechtenstein},
  {country:'Lithuania',phone:'+370',code:'LT / LTU',flag:flags.lithuania},
  {country:'Luxembourg',phone:'+352',code:'LU / LUX',flag:flags.luxembourg},
  {country:'Macau',phone:'+853',code:'MO / MAC',flag:flags.macau},
  {country:'Macedonia',phone:'+389',code:'MK / MKD',flag:flags.macedonia},
  {country:'Madagascar',phone:'+261',code:'MG / MDG',flag:flags.madagascar},
  {country:'Malawi',phone:'+265',code:'MW / MWI',flag:flags.malawi},
  {country:'Malaysia',phone:'+60',code:'MY / MYS',flag:flags.malaysia},
  {country:'Maldives',phone:'+960',code:'MV / MDV',flag:flags.maldives},
  {country:'Mali',phone:'+223',code:'ML / MLI',flag:flags.mali},
  {country:'Malta',phone:'+356',code:'MT / MLT',flag:flags.malta},
  {country:'Marshall Islands',phone:'+692',code:'MH / MHL',flag:flags.marshallIslands},
  {country:'Mauritania',phone:'+222',code:'MR / MRT',flag:flags.mauritania},
  {country:'Mauritius',phone:'+230',code:'MU / MUS',flag:flags.mauritius},
  {country:'Mayotte',phone:'+262',code:'YT / MYT',flag:flags.mayotte},
  {country:'Mexico',phone:'+52',code:'MX / MEX',flag:flags.mexico},
  {country:'Micronesia',phone:'+691',code:'FM / FSM',flag:flags.micronesia},
  {country:'Moldova',phone:'+373',code:'MD / MDA',flag:flags.moldova},
  {country:'Monaco',phone:'+377',code:'MC / MCO',flag:flags.monaco},
  {country:'Mongolia',phone:'+976',code:'MN / MNG',flag:flags.mongolia},
  {country:'Montenegro',phone:'+382',code:'ME / MNE',flag:flags.montenegro},
  {country:'Montserrat',phone:'+1-664',code:'MS / MSR',flag:flags.montserrat},
  {country:'Morocco',phone:'+212',code:'MA / MAR',flag:flags.morocco},
  {country:'Mozambique',phone:'+258',code:'MZ / MOZ',flag:flags.mozambique},
  {country:'Myanmar',phone:'+95',code:'MM / MMR',flag:flags.myanmar},
  {country:'Namibia',phone:'+264',code:'NA / NAM',flag:flags.namibia},
  {country:'Nauru',phone:'+674',code:'NR / NRU',flag:flags.nauru},
  {country:'Nepal',phone:'+977',code:'NP / NPL',flag:flags.nepal},
  {country:'Netherlands',phone:'+31',code:'NL / NLD',flag:flags.netherlands},
  {country:'Netherlands Antilles',phone:'+599',code:'AN / ANT',flag:flags.netherlandsAntilles},
  {country:'New Caledonia',phone:'+687',code:'NC / NCL',flag:flags.newCaledonia},
  {country:'New Zealand',phone:'+64',code:'NZ / NZL',flag:flags.newZealand},
  {country:'Nicaragua',phone:'+505',code:'NI / NIC',flag:flags.nicaragua},
  {country:'Niger',phone:'+227',code:'NE / NER',flag:flags.niger},
  {country:'Nigeria',phone:'+234',code:'NG / NGA',flag:flags.nigeria},
  {country:'Niue',phone:'+683',code:'NU / NIU',flag:flags.niue},
  {country:'North Korea',phone:'+850',code:'KP / PRK',flag:flags.northKorea},
  {country:'Northern Mariana Islands',phone:'+1-670',code:'MP / MNP',flag:flags.northernMarianasIslands},
  {country:'Norway',phone:'+47',code:'NO / NOR',flag:flags.norway},
  {country:'Oman',phone:'+968',code:'OM / OMN',flag:flags.oman},
  {country:'Pakistan',phone:'+92',code:'PK / PAK',flag:flags.pakistan},
  {country:'Palau',phone:'+680',code:'PW / PLW',flag:flags.palau},
  {country:'Palestine',phone:'+970',code:'PS / PSE',flag:flags.palestine},
  {country:'Panama',phone:'+507',code:'PA / PAN',flag:flags.panama},
  {country:'Papua New Guinea',phone:'+675',code:'PG / PNG',flag:flags.papuaNewGuinea},
  {country:'Paraguay',phone:'+595',code:'PY / PRY',flag:flags.paraguay},
  {country:'Peru',phone:'+51',code:'PE / PER',flag:flags.peru},
  {country:'Philippines',phone:'+63',code:'PH / PHL',flag:flags.philippines},
  {country:'Pitcairn',phone:'+64',code:'PN / PCN',flag:flags.pitcairnIslands},
  {country:'Poland',phone:'+48',code:'PL / POL',flag:flags.poland},
  {country:'Portugal',phone:'+351',code:'PT / PRT',flag:flags.portugal},
  {country:'Puerto Rico',phone:'+1-787',code:'PR / PRI',flag:flags.puertoRico},
  {country:'Puerto Rico',phone:'+1-939',code:'PR / PRI',flag:flags.puertoRico},
  {country:'Qatar',phone:'+974',code:'QA / QAT',flag:flags.qatar},
  {country:'Republic of the Congo',phone:'+242',code:'CG / COG',flag:flags.republicOfTheCongo},
  {country:'Reunion',phone:'+262',code:'RE / REU',flag:flags.reunion},
  {country:'Romania',phone:'+40',code:'RO / ROU',flag:flags.romania},
  {country:'Russia',phone:'+7',code:'RU / RUS',flag:flags.russia},
  {country:'Rwanda',phone:'+250',code:'RW / RWA',flag:flags.rwanda},
  {country:'Saint Barthelemy',phone:'+590',code:'BL / BLM',flag:flags.saintBarthelemy},
  {country:'Saint Helena',phone:'+290',code:'SH / SHN',flag:flags.saintHelena},
  {country:'Saint Kitts and Nevis',phone:'+1-869',code:'KN / KNA',flag:flags.saintKittsAndNevis},
  {country:'Saint Lucia',phone:'+1-758',code:'LC / LCA',flag:flags.saintLucia},
  {country:'Saint Martin',phone:'+590',code:'MF / MAF',flag:flags.saintMartin},
  {country:'Saint Pierre and Miquelon',phone:'+508',code:'PM / SPM',flag:flags.saintPierreAndMiquelon},
  {country:'Saint Vincent and the Grenadines',phone:'+1-784',code:'VC / VCT',flag:flags.stVincentAndTheGrenadines},
  {country:'Samoa',phone:'+685',code:'WS / WSM',flag:flags.samoa},
  {country:'San Marino',phone:'+378',code:'SM / SMR',flag:flags.sanMarino},
  {country:'Sao Tome and Principe',phone:'+239',code:'ST / STP',flag:flags.saoTomeAndPrincipe},
  {country:'Saudi Arabia',phone:'+966',code:'SA / SAU',flag:flags.saudiArabia},
  {country:'Senegal',phone:'+221',code:'SN / SEN',flag:flags.senegal},
  {country:'Serbia',phone:'+381',code:'RS / SRB',flag:flags.serbia},
  {country:'Seychelles',phone:'+248',code:'SC / SYC',flag:flags.seychelles},
  {country:'Sierra Leone',phone:'+232',code:'SL / SLE',flag:flags.sierraLeone},
  {country:'Singapore',phone:'+65',code:'SG / SGP',flag:flags.singapore},
  {country:'Sint Maarten',phone:'+1-721',code:'SX / SXM',flag:flags.sintMaarten},
  {country:'Slovakia',phone:'+421',code:'SK / SVK',flag:flags.slovakia},
  {country:'Slovenia',phone:'+386',code:'SI / SVN',flag:flags.slovenia},
  {country:'Solomon Islands',phone:'+677',code:'SB / SLB',flag:flags.solomonIslands},
  {country:'Somalia',phone:'+252',code:'SO / SOM',flag:flags.somalia},
  {country:'South Africa',phone:'+27',code:'ZA / ZAF',flag:flags.southAfrica},
  {country:'South Korea',phone:'+82',code:'KR / KOR',flag:flags.southKorea},
  {country:'South Sudan',phone:'+211',code:'SS / SSD',flag:flags.southSudan},
  {country:'Spain',phone:'+34',code:'ES / ESP',flag:flags.spain},
  {country:'Sri Lanka',phone:'+94',code:'LK / LKA',flag:flags.sriLanka},
  {country:'Sudan',phone:'+249',code:'SD / SDN',flag:flags.sudan},
  {country:'Suriname',phone:'+597',code:'SR / SUR',flag:flags.suriname},
  {country:'Svalbard and Jan Mayen',phone:'+47',code:'SJ / SJM',flag:flags.svalbardAndJanMayen},
  {country:'Swaziland',phone:'+268',code:'SZ / SWZ',flag:flags.swaziland},
  {country:'Sweden',phone:'+46',code:'SE / SWE',flag:flags.sweden},
  {country:'Switzerland',phone:'+41',code:'CH / CHE',flag:flags.switzerland},
  {country:'Syria',phone:'+963',code:'SY / SYR',flag:flags.syria},
  {country:'Taiwan',phone:'+886',code:'TW / TWN',flag:flags.taiwan},
  {country:'Tajikistan',phone:'+992',code:'TJ / TJK',flag:flags.tajikistan},
  {country:'Tanzania',phone:'+255',code:'TZ / TZA',flag:flags.tanzania},
  {country:'Thailand',phone:'+66',code:'TH / THA',flag:flags.thailand},
  {country:'Togo',phone:'+228',code:'TG / TGO',flag:flags.togo},
  {country:'Tokelau',phone:'+690',code:'TK / TKL',flag:flags.tokelau},
  {country:'Tonga',phone:'+676',code:'TO / TON',flag:flags.tonga},
  {country:'Trinidad and Tobago',phone:'+1-868',code:'TT / TTO',flag:flags.trinidadAndTobago},
  {country:'Tunisia',phone:'+216',code:'TN / TUN',flag:flags.tunisia},
  {country:'Turkey',phone:'+90',code:'TR / TUR',flag:flags.turkey},
  {country:'Turkmenistan',phone:'+993',code:'TM / TKM',flag:flags.turkmenistan},
  {country:'Turks and Caicos Islands',phone:'+1-649',code:'TC / TCA',flag:flags.turksAndCaicosIslands},
  {country:'Tuvalu',phone:'+688',code:'TV / TUV',flag:flags.tubalu},
  {country:'U.S. Virgin Islands',phone:'+1-340',code:'VI / VIR',flag:flags.virginIslands},
  {country:'Uganda',phone:'+256',code:'UG / UGA',flag:flags.uganda},
  {country:'Ukraine',phone:'+380',code:'UA / UKR',flag:flags.ukraine},
  {country:'United Arab Emirates',phone:'+971',code:'AE / ARE',flag:flags.unitedArabEmirates},
  {country:'United Kingdom',phone:'+44',code:'GB / GBR',flag:flags.unitedKingdom},
  {country:'United States',phone:'+1',code:'US / USA',flag:flags.unitedStatesOfAmerica},
  {country:'Uruguay',phone:'+598',code:'UY / URY',flag:flags.uruguay},
  {country:'Uzbekistan',phone:'+998',code:'UZ / UZB',flag:flags.uzbekistan},
  {country:'Vanuatu',phone:'+678',code:'VU / VUT',flag:flags.vanuatu},
  {country:'Vatican',phone:'+379',code:'VA / VAT',flag:flags.vaticanCity},
  {country:'Venezuela',phone:'+58',code:'VE / VEN',flag:flags.venezuela},
  {country:'Vietnam',phone:'+84',code:'VN / VNM',flag:flags.vietnam},
  {country:'Wallis and Futuna',phone:'+681',code:'WF / WLF',flag:flags.wallisAndFutuna},
  {country:'Western Sahara',phone:'+212',code:'EH / ESH',flag:flags.sahrawiArabDemocraticRepublic},
  {country:'Yemen',phone:'+967',code:'YE / YEM',flag:flags.yemen},
  {country:'Zambia',phone:'+260',code:'ZM / ZMB',flag:flags.zambia},
  {country:'Zimbabwe',phone:'+263',code:'ZW / ZWE',flag:flags.zimbabwe}
  ]

// export const getCountryFlagByPhoneNumber = (number = '') => {
//   const result = PHONES.find(el => number.startsWith(el.phone))

//   if (result) return result.flag
//   return PHONES[0].flag
// }

// export const getCodeByNumber = (number = '') => {
//   const result = PHONES.find(el => number.startsWith(el.phone))

//   if (result) return result.code
//   return PHONES[0].code
// }

export default PHONES
