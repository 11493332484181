import React from 'react'
import PropTypes from 'prop-types'

import Switch from 'react-switch'

import { ServicesSwitch, LowHighText, Option, PickText } from './style'

const SortOption = ({
  title,
  value,
  isActive,
  onClickTitle,
  orderText,
  isChecked,
  onChangeSwitch
}) => (
  <Option value={value}>
    <PickText isActive={isActive} onClick={onClickTitle}>
      {title}
    </PickText>

    {isActive && (
      <>
        <ServicesSwitch>
          <LowHighText>{orderText}</LowHighText>
          <Switch
            checked={isChecked}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={onChangeSwitch}
          />
        </ServicesSwitch>
      </>
    )}
  </Option>
)

SortOption.propTypes = {
  isActive: PropTypes.bool,
  isChecked: PropTypes.bool,
  orderText: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
  onChangeSwitch: PropTypes.func,
  onClickTitle: PropTypes.func
}

export { SortOption }
