import styled from 'styled-components'

export const Wrapper = styled.div`
  box-shadow: ${props => props.theme.colors?.boxShadow};
  background: ${props => props.theme.colors?.componentsBackground};
  display: flex;
  flex-direction: column;
  padding: 15px;
  font-weight: 600;
  @media only screen and (max-width: ${props => props.theme.sizes?.desktop}px) {
    align-items: center;
  }
  @media only screen and (max-width: ${props => props.theme.sizes?.tablet}px) {
    align-items: center;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.h1`
  font-size: ${props => props.theme.fontSize?.h4};
  font-family: ${props => props.theme.fontFamily?.default};
  font-weight: 800;
  text-transform: capitalize;
  margin: 0;
`

export const Body = styled.div`
  display: flex;
  margin-top: 20px;
`

export const Avatar = styled.div`
  & > img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
`

export const Info = styled.div`
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  line-height: 28px;
  & a {
    text-decoration: none;
    color: ${props => props.theme.colors?.defaultBlue};
  }
`

export const Stars = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    margin-right: 5px;
    & > path {
      fill: ${props => props.theme.colors?.premiumBg};
    }
  }
`

export const TextVerified = styled.div`
  padding: 2px 10px;
  border-radius: 15px;
  color: ${props => props.theme.colors.joinButtonColor};
  background-color: ${props => props.theme.colors.defaultGreen};
  font-weight: bold;
  font-size: 12px;
  line-height: 1.5;
`