import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'

import { Modal, Radio, PreloadImg } from 'Components/ui'
import { icons } from 'Assets/icons'

import { data } from './config'
import {
  Container,
  Content,
  TitleRow,
  CloseIcon,
  Title,
  SubTitle,
  Info,
  RadioGroup,
  RadioRow,
  RadioLabel,
  WriteContainer,
  WriteLabel,
  Textarea,
  ButtonContainer,
  DeleteButton,
  ReasonError
} from './style'

class DeleteAccountModal extends PureComponent {
  state = {
    activeIndex: 2,
    text: '',
    isRequest: false,
    isReasonError: false
  }

  handleChange = index => this.setState({ activeIndex: index })

  handleChangeText = ({ target: { value } }) => this.setState({ text: value })

  handleSubmit = () => {
    const { onCloseModal, onDeleteAccount, onLogOut } = this.props
    const { text } = this.state
    if (text.trim()) {
      this.setState({ isRequest: true })

      onDeleteAccount(text, error => {
        if (!error) {
          onLogOut()
        }
      })

      setTimeout(() => {
        this.setState({ isRequest: false }, onCloseModal)
      }, 2000)
    } else {
      this.setState({ isReasonError: true })
    }
  }

  _renderRadioRow = (text, index) => {
    const { t } = this.props
    const { activeIndex } = this.state

    return (
      <RadioRow key={index}>
        <Radio
          id={text}
          isChecked={activeIndex === index}
          onClick={() => this.handleChange(index)}
        />
        <RadioLabel for={text}>{t(text)}</RadioLabel>
      </RadioRow>
    )
  }

  render() {
    const { t, isShow, onCloseModal } = this.props
    const { text, isRequest, isReasonError } = this.state

    return (
      <Modal isShow={isShow} width="420px" onRequestClose={onCloseModal}>
        <Container>
          <TitleRow>
            <Title>{t('deleteMyAccount')}</Title>
            <CloseIcon onClick={onCloseModal} />
          </TitleRow>
          <Content>
            <SubTitle>{t('deleteYourAccount')}</SubTitle>
            <Info>{t('whyYouAreLeaving')}</Info>
            <RadioGroup>{data.map(this._renderRadioRow)}</RadioGroup>
            <WriteContainer>
              <WriteLabel for="textarea">{t('writeYourReason')}</WriteLabel>
              <Textarea
                id="textarea"
                value={text}
                onChange={this.handleChangeText}
              />
              {isReasonError && (
                <ReasonError>{t('reasonIsRequired')}</ReasonError>
              )}
            </WriteContainer>
            <ButtonContainer>
              {isRequest ? (
                <PreloadImg mb={0} mt={0} src={icons.preload} />
              ) : (
                <DeleteButton onClick={this.handleSubmit}>
                  {t('deleteMyAccount').toUpperCase()}
                </DeleteButton>
              )}
            </ButtonContainer>
          </Content>
        </Container>
      </Modal>
    )
  }
}

DeleteAccountModal.propTypes = {
  isShow: PropTypes.bool,
  t: PropTypes.func,
  onCloseModal: PropTypes.func,
  onLogOut: PropTypes.func
}

export default withNamespaces(['deleteAccountScreen'])(DeleteAccountModal)
