import styled from 'styled-components'
import { fontFamily, fontSize } from 'Constants'

const Button = styled.button.attrs(props => ({
  type: 'button'
}))`
  width: 275px;
  height: 35px;
  color: #fff;
  background: linear-gradient(
    to right,
    rgba(10, 148, 241, 1) 0%,
    rgba(10, 148, 241, 1) 56%,
    rgba(100, 195, 255, 1) 100%
  );
  font-size: ${fontSize.h4};
  font-family: ${fontFamily};
  outline: none;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background: linear-gradient(
      to left,
      rgba(10, 148, 241, 1) 0%,
      rgba(10, 148, 241, 1) 56%,
      rgba(100, 195, 255, 1) 100%
    );
  }
`

export { Button }
