import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withNamespaces } from 'react-i18next'

import { SmallLoader } from 'Components/ui'

import SideBarItem from './PopularCategoriesItem'
import { SideBarWrapper, SideBarTitle, Header } from '../styles'

const PopularCategories = ({
  t,
  items,
  handleFilter,
  activeIds,
  loading,
  onUpdate
}) => (
  <SideBarWrapper>
    <Header>
      <SideBarTitle>{t('rightSideBar.popularCategories')}</SideBarTitle>
      <SmallLoader active={loading} onClick={onUpdate} />
    </Header>
    {_.isEmpty(items)
      ? null
      : _.orderBy(items, 'popularity', 'desc').map((item, key) => (
          <SideBarItem
            isActive={
              _.isEmpty(activeIds) ? false : activeIds.indexOf(item._id) !== -1
            }
            item={item}
            key={key}
            onClick={() =>
              _.isFunction(handleFilter)
                ? handleFilter({ services: [item._id] })
                : null
            }
          />
        ))}
  </SideBarWrapper>
)

PopularCategories.propTypes = {
  activeIds: PropTypes.array,
  handleFilter: PropTypes.func,
  items: PropTypes.array,
  loading: PropTypes.bool,
  t: PropTypes.func,
  onUpdate: PropTypes.func
}

export default withNamespaces('common')(PopularCategories)
