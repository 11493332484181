import memoize from 'memoize-one'
import { get } from 'lodash'
import ProfileImagesManager from 'Services/Images/ProfileImagesManager'

export const getFreelancerInfo = memoize(profile => ({
  name: profile.short_name,
  comments: 0,
  distance: get(profile, 'distance.length'),
  metric: get(profile, 'distance.units'),
  jobsDone: profile.jobsDoneCount || 0
}))

export const getModalImages = memoize((picsOfWork, certificates, username) => {
  const nextPicsOfWork = picsOfWork ? picsOfWork.map(url => {
    const image = ProfileImagesManager.getSignedURLForProfileImage(
      'picsOfWork',
      url,
      username
    )

    return {
      original: image,
      thumbnail: image,
      size: 0.1
    }
  }) : []

  const nextCertificates = certificates ? certificates.map(url => {
    const image = ProfileImagesManager.getSignedURLForProfileImage(
      'certificate',
      url,
      username
    )

    return {
      original: image,
      thumbnail: image,
      size: 0.1
    }
  }) : []

  return [...nextPicsOfWork, ...nextCertificates]
})
