import styled from 'styled-components'
import { sizes } from 'Constants'

/**********************************************************************
 ***********************Arrows Slick***********************************
 **********************************************************************/

const Arrows = styled.img`
  position: absolute;
  top: 50%;
  cursor: pointer;
  z-index: 10;
`

export const PrevArrow = styled(Arrows)`
  left: 15px;
  transform: translate(-100%, -100%);
  @media (max-width: ${sizes.tablet}px) {
  }
`

export const NextArrow = styled(Arrows)`
  right: 15px;
  transform: translate(100%, -100%);
  @media (max-width: ${sizes.tablet}px) {
  }
`
