import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withNamespaces } from 'react-i18next'
import withCurrency from '../../../Components/hoc/withCurrency'

import Component from './RecurringModal'

const selectors = createStructuredSelector({})

export default compose(
  withNamespaces(['jobDetail', 'findJobPage', 'jobPost']),
  withCurrency,
  connect(
    selectors,
  )
)(Component)
