import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { services } from 'Redux/actions/services'
import { getGeo } from 'Redux/actions/googleGeocoding'
import { getServices } from 'Redux/selectors/services'
import { getCategories } from 'Redux/selectors/categories'
import Component from './ServicesModal'

const selector = createStructuredSelector({
  items: services,
  services: getServices,
  categories: getCategories
})

const action = {
  onGetServices: services,
  onGetGeo: getGeo
}

export const ServicesModal = connect(
  selector,
  action
)(Component)
