import React from 'react'
import PropTypes from 'prop-types'

import { Text } from './style'

const Title = ({ children }) => <Text>{children}</Text>

Title.propTypes = {
  children: PropTypes.string
}

export default Title
