import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { withNamespaces } from 'react-i18next'

import { loadPostedJobList } from 'Redux/actions/postedJobs'

import { getPostedJobs } from 'Redux/selectors/postedJobs'

import Component from './SelectJobModal'

const actions = {
  onLoadJobs: loadPostedJobList
}

const selectors = createStructuredSelector({
  jobs: getPostedJobs
})

export default compose(
  connect(
    selectors,
    actions
  ),
  withNamespaces(['LeftoverOnes', 'apiErrors'])
)(Component)
