import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { jobStatuses } from 'Constants/jobStatuses'
import * as routes from 'Routes/routesConstants'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { cancelAppliedJob } from 'Redux/actions/appliedJobs'
import { cancelBookedJob } from 'Redux/actions/bookedJobs'
import { jobDescription } from 'Redux/actions/job'

import { CancelJobModal } from 'Components/blocks'

import { getProfile } from 'Redux/selectors/user'
import { disputeJob } from 'Redux/actions/bookedJobs'
import { toast } from 'react-toastify'
import { getSpecificSP } from 'Redux/selectors/serviceProvider'
import { clientUrl } from 'Constants/api'

function withCancelJob(Component) {
  return class extends React.Component {
    static displayName = 'withCancelJob'

    static propTypes = {
      cancelJob: PropTypes.func
    }

    state = {
      isRequest: false,
      canceledJob: null,
      reason: ''
    }

    handleChangeReason = ({ target: { value } }) => {
      this.setState({ reason: value })
    }

    handleShowModal = job => this.setState({ canceledJob: job, reason: '' })

    handleHideModal = () => this.setState({ canceledJob: null })

    handleSubmit = async () => {
      const {
        cancelAppliedJob,
        cancelBookedJob,
        user,
        jobDescription
      } = this.props
      const { reason, canceledJob } = this.state

      const request = {
        reason,
        jobId: canceledJob._id
      }

      const func =
        canceledJob.jobStatus === jobStatuses.booked
          ? cancelBookedJob
          : cancelAppliedJob
      const isAuthor = canceledJob?.author?.username === user?.username
      const isOwnDispute =
        (isAuthor && canceledJob?.authorDispute) || (!isAuthor && canceledJob?.doerDispute)

      if (canceledJob?.disputeReason && isOwnDispute) {
        await this.handleDisputeJob()
      }

      this.setState({ isRequest: true })
      func(request, (error, response) => {
        if (error) {
          return this.setState({ isRequest: false, canceledJob: null }, () =>
            toast.error(error?.message)
          )
        }

        const isAuthor = user?.username === canceledJob?.author?.username

        if (isAuthor) {
          if (response?.jobStatus === jobStatuses.posted) {
            return this.setState({ isRequest: false, canceledJob: null }, () =>
              this.handleRedirectFindJobs(response?._id)
            )
          }
          return this.setState({ isRequest: false, canceledJob: null }, () =>
            jobDescription(response?._id)
          )
        }
        if (response?.jobStatus !== jobStatuses.posted) {
          return this.setState({ isRequest: false, canceledJob: null }, () =>
            jobDescription(response?._id)
          )
        }
        this.setState({ isRequest: false, canceledJob: null }, () =>
          this.handleRedirectFindJobs(response?._id)
        )
      })
    }

    handleDisputeJob = () => {
      const { user, disputeJob, serviceProvider } = this.props
      const { canceledJob } = this.state

      const disputeSendBy =
      canceledJob?.author?.username === user?.username
          ? 'Employer'
          : 'Service Provider'
      const username = canceledJob?.author?.short_name
        ? canceledJob?.author.short_name
        : user?.username

      const content = `Job Cancelled<br>Job Title: ${canceledJob?.title}<br>Job Id: ${canceledJob?._id}<br>Dispute send by: ${disputeSendBy}<br>User name: ${username}<br>Reason: ${canceledJob.disputeReason}<br>Budget: ${canceledJob?.budget} ${canceledJob?.currency}<br>Employer Email: ${canceledJob?.author?.email}<br>Service Provider Email: ${serviceProvider?.email}\n`

      const request = {
        subject: 'Dispute Cancelled',
        content,
        jobId: canceledJob?._id,
        reason: canceledJob.disputeReason,
        cancelDispute: true,
        isCancel: true
      }

      disputeJob(request)
    }

    handleRedirectFindJobs = jobId => {
      const { location, history } = this.props

      if (
        location?.pathname === `${routes.findJobs}${routes.listView}/${jobId}`
      ) {
        return window.location.replace(
          `${clientUrl}${routes.findJobs}${routes.listView}/${jobId}`
        )
      }

      history.push(`${routes.findJobs}${routes.listView}/${jobId}`)
    }

    render() {
      const { canceledJob, reason, isRequest } = this.state

      const isNoShow = canceledJob?.isPaymentViaOurService
        ? (!canceledJob?.authorStartJob || !canceledJob?.doerStartJob) &&
        canceledJob?.jobStatus !== jobStatuses.posted
        : false

      return (
        <>
          <Component
            {..._.omit(this.props, ['cancelAppliedJob', 'cancelBookedJob'])}
            canceledJob={canceledJob}
            onCancelJob={this.handleShowModal}
          />
          <CancelJobModal
            isLoading={isRequest}
            isShow={_.isObject(canceledJob)}
            value={reason}
            onChange={this.handleChangeReason}
            onClose={this.handleHideModal}
            onSubmit={this.handleSubmit}
            isNoShow={isNoShow}
          />
        </>
      )
    }
  }
}

const actions = {
  cancelAppliedJob,
  cancelBookedJob,
  jobDescription,
  disputeJob
}

const selectors = createStructuredSelector({
  user: getProfile,
  serviceProvider: getSpecificSP
})

export default compose(connect(selectors, actions), withCancelJob)
