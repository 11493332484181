import styled from 'styled-components'
import { fontFamily, fontSize, sizes } from 'Constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'

export const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'rgba(0,0,0, 0.3)',
  zIndex: 10,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}

export const modalContentStyle = {
  position: 'relative',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  padding: '10px',
  width: '100%',
  border: '0',
  height: '90%',
  backgroundColor: 'transparent',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'column'
}

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000000;
  opacity: 0.4;
  z-index: 999;
`

export const ModalContainer = styled.div`
  opacity: 1 !important;
  position: fixed;
  top: 5%;
  background: #fff;
  z-index: 1000;
  max-width: 90%;
  min-width: 50%;
  border-radius: 5px;
  padding:  0 0 2em;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  &:after {
    content: '';
    position: absolute;
    bottom: 2em;
    width: 100%;
    height: 5em;
    background: linear-gradient(rgba(255, 255, 255, 0.001), white);
    pointer-events: none;
  }
  @media (max-width: ${sizes.desktop}px) {
    width: 90%;
  }
  @media (max-width: ${sizes.tablet}px) {
    top: 15%;
    &:after {
      height: 1em;
    }
  }
  @media (max-width: ${sizes.phone}px) {
    width: 90%;
    padding: 0 0 30px;
  }
`
export const ModalBody = styled.div``
export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 2em;
`

export const HeaderBack = styled.div`
  position: absolute;
  padding: 5px 10px;
  cursor: pointer;
  color: ${props => props.theme.colors.blueIcon};
  z-index: 100;
`

export const ServicesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: start;
  margin: 40px 0 45px 0;
`
export const HeaderStep = styled.span`
  font-size: ${fontSize.h6};
  font-family: ${fontFamily};
  margin-left: 7px;
  cursor: default;
  color: ${props => props.theme.colors.defaultFontBlack};
`
export const HeaderTitle = styled.h3`
  font-size: ${fontSize.h2};
  font-family: ${fontFamily};
  font-weight: 800;
  margin: 0 auto;
  @media (max-width: ${sizes.phone}px) {
    padding-right: 10%;
    font-size: ${fontSize.h4};
  }
`
export const HeaderClose = styled.div`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: ${fontSize.h3};
  font-family: ${fontFamily};
  color: ${props => props.theme.colors.joinUs};
  transition: 0.2s;
  &:hover {
    opacity: 0.5;
  }
  @media (max-width: ${sizes.phone}px) {
    top: 20px;
    right: 20px;
  }
`
export const ModalButtonWrapper = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  bottom: 0;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -35%);
`

export const ModalButtonWrapperBottom = styled.div`
  @media (max-width: ${sizes.phone}px) {
    position: absolute;
    bottom: -40px;
    left: 50%;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    z-index: 1;
    transform: translate(-50%, -50%);
  }
`

export const ModalButtonWrapperLeft = styled(ModalButtonWrapper)`
  left: 5%;
  & > button {
    transform: translate(50%, -35%);
  }
  @media (max-width: ${sizes.phone}px) {
    position: static;
    width: 100%;
    & > button {
      width: 100%;
      transform: translate(50%, 50%);
    }
  }
`

export const ModalButtonWrapperRight = styled(ModalButtonWrapper)`
  left: 95%;
  & > button {
    transform: translate(-50%, -35%);
  }
  @media (max-width: ${sizes.phone}px) {
    position: static;
    width: 100%;
    & > button {
      width: 100%;
      transform: translate(50%, 50%);
    }
  }
`
export const ModalInput = styled.input`
  width: 100%;
  border-radius: 3px;
  padding: 6px 0 6px 6px;
  margin-bottom: 20px;
  font-size: ${fontSize.h3};
  font-family: ${fontFamily};
  border: 2px solid ${props => props.theme.colors.itemBorder};
  &:-webkit-autofill {
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0 1000px #5da6ff inset;
    border: none;
  }
`
export const InputTitle = styled.p`
  margin: 0;
  padding-bottom: 5px;
  font-size: ${fontSize.h5};
  font-weight: bold;
`

export const ServiceBlock = styled.div`
  position: relative;
  width: 17.6%;
  justify-content: center;
  height: 110px;
  text-align: center;
  user-select: none;
  cursor: pointer;
  margin: 8px;
  background: #fff;
  transition: 0.3s;
  border: 2px solid
    ${props =>
      props.active
        ? '#afd5ff'
        : props.edit
        ? props.theme.colors.accentColor
        : '#fff'};
  border-radius: 7px;
  &:hover {
    & * p {
      -webkit-line-clamp: 4;
    }
  }
  & > svg {
    position: absolute;
    top: 10px;
    right: 10px;
    & > path {
      fill: ${props =>
        props.isRed ? props.theme.colors.iconRed : props.theme.colors.blueIcon};
    }
  }
  @media (max-width: ${sizes.phone}px) {
    width: 25%;
  }
`

export const EditIcon = styled(FontAwesomeIcon).attrs(props => ({
  icon: faPen
}))``

export const ServiceWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
`
export const ServiceImage = styled.img`
  margin-top: 10px;
  width: auto;
  max-height: 40px;
`

export const ServiceTitle = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%;
  font-size: ${props => (props.bold ? '16px' : '13px')};
  font-family: ${fontFamily};
  color: #555;
  padding-top: 5px;
  margin: 0;
  text-transform: capitalize;
  ${props => (props.bold ? 'font-weight: 800;' : '')};
  & > span {
    color: ${props => props.theme.colors.defaultBlue};
  }
  @media (max-width: ${sizes.phone}px) {
    font-size: ${fontSize.small};
  }
`

export const ModalBodyWrapper = styled.div`
  padding: 0 5%;
  @media (max-width: ${sizes.tablet}px) {
    padding: 0;
  }
`

export const ModalWrapper = styled.div`
  max-height: 70vh;
  overflow-y: scroll;
  margin: 0 20px;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset #fff;
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: inset #fff;
  }
`

export const SearchButton = styled.span`
  position: absolute;
  font-weight: 500;
  right: 2%;
  top: 10%;
  font-size: ${fontSize.h3};
  font-family: ${fontFamily};
  transform: rotate(-45deg);
  cursor: pointer;
  user-select: none;
  @media (max-width: ${sizes.phone}px) {
    right: 5%;
  }
`
export const SearchInput = styled.div`
  position: relative;
`

export const Form = styled.form`
  width: 100%;
  padding: 0 5%;
  display: flex;
  flex-direction: column;
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 20px;
`

export const InputWrapperRow = styled(Row)`
  @media (max-width: ${sizes.phone}px) {
    flex-direction: column;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`

export const InputWrapper = styled.div`
  flex-basis: 20%;
`

export const AutoWrapper = styled.div`
  flex-basis: 100%;
`
export const InputShort = styled(ModalInput)`
  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
`

export const Label = styled.label`
  text-align: left;
  font-size: ${fontSize.default};
  margin-bottom: 5px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
ServiceBlock.displayName = 'ServiceBlock'