import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { fontFamily, sizes, fontSize } from 'Constants'

/* PostJobBtn
/* ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## */

export const PostJobWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: -40px;
  transform: translate(0, 50%);
  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    right: 50%;
    width: 2px;
    height: 16px;
    background: white;
    transform: translate(50%, -50%);
  }
  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    right: 50%;
    width: 16px;
    height: 2px;
    background: white;
    transform: translate(50%, -50%);
    z-index: 1;
  }
  @media (max-width: ${sizes.tablet}px) {
    display: none;
  }
`

export const PostJobAdd = styled(Link)`
  position: relative;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  text-decoration: none;
  background: #01b151;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: ${fontSize.h1};
  font-family: ${fontFamily};
  cursor: pointer;
  & > span {
    font-weight: 200 !important;
    transform: translateY(-4%);
  }
`

export const PostJobBlock = styled.div`
  display: ${props => (props.isActive ? 'block' : 'none')};
`

export const Span = styled.span`
  color: #01b151;
  font-family: Papyrus, 'Open Sans', sans-serif;
  position: relative;
  top: 1.3em;
`

export const Img = styled.img`
  width: 30px;
  height: 30px;
  transform: rotate(-100deg) translateX(-50%);
`
