import { createSelector, createStructuredSelector } from 'reselect'

const getState = state => state.providers

export const getServiceProvidersList = createSelector(
  getState,
  serviceProvidersList => serviceProvidersList
)

export const getFullLibraryData = createStructuredSelector({
  getServiceProvidersList
})
