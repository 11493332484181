import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import * as routes from 'Routes/routesConstants'

import FreelancerProfileView from '../FreelancerProfileView'

import { Container } from './style'

class RequestSPDescription extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isRequest: false
    }

    this.request = null
    this.job = null
  }

  componentDidMount() {
    this.handleLoadSPProfile()
  }

  handleLoadSPProfile = userId => {
    const { onLoadServiceProvider, match, requests, history } = this.props

    const requestId = _.get(match, 'params.requestId')

    const request = requests.list.find(el => el.jobRequest._id === requestId)

    if (!request || !requestId) {
      history.replace(`${routes.myRequests}`)
      return
    }

    this.request = request.jobRequest
    this.job = _.omit(request, ['doer', 'jobRequest'])

    if (_.isString(userId) || request.doer) {
      this.setState({ isRequest: false }, () => {
        const result = _.isString(userId) ? userId : request.doer.username

        onLoadServiceProvider(result, () => {
          this.setState({ isRequest: false })
        })
      })
    }
  }

  handleFavouriteClick = () => {
    const { serviceProvider, onFavouriteSPClick } = this.props

    onFavouriteSPClick(serviceProvider)
  }

  render() {
    const { serviceProvider, loadFavouriteSPId } = this.props
    const { isRequest } = this.state

    return (
      <Container>
        <FreelancerProfileView
          isDisabledApplyButton={
            _.get(this, 'request.doer') === _.get(serviceProvider, 'username')
          }
          isLoadingFavourite={Boolean(loadFavouriteSPId)}
          isRequest={isRequest}
          job={this.job}
          profile={serviceProvider}
          onFavouriteClick={this.handleFavouriteClick}
        />
      </Container>
    )
  }
}

RequestSPDescription.propTypes = {
  loadFavouriteSPId: PropTypes.string,
  requests: PropTypes.object,
  serviceProvider: PropTypes.object,
  onFavouriteSPClick: PropTypes.func,
  onLoadServiceProvider: PropTypes.func
}

export default RequestSPDescription
