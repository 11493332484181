import styled from 'styled-components'

export const DescriptionWrapper = styled.div`
  background: ${props => props.theme.colors?.componentsBackground};
  margin-bottom: 20px;
  width: 100%;
  box-shadow: ${props => props.theme.colors?.boxShadow};
  border-radius: 4px;
`

export const TitleWrapper = styled.div`
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors?.divideLine};
  min-height: 60px;
`

export const TitleText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${p => p.theme.fontSize?.h3};
  margin-bottom: 5px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 95%;
  & > span {
    margin-right: 10px;
  }
`

export const TitleTextSpan = styled.span`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
`

export const TitleRight = styled.div`
  display: flex;
  & > button {
    margin: 0 4px;
  }
`

export const Budget = styled.div`
  border-radius: 0;
  width: 100%;
  height: 45px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${p => p.theme.fontSize?.h3};
  color: #fff;
  background: ${p => p.theme.colors?.joinButton};
`

export const MilesGroup = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 15px 10px;
`

export const MilesLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-family: ${p => p.theme.fontFamily?.default};
  & > h2 {
    font-size: ${p => p.theme.fontSize?.h4};
    font-weight: 700;
    color: ${props => props.theme.colors?.accentColor};
    margin: 0;
    padding: 10px 0;
  }
  & > h3 {
    font-size: ${p => p.theme.fontSize?.h4};
    margin: 0;
    font-weight: 700;
  }
  overflow: hidden;
`

export const MilesRight = styled.div`
  min-width: 40px;
  font-weight: 700;
  font-size: ${p => p.theme.fontSize?.default};
  font-family: ${p => p.theme.fontFamily?.default};
  height: fit-content;
  padding: 10px 0;
  & > span {
    margin-left: 5px;
  }
  & > svg {
    & > path {
      fill: ${props => props.theme.colors?.iconRed};
    }
  }
`

export const ScrollWrap = styled.div`
  max-height: 200px;
  overflow-y: scroll;
  padding-bottom: 20px;
  border-bottom: 20px solid #fff;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 5px #fff;
  }
  @media (max-width: ${p => p.theme.sizes?.desktop}px) {
    padding: 0 0 30px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export const Text = styled.p`
  background: transparent;
  padding: 10px 15px;
  font-size: ${p => p.theme.fontSize?.h4};
  margin: 0;
  line-height: 2;
  color: rgba(85, 85, 85, 1);
`

export const TitleLeft = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 800;
  width: 80%;
`

export const JobPopupContainer = styled.div`
  display: flex;
  padding-left: 5px;
`

export const BookedInfo = styled.span`
  font-size: ${p => p.theme.fontSize?.h4};
  color: ${p => p.theme.colors?.joinUs};
  font-weight: bold;
`

export const ApplyBy = styled(BookedInfo)`
  color: ${p => p.theme.colors?.accentColor};
`

export const JobTitle = styled.h1`
  font-size: ${p => p.theme.fontSize?.h3};
  color: #000;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 0;
  width: 100;
  overflow: hidden;
`
