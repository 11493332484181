import { createReducer } from 'Helpers/redux'

import { inSocketTypes } from 'Constants/socketEventTypes'
import { AUTHENTICATE } from 'Redux/actions/socket'
import { LOG_OUT } from 'Redux/actions/auth'

const initialState = {
  isConnected: false,
  attemp: 0
}

const handlers = {
  [`socket/${inSocketTypes.Authorized.toUpperCase()}`]: state => ({
    ...state,
    isConnected: true,
    attemp: 0
  }),
  [`socket/${inSocketTypes.Unauthorized.toUpperCase()}`]: state => ({
    ...state,
    isConnected: false
  }),
  [AUTHENTICATE.event]: state => ({
    ...state,
    isConnected: false,
    attemp: state.attemp + 1
  }),
  [LOG_OUT.REQUEST]: () => initialState
}

export default createReducer(initialState, handlers)
