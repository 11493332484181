import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { updateUser } from 'Redux/actions/user'
import { getProfile } from 'Redux/selectors/user'
import Component from './profile'

const selector = createStructuredSelector({
  user: getProfile
})

const actions = {
  onUpdateProfile: updateUser
}

export const Profile = connect(
  selector,
  actions
)(Component)
