import React from 'react'
import { withNamespaces } from 'react-i18next'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faWallet, faTools } from '@fortawesome/free-solid-svg-icons'

import { MainTitle } from 'Components/ui'
import { findHelp, findJobs, jobPosts } from 'Routes/routesConstants'
import { Search } from 'Components/blocks'

import {
  WhatYouNeedWrapper,
  WhatYouNeedLinks,
  SelectBtn,
  WrapperSelect,
  SearchContainer
} from '../styles'

const Component = ({ t }) => (
  <WrapperSelect>
    <MainTitle>{t('selectWhatYouNeed')}</MainTitle>
    <WhatYouNeedWrapper>
      <SearchContainer>
        <Search isWider />
      </SearchContainer>
      <WhatYouNeedLinks>
        <Link to={findJobs}>
          <SelectBtn>
            <FontAwesomeIcon icon={faSearch} />
            {t('findJobs')}
          </SelectBtn>
        </Link>
        <Link to={jobPosts}>
          <SelectBtn>
            <FontAwesomeIcon icon={faWallet} />
            {t('postJob')}
          </SelectBtn>
        </Link>
        <Link to={findHelp}>
          <SelectBtn>
            <FontAwesomeIcon icon={faTools} />
            {t('findHelp')}
          </SelectBtn>
        </Link>
      </WhatYouNeedLinks>
    </WhatYouNeedWrapper>
  </WrapperSelect>
)

export const SelectWhatYouNeed = withNamespaces('homePage')(Component)
