import React from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'

import {
  ContainersWrapper,
  GridFourElements,
  MainTitle,
  WidthLimiter
} from './styles'
import Item from '../Item'

const Advantages = props => {
  const { items, t, isCards } = props
  return (
    <ContainersWrapper className="advantages">
      <WidthLimiter>
        <MainTitle>{t('moreForLess')}</MainTitle>
        <GridFourElements>
          {items.map((item, i) => (
            <Item isCards={isCards} key={i} more {...item} />
          ))}
        </GridFourElements>
      </WidthLimiter>
    </ContainersWrapper>
  )
}

Advantages.propTypes = {
  isCards: PropTypes.bool,
  items: PropTypes.array,
  t: PropTypes.func
}
export default withNamespaces('landingPage')(Advantages)
