import React from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import * as routes from 'Routes/routesConstants'
import { compose } from 'redux'

import { Divider, Avatar, Name } from 'Components/ui'
import { Report } from '../index'

import { Wrapper, Header, Body, Info, Stars, Title, TextVerified } from './styles'
import { idVerificationStatus } from 'Constants/idVerification'

const CustomerInfo = ({
  title,
  t,
  data: {
    avatar,
    name,
    username,
    reviews,
    stars,
    id_verification,
    isPro,
    isPremium
  },
  user,
  isNotAuthor,
  usernameId,
  onReport
}) => {
  if (!username) return null

  return (
    <Wrapper>
      <Header>
        <Title>{title || t('customerInfo')}</Title>
        <Report
          isNotAuthor={isNotAuthor}
          isVisible={username !== user?.username}
          onClick={onReport}
        >
          {name}
        </Report>
      </Header>
      <Body>
        <Avatar avatar={avatar} id={username} isVerified={id_verification === idVerificationStatus.success} />
        <Info>
          <Name id={username} isPremium={isPremium} isPro={isPro} name={name} />
          {id_verification === idVerificationStatus.success && (
            <TextVerified>{t('profilePage:identificationVerified')}</TextVerified>
          )}
          <Stars>
            <FontAwesomeIcon icon={faStar} />
            <span>{stars || 0}</span>
            <Divider />
            <Link
              to={
                usernameId
                  ? `${routes.findHelp}/${usernameId}${routes.ratingProfile}`
                  : `${routes.findJobs}`
              }
            >
              {reviews || 0} {t('reviews')}
            </Link>
          </Stars>
          <Link to={`${routes.findJobs}${routes.listView}?author=${username}`}>
            {t('otherJobs')}
          </Link>
        </Info>
      </Body>
    </Wrapper>
  )
}

CustomerInfo.propTypes = {
  data: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
    username: PropTypes.string,
    reviews: PropTypes.number,
    stars: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isVerified: PropTypes.bool,
    isPro: PropTypes.bool,
    isPremium: PropTypes.bool
  }),
  t: PropTypes.func,
  title: PropTypes.string,
  usernameId: PropTypes.string
}

export default compose(withNamespaces('jobDescription'))(CustomerInfo)
