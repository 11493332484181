import ru from 'date-fns/locale/ru'
import en from 'date-fns/locale/en-US'
import bn from 'date-fns/locale/bn'
import de from 'date-fns/locale/de'
import es from 'date-fns/locale/es'
import fr from 'date-fns/locale/fr'
import it from 'date-fns/locale/it'
import pt from 'date-fns/locale/pt'

export const locales = {
  en: en,
  ru: ru,
  bn: bn,
  de: de,
  es: es,
  fr: fr,
  gu: en,
  hi: en,
  it: it,
  kn: en,
  ml: en,
  mr: en,
  pt: pt,
  ta: en,
  te: en
}
