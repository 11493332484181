import styled from 'styled-components'
import { fontFamily, fontSize } from 'Constants'

export const AddButton = styled.button`
  width: 100px;
  height: 100px;
  background: rgba(90, 132, 235, 0.2);
  border-radius: 5px;
  font-weight: 300;
  font-size: ${fontSize.default};
  font-family: ${fontFamily};
  margin: 10px 0;
  transition: all 0.2s ease-in;
  cursor: pointer;
  outline: none;
  border: 1px dashed rgba(90, 132, 235, 1);

  &:hover {
    background: rgba(90, 132, 235, 1);
  }
`

export const AddButtonIcon = styled.div`
  width: 40%;
  height: 40%;
  position: relative;
  margin: 0 auto;

  ${AddButton}:hover &:before {
    background-color: #fff;
  }

  ${AddButton}:hover &:after {
    background-color: #fff;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: rgba(90, 132, 235, 1);
  }

  &:before {
    top: 0;
    left: 50%;
    width: 4px;
    height: 100%;
    margin-left: -2px;
  }

  &:after {
    top: 50%;
    left: 0;
    width: 100%;
    height: 4px;
    margin-top: -2px;
  }
`
