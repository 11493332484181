import styled from 'styled-components'
import { Button } from 'Components/ui'
import { fontFamily, sizes, fontSize } from 'Constants'

export const Container = styled.div`
  background: #fff;
  border-radius: 12px;
`

export const Header = styled.header`
  padding: 15px 0;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 5px;
`

export const Title = styled.span`
  color: #000;
  font-size: ${p => p.theme.fontSize?.h4};
  font-weight: bold;
  text-transform: uppercase;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
`

const ConfirmButton = styled(Button)`
  border-radius: 0;
  flex-basis: 50%;
  font-weight: bold;
`

export const SubmitButton = styled(ConfirmButton)`
  ${p => p.disabled && 'pointer-events: none; opacity: 0.6;'};
  border-bottom-right-radius: 12px;
`

export const CancelButton = styled(ConfirmButton)`
  filter: grayscale(100%);
  border-bottom-left-radius: 12px;
  ${p => p.disabled && 'pointer-events: none; opacity: 0.6;'};
`

export const Content = styled.div`
  padding-right: 40px;
  padding-left: 40px;
`

export const Block = styled.div``

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: ${({ top }) => top ? top : 0}px;
  @media (max-width: ${sizes.tablet}px) {
    flex-direction: ${({ isTop }) => isTop ? 'column' : 'row'};
  }
`

export const Text = styled.span`
  ${({ flex }) => flex && { flex }};
  font-size: ${({ isBold }) => (isBold ? '20px' : '18px')};
  font-weight: ${({ isBold }) => (isBold ? 'bold' : 'normal')};
`

export const Description = styled.p`
  text-align: center;
  max-width: 600px;
  padding: 0 20px 30px;
  margin-top: -10px;
  font-weight: 500;
`

export const MethodImage = styled.div`
  background-image: ${({ url }) => `url(${url})`};
  height: 40px;
  width: 70px;
  background-position: center;
  background-size: cover;
  margin-left: 10px;
  border-radius: 5px;
  border: 2px solid ${({ active }) => (active ? 'red' : '#ddd')};
  cursor: pointer;
`

export const Label = styled.label`
  display: inline-block;
  font-size: ${fontSize.default};
  font-family: ${fontFamily};
  padding-bottom: 5px;
  align: left;
`

export const Col6 = styled.div`
  flex: 0 0 50%;
  padding: 0 5px;
  & .react-datepicker-wrapper,
  react-datepicker__input-container {
    width: 100%;
  }
  @media (max-width: ${sizes.tablet}px) {
    flex: 0 0 100%;
    width: 100%;
  }
`

export const DatePickerWrapper = styled.div`
  flex-direction: column;
  margin-bottom: 10px;
  align-self: flex-start;
  width: 100%;
  & > .react-datepicker-wrapper {
    width: 100%;
  }
`

export const BudgetWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 45px;
`

export const Input = styled.input`
  width: 100%;
  height: 100%;
  padding: 10px 15px;
  font-size: ${fontSize.h3};
  font-family: ${fontFamily};
  border: 2px solid #eee;
  border-radius: 5px;
  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
`

export const CurrencyContainer = styled.div`
  width: 90px;
  border-radius: 5px;
  border: 2px solid #eee;
  background: #fff;
  margin-left: 5px;
  height: 100%;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const ModalButton = styled(Button)`
  transition: box-shadow 0.3s;
  ${p => p.disabled && 'pointer-events: none; opacity: 0.6;'};
  &:hover {
    box-shadow: 0 0 10px silver;
  }
`

export const styles = {
  budgetConvert: {
    width: 'calc(100% - 80px)',
    pointerEvents: 'none'
  },
  currency: {
    width: 75,
    marginLeft: 5,
    fontSize: 18,
    textAlign: 'center',
    pointerEvents: 'none'
  }
}

export const CloseIcon = styled.div`
  position: absolute;
  top: -20px;
  right: -10px;
  font-size: 45px;
  background: #fff;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50%;
  box-shadow: 0 1px 4px 0 #c9cfde;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.5;
  }
`