import React from 'react'
import styled from 'styled-components'
import { AuthFormWrapper, FormTitle as Title } from 'Components/ui'
import { fontFamily, fontSize, sizes } from 'Constants'

export const StyledAuthFormWrapper = styled(props => <AuthFormWrapper {...props} />)`
  @media (max-width: ${sizes.phone}px) {
    width: 100%;
  }
`

export const FormTitle = styled(props => <Title {...props} />)`
  font-size: ${fontSize.h4};
  font-family: ${fontFamily};
`
export const FormTitleSub = styled(props => <Title {...props} />)`
  font-size: ${fontSize.h5};
  font-family: ${fontFamily};
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 1em
`

export const BtnSubmitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`

export const Loading = styled.div`
  display: flex;
  height: calc(100vh - 225px);
  width: 100%;
  justify-content: center;
  & img {
    margin: auto;
    height: 16px;
  }
`
