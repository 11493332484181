import styled from 'styled-components'
import { fontFamily, fontSize } from 'Constants'

export const Wrapper = styled.div`
  height: 14px;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colors.premiumBg};
  border-radius: 10px;
  font-size: ${fontSize.small};
  font-family: ${fontFamily};
  font-weight: 500;
  color: ${props => props.theme.colors.defaultFont};
  padding: 8px 10px;
  & > p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`
