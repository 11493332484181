import styled, { keyframes } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'

const rotate = keyframes`
  0% {
    transform: rotate(0deg) scale(1);
  }
  
  50% {
    transform: rotate(180deg) scale(1.1);
  }

  100% {
    transform: rotate(360deg) scale(1);
  }
`

export const Icon = styled(FontAwesomeIcon).attrs(p => ({
  icon: faSyncAlt
}))`
  color: silver;
`

export const Container = styled.div`
  cursor: pointer;
  transition: opacity 0.3s;
  & > ${Icon} {
    animation: ${p => p.active && rotate} 1s linear infinite;
  }
`
