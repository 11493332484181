import React from 'react'
import PropTypes from 'prop-types'
import { icons } from 'Assets/icons'
import { Messages, Img, Popap } from './styles'

export const Msg = ({ isRequest, onClick }) => (
  <Messages disabled={isRequest} onClick={onClick}>
    <Popap>Popap</Popap>
    <Img src={icons.msg} />
  </Messages>
)

Msg.propTypes = {
  onClick: PropTypes.func
}
