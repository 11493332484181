import React, { Component } from 'react'

import _ from 'lodash'

import ProfileImagesManager from 'Services/Images/ProfileImagesManager'

const pickSource = props => {
  const { type, src, userId } = props

  let source = src
  if (_.isString(src)) {
    if (/(data:image\/.*?;base64),/g.exec(src) || src.startsWith('http')) {
      source = src
    } else {
      source = ProfileImagesManager.getSignedURLForProfileImage(
        type,
        src,
        userId
      )
    }
  } else if (_.isObject(src)) {
    source = null
  }

  return source
}

class ProfileImage extends Component {
  constructor(props) {
    super(props)
    this.state = { source: null, props: null }
  }

  static getDerivedStateFromProps(nextProps) {
    let nextSource
    if (nextProps) {
      if (nextProps.type === 'picsOfWork') {
        nextSource = pickSource({ ...nextProps, type: 'picsOfWork' })
      } else {
        nextSource = pickSource(nextProps)
      }

      return { source: nextSource, props: nextProps }
    }
    return null
  }

  render() {
    const { source } = this.state

    if (!source) return null

    const imgProps = _.cloneDeep(this.props)

    delete imgProps.src
    delete imgProps.userId
    delete imgProps.type
    return <img alt="" {...imgProps} src={source} />
  }
}

export { ProfileImage }
