import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
  withShare,
  withServiceProviderProfile,
  withConnectToChat
} from 'Components/hoc'

import { withNamespaces } from 'react-i18next'

import { report } from 'Redux/actions/report'
import { resetError } from 'Redux/actions/serviceProviders'

import { getProfile } from 'Redux/selectors/user'
import { getJobDescription } from 'Redux/selectors/jobDescription'
import { getError } from 'Redux/selectors/serviceProvider'

import Component from './FreelancerProfileView'

const actions = {
  onReport: report,
  onResetError: resetError
}

const selectors = createStructuredSelector({
  user: getProfile,
  job: getJobDescription,
  error: getError
})

export default compose(
  withConnectToChat,
  withServiceProviderProfile,
  withShare,
  connect(
    selectors,
    actions
  ),
  withNamespaces([
    'serviceProvider',
    'appServiceProvider',
    'jobDetail',
    'common'
  ])
)(Component)
