import PropTypes from 'prop-types'
import React from 'react'
import { Wrapper } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCrown } from '@fortawesome/free-solid-svg-icons'

export const Premium = ({ size, visible }) => {
  if (!visible) return null

  const iconSize = size && size - 6
  return (
    <Wrapper iconSize={iconSize} isPremiumCenter size={size}>
      <FontAwesomeIcon icon={faCrown} />
    </Wrapper>
  )
}

Premium.propTypes = {
  size: PropTypes.any,
  visible: PropTypes.bool
}

Premium.defaultProps = {
  visible: true
}
