import React from 'react'
import { withNamespaces } from 'react-i18next'

const Component = props => {
  const { t, title } = props

  return <span>{t(title)}</span>
}

export const SubCategoryTitle = withNamespaces('services')(Component)
