import * as Yup from 'yup'

export const getValidationSchema = t => {
  return Yup.object().shape({
    confirmationCode: Yup.string().required(t('Required')),
    password: Yup.string()
      .required(t('validation.password'))
      .min(8, t('validation.passwordLength'))
      .matches(/[a-z]/, t('validation.passwordLowerCase'))
      .matches(/[A-Z]/, t('validation.passwordUpperCase'))
      .matches(/[0-9]/, t('validation.passwordNumber'))
      .matches(/[a-zA-Z0-9]+$/, t('validation.passwordLatin')),
    confirm: Yup.string()
      .oneOf([Yup.ref('password'), null], t('validation.passwordsDoNotMuch'))
      .required(t('validation.passwordConfirm'))
  })
}
