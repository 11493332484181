import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { width } from 'styled-system'
import { fontFamily, sizes, fontSize } from 'Constants'

export const FooterTitle = styled.h3`
  font-size: ${fontSize.h1};
  font-family: ${fontFamily};
  margin: 0;
  ${props =>
    props.state
      ? 'color: #fff;'
      : 'background:linear-gradient' +
        '(45deg, #0D61BC 33%, #0a94f1 66%, #64c3ff);' +
        '-webkit-background-clip: text;' +
        '-webkit-text-fill-color: transparent;'};
`

export const Registered = styled.sup`
  font-size: ${fontSize.h5};
  font-family: ${fontFamily};
  color: ${props => (props.state ? '#565656' : '#fff')};
`

export const FooterSubTitle = styled.h4`
  font-size: ${fontSize.default};
  font-family: ${fontFamily};
  margin: 0;
  white-space: nowrap;
  color: ${props => (props.state ? '#fff' : '#ccc')};
`

export const FooterSection = styled.footer`
  background: ${props => (props.state ? '#565656' : '#fff')};
  color: ${props => (props.state ? '#fff' : '#565656')};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 30px;
  @media (max-width: ${sizes.phone}px) {
  }
`

export const FooterList = styled.ul`
  list-style: none;
  padding-left: 0;
  @media (max-width: ${sizes.tablet}px) {
    width: 50%;
    padding-bottom: 30px;
    align-self: center;
    margin-bottom: auto;
    &:nth-child(2) {
      margin-top: 80px;
    }
    &:last-child {
      margin-top: 80px;
    }
  }
  @media (max-width: ${sizes.phone}px) {
    width: 100%;
    text-align: center;
    display: grid;
    padding-bottom: 10px;
    &:nth-child(2) {
      padding-bottom: 0;
    }
    &:last-child {
      margin-top: 0;
      padding-top: 0;
    }
  }
`

export const FooterLogo = styled(FooterList)`
  @media (max-width: ${sizes.tablet}px) {
    padding-bottom: 0;
    position: absolute;
    top: 40px;
    left: 0;
  }
`

export const FooterLi = styled.li`
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 10px;
  display: table;
  text-transform: capitalize;
  &:hover {
    opacity: 0.5;
  }
`

export const FooterLink = styled(Link)`
  text-decoration: none;
  color: ${props => (props.state ? '#fff' : '#565656')};
  font-size: ${fontSize.default};
  text-transform: capitalize;
`

export const FooterHL = styled.div`
  width: 100%;
`

export const FooterGrid = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  flex-wrap: wrap;
  padding: 50px 0 30px;
  @media (max-width: ${sizes.desktop}px) {
    justify-content: space-between;
  }
  @media (max-width: ${sizes.tablet}px) {
    justify-content: end;
    position: relative;
  }
`

export const FooterWrap = styled.div`
  width: 90%;
  max-width: 1168px;
  margin: 0 auto;
`

export const FooterWrapNav = styled.div`
  width: 90%;
  max-width: 1168px;
  margin: 0 auto;
`

export const FooterGridSocial = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  max-width: 1168px;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 30px 0;
  @media (max-width: ${sizes.desktop}px) {
    width: 100%;
    align-items: center;
  }
  ${FooterList} {
    margin: 7px;
    width: 22%;
    @media (max-width: ${sizes.desktop}px) {
      width: 22%;
    }
    @media (max-width: ${sizes.tablet}px) {
      width: 45%;
    }
    @media (max-width: ${sizes.phone}px) {
      width: 90%;
    }
  }
`

export const FooterLine = styled.div`
  width: 100%;
  border: ${props => (props.state ? '1px solid #636161' : '1px solid #eee')};
`

export const FooterCopyright = styled.div`
  display: flex;
  align-items: center;
  color: ${props => (props.state ? '#fff' : '#565656')};
  font-family: ${fontFamily};
  @media (max-width: ${sizes.phone}px) {
    width: 100%;
    text-align: center;
    line-height: 2;
    padding-left: 10px;
    padding-bottom: 20px;
    & > span {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
`

export const FooterSocial = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${sizes.phone}px) {
    margin-left: auto;
    margin-right: auto;
    padding-top: 15px;
  }
`

export const FooterSocialIcon = styled.li`
  width: 35px;
  height: 35px;
  margin-left: 10px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  vertical-align: center;
  font-weight: 900;
  display: flex;
  align-items: center;
  user-select: none;
  border: 1px solid;
  justify-content: center;
  cursor: pointer;
  background: ${props => (props.state ? 'transparent' : '#ccc')};
  transition: 0.3s;
  border: ${props =>
    props.state
      ? '1px solid ' + props.theme.colors.defaultFont
      : '1px solid ' + props.theme.colors.downloadGrey};
  &:hover {
    background: ${props =>
      props.state ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)'};
    color: #565656;
    border: ${props => (props.state ? '1px solid #fff' : '1px solid #2989d8')};
  }
  > a {
    display: flex;
    width: 35px;
    height: 35px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    text-decoration: none;
    font-family: ${fontFamily};
  }
`

export const FooterHR = styled.div`
  border: #565656;
`

export const TextWrapper = styled.div`
  ${width};
  max-height: 90vh;
  overflow-y: auto;
  @media (max-width: ${sizes.desktop}px) {
    width: auto;
  }
`

export const ContactUsWrapper = styled(TextWrapper)`
  width: min-content;
  & > p {
    white-space: nowrap;
  }
  & > p:last-child {
    text-align: end;
  }
`

export const styles = {
  bold: {
    fontWeight: 'bold'
  }
}