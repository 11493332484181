import * as Yup from 'yup'

import { urlRegex } from 'Constants'

const validationSchema = Yup.object().shape({
  Link: Yup.string()
    .matches(urlRegex, 'Must ber a valid URL')
    .required()
})

export { validationSchema }
