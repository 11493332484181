import { clientUrl, clientId, linkPaypal } from 'Constants/api'

function makeid(length) {
  var result = ''
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

const state = makeid(6)

export const requestUrl = `${linkPaypal}/connect/?flowEntry=static&client_id=${clientId}&response_type=code&scope=email&redirect_uri=${clientUrl}/withdrawal-money&state=${state}`
