import React from 'react'
import PropTypes from 'prop-types'

import { Container } from './style'

const Wrapper = ({ children }) => <Container>{children}</Container>

Wrapper.propTypes = {
  children: PropTypes.node
}

export default Wrapper
