import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'
import _ from 'lodash'
import memoize from 'memoize-one'
import { icons } from 'Assets/icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { faStar as emptyfaStar } from '@fortawesome/free-regular-svg-icons'
import { Premium, Pro, Star, PreloadImg, PreloadWrap } from 'Components/ui'
import { toast } from 'react-toastify'

import { Ad, ListAd, ProfileImage, RatingReview } from '../index'
import ReviewPuck from '../ReviewPuck'
import {
  Content,
  ContentAvatar,
  RaitingTitle,
  SubTitleAvatar,
  ReviewsWrapper,
  StarWrapper,
  TitleAvatarName,
  AvatarWrapper
} from './style'

class RatingsProfile extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isRequest: true,
      review: null
    }

    this.stars = new Array(5).fill(1)
  }

  componentDidMount() {
    const { onLoadProfile, match, onLoadReviews } = this.props

    const userId = _.get(match, 'params.userId')

    onLoadProfile(userId)
    onLoadReviews(userId)
  }

  editReviewClick = review => {
    this.setState({ review })
  }

  deleteReviewClick = ({ _id }) => {
    const { onDeleteReview } = this.props

    onDeleteReview(_id, error => {
      if (!error) toast.success('')
    })
  }

  renderReviews = memoize((data, userId) =>
    data.map((review, index) => (
      <React.Fragment key={review._id}>
        <ReviewPuck
          {...review}
          isShowButtons={userId === review.author.username}
          onDelete={() => this.deleteReviewClick(review)}
          onEdit={() => this.editReviewClick(review)}
        />
        <ListAd data={data} index={index} />
      </React.Fragment>
    ))
  )

  renderStars = memoize(profileRate =>
    this.stars.map((el, index) => (
      <Star>
        <FontAwesomeIcon
          icon={index < Number(profileRate) ? faStar : emptyfaStar}
        />
      </Star>
    ))
  )

  render() {
    const { profile, t, reviews = [], user, history, location } = this.props
    const { review } = this.state
    if (!profile) {
      return(
        <PreloadWrap>
          <PreloadImg alt="loading" src={icons.preload} />
        </PreloadWrap>
      )
    }
    if (review)
      return (
        <RatingReview
          {...review}
          history={history}
          location={location}
          review={review}
        />
      )

    return (
      <Content>
        <Ad />
        <RaitingTitle>{t('reviewRating')}</RaitingTitle>
        <ContentAvatar>
          <AvatarWrapper>
            <ProfileImage
              src={profile.avatarURL || icons.avatar}
              type="avatar"
              userId={profile.username}
            />
          </AvatarWrapper>
        </ContentAvatar>
        <SubTitleAvatar>
          <TitleAvatarName>
            {profile.short_name}
          </TitleAvatarName>
          <Premium visible={profile.isPremium} />
          <Pro visible={profile.isPro} />
        </SubTitleAvatar>

        <ReviewsWrapper>
          <StarWrapper>{this.renderStars(profile.rate)}</StarWrapper>
        </ReviewsWrapper>
        {this.renderReviews(reviews, user.username)}
        <Ad />
      </Content>
    )
  }
}

RatingsProfile.propTypes = {
  history: PropTypes.object,
  loadProfileId: PropTypes.string,
  profile: PropTypes.object,
  onDeleteReview: PropTypes.func,
  onLoadProfile: PropTypes.func
}

export default withNamespaces(['rating', 'common'])(RatingsProfile)
