import i18n from 'i18next'
import { reactI18nextModule } from 'react-i18next'
// import USER_LANG from 'Constants/userLang'
import bn from './spotJobs-localization/locales/bn'
import de from './spotJobs-localization/locales/de'
import en from './spotJobs-localization/locales/en'
import es from './spotJobs-localization/locales/es'
import fr from './spotJobs-localization/locales/fr'
import it from './spotJobs-localization/locales/it'
import pt from './spotJobs-localization/locales/pt'
import hi from './spotJobs-localization/locales/hi'
import te from './spotJobs-localization/locales/te'
import gu from './spotJobs-localization/locales/gu'
import kn from './spotJobs-localization/locales/kn'
import ml from './spotJobs-localization/locales/ml'
import mr from './spotJobs-localization/locales/mr'
import ta from './spotJobs-localization/locales/ta'
import ru from './spotJobs-localization/locales/ru'

// the translations
//for more information https://react.i18next.com/guides/step-by-step-guide
const resources = {
  bn,
  de,
  en,
  es,
  fr,
  gu,
  hi,
  it,
  kn,
  ml,
  mr,
  pt,
  ru,
  ta,
  te
}

const localLang = localStorage.getItem('lang')

i18n.use(reactI18nextModule).init({
  resources,
  lng: localLang || 'en',
  keySeparator: '.',
  interpolation: {
    escapeValue: false
  },
  fallbackLng: 'en'
})

export default i18n
