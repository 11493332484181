import { createSelector, createStructuredSelector } from 'reselect'

const getState = state => state.job

export const getJobList = createSelector(
  getState,
  jobList => jobList
)

export const getSpecificJob = createSelector(
  getState,
  data => data.jobDescription
)

export const getJobs = createSelector(
  getState,
  data => data.jobList
)

export const getFullLibraryData = createStructuredSelector({
  getJobList,
  getSpecificJob,
  getJobs
})

export const getIsEndJobsList = createSelector(
  getState,
  data => data.isListEnd
)

export const getJobsFilters = createSelector(
  getState,
  data => data.filters
)

export const getJobsPage = createSelector(
  getState,
  data => data.page
)

// const jobsData = store => store.jobs

// export const getJobsList = createSelector(
//   jobsData,
//   data => data.jobs,
// )
// export const getSpecificJob = createSelector(
//   jobsData,
//   data => data.vacancy,
// )
// export const getFavoriteJobs = createSelector(
//   jobsData,
//   data => data.favorites,
// )
// export const getIsEndJobsList = createSelector(
//   jobsData,
//   data => data.isEndJobsList,
// )
// export const getJobsPage = createSelector(
//   jobsData,
//   data => data.page,
// )
// export const getJobsFilters = createSelector(
//   jobsData,
//   data => data.filters,
// )
