import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Link } from 'react-router-dom'

import { icons } from 'Assets/icons'
import { ProfileImage } from 'Components/blocks/ProfileImage'
import { findHelp } from 'Routes/routesConstants'
import { Verified } from 'Components/ui'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/free-solid-svg-icons'

import {
  Wrapper,
  VerifiedContainer,
  AvatarContainer,
  CameraWrapper
} from './styles'

export const Avatar = ({
  id,
  avatar,
  isVerified = false,
  isBig = false,
  onClick,
  path
}) => {
  const _renderProfileImage = () => (
    <AvatarContainer isBig={isBig} onClick={onClick}>
      <ProfileImage src={avatar || icons.avatar} type="avatar" userId={id} />
    </AvatarContainer>
  )

  return (
    <Wrapper>
      <Link to={_.isFunction(onClick) ? '#' : path || `${findHelp}/${id}`}>
        {_renderProfileImage()}
      </Link>
      {isVerified && (
        <VerifiedContainer>
          <Verified />
        </VerifiedContainer>
      )}
      {isBig && (
        <CameraWrapper>
          <FontAwesomeIcon color="white" icon={faCamera} />
        </CameraWrapper>
      )}
    </Wrapper>
  )
}

Avatar.propTypes = {
  avatar: PropTypes.string,
  id: PropTypes.string,
  isVerified: PropTypes.bool,
  path: PropTypes.string,
  onClick: PropTypes.func
}
