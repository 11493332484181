import styled from 'styled-components'
import { icons } from 'Assets/icons'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 75px;
  max-height: 90px;
  min-width: 150px;
  height: 80px;
  box-shadow: 0 0 5px silver;
  position: relative;
  padding: 5px 10px;
  background-color: #fff;
  margin-bottom: 10px;
  border-radius: 5px;
  overflow: hidden;
`

export const Block = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
`

export const Image = styled.img.attrs(() => ({
  src: icons.adsHome
}))`
  width: 50px;
  margin-right: 10px;
  object-fit: cover;
`

export const TitleGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10px;
`

export const Title = styled.h1`
  font-size: ${p => p.theme.fontSize.h3};
  color: #000;
  font-weight: bold;
  margin: 0;
`

export const SubTitle = styled.h3`
  font-size: ${p => p.theme.fontSize.h4};
  color: #000;
  font-weight: bold;
  margin: 0;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const Button = styled.button`
  border: none;
  border-radius: 5px;
  background-color: ${p => p.theme.colors.joinUs};
  font-size: ${p => p.theme.fontSize.default};
  color: #fff;
  padding: 5px 10px;
`

export const Ad = styled.span`
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: ${p => p.theme.fontSize.default};
  color: silver;
`
