export default {
  picsOfWork: {
    free: 5,
    premium: 50
  },
  certificate: {
    free: 1,
    premium: 10
  },
  idPic: {
    free: 5,
    premium: 5
  },
  VIDEO_LINKS_MAX_COUNT: {
    free: 1,
    premium: 1000
  },
  WEBSITE_LINKS_MAX_COUNT: {
    free: 1,
    premium: 1000
  },
  SERVICES_MAX_COUNT: {
    free: 3,
    premium: 1000
  }
}
