import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Modal } from 'Components/ui'

import { Wrapper } from './style'

const InformationModal = ({ children, className, ...props }) => (
  <Modal {...props}>
    <Wrapper className={className}>
      {_.isString(children) ? <p>{children}</p> : children}
    </Wrapper>
  </Modal>
)

InformationModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  t: PropTypes.func
}

export { InformationModal }
