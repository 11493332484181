import { put } from 'redux-saga/effects'
import { get } from 'lodash'

import { fetchUser } from 'Redux/actions/user'
import { mountChannels } from 'Redux/actions/socket'
import { updateCredentials } from '../../../../Services/Libs/s3.lib';

function* onTokenUpdate(action) {
  const failedAction = get(action, 'meta.action', null)

  const accessToken = get(action, 'payload.AccessToken', null)
  const awsCredentials = get(action, 'payload.aws_credential', null)

  if (awsCredentials) {
    localStorage.setItem('awsCredential', JSON.stringify(awsCredentials))
    yield updateCredentials(awsCredentials)
  }

  if (accessToken) {
    localStorage.setItem('access', accessToken)
  }

  yield put(mountChannels())

  if (failedAction) {
    yield put(failedAction)
  } else {
    yield put(fetchUser())
  }

  yield true
}

export default onTokenUpdate
