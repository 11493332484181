import styled from 'styled-components'
import { Button } from 'Components/ui'
import { Link } from 'react-router-dom'

export const BodyPart = styled.div`
  display: flex;
  @media (max-width: ${p => p.theme.sizes.tablet}px) {
    flex-direction: column;
    /* width: 100%; */
  }
`

export const ImagesPart = styled.div`
  flex-basis: 50%;
  /* @media only screen and (max-width: ${p => p.theme.sizes.desktop}px) {
    flex-basis: 100%;
    margin-bottom: 20px;
    padding-right: 20px;
    & > div {
      position: static;
      top: 0;
    }
  } */
  @media only screen and (max-width: ${p => p.theme.sizes.tablet}px) {
    flex-basis: 100%;
    & > div {
      position: static;
      top: 0;
    }
  }
  @media (max-width: ${p => p.theme.sizes.tablet}px) {
    padding-right: 0;
  }
`

export const StyledButton = styled(Button)`
  width: 100%;
  height: 50px;
  text-transform: uppercase;
  margin: 20px 0 0;
`

export const JobInfoWrapper = styled(Link)`
  background-color: ${props => props.theme.colors.componentsBackground};
  border-radius: 4px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  box-shadow: ${props => props.theme.colors.boxShadow};
  transition: box-shadow 0.3s;
  text-decoration: none;
  &:hover {
    box-shadow: 0 0 5px ${p => p.theme.colors.header};
  }
  font-family: ${p => p.theme.fontFamily.default};
`

export const JobTitle = styled.span`
  font-size: ${p => p.theme.fontSize.h4};
  color: ${p => p.theme.colors.jumbotronBoldText};
  font-weight: bold;
  text-decoration: none;
`

export const JobService = styled.span`
  font-size: ${p => p.theme.fontSize.h3};
  color: ${p => p.theme.colors.joinUs};
  font-weight: bold;
  text-decoration: none;
`

export const ApplicationButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
`

export const ApplicationButton = styled(Button)`
  transition: box-shadow 0.3s;
  ${p => p.disabled && 'pointer-events: none; opacity: 0.6;'};
  width: 50%;
  &:hover {
    box-shadow: 0 0 10px silver;
  }
`

export const AcceptButton = styled(ApplicationButton)`
  border-radius: 0 15px 15px 0;
`

export const RejectButton = styled(ApplicationButton)`
  background: ${p => p.theme.colors.grayButton};
  border-radius: 15px 0 0 15px;
  &:hover {
    background: ${p => p.theme.colors.grayButton};
  }
`
