import React from 'react'
import { createStructuredSelector } from 'reselect'

import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom'

import { connect } from 'react-redux'

import { getIsAuth } from 'Redux/selectors/auth'

import {
  root,
  profile,
  subscriptions,
  jobPosts,
  findJobs,
  myRequests,
  findHelp,
  notifications,
  forgotIdPass,
  signIn,
  signUp,
  verify,
  verifyEmail,
  verifyPassword,
  termsCond,
  myFavourites,
  myJobs,
  settings,
  chats,
  privacy,
  trackNow,
  paymentsTransactions,
  paymentSetup,
  earnings,
  paymentsHistory,
  withdrawals,
  withdrawalMoney,
  postAds,
  termsCondNew
} from 'Routes/routesConstants'

import ScrollToTop from './ScrollToTop'
import { LandingPage } from 'Components/screens/LandingPage'
import HomePage from 'Components/screens/HomePage'

import { JobPosts } from 'Components/screens/JobPosts'
import FindJob from 'Components/screens/FindJob'
import MyRequests from 'Components/screens/MyRequests'
import FindHelp from 'Components/screens/FindHelp'
import Notifications from 'Components/screens/Notifications'
import ForgotIdPass from 'Components/screens/ForgotIdPass'
import SignIn from 'Components/screens/SignIn'
import SignUp from 'Components/screens/SignUp'
import Verify from 'Components/screens/Verify'
import VerifyPassword from 'Components/screens/ForgotPassVerify'
import { TermsCond } from 'Components/screens/TermsCond'
import { TermsCondNew } from 'Components/screens/TermsCondNew'
import Privacy from 'Components/screens/Privacy'
import ProfilePage from 'Components/screens/ProfilePage'
// import Subscriptions from 'Components/screens/Subscriptions'
import Favourites from 'Components/screens/Favourites'
import MyJobs from 'Components/screens/MyJobs'
import PostAds from 'Components/screens/PostAds'
import Settings from 'Components/screens/Settings'
import Chats from 'Components/screens/Chats'
import TrackNow from 'Components/screens/TrackNow'
import SuccessfulPayment from 'Components/screens/SuccessfulPayment'
import VerifyEmail from 'Components/screens/VerifyEmail'
import PaymentsTransactions from 'Components/screens/PaymentsTransactions'
import PaymentSetup from 'Components/screens/PaymentSetup'
import Earnings from 'Components/screens/Earnings'
import PaymentsHistory from 'Components/screens/PaymentsHistory'
import Withdrawals from 'Components/screens/Withdrawals'
import WithdrawalMoney from 'Components/screens/WithdrawalMoney'

const AppRouter = ({ isAuth }) => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        {!isAuth ? (
          <Switch>
            <Route component={LandingPage} exact path={root} />
            <Route component={ForgotIdPass} exact path={forgotIdPass} />
            <Route component={SignIn} exact path={signIn} />
            <Route component={SignUp} exact path={signUp} />
            <Route component={Verify} exact path={verify} />
            <Route component={VerifyEmail} exact path={verifyEmail} />
            <Route component={VerifyPassword} exact path={verifyPassword} />
            <Route component={TermsCond} exact path={termsCond} />
            <Route component={TermsCondNew} exact path={termsCondNew} />
            <Route component={Privacy} exact path={privacy} />
            <Route component={FindJob} path={findJobs} />
            <Route component={FindHelp} path={findHelp} />
            <Redirect to={root} />
          </Switch>
        ) : (
          <Switch>
            <Route component={VerifyEmail} exact path={verifyEmail} />
            <Route component={JobPosts} exact path={jobPosts} />
            <Route component={HomePage} exact path={root} />
            <Route component={ProfilePage} exact path={profile} />
            <Route component={FindJob} path={findJobs} />
            <Route component={Notifications} exact path={notifications} />
            <Route component={FindHelp} path={findHelp} />
            <Route component={TermsCond} exact path={termsCond} />
            <Route component={TermsCondNew} exact path={termsCondNew} />
            <Route component={Privacy} exact path={privacy} />
            <Route component={Favourites} exact path={myFavourites} />
            <Route component={MyJobs} path={myJobs} />
            <Route component={Settings} exact path={settings} />
            <Route component={MyRequests} path={myRequests} />
            <Route component={Chats} path={chats} />
            <Route component={TrackNow} path={trackNow} />
            <Route component={PostAds} path={postAds} />
            <Route
              component={SuccessfulPayment}
              exact
              path={`${subscriptions}/:paymentId`}
            />
            <Route
              component={PaymentsTransactions}
              exact
              path={paymentsTransactions}
            />
            <Route component={PaymentSetup} exact path={paymentSetup} />
            <Route component={Earnings} exact path={earnings} />
            <Route component={PaymentsHistory} exact path={paymentsHistory} />
            <Route component={Withdrawals} exact path={withdrawals} />
            <Route component={WithdrawalMoney} exact path={withdrawalMoney} />
          </Switch>
        )}
      </ScrollToTop>
    </BrowserRouter>
  )
}

const selector = createStructuredSelector({
  isAuth: getIsAuth
})

export default connect(selector)(AppRouter)
