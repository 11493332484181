import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
  setJobAlerts,
  setAccountStatus,
  setNotifications
} from 'Redux/actions/settings'

import { getSettings, getActiveLanguage } from 'Redux/selectors/settings'
import { getProfile } from 'Redux/selectors/user'

import Component from './Settings'

const actions = {
  onSetJobAlerts: setJobAlerts,
  onSetAccountStatus: setAccountStatus,
  onSetNotifications: setNotifications,
}

const selectors = createStructuredSelector({
  settings: getSettings,
  activeLanguage: getActiveLanguage,
  user: getProfile
})

export default connect(
  selectors,
  actions
)(Component)
