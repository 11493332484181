//apiUrl dev 'https://23cnnk7jt0.execute-api.us-east-1.amazonaws.com/dev'
export const apiUrl = process.env.REACT_APP_API_URL

//apiUrl secondApi 'http://spotjobs-socket-server-768469600.us-east-1.elb.amazonaws.com'
export const secondApi = process.env.REACT_APP_SECOND_API

//clientUrl dev 'https://dzbbrssnsxdez.cloudfront.net'
export const clientUrl = process.env.REACT_APP_CLIENT_URL

export const appBundleId = process.env.REACT_APP_APP_BUNDLE_ID

export const logoPath = process.env.REACT_APP_LOGO_PATH

export const linkAppleStore = process.env.REACT_APP_APPLE_STORE

export const linkPlayStore = process.env.REACT_APP_PLAY_STORE

export const linkPaypal = process.env.REACT_APP_PAYPAL_URL

export const clientId = process.env.REACT_APP_CLIENT_ID
