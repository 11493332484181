import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMicrophone } from '@fortawesome/free-solid-svg-icons'
import { fontFamily, fontSize } from 'Constants'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 600px;
  max-height: 600px;
`

export const ChatsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
`

export const ChatWrapper = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px silver;
  border-radius: 10px;
`

export const ChatsList = styled.div`
  height: 100%;
  overflow-y: auto;

`

export const ChatPuckWrapper = styled.div`
  margin-top: 10px;
  padding: 0 5px;
  cursor: ${p => (p.isActive ? 'default' : 'pointer')};
  &:last-child {
    margin-bottom: 10px;
  }
`

export const InputWrapper = styled.form`
  padding: 15px 20px;
  background: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  border-radius: 0 0 10px 10px;
  opacity: ${p => p.active ? 1 : 0.5};
  pointer-events: ${p => p.active ? 'unset' : 'none'};
`

export const Input = styled.textarea`
  font-size: ${p => p.theme.fontSize.default};
  color: #000000;
  padding: 5px;
  border: solid 1px silver;
  /* border-radius: 5px; */
  border-radius: 5px 0 0 5px;
  /* margin-right: 20px; */
  width: 95%;
  height: 30px;
  overflow: none;
  outline: none;
  resize: none;
`

export const VoiceButton = styled(FontAwesomeIcon).attrs(() => ({
  icon: faMicrophone
}))`
  color: ${p => p.recording === 'true' ? p.theme?.colors?.joinUs : '#7990a2'};
  transform: scale(1.2);
  cursor: pointer;
`
export const Mic = styled.div`
  opacity: 0;
  width: 0; 
  height: 0;
  > canvas {
    width: 0;
    height: 0;
  }
`

export const SelectChatText = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${p => p.theme.fontSize.h1};
  color: #000;
  font-weight: bold;
`

export const SubmitButton = styled.button.attrs(props => ({
  type: 'button'
}))`
  width: 65px;
  height: 30px;
  margin-right: 10px;
  color: #fff;
  background: linear-gradient(
    to right,
    rgba(10, 148, 241, 1) 0%,
    rgba(10, 148, 241, 1) 56%,
    rgba(100, 195, 255, 1) 100%
  );
  font-size: ${fontSize.h5};
  font-family: ${fontFamily};
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background: linear-gradient(
      to left,
      rgba(10, 148, 241, 1) 0%,
      rgba(10, 148, 241, 1) 56%,
      rgba(100, 195, 255, 1) 100%
    );
  }
`

export const ChatInActiveWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`
