import PropTypes from 'prop-types'
import React from 'react'
import { withNamespaces } from 'react-i18next'
import { toast } from 'react-toastify'
import _ from 'lodash'
import moment from 'moment'
import getJobImage from 'Helpers/jobImage'

import { Avatar, NotificationText, NotificationIcon } from 'Components/ui'

import { NotificationWrapper, JobImage, TextPart, Indicator } from './styles'
import { getUrl } from './config'
import { idVerificationStatus } from 'Constants/idVerification'

const Notification = ({
  user,
  job,
  reason,
  type,
  isRead,
  t,
  date,
  jobId,
  userId,
  requestId,
  currentUserId,
  userCancelId,
  userCancelName,
  onMarkAsRead,
  onOpenTrack,
  jobDoer,
  jobTitleUnavailable,
  isAuthor,
  netMoneyWithdraw
}) => {
  const renderImage = () => {
    if (_.has(job, 'pics[0]')) {
      return (
        <JobImage alt={t(job.title)} src={getJobImage(job._id, job.pics[0])} />
      )
    }

    if (_.has(user, 'username')) {
      return <Avatar avatar={user.avatarURL} isVerified={user?.id_verification === idVerificationStatus.success} />
    }

    return <NotificationIcon />
  }

  const onClickNotification = () => {
    onMarkAsRead(requestId)
    if(type === 'trackingAvailable') {
      onOpenTrack(job)
      return
    }
    if(type === 'jobUnavailable'){
      toast.warn(t('notifications:jobLongerAvailable'))
    }
  }

  return (
    <NotificationWrapper
      to={getUrl(type, jobId, userId, requestId, jobDoer, isAuthor)}
      onClick={onClickNotification}
    >
      {renderImage()}
      <TextPart>
        <NotificationText
          isRead={isRead}
          job={job}
          reason={reason}
          type={type}
          user={user}
          currentUserId={currentUserId}
          userCancelId={userCancelId}
          userCancelName={userCancelName}
          userId={userId}
          jobDoer={jobDoer}
          jobTitleUnavailable={jobTitleUnavailable}
          isAuthor={isAuthor}
          netMoneyWithdraw={netMoneyWithdraw}
        />
        <span>
          {t('on')} <b>{moment(date).format('DD/MM/YYYY')}</b> {t('at')}{' '}
          <b>{moment(date).format('hh:mm A')}</b>
        </span>
      </TextPart>
      {!isRead && <Indicator />}
    </NotificationWrapper>
  )
}

Notification.propTypes = {
  date: PropTypes.string,
  isRead: PropTypes.bool,
  job: PropTypes.object,
  jobId: PropTypes.string,
  reason: PropTypes.string,
  t: PropTypes.func,
  type: PropTypes.string,
  user: PropTypes.object,
  userId: PropTypes.string,
  onMarkAsRead: PropTypes.func,
  onOpenTrack: PropTypes.func
}

export default withNamespaces('notifications')(Notification)
