import React from 'react'
import PropTypes from 'prop-types'
import { omit } from 'lodash'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { getCurrencyRatio } from 'Redux/actions/paymentsTransactions'

function withCurrency(Component) {
  return class extends React.Component {
    static displayName = 'withCurrency'

    static propTypes = {
      onGetRatio: PropTypes.func
    }

    state = {
      loading: false
    }

    handleGetRatio = async (currency, callback) => {
      try {
        if (!currency || currency === 'USD') return callback(1)
        const response = await this.props.onGetRatio(currency, callback)
        return callback(response.payload.exchangeRate)
      } catch (error) {
        return callback(1)
      }
    }

    render() {
      return (
        <Component
          {...omit(this.props, ['onGetRatio'])}
          onGetRatio={this.handleGetRatio}
        />
      )
    }
  }
}

const actions = {
  onGetRatio: getCurrencyRatio
}

export default compose(
  connect(
    null,
    actions
  ),
  withCurrency
)
