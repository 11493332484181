import PropTypes from 'prop-types'
import React from 'react'
import { isFunction, get } from 'lodash'
import { Link } from 'react-router-dom'
import * as routes from 'Routes/routesConstants'
import memoize from 'memoize-one'
import { Avatar as Ava } from 'Components/ui'

import {
  Wrapper,
  AvatarsWrapper,
  Avatar,
  ViewAll,
  Header,
  Title
} from './styles'
import { getPeopleWhoApplied } from './memoize'
import { idVerificationStatus } from 'Constants/idVerification'

const WhoApplied = ({
  author,
  t,
  onClick,
  onClickProfile,
  data,
  location,
  user
}) => {
  const peopleWhoApplied = getPeopleWhoApplied(author, data, user?.username)

  return (
    <Wrapper>
      <Header>
        <Title>{t('jobDetail:seePeopleWhoApplied')}</Title>
      </Header>
      <AvatarsWrapper>
        {peopleWhoApplied.map((item, key) =>
          isFunction(onClickProfile) ? (
            <Avatar index={data.length - key} key={key}>
              <Ava
                avatar={get(item, 'user.avatarURL')}
                id={data.userId}
                key={key}
                onClick={memoize(() => onClickProfile(item))}
                isVerified={get(item, 'user.id_verification') === idVerificationStatus.success}
              />
            </Avatar>
          ) : (
            <Avatar index={data.length - key} key={key}>
              <Ava
                avatar={get(item, 'user.avatarURL')}
                id={data.userId}
                key={key}
                path={
                  location.pathname +
                  routes.appliedServiceProviders +
                  '/' +
                  get(item, 'user.username')
                }
                isVerified={get(item, 'user.id_verification') === idVerificationStatus.success}
              />
            </Avatar>
          )
        )}
        {isFunction(onClick) ? (
          <ViewAll onClick={onClick}>{t('viewAll')}</ViewAll>
        ) : (
          <Link to={location.pathname + routes.appliedServiceProviders}>
            <ViewAll onClick={onClick}>{t('viewAll')}</ViewAll>
          </Link>
        )}
      </AvatarsWrapper>
    </Wrapper>
  )
}

WhoApplied.propTypes = {
  author: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  data: PropTypes.array,
  location: PropTypes.object,
  t: PropTypes.func,
  user: PropTypes.object,
  onClick: PropTypes.func
}

export default WhoApplied
