import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import InfiniteScroll from 'react-infinite-scroller'
import { Switch, Redirect, Route } from 'react-router-dom'
import qs from 'qs'
import memoize from 'memoize-one'
import * as routes from 'Routes/routesConstants'
import { jobStatuses } from 'Constants/jobStatuses'

import { TABS_LIST_MAP } from 'Constants/tabNav'
import { icons } from 'Assets/icons'

import {
  BreadCrumbs,
  Header,
  JobCard,
  RightSidebar,
  Ad,
  Filter,
  BodySearch,
  Sort,
  Footer,
  BurgerMenu,
  HeaderTabs,
  MapView,
  ProgressBar,
  ListAd,
  JobView,
  ApplyBySection,
  FreelancerProfileView
} from 'Components/blocks'

import {
  PageWrapper,
  ContentWrapper,
  CardWrapper,
  RightSideBarWrapper,
  TopPart,
  DownPart,
  FiltersGroup,
  SSGroup,
  PreloadImg,
  Msg,
  LoadMoreWrapper
} from 'Components/ui'

import { sortData, path } from './config'
import { Wrapper, GoogleAdsBanner } from './style'
import {
  getJobsListBC,
  getJobsMapBC,
  getJobDescrBC,
  getPeopleWhoAppliedBC,
  getAppliedProfileBC
} from './memoize'
import { toast } from 'react-toastify'

let isRefresh = true

class FindJob extends React.Component {
  constructor(props) {
    super(props)

    const state = {
      isRequest: true,
      elements_per_page: 10,
      pageNumber: 0,
      order_by: 'distance',
      order: 'asc',
      keywords: _.get(props.location.search.slice(1), 'keywords', ''),
      isLoadMoreRequest: false,
      activeCategoriesIds: [],
      activeTabId: 'a',
      author: null,
      location: {
        location: undefined
      },
      idCardFavouriteLoad: null,
      isLoadingNewCards: false,
      hasMore: false,
    }

    const queryService = _.get(
      qs.parse(props.location.search.slice(1)),
      'service'
    )

    if (queryService) state.activeCategoriesIds.push(queryService)

    this.state = state
  }

  componentDidMount() {
    const { location: { state: navigateState } } = this.props

    // isRefresh
    this.setState({
      isRequest: false,
      activeCategoriesIds: navigateState?.filter?.categories || []
    }, () => {
      this.handleLoadJobs({
        author: undefined,
        location: navigateState?.filter?.location,
        categories: navigateState?.filter?.categories
      })
    })
  }

  handleLoadJobs = (filters = {}, page = 0, isClearRemoved = false, isSort = false) => {
    const { onGetJobList, user } = this.props
    const {
      order_by,
      order,
      location,
      keywords,
      activeCategoriesIds,
      pageNumber
    } = this.state



    onGetJobList({
      isPagination: pageNumber > 0 && !isSort,
      savedFilters: filters,
      isClearRemoved,
      filters: {
        page_number: isSort ? 0 : pageNumber,
        radius: !_.isEmpty(user)
          ? _.get(filters, 'radius', user.defaultRadius)
          : 1000000000000,
        geolocation:
          filters.lat && filters.lon
            ? `${filters.lat}/${filters.lon}`
            : (filters.location || location.location || null),
        categories: !_.isEmpty(filters.categories)
          ? JSON.stringify(filters.categories)
          : activeCategoriesIds,
        keywords: _.get(filters, 'keywords') || keywords,
        order_by: _.get(filters, 'sortBy') || order_by,
        order: _.get(filters, 'order', 'asc') || order,
        author: _.get(filters, 'author')
      },
      callback: (error, response) => {
        isRefresh = true
        if (response && response.isListEnd === false) {
          this.setState({ isLoadingNewCards: false, hasMore: true, pageNumber: pageNumber + 1 })
        } else {
          this.setState({ isLoadingNewCards: false, hasMore: false, pageNumber: pageNumber + 1 })
        }
      }
    })
  }

  handleFavouriteClick = (id, favourite) => {
    const { onRemoveFromFavourites, onAddToFavourites } = this.props

    this.setState({ idCardFavouriteLoad: id })
    if (favourite) {
      onRemoveFromFavourites(favourite, () => {
        this.setState({ idCardFavouriteLoad: null })
      })
    } else {
      onAddToFavourites(id, () => {
        this.setState({ idCardFavouriteLoad: false })
      })
    }
  }

  handleRemoveClick = id => {
    const { onRemove } = this.props

    onRemove(id)
  }

  handleEditClick = job => {
    const { history } = this.props

    history.push({
      pathname: routes.jobPosts,
      params: {
        data: {
          ...job,
          category: _.get(job, 'category._id', job.category),
          service: _.get(job, 'service._id', job.service)
        }
      }
    })
  }

  handleDeleteClick = id => {
    const { onDeleteJob, t } = this.props

    onDeleteJob(id, error => !error && toast.success(t('common:JobDeleted')))
  }

  handleTabClick = id => {
    const { history } = this.props
    const jobId = window.location.pathname.slice(
      window.location.pathname.indexOf('/', 12)
    )
    if (
      window.location.pathname === '/find-jobs/list' ||
      window.location.pathname === '/find-jobs/map'
    ) {
      history.push(id === 'a' ? path.jobsList : path.jobsMap)
    } else {
      history.push(id === 'a' ? `${path.jobsList}${jobId}` : `${path.jobsMap}`)
      // history.push(id === 'a' ? path.jobsList : path.jobsMap)
    }
    // history.push(id === 'a' ? path.jobsList : path.jobsMap)
  }

  handleClearKeywords = () => this.setState({ keywords: '' })

  onFilterModalSubmit = data => {
    if (data.clear) {
      this.setState({ location: {}, activeCategoriesIds: [] }, this.onFilter)
    } else this.onFilter(data)
  }

  onFilter = (data = {}) => {
    const { user } = this.props
    const { order_by, order, keywords } = this.state

    let location = this.state.location
    if (!_.isEmpty(data.location)) {
      location = {
        ...data.locationFields,
        location: data.location
      }
    }

    const geolocation = data.location || user.geolocation
    let lat,
      lon = null
    if (geolocation) {
      lat = +geolocation.slice(0, geolocation.indexOf('/'))
      lon = +geolocation.slice(lat.toString().length + 1)
    }

    const request = {
      order_by,
      order,
      categories: _.isEmpty(data.services)
        ? undefined
        : data.services,
      keywords,
      lat,
      lon
    }

    this.setState({
      activeCategoriesIds: _.isEmpty(data.services) ? [] : data.services,
      location,
      isLoadingNewCards: true
    })
    this.handleLoadJobs(request, 0, true)
  }

  onClickPopularService = ({ services }) => {
    const { activeCategoriesIds = [] } = this.state
    const id = services[0]
    let result = []
    if (activeCategoriesIds.indexOf(id) === -1) {
      result = activeCategoriesIds.concat(services)
    } else {
      result = activeCategoriesIds.filter(el => el !== id)
    }

    this.onFilter({ services: result })
  }

  onHeaderFilter = ({ keywords, service }) => {
    const { history } = this.props

    history.replace(`${routes.findJobs}${routes.listView}`)
    if (_.isString(keywords)) this.setState({ keywords }, this.onFilter)
    if (_.isString(service)) this.onFilter({ services: [service] })
  }

  handleLoadMoreJobs = () => {
    const { isEndJobsList, jobsFilters, jobsPage } = this.props

    if (!isEndJobsList && isRefresh) {
      isRefresh = false
      this.handleLoadJobs(jobsFilters, jobsPage + 1)
    }
  }

  handleSorting = ({ order, orderBy }) => {
    this.setState(
      { order_by: orderBy, order, pageNumber: 0 },
      this.handleLoadJobs({ sortBy: orderBy, order }, 0, false, true)
    )
  }

  handleOpenAppliedProfile = (profile, application) => {
    const { history } = this.props

    history.push(
      `${routes.findJobs}${routes.listView}/${application.jobId}${routes.appliedServiceProviders}/${application._id}/${profile.username}`
    )
  }

  handleSearch = keywords => {
    return this.setState(
      { keywords },
      this.handleLoadJobs({ keywords }, 0, true)
    )
  }

  handleHireClick = () => {
    const {
      serviceProvider: { serviceProvider },
      onSendRequest
    } = this.props

    onSendRequest(_.get(serviceProvider, 'username'))
  }

  renderListContent = props => {
    const { job, onShare, onApplyJob, t, services } = this.props
    const {
      isRequest,
      activeCategoriesIds,
      idCardFavouriteLoad,
      order_by: orderBy,
      location,
      isLoadingNewCards,
      hasMore
    } = this.state

    const breadCrumbs = getJobsListBC(t)

    return (
      <>
        <HeaderTabs
          activeTabIndex="a"
          options={TABS_LIST_MAP}
          onClickLink={this.handleTabClick}
        />
        <BreadCrumbs data={breadCrumbs} />
        <TopPart>
          <FiltersGroup>
            <Filter
              handleFilter={this.onFilterModalSubmit}
              initialValues={{
                location: location.location,
                adress: location,
                services: activeCategoriesIds
              }}
              isfindJob
            />
            <SSGroup>
              <BodySearch
                services={services.services}
                onFocus={this.handleClearKeywords}
                onSetService={memoize(service =>
                  this.onFilter({ services: [service] })
                )}
                onSubmit={this.handleSearch}
              />
              <Sort
                data={sortData}
                value={orderBy}
                onSelect={this.handleSorting}
              />
            </SSGroup>
          </FiltersGroup>
        </TopPart>
        <DownPart>
          <CardWrapper>
            <GoogleAdsBanner />
            {isLoadingNewCards && (
              <PreloadImg alt="loading" src={icons.preload} />
            )}
            {isRequest && _.isEmpty(job.jobList) ? (
              <PreloadImg alt="loading" src={icons.preload} />
            ) : (
              <InfiniteScroll
                hasMore={hasMore}
                loadMore={this.handleLoadMoreJobs}
                loader={
                  <LoadMoreWrapper key={-1}>
                    <PreloadImg
                      alt="loading"
                      className="loader"
                      src={icons.preload}
                    />
                  </LoadMoreWrapper>
                }
                useWindow
              >
                {!_.isEmpty(job.jobList)
                  && job.jobList.map((item, key) => (
                      <Fragment key={key}>
                        <JobCard
                          {...item}
                          isCheck={this.state.isCheck}
                          isCheckArr={this.state.isCheckArr}
                          isLoadingFavourite={idCardFavouriteLoad === item._id}
                          path={`${routes.findJobs}${routes.listView}/${item._id}`}
                          onHandleApply={memoize((cb) => onApplyJob(item, cb))}
                          onHandleDeleteClick={this.handleRemoveClick}
                          onHandleFavouriteClick={this.handleFavouriteClick}
                          onShareClick={memoize(() => onShare(item))}
                        />
                        <ListAd data={job.jobList} index={key} />
                      </Fragment>
                    ))}
              </InfiniteScroll>
            )}
            {!_.isEmpty(job.jobList) && <Ad />}
          </CardWrapper>
          <RightSideBarWrapper>
            <RightSidebar
              activeCategoriesIds={activeCategoriesIds}
              handleFilter={this.onClickPopularService}
            />
          </RightSideBarWrapper>
        </DownPart>
      </>
    )
  }

  renderMapCard = card => {
    const { onShare, onApplyJob } = this.props

    return (
      <JobCard
        {...card}
        path={`${routes.findJobs}${routes.mapView}/${card._id}`}
        onHandleApply={memoize((cb) => onApplyJob(card, cb))}
        onHandleFavouriteClick={this.handleFavouriteClick}
        onShareClick={memoize(() => onShare(card))}
      />
    )
  }

  renderMapView = () => {
    const { job, t, services } = this.props
    const {
      isRequest,
      activeCategoriesIds,
      order_by: orderBy,
      location: savedLocation
    } = this.state

    const breadCrumbs = getJobsMapBC(t)

    return (
      <>
        <HeaderTabs
          activeTabIndex="b"
          options={TABS_LIST_MAP}
          onClickLink={this.handleTabClick}
        />
        <BreadCrumbs data={breadCrumbs} />
        <TopPart>
          <FiltersGroup>
            <Filter
              handleFilter={this.onFilterModalSubmit}
              initialValues={{
                location: savedLocation,
                services: activeCategoriesIds
              }}
              isfindJob
            />
            <SSGroup>
              <BodySearch
                services={services.services}
                onFocus={this.handleClearKeywords}
                onSetService={memoize(service =>
                  this.onFilter({ services: [service] })
                )}
                onSubmit={this.handleSearch}
              />
              <Sort
                data={sortData}
                value={orderBy}
                onSelect={this.handleSorting}
              />
            </SSGroup>
          </FiltersGroup>
        </TopPart>
        <MapView
          data={job.jobList}
          loading={isRequest}
          renderCard={this.renderMapCard}
        />
      </>
    )
  }

  _renderChatButton = () => {
    const { user, history, job: { jobDescription } = {} } = this.props
    if (!jobDescription) return null

    const jobByUser = jobDescription.peopleWhoApplied.find(item => item.userId === user._id) || {}
    const showChatButton = jobByUser.status === 'accepted'

    if (!showChatButton) return null

    return (
      <Msg
        isRequest={false}
        onClick={() => history.push(`/chats/${jobDescription._id}#${jobByUser.user.chatId || ''}`)}
      />
    )
  }

  renderJobDescription = props => {
    const {
      t,
      job: { jobDescription },
      user,
      onCancelJob
    } = this.props

    const isListView = props.location.pathname.includes(routes.listView)

    const breadCrumbs = getJobDescrBC(
      t,
      isListView,
      _.get(jobDescription, 'title')
    )

    const status = _.get(jobDescription, 'jobStatus')

    const canCancel =
      status === jobStatuses.booked ||
      _.get(jobDescription, 'peopleWhoApplied', []).some(
        el => el.userId === user.username
      )

    return (
      <>
        <HeaderTabs
          activeTabIndex={isListView ? 'a' : 'b'}
          isHidden={true}
          options={TABS_LIST_MAP}
          onClickLink={this.handleTabClick}
        />
        <BreadCrumbs data={breadCrumbs} />
        <ContentWrapper>
          <Wrapper>
            <JobView
              onCancelClick={
                canCancel ? () => onCancelJob(jobDescription) : undefined
              }
              onDeleteClick={memoize(() =>
                this.handleDeleteClick(_.get(jobDescription, '_id'))
              )}
              onEditClick={memoize(() => this.handleEditClick(jobDescription))}
              onOpenApplyByProfile={this.handleOpenAppliedProfile}
            />
          </Wrapper>
          {this._renderChatButton()}
        </ContentWrapper>
      </>
    )
  }

  renderPeopleWhoApplied = props => {
    const { t, job } = this.props
    const { location } = props

    const breadCrumbs = getPeopleWhoAppliedBC(
      t,
      location.pathname.includes(routes.listView),
      _.get(job, 'jobDescription.title'),
      _.get(job, 'jobDescription._id')
    )
    return (
      <>
        <BreadCrumbs data={breadCrumbs} />
        <ApplyBySection />
      </>
    )
  }

  renderAppliedProfile = props => {
    const {
      t,
      job: { jobDescription: job = {} },
      serviceProvider: { serviceProvider },
      onAccept,
      onHire,
      onReject,
      history,
      user
    } = this.props
    const { location, match } = props
    const breadCrumbs = getAppliedProfileBC(
      t,
      location.pathname.includes(routes.listView),
      job?.title,
      job?._id,
      serviceProvider.short_name
    )

    const { requestId } = match.params

    const author = _.get(job, 'author.username', job?.author)

    const application = job?.peopleWhoApplied?.find(el => el._id === requestId)

    return (
      <>
        <BreadCrumbs data={breadCrumbs} />
        <Wrapper>
          <FreelancerProfileView
            application={user.username === author ? application : undefined}
            isMyJob={user.username === author}
            onAcceptApplication={memoize(() => onAccept(application))}
            onHire={this.handleHireClick}
            onHireApplication={memoize(() => onHire(application))}
            onRejectApplication={memoize(() =>
              onReject(application, history.goBack)
            )}
          />
        </Wrapper>
      </>
    )
  }

  render() {
    const { user } = this.props
    const { isRequest } = this.state
    this.props.onClose()
    return (
      <PageWrapper>
        <ProgressBar percent={isRequest ? 1 : 100} />
        {user && <BurgerMenu user={user} />}
        <Header
          handleSearchJob={this.onHeaderFilter}
          postJob={false}
          view={Object.keys(user).length === 0}
        />
        <ContentWrapper>
          <Switch>
            <Route path={path.jobsMap}>
              <Switch>
                <Route path={path.mapJobDescription}>
                  <Switch>
                    <Route
                      exact
                      path={path.mapJobPeopleWhoApplied}
                      render={this.renderPeopleWhoApplied}
                    />
                    <Route
                      path={path.mapAppliedProfile}
                      render={this.renderAppliedProfile}
                    />
                    <Route render={this.renderJobDescription} />
                  </Switch>
                </Route>
                <Route render={this.renderMapView} />
              </Switch>
            </Route>

            <Route path={path.jobsList}>
              <Switch>
                <Route path={path.listJobDescription}>
                  <Switch>
                    <Route
                      exact
                      path={path.listJobPeopleWhoApplied}
                      render={this.renderPeopleWhoApplied}
                    />
                    <Route
                      path={path.listAppliedProfile}
                      render={this.renderAppliedProfile}
                    />
                    <Route render={this.renderJobDescription} />
                  </Switch>
                </Route>
                <Route render={this.renderListContent} />
              </Switch>
            </Route>

            <Redirect to={path.jobsList} />
          </Switch>
        </ContentWrapper>
        <Footer black={true} isAuth={user} />
      </PageWrapper>
    )
  }
}

FindJob.propTypes = {
  job: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  serviceProvider: PropTypes.object,
  services: PropTypes.object,
  t: PropTypes.func,
  user: PropTypes.object,
  onAddToFavourites: PropTypes.func,
  onApplyJob: PropTypes.func,
  onGetJobList: PropTypes.func,
  onGetPopularServices: PropTypes.func,
  onRemove: PropTypes.func,
  onRemoveFromFavourites: PropTypes.func,
  onShare: PropTypes.func,
  onCancelJob: PropTypes.func
}

export default FindJob
