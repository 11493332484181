import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { forgotPasswordConfirm, forgotPassword } from 'Redux/actions/auth'
import Component from './VerifyPassword'

const selector = createStructuredSelector({})

const actions = {
  onPasswordChange: forgotPasswordConfirm,
  onResend: forgotPassword
}

export default connect(
  selector,
  actions
)(Component)
