import React from 'react'

import i18n from 'i18next'
import { Modal } from 'Components/ui'
import { PaymentFailedContainer, PaymentFailedText } from './style'

const PaymentFailedModal = ({ isVisible, onClose }) => {
  return (
    <Modal zIndex={9999} isShow={isVisible} onRequestClose={onClose}>
      <PaymentFailedContainer>
        <PaymentFailedText size={20} bottom={20}>{i18n.t('myJobsDetail:paymentFailed')}</PaymentFailedText>
        <PaymentFailedText>{i18n.t('myJobsDetail:paymentFailDescription')}</PaymentFailedText>
      </PaymentFailedContainer>
    </Modal>
  )
}

PaymentFailedModal.propTypes = {

}

export default PaymentFailedModal
