import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { withNamespaces } from 'react-i18next'

import { Premium, Avatar, Profession, Pro } from 'Components/ui'

import {
  ServiceProvidersItemWrapper,
  InfoGroup,
  Title,
  Stars,
  Icons,
  ProContainer
} from '../styles'
import { idVerificationStatus } from 'Constants/idVerification'

const ServiceProvidersItem = ({ item, t }) => {
  const name = item.short_name

  return (
    <ServiceProvidersItemWrapper>
      <Avatar
        avatar={item.avatarURL}
        id={item.username}
        isVerified={item.id_verification === idVerificationStatus.success}
      />
      <InfoGroup>
        <Title>
          {name} {item.isPremium && <Premium />}
        </Title>
        <Icons>
          <Stars>
            <FontAwesomeIcon icon={faStar} />
            <span>{item.rate || 0}</span>
          </Stars>
          <ProContainer>
            <Pro size={17} visible={item.isPro} />
          </ProContainer>
        </Icons>
        <Profession professions={item.services} />
      </InfoGroup>
    </ServiceProvidersItemWrapper>
  )
}

ServiceProvidersItem.propTypes = {
  item: PropTypes.object,
  t: PropTypes.func
}

export default withNamespaces('findHelp')(ServiceProvidersItem)

export { ServiceProvidersItem }
