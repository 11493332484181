import * as Yup from 'yup'

import { icons } from 'Assets/icons'

export const getValidationSchema = (isEdit, t) => {
  return Yup.object().shape({
    firstName: Yup.string().required(
      t('fields.firstName.validationErrors.required')
    ),
    lastName: Yup.string().required(
      t('fields.lastName.validationErrors.required')
    ),
    address: Yup.string().required(
      t('fields.address.validationErrors.required')
    ),
    city: Yup.string().required(t('fields.city.validationErrors.required')),
    state: Yup.string().required(t('fields.state.validationErrors.required')),
    zip: Yup.string().required(t('fields.zipCode.validationErrors.required')),
    country: Yup.string().required(
      t('fields.country.validationErrors.required')
    ),
    phone: Yup.string()
      .matches(/^[0-9]*$/, `${t('sign:WrongPhoneNumberFormat')}`)
      .required(`${t('fields.phoneNumber.validationErrors.required')}`),
    cardNumber: Yup.string().required(
      t('fields.debitOrCreditCardNumber.validationErrors.required')
    ),
    expiryDate: Yup.string().required(
      t('fields.expiryDate.validationErrors.required')
    ),
    csc:
      !isEdit &&
      Yup.string()
        .min(3, 'CSC must have min 3 characters')
        .max(4, 'CSC must have max 4 characters')
        .required(t('fields.csc.validationErrors.required')),
    accountType: Yup.string().required(
      t('fields.accountType.validationErrors.required')
    )
  })
}

export const cards = [
  {
    id: 1,
    type: 'visa',
    icon: icons.visaCard
  },
  {
    id: 2,
    type: 'mastercard',
    icon: icons.masterCard
  },
  {
    id: 3,
    type: 'amex',
    icon: icons.amexCard
  },
  {
    id: 4,
    type: 'discover',
    icon: icons.discoverCard
  },
  {
    id: 5,
    type: 'maestro',
    icon: icons.maestroCard
  },
]
