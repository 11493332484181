import React from 'react'
import { Map, Marker, Circle } from 'google-maps-react'

import { 
        // StyledThumb,
        //  StyledTrack, 
        //  StyledSlider, 
        //  StyledSliderContainer, 
         GoogleMapContainer ,
        //   StyledRadiusLabel
        } from './styles';

// const Thumb = (props, state) => <StyledThumb {...props}>{state.valueNow}</StyledThumb>;
// const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

const MyGoogleMap = ({ google, lat, lng, radius, onHandleChange }) => {
    const location = { lat, lng };

    return (
        <GoogleMapContainer>
            <Map
                defaultZoom={9}
                zoom={9}
                google={google}
                initialCenter={{ lat: -34.397, lng: 150.644 }}
                center={location}
            >
                {location.lat ? (
                    <Circle
                        radius={radius * 1000}
                        center={location}
                        strokeColor='transparent'
                        strokeOpacity={0}
                        strokeWeight={5}
                        fillColor='#37ABF8'
                        fillOpacity={0.75}
                    />
                ) : null}
                {location.lat ? <Marker position={location} /> : null}
            </Map>

            {/* {location.lat ?
                <StyledSliderContainer>
                    <StyledRadiusLabel>50 mi | </StyledRadiusLabel>
                    <StyledSlider
                        defaultValue={[50]}
                        max={50}
                        onAfterChange={(value) => {
                            setRadius(value)
                            onHandleChange({
                                target: {
                                    name: 'radius',
                                    value
                                }
                            }, true);
                        }}
                        renderTrack={Track}
                        renderThumb={Thumb}
                    />
                </StyledSliderContainer> : null} */}
        </GoogleMapContainer>
    )
}
export default MyGoogleMap;