import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'

import { InputContainer, Container, Label, Error, BottomLabel } from './style'

const Input = props => {
  const {
    label,
    errors,
    touched,
    bottomLabel,
    inputStyle,
    className,
    onErrorClick,
    ...inputProps
  } = props

  const handleErrorClick = () => {
    if (_.isFunction(onErrorClick)) {
      onErrorClick()
    }
  }

  return (
    <Container className={className}>
      <Label>{label}</Label>
      <InputContainer {...inputProps} style={inputStyle} />
      {errors && touched ? (
        <Error onClick={handleErrorClick}>{errors}</Error>
      ) : (
        <BottomLabel>{bottomLabel && bottomLabel}</BottomLabel>
      )}
    </Container>
  )
}

Input.propTypes = {
  bottomLabel: PropTypes.string,
  containerStyle: PropTypes.object,
  errors: PropTypes.string,
  input: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  touched: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onErrorClick: PropTypes.func
}

export { Input }
