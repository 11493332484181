import React from 'react'
import PropTypes from 'prop-types'

import { Marker } from './style'

const MapMarker = ({ center, onClick, isActive, ...props }) => (
  <Marker center={center} isActive={isActive} onClick={onClick} {...props} />
)

MapMarker.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }).isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func
}

export { MapMarker }
