import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'i18next'

import { Container, LinkText, RowBlock, Text } from './styles'

const PaymentCard = ({
  date,
  refValue,
  jobTitle,
  budget,
  serviceFees,
  totalAmount,
  paymentType,
  link,
  isPayment,
  tax,
  payoutFee,
}) => {
  return (
    <Container>
      <RowBlock>
        <Text flex={1}>{date}</Text>
        <Text>Ref: {refValue?.slice(0, 6) || ''}</Text>
      </RowBlock>
      {!!jobTitle && (
        <RowBlock>
          <Text flex={1} numberOfLines={1}>
            {i18n.t('LeftoverOnes:jobTitle')}:{' '}
            <LinkText to={link}>{jobTitle}</LinkText>
          </Text>
        </RowBlock>
      )}
      {!!budget && (
        <RowBlock>
          <Text flex={1}>{i18n.t('profilePage:budget')}</Text>
          <Text>${budget.toFixed(2)}</Text>
        </RowBlock>
      )}
      {!!serviceFees && (
        <RowBlock>
          <Text flex={1}>{i18n.t('profilePage:serviceFee')}:</Text>
          {serviceFees !== 0 && <Text isBold>${serviceFees.toFixed(2)}</Text>}
        </RowBlock>
      )}
      {!!tax && (
        <RowBlock>
          <Text flex={1}>{i18n.t('jobDetail:processingFee')}:</Text>
          {tax !== 0 && <Text isBold>$0.30</Text>}
        </RowBlock>
      )}
      {!!tax && (
        <RowBlock>
          <Text flex={1}>
            + 2.9% ({i18n.t('myJobsDetail:paypalFeeDescription')}):
          </Text>
          {tax !== 0 && <Text isBold>${tax.toFixed(2)}</Text>}
        </RowBlock>
      )}
      {!!payoutFee && (
        <RowBlock>
          <Text flex={1}>
            {i18n.t('profilePage:payoutFee')}:
          </Text>
          {payoutFee !== 0 && <Text isBold>- ${payoutFee.toFixed(2)}</Text>}
        </RowBlock>
      )}
      <RowBlock>
        <Text flex={1}>{i18n.t('profilePage:total')}</Text>
        <Text isBold>
          {isPayment ? '-' : '+'} ${totalAmount.toFixed(2)}
        </Text>
      </RowBlock>
      {!!paymentType && (
        <RowBlock>
          <Text flex={1}>{`(${paymentType})`}</Text>
        </RowBlock>
      )}
    </Container>
  )
}

PaymentCard.propTypes = {
  date: PropTypes.string,
  refValue: PropTypes.string,
  jobTitle: PropTypes.string,
  budget: PropTypes.number,
  serviceFees: PropTypes.number,
  totalAmount: PropTypes.number,
  paymentType: PropTypes.string,
  link: PropTypes.string,
  isPayment: PropTypes.bool
}

export default PaymentCard
