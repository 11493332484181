import style from 'Assets/lang'

const PHONES = {
  en: {
    flag: style.flagUs,
    code: 'usa',
    phone: '+1',
    id: 1
  },
  fr: {
    flag: style.flagFr,
    code: 'fr',
    phone: '+33',
    id: 2
  },
  es: {
    flag: style.flagSp,
    code: 'sp',
    phone: '+34',
    id: 3
  },
  it: {
    flag: style.flagIt,
    code: 'it',
    phone: '+39',
    id: 4
  },
  pt: {
    flag: style.flagPg,
    code: 'pt',
    phone: '+351',
    id: 5
  },
  de: {
    flag: style.flagDe,
    code: 'de',
    phone: '+49',
    id: 6
  },
  ru: {
    flag: style.flagRu,
    code: 'ru',
    phone: '+7',
    id: 7
  },
  in: {
    flag: style.flagIn,
    code: 'in',
    phone: '+91',
    id: 8
  },
  ua: {
    flag: style.flagRu,
    code: 'ua',
    phone: '+38',
    id: 9
  }
}

export default PHONES
