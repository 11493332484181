export const PostedJobsKeys = [
  't',
  'location',
  'match',
  'postedJobs',
  'onGetPostedJobList',
  'history',
  'user',
  'onDeleteJob',
  'services',
  'onShare',
  'specificJob',
  'serviceProfile',
  'onAccept',
  'onHire',
  'onReject',
  'loadingApplicationId',
  'onSendRequest'
]

export const AppliedJobsKeys = [
  't',
  'appliedJobs',
  'location',
  'match',
  'onLoadAppliedJobList',
  'user',
  'onShare',
  'onCancelJob',
  'history',
  'specificJob',
  'onSendRequest',
  'serviceProfile'
]

export const BookedJobsKeys = [
  't',
  'bookedJobs',
  'location',
  'match',
  'onLoadBookedJobList',
  'user',
  'onShare',
  'specificJob',
  'onCancelJob'
]

export const getHeaderTabsInitialId = location => {
  const subRoute = location.pathname.split('/')[2]

  switch (subRoute) {
    case 'applied':
      return 'b'
    case 'booked':
      return 'c'
    default:
      return 'a'
  }
}

export const sortData = [
  {
    text: 'new',
    value: 'new',
    turnOffText: 'common:off',
    turnOnText: 'common:on'
  },
  {
    text: 'distance',
    value: 'distance',
    turnOnText: ['closest', 'on'],
    turnOffText: ['closest', 'off'],
    activeValue: 'asc',
    disabledValue: 'desc'
  },
  {
    text: 'budget',
    value: 'budget',
    turnOffText: 'lowToHigh',
    turnOnText: 'highToLow'
  },
  {
    text: 'expiryDate',
    value: 'expiryDate',
    turnOnText: ['soonest', 'on'],
    turnOffText: ['soonest', 'off']
  }
]
