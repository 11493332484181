import * as Yup from 'yup'

export const getValidationSchema = t => {
  return Yup.object().shape({
    password: Yup.string()
      .required(t('validation.password'))
      .min(8, t('validation.passwordLength'))
      .matches(/[a-z]/, t('validation.passwordLowerCase'))
      .matches(/[A-Z]/, t('validation.passwordUpperCase'))
      .matches(/[0-9]/, t('validation.passwordNumber'))
      .matches(/[a-zA-Z0-9]+$/, t('validation.passwordLatin')),
  })
}
