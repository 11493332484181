import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ProgressBarLib from 'react-progress-bar-plus'
import 'react-progress-bar-plus/lib/progress-bar.css'

import { Container } from './style'

class ProgressBar extends Component {
  shouldComponentUpdate(nextProps) {
    const { percent } = this.props
    return nextProps.percent !== percent
  }

  render() {
    const {
      percent,
      spinner,
      autoIncrement,
      intervalTime,
      onCreateRef,
      isDisabledScreen,
      ...props
    } = this.props

    return (
      <Container isDisabled={isDisabledScreen}>
        <ProgressBarLib
          autoIncrement={autoIncrement}
          intervalTime={intervalTime}
          percent={percent}
          ref={onCreateRef}
          spinner={spinner}
          onTop
          {...props}
        />
      </Container>
    )
  }
}

ProgressBar.propTypes = {
  autoIncrement: PropTypes.bool,
  intervalTime: PropTypes.number,
  isDisabledScreen: PropTypes.bool,
  percent: PropTypes.number,
  spinner: PropTypes.oneOf([false, 'left', 'right']),
  onCreateRef: PropTypes.func
}

ProgressBar.defaultProps = {
  percent: -1,
  autoIncrement: true,
  intervalTime: 100,
  spinner: false
}

export { ProgressBar }
