import styled from 'styled-components'
import { fontFamily, sizes, fontSize } from 'Constants'

export const ItemDefaultWrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  ${props =>
    props.wrapped && `border: 1px solid ${props.theme.colors.itemBorder}`}
`

export const ContainersWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 44px 0;
  @media (max-width: ${sizes.phone}px) {
    padding: 30px 0;
  }
`

export const GridFourElements = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  ${ItemDefaultWrapper} {
    margin: 10px 0;
    width: 22%;
    @media (max-width: ${sizes.desktop}px) {
      width: 22%;
    }
    @media (max-width: ${sizes.tablet}px) {
      width: 45%;
    }
    @media (max-width: ${sizes.phone}px) {
      width: 90%;
    }
  }
`

export const SubTitle = styled.h3`
  text-align: center;
  font-size: ${fontSize.h1};
  font-family: ${fontFamily};
  font-weight: 300;
  color: #565656;
  @media (max-width: ${sizes.tablet}px) {
    font-size: ${fontSize.h3};
  }
`

export const MainTitle = styled.h2`
  text-align: center;
  margin: 0.1em;
  font-size: ${fontSize.large};
  font-family: ${fontFamily};
  font-weight: 300;
  color: #565656;
  @media (max-width: ${sizes.desktop}px) {
    font-size: ${fontSize.h1};
  }
  @media (max-width: ${sizes.tablet}px) {
    font-size: ${fontSize.h3};
  }
  @media (max-width: ${sizes.phone}px) {
    font-size: ${fontSize.h4};
  }
`

export const WidthLimiter = styled.div`
  width: 70rem;
  max-width: 90%;
  margin: 0 auto;
`
