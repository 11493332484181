import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'i18next'

import { Container, Text } from './style'

const EmptyList = ({ children }) => (
  <Container>
    <Text>{children || i18n.t('common:emptyList')}</Text>
  </Container>
)

EmptyList.propTypes = {
  children: PropTypes.string,
  t: PropTypes.func
}

export default EmptyList
