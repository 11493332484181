import { createAsyncAction } from 'Helpers/redux'
import { apiCall } from 'Redux/actions/api'

export const REPORT = createAsyncAction('REPORT')
export const report = (userId, message) => {
  return apiCall({
    method: 'POST',
    endpoint: `/user-report`,
    types: REPORT,
    query: {
      userId,
      message
    }
  })
}
