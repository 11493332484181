import React, { useEffect, useState } from 'react'
import { Select } from '../style'

const SelectField = ({
  option,
  name,
  onHandleChange,
  placeholder,
  isValid,
  requiredMessage,
  value
}) => {
  const [isSelect, setIsSelected] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {}, [value])

  const onValueCheck = () => {
    if (isSelect) {
      setIsError(false)
    } else {
      setIsError(true)
    }
  }
  const onSelect = (event) => {
    onHandleChange(event,true)
    setIsSelected(true)
  }
  return (
    <div>
      <Select
        defaultValue={value}      // custom code value change with DefaultValue
        required
        onMouseOut={onValueCheck}
        name={name}
        onChange={onSelect}
      >
        
        <option disabled>
          {placeholder}
        </option>
        {option.map((item, key) => (
          <option key={key} value={item.id}>
            {item.name}
          </option>
        ))}
      </Select>
      {isError && (
        <span style={{ color: 'red', padding: '10px' }}>{requiredMessage}</span>
      )}
    </div>
  )
}

export default SelectField
