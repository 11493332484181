import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withNamespaces } from 'react-i18next'
import { services } from 'Redux/actions/services'
import { getGeo } from 'Redux/actions/googleGeocoding'
import { viewedAds } from 'Redux/actions/subscription'
import { rehydrateToken } from 'Redux/actions/auth'

import { getProfile } from 'Redux/selectors/user'
import { getServices } from 'Redux/selectors/services'
import { getCredentials } from 'Redux/selectors/auth'
import { postJob, updateJob } from 'Redux/actions/job'
import Component from './JobPosts'

const selector = createStructuredSelector({
  credentials: getCredentials,
  user: getProfile,
  services: getServices
})

const actions = {
  onViewedAds: viewedAds,
  onTokenRehydrate: rehydrateToken,
  onGetServices: services,
  onPostJob: postJob,
  onUpdateJob: updateJob,
  onGetGeo: getGeo,
}

export const JobPosts = connect(
  selector,
  actions
)(withNamespaces('services')(Component))
